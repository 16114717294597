import { motion } from 'framer-motion';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FadeIn = ({ children }: { children: any }) => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};
