import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { featureBox, featureBoxBtn, featureBoxTypo, featureMainBox } from './style'
import { CASH_FUND_ID, CASH_FUND_WEALTH_BASE_URL } from '../../../../constants';
import { WEALTH_STORAGE_BASER_URL } from 'constants/settings';

const CFDownloadTab = () => {

    const arr = [
        // { title: "Latest Fund Manager's Report", btnTitle: "Download", href: `${WEALTH_STORAGE_BASER_URL}/publicfiles/FundManagerReportNov2023.pdf` },
        { title: "Latest Fund Manager's Report", btnTitle: "Download", href: `${CASH_FUND_WEALTH_BASE_URL}/api/Document/FMR_Dec_2023` },
        { title: "Offering Document", btnTitle: "Download", href: `${CASH_FUND_WEALTH_BASE_URL}/api/Document/OfferingDocument` },
    ]
    return (
        <>
            {arr.map((item: any, index: number) => {
                return (
                    <Grid key={index} item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Box sx={featureMainBox} className="downloadBtnWrapper">
                            <Box sx={featureBox} >
                                <Typography sx={featureBoxTypo}>{item.title}</Typography>
                            </Box>
                            <Button sx={featureBoxBtn}
                                href={item.href}
                                target={"_blank"}
                            >{item.btnTitle}</Button>
                            {/* <Link download to="/files/myfile.pdf" target="_blank">Download</Link> =========> 2nd way of download pdf */}
                        </Box>
                    </Grid>
                )
            })}
        </>
    )
}

export default CFDownloadTab