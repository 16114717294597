import * as React from 'react';
import Typography from '@mui/material/Typography';
import { memo, useCallback, useState } from 'react';
import {
  Cell,
  Legend,
  Pie,
  Tooltip,
  PieChart as Chart,
  ResponsiveContainer,
  Sector
} from 'recharts';
import { CurrencyFormatter } from "../../../utils/calculation";
import { pieChartLegentCustomAmountStyle, pieChartLegentCustomColorBoxStyle, pieChartLegentCustomMainDivStyle, pieChartLegentCustomTitleStyle } from './style/pieChartStyle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PieChart = ({
  colors,
  data,
  route,
  savings,
  selection
}: PieChartProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const [isOpenOneExpanded, setIsOpenOneExpanded] = React.useState(false);
  const [isOpenTwoExpanded, setIsOpenTwoExpanded] = React.useState(false);

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const handleClick = () => {
    setIsOpenOneExpanded(!isOpenOneExpanded);
  };
  const handleClick1 = () => {
    setIsOpenTwoExpanded(!isOpenTwoExpanded);
  };

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      endAngle,
      fill,
      innerRadius,
      midAngle,
      outerRadius,
      payload,
      percent,
      startAngle,
      value
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text
          dy={15}
          fill={fill}
          fontFamily='Poppins'
          fontSize={smScreen ? "19.925px" : 'inherit'}
          fontStyle='normal'
          fontWeight='400'
          textAnchor='middle'
          x={cx}
          y={cy}
        >
          {payload.name}
        </text>
        {/* <text
        x={cx}
        y={cy}
        dy={0}
        textAnchor="middle"
        fill="#333"
      >{`${value}`}</text> */}
        <text
          color='#000'
          dy={-5}
          fill='#000'
          fontSize={smScreen ? "30px" :'inherit'}
          fontWeight='bold'
          textAnchor='middle'
          x={cx}
          y={cy}
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          endAngle={endAngle}
          fill={fill}
          innerRadius={innerRadius - 2}
          outerRadius={outerRadius + 2}
          startAngle={startAngle}
          stroke={'26.5667px solid #3D2B7D'}
          style={{
            filter: 'drop-shadow(rgb(152,152,152) 1.32084px 10.9813px 3.6229px)',
            cursor: 'pointer'
          }}
        />
      </g>
    );
  };

  return (
    <>

      <ResponsiveContainer aspect={smScreen ? 2 : 0.7}>
        <Chart>
          {route === 'Dashboard' ? (
            <Legend align='left' layout='vertical' verticalAlign='middle' />
          ) : route === 'ProgressBarChart' ? (
            <>
              {smScreen ? <Legend
                align='right'
                layout='vertical'
                verticalAlign='middle'
                wrapperStyle={{
                  marginBottom: '10px',
                  marginRight: '1rem',
                  display: "flex"
                }}
                content={renderCusomizedLegend}
              />
                :
                <Legend content={renderCusomizedLegend} layout='horizontal'
                  wrapperStyle={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                />
              }
            </>
          ) : ""}

          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            className='chartOpacity'
            cx={smScreen ? '35%' : "50%"}
            // cy='10%'
            data={data}
            dataKey='value'
            fill='#8884d8'
            innerRadius={"55%"}
            onMouseEnter={onPieEnter}
            outerRadius={"80%"}
            stroke={'26.5667px solid #3D2B7D'}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                className='chartOpacity'
                fill={colors[index % colors?.length]}
                style={{ border: '26.5667px solid #3D2B7D' }}
              />
            ))}
          </Pie>

          {/* <Tooltip /> */}
        </Chart>
      </ResponsiveContainer>
    </>
  );
};

export default memo(PieChart);

interface Selection {
  active: number;
  setActive: (active: number) => void;
}

type Shape = { name: string; value: number };
interface PieChartProps {
  colors: any[];
  data: Array<Shape>;
  selection?: Selection;
  heading?: string;
  route?: any;
  savings?: number;
}


const renderCusomizedLegend = (props: any) => {
  const { payload } = props
  return (
    <ul>
      {
        payload.map((entry: any, index: any) => (
          <div key={`item-${index}`} style={pieChartLegentCustomMainDivStyle}>
            <div style={{ ...pieChartLegentCustomColorBoxStyle, background: entry.color }}></div>
            <div style={pieChartLegentCustomTitleStyle}>{entry.value} {entry.payload.value}%

              <div style={pieChartLegentCustomAmountStyle}>
                {CurrencyFormatter(
                  entry.payload.amount,
                  true
                )}
              </div>
            </div>
          </div>
        ))
      }
    </ul >
  )
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any

