enum genderEnum {
    Female = '0',
    Male = '1',
    Other = '2'
}

enum maritalStatusEnum {
    Single = 0,
    Married = 1,
}

enum YesNoEnum {
    No = 0,
    Yes = 1,
}

enum employmentStatusEnum {
    FullTime = 0,
    PartTime = 1,
    Freelancer = 2,
    Contract = 3,
    Internship = 4,
    Apprenticeship = 5,
    Seasonal = 6,
}

export const gender = [
    { label: 'Male', value: (genderEnum.Male).toString() },
    { label: 'Female', value: (genderEnum.Female).toString() },
    // { label: 'Other', value: (genderEnum.Other).toString() },
];


export const maritalStatus = [
    { label: 'Single', value: (maritalStatusEnum.Single).toString() },
    { label: 'Married', value: (maritalStatusEnum.Married).toString() },
];

export const YesNoRadioButtonValues = [
    { label: 'Yes', value: (YesNoEnum.Yes).toString() },
    { label: 'No', value: (YesNoEnum.No).toString() },
];

export const employmentStatus = [
    { label: 'Full Time', value: (employmentStatusEnum.FullTime).toString() },
    { label: 'Part Time', value: (employmentStatusEnum.PartTime).toString() },
    { label: 'Freelancer', value: (employmentStatusEnum.Freelancer).toString() },
    { label: 'Contract', value: (employmentStatusEnum.Contract).toString() },
    { label: 'Internship', value: (employmentStatusEnum.Internship).toString() },
    { label: 'Apprenticeship', value: (employmentStatusEnum.Apprenticeship).toString() },
    { label: 'Seasonal', value: (employmentStatusEnum.Seasonal).toString() },
];
