import React from 'react'
import { Form } from 'react-bootstrap'

const labelStyle = {
    display: 'flex',
    // alignItems: 'center',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px!important",
    lineHeight: "24px",
    color: "#202020",

}

const CustomSingleCheckBox = ({ fieldName, formik, label, styleErr, styleForm, type = "checkbox" }: any) => {
    const formikTouch = formik.touched as never;
    const formikError = formik.errors as never;
    return (
        <>
            <div>
                <Form.Check
                    checked={formik.values[fieldName]}
                    id={`${fieldName}`}
                    label={<div dangerouslySetInnerHTML={{ __html: label }} />}
                    // className="sameAsAddressCheckBox"
                    onChange={formik.getFieldProps(fieldName).onChange}
                    type={type}
                    style={{ ...labelStyle , ...styleForm }}
                />

                <span className='text-danger' style={{ ...styleErr }}>
                    {formikTouch[fieldName] && formikError[fieldName]}
                </span>
            </div>

        </>
    )
}

export default CustomSingleCheckBox