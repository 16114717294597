import { Modal } from 'react-bootstrap';

const DeleteConfirmation = ({
  deleteConfirm,
  handleDeleteCancel,
  handleDeleteConfirm
}: DeleteConfirmProps) => {
  return (
    <Modal
      centered
      aria-labelledby='contained-modal-title-vcenter'
      show={deleteConfirm}
      size='sm'
    >
      <Modal.Body className='px-3'>
        <div className='text-center'>
          <h4 className='pb-3'>Are you sure you want to delete ?</h4>
          <div>
            <span className='ConfirmationContentButton'>
              <button
                className='btn btn-primary mr-2'
                onClick={handleDeleteConfirm}
              >
                Yes
              </button>
            </span>
            <span className='ConfirmationContentButton'>
              <button className='btn btn-danger ' onClick={handleDeleteCancel}>
                No
              </button>
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteConfirmation;

interface DeleteConfirmProps {
  deleteConfirm: boolean;
  handleDeleteConfirm: () => void;
  handleDeleteCancel: () => void;
}
