import * as Yup from 'yup';

export const zakaatSchema = Yup.object({
    
    place: Yup.string().required().label('Name'),

    fiqah: Yup.string().required().label('fiqah'),

    signature: Yup.string().required().matches(
        /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
        "Signature is required"
    ).nullable()

});