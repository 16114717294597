import { Box, Divider, Grid, Typography } from '@mui/material'
import BottomBtn from 'Latest/2ndTierKyc/bottomBtn'
import { useFormik } from 'formik'
import DynamicQuestionAnswers from './dynamicQuestion'
import React, { useEffect } from 'react'
import { QuestionAnswerCategoryEnum } from '../../../../constants'
import { onGetUserQuestionAnswers } from 'redux/actions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useHistory } from 'react-router'

const Index = ({ formik, handleModalSubmitAPIBtn, handleNext , handlePrevious }: any) => {

    const dispatch = useAppDispatch();
    const { kycDecQuestion } = useAppSelector((state: any) => state.questionAnswer);
    const { push } = useHistory();
    
    let nestedQuestion_1 = {}
    const addNestedQuestion = (questionAnswer: any) => {
        questionAnswer?.answer?.answerOptions?.map((item: any, index: number) => {
            const questionId = questionAnswer?.id;
            if (item?.value === formik?.values[questionId]) {
                if (item?.answer) {
                    nestedQuestion_1 = { item: item?.answer, activeStep: questionId };
                } else {
                    nestedQuestion_1 = {};
                    if (formik.values[`${questionId}-nested`] || formik.values[`${questionId}-nested`] =="") {
                        formik.setFieldValue(`${questionId}-nested`, undefined);
                    }
                }
            }
        });
    };

    const handleSaveExit = async () => {
        const errors = await formik.validateForm();
        if (Object.keys(errors).length === 0) {
            // No validation errors, proceed with your save/exit logic
            // formik.handleSubmit()
            handleModalSubmitAPIBtn(true)
        } else {
            // There are validation errors, you can handle them or show a message
            push("myprofile?scroll=true")
        }
    }

    return (
        <>
            <Grid item md={12} >
                <Typography variant="h5">Declarations</Typography>
            </Grid>

            <Grid item md={12} sx={{ py: "20px" }} >
                {kycDecQuestion?.questionAnswer?.map((value: any, index: number) => {
                    addNestedQuestion(value)
                    const showDivider = kycDecQuestion?.questionAnswer?.length - 1 != index
                    return (
                        <Box key={index} >
                            <DynamicQuestionAnswers
                                formik={formik}
                                nestedQuestion={nestedQuestion_1}
                                questionAnswers={value}
                            />
                            {showDivider && <Divider sx={{ borderColor: "#EAECF0", my: "30px" }} />}
                        </Box>
                    )
                })}
            </Grid>

            <BottomBtn handleSaveExit={handleSaveExit} handlePrevious={handlePrevious} handleNext={() => formik.handleSubmit()} nextBtnTitle={"Submit"} />
        </>
    )
}

export default Index