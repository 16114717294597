import React from 'react'

const ProfileIconV2 = ({ fill = "#667085" }: any) => {
    return (
        <div>
            <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.9734 18.6874C19.688 16.4315 17.6809 14.6737 15.2752 13.6971C16.4716 12.7998 17.3553 11.5489 17.8011 10.1215C18.247 8.69412 18.2325 7.16262 17.7596 5.74395C17.2867 4.32527 16.3794 3.09135 15.1663 2.21697C13.9531 1.34259 12.4956 0.87207 11.0002 0.87207C9.50481 0.87207 8.04731 1.34259 6.83416 2.21697C5.62102 3.09135 4.71375 4.32527 4.24086 5.74395C3.76797 7.16262 3.75343 8.69412 4.19932 10.1215C4.6452 11.5489 5.52889 12.7998 6.72522 13.6971C4.31953 14.6737 2.31245 16.4315 1.0271 18.6874C0.947454 18.8154 0.894371 18.9582 0.871006 19.1072C0.847641 19.2562 0.85447 19.4084 0.891088 19.5547C0.927706 19.701 0.993366 19.8384 1.08416 19.9588C1.17496 20.0792 1.28904 20.1802 1.41963 20.2556C1.55021 20.3311 1.69463 20.3795 1.8443 20.398C1.99397 20.4165 2.14584 20.4047 2.29087 20.3634C2.4359 20.322 2.57114 20.252 2.68854 20.1573C2.80595 20.0626 2.90312 19.9453 2.97429 19.8124C4.67304 16.8761 7.67304 15.1249 11.0002 15.1249C14.3274 15.1249 17.3274 16.8771 19.0262 19.8124C19.1804 20.0604 19.425 20.2387 19.7083 20.3096C19.9916 20.3805 20.2914 20.3385 20.5443 20.1924C20.7972 20.0463 20.9833 19.8076 21.0634 19.5267C21.1434 19.2458 21.1112 18.9449 20.9734 18.6874ZM6.12523 7.99988C6.12523 7.0357 6.41114 6.09317 6.94681 5.29148C7.48248 4.48979 8.24385 3.86495 9.13464 3.49597C10.0254 3.12699 11.0056 3.03045 11.9513 3.21855C12.8969 3.40666 13.7656 3.87096 14.4474 4.55274C15.1292 5.23452 15.5935 6.10316 15.7816 7.04882C15.9697 7.99447 15.8731 8.97467 15.5041 9.86546C15.1352 10.7563 14.5103 11.5176 13.7086 12.0533C12.9069 12.589 11.9644 12.8749 11.0002 12.8749C9.70775 12.8734 8.46864 12.3593 7.55472 11.4454C6.64081 10.5315 6.12671 9.29236 6.12523 7.99988Z" fill={fill} />
            </svg>
        </div>
    )
}

export default ProfileIconV2