import * as React from 'react';
import '../../scss/login/_Login.scss';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { FormEvent, memo } from 'react';
import { authStyles } from 'Latest/Style/Style';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Logo from 'Latest/Images/mahanna2.png'
import Container from '@mui/material/Container';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import verifyEmail from 'Latest/Images/svg/verifyEmail.svg';
import { useAppLoading } from 'hooks/appLoading';
import { Link } from 'react-router-dom';
import { Typography, useMediaQuery } from '@mui/material';
import { styleAuth } from './style';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';

const ResendEmailVerification = ({ resendEmail }: ResendEmailVerificationProps) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    resendEmail()
  };

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const authResendEmailLoading = useAppLoading('auth');
  const authVerificationLoading = useAppLoading('AuthVerificaton');

  const isLoading = authResendEmailLoading || authVerificationLoading

  return (
    <>
      <Container disableGutters={smScreen} maxWidth="sm" sx={{ mt: { xs: "24px", md: "40px" } }}>
        <Box sx={styleAuth.cardContainer}>
          <img src={verifyEmail} style={{ margin: '0 auto 2.5rem' }} />

          <Typography variant='h1' textAlign={"center"} color="#1D2939">Verify your email</Typography>

          <Box >
            <Typography variant='body2' textAlign={"center"} color={"rgba(29, 41, 57, 0.70)"}>
              We sent a verification email
              {/* to */}
            </Typography>
            {/* <Typography variant='body2' textAlign={"center"}>
            johndoe@gmail.com.
          </Typography> */}
            <Typography variant='body2' textAlign={"center"}  color={"rgba(29, 41, 57, 0.70)"}>
              Check your inbox for next steps.
            </Typography>
          </Box>

          <Box sx={{ pt: "12px" }}>
            <LoadingBtnCustom btnTitle="Resend email" size={"large"} fullWidth={true} handleOnClick={handleSubmit} loading={isLoading}  />
          </Box>

        </Box>
      </Container>


      {/* <Container className="Login" component="main" maxWidth="md" sx={{ ...authStyles.container, ...authStyles.resendEmail }}>
        <Card className='login_Card'>
          <Box className='Form_Main'
            component="form"
            onSubmit={handleSubmit}
          >
            <Link to="/" style={{ display: "flex", justifyContent: "center" }}>
              <img alt="Mahaana" className='img logo' src={Logo} />
            </Link>
            <Box className='Form_Secondary' id='otp-field' >
              <img src={verifyEmail} alt="verify-email" />
              <h1 className='ReEmail_heading'>Please check your mailbox for a confirmation email</h1>
              <AuthSubmitButton title='Resend Email' loading={isLoading} />
            </Box>
          </Box>
        </Card>
      </Container> */}

    </>
  );
};

export default memo(ResendEmailVerification);

interface ResendEmailVerificationProps {
  resendEmail: () => void;
}