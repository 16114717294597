import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
  filters: {
    categories: [],
    companies: [],
    risks: [],
    types: []
  },
  funds: []
};

const name = 'funds';
const fundsSlice = createSlice({
  initialState,
  name,
  reducers: {
    get: state => {
      state.funds = [];
    },
    getCategoriesSuccess: (state, action: PayloadAction<fundTypeSuccess>) => {
      const data = JSON.parse(action.payload.Data);

      state.filters.categories = data.map(
        (val: Record<string, string | number>) => ({
          ...val,
          label: val.name,
          value: val.id
        })
      );
    },
    getCompaniesSuccess: (state, action: PayloadAction<fundTypeSuccess>) => {
      const data = JSON.parse(action.payload.Data);

      state.filters.companies = data.map(
        (val: Record<string, string | number>) => ({
          ...val,
          label: val.name,
          value: val.id
        })
      );
    },
    getRisksSuccess: (state, action: PayloadAction<fundTypeSuccess>) => {
      const data = JSON.parse(action.payload.Data);

      state.filters.risks = data.map(
        (val: Record<string, string | number>) => ({
          ...val,
          label: val.name,
          value: val.id
        })
      );
    },
    getSuccess: (state, action: PayloadAction<SuccessPayload>) => {
      const data = JSON.parse(action.payload.data);
      state.funds = data;
    },
    getTypesSuccess: (state, action: PayloadAction<fundTypeSuccess>) => {
      const data = JSON.parse(action.payload.Data);
      state.filters.types = data.map(
        (val: Record<string, string | number>) => ({
          ...val,
          label: val.name,
          value: val.id
        })
      );
    }
  }
});

export default fundsSlice.reducer;

//? APIS ACTIONS

export const onGetFunds = (
  data: FilterParams = { Category: 0, Company: 0, FundType: 0, Risk: 0 }
) => ({
  payload: {
    apiName: name,
    data,
    method: 'get',
    url: `Product/GetProductList?fund_type_id=${data.Category}&fund_risk_type_id=${data.Risk}&fund_investment_type_id=${data.FundType}&fund_provider_id=${data.Company}`
  },
  type: 'get'
});

export const onSaveFundInvestments = (data: InvestmentProps) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: `BankFundsAllocation/SaveFundInvestments`
  },
  type: 'saveFundInvestments'
});

export const onGetFundsCategories = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'Common/GetAllFundTypes'
  },
  type: 'getCategories'
});

export const onGetFundsRisks = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'Common/GetAllFundRiskTypes'
  },
  type: 'getRisks'
});

export const onGetFundsCompanies = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'Common/GetAllBankList'
  },
  type: 'getCompanies'
});

export const onGetFundsTypes = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'Common/GetAllFundInvestmentTypes'
  },
  type: 'getTypes'
});

// ? Types

export interface Fund {
  id: number;
  name_: string;
  name: string;
  net_asset_value: number;
  absolute_return_annualized_1_year: number;
  absolute_return_annualized_3_year: number;
  absolute_return_annualized_5_year: number;
  absolute_return_annualized_since_inception: number;
  fund_symbol: string;
  fund_type: string;
  percent_change_net_asset_value: number;
  launch_date: string;
}
export interface InvestmentProps {
  bank_funds_allocation_id: number;
  initial_amount: number;
}
export type Funds = Array<Fund>;

type DynamicList = Array<Record<string, string | number>>;
export type Filters = {
  categories: DynamicList;
  companies: DynamicList;
  risks: DynamicList;
  types: DynamicList;
};
interface InitialState {
  funds: Funds;
  filters: Filters;
}
export interface fundTypeSuccess {
  Data: string;
}
interface SuccessPayload {
  data: string;
}
export type FilterParams = {
  Category: number;
  Company: number;
  Risk: number;
  FundType: number;
};
