import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CASH_FUND_ID } from '../../../../constants';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import { useAppSelector } from 'hooks/reduxHooks';
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style';

const Index = ({ RedirectLink, goalId, nextStep, previousStep }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { userAccountDetails } = useAppSelector(state => state.auth);


    const handleNextCF = () => {
        if (RedirectLink == "dashboard") {
            nextStep(undefined, 1)
        } else {
            nextStep(undefined, 2)
        }
    }

    const handleNextGI = () => {
        if (RedirectLink == "dashboard") {
            nextStep(undefined, 1)
        } else {
            nextStep(undefined, 2)
        }
    }

    const infoData = [
        { name: "Your financial information", description: "The income you make and your occupation." },
        goalId !== CASH_FUND_ID && { name: "Your risk profile information", description: "Your investing capability and experience." },
        { name: "Your personal information", description: "Your CNIC information, bank account IBAN and address." },
    ]

    const buttonHtml = () => {
        return (
            goalId === CASH_FUND_ID ?
                <ButtonRowV1 handleBack={previousStep} handleNext={handleNextCF} btnTitleNext={"Continue"} />
                :
                <ButtonRowV1 handleBack={previousStep} handleNext={handleNextGI} btnTitleNext={"Continue"} />
        )
    }

    return (
        <>
            <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ pt: { xs: "24px", md: "40px" } }}>
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <Box sx={mainContainerV2}>
                            <Grid item md={12} xs={12}>
                                <Typography variant='h4' sx={{ textAlign: "center", pb: "4px", color: "#1D2939" }}>Let’s get you set up</Typography>
                                <Typography maxWidth={"636px"} variant='body1' color={"rgba(29, 41, 57, 0.70)"} lineHeight={"normal"} letterSpacing={"unset"} textAlign={"center"}>
                                    {goalId === CASH_FUND_ID ? "We’ll ask for some information so that we can verify your identity and income. Here’s what we’ll need:" : "We’ll ask for some information so that we can recommend a suitable portfolio, verify your identity and income. Here’s what we’ll need:"}
                                </Typography>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <Box gap={"16px"} display={"flex"} flexDirection={"column"} maxWidth={"395px"}>
                                    {infoData?.map((item, index) => {
                                        return (
                                            item && <Box key={index} display={"flex"} gap={"8px"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                    <path d="M12.5 2.25C10.5716 2.25 8.68657 2.82183 7.08319 3.89317C5.47982 4.96451 4.23013 6.48726 3.49218 8.26884C2.75422 10.0504 2.56114 12.0108 2.93735 13.9021C3.31355 15.7934 4.24215 17.5307 5.60571 18.8943C6.96928 20.2579 8.70656 21.1865 10.5979 21.5627C12.4892 21.9389 14.4496 21.7458 16.2312 21.0078C18.0127 20.2699 19.5355 19.0202 20.6068 17.4168C21.6782 15.8134 22.25 13.9284 22.25 12C22.2473 9.41498 21.2192 6.93661 19.3913 5.10872C17.5634 3.28084 15.085 2.25273 12.5 2.25ZM16.7806 10.2806L11.5306 15.5306C11.461 15.6004 11.3783 15.6557 11.2872 15.6934C11.1962 15.7312 11.0986 15.7506 11 15.7506C10.9014 15.7506 10.8038 15.7312 10.7128 15.6934C10.6218 15.6557 10.539 15.6004 10.4694 15.5306L8.21938 13.2806C8.07865 13.1399 7.99959 12.949 7.99959 12.75C7.99959 12.551 8.07865 12.3601 8.21938 12.2194C8.36011 12.0786 8.55098 11.9996 8.75 11.9996C8.94903 11.9996 9.1399 12.0786 9.28063 12.2194L11 13.9397L15.7194 9.21937C15.7891 9.14969 15.8718 9.09442 15.9628 9.0567C16.0539 9.01899 16.1515 8.99958 16.25 8.99958C16.3486 8.99958 16.4461 9.01899 16.5372 9.0567C16.6282 9.09442 16.7109 9.14969 16.7806 9.21937C16.8503 9.28906 16.9056 9.37178 16.9433 9.46283C16.981 9.55387 17.0004 9.65145 17.0004 9.75C17.0004 9.84855 16.981 9.94613 16.9433 10.0372C16.9056 10.1282 16.8503 10.2109 16.7806 10.2806Z" fill="#422E84" />
                                                </svg>
                                                <Box display={"flex"} gap={"8px"} flexDirection={"column"} flex={1}>
                                                    <Typography variant='h6' color={"#422E84"} lineHeight={"normal"}>{item.name}</Typography>
                                                    <Typography variant='body1' color={"rgba(29, 41, 57, 0.70)"} lineHeight={"normal"}>{item.description}</Typography>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Grid>

                            <Grid item md={12} xs={12} sx={{ justifyContent: "flex-end" }} width={"100%"}>
                                {!smScreen && buttonHtml()}
                            </Grid>

                        </Box>

                        {smScreen && <Box sx={smallScreenBtnWrap}>
                            {buttonHtml()}
                        </Box>}
                    </Grid>
                </Grid>
            </Container>

        </>
    )
}

export default Index