import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { proofOfIncome } from './style'
import LoadingButton from '@mui/lab/LoadingButton'
import pdfIcon from "Latest/Images/svg/pdfIcon.svg"
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { toast } from 'react-toastify'
import { useAppLoading } from 'hooks/appLoading'
import { onGetKYCImg } from 'redux/actions'

const ShowPOICard = () => {

    const { user ,userAccountDetails } = useAppSelector(state => state.auth);

    const dispatch = useAppDispatch();
    const loading = useAppLoading('onGetKYCImg');

    const handleDownload = (file: any) => {
        const body = {
            userId: user?.id,
            documentName: file?.name
        }

        const onSucces = (message: string, response: any) => {
            const blob = new Blob([message], { type: 'application/octet-stream' });

            // Create a URL for the Blob
            const downloadUrl = URL.createObjectURL(blob);

            // Create an anchor tag with the download URL
            const downloadLink = document.createElement('a');
            downloadLink.href = downloadUrl;
            downloadLink.download = file?.documentName; // Set the desired filename and extension
            downloadLink.click();

            // Clean up the URL object
            URL.revokeObjectURL(downloadUrl);

        }
  
        dispatch(onGetKYCImg(body, onSucces))
    }

    return (
        <>

            <Grid item md={12} xs={12} >
                <Typography variant='h5' sx={{mb:"2px"}}>Proof of income</Typography>
            </Grid>

            <Grid container rowSpacing={{xs:"16px", md:"0px"}}>

                <Grid item md={6} xs={12}>
                    <Typography variant='body2'>Documents that prove your income or wealth</Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={proofOfIncome.selectedFileMainBox}>
                        {userAccountDetails?.kyc?.kycDocument?.incomeProofs?.map((item: any, index: number) => {
                            return (
                                <>
                                    <Box key={index} sx={proofOfIncome.selectedFileMapMainBox}>
                                        <Box sx={{ display: "flex", gap: "16px" }}>
                                            <img src={pdfIcon} />
                                            <Typography sx={{ wordBreak: "break-all" }}>{item.documentName}</Typography>
                                        </Box>
                                        <LoadingButton loading={loading} color='secondary' variant='outlined' sx={{...proofOfIncome.uploadBtn ,  minWidth: 120 }} onClick={() => handleDownload(item)}>Download</LoadingButton>
                                    </Box>

                                </>
                            )
                        })}



                    </Box>
                </Grid>

            </Grid>

        </>
    )
}

export default ShowPOICard