import { Col, Row } from 'react-bootstrap';
import { memo } from 'react';
import PhoneInput from 'react-phone-input-2';
import { CountryData } from 'jsx/pages/Signup';
import InputLabel from '@mui/material/InputLabel';
import './style.scss';
import { width } from '@mui/system';
const PhoneField = ({
  autoFocus = false,
  bottomInfo,
  country,
  countryCodeEditable,
  disableCountryCode,
  disableDropdown,
  disabled = false,
  fieldName,
  formik,
  isMutual = false,
  label,
  nestedFieldName,
  onChange,
  phoneError = '',
  required = false,
  topInfo,
  type = 'text',
  value
}: PhoneFieldProps) => {
  const changeHandle = (value: string, data: CountryData) => {
    formik.setFieldValue(fieldName, value);
  };
  const formikTouch = formik.touched as any;
  const formikError = formik.errors as any;

  let FieldError = "";
  let FieldTouch = false;
  let NestedFieldError = "";
  let NestedFieldTouch = false;  

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }

  return (
    <Col style={{ fontFamily: 'PlayFair' }}>
      <Row>
        <label className='mb-2 field-label'>
          <strong style={{ fontFamily: 'PlayFair', color: '#000000' }}>
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </strong>
        </label>
      </Row>
      
      {topInfo && (
        <Row>
          <div className='small-info mb-2 mt-1 text-muted'>{topInfo}</div>
        </Row>
      )}
      <Row >
        <div className='ph_lay'> 
        <PhoneInput
          country={country}
          countryCodeEditable={countryCodeEditable}
          disableCountryCode={disableCountryCode}
          disableDropdown={disableDropdown}
          disabled={disabled}
          onChange={(value: string, data: CountryData) => changeHandle(value, data)}
          value={value}
          />
          </div>
      </Row>

      {bottomInfo && (
        <Row>
          <div className='small-info mb-2 mt-1 text-muted'>{bottomInfo}</div>
        </Row>
      )}

      {FieldTouch && FieldError && (
        <Row>
          <span className='ml-2 mt-1 text-danger'>
            {FieldError}
          </span>
        </Row>
      )}

      {NestedFieldTouch && NestedFieldError && (
        <Row>
          <span className='ml-2 mt-1 text-danger'>
            {NestedFieldError}
          </span>
        </Row>
      )}
    </Col>
  );
};

export default memo(PhoneField);

interface PhoneFieldProps {
  country: string;
  countryCodeEditable: boolean;
  disableCountryCode: boolean;
  disableDropdown: boolean;
  formik: any;
  onChange?: (value: string, data: CountryData) => void;
  value: string;
  autoFocus?: boolean;
  disabled?: boolean;
  label: string | JSX.Element;
  fieldName: string;
  nestedFieldName?: any;
  required?: boolean;
  topInfo?: string;
  type?: string;
  bottomInfo?: string | JSX.Element;
  isMutual?: boolean;
  phoneError?: string;
}
