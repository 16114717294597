export const goalMainHeadTypoStyle = {
    color: "#202020",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "140%",
}

export const goalSubHeadTypoStyle = {
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#202020",
    flexWrap: 'wrap',
    lineHeight: "155%",
}

export const goalCardHeadTypoStyle = {
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "150%",
    color: "#202020"
}

export const monthlyAmountStyle = {
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "26px!important",
    lineHeight: "31.47px",
    color: "#2E205D",
    mt:"8px"
}

export const cardMainLogoBoxStyle = {
    background: "#EAF8FB",
    borderRadius: "50%",
    width: 60, height: 60, display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

export const goalCardTitleStyle = {
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "150%",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#666666",
}

export const otherInputField = {
    // width: "100%",
    // maxWidth: "516px",
     background: "#FFFFFF",
    border: "1px solid #EDEDED",
    borderRadius: "6px",
    padding: "0.5rem 1rem",
    // fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    // color: "#ADADAD",
    fontSize: "14px",
    lineHeight: "24px",
    height:"59px"
}

export const checkMarkStyle = {
    right: "10px",
    position: "absolute",
    top: "9px",
}

export const createdLabel = {
    right: "10px",
    position: "absolute",
    top: "9px",
    // background:"rgba(84, 214, 44, 0.16)",
    // color: "rgb(34, 154, 22)",
    background: "rgba(234, 248, 251, 1)",
    color: "rgba(67, 190, 216, 1)",
    fontFamily: 'Inter,sans-serif',
    fontSize: "0.75rem",
    px: "0.8rem",
    borderRadius: "6px",
    fontWeight: 700
}

// SH Styles

export const investmentOptionsStyle = {
    background: '#EAF8FB',
    borderRadius: '27px',
    padding: '12px',
    border: '2px solid #EAF8FB',
    '&:hover': {
        cursor: 'pointer'
    }
}

export const secondaryTextStyles = {
    color: '#403081',
    fontWeight: '700',
    padding: '0 5px',
    marginTop: '1px'
}


export const NewCFIGraphStyles = {
    headStyles: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: {md: '23px', sm: '23px', xs: '18px'},
        lineHeight: '140%',
        color: '#202020',
    },
    buttonWrapper: {
        '& .MuiBox-root': {
            justifyContent: 'flex-start',
            pt: 2,
            margin: 0
        },
        '& button': {
            width: { md: '246px !important', sm: '246px !important', xs: '100% !important' },
            height: '54px !important',
        }
    },
    customSliderInputField: {
        minWidth: "150px",
        height: "48px",
        background: '#FFFFFF',
        border: '1px solid #EDEDED',
        borderRadius: '6px',
        paddingRight: "1rem",
        paddingLeft: "1rem",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: { md: '0 !important', xs: '0rem !important' },
        marginTop: { lg: '0', md: '10px' },
        '& input': {
            boxShadow: 'none'
        },
        '& .text-danger': {
            position: 'absolute',
            width: '220px',
            top: '34px',
            right: '-24px',
            display: 'flex',
            justifyContent: 'flex-end'
        },

        '& div.position-relative.row': {
            flexWrap: 'noWrap',
            alignItems: 'center',
            '& input': {
                borderWidth: 0,
                padding: 0,
                height: '100%',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '17px',
                lineHeight: '24px',
                border: 'none !important'
            },
            '& p': {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '17px',
                lineHeight: '24px',
                color: '#ADADAD'
            }
        }
    },
    customSliderInput: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '17px',
        lineHeight: '24px',
        color: '#ADADAD',
    },
    errorMsgStyle: {
        fontSize: '14px', color: 'red', fontFamily: 'Inter'
    }

}

