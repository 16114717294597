import { Box, Typography } from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    style?: any
}

export function TabPanel(props: TabPanelProps) {
    const { children, index, style, value, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ width: "100%" }}
        >
            {value === index && (
                // <Box sx={{ p: { xs: "16px", sm: "30px" }, ...style }}>
                <Box>{children}</Box>
                // </Box>
            )}
        </div>
    );
}

export default function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}