import { passwordSchema } from '../../constants';
import * as Yup from 'yup';

export const resetPassword = Yup.object({
  confirmPassword: Yup.string().required().oneOf([Yup.ref('password')], 'Password does not match').label('Password Confirmation'),
  password: Yup.string()
    .matches(
      passwordSchema,
      'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character.'
    ).required().min(8).label('Password')
});
