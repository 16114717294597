import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import BankCheckBoxCard from 'Latest/DashboardScreen/GoalDashboard/Component/bankCheckBoxCard'
import AddPaymentModal from "../Modal/AddPayment"
import ThankYouModal from '../Modal/thankYouModal';
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { onGetUserBankAccount } from 'redux/reducers/sharedStateData';
import ErrModal from '../Modal/errModal';
import { tokens } from 'theme';
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import { cnicViewStyle } from "Latest/MyProfileV1/Style/style";

const Index = ({ screen = "MyProfile", selectedBank }: any) => {
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [openThankModal, setOpenThankModal] = useState(false);
    const [openErrModal, setOpenErrModal] = useState(false);

    const dispatch = useAppDispatch();
    const loading = useAppLoading('sharedStateData');
    const { userBankAcc } = useAppSelector(state => state.sharedData)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
     const svgIconPlus = <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path opacity="0.9" d="M12.3125 5.89282C12.3125 6.27563 12.0117 6.54907 11.6562 6.54907H7.28125V10.9241C7.28125 11.3069 6.98047 11.5803 6.625 11.5803C6.24219 11.5803 5.96875 11.3069 5.96875 10.9241V6.54907H1.59375C1.21094 6.54907 0.9375 6.27563 0.9375 5.89282C0.9375 5.53735 1.21094 5.23657 1.59375 5.23657H5.96875V0.861572C5.96875 0.506104 6.24219 0.205322 6.625 0.205322C6.98047 0.205322 7.28125 0.506104 7.28125 0.861572V5.23657H11.6562C12.0117 5.23657 12.3125 5.53735 12.3125 5.89282Z" fill="#432F87"/>
     </svg>
     

    useEffect(() => {
        dispatch(onGetUserBankAccount())
    }, [])

    const handleConfirm = () => {

        dispatch(onGetUserBankAccount())

        
        setOpenPaymentModal(false)
        setOpenThankModal(true)
    }

    const Content = () => {
        return (
            <Grid item xs={12} sx={{ pt: "8px" }}>
                <Typography variant="h6" color={colors.grey[900]}>Payment method added!</Typography>
                <Typography variant="body2" color={colors.grey[600]}>Thank you for adding a new payment method. We take your payment security seriously and assure you that your information is kept confidential.</Typography>
            </Grid>
        )
    }
    const ContentFailed = () => {
        return (
            <Grid item xs={12} sx={{ pt: "8px" }}>
                <Typography variant="h6" color={colors.grey[900]}>Oops!</Typography>
                <Typography variant="body2" sx={{ mt: "4px" }} color={colors.grey[600]}>"Bank account limit exceeded. Only 5 accounts are allowed. Please delete an existing account if you wish to add a new one."</Typography>
            </Grid>
        )
    }

    const handleNewAcc = () => {
        if (userBankAcc.length >= 5) {
            setOpenErrModal(true)
        } else {
            setOpenPaymentModal(true)
        }
    }

    const ContentBankCheckBoxCard = () => {
        return (
            <BankCheckBoxCard list={userBankAcc} deleteBtn={screen == "MyProfile"} selectedBank={selectedBank} defaultViewLimit={5} />
        )
    }
    const ContentAddNewBtn = () => {
        return (
            <LoadingBtnCustom loading={loading} btnTitle="Add New Account" handleOnClick={() => handleNewAcc()} />
        )
    }
    const ContentAddNewBtn2 = (title: string) => {
        return (
            <LoadingBtnCustomBack btnTitle={title} icon={svgIconPlus} handleOnClick={handleNewAcc}  />
        )
    }


    return (
        <Box>
            <AddPaymentModal
                handleOpen={() => setOpenPaymentModal(true)}
                open={openPaymentModal}
                handleClose={() => setOpenPaymentModal(false)}
                handleConfirm={handleConfirm}
            />

            <ThankYouModal
                open={openThankModal}
                handleClose={() => setOpenThankModal(false)}
                Content={Content}
            />

            <ErrModal handleOpen={() => setOpenErrModal(true)} open={openErrModal} handleClose={() => setOpenErrModal(false)} Content={ContentFailed} />

            {screen == "MyProfile" ?
                <Grid container spacing={"24px"}>

                    <Grid item md={12} xs={12} >
                        <Typography variant='h4' sx={{ ...cnicViewStyle.title }}>Bank Details</Typography>
                    </Grid>

                    <Grid item xs={12} md={12}  >
                        {ContentBankCheckBoxCard()}
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: userBankAcc.length > 0 ? "start" : "center" }} >
                        {ContentAddNewBtn()}
                    </Grid>

                </Grid>
                :
               
                screen == "Paynow" ?
                    <>
                        {ContentAddNewBtn2(`Add account `)}
                    </>
                    :

                    <>
                        {userBankAcc?.length > 0 && <Grid item md={12} xs={12} sx={{ mb: 2, }}>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                                <Box>
                                    <Typography variant="h6"> Select a deposit bank account</Typography>
                                    <Typography variant="body2"> You must use a bank account that’s in your name </Typography>
                                </Box>
                                {screen == "MyProfile" && ContentAddNewBtn2("ADD")}
                            </Box>
                        </Grid>
                        }
                        <Grid item xs={12} md={12} sx={{}}  >
                            {ContentBankCheckBoxCard()}
                        </Grid>
                        {userBankAcc?.length == 0 &&
                            <Grid item xs={12} md={12} sx={{ mt: 1, }}  >
                                {ContentAddNewBtn()}
                            </Grid>
                        }
                    </>
            }
        </Box>
    )
}

export default Index