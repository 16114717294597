import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, ModalBody, Row, Table, } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Option from 'react-select/src/components/Option';
import { LocalUrl } from 'redux/middlewares/api';
import SelectField from '../Shared/FormikSelectField';


interface IProps {
    openModal: boolean;
    setOpenModal: any;
    id: any;
}

const DeleteEquity = (props: IProps) => {
    const [editData, setEditData] = useState([]);

    const DeleteEquity = async () => {
        // alert('AS')
        const resp = await axios.get(LocalUrl + `HoldingAllocation/${(props.id).toString()}`)
        // alert(props.id)
        const tempArray = resp.data;
        const _arr = tempArray.isRetired = false;
        props.setOpenModal(false);
    }
    return (
        <Modal animation={false} onHide={() => props.openModal} show={props.openModal} >
            <Modal.Header >
                <Modal.Title>Edit Score</Modal.Title>
            </Modal.Header>
            <ModalBody>
                Are you sure want to retire this score?
            </ModalBody>
            <Modal.Footer>
                <Button onClick={() => DeleteEquity()}>
                    Delete
                </Button>
                <Button onClick={() => props.setOpenModal(false)}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>
    )
}
export default DeleteEquity;