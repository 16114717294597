import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import Projection from '../../CashFundAssetAllocation/Component/Projection'
import ProjectionChart from '../../CashFundAssetAllocation/Component/ProjectChart'
import { projectionCard } from '../style'
import { removeCommaFormatting } from 'utils/commaSeparateMasking'
import { CashFundAllocationStyles, CashFundAssetsAllocation } from '../../CashFundAssetAllocation/Style/style'
import { CurrencyFormatter, CurrencyFormatterWithDecimal } from 'utils/calculation'
import infoIcon from "Latest/Images/svg/infoIcon1.svg"
import Big from 'big.js'
import FormikInputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import { useAppSelector } from 'hooks/reduxHooks'


const ProjectionCard = ({ colorGraph, formik, handleInterestCompute, initialAmount, interestData,
    isEmployer, loading, monthlySaving, nextStep,
    planType, previousStep,
    retirementAmount,
    selectedPorfolio,
    submitGoal
}: any

) => {

    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const [projectionProfitPercentage, setprojectionProfitPercentage] = useState<any>(0)
    const { userAccountDetails } = useAppSelector(state => state.auth);

    useEffect(() => {
        if (selectedPorfolio?.returnPercentage > 0) {
            const val = new Big(selectedPorfolio?.returnPercentage / 100)
            setprojectionProfitPercentage(val)
        }

    }, [selectedPorfolio])


    return (
        <>

            <Grid item md={12} xs={12} sx={{ p: "12px 20px", borderBottom: "1px solid rgba(208, 213, 221, 0.25)" }}>

                <Typography variant='h1' color="#422E84" >Projections</Typography>

            </Grid>

            <Grid item md={8} xs={12} sx={{ p: { xs: "16px", md: "24px" }, borderRight: "1px solid rgba(208, 213, 221, 0.25)" }}>

                <ProjectionChart
                    formik={formik}
                    initialAmount={initialAmount}
                    monthlySaving={monthlySaving}
                    retirementAmount={retirementAmount}
                    planType={planType}
                    handleInterestCompute={handleInterestCompute}
                    isEmployer={isEmployer}
                    interestData={interestData}
                    height={smScreen ? 270 : 350}
                    projectionProfitPercentage={projectionProfitPercentage}
                    colorGraph={colorGraph}
                />

                <Box sx={{ p: "8px 12px", background: "#F2F4F7", borderRadius: "6px", mt: "16px" }}>
                    <Typography variant='body2' color='#667085'>Note: Values shown are approximations based on historical performance and are not indicative of future results.</Typography>
                </Box>

            </Grid>

            <Grid item md={4} xs={12} sx={{ p: { xs: "16px", md: "24px" } }}>

                <Box sx={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                    <FormikInputField
                        // required
                        FieldTouchInput={true}
                        formik={formik}
                        label={"Initial Investment"}
                        maxLength={8}
                        name={"initial_amount"}
                        placeholder='Enter here...'
                        thousandSeparator={true}
                        type='number'
                        labelChip={userAccountDetails?.kyc?.status != "ApprovalSucceeded" ? "Max 800,000" : undefined}
                        inputEnd={"PKR"}

                    />

                    <FormikInputField
                        // required
                        FieldTouchInput={true}
                        formik={formik}
                        label={<><span>Your monthly contribution</span><span className='ml-1 text-red'>(optional)</span></>}
                        maxLength={10}
                        name={"monthly_saving"}
                        placeholder='Enter here...'
                        thousandSeparator={true}
                        type='number'
                        inputEnd={"PKR"}
                    />

                    <FormikInputField
                        // required
                        FieldTouchInput={true}
                        formik={formik}
                        label={"Duration of investing"}
                        maxLength={2}
                        name={"desired_retirement_age"}
                        placeholder='Enter here...'
                        thousandSeparator={true}
                        type='number'
                        inputEnd={"Year"}
                    />

                    <Box sx={{ background: "#F9FAFB", borderRadius: "8px", height: "144px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <Typography variant='h6' color="#667085">Projection</Typography>
                        <Typography variant='h4' color={"#475467"}>
                            {CurrencyFormatterWithDecimal((formik?.values?.future_values || formik?.values?.initial_amount || 0), true, "never")}
                        </Typography>
                    </Box>
                </Box>
            </Grid>

        </>
    )
}

export default ProjectionCard