import { Box, Grid, List, ListItem, Stack, Typography, makeStyles, useTheme } from '@mui/material'
import BottomBtn from 'Latest/2ndTierKyc/bottomBtn'
import React, { useEffect, useState } from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import UploadFileCard from './uploadFileCard';
import { useAppLoading } from 'hooks/appLoading';
import { useAppSelector } from 'hooks/reduxHooks';
import { useHistory } from 'react-router';

const content = [
  "<strong>Employed / salaried:</strong> Recent salary slip/salary certificate and/or wealth statement",
  "<strong>Sole proprietor:</strong> Attested copy of registration certificate for registered concerns and sales tax registration document",
  "<strong>Student:</strong> Student card and letter of support",
  "<strong>Freelancer:</strong> Freelance contract and/or wealth statement",
  "<strong>Retired:</strong> Retirement letter / proof of retirement and/or wealth statement",
]


const Index = ({ formik, handleNext, handlePrevious }: any) => {
  const loading = useAppLoading('onPostSecondTier');
  const { userAccountDetails } = useAppSelector((state: any) => state.auth);
  const uploadedFile = userAccountDetails?.kyc?.kycDocument?.incomeProofs || []
  const { push } = useHistory();
  
  const handleSaveExit = () => {
    formik.handleSubmit()
    push("myprofile?scroll=true")
}

  return (
    <>
      <Grid item md={12} >
        <Typography variant="h5"> Upload proof of income</Typography>
      </Grid>

      <Grid container spacing={"32px"} py="20px">
        <Grid item xs={12} md={6}>
          <UploadFileCard formik={formik} fieldName={"uploadPoiImg"} loading={loading} uploadedFile={uploadedFile} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography> Provide documents that prove your income or wealth: </Typography>
          <List >
            {content.map((item, index) => (
              <ListItem key={index} >
                <FiberManualRecordIcon sx={{ color: "black", width: "8px", mr: 1 }} /> <Typography dangerouslySetInnerHTML={{ __html: item }} />
              </ListItem>
            ))}
          </List>

          In case of any questions please reach out to our <a href='/support' style={{textDecoration:"underline"}}>support team</a>.

        </Grid>

      </Grid>

      <BottomBtn handleSaveExit={handleSaveExit} handlePrevious={handlePrevious} handleNext={() => formik.handleSubmit()} loading={loading} />


    </>
  )

}

export default Index