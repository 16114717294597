export const styleAuth = {
  cardContainer: {
    p: { xs: "16px", md: "20px 40px" }, borderRadius: "8px",
    border: "0.5px solid rgba(208, 213, 221, 0.25)",
    background: "#FFF", gap: "12px",
    boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
    display: 'flex', flexDirection: 'column',

    '& .react-tel-input .form-control': {
      color: "#344054",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      height: "44px !important"
    },
    '& .react-tel-input .flag-dropdown, .react-tel-input .selected-flag': {
      borderRadius: "8px 0 0 8px"
    }
  }
}