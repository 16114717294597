import { Box, Container, Grid } from "@mui/material"
import IdCardGrid from "./idCardGrid"
import ZakatDeclaration from "../ZakatDeclaration"
import { useState } from "react";
import ShowPOICard from "Latest/2ndTierKyc/showPOICard";
import { useAppSelector } from "hooks/reduxHooks";
import WNineFormDownloadable from "./WNineFormDownloadable/index"
import { FatcaShowStatus, zakatStatus } from '../../../constants'

const Index = () => {

  const [showNicGrid, setShowNicGrid] = useState(false);
  const { userAccountDetails } = useAppSelector(state => state.auth);
  const statusEnum: any = userAccountDetails?.kyc == null ? "Default" : userAccountDetails?.kyc?.status;
  const isFatcaAndCrs: any = userAccountDetails?.fatcaCrs;
  const isWnineSubmitted = isFatcaAndCrs?.formSubmitted === "Yes";

  const findFatcaStatus = FatcaShowStatus?.find((status: any) => status?.toLowerCase() === statusEnum?.toLowerCase())

  const zakaatStatusObj = zakatStatus[userAccountDetails?.zakatAffidavit?.documentStatus]
  const isNonMuslim = zakatStatus[userAccountDetails?.zakatAffidavit?.religiousAffiliation] == 'NonMuslim'
  const optionsForRenderZakatSection = ["Verified", "Verification in progress"];

  console.log("zakaatStatusObj: ", zakaatStatusObj);
  console.log("isNonMuslim: ", isNonMuslim);

  return (
    <>
      <Grid container sx={{ p: { xs: "24px 16px", md: "30px 32px" } }}>
        <IdCardGrid setShowNicGrid={setShowNicGrid} showNicGrid={showNicGrid} />
      </Grid>

      {
        (zakaatStatusObj && optionsForRenderZakatSection.includes(zakaatStatusObj?.label))
          ?
          (
            <>
              <Box sx={{ height: "20px", width: "100%", backgroundColor: "#F8FAFB" }} />

              <Grid container sx={{ p: { xs: "24px 16px", md: "30px 32px" } }}>
                <Container disableGutters maxWidth={"lg"} sx={{ ml: 0, px: 0 }}>
                  <ZakatDeclaration />
                </Container>
              </Grid>
            </>
          )
          :
          (null)
      }

      {userAccountDetails?.kyc?.status == 'ApprovalSucceeded' &&
        <>
          <Box sx={{ height: "20px", width: "100%", backgroundColor: "#F8FAFB" }} />
          <Grid container sx={{ p: { xs: "24px 16px", md: "30px 32px" } }}>
            <Container disableGutters maxWidth="lg" sx={{ px: 0, ml: 0 }}>
              <ShowPOICard />
            </Container>
          </Grid>
        </>
      }

      {
        isFatcaAndCrs && findFatcaStatus && isWnineSubmitted &&
        <>
          <Box sx={{ height: "20px", width: "100%", backgroundColor: "#F8FAFB" }} />
          <Grid container sx={{ p: { xs: "24px 16px", md: "30px 32px" } }}>
            <Container disableGutters maxWidth={"lg"} sx={{ ml: 0, px: 0 }}>
              <WNineFormDownloadable />
            </Container>
          </Grid>
        </>
      }
    </>
  )
}

export default Index