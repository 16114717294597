import * as React from 'react';
import '../../scss/login/_Login.scss';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { FormEvent, memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { resendSmsCodeParams, verifyEmailParams, verifyWithdrawalOtp } from 'redux/actions';
import { authStyles, authStylesOTP } from 'Latest/Style/Style';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Logo from 'Latest/Images/mahanna2.png';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import OtpInput from 'react-otp-input';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import OTPInput from './OtpInput';
import { ENVIRONMENT_NAME } from 'constants/settings';
import { Env_Name } from 'constants/index';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { styleAuth } from './style';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/reduxHooks';

interface withdrawalInterface {
  handleResendWithdrawalOTP : () => void
  otpData: any
  loading: boolean,
  category: () => void,
  successModal: (success:boolean) => void
}
const WithdrawalOTPValidation: React.FC<withdrawalInterface> = ({category ,handleResendWithdrawalOTP , loading , otpData  , successModal}) => {

  const dispatch = useDispatch();

  const [otp, setOtp] = useState('');
  const [disableOTP, setDisableOTP] = useState(false);

  const defaultTimeLimit = 30;
  const [counter, setCounter] = React.useState(defaultTimeLimit); 
  const [isFlag, setIsFlag] = React.useState(false);
  const { user } = useAppSelector(state => state.auth);

  const Timer = () => {
    counter === 0 && setCounter(defaultTimeLimit); // Reset to 1 hour when it reaches 0
    setIsFlag(true);
  };

  const handleResendOTP = () => {

    if (!disableOTP) {
      setOtp('')
      handleResendWithdrawalOTP();
      setDisableOTP(true);
      setTimeout(() => {
        setDisableOTP(false);
      }, 30000);
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    otp.length === 6 && verifyWithdrawalSmsCode({
      redemptionId: otpData?.redemptionId,
      code: otp.toString()
  });
  };

  const verifyWithdrawalSmsCode = (body:any) => {
    const filterCat:any = category();

    const param = {
      id: user?.id,
      category: filterCat[0].category
  }
    const onSuccess = (message: any, response: any) => {
      console.log(message , response)
      successModal(true)
    }

    const onFail = (message: any, response: any) => {
      console.log(message , response)
    }

    dispatch(verifyWithdrawalOtp(body,param,onSuccess ,onFail ))
  }

  React.useEffect(() => {
    let timerId: any;
    if (counter > 0) {
      timerId = setTimeout(() => setCounter(counter - 1), 1000);
    }

    if (counter === 0) {
      clearInterval(timerId); // Clear the interval when the counter reaches 0
      setIsFlag(false);
    }

    return () => clearInterval(timerId); // Cleanup on component unmount or counter change
  }, [counter, isFlag]);

  const hours = Math.floor(counter / 3600);
  const minutes = Math.floor((counter % 3600) / 60);
  const seconds = counter % 60;

  const displayTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

    return (
        <>
            <Box>
            <Typography variant='h1' color='#1D2939'>Confirm your withdrawal</Typography>
            <Typography variant='body2' color={"rgba(29, 41, 57, 0.70)"} mt={"3px"}>
              Enter OTP sent to {user?.email}
            </Typography>
          </Box>

          <Box  sx={{ mt: "12px" }} width={"100%"}>
            <Box className='Form_Secondary ' id='withdrawal-otp-field' sx={{marginTop:"20px"}}>
              <Typography variant='body2' fontWeight={"500"} color={"#6e6e6e;"}>OTP</Typography>

              <Grid direction={{ xs: 'column', sm: 'row' }}  sx={{ marginTop: '7px',marginLeft:"-3px",  }}>

                <OTPInput
                  autoFocus
                  isNumberInput
                  length={6}
                  className="otpContainer"
                  inputClassName="otpInput"
                  onChangeOTP={setOtp}
                  disabled={loading}
                  style={{display:"flex" , justifyContent:"space-between" , width:"100%"}}
                />
              </Grid>

              <Stack direction={{ xs: 'row', sm: 'row', md:"row" }} spacing={3} style={{ marginTop: '32px' }} >
                <Typography sx={{ justifyContent: 'start', width: 'auto', color: '#899CA8' }} display={"flex"}>
                  {counter && counter > 0?
                  <>
                  <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>Didn’t receive the OTP?</Typography>
                    <Typography sx={authStyles.counter} variant='body2' color={"#49BDD8 !important"} fontWeight={400}>
                     Resend in {displayTime}
                  </Typography>
                  </>
                    :
                    <>
                     <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>Didn’t receive the OTP?</Typography>
                     <Typography variant='body2'>
                      <a className='ResendOtp' onClick={() => { Timer(); handleResendOTP(); }} style={{ color: "#49BDD8", fontWeight: 500 }} >
                        &nbsp;{" Resend OTP now"}
                      </a>
                    </Typography>
                    </>
                  }
                </Typography>

              </Stack>

              <Box sx={{ mt: "8px" }}>
                <LoadingBtnCustom btnTitle="Verify OTP" size={"large"} fullWidth={true} handleOnClick={handleSubmit}  /*loading={loading} *//>
              </Box>

            </Box>
          </Box>


      {/* <Container className="Login" component="main" maxWidth="md" sx={{ ...authStyles.container, ...authStyles.verifyEmail }}>
        <Card className='login_Card'>
          <Box className='otpForm'
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Link to="/">
              <img alt="Mahaana" className='img logo' src={Logo} />
            </Link>

            <h1 className='forgot_heading Login_heading'>Mobile Number Confirmation</h1>
            <Typography className="forgot_text">
              Enter OTP received on +{history.location.state}
            </Typography>
            <Box className='Form_Secondary' id='otp-field'>
              <Typography>OTP</Typography>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ marginTop: '1rem', '> div': { flexWrap: 'wrap' } }}>

                <OTPInput
                  autoFocus
                  isNumberInput
                  length={6}
                  className="otpContainer"
                  inputClassName="otpInput"
                  onChangeOTP={setOtp}
                  disabled={loading}
                />
              </Stack>
              <Stack direction={{ xs: 'row', sm: 'row' }} spacing={3} style={{ marginTop: '1rem' }}>
                <Typography sx={{ justifyContent: 'start', width: 'auto', color: '#899CA8' }}>
                  {counter ?
                    <Typography className='counter-text'>You can resend the code in</Typography>
                    :
                    <a className='ResendOtp' onClick={() => { Timer(); handleResendOTP(); }} style={{ color: "#49BDD8", textDecoration: 'underline' }} >
                      {" Resend OTP"}
                    </a>
                  }
                </Typography>

                {counter > 0 ?
                  <Typography sx={authStyles.counter}>
                    {displayTime}
                  </Typography>
                  : null}
              </Stack>
              <Box sx={{ mt: "10px" }}>
                <AuthSubmitButton
                  loading={loading}
                  isAuth={true}
                  title='Confirm'
                />
              </Box>
            </Box>
          </Box>
        </Card>
      </Container> */}
        </>
    )
}

export default WithdrawalOTPValidation