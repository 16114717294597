/* eslint-disable @typescript-eslint/no-explicit-any */
export const base64ToFile = (base64: any) => {

  const base64String = base64
  let base64Data = null;
  // Remove the data URL part from the Base64 string, if it exists

  if (base64String.startsWith("data:application/octet-stream;base64")) {
    base64Data = base64String?.replace(/^data:application\/octet-stream;base64,/, '');
  } else {
    base64Data = base64String?.replace(/^data:image\/\w+;base64,/, '');
  }

  // Convert the Base64 string to binary data
  const binaryData = atob(base64Data);

  // Create a Uint8Array from the binary data
  const byteArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([byteArray], { type: 'image/png' }); // Adjust the 'type' accordingly

  // Create a File object from the Blob (optional, you can use Blob directly)
  const file = new File([blob], 'image.png', { type: 'image/png' }); // Adjust the filename and 'type' accordingly
  return (file)
  // Use the 'file' object as needed (e.g., upload to a server, display it, etc.)
  // Here, you can pass 'file' to other components or perform any other actions.
};

