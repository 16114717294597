export const customPieChartStyle = {
    legendMainBox: {
        display: "flex", justifyContent: "center", mt: { xs: "20px", sm: 7 }, flexDirection: "column", alignItems: "center", gap: 1,
        ".legendSubBox": {
            // display: "flex", justifyContent: "space-between", 
            width: { xs: "100%", xl: "70%" }, flexWrap: "wrap",
            border: "1px solid #EAECF0", borderRadius: "6px", px: "14px", py: "16px",
            alignItems: "center",
        },
        "& h6": {
            display: "flex", alignItems: "center", gap: 1,
        },
        ".colorSubBox": {
            width: "12px", height: "12px",

        },
        ".amountBox": {
            display: 'flex', gap: 1,
        }
    },
    renderActiveShapeStyleText1: {
        fontStyle: "normal", fontWeight: 400, fontSize: "12px", lineHeight: "18px"
    },
    renderActiveShapeStyleText2: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "20px",
        fontSize: "12px"
    },

}

export const graphTabStyle = {

    tabBox: { padding: "12px 24px", borderRadius: "6px 6px 0px 0px", maxWidth: "200px", height: "48px", gap: "8px", cursor: "pointer" },

    typo: { fontFamily: 'Inter', fontStyle: "normal", fontWeight: 600, fontSize: { xs: "12px", md: "16px" }, lineHeight: "24px" },

    graphBox: {
        background: "#FFFFFF",
        border: "1px solid #EAEAEA",
        borderRadius: "0px 0px 8px 8px",
        p: 2
    },
    tooltipMainBox: {
        background: "#FFF",
        borderRadius: "8px",
        padding: { md: "8px 16px", xs: "4px 8px 4px 8px" },
        cursor: "pointer",
        border: "1px solid #D0D5DD40",
        boxShadow: "0px 1px 4px 0px #D0D5DD40"
    },
    tooltipCircle: {
        width: "8px", /* Adjust the width and height to set the circle's size */
        height: "8px",
        borderRadius: "100%",

    }

}

export const graphStyle = {
    yAxis: {
        fontSize: "14px",
        fontWeight: 400,
        // fontFamily: 'Inter,sans-serif',
        lineHeight: "20px",
        color: "#6B6F80",
        letterSpacing: "-0.25px"
    },
    xAxis: {
        // fontSize: "14px",
        // fontWeight: 400,
        // // fontFamily: 'Inter,sans-serif',
        // lineHeight: "20px",
        // color: "#6B6F80",
        // fontStyle: "normal",
        // letterSpacing: "-0.25px"


        // color: var(--Neutral- neutral - 60, #6B6F80);
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "normal",
    },
    legend: {
        // fontFamily: 'Inter,sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '-0.25px',
        lineHeight: "20px",
    }
}