import React, { useRef } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { onGetAccountSetup, onPutUserAnswers } from 'redux/actions';
import { getFormattedDate } from 'utils/getDateDMYFormat';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import FormikInputField from '../../components/Account_Fields/FormikInputFieldLatest';
import FormikRadioField from '../../components/Account_Fields/FormikRadioFieldV2';
import FormikSelectFieldWithLabel from '../../components/Account_Fields/AppSelectWithLabelLatest';
import PhoneField from 'jsx/components/Account_Fields/PhoneFieldFormikLatest';
import { FadeIn } from 'jsx/components/Shared/FadeIn';
import ConfirmationModal from 'jsx/components/AccountSetupForm/ConfirmationModal';
import LoaderWithText from 'jsx/components/Shared/LoaderWithText';
import DynamicQuestionAnswers from 'Latest/2ndTierKyc/steps/Declaration/dynamicQuestion';
import * as Yup from 'yup';
import { AnswerFieldType, CASH_FUND_ID, InputCategoryEnum, mixPannelTrackerEventName } from '../../../constants';
import '../../../scss/login/_Login.scss';
import { Container, Grid } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Loader from 'jsx/components/Shared/Loader';
import InfoIcon from '@mui/icons-material/Info';
import FormikInputFieldLifeTime from 'jsx/components/Account_Fields/FormikInputFieldLifeTime';
import { accountSetupFormValidation, additionalDetailsFormValidation } from 'validate/Screen/AccountSetupFormValidation';
import { goalMainHeadTypoStyle } from 'Latest/Goal/style';
import ButtonsRow from 'jsx/components/WizardFormModal/ButtonsRowLatest';
import { containerMain, containerMainBox, HeadingStyle, subHeadingBlueStyle, accountsetupBtnStyles, juniorHeadingStyle, topInfoWithLabelStyles } from 'Latest/Style/Main';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import infoIcon from 'Latest/Images/svg/infoIconGray.svg';
import FormikBooleanButtonsField from 'jsx/components/Shared/V1/FormikBooleanBtnFieldBoxV1';


const AdditionalDetailsForm = ({ formikVP, goalId, loading, nextStep, previousStep }: any) => {
    const dispatch = useAppDispatch();
    const { userAccountDetails } = useAppSelector(state => state.auth);


    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        dispatch(onGetAccountSetup());
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            motherName: userAccountDetails?.motherName,
            IBANnumber: userAccountDetails?.iban,
            ukn: userAccountDetails?.identityCard?.ukn,
            isStockMarketAccountOpen: "",
        },
        onSubmit: async (values: any) => {
            onSubmit()
        },
        validationSchema: additionalDetailsFormValidation
    });

    const onSubmit = () => {

        const body = {
            name: userAccountDetails?.identityCard?.name,
            fatherOrHusbandName: userAccountDetails?.identityCard?.fatherName,
            dateOfBirth: userAccountDetails?.identityCard?.dateOfBirth,
            identityCardNumber: userAccountDetails?.identityCard?.identityCardNumber,
            identityCardIssuanceDate: userAccountDetails?.identityCard?.dateOfIssue,
            identityCardExpiryDate: userAccountDetails?.identityCard?.dateOfExpiry,
            gender: userAccountDetails?.identityCard?.gender,
            countryOfStay: userAccountDetails?.identityCard?.countryOfStay,
            identityCardLifeTimeExpiry: userAccountDetails?.identityCard?.lifeTimeExpiry,
            accountType: userAccountDetails?.accountType,
            rememberUKN: userAccountDetails?.rememberUKN,

            ukn: formik?.values?.isStockMarketAccountOpen == "Yes" ? formik?.values?.ukn : "",
            iban: formik?.values?.IBANnumber,
            motherName: formik?.values?.motherName,
            isStockMarketAccountOpen: formik?.values?.isStockMarketAccountOpen

        };

        const onSuccess = () => {
            nextStep();
        }

        dispatch(onPutUserAnswers(body, onSuccess));
    };

    useEffect(() => {
        let modifiedValue: any = formik.values.IBANnumber || '';

        modifiedValue = modifiedValue.toUpperCase();
        modifiedValue = modifiedValue.replace(/\s/g, '');

        formikVP.setFieldValue('IBANnumber', modifiedValue)

        if (formik.values.IBANnumber) {
            formik.setFieldValue('IBANnumber', modifiedValue)
        }

    }, [formik.values.IBANnumber])

    const goBack = (e: any) => {
        e.preventDefault();
        if (userAccountDetails?.isAccountSetupComplete && userAccountDetails?.permanentAddress?.lines?.length > 0) {
            previousStep(undefined, 4)
        } else {
            previousStep()
        }
    }

    const goNext = (e: any) => {
        e.preventDefault();
        formik.submitForm();
    }

    const buttonHtml = () => {
        return (
            <ButtonRowV1 handleNext={goNext} handleBack={goBack} btnTitleNext={"Continue"} />
        )
    }

    const infoText = (textList: any) => {
        return (
            textList?.map((text: string) => {
                return (
                    <Box key={text} display={"flex"} alignItems={"flex-start"} pt={1}>
                        <img src={infoIcon} alt='info' />
                        <Typography pl={1} color={"rgba(29, 41, 57, 0.70)"} fontSize={{ md: "14px", xs: "14px" }}>
                            <span dangerouslySetInnerHTML={{ __html: text }}></span>
                        </Typography>
                    </Box>
                )
            })

        )
    }

    const answerOptions = [
        {
            "id": 1,
            "score": 0,
            "label": "Yes",
            "value": "Yes",
            "answer": null,
            "idRelatedQuestions": null
        },
        {
            "id": 2,
            "score": 0,
            "label": "No",
            "value": "No",
            "answer": null,
            "idRelatedQuestions": null
        }
    ]


    return (
        <>
            <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ pt: { xs: "24px", md: "40px" } }}>

                <Grid container>

                    <Grid item md={12} xs={12} sx={mainContainerV2}>

                        <Grid item md={12} xs={12}>
                            <Typography variant='h4' color={"#1D2939"} lineHeight={"normal"} textAlign={"center"}>Additional details</Typography>
                        </Grid>

                        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"40px"}>
                            <Grid item md={12} xs={12}>

                                <Grid item md={6} xs={12} mb={3}>
                                    <FormikInputField
                                        formik={formik}
                                        FieldTouchInput={true}
                                        label="What’s your mother’s name?"
                                        name='motherName'
                                        placeholder="Mother’s name"
                                    />
                                    {infoText(["This is a security question for your account."])}
                                </Grid>

                                {!userAccountDetails?.iban && <Grid item md={6} xs={12} mb={3}>
                                    <FormikInputField
                                        formik={formik}
                                        FieldTouchInput={true}
                                        label="Your bank account IBAN"
                                        name='IBANnumber'
                                        placeholder="Enter IBAN"
                                    />
                                    {infoText(["Add a bank account that’s in your name.", "For security purposes all cash withdrawals from Mahaana will be made to this bank account."])}
                                </Grid>}

                                <Grid item md={6} xs={12} mb={3}>
                                    <Box
                                        sx={{
                                            '& p.MuiTypography-root': { fontSize: { ms: "14px", xs: "14px" }, color: "#1D2939", mb: "8px" },
                                            '& label': { color: "#344054", fontSize: "14px", fontWeight: 500, lineHeight: "24px", letterSpacing: "0.4px" },
                                            '& .form-check': { width: "auto !important" }
                                        }}
                                    >
                                        <FormikBooleanButtonsField
                                            required={false}
                                            singleLine={true}
                                            FieldTouchInput={true}
                                            col={12}
                                            fieldName={"isStockMarketAccountOpen"}
                                            fields={answerOptions}
                                            formik={formik}
                                            label={"Do you have a PSX stock market account?"}
                                        />
                                    </Box>
                                </Grid>

                                {formik?.values?.isStockMarketAccountOpen == "Yes" &&
                                    <Grid item md={6} xs={12}>
                                        <FormikInputField
                                            formik={formik}
                                            FieldTouchInput={true}
                                            label="Your stock market UKN"
                                            name='ukn'
                                            placeholder="Enter UKN"
                                        />
                                        {infoText([`A UKN is a unique number for a PSX stock market account. You can contact your broker or <a style="color: #1D2939; text-decoration-line: underline;" href="https://www.nccpl.com.pk/" target="_blank">NCCPL</a> for further details.`])}
                                    </Grid>
                                }

                            </Grid>

                            {!smScreen && buttonHtml()}
                        </Box>
                    </Grid>


                    {smScreen && <Box sx={smallScreenBtnWrap}>
                        {buttonHtml()}
                    </Box>}


                </Grid>

            </Container>

        </>
    );
}

export default AdditionalDetailsForm;