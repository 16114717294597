import { useAppLoading } from 'hooks/appLoading';
import FormikInputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import AppSelectWithLabel from 'jsx/components/Shared/AppSelectWithLabel';
import { InputCategoryEnum, FieldPattern } from '../../../../constants';
import FormikRadioField from 'jsx/components/Shared/FormikRadioFieldLatest';
import FormikCheckboxField from '../../Shared/V2/FormikCheckboxFieldV2';
// import FormikCheckboxField from '../../Shared/FormikCheckboxFieldLatest';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import FormikRadioFieldCustomUI from 'jsx/components/Shared/FormikRadioFieldCustomUI';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style';

export default function DynamicQuestionAnswers({
  activeStep,
  formik,
  formikRiskProfile,
  isDisabledNestedRisprofileNextBtn,
  isDisabledRisprofileNextBtn,
  isDisabledRisprofileNextBtnError,
  nestedQuestion,
  previousStep,
  questionAnswers,
  riskProfileActiveStep,
  riskProfileUserQuestions,
  setRiskProfileActiveStep,
  totalQuestion
}: any) {
  const loading = useAppLoading('auth');

  const handleNextStep = () => {
    if (isDisabledRisprofileNextBtn || isDisabledRisprofileNextBtnError || isDisabledNestedRisprofileNextBtn) {
      formikRiskProfile.submitForm();
    }
    else {
      formikRiskProfile.submitForm();
      if (riskProfileActiveStep >= riskProfileUserQuestions?.questionAnswer?.length + 1) {
        // nextStep();
      } else {
        setRiskProfileActiveStep(riskProfileActiveStep + 1);
      }
    }
  }

  const handleType = (pattern: string) => {
    if (pattern === FieldPattern.Number) {
      return 'number';
    } else if (pattern === FieldPattern.Date) {
      return 'date';
    } else {
      return 'text';
    }
  };

  const TextBox_ = (item: any) => {
    return (
      <div style={{ maxWidth: "500px", margin: "0 auto" }}>
        <Typography variant='h4' color={"#1D2939"} textAlign={"center"} pb={3} lineHeight={"normal"}>{item.question}</Typography>
        <FormikInputField
          required
          col={12}
          // inputEnd={"PKR"}
          formik={formik}
          // label={item.question}
          maxLength={50}
          name={item.id}
          placeholder='Enter here...'
          thousandSeparator={true}
          type={handleType(item.pattern)}
          gotoNextStep={handleNextStep}
          FieldTouchInput={true}
        />
      </div>
    );
  };

  const TextBoxNested_ = (parentItem: any, nestedQuestion: any) => {
    return (
      <FormikInputField
        required
        col={12}
        formik={formik}
        maxLength={50}
        name={`${parentItem?.id}-nested`}
        placeholder='Enter here...'
      />
    );
  };

  const RadioButton_ = (item: any) => {

    return (
      <>
        <Typography variant='h4' color={"#1D2939"} textAlign={"center"} pb={3} lineHeight={"normal"}>{item.question}</Typography>
        <FormikRadioField
          required
          singleLine
          col={12}
          fieldName={item.id}
          fields={item.answer.answerOptions}
          formik={formik}
          FieldTouchInput={true}
          // label={item.question}
          styleForm={{ display: "flex", justifyContent: "center" }}
        />
      </>
    );
  };

  const RadioButtonNested_ = (parentItem: any, nestedQuestion: any) => {

    const questionId = parentItem?.id

    if (formik.errors[questionId] && formik.values[questionId] != "") {
      formik.setFieldError(questionId, '');
    }

    if (formik.values[`${questionId}-nested`] == undefined) {
      formik.setFieldValue(`${questionId}-nested`, "");
    }

    return (
      <FormikRadioFieldCustomUI
        singleLine
        col={12}
        fieldName={`${parentItem?.id}-nested`}
        fields={nestedQuestion?.answerOptions}
        formik={formik}
        label={''}
      />
    );
  };

  const CheckBox_ = (item: any) => {

    return (
      <FormikCheckboxField
        required
        fieldName={item.id}
        fields={item.answer.answerOptions}
        formik={formik}
        label={item.question}
      />
    );
  };

  const CheckboxNested_ = (parentItem: any, nestedQuestion: any) => {
    const questionId = parentItem?.id

    if (formik.errors[questionId] && formik.values[questionId] != "") {
      formik.setFieldError(questionId, '');
    }

    if (formik.values[`${questionId}-nested`] == undefined) {
        formik.setFieldValue(`${questionId}-nested`, "");
    }

    return (
      <FormikCheckboxField
        singleLine
        col={12}
        fieldName={`${parentItem?.id}-nested`}
        fields={nestedQuestion?.answerOptions}
        formik={formik}
        label={''}
      />
    );
  };

  const DropDown_ = (item: any) => {
    return (
      <AppSelectWithLabel
        required
        col={6}
        fieldName={item.id}
        fields={item.answer.answerOptions}
        formik={formik}
        label={item.question}
        loading={loading}
        onChange={(val, option) => {
          formik.setFieldValue(item.id, val);
        }}
        placeholder={`Select ${item.question}`}
      />
    );
  };

  const handlePressGoBack = () => {
    if (riskProfileActiveStep === 0) {
      previousStep(undefined, 2);
    } else {
      setRiskProfileActiveStep(riskProfileActiveStep - 1);
    }
  }


  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));

  const buttonHtml = () => {
    return (
      <ButtonRowV1 handleBack={handlePressGoBack} handleNext={handleNextStep} btnTitleNext={"Continue"} />
    )
  }

  return (
    <>
      {/* <Container disableGutters maxWidth={'md'}>
        <Grid container sx={{ mt: { md: "40px" }, border: { md: "1px solid #EAECF0" }, borderRadius: { xs: "0px", md: "12px" }, p: { xs: "16px", md: "24px" } }}>
          <Grid item md={12} xs={12} sx={{ pb: "16px" }}>
            {questionAnswers?.questionAnswer[activeStep]?.answer?.answerFieldType === InputCategoryEnum.TextBox && TextBox_(questionAnswers?.questionAnswer[activeStep])}
            {questionAnswers?.questionAnswer[activeStep]?.answer?.answerFieldType === InputCategoryEnum.RadioButton && RadioButton_(questionAnswers?.questionAnswer[activeStep])}
            {questionAnswers?.questionAnswer[activeStep]?.answer?.answerFieldType === InputCategoryEnum.CheckBox && CheckBox_(questionAnswers?.questionAnswer[activeStep])}
            {questionAnswers?.questionAnswer[activeStep]?.answer?.answerFieldType === InputCategoryEnum.DropDownSelection && DropDown_(questionAnswers?.questionAnswer[activeStep])}
          </Grid>

          <Grid item md={12} xs={12}>
            {nestedQuestion?.answerOptions && (
              <>
                {nestedQuestion.answerFieldType === InputCategoryEnum.TextBox &&
                  TextBoxNested_(
                    questionAnswers?.questionAnswer[activeStep],
                    nestedQuestion
                  )}
                {nestedQuestion.answerFieldType === InputCategoryEnum.RadioButton &&
                  RadioButtonNested_(
                    questionAnswers?.questionAnswer[activeStep],
                    nestedQuestion
                  )}
                {nestedQuestion.answerFieldType === InputCategoryEnum.CheckBox &&
                  CheckboxNested_(
                    questionAnswers?.questionAnswer[activeStep],
                    nestedQuestion
                  )}
              </>
            )}
          </Grid>

          <Grid item md={12} xs={12} sx={{ pt: "32px" }}>
            <ButtonRowV1 handleBack={handlePressGoBack} handleNext={handleNextStep} btnTitleNext={"Next"} />
          </Grid>
        </Grid>

        <Typography textAlign={"center"} sx={{ mt: 3 }} variant='h6'> QUESTION {activeStep + 1}/{totalQuestion}</Typography>
      </Container> */}

      <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ pt: { xs: "24px", md: "40px" } }}>
        <Grid container>

          <Grid item md={12} xs={12} sx={mainContainerV2}>

            <Grid item md={12} xs={12} width={"100%"}>
              <Grid item md={12} xs={12}>
                {questionAnswers?.questionAnswer[activeStep]?.answer?.answerFieldType === InputCategoryEnum.TextBox && TextBox_(questionAnswers?.questionAnswer[activeStep])}
                {questionAnswers?.questionAnswer[activeStep]?.answer?.answerFieldType === InputCategoryEnum.RadioButton && RadioButton_(questionAnswers?.questionAnswer[activeStep])}
                {questionAnswers?.questionAnswer[activeStep]?.answer?.answerFieldType === InputCategoryEnum.CheckBox && CheckBox_(questionAnswers?.questionAnswer[activeStep])}
                {questionAnswers?.questionAnswer[activeStep]?.answer?.answerFieldType === InputCategoryEnum.DropDownSelection && DropDown_(questionAnswers?.questionAnswer[activeStep])}
              </Grid>

              <Grid item md={12} xs={12}>
                {nestedQuestion?.answerOptions && (
                  <>
                    {nestedQuestion.answerFieldType === InputCategoryEnum.TextBox &&
                      TextBoxNested_(
                        questionAnswers?.questionAnswer[activeStep],
                        nestedQuestion
                      )}
                    {nestedQuestion.answerFieldType === InputCategoryEnum.RadioButton &&
                      RadioButtonNested_(
                        questionAnswers?.questionAnswer[activeStep],
                        nestedQuestion
                      )}
                    {nestedQuestion.answerFieldType === InputCategoryEnum.CheckBox &&
                      CheckboxNested_(
                        questionAnswers?.questionAnswer[activeStep],
                        nestedQuestion
                      )}
                  </>
                )}
              </Grid>

              {!smScreen && <Grid item md={12} xs={12} sx={{ justifyContent: "flex-end" }} width={"100%"} pt={3}>
                {buttonHtml()}
              </Grid>}

            </Grid>

          </Grid>


          {smScreen && <Box sx={{ ...smallScreenBtnWrap, mt: "24px", width: "100%" }}>
            {buttonHtml()}
          </Box>}

        </Grid>
      </Container>

    </>
  );
}

interface AccountSetupFormProps {
  questionAnswers: any;
  formik?: any;
  activeStep: number;
  nestedQuestion?: any;
}
