import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STOCK_DETAIL_BASE_URL } from '../../constants';

const initialState: InitialState = {
  allEquityBasketData: null
};

const name = 'equityBasket';
const equityBasketSlice = createSlice({
  initialState,
  name,
  reducers: {
    allEquityBasketDataSuccess: (state, action: PayloadAction<any>) => {
      state.allEquityBasketData = action.payload;
    }
  }
});

export const { allEquityBasketDataSuccess } = equityBasketSlice.actions;
export default equityBasketSlice.reducer;

//? APIS ACTIONS

export const onGetEquityBasketData = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: allEquityBasketDataSuccess,
    url: `${STOCK_DETAIL_BASE_URL}/api/Basket/equities`
  },
  type: 'onGetEquityBasketData'
});

export const PostStock = (data: any, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    errorToast: true,
    method: 'post',
    onSuccess,
    url: `${STOCK_DETAIL_BASE_URL}/api/Stock`,
  },
  type: 'PostStock'
});

// ? Types

interface InitialState {
  allEquityBasketData: any | null;
}
