/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';
import { Row } from 'react-bootstrap';
import UploadField from '../Shared/FileUploadField';

const NationalIdentityDetails = ({ backNICFile, formik, frontNICFile, imageError, setImageError, setUserNICBack, setUserNICFront }: CnicProps) => {

  return (
    <Row>
      <UploadField
        required
        acceptedType='image/png, image/jpeg'
        buttonTitle="ID Card Front Picture"
        col={6}
        fieldName="UserNICFront"
        formik={formik}
        imageError={imageError}
        inputFiles={frontNICFile}
        maxFiles={1}
        setImageError={setImageError}
        setImages={setUserNICFront}
      />
      <UploadField
        required
        acceptedType='image/png, image/jpeg'
        buttonTitle="ID Card Back Picture"
        col={6}
        fieldName="UserNICBack"
        formik={formik}
        imageError={imageError}
        inputFiles={backNICFile}
        maxFiles={1}
        setImageError={setImageError}
        setImages={setUserNICBack}
      />
    </Row>
  );
};

export default memo(NationalIdentityDetails);

interface CnicProps {
  backNICFile: any;
  formik: any;
  frontNICFile: any;
  setUserNICFront: any;
  setUserNICBack: any;
  imageError: boolean;
  setImageError: (isImage: boolean) => void;
}
