import axios, { AxiosError } from 'axios';
import { ApiMessage } from 'constants/Error/errorMessage';
import { toast } from 'react-toastify';
import arrayBufferTostring from 'utils/arrayBufferTostring';

export const ErrorsToast = (error: Error | AxiosError , responseType:any) => {
    const custom = null;
    if (!axios.isAxiosError(error)) return custom;

    if (error.response) {
        // server throw status > 400
        if (error.response.status >= 400 && error.response.status < 500) {

            const err = error.response
            let stringOfError = err?.data;
            if (responseType === "arraybuffer") {
                const stringError = arrayBufferTostring(err?.data);
                stringOfError = stringError.replace(/"/g, '');
            }
            
            const arrayOfError = stringOfError?.split(/[\s,]+/);
            arrayOfError?.map((i: any) => {
                const message = ApiMessage[i] || 'Something went wrong'
                return (toast.error(message, { theme: "colored" }))
            })

        }
        return;
    }

};

