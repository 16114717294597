import { memo, useCallback, useEffect, useState } from 'react';

import FundsOverviewComponent from 'jsx/components/FundsOverview';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useAppLoading } from 'hooks/appLoading';
import {
  onGetFunds,
  onGetFundsCategories,
  onGetFundsRisks,
  onGetFundsCompanies,
  onGetFundsTypes,
  Fund,
  // onClearDashboardNotifications
} from 'redux/actions';
import { Button, Form, Modal, Col } from 'react-bootstrap';
import Loader from 'jsx/components/Shared/Loader';
import { toast, ToastContainer } from 'react-toastify';

const FundsOverview = () => {
  const isLoading = useAppLoading('funds');
  const isDashboardLoading = useAppLoading('dashboard');
  const { filters, funds } = useAppSelector(state => state.funds);
  const [selectedFund, setSelectedFund] = useState<Fund>();
  const [total, setTotal] = useState(0);
  const insurance = localStorage.getItem("InvestmentAmount")
  const [investmentAmount] = useState(Math.ceil(Number(insurance)))

  const handleFundInvestment = () => {
    if (selectedFund) {
      const data = {
        bank_funds_allocation_id: selectedFund?.id,
        initial_amount: total
      };
      const investedFund = [
        ...funds
          .filter((el: Fund) => el.id === selectedFund.id)
          .map(elem => ({
            amount: total,
            bank_fund_id: elem.id,
            name: elem.name,
            symbol: elem.fund_symbol
          }))
      ];
    }
  };

  return (
    <div style={{ marginTop: '3%' }}>
      {/* <ToastContainer /> */}
      <Modal
        centered
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => setSelectedFund(undefined)}
        show={!!selectedFund}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Your Investment Amount
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col lg={12}>
            <div className='form-group'>
              <label className='mb-1 '>
                <strong>Invest the following amount:</strong>
              </label>
              <div style={{ display: 'flex', width: '30%' }}>
                <input 
                  autoFocus={true}
                  className='form-control'
                  disabled={true}
                  //onChange={e => setTotal(parseInt(e.target.value))}
                  type={'number'}
                  value={total}
                />
              </div>
            </div>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <div className='custom-goal-footer'>
            <Form.Check
              checked={true}
              className='custom-font-size'
              label='Agree Terms & Condition'
              type='checkbox'
            />
            <Button
              disabled={isLoading || isDashboardLoading}
              onClick={handleFundInvestment}
            >
              {isLoading || isDashboardLoading ? <Loader /> : 'OK'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <FundsOverviewComponent
        filters={filters}
        funds={funds}
        isLoading={isLoading}
        setSelectedFund={setSelectedFund}
      />
    </div>
  );
};

export default memo(FundsOverview);
