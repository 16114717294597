import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useEffect, ReactElement } from 'react';

const ScrollToTop = ({ children, location }: ScrollerProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{children}</>;
};
export default withRouter(ScrollToTop);

interface ScrollerProps extends RouteComponentProps {
  children: ReactElement;
}
