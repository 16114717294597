import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import mahaanaBoxLogo from "Latest/Images/svg/mahaanaBoxLogo.svg"
import { HeaderSectionStyles } from '../../CashFundAssetAllocation/Style/style'
import backIcon from 'Latest/Images/backArrowBlack.png';
import { GoalNameEnum } from '../../../../../constants';
import { useAppSelector } from 'hooks/reduxHooks';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';

const HeaderSec = ({ formik, handlePressGoBack, handlePressNext, smScreen }: any) => {


    const GoalNameArr = Object.values(GoalNameEnum);

    const GoalName = formik?.values?.goalId == 4 ? formik?.values?.goal_name : GoalNameArr[formik?.values?.goalId]

    return (
        <>

            <Box sx={{
                // position: "sticky", top: 0, zIndex: 100,
                p: { xs: "16px", md: "16px 0px" }, 
                background: "#fff"
                , borderBottom: "1px solid #EAECF0"
            }}>
                <Container maxWidth={'xl'} disableGutters={smScreen}>

                    <Grid item md={12}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", }}>

                            <Box sx={{ width: "100%", display: "flex", flex: 1, alignItems: { xs: "flex-start", md: "flex-start", lg: "center" }, gap: "10px", flexDirection: { xs: "column", md: "column", lg: "row" } }}>
                                <Typography variant={"h1"} color={"#2C2C2C"} >Recommended portfolio </Typography>
                                <Typography variant="h6" color={"#2E90FA"} sx={{ p: "2px 10px", borderRadius: "16px", background: "#EFF8FF" }}>{"Goal: " + GoalName}</Typography>
                            </Box>

                            {/* {!smScreen && <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                                <ButtonRowV1 handleBack={handlePressGoBack} handleNext={handlePressNext} />
                            </Box>} */}

                        </Box>
                    </Grid>

                </Container>
            </Box>


            {/* <Box sx={{
                borderBottom: "2px solid rgb(243, 244, 246)",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 100
            }} >
                <Container maxWidth={'xl'}>
                    <Box sx={{ py: { xs: "16px", md: "40px" }, display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: { xs: "wrap", md: "no-wrap" } }}>
                        <Box sx={{ display: 'flex', alignItems: "center", gap: "15px", width: { xs: "100%", md: "auto" } }}>
                            <img src={mahaanaBoxLogo} />
                            <Typography variant="h4" sx={{ fontSize: { md: '31px!important', sm: '25px!important', xs: '21px!important' } }} >
                                Your Goal: {GoalName}
                            </Typography>
                        </Box>

                        <Box sx={{ display: "flex", width: { xs: "100%", md: "auto" }, justifyContent: "flex-end" }}>
                            <Button sx={HeaderSectionStyles.backWrapperStyle} onClick={handlePressGoBack}>
                                <img src={backIcon} alt="backIcon" />
                                <Typography variant='body1'>Back</Typography>
                            </Button>
                            <Button sx={HeaderSectionStyles.nextWrapperStyle} onClick={handlePressNext}><Typography variant='subtitle1' sx={{ cursor: "pointer" }}>Next</Typography></Button>

                        </Box>
                    </Box>
                </Container>
            </Box> */}
        </>
    )
}

export default HeaderSec