export const ManipulateTransactionList = (message: any) => {
    const transactionDates: any = {};
    const validTypes = ["CashDividend", "Bonus", "DividendReInvest"];

    message?.forEach((transaction: any) => {
        // Extract the date part from the transactionDate string
        const datePart = transaction?.transactionDate.split('T')[0];
        const { transactionType } = transaction;

        if (!validTypes.includes(transactionType)) {
            return; // Skip transaction types that are not in validTypes
        }
        if (!transactionDates[datePart]) {
            transactionDates[datePart] = new Set();
        }

        transactionDates[datePart].add(transactionType);
    });

    const matchingDates = [];

    for (const date in transactionDates) {
        const typesArray = Array.from(transactionDates[date]);
        const hasOnlyBonus = typesArray?.every(type => type === "Bonus");
        if (transactionDates[date].has("CashDividend") || transactionDates[date].has("Bonus") || transactionDates[date].has("DividendReInvest")) {
            matchingDates.push({
                date,
                types: Array.from(transactionDates[date]),
                hasOnlyBonus
            });
        }
    }

    const matchingDateMap = new Map();

    matchingDates?.forEach(item => {
        matchingDateMap?.set(item?.date, item);
    });

    const newTransactions: any = [];

    message?.forEach((transaction: any) => {
        const datePart = transaction?.transactionDate.split('T')[0];

        // Check if the transaction is a "Bonus" and if hasOnlyBonus is true
        if (matchingDateMap.has(datePart) && matchingDateMap.get(datePart).hasOnlyBonus) {
            if (transaction.transactionType === "Bonus") {
                // Change the transaction type to "CashDividend"
                newTransactions.push({
                    ...transaction,
                    transactionType: "DividendReInvest",
                    matchingDateObj: matchingDateMap.get(datePart)
                });

                // Insert the new entity after the "CashDividend" transaction
                newTransactions.push({
                    ...transaction,
                    transactionType: "Dividend", // The new entity name
                    matchingDateObj: matchingDateMap.get(datePart)
                });
            } else {
                // Add the transaction to the new transactions list as is
                newTransactions.push(transaction);
            }
        } else {
            // Add the transaction to the new transactions list as is
            newTransactions.push(transaction);
        }
    });

    return newTransactions;
}
