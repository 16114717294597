import * as React from 'react';
import '../../scss/login/_Login.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import { memo } from 'react';
import { useFormik } from 'formik';
import { ForgotParams } from 'redux/actions';
import { forgot } from 'validate/auth/forgot';
import { authStyles } from 'Latest/Style/Style';
import { Card, Box, Container, Typography, useTheme, useMediaQuery } from '@mui/material';
import Logo from 'Latest/Images/mahanna2.png';
import AuthInputField from '../components/MUI_components/FormikTextField';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import { styleAuth } from './style';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';


const theme = createTheme();

const ForgotPassword = ({
  forgotPasswordHandle,
  loading,
}: ForgotProps) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formik = useFormik({
    initialValues: { email: '', ...(token && { token }) },
    onSubmit: (values: ForgotParams) => {
      forgotPasswordHandle(values);
      formik.resetForm()
    },
    validationSchema: forgot
  });

  return (
    <>
      <Container disableGutters={smScreen} sx={{ mt: { xs: "24px", md: "40px" }, maxWidth: "500px!important" }}>
        <Box sx={styleAuth.cardContainer}>

          <Typography variant='h1' color='#1D2939' sx={{ pb: "12px" }}>Send reset password link</Typography>

          <AuthInputField
            // required
            disabled={loading}
            formik={formik}
            label='Email'
            name='email'
            placeholder='hello@example.com'
          />

          <Box sx={{ pt: "12px" }}>
            <LoadingBtnCustom btnTitle="Send reset link" size={"large"} fullWidth={true} handleOnClick={formik.handleSubmit} loading={loading} />
          </Box>

        </Box>

      </Container>

      {/* <Container className="Login" component="main" maxWidth="sm" sx={authStyles.container}>
        <Card className='login_Card'>
          <Box className='Form_Main'
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Link to="/">
              <img alt="Mahaana" className='img logo' src={Logo} />
            </Link>
            <h1 className='forgot_heading Login_heading'>Send reset password link</h1>
            <Box className='Form_Secondary'>
              <AuthInputField
                required
                disabled={loading}
                formik={formik}
                label='Email Address'
                name='email'
                placeholder='hello@example.com'
              />
              <AuthSubmitButton loading={loading} title='Send'
              />
            </Box>
          </Box>
        </Card>
      </Container> */}
    </>

  );
};

export default memo(ForgotPassword);

interface ForgotProps {
  forgotPasswordHandle: (data: ForgotParams) => void;
  loading: boolean;
  // openInvite: () => void;
}
