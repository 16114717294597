import * as React from 'react';
import '../../scss/login/_Login.scss';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { FormEvent, memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { resendSmsCodeParams, verifyEmailParams } from 'redux/actions';
import { authStyles, authStylesOTP } from 'Latest/Style/Style';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Logo from 'Latest/Images/mahanna2.png';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import OtpInput from 'react-otp-input';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import OTPInput from './OtpInput';
import { ENVIRONMENT_NAME } from 'constants/settings';
import { AuthMsgs, Env_Name } from 'constants/index';
import { Button, useMediaQuery } from '@mui/material';
import { styleAuth } from './style';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

const ValidateOTP = ({
  loading,
  otpUserId,
  phoneNo,
  resendSmsCode,
  userId,
  verifySmsCode
}: ValidateOTPProps) => {

  const [otp, setOtp] = useState('');
  const [disableOTP, setDisableOTP] = useState(false);
  const [mobValidateWTP, setMobValidateWTP] = useState<string>('');
  const theme = useTheme();

  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    otp.length === 6 && verifySmsCode({
      userId,
      token: otp.toString()
    });
  };
  const history = useHistory()
  const handleResendOTP = (messageMethod: string) => {
    if (messageMethod.toLowerCase() === "sms".toLowerCase()) {
      AuthMsgs.resendOtpWhatsapp = "OTP is sent to you via SMS";
    } else {
      AuthMsgs.resendOtpWhatsapp = "OTP is sent to you via WhatsApp";
    }

    if (!disableOTP) {
      setOtp('')
      resendSmsCode({ userId, messageMethod });
      setDisableOTP(true);
      setTimeout(() => {
        setDisableOTP(false);
      }, 30000);
    }
  };

  const defaultTimeLimit = 30;

  const [counter, setCounter] = React.useState(defaultTimeLimit); // 1 hour in seconds
  const [isFlag, setIsFlag] = React.useState(false);

  const Timer = () => {
    counter === 0 && setCounter(defaultTimeLimit); // Reset to 1 hour when it reaches 0
    setIsFlag(true);
  };

  React.useEffect(() => {
    let timerId: any;
    if (counter > 0) {
      timerId = setTimeout(() => setCounter(counter - 1), 1000);
    }

    if (counter === 0) {
      clearInterval(timerId); // Clear the interval when the counter reaches 0
      setIsFlag(false);
    }

    return () => clearInterval(timerId); // Cleanup on component unmount or counter change
  }, [counter, isFlag]);

  const hours = Math.floor(counter / 3600);
  const minutes = Math.floor((counter % 3600) / 60);
  const seconds = counter % 60;

  const displayTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  const phoneNumber = formatPhoneNumber(history.location.state);
  console.log("this comp")


  const verifyMobileNumber = (mobNum: any) => {
    if (mobNum.startsWith('+92') || mobNum.startsWith('92')) {
      return true
    } else {
      return false
    }

  }

  const handleChange = (name: string) => {
    setMobValidateWTP(name)
  }

  return (
    <>
      <Container sx={{ mt: { xs: "24px", md: "40px" }, ...authStylesOTP, ...authStyles.container, maxWidth: "540px !important" }} disableGutters={smScreen} >
        <Box sx={styleAuth.cardContainer} >
          <Box sx={{ display: 'flex', alignItems: "center", gap: 1, pb: "4px", cursor: "pointer" }} component={Link} to={"/auth/login"}>
            <ArrowBackIcon sx={{ fontSize: "18px", color: "#422E84" }} />
            <Typography variant='body2' color={"#422E84"}>Back</Typography>
          </Box>

          <Box>
            <Typography variant='h1' color='#1D2939'>Mobile number confirmation</Typography>
            <Typography variant='body2' color={"rgba(29, 41, 57, 0.70)"} >
              Enter OTP sent to {verifyMobileNumber(phoneNumber) ? `${phoneNumber} via ${!mobValidateWTP ? "Sms" : mobValidateWTP}` : `${phoneNumber} via Whatsapp`}
            </Typography>
          </Box>

          <Box className={"otpForm"} sx={{ mt: "12px" }}>
            <Box className='Form_Secondary' id='otp-field'>
              <Typography>OTP</Typography>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ marginTop: '1rem', '> div': { flexWrap: 'wrap' } }}>

                <OTPInput
                  autoFocus
                  isNumberInput
                  length={6}
                  className="otpContainer"
                  inputClassName="otpInput"
                  onChangeOTP={setOtp}
                  disabled={loading}
                />
              </Stack>
              <Stack direction={{ xs: 'row', sm: 'row', md: "row" }} spacing={3} style={{ marginTop: '32px' }} >
                <Typography sx={{ justifyContent: 'start', width: 'auto', color: '#899CA8' }} display={"flex"} flexWrap={"wrap"}>
                  {counter && counter > 0 ?
                    <>
                      <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>You can resend the code in</Typography>
                      <Typography sx={authStyles.counter} variant='body2' color={"#49BDD8 !important"} fontWeight={400}>
                        {displayTime}
                      </Typography>
                    </>
                    :
                    <>
                      <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>Didn’t receive the OTP? </Typography>
                      <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}> &nbsp;Resend via&nbsp;</Typography>
                      {
                        verifyMobileNumber(history.location.state) ? (
                          <>
                            <a className='ResendOtp' onClick={() => { Timer(); handleResendOTP("sms"); handleChange("Sms") }} style={{ color: "#49BDD8", fontWeight: 500 }} >
                              {" SMS "}
                            </a>
                            <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>&nbsp;or&nbsp;</Typography>
                            <a className='ResendOtp' onClick={() => { Timer(); handleResendOTP("WhatsappMesage"); handleChange("WhatsApp") }} style={{ color: "#49BDD8", fontWeight: 500 }} >
                              {" WhatsApp "}
                            </a>
                          </>
                        ) : (
                          <a className='ResendOtp' onClick={() => { Timer(); handleResendOTP("WhatsappMesage"); }} style={{ color: "#49BDD8", fontWeight: 500 }} >
                            {" WhatsApp "}
                          </a>
                        )
                      }


                    </>
                  }
                </Typography>

              </Stack>

              <Box sx={{ mt: "8px" }}>
                <LoadingBtnCustom btnTitle="Verify OTP" size={"large"} fullWidth={true} handleOnClick={handleSubmit} loading={loading} />
              </Box>

            </Box>
          </Box>

        </Box>
      </Container>


      {/* <Container className="Login" component="main" maxWidth="md" sx={{ ...authStyles.container, ...authStyles.verifyEmail }}>
        <Card className='login_Card'>
          <Box className='otpForm'
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Link to="/">
              <img alt="Mahaana" className='img logo' src={Logo} />
            </Link>

            <h1 className='forgot_heading Login_heading'>Mobile Number Confirmation</h1>
            <Typography className="forgot_text">
              Enter OTP received on +{history.location.state}
            </Typography>
            <Box className='Form_Secondary' id='otp-field'>
              <Typography>OTP</Typography>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ marginTop: '1rem', '> div': { flexWrap: 'wrap' } }}>

                <OTPInput
                  autoFocus
                  isNumberInput
                  length={6}
                  className="otpContainer"
                  inputClassName="otpInput"
                  onChangeOTP={setOtp}
                  disabled={loading}
                />
              </Stack>
              <Stack direction={{ xs: 'row', sm: 'row' }} spacing={3} style={{ marginTop: '1rem' }}>
                <Typography sx={{ justifyContent: 'start', width: 'auto', color: '#899CA8' }}>
                  {counter ?
                    <Typography className='counter-text'>You can resend the code in</Typography>
                    :
                    <a className='ResendOtp' onClick={() => { Timer(); handleResendOTP(); }} style={{ color: "#49BDD8", textDecoration: 'underline' }} >
                      {" Resend OTP"}
                    </a>
                  }
                </Typography>

                {counter > 0 ?
                  <Typography sx={authStyles.counter}>
                    {displayTime}
                  </Typography>
                  : null}
              </Stack>
              <Box sx={{ mt: "10px" }}>
                <AuthSubmitButton
                  loading={loading}
                  isAuth={true}
                  title='Confirm'
                />
              </Box>
            </Box>
          </Box>
        </Card>
      </Container> */}

    </>
  );
};

export default memo(ValidateOTP);

interface ValidateOTPProps {
  resendSmsCode: (data: resendSmsCodeParams) => void;
  loading: boolean;
  otpUserId: any;
  userId: string;
  phoneNo: string;
  verifySmsCode: (data: verifyEmailParams) => void;
}
