import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import GreenTick from "../../../Latest/Images/greenTick.png"
import { ModalStyle } from '../Style/modalStyle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BlockIcon from '@mui/icons-material/Block';
import { links } from 'static/links';

import { useHistory } from 'react-router-dom';
import { WrongCnicModalStyles } from 'Latest/Style/Main';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '400px', sm: '400px', xs: '90%' },
    bgcolor: '#FFFFFF',
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    py: 4,
    borderRadius: "12px"
};

export default function ErrModal({ Content, btnTitle = "Close", customOnClose, handleClose, handleConfirm, handleOpen, link, nextBtnTitle, open }: any) {
    const { push } = useHistory();
    const [modalContent, setModalContent] = React.useState<any>('');

    React.useEffect(() => {
        setModalContent(Content)
    }, [Content])

    const handleCloseBtn = () => {
        handleClose()
        customOnClose && customOnClose()
        if (link) {
            push(links.home)
        }
    }

    const buttonHtml = () => {
        const styles = {
            // padding: "11px 24px",
            // fontSize: "1rem",
            minWidth: "auto",
            width: {md: "auto", xs: "100%"}
        }
        return (
            <ButtonRowV1 nextBtnStyle={styles} handleNext={handleCloseBtn} btnTitleNext="Close" isShowBack={false} />
        )
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseBtn}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={WrongCnicModalStyles.containerV2}>

                    {/* <Box sx={{ position: "absolute", right: 11, top: 11 }}>
                        <IconButton aria-label="upload picture" component="label">
                            <CloseIcon onClick={handleCloseBtn} sx={{ color: '#667085' }} />
                        </IconButton>
                    </Box> */}

                    <Grid xs={12} sx={WrongCnicModalStyles.wrapperV2}>
                        {modalContent}

                        <Grid item xs={12} px={2}>
                            {buttonHtml()}
                        </Grid>

                        {nextBtnTitle && <Grid item xs={12}>
                            <Button fullWidth sx={ModalStyle.nextBtn} onClick={handleCloseBtn}>{nextBtnTitle}</Button>
                        </Grid>}

                    </Grid>
                </Box>
            </Modal>
        </div >
    );
}