import { Box, Divider, Grid, Typography } from "@mui/material";
import { useAppSelector } from "hooks/reduxHooks";

const Index = () => {

    const { articlesData } = useAppSelector((state: any) => state.sharedData);
    const latestNews: any = articlesData?.news;
    console.log('News: ', latestNews);

    const elipsesStyle = {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    }

    if (!latestNews || !latestNews.length) {
        return null
    }

    return (
        <Grid>
            <Box sx={{ backgroundColor: "#FFF6EF", borderRadius: "8px" }} p={2}>
                <Typography variant="h4">Today’s News</Typography>
                {
                    latestNews?.map((blog: any, ind: any) => {
                        return (
                            <Box
                                key={ind}
                                sx={{ marginTop: "20px", cursor: "pointer" }}
                                onClick={() => window.open(blog?.link, '_blank')}
                            >
                                <Typography variant="body1" sx={{ fontWeight: "400" }}>{blog?.category.toUpperCase()}</Typography>
                                <Typography variant="body1" sx={elipsesStyle} marginTop={"10px"}>
                                    <a
                                        // href={blog?.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ color: "#443087", fontWeight: "500", cursor: "pointer" }}
                                    >
                                        {blog?.name}
                                    </a>
                                </Typography>
                                <Typography variant="body2" sx={elipsesStyle} paddingBottom={"10px"}>{blog?.body}</Typography>
                                {
                                    ind + 1 < latestNews.length &&
                                    <Divider variant="middle" sx={{ paddingTop: "10px" }} />
                                }

                            </Box>
                        )
                    })
                }
            </Box>
        </Grid>
    )
}

export default Index;