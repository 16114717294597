import axios from "axios";
import DeleteModal from "jsx/components/DeleteModal/DeleteModal";
import EditModal from "jsx/components/EditModal/EditModal";
import TableHead from "jsx/components/FundsOverview/TableHead";
import Loader from "jsx/components/Shared/Loader";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row, Table, } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { LocalUrl } from "redux/middlewares/api";
import { links } from 'static/links';
import './index.css'

interface answerOptions {
    label: "",
    id: "0",
    value: "",
    score: "",
    answerOptions: [
        {
            label: "",
            id: "0",
            value: "",
            score: "",
        }
    ] | null
}

const answerOptionsInitialValue = [
    {
        label: "",
        id: "0",
        value: "",
        score: "",
        answerOptions: null,
    }
];

const inputSubArr = [
    {
        label: "",
        id: "0",
        value: "",
        // score: ""
    }
]
const AdminView = () => {
    const history = useHistory();

    const [answerOptions, setAnswerOptions]: any = useState(answerOptionsInitialValue);
    const [subAnswerOptions, setSubAnswerOptions] = useState(inputSubArr);
    const [getAnswers, setGetAnswers] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [score, setScore] = useState('');
    const [questionID, setQuestionID] = useState('');
    const [category, setCategory] = useState<any>('');
    const [profileCategory, setProfileCategory] = useState<any>('');
    const [viewModel, setViewModel] = useState<any>([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [flag, setFlag] = useState(0);

    const addInput = () => {
        setAnswerOptions((a: any) => {
            return [
                ...a,
                {
                    label: "",
                    id: "0",
                    value: "",
                    score: "",
                    answerOptions: null
                }
            ];
        });
    };
    const addSubInput = (index: number) => {
        const answerOptions_ = [...answerOptions];
        const item = answerOptions_[index];
        if (!item["answerOptions"]) {
            item["answerOptions"] = []
        }

        item["answerOptions"]?.push(
            {
                label: "",
                id: "0",
                value: "",
                score: "",
                answerOptions: null
            }
        )
        setAnswerOptions(answerOptions_);
    };
    const handleInputCategory = (e: any) => {
        setCategory(e.value);
    }
    const handleProfileCategory = (e: any) => {
        setProfileCategory(e.value);
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        const index = e.target.id;
        setAnswerOptions((s: any) => {
            const newArr = s.slice();
            newArr[index].value = e.target.value;
            newArr[index].label = e.target.value;
            newArr[index].id = '0';
            return newArr;
        });
    };
    const handleScore = (e: any) => {
        e.preventDefault();
        const index = e.target.id;
        setAnswerOptions((s: any) => {
            const newArr = s.slice();
            newArr[index].score = e.target.value;
            return newArr;
        });
    };
    const AddSubQustions = (e: any) => {
        // e.preventDefault();
        const index = e;
        const demo = {
            id: 0,
            label: 'demo',
            value: 'demoo'
        }
        setAnswerOptions((s: any) => {
            const newArr = s.slice();
            newArr[index].answerOptions = demo;
            // newArr.push(demo)
            return newArr;
        });
    }

    const fetchData = async () => {
        const fieldData = await axios.get(LocalUrl + "QuestionAnswer/ViewModel")
        setViewModel(fieldData.data);
        const resp = await axios.get(LocalUrl + 'QuestionAnswer')
        setGetAnswers(resp.data)
    }
    useEffect(() => {
        fetchData();
        setFlag(0)
    }, [flag])
    const ModalOpen = (id: any) => {
        setShow(true)
        setQuestionID(id)
    }
    const DeleteModalOpen = (id: any) => {
        setDeleteModal(true)
        setQuestionID(id)
    }
    const submitHandler = async () => {
        if (category === 1) {
            const body = {
                "question": question,
                "answer": {
                    "answerOptions": [
                        {
                            "score": Number(score),
                        }
                    ],
                    "answerFieldType": category.toString()
                },
                "riskProfileCategory": profileCategory
            };
            try {
                await axios.post(LocalUrl + "QuestionAnswer", body);
                toast.success("Question Addesd Succefully");
                history.push(links.adminForm)
            } catch (error) {
                toast.error('An Error Occured',{theme: "colored"})
            }
        }
        else {
            const body = {
                "question": question,
                "answer": {
                    answerOptions,
                    "answerFieldType": category.toString()
                },
                "riskProfileCategory": profileCategory
            };
            try {

                await axios.post(LocalUrl + "QuestionAnswer", body);
                // setTimeout(() => {
                //     window.location.reload();
                //     alert('triggered')
                // }, 500);
                toast.success("Question Addesd Succefully");
            } catch (error) {

                toast.error('An Error Occured',{theme: "colored"})
            }
        }
    }
    const [counter, setCounter] = useState(0);
    const handleClick = () => {
        setCounter(counter + 1);
    };
    const handleOnChange = (e: any, index1: number, index2: number) => {
        const tempArray = [...answerOptions]
        const tempArraykey = answerOptions[index1].answerOptions[index2];
        tempArraykey[(e.target.name)] = e.target.value;
        setAnswerOptions(tempArray);
    };

    return (
        <Container fluid >
            <h1 style={{ textAlign: 'center' }}>
                VIEW  RISK PROFILE
            </h1>
            {
                show === true &&
                <EditModal DropDownMoadl={viewModel} flag={setFlag} id={questionID} openModal={show} setOpenModal={setShow} />
            }
            {
                deleteModal === true &&
                <DeleteModal id={questionID} openModal={deleteModal} setOpenModal={setDeleteModal} />
            }
            <Card>
                <Card.Body>
                    <Row >
                        <Col md="12" xs="12">

                            <Table bordered hover >

                                {getAnswers.length > 0 ?
                                    getAnswers.map((val: any, MainIndex: any) => {
                                        return (
                                            <>
                                                <div style={{ textAlign: 'center', backgroundColor: '' }}>
                                                </div>
                                                <thead key={MainIndex} style={{ backgroundColor: '' }}>
                                                    <tr style={{ textAlign: 'center' }}>

                                                    </tr>
                                                    <tr style={{}}>
                                                        <th>#</th>
                                                        <th>Questions</th>
                                                        <th>Answer Type</th>
                                                        <th>Answer Label</th>
                                                        <th>Answer Score</th>
                                                        <th>
                                                            <strong>  {val.riskProfileCategory}</strong>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {
                                                        val.questionAnswer.map((question: any, id: any) => {
                                                            return (
                                                                <tr key={id} style={{}}>
                                                                    <td>
                                                                        {id + 1}
                                                                    </td>
                                                                    <td>
                                                                        {question.question}
                                                                    </td>
                                                                    <td>
                                                                        {question?.answer?.answerFieldType}
                                                                    </td>
                                                                    <td>
                                                                        {question?.answer?.answerOptions?.map(
                                                                            (labels: any, ids: any) => {
                                                                                const tempArray: any = [labels];

                                                                                return (
                                                                                    <div key={ids}>

                                                                                        {ids + 1}-
                                                                                        {labels.label == null ? "None" : labels.label}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {question?.answer?.answerOptions?.map(
                                                                            (labels: any, ids: any) => {
                                                                                return (
                                                                                    <div key={ids} style={{ textAlign: 'center' }}>

                                                                                        {labels?.score}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <svg className="bi bi-three-dots-vertical" fill="currentColor" height="16" onClick={() => ModalOpen(question.id)} viewBox="0 0 16 16" width="26" xmlns="http://www.w3.org/2000/svg" >
                                                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                                <path d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                            </svg>
                                                                            <svg className="bi bi-three-dots-vertical" height="16" onClick={() => DeleteModalOpen(question.id)} viewBox="0 0 16 16" width="26" xmlns="http://www.w3.org/2000/svg" >
                                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                            </svg>
                                                                        </div>
                                                                        <div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </>
                                        )
                                    }
                                    )
                                    :
                                    <h1 style={{ textAlign: 'center' }}>
                                        No Questions Added
                                        {/* <Loader /> */}
                                    </h1>
                                }

                            </Table>
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
        </Container >
        // <div>
        //     <Form.Group className="mb-3">
        //         <Form.Check disabled label="Can't check this" type="checkbox" />
        //     </Form.Group>
        // </div>
    )
}
export default AdminView;
export type Field = { label: string; value: string };
