import { Box, Grid, Typography, Modal } from '@mui/material'
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import FormikInputField from 'jsx/components/Account_Fields/FormikInputFieldLatest';
import ErrorIcon from 'Latest/Images/svg/ErrorV2.svg';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    boxShadow: "0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)",
    p: "24px",
    borderRadius: "8px",
    maxWidth: '100%',
    width: { xs: "95%", sm: "500px", md: "500px" },

    '& img': { height: 'auto', width: 'auto', maxWidth: '100%' },
};

export const EditGoalModal = ({ formik, handleClose, handleConfirm, loadingConfirmBtn = false, open }: any) => {

    const customStyles = {
        display: "flex", flexDirection: "column", gap: "24px",
        '& h5': { fontSize: "18px", color: "#101828", lineHeight: "28px" },
        '& .col-md-12 p': { color: "#344054" }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...modalStyle, ...customStyles }}>
                    <Typography variant='h5' color={"#101828"}>Update goal name</Typography>

                    <Grid item md={12} xs={12}>
                        <FormikInputField
                            formik={formik}
                            FieldTouchInput={true}
                            label="Enter new goal name"
                            name='goalName'
                            placeholder="Enter name"
                        />
                    </Grid>

                    <ButtonRowV1
                        handleBack={handleClose} handleNext={handleConfirm}
                        loadingNextBtn={loadingConfirmBtn} btnTitleNext={"Save"}
                        btnTitleBack="Cancel"
                    />
                </Box>
            </Modal>
        </div>
    )
}


export const DltGoalModal = ({ backTitle = "Cancel", formik, handleClose, handleConfirm, loadingConfirmBtn = false, nextTitle, open, showNextBtn, subTitle, title }: any) => {

    const customModalStyles = { display: "flex", gap: "24px", alignItems: "flex-start",flexWrap:{md:"nowrap" , sm:"nowrap",xs:"wrap"} }

    const customStyles = {
        display: "flex", flexDirection: "column", gap: "24px", width: "100%",
        '& .col-md-12 p': { color: "#344054" }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...modalStyle, ...customModalStyles }}>
                    <img src={ErrorIcon} alt='error' />
                    <Box sx={{ ...customStyles }}>

                        <Box>
                            {title && <Typography variant='alertTitle' color={"#101828"}>{title}</Typography>}
                            {subTitle && <Typography variant='subtitle2' fontWeight={400} color={"#667085"} mt={1} sx={{xs:{wordBreak:"break-all"}}}>{subTitle}</Typography>}
                        </Box>

                        {formik && <Grid item md={12} xs={12}>
                            <FormikInputField
                                formik={formik}
                                FieldTouchInput={true}
                                label="Enter the name of the goal to proceed"
                                name='goalName'
                                placeholder="Enter name"
                            />
                        </Grid>}

                        <ButtonRowV1
                            handleBack={handleClose} handleNext={handleConfirm}
                            loadingNextBtn={loadingConfirmBtn} btnTitleNext={nextTitle}
                            isShowNext={showNextBtn}
                            btnTitleBack={backTitle} nextBtnStyle={{ backgroundColor: "#821B13", '&:hover': { backgroundColor: "#821B13" } }}
                        />
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}