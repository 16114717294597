function getLightnessFromColorString(colorString: string): number {
    // Extract the part of the string between the second "(" and the first ")"
    const parts = colorString.substring(colorString.indexOf('(') + 1, colorString.indexOf(')')).split(',');

    // Extract the lightness value
    const lightness = parseFloat(parts[2].trim().replace('%', '')); // Remove '%' if present

    return lightness;
}

export const customColorGenerator = (hslColor: string): any[] => {
    const initialLightness = getLightnessFromColorString(hslColor);
    const iterations = 7;
    const generatedColors: string[] = [];
    const lightnessIncrement = 10
    for (let i = 0; i < iterations; i++) {
        const newLightness = initialLightness - i * lightnessIncrement;
        const newColor = `${hslColor.split(',')[0]}, ${hslColor.split(',')[1]}, ${newLightness}%)`;
        generatedColors.push(newColor);
    }

    return generatedColors;
};