import { Box, Typography } from '@mui/material'
import React from 'react'
import { submitInfoStyle } from '../style'
import Checkbox from '@mui/material/Checkbox';
import ModalNextOfKin from 'Latest/MyProfileV1/NextOfKin/modalNextOfKin';
import ModalZakaatDec from 'Latest/MyProfileV1/ZakatDeclaration/modalZakaat';
import { useAppSelector } from 'hooks/reduxHooks';
import { useHistory } from 'react-router-dom';
import IconInfo from "Latest/Images/svg/IconInfo.svg";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const SubmitInfoZakaat = ({ checkNextOfKinbeneficiaries, checkZakaatDocDefault }: any) => {
    const { push } = useHistory();

    const handleRoute = () => {
        push("/myprofile?value=3&scroll=true")

    }

    return (

        <Box sx={submitInfoStyle.mainCard}>
            <Typography sx={submitInfoStyle.mainHeading}>
                <img src={IconInfo} />
                Submit your Zakat declaration before 29 Feb 2024 if you wish to avoid Zakat deductions
            </Typography>

            <Typography variant='body1' sx={{ ...submitInfoStyle.alignContent, ...submitInfoStyle.text }}>
                <Typography sx={submitInfoStyle.underLineContent} onClick={handleRoute}>Submit</Typography>
                &nbsp;Zakat declaration.
            </Typography>

            {/* <Typography variant='h6'>Submit your Zakat exemption form before <span style={{ color: '#422E84' }}>29 Feb 2024</span> if you wish to avoid Zakat deductions</Typography>

            <Box sx={submitInfoStyle.secondaryCardMainBox}>
                <Box sx={submitInfoStyle.secondaryCard}  >
                    <Checkbox {...label} checked={checkZakaatDocDefault} sx={{ p: 0, cursor: "default" }} color="success" />
                    <Typography variant='body1' sx={submitInfoStyle.alignContent}>Submit&nbsp;<ModalZakaatDec checkZakaatDocDefault={checkZakaatDocDefault} /></Typography>
                    <Typography variant='body1' sx={submitInfoStyle.alignContent}>Submit&nbsp;<span style={{
                        color: "#344054",
                        fontWeight: 600,
                        textDecorationLine: "underline",
                        cursor: "pointer"
                    }} onClick={handleRoute}> Zakat form</span></Typography>
                </Box>
            </Box> */}
        </Box>
    )
}

export default SubmitInfoZakaat