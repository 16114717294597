import { memo } from 'react';

const HeaderText = () => (
  <>
    <h1  style={{fontFamily: 'Playfair Display', fontStyle: 'normal',fontWeight: 700,fontSize: '28px',color: '#000000'}}>
      Account setup
    </h1>
  </>
);

export default memo(HeaderText);
