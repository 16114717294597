import { Card, Col, Row } from 'react-bootstrap';
import { memo, useEffect, useState } from 'react';
import InputField, {
  FormikFieldProps
} from 'jsx/components/Shared/FormikInputField';
import FormikRadioField from 'jsx/components/Shared/FormikRadioField';
import { investmentType } from 'static/selection';
import { useLocation } from 'react-router-dom';
import { checkProperties } from 'utils/validateObject';
import { FormikHandlers, FormikProps } from 'formik';
import './styles/ChooseInvestment.scss';
import { Goals } from './../../../constants';
import { useAppSelector } from 'hooks/reduxHooks';

const LABELS = {
  [Goals.HouseBuy]: {
    INITIAL_AMOUNT: 'Initial amount',
    I_KNOW_THE_AMOUNT: 'I know the amount I want to buy a house',
    PLAN_MONTHLY: 'Plan monthly contributions​​',
    AMOUNT_HEADING: 'Expected amount required​​',
    AMOUNT_DESCRIPTION: 'How much money would you like to save for a house?​​',
    SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
    SAVING_AMOUNT_DESCRIPTION:
      'How much money would you like to save every month?​​',
    AGE_HEADING: 'At what age you plan on buying a house​​',
    AGE_DESCRIPTION: 'Choose age ​​',
    YEAR_HEADING: 'In how many years do you plan on buying a house?​​',
    YEAR_DESCRIPTION: 'Number of years​​'
  },
  [Goals.CollegeFee]: {
    INITIAL_AMOUNT: 'Initial amount',
    I_KNOW_THE_AMOUNT: 'I know the amount I want to save for education',
    PLAN_MONTHLY: 'Plan monthly contributions​​',
    AMOUNT_HEADING: 'Expected amount required​',
    AMOUNT_DESCRIPTION:
      '​How much money would you like to save for school/ college? ​​',
    SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
    SAVING_AMOUNT_DESCRIPTION:
      'How much money would you like to save every month?​​',
    AGE_HEADING: 'At what age you plan on to utilize the fees​​',
    AGE_DESCRIPTION: 'Choose age ​​',
    YEAR_HEADING: 'In how many years do you plan to utilize the fees?​​',
    YEAR_DESCRIPTION: 'Number of years​ ​​'
  },
  [Goals.LargeExpense]: {
    INITIAL_AMOUNT: 'Initial amount',
    I_KNOW_THE_AMOUNT: 'I know the amount I want for my planned expense',
    PLAN_MONTHLY: 'Plan monthly contributions​​',
    AMOUNT_HEADING: 'Expected amount you want to receive​',
    AMOUNT_DESCRIPTION:
      'How much money would you like to save for this expense?  ​​',
    SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
    SAVING_AMOUNT_DESCRIPTION:
      'How much money would you like to save every month?​​',
    AGE_HEADING: 'At what age you plan to incur the expense?​​',
    AGE_DESCRIPTION: 'Choose age ​​',
    YEAR_HEADING: 'In how many years do you plan to incur this expense?​​​​',
    YEAR_DESCRIPTION: 'Number of years​ ​​'
  },
  [Goals.WindFallReceive]: {
    INITIAL_AMOUNT: 'Initial amount',
    I_KNOW_THE_AMOUNT: 'I know the amount I want for my planned goal​',
    PLAN_MONTHLY: 'Plan monthly contributions​​',
    AMOUNT_HEADING: 'Expected amount you want to accumulate​',
    AMOUNT_DESCRIPTION:
      'How much money would you like to save for this goal? ​ ​​',
    SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
    SAVING_AMOUNT_DESCRIPTION:
      'How much money would you like to save every month?​​',
    AGE_HEADING: 'At what age you plan to achieve the goal?​​',
    AGE_DESCRIPTION: 'Choose age ​​',
    YEAR_HEADING: 'In how many years do you plan to achieve this goal?​​​',
    YEAR_DESCRIPTION: 'Number of years​ ​​',
    GOAL_NAME: 'What would you like to name your goal?'
  },
  [Goals.TravelTime]: {
    INITIAL_AMOUNT: 'Initial amount',
    I_KNOW_THE_AMOUNT: 'I know the amount I want for travel',
    PLAN_MONTHLY: 'Plan monthly contributions​​',
    AMOUNT_HEADING: 'Expected amount for travel plans​',
    AMOUNT_DESCRIPTION:
      'How much money would you like to save for travelling? ​​',
    SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
    SAVING_AMOUNT_DESCRIPTION:
      'How much money would you like to save every month?​​',
    AGE_HEADING: 'At what age you like to travel?​​',
    AGE_DESCRIPTION: 'Choose age ​​',
    YEAR_HEADING: 'When would you like to travel?​​',
    MONTH_HEADING: 'In how many months do you plan to travel?',
    YEAR_DESCRIPTION: 'Choose year​ ​​',
    Month_DESCRIPTION: 'Number of months ​​'
  },
  [Goals.VoluntaryPension]: {
    INITIAL_AMOUNT: 'Initial amount',
    I_KNOW_THE_AMOUNT: 'I know the amount I want at retirement',
    PLAN_MONTHLY: 'Plan monthly contributions​​',
    AMOUNT_HEADING: 'Expected amount at retirement​​',
    AMOUNT_DESCRIPTION:
      'How much money would you like to have at your retirement?​​',
    SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
    SAVING_AMOUNT_DESCRIPTION:
      'How much money would you like to save every month?​​',
    AGE_HEADING: 'Expected age at retirement​',
    AGE_DESCRIPTION: '​​',
    YEAR_HEADING: 'Expected age at retirement​​',
    YEAR_DESCRIPTION: '​ ​​'
  }
};

const ChooseInvestment = ({ formik, goalId }: ChooseInvestmentProps) => {
  const { userAccountDetails } = useAppSelector((state: any) => state.auth);
  const [otherGoal, setOtherGoal] = useState(false);

  useEffect(() => {
    const frequencyAge: number =
      userAccountDetails?.identityCard?.age;
    formik.setFieldValue('userAge', frequencyAge);
  }, []);
  const expected = (
    <Row>
      <Col className='mb-4' md='12'>
        <InputField
          // callback={(values: any) => {formik.setFieldValue('retirement_amount', values.floatValue);  formik.validateForm(); formik.setFieldTouched('retirement_amount', true)}}
          col={12}
          formik={formik}
          isStep={true}
          label={LABELS[goalId].AMOUNT_HEADING}
          name='retirement_amount'
          placeholder='Enter amount'
          thousandSeparator={true}
          topInfo={LABELS[goalId].AMOUNT_DESCRIPTION}
          type='number'
        />
      </Col>

      {formik.getFieldProps('goalId')?.value === Goals.TravelTime ? (
        <Col className='mb-3' md='12'>
          <InputField
            // callback={(values: any) => formik.setFieldValue('desired_retirement_age', values.floatValue)}
            col={12}
            formik={formik}
            isStep={false}
            label={LABELS[goalId].MONTH_HEADING}
            max={4}
            maxLength={4}
            name='desired_retirement_age'
            placeholder='Enter months'
            topInfo={LABELS[goalId].Month_DESCRIPTION}
            type='number'
          />
        </Col>
      ) : (
        <Col className='mb-3' md='12'>
          <InputField
            // callback={(values: any) => formik.setFieldValue('desired_retirement_age', values.floatValue)}
            col={12}
            formik={formik}
            isStep={false}
            label={LABELS[goalId].YEAR_HEADING}
            max={3}
            maxLength={3}
            name='desired_retirement_age'
            placeholder='Enter years'
            topInfo={LABELS[goalId].YEAR_DESCRIPTION}
            type='number'
          />
        </Col>
      )}
    </Row>
  );
  const monthly = (
    <Row>
      <Col className='mb-3' md='12'>
        <InputField
          //  callback={(values: any) => {formik.setFieldValue('monthly_saving', values.floatValue); formik.validateForm(); formik.setFieldTouched('monthly_saving', true)}}
          col={12}
          formik={formik}
          isStep={true}
          label={LABELS[goalId].SAVING_AMOUNT_HEADING}
          //max={10000000}
          name='monthly_saving'
          placeholder='Enter amount'
          thousandSeparator={true}
          topInfo={LABELS[goalId].SAVING_AMOUNT_DESCRIPTION}
          type='number'
        />
      </Col>

      {formik.getFieldProps('goalId')?.value === Goals.TravelTime ? (
        <Col className='mb-3' md='12'>
          <InputField
            // callback={(values: any) => formik.setFieldValue('desired_retirement_age', values.floatValue)}
            col={12}
            formik={formik}
            isStep={false}
            label={LABELS[goalId].MONTH_HEADING}
            max={4}
            maxLength={4}
            name='desired_retirement_age'
            placeholder='Enter months'
            topInfo={LABELS[goalId].Month_DESCRIPTION}
            type='number'
          />
        </Col>
      ) : (
        <Col className='mb-3' md='12'>
          <InputField
            // callback={(values: any) => formik.setFieldValue('desired_retirement_age', values.floatValue)}
            col={12}
            formik={formik}
            isStep={false}
            label={LABELS[goalId].YEAR_HEADING}
            max={3}
            maxLength={3}
            name='desired_retirement_age'
            placeholder='Enter years'
            topInfo={LABELS[goalId].YEAR_DESCRIPTION}
            type='number'
          />
        </Col>
      )}
    </Row>
  );

  const returnSelectForm = () => {
    // @ts-ignore
    const type = formik.getFieldProps('plan_type')?.value;
    if (type === 'AccumulateAmount') return expected;
    if (type === 'MonthlyContributions') return monthly;
    return null;
  };
  const { search } = useLocation();
  // alert(param)
  return (
    <Col className='modalStyle' md='12' style={{ overflow: 'auto' }}>
      <form onSubmit={formik.handleSubmit}>
        {/* <Row>
          <Col md="12">
            <FormikRadioField
              singleLine
              col={12}
              fieldName='investment_type'
              fields={investmentType}
              formik={formik}
              label='Choose Investment'
            />
          </Col>
        </Row> */}
        <Row>
          {goalId === 4 && (
            <Col className='pb-4' md='12'>
              <InputField
                col={12}
                formik={formik}
                isStep={true}
                label={LABELS[goalId].GOAL_NAME}
                max={1000000}
                name='goal_name'
                placeholder=''
                // topInfo='Enter name'
                type='text'
              />
            </Col>
          )}
          <Col md='12'>
            <InputField
              //callback={(values: any) => {formik.setFieldValue('initial_amount', values.floatValue); formik.validateForm(); formik.setFieldTouched('initial_amount', true)}}
              col={12}
              formik={formik}
              isStep={true}
              label={LABELS[goalId].INITIAL_AMOUNT}
              max={1000000}
              name='initial_amount'
              placeholder=''
              thousandSeparator={true}
              topInfo='Enter amount'
              type='number'
            />
          </Col>
        </Row>
        <Row>
          <Col className='pt-4' md='12'>
            {formik?.values?.initial_amount ? (
              <>
                <FormikRadioField
                  singleLine
                  col={12}
                  fieldName='plan_type'
                  fields={[
                    {
                      // disabled: true,
                      label: LABELS[goalId].I_KNOW_THE_AMOUNT,
                      value: 'AccumulateAmount'
                    },
                    {
                      label: LABELS[goalId].PLAN_MONTHLY,
                      value: 'MonthlyContributions'
                    }
                  ]}
                  formik={formik}
                  label='Choose an option'
                />
              </>
            ) : // <div className=''>

            // </div>
            null}
          </Col>
        </Row>

        {formik?.values?.initial_amount ? returnSelectForm() : null}
        {/* </div> */}
      </form>
    </Col>
  );
};

export default memo(ChooseInvestment);

export interface ChooseInvestmentProps {
  formik: any;
  goalId: any;
}
