import { Box, ClickAwayListener, Divider, Grid, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { CurrencyFormatterWithDecimal } from 'utils/calculation'
import CustomChip from 'Latest/ComponentV1/customChip'
import { tokens } from 'theme'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Big from 'big.js'
import infoIconSvg from "Latest/Images/svg/Info.svg"
import formatNumberForDisplay from 'utils/numberDisplay'

const tootltipContent = "Return is extended to a one year period with the assumption that performance remains the same as before. This is also called an annualized value."


const AmtCard = ({ goalInfo }: any) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const portfolioAmount = CurrencyFormatterWithDecimal(goalInfo?.potDetails?.portfolioAmount, true, "never", true ,true)
    const gainLossAmount = CurrencyFormatterWithDecimal(Number(goalInfo?.potDetails?.gainLossAmount), true, "never", true , true)
    const simpleReturnPercentage = goalInfo && Big(goalInfo?.potDetails?.simpleReturnPercentage || 0).toFixed(2)
    const annualizeTWR = CurrencyFormatterWithDecimal(Number(goalInfo?.potDetails?.annualizeTWR || 0), false, "never", false)
    const totalInvestedAmount = CurrencyFormatterWithDecimal(goalInfo?.potDetails?.totalInvestedAmount, true, "never", true , true)

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setIsTooltipOpen(true);
    };

    const customTooltip = () => {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={isTooltipOpen}
                    title={tootltipContent}
                >
                    <img src={infoIconSvg} alt='info' onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleTooltipOpen();
                    }} style={{ cursor: "pointer" }} />
                </Tooltip>
            </ClickAwayListener>
        )
    }

    const isAnnualizeTWR = goalInfo?.potDetails?.annualizeTWR > 5 ? true : false;
    const setSmSize = isAnnualizeTWR ? 5 : 3.5;

    return (
        <Grid container sx={{ gap: "24px" }} flexWrap={{ md: "nowrap", xs: "wrap" }} alignItems={"flex-end"}>

            {/* Total Value */}
            <Grid item md={3} sm={setSmSize} xs={12}>
                <Typography variant='h3' color={colors.purpleAccent[100]} lineHeight={"42px"}>{portfolioAmount}</Typography>
                <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"}>Total value (PKR)</Typography>
            </Grid>

            {/* Return */}
            <Grid item md={3} sm={setSmSize} xs={12}>
                {goalInfo?.potDetails?.gainLossAmount >= 0 || (goalInfo?.potDetails?.gainLossAmount <= -0 && goalInfo?.potDetails?.gainLossAmount> -1) ?
                    <>
                        <Typography variant='subtitle2' color={colors.grey[800]} lineHeight={"24px"}>
                            {/* <ArrowUpwardIcon sx={{ color: "#12B76A" }} fontSize={"medium"} />  */}
                            {gainLossAmount}
                            {/* {simpleReturnPercentage > 0 && <CustomChip label={`${simpleReturnPercentage}%`} status={"success"} />}   hide twr for now coment for now */}
                        </Typography>
                    </>
                    :
                    <>
                        <Typography variant='subtitle2' color={colors.grey[800]} lineHeight={"24px"}>
                            {/* <ArrowDownwardIcon sx={{ color: colors.redAccent[600] }} fontSize={"medium"} /> */}
                            -{gainLossAmount}
                            {/* {simpleReturnPercentage < 0 && <CustomChip label={`${simpleReturnPercentage}%`} status={"error"} />} hide twr for now coment for now */}
                        </Typography>
                    </>
                }
                <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"}>Profit (PKR)</Typography>
            </Grid>

            {/* Invested Amount */}
            {goalInfo?.potDetails?.annualizeTWR > 5 && <Grid item md={3} sm={setSmSize} xs={12}>
                <Typography variant='subtitle2' color={colors.grey[800]} lineHeight={"24px"}>{annualizeTWR}%</Typography>
                <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"} width={"150px"}>Annualized return {customTooltip()}</Typography>
            </Grid>}

            {/* Invested Amount */}
            <Grid item md={3} sm={setSmSize} xs={12}>
                <Typography variant='subtitle2' color={colors.grey[800]} lineHeight={"24px"}>{totalInvestedAmount}</Typography>
                <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"}>Invested Amount</Typography>
            </Grid>

        </Grid>
    )
}

export default AmtCard