/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useAppSelector } from 'hooks/reduxHooks';
import { CASH_FUND_ID, Goals, intialInvestmentMaxLimit } from './../constants';
import * as Yup from 'yup';

export const VPSGoal_Errors = {
  AMOUNT_REQUIRED: 'Amount is required',
  YEARS_REQUIRED: '	Number of years is required',
  AGE_REQUIRED: 'Age is required',
  MONTH_REQUIRED: 'Number of months is required',
  NUMBER_OF_YEARS: 'Number of years should be less than 100 years',

  AMOUNT_REQUIRED_NOT_GREATER: 'The initial amount cannot be equal to or more than the Total investment goal',

  MIN_INTIAL_AMT_CF: "Amount should be greater than or equal to 1,000",
  MIN_INTIAL_AMT_GI: "Amount should be greater than or equal to 10,000",

  MAX_INTIAL_AMT_CF: 'Amount should not be greater than 800,000',
  MAX_INTIAL_AMT_GI: 'Amount should not be greater than 800,000',

};

export const VPSGoal = Yup.object({
  goal_name: Yup.string()
    .required('Goal name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  goalId: Yup.number(),

  initial_amount: Yup.number().required(VPSGoal_Errors.AMOUNT_REQUIRED).when('goalId', {
    is: (val: any) => val == CASH_FUND_ID,
    then: Yup.number().min(1000, VPSGoal_Errors.MIN_INTIAL_AMT_CF).max(intialInvestmentMaxLimit, VPSGoal_Errors.MAX_INTIAL_AMT_CF).nullable(),  //CF
    otherwise: Yup.number().min(10000, VPSGoal_Errors.MIN_INTIAL_AMT_GI).max(intialInvestmentMaxLimit, VPSGoal_Errors.MAX_INTIAL_AMT_GI).nullable(),   //GI
  }),

  // .typeError('Only numbers allowed'),
  investment_type: Yup.string().required('Investment type is required'),

  monthly_saving: Yup.number().when('goalId', {
    is: (val: any) => val == CASH_FUND_ID,
    then: Yup.number().required(VPSGoal_Errors.AMOUNT_REQUIRED).max(1000000, 'Amount should not be greater than 1,000,000').nullable(),  //CF
    otherwise: Yup.number().required(VPSGoal_Errors.AMOUNT_REQUIRED).max(1000000, 'Amount should not be greater than 1,000,000').test({
      message: 'Amount should be zero or 10,000 or more.',
      test: (value: any) => value === 0 || value >= 10000,
    }).nullable(),   //GI
  }),

  expected_monthly_saving: Yup.number()
    .test('required', '', function (value, context) {
      if (!value) {
        return this.createError({ message: VPSGoal_Errors.AMOUNT_REQUIRED });
      }
      return true;
    })
    .required('Amount is required')
    .typeError('Only numbers are required ')

    .max(9999999, 'Amount should be less than 10 million'),

  plan_type: Yup.string().required('Plan type is required.'),

  retirement_amount: Yup.number()
    .required('Amount is required')
    // .min(50000,"")
    .max(1000000, 'Amount should not be greater than 1,000,000')
    .test('required', '', function (value: any, context) {
      // old
      // if (!value) {
      //   console.log(value , "PLOKI")
      //   return this.createError({ message: VPSGoal_Errors.AMOUNT_REQUIRED });
      // }
      // if (context.parent.plan_type == "MonthlyContributions" && value < 1000) {
      //   return this.createError({ message: "Amount cannot be less than 1,000" });
      // }
      // if (context.parent.plan_type == "AccumulateAmount" && value < 50000) {
      //   return this.createError({ message: "Amount cannot be less than 50,000" });
      // }

      if (!value) {
        return this.createError({
          message: "Amount is required"
        });
      }
      // else if (value && value < 50000) {
      //   return this.createError({
      //     message: "Amount cannot be less than 50,000"
      //   });
      // } 
      else if (value && (value === context.parent.initial_amount || context.parent.initial_amount > value) && context.parent.plan_type === 'AccumulateAmount') {
        return this.createError({
          message: VPSGoal_Errors.AMOUNT_REQUIRED_NOT_GREATER
        });

      }
      return true;
    })
    .nullable(),

  desired_retirement_age: Yup.number()
    // .transform((value: any) => (isNaN(value) ? undefined : value))
    .required('Time horizon is required')
    .max(25, 'Time horizon should not be greater than 25')
    .min(1, "Time horizon is required")
    .nullable(),
  // .transform((value: any) => (isNaN(value) ? undefined : value))
  // .test('required', '', function (value, context) {
  //   const isRetirementPlanSelected =
  //     context.parent.plan_type === 'AccumulateAmount';

  //   if (!value) {
  //     if (context.parent.goalId === Goals.TravelTime) {
  //       return this.createError({ message: VPSGoal_Errors.MONTH_REQUIRED });
  //     } else {
  //       return this.createError({ message: VPSGoal_Errors.YEARS_REQUIRED });
  //     }
  //   }

  //   if (
  //     isRetirementPlanSelected &&
  //     value > 100 &&
  //     context.parent.goalId === Goals.VoluntaryPension
  //   ) {
  //     return this.createError({
  //       message: 'Age must be less than 100 years'
  //     });
  //   }

  //   if (
  //     value > 100 &&
  //     context.parent.goalId !== Goals.TravelTime &&
  //     context.parent.goalId !== Goals.VoluntaryPension
  //   ) {
  //     return this.createError({
  //       message: 'Number of years should be less than 100 years'
  //     });
  //   }

  //   if (value > 1200 && context.parent.goalId === Goals.TravelTime) {
  //     return this.createError({
  //       message: 'Number of months should be less than 1200 months'
  //     });
  //   }

  //   if (
  //     isRetirementPlanSelected &&
  //     context.parent.userAge + 1 > context.parent.desired_retirement_age &&
  //     context.parent.goalId === Goals.VoluntaryPension
  //   ) {
  //     return this.createError({
  //       message: 'Age must be greater than your current age'
  //     });
  //   }

  //   return true;
  // }),

  years_required: Yup.number()

    .max(100, 'Number of years should be less than 100 years')
    .test('required', '', function (value, context) {
      if (!value) {
        return this.createError({ message: VPSGoal_Errors.YEARS_REQUIRED });
      }
      return true;
    })
});


export const VPSGoalFunc = () => {

  const { userAccountDetails } = useAppSelector(state => state.auth);
  const maxInitLimitCF = userAccountDetails?.kyc?.status == "ApprovalSucceeded" ? 10000000000 : intialInvestmentMaxLimit
  const maxInitLimitGI = userAccountDetails?.kyc?.status == "ApprovalSucceeded" ? 10000000000 : intialInvestmentMaxLimit

  return Yup.object({
    goal_name: Yup.string()
      .required('Goal name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    goalId: Yup.number(),

    initial_amount: Yup.number().required(VPSGoal_Errors.AMOUNT_REQUIRED).when('goalId', {
      is: (val: any) => val == CASH_FUND_ID,
      then: Yup.number().min(1000, VPSGoal_Errors.MIN_INTIAL_AMT_CF).max(maxInitLimitCF, VPSGoal_Errors.MAX_INTIAL_AMT_CF).nullable(),  //CF
      otherwise: Yup.number().min(10000, VPSGoal_Errors.MIN_INTIAL_AMT_GI).max(maxInitLimitGI, VPSGoal_Errors.MAX_INTIAL_AMT_GI).nullable(),   //GI
    }),

    // .typeError('Only numbers allowed'),
    investment_type: Yup.string().required('Investment type is required'),

    // monthly_saving: Yup.number().when('goalId', {
    //   is: (val: any) => val == CASH_FUND_ID,
    //   then: Yup.number().required(VPSGoal_Errors.AMOUNT_REQUIRED).max(1000000, 'Amount should not be greater than 1,000,000').nullable(),  //CF
    //   otherwise: Yup.number().required(VPSGoal_Errors.AMOUNT_REQUIRED).max(1000000, 'Amount should not be greater than 1,000,000')
    //     // .test({
    //     //   message: 'Amount should be zero or 10,000 or more.',
    //     //   test: (value: any) => value === 0 || value >= 10000,
    //     // })
    //     .nullable(),   //GI
    // }),

    expected_monthly_saving: Yup.number()
      .test('required', '', function (value, context) {
        if (!value) {
          return this.createError({ message: VPSGoal_Errors.AMOUNT_REQUIRED });
        }
        return true;
      })
      .required('Amount is required')
      .typeError('Only numbers are required ')

      .max(9999999, 'Amount should be less than 10 million'),

    plan_type: Yup.string().required('Plan type is required.'),

    retirement_amount: Yup.number()
      .required('Amount is required')
      // .min(50000,"")
      .max(1000000, 'Amount should not be greater than 1,000,000')
      .test('required', '', function (value: any, context) {

        if (!value) {
          return this.createError({
            message: "Amount is required"
          });
        } else if (value && (value === context.parent.initial_amount || context.parent.initial_amount > value) && context.parent.plan_type === 'AccumulateAmount') {
          return this.createError({
            message: VPSGoal_Errors.AMOUNT_REQUIRED_NOT_GREATER
          });

        }
        return true;
      })
      .nullable(),

    desired_retirement_age: Yup.number()
      .required('Time horizon is required')
      .max(25, 'Time horizon should not be greater than 25')
      .min(1, "Time horizon is required")
      .nullable(),
    years_required: Yup.number()

      .max(100, 'Number of years should be less than 100 years')
      .test('required', '', function (value, context) {
        if (!value) {
          return this.createError({ message: VPSGoal_Errors.YEARS_REQUIRED });
        }
        return true;
      })
  })
}





export const waitListValidation = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g, 'Invalid email')
    .test('exclude-subdomains', 'Invalid email', (value) => {
      if (typeof value === 'string' && value !== '') { // add check for non-empty value
        const parts = value.split('@');
        if (parts.length !== 2) {
          return false;
        }
        const domainParts = parts[1].split('.');
        return domainParts.length === 2;
      }
      return true;
    })
    .required('Email is required')
    .max(254),
})


