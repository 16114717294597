import { Box, Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import InfoIcon from "Latest/Images/svg/infoIcon.svg"
import { zakatStyle } from 'Latest/MyProfileV1/Style/style'
import LoadingButton from '@mui/lab/LoadingButton'
import UploadIcon from "Latest/Images/svg/uploadIcon.svg"
import ReadInstructionModal from './readInstructionModal'
import { onGetAccountSetup, onSubmitZakatDocuments } from 'redux/reducers/auth'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useFormik } from 'formik'
import UploadCustomInputFieldV1 from 'jsx/components/Shared/V1/uploadCustomInputFieldV1'
import * as Yup from 'yup';
import { useAppLoading } from 'hooks/appLoading'
import { ToastContainer, toast } from 'react-toastify'
import SubmitCard from './submitCard'
import StatusCard from './statusCard'
import { zakatStatus } from '../../../constants';
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral'
import CustomChip from 'Latest/ComponentV1/customChip'
import CustomSingleCheckBox from 'jsx/components/Shared/V2/CustomSingleCheckBoxV2';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom'


const Index = ({ handleCloseModal, path = "myprofile" }: any) => {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const identityCard = useAppSelector(state => state.auth.userAccountDetails?.identityCard);

    const dispatch = useAppDispatch();
    const accountSetupLoading = useAppLoading('accountSetup');
    const zakaatDocLoading = useAppLoading('zakaatDoc');


    const loading = accountSetupLoading || zakaatDocLoading
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const classes = useStyles();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { document: "", nonMuslimCheck: false },
        onSubmit: async (values: any) => {
            const formData = new FormData();
            formData.append('Document', values?.document);
            handleUpload(formData, undefined)
        },
        validationSchema: Yup.object().shape({
            document: Yup.mixed().required('Zakat document is required')
                .test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed.', function (value) {
                    if (value) {
                        const allowedFileExtensions = ['.png', '.jpg', '.jpeg', '.pdf'];
                        const fileName = value.name;
                        const fileExtension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
                        return allowedFileExtensions.includes(fileExtension);
                    }
                    return true; // If value is not provided (e.g., not required), return true
                })
                .test('required', '', function (value, context) {
                    if (value && value.size > 10000000) {
                        return this.createError({
                            message: "File size should be 25 KB to 10 MB"
                        });
                    }
                    else if (value && value.size < 25000) {
                        return this.createError({
                            message: "File size should be 25 KB to 10 MB"
                        });
                    }
                    else if (value == undefined) {
                        return this.createError({
                            message: "Zakat document is required"
                        });
                    }
                    return true
                })
        })
    });

    const formikNonMuslim = useFormik({
        enableReinitialize: true,
        initialValues: { nonMuslimCheck: false },
        onSubmit: async (values: any) => {
            const isNonMuslim: any = values?.nonMuslimCheck ? "NonMuslim" : undefined
            const formData = new FormData();
            formData.append('ReligiousAffiliation', isNonMuslim);
            handleUpload(formData, undefined)
        },
        validationSchema: Yup.object().shape({
            nonMuslimCheck: Yup.bool().oneOf([true], 'Your consent is required'),
        })
    });

    const handleUpload = (formData: any, handleClose: any) => {
        const onSuccess = (message: string, response: any) => {
            formik.resetForm()
            formikNonMuslim.resetForm()
            toast.success('Zakat declaration successfully submitted', {
                autoClose: 5000,
                closeOnClick: true,
                draggable: true,
                hideProgressBar: false,
                pauseOnHover: true,
                position: 'top-right',
                progress: undefined
            });
            dispatch(onGetAccountSetup("accountSetup"));
            handleCloseModal && handleCloseModal()
            handleClose && handleClose()
        }

        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
        }
        dispatch(onSubmitZakatDocuments(formData, onSuccess, onFail))
    };

    useEffect(() => {
        dispatch(onGetAccountSetup("accountSetup"));
    }, []);

    const zakaatStatusObj = zakatStatus[userAccountDetails?.zakatAffidavit?.documentStatus]
    const isNonMuslim = zakatStatus[userAccountDetails?.zakatAffidavit?.religiousAffiliation] == 'NonMuslim'

    // const zakaatRejectedStatus = zakatStatus[userAccountDetails?.zakatAffidavit?.documentStatus]


    const formikTouch = formikNonMuslim.touched as never;
    const formikError = formikNonMuslim.errors as never;

    return (
        <>
            {/* <ToastContainer /> */}

            <Grid item md={12} xs={12}>

                <Grid item md={12} xs={12}>

                    <Box sx={{ display: "flex", flexDirection: { sm: "row", md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>

                        <Grid item md={6} xs={12} sx={{ display: "flex", gap: "24px", flexDirection: { sm: "row", md: "row", xs: "column-reverse" }, }}>
                            <Box>
                                <Typography variant='h4' color={"#422E84"}>Apply for Zakat exemption</Typography>
                                <Typography variant='body1' fontWeight={500} color={"#767676"} marginTop={"4px"}>Deadline to submit: 29 Feb 2024</Typography>
                            </Box>

                            {(
                                zakaatStatusObj?.status != "Default" &&
                                zakaatStatusObj?.status?.toLowerCase() != "failed" &&
                                zakaatStatusObj?.status?.toLowerCase() != "rejected"
                            ) &&
                                <CustomChip
                                    label={zakaatStatusObj?.label}
                                    status={zakaatStatusObj?.status}
                                    style={{ marginBottom: "-15px"  }}
                                    verifiedWidth={{ width: "80px" }}
                                    pendingWidth = {{width:"200px",fontSize:{md:"14px",xs:"14px"}}}
                                />}
                        </Grid>

                        {zakaatStatusObj?.isSubmit && <Grid item md={6} xs={12}>
                            <Typography variant='subtitle2' sx={zakatStyle.formSubmitText}>
                                {isNonMuslim ? "NON-MUSLIM DECLARATION submitted" : "ZAKAT FORM SUBMITTED"}
                            </Typography></Grid>}

                    </Box>

                </Grid>

                <Grid item md={zakaatStatusObj?.isSubmit ? 6.5 : 8} xs={12} sx={{ mb: "24px", mt: "16px" }} >
                    <Typography variant='body2' color={"#475467"}>As per current regulations, Zakat is deducted at <b>2.5%</b> of the entire account value. It’s only deducted when your balance is above a minimum value. This value varies every year, but is usually around PKR 100,000. </Typography>
                </Grid>

                <Grid>
                    {zakaatStatusObj?.isSubmit && <Typography sx={zakatStyle.formSubmitTextXs}>
                        {isNonMuslim ? "NON-MUSLIM DECLARATION submitted" : "ZAKAT FORM SUBMITTED"}
                    </Typography>}
                </Grid>

                {!zakaatStatusObj?.isSubmit && <>


                    <Typography variant='body2' color={"#475467"}><strong>For Muslims:</strong> Submit a new Zakat form online or upload an already available one to apply for an exemption. </Typography>

                    <Grid item md={12} xs={12} sx={{ padding: "16px 0" }}>

                        <ReadInstructionModal handleUpload={handleUpload} isloading={loading} />
                        <Typography variant='h6' sx={{ padding: "14px 24px", color: "#616161", textAlign: "center" }}>OR</Typography>

                        {/* {zakatStatus[userAccountDetails?.zakatAffidavit?.documentStatus]?.isSubmit && path != "modal" ?
                    <StatusCard />
                    : */}
                        <SubmitCard loading={loading} formik={formik} handleCloseModal={handleCloseModal} path={path} />
                        {/* } */}

                    </Grid >

                    <Typography variant='body2' pt={"32px"} pb={"8px"} color={"#475467"}><strong>For Non-Muslims:</strong> Submit the following declaration to apply for an exemption.</Typography>
                    <Grid item md={12} xs={12} >
                        <Box sx={{ ...zakatStyle.checkBoxWrapper2, border: formikError["nonMuslimCheck"] && formikTouch["nonMuslimCheck"] ? "1px solid #E8655A" : "none" }}>
                            <CustomSingleCheckBox
                                formik={formikNonMuslim}
                                fieldName={`nonMuslimCheck`}
                                label={`I, ${identityCard?.name} hereby declare that I am a Non-Muslim and according to my faith, I am not obliged to pay zakat.`}
                                type={"checkbox"}
                                styleForm={{ padding: "0px", paddingLeft: 0 }}
                                styleErr={{ marginLeft: "0px", fontSize: "12px" }}
                                containerStyleCustom={{ background: "transparent", padding: "0px" }}
                            />
                            <LoadingBtnCustom handleOnClick={formikNonMuslim.submitForm} loading={loading} btnTitle={"Submit"} style={{ padding: "8px 16px", fontSize: "1rem", minWidth: "90px", lineHeight: "24px" }} />
                        </Box>
                    </Grid >
                </>}

                {/* {
                path == "modal" && <Grid item md={12} xs={12} mt="32px" display={"flex"} gap={2}>
                    <LoadingButton fullWidth size={"large"} variant='outlined' className={classes.neutralButton} onClick={handleCloseModal} >cancel</LoadingButton>
                    <LoadingButton fullWidth size="large" loading={loading} onClick={formik.submitForm} variant='contained' color={"secondary"} >{!smScreen ? "Submit" : "Submit for approval"}</LoadingButton>
                </Grid>
            } */}
            </Grid>
        </>
    )
}

export default Index