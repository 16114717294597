import { memo } from 'react';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { bankTransferPost } from 'redux/actions';
import { Field } from '../Shared/FormikSelectField';
import OnlineBankForm from './OnlineBankForm';

const PaymentOptionsConent = ({ banksList, paymentAccList, submitBankTransfer, }: paymentOptionProps) => {
  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col md="12">
              <OnlineBankForm
                banksList={banksList}
                paymentAccList={paymentAccList}
                submitBankTransfer={submitBankTransfer}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default memo(PaymentOptionsConent);

export interface paymentOptionProps {
  banksList: Array<Field>;
  submitBankTransfer: (data: bankTransferPost) => void;
  paymentAccList: any
}
