export const BlogStyle = {
    blogBox: {
        display: "flex", gap: "16px", flexDirection: "column", alignItems: "center",
        "& button": {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            textDecorationLine: "underline",
            borderRadius: "5px",
            padding: "15px 10px"
        },
        '& hr': {
            borderColor: "#D0D5DD40"
        }

    },
    blogBoxItem: {
        display: "flex", alignItems: "flex-start", gap: "18px",
        flexDirection: 'column',

        "&:hover": {
            // background: "#F2F4F7",
            // borderRadius: "8px"
        },
        "& h4": {
            fontFamily: 'Inter,sans-serif',
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "28px"
        },
        "& h5": {
            fontFamily: 'Inter,sans-serif',
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px"
        },
        ".childBox": {
            display: 'flex',
            flexDirection: "column",
            gap: "8px"
        }
    },

    blogFirstBox: {
        boxShadow: '0px 1px 4px 0px #D0D5DD40',
        backgroundColor: "#fff", borderRadius: "12px", position: "relative", overflow: "hidden", border: '1px solid #CFCBDF',
    },

    MahaanaDeskStyles: {
        p: 2,
        backgroundColor: "#fff"
    }
}