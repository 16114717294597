import React, { useState } from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import mahaanaFullLogo from "../Images/mahaanaFullLogo.png"
import mahaanaSmallLogo from "../Images/mahaanaSmallLogo.png"
import MuiDrawer from '@mui/material/Drawer';
import { navBarOptions, sideBarRoute } from 'Latest/DashboardScreen/FreshUserScreen/constant';
import {
  LinkProps as RouterLinkProps,
  MemoryRouter,
  useLocation
} from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, IconButton, Typography, makeStyles } from '@mui/material';
import { drawerStyle } from 'Latest/Style/drawer';
import { customDrawerStyle, customDrawerStylelistItemHover } from './style/customDrawerStyle';
import LogoutIcon from '@mui/icons-material/Logout';
import { onLogout } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { tokens } from 'theme';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RouteList from './routeList';
import { Link } from 'react-router-dom';
import mahaanaFullLogoSvg from "../Images/svg/logoMahaana.svg"
import mahaanaIconLogoSvg from "../Images/svg/iconMahaana.svg"
import arrowLeftSvg from "../Images/svg/arrowLeftIcon.svg"
import arrowRightSvg from "../Images/svg/arrowRightIcon.svg"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const drawerWidth = 210;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: "space-between",
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  // height:"98px",
  marginLeft: "16px",
  marginTop: "24px"
}));

const DrawerHeaderCLossed = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: "space-between",
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  // marginLeft: "24px",
  marginLeft: "13px",
  marginTop: "22px"
  // marginTop: "22px"
  // minHeight: "84px",
  // paddingTop: "37px",
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ open, theme }) => ({
  width: drawerWidth,
  p: 20,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...OpenedMixin(theme),
    '& .MuiDrawer-paper': OpenedMixin(theme),
  }),
  ...(!open && {
    ...ClosedMixin(theme),
    '& .MuiDrawer-paper': ClosedMixin(theme),
  }),
}),
);

const OpenedMixin = (theme: Theme): CSSObject => {
  const themes = useTheme();
  const colors = tokens(themes.palette.mode);
  return {
    background: "#F8FAFB",
    borderRight: `1px solid #D0D5DD40`,
    boxShadow: "none",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  };
};

const ClosedMixin = (theme: Theme): CSSObject => {
  const themes = useTheme();
  const colors = tokens(themes.palette.mode);
  return {
    background: "#F8FAFB",
    borderRight: `1px solid #D0D5DD40`,
    boxShadow: "none",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  }
};

const DrawerCustom = ({ handleDrawerClose, handleDrawerOpen, open }: any) => {
  const dispatch = useAppDispatch();
  const logoutHandle = () => dispatch(onLogout());
  const location = useLocation()
  const themes = useTheme();
  const colors = tokens(themes.palette.mode);
  const [isHover, setIsHover] = useState(false)

  const [openListItem, setOpenListItem] = React.useState<any>({});

  const handleClick = (item: any) => {
    setOpenListItem((prevState: any) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  const handleExpandBtn = () => {
    if (open) {
      handleDrawerClose()
    } else {
      handleDrawerOpen()
    }
    setIsHover(false)
  }
  return (
    <>
      <Box sx={{ position: "fixed", left: open ? 195 : 53, zIndex: themes.zIndex.drawer + 1, top: 37 }} onClick={handleExpandBtn} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
        <Box
          sx={{
            ...customDrawerStyle.expandBox,
            border: `1.0166px solid ${isHover ? "#432F87" : "#EAECF0"}`,
          }}
        >
          {open ?
            <KeyboardArrowLeftIcon sx={{ color: isHover ? "#432F87" : "#667085" }} />
            :
            <KeyboardArrowRightIcon sx={{ color: isHover ? "#432F87" : "#667085" }} />
          }
        </Box>

      </Box>



      <Drawer variant="permanent" open={open}
      // onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose}
      >
        {open &&
          <DrawerHeader>
            <Link to={"/"}>
              <img src={mahaanaFullLogoSvg} style={{ width: "108.52px", height: "22.4px" }} />
            </Link>
          </DrawerHeader>
        }

        {!open &&
          <DrawerHeaderCLossed >
            <Box sx={{ py: "16.34px" }}>
              <Link to={"/"}>
                <img src={mahaanaIconLogoSvg} style={{ width: "38px", height: "22.4px" }} />
              </Link>
            </Box>
          </DrawerHeaderCLossed>
        }

        <RouteList open={open} />

        <Divider />

      </Drawer >


    </>
  )
}

export default DrawerCustom