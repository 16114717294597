import React from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import HeaderText from 'jsx/components/AccountSetupForm/HeaderText';
import { useEffect, useState } from 'react';
import {
  getUserAccountInfo,
  onGetAccountSetup,
  onPostUserAnswers,
  onPutUserAnswers
} from 'redux/actions';
import { onGetCountries } from 'redux/reducers/sharedStateData';
import { getFormattedDate } from 'utils/getDateDMYFormat';
import { useHistory } from 'react-router-dom';
import { useAppLoading } from 'hooks/appLoading';
import { links } from 'static/links';
import { Box, IconButton, Paper, Stack, Tooltip } from '@mui/material';
import { gender } from 'constants/dropdownValues';
import FormikInputField from '../components/Account_Fields/FormikInputField';
import FormikRadioField from '../components/Account_Fields/FormikRadioField';
import FormikSelectFieldWithLabel from '../components/Account_Fields/AppSelectWithLabel';
import PhoneField from 'jsx/components/Account_Fields/PhoneFieldFormik';
import { FadeIn } from 'jsx/components/Shared/FadeIn';
import ConfirmationModal from 'jsx/components/AccountSetupForm/ConfirmationModal';
import LoaderWithText from 'jsx/components/Shared/LoaderWithText';
import DynamicQuestionAnswers from 'jsx/components/AccountSetupForm/DynamicQuestionAnswers';
import * as Yup from 'yup';
import { AnswerFieldType, InputCategoryEnum } from '../../constants';
import '../../scss/login/_Login.scss';
import { Container, Grid } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Loader from 'jsx/components/Shared/Loader';
import InfoIcon from '@mui/icons-material/Info';
import FormikInputFieldLifeTime from 'jsx/components/Account_Fields/FormikInputFieldLifeTime';
import { accountSetupFormValidation } from 'validate/Screen/AccountSetupFormValidation';

export default function AccountSetupForm() {
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const authLoading = useAppLoading('auth');
  const sharedStateDataLoading = useAppLoading('sharedStateData');
  const { countries } = useAppSelector(state => state.sharedData);
  const { user, userAccountDetails } = useAppSelector(state => state.auth);
  const [isConfirmationModalOpen, setConfirmationModal] = useState(false);
  const [dynamicFormikInitialValues, setDynamicFormikInitialValues] =
    useState<any>({});
  const [dynamicFormikValidationSchema, setDynamicFormikValidationSchema] =
    useState<any>({});
  const investment = localStorage.getItem('InvestmentAmount');
  const [investmentAmount] = useState(Math.ceil(Number(investment)));
  const [isTrading, setIsTrading] = useState('No');
  const [UKN, setUKN] = useState('');
  const [isLifeTime, setisLifeTime] = useState(false);

  const onChangeValueTrading = (e: any) => {
    setIsTrading(e.target.value);
    if ((e.target.value = 'No')) {
      setUKN('');
    }
  };

  const onChangeLifeTimeCheck = () => {
    setisLifeTime(!isLifeTime);
    if (isLifeTime) {
      formik.setFieldValue(
        'nationalIdentityExpiryDate',
        getFormattedDate(userAccountDetails?.identityCard?.dateOfExpiry, '/')
      );
    } else {
      formik.setFieldValue('nationalIdentityExpiryDate', '');
    }
    formik.setFieldValue('lifeTimeBit', !isLifeTime);
  };

  useEffect(() => {
    dispatch(onGetCountries());
  }, []);

  useEffect(() => {
    if (userAccountDetails?.identityCard?.countryOfStay) {
      formik.setFieldValue(
        'countryOfStay',
        userAccountDetails?.identityCard?.countryOfStay
      );
    }
  }, [dispatch, userAccountDetails?.identityCard]);

  useEffect(() => {
    let intervalId: any;
    if (!userAccountDetails?.identityCard?.fatherName) {
      intervalId = setInterval(() => {
        dispatch(onGetAccountSetup());
      }, 5000);
      return () => clearInterval(intervalId);
    } else {
      clearInterval(intervalId);
    }
  }, [dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId: user?.id,
      accountType: 0,
      name: userAccountDetails?.userName,
      fatherName: userAccountDetails?.identityCard?.fatherName,
      countryOfStay: userAccountDetails?.identityCard?.countryOfStay,
      nationalIdentityNumber:
        userAccountDetails?.identityCard?.identityCardNumber?.replace(/-/g, ''),
      nationalIdentityIssuanceDate: getFormattedDate(
        userAccountDetails?.identityCard?.dateOfIssue,
        '/'
      ),
      nationalIdentityExpiryDate: getFormattedDate(
        userAccountDetails?.identityCard?.dateOfExpiry,
        '/'
      ),
      gender: userAccountDetails?.identityCard?.gender === 'F' ? '0' : '1',
      dateOfBirth: getFormattedDate(userAccountDetails?.dateOfBirth, '/'),
      email: user?.email,
      mobile: `92${user?.phoneNumber?.slice(1)}`,
      lifeTimeBit: isLifeTime,
      ...dynamicFormikInitialValues
    },
    onSubmit: async (values: any) => {
      setConfirmationModal(true);
    },
    validationSchema: accountSetupFormValidation
  });

  const onSubmit = () => {
    setConfirmationModal(false);
    const genderEnum = formik?.values?.gender;

    const body = {
      fatherOrHusbandName: formik.values.fatherName,
      identityCardNumber: formik.values.nationalIdentityNumber,
      identityCardIssuanceDate: formik.values.nationalIdentityIssuanceDate,
      identityCardExpiryDate: formik.values.nationalIdentityExpiryDate,
      gender:
        genderEnum == 0
          ? 'Female'
          : genderEnum == 1
            ? 'Male'
            : genderEnum == 2
              ? 'Other'
              : 'None',
      countryOfStay: formik.values.countryOfStay,
      identityCardLifeTimeExpiry: isLifeTime,
      ukn: UKN
    };
    // dispatch(onPutUserAnswers(body));
    push(links.paymentOptions);
  };

  return (
    <>
      <Box
        component='main'
      >
        <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid
              item
              sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
              xs={12}
            >
              <HeaderText />
            </Grid>

            {userAccountDetails?.identityCard?.fatherName ? (
              <Grid
                item
                sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
                xs={12}
              >

                <Paper sx={{ px: 5, py: 3, display: 'flex', flexDirection: 'column', wordBreak: 'break-all' }}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={3}
                    style={{ width: '100%' }}
                  >
                    <FormikInputField
                      required
                      disabled={true}
                      formik={formik}
                      label='Name'
                      name='name'
                      placeholder='Enter Name'
                    />
                    <FormikInputField
                      required
                      formik={formik}
                      label="Father's Name"
                      name='fatherName'
                      placeholder="Enter Father's Name"
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={3}
                    style={{ marginTop: '1rem', width: '100%' }}
                  >
                    <FormikInputField
                      required
                      disabled={true}
                      formik={formik}
                      label='Email Address'
                      name='email'
                      placeholder='Enter Your Email'
                    />
                    <FormikInputField
                      required
                      formik={formik}
                      label='CNIC Number'
                      maxLength={13}
                      name='nationalIdentityNumber'
                      placeholder='Enter CNIC Number'
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={3}
                    style={{ marginTop: '1rem', width: '100%' }}
                  >
                    <FormikInputField
                      required
                      formik={formik}
                      label='Date of CNIC Issuance'
                      name='nationalIdentityIssuanceDate'
                      placeholder=''
                      type='Date'
                    />
                    <FormikInputFieldLifeTime
                      required
                      disabled={isLifeTime}
                      formik={formik}
                      isLifeTime={isLifeTime}
                      label='Date of CNIC Expiry'
                      name='nationalIdentityExpiryDate'
                      onChangeLifeTimeCheck={onChangeLifeTimeCheck}
                      placeholder=''
                      type='Date'
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={3}
                    style={{ marginTop: '1rem', width: '100%' }}
                  >
                    <PhoneField
                      country={'pk'}
                      countryCodeEditable={false}
                      disableCountryCode={false}
                      disableDropdown={true}
                      disabled={true}
                      fieldName='mobile'
                      formik={formik}
                      label='Mobile Number'
                      required={true}
                      value={formik.values.mobile}
                    />
                    <FormikInputField
                      required
                      disabled={true}
                      formik={formik}
                      label='Date Of Birth'
                      name='dateOfBirth'
                      placeholder=''
                      type='Date'
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={3}
                    style={{ marginTop: '1rem', width: '100%' }}
                  >
                    <FormikRadioField
                      required
                      fieldName='gender'
                      fields={gender}
                      formik={formik}
                      label='Gender'
                      singleLine={true}
                    />
                    <FormikSelectFieldWithLabel
                      required
                      disabled={false}
                      fieldName='countryOfStay'
                      fields={countries}
                      formik={formik}
                      label='Country of stay'
                      onChange={(val, option) => {
                        formik.setFieldValue('countryOfStay', val);
                      }}
                      placeholder='Select'
                      value={formik.values.countryOfStay}
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={3}
                    style={{ marginTop: '1rem', width: '100%' }}
                  >
                    <Col>
                      <Row>
                        <label className='mb-2 field-label'>
                          <strong
                            style={{ fontFamily: 'PlayFair', color: '#000000' }}
                          >
                            Existing stock trading account
                            <Tooltip title='Your Unique Know Your Customer (UKN) is provided by NCCPL. Please contact your broker.'>
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </strong>
                        </label>

                        <div
                          onChange={onChangeValueTrading}
                          style={{ display: 'flex' }}
                        >
                          <Form.Check
                            checked={isTrading === 'No'}
                            id={`stock`}
                            label={`No`}
                            name='stock'
                            style={{ marginTop: '5px' }}
                            type='radio'
                            value='No'
                          />

                          <Form.Check
                            checked={isTrading === 'Yes'}
                            id={`stock`}
                            label={`Yes`}
                            name='stock'
                            style={{ marginLeft: '5px', marginTop: '5px' }}
                            type='radio'
                            value='Yes'
                          />
                        </div>
                      </Row>

                      <Row>
                        <input
                          className='text-box-custom'
                          disabled={isTrading === 'No'}
                          onChange={e => setUKN(e.target.value)}
                          placeholder='Enter UKN number'
                          // type={'number'}
                          value={UKN}
                        />
                      </Row>
                    </Col>
                  </Stack>
                  <Row>
                  </Row>
                  <ConfirmationModal
                    investmentAmount={investmentAmount}
                    isModalOpen={isConfirmationModalOpen}
                    onClose={() => setConfirmationModal(false)}
                    onSubmit={() => onSubmit()}
                  />
                  <div style={{ display: 'flex' }}>
                    <button
                      className='NextButton mt-5'
                      disabled={
                        !!formik?.errors?.nationalIdentityIssuanceDate ||
                        !!formik?.errors?.nationalIdentityExpiryDate ||
                        !!formik?.errors?.fatherName ||
                        !!formik?.errors?.nationalIdentityNumber
                      }
                      onClick={e => {
                        e.preventDefault();
                        formik.submitForm();
                      }}
                      style={{ marginLeft: 'auto' }}
                    >
                      Proceed
                    </button>
                  </div>
                </Paper>
              </Grid>
            ) : (
              <Grid
                item
                sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
                xs={12}
              >
                <LoaderWithText
                  text={'Please wait, your details are being extracted.'}
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
