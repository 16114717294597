import * as Yup from 'yup';

export const CnicValidation = Yup.object().shape({
    consentCheck: Yup.bool().oneOf([true], 'Your consent is required'),
    UserNICFront: Yup.mixed()
        .required('ID Card front picture is required')
        .test('required', '', function (value, context) {
            if (value && value.size > 10000000) {
                return this.createError({
                    message: "File size should be 10 MB"
                });
            }
            else if (value && value.size < 5000) {
                return this.createError({
                    message: "File size should be 5 KB"
                });
            }
            else if (value == undefined) {
                return this.createError({
                    message: "ID Card front picture is required"
                });
            }
            return true
        })
    ,
    UserNICBack: Yup.mixed()
        .required('ID Card back picture is required')
        .test('required', '', function (value, context) {
            if (value && value.size > 10000000) {
                return this.createError({
                    message: "File size should be 10 MB"
                });
            }
            else if (value && value.size < 5000) {
                return this.createError({
                    message: "File size should be 5 KB"
                });
            }
            else if (value == undefined) {
                return this.createError({
                    message: "ID Card back picture is required"
                });
            }
            return true
        })
})