const Fields:any = [
    {
        id:1,
        question: "Are you a U.S. Resident?",
        answer: [
            {
                id:2,
                disabled:false,
                label: "Yes",
                value: "Yes"
            },
            {
                id:3,
                disabled:false,
                label: "No",
                value: "No"
            },
        ]
    },
    {
        id:4,
        question: "Are you a U.S. Citizen?",
        answer: [
            {
                id:5,
                disabled:false,
                label: "Yes",
                value: "Yes"
            },
            {
                id:6,
                disabled:false,
                label: "No",
                value: "No"
            },
        ]
    },
    {
        id:7,
        question: "Are you holding a U.S. Permanent Resident Card (Green Card)?",
        answer: [
            {
                id:8,
                disabled:false,
                label: "Yes",
                value: "Yes"
            },
            {
                id:9,
                disabled:false,
                label: "No",
                value: "No"
            },
        ]
    },
    {
        id:10,
        question: "Are you registered in the U.S. as a tax payer?",
        answer: [
            {
                id:11,
                disabled:false,
                label: "Yes",
                value: "Yes"
            },
            {
                id:12,
                disabled:false,
                label: "No",
                value: "No"
            },
        ]
    },
    {
        id:13,
        question: "Are you a tax resident of any country apart from Pakistan or United States of America?",
        answer: [
            {
                id:14,
                disabled:false,
                label: "Yes",
                value: "Yes"
            },
            {
                id:15,
                disabled:false,
                label: "No",
                value: "No"
            },
        ]
    },
    {
        id:27,
        question: "Country",
        
    },
    {
        id:16,
        question: "City",
        
    },
    {
        id:17,
        question: "Do you have a Taxpayer Identification Number (TIN) ?",
        answer: [
            {
                id:18,
                disabled:false,
                label: "Yes",
                value: "Yes"
            },
            {
                id:19,
                disabled:false,
                label: "No",
                value: "No"
            },
        ]
    },
    {
        id:20,
        question: "Enter Taxpayer Identification Number (TIN) ",
        
    },
    {
        id:21,
        question: "Select a reason",
        answer: [
            {
                id:22,
                disabled:false,
                label: "Does not issue TIN to its Residents.",
                value: "Does not issue TIN to its Residents."
            },
            {
                id:23,
                disabled:false,
                label: "The Account Holder is otherwise unable to obtain a TIN.",
                value: "The Account Holder is otherwise unable to obtain a TIN."
            },{
                id:24,
                disabled:false,
                label: "No TIN is required.",
                value: "No TIN is required."
            },
        ]
    },
    {
        id:25,
        question: "",
        answer: [
            {
                id:26,
                disabled:false,
                label: "I hereby confirm that the information provided above is true, accurate and complete.",
                value: "Yes"
            },
            
        ]
        
    },
]

export default Fields