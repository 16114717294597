import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import infoIcon from "Latest/Images/svg/InfoOutlined.svg"
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: "24px",
  borderRadius: "12px",
  width: { xs: "95%", sm: "auto", md: "400px" },
};

export default function SubmitModal({ handleClose, handleModalSubmitBtn,  open }: any) {
  const classes = useStyles();

  const handleBtn = () => {
    handleModalSubmitBtn()
  }
  
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
            <img src={infoIcon} />

            <IconButton aria-label="close" component="label" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography sx={{ mt: "16px", mb: "32px" }}> Are you sure you wish to submit information for review? </Typography>

          <Box sx={{ display: "flex", gap: "12px" }}>
            <Button fullWidth variant='outlined' className={classes.neutralButton} onClick={handleClose}> Cancel</Button>
            <Button fullWidth variant='contained' color={"secondary"} onClick={handleBtn}>Yes</Button>
          </Box>

        </Box>
      </Modal>
    </div>
  );
}