export const cryptoGraphy = (file: any) => {
    return new Promise<string>((resolve, reject) => {
      const fileReader:any = new FileReader();
      fileReader.onload = () => {
        const buffer = new Uint8Array(fileReader.result);
        crypto.subtle.digest('SHA-512', buffer)
          .then(hashBuffer => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashString = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
            resolve(hashString);
          })
          .catch(error => {
            console.error('Error calculating SHA-512 hash:', error);
            reject(error);
          });
      };
      fileReader.readAsArrayBuffer(file);
    });
  };