import React, { useState } from 'react'
import TierKycRejectAlert from '../Alerts/tierKycRejectAlert'
import TierKycWarningAlert from '../Alerts/tierKycWarningAlert'
import TierKycInfoAlert from '../Alerts/tierKycInfoAlert'
import { AnimatePresence, motion } from 'framer-motion'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { Alert, Box, Collapse, Skeleton } from '@mui/material'
import { onGetNotifications, onPatchNotifications } from 'redux/reducers/sharedStateData'
import { useAppLoading } from 'hooks/appLoading'

const Tier_KYC_Notification = () => {

    const [isCloseLocal, setIsCloseLocal] = useState(true)

    const { userAccountDetails } = useAppSelector(state => state.auth);

    const { notficationHub } = useAppSelector(state => state.sharedData);

    const dispatch = useAppDispatch();

    const handleClose = () => {
        setIsCloseLocal(false)
        const formData: any = new FormData();
        formData.append('EnableNotifications', false);
        formData.append('NotificationType', "kyc");
        dispatch(onPatchNotifications(formData))
    }

    const statusKYCNotification: any = {
        "ApprovalSucceeded": <TierKycInfoAlert handleClose={handleClose} />,
        "ApprovalHalted": <TierKycWarningAlert handleClose={handleClose} />,
        "ApprovalFailed": <TierKycRejectAlert handleClose={handleClose} />,
    }
    const loading = useAppLoading('auth');

    return (
        <>
            {loading ?
                <SkeletonLoading />
                :
                <Collapse in={isCloseLocal} timeout={{ enter: 500, exit: 500 }} >
                    {notficationHub?.KYC == "True" && statusKYCNotification[userAccountDetails?.kyc?.status]}
                </Collapse>
            }
        </>
    )

}

export default Tier_KYC_Notification

const SkeletonLoading = () => {
    return (
        <Box sx={{ px: { xs: "16px", sm: "32px" }, pb: { xs: "16px", sm: "24px" } }}>
            <Skeleton variant="rounded" width={"100%"} height={20} />
        </Box>
    )
}

