import { Col, Row } from 'react-bootstrap';
import { memo } from 'react';
import PhoneInput from 'react-phone-input-2';
import { CountryData } from 'jsx/pages/Signup';
import InputLabel from '@mui/material/InputLabel';
import { Box, Typography } from '@mui/material';

const PhoneField = ({
  autoFocus = false,
  bottomInfo,
  col = 12,
  country,
  countryCodeEditable,
  disableCountryCode,
  disableDropdown,
  disabled = false,
  dropdownStyle={},
  enableSearch = false,
  fieldName,
  formik,
  isMutual = false,
  label,
  nestedFieldName,
  onChange,
  phoneError = '',
  required = false,
  topInfo,
  type = 'text',
  value
}: PhoneFieldProps) => {
  const changeHandle = (value: string, data: CountryData) => {
    formik.setFieldValue(fieldName, value);
  };
  const formikTouch = formik.touched as any;
  const formikError = formik.errors as any;

  let FieldError = "";
  let FieldTouch = false;
  let NestedFieldError = "";
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }

  return (
    <Box>
      {label && (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "6px" }}>
          <Typography variant='body2' color="#1D2939">
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </Typography>
        </Box>
      )}

      <PhoneInput
        country={country}
        countryCodeEditable={countryCodeEditable}
        disableCountryCode={disableCountryCode}
        disableDropdown={disableDropdown}
        enableSearch={enableSearch}
        disabled={disabled}
        dropdownStyle = {dropdownStyle}
        onChange={(value: string, data: CountryData) => changeHandle(value, data)}
        value={value}
      />

      {bottomInfo && (
          <Box className='small-info mb-2 mt-1 text-muted'>{bottomInfo}</Box>
      )}

      {formik && FieldTouch && FieldError && (
          <span className='mt-1 text-danger'>
            {FieldError}
          </span>
      )}

      {formik && NestedFieldTouch && NestedFieldError && (
          <span className='mt-1 text-danger'>
            {NestedFieldError}
          </span>
      )}
    </Box>
  );
};

export default memo(PhoneField);

interface PhoneFieldProps {
  country: string;
  countryCodeEditable: boolean;
  disableCountryCode: boolean;
  disableDropdown: boolean;
  dropdownStyle?: any;
  enableSearch?: boolean;
  formik: any;
  onChange?: (value: string, data: CountryData) => void;
  value: string;
  autoFocus?: boolean;
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  label: string | JSX.Element;
  fieldName: string;
  nestedFieldName?: any;
  required?: boolean;
  topInfo?: string;
  type?: string;
  bottomInfo?: string | JSX.Element;
  isMutual?: boolean;
  phoneError?: string;
}
