export const accordianListBoxStyle = {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
}

export const accordianListItemStyle = {
    fontFamily: 'Inter,sans-serif',
    m: 0.2,
    display: "flex",
    justifyContent: "space-between",
    color: "#202020",
    lineHeight: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px"
}

export const accordianListTitleStyle = {
    fontFamily: 'Inter,sans-serif', color: "#202020", lineHeight: "24px", fontStyle: "normal", fontWeight: 700, fontSize: "16px"
    , display: "flex", alignItems: "center", gap: "10px"
}

export const accordianListDetailStyle = { overflow: 'auto', height: '8rem' }

export const accordianSummaryStyle = {
    "& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
        borderBottom: "1px solid #EDEDED",
        padding: "10px 4px 14px",
        margin: 0,
    },
    "& .css-17o5nyn": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .css-17o5nyn.Mui-expanded": {
        display: "flex",
        justifyContent: "space-between"
    }

}

export const accordianListAmountStyle = {
    fontFamily: 'Inter,sans-serif',
    color: "#6B7280",
    lineHeight: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "17px"
}