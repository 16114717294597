import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { withResizeDetector } from 'react-resize-detector';
import AppErrors from 'jsx/containers/AppErrors';
import Routes from 'jsx/routes';
import ThemeContextProvider from 'context/ThemeContext';
import { store, persistor } from 'redux/store';
import 'vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import 'css/style.css';
import './index.css';
import { USER_MANAGER_BASE_URL } from 'constants/settings';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ToastContainer } from 'react-toastify';
import AppLink from "./Latest/HelpCrunch/helpCrunchTrigger"

const App = () => {
  const [theme, colorMode] = useMode();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <AppErrors />
            <Router>
              <Routes />
              <AppLink />
            </Router>
            <ToastContainer />
          </ThemeProvider>
        </ColorModeContext.Provider>
      </PersistGate>
    </Provider>
  )
}
export default withResizeDetector(App);