import * as Yup from 'yup';
export const OccupationalGoal = Yup.object({
  CNIC: Yup.string()
    .required('This field is Required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'CNIC number is not valid'
    )
    .length(13, 'CNIC number is not valid')
});
