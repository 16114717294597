export const sliderStyle = {
    height: "12px",

    '& .MuiSlider-thumb': {
        background: "#432F87",
        border: "2px solid #FFFFFF"
    },
    '& .MuiSlider-rail': {
        background: "#E8E2FD",
        border: "1px solid #EAF8FB",
        borderRadius: "12px"
    },
    '& .MuiSlider-track': {
        background: "#E8E2FD",
        border: "1px solid #EAF8FB",
        borderRadius: "12px"
    },
    '& .MuiSlider-valueLabel': {
        background: "#432F87",
        borderRadius: "6px",
        transform: "translateY(-100%) scale(1)"
    },
    '& .rangeLabel': {
        fontSize: '14px',
        // fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        color: '#ffffff'
    }
}

export const sliderBoxStyle = { display: "flex", alignItems: "end", flexWrap: { lg: 'noWrap', md: 'wrap', sm: 'noWrap', xs: 'wrap' } }

export const customSliderInputField = {
    minWidth: "150px",
    height: "48px",
    background: '#FFFFFF',
    border: '1px solid #EDEDED',
    borderRadius: '6px',
    marginLeft: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    display: 'flex',
    alignItems: 'center',
}

export const calculatorWrapper = {
    background: '#FFFFFF',
    border: { md: '1px solid #EDEDED', xs: '0px solid #EDEDED' },
    borderRadius: '10px',
    marginTop: { sm: '20px', xs: '20px' },
    px: { md: '20px', xs: '0' },
    pt: { md: '40px', xs: '0' },
    pb: { md: '20px', xs: '0' },
    '& svg.recharts-surface .recharts-rectangle.recharts-tooltip-cursor': {
        fill: 'transparent'
    }
}

export const investYearSelectWrapperStyle = {
    paddingLeft: '10px',
    paddingBottom: '20px',
    '& .MuiOutlinedInput-root.MuiOutlinedInput-root fieldset': {
        borderColor: '#EDEDED',
        borderRadius: '4px',
    },
    '& .MuiSelect-select': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        letterSpacing: '-0.25px',
        color: '#838799',
    }
}

export const tooltipWrapper = {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '10px'
}

export const rechartsBarLableStyle = {
    backgroundColor: '#000',
    '& tspan': {

    }
}

export const legendWrapperStyle = {
    paddingLeft: '20px',
    backgroundColor: '#fff'
}

export const disclaimerTypo = {
    color: "#202020",
    fontFamily: "Inter",
    fontSize: "12px!important",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%", /* 18px */


}