export const projectionsMainTypoStyle = {
    color: "#202020",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "155%"
}
export const projectionsAmountStyle = {
    color: "#202020",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "140%"
}
export const projectionsAmountTitleStyle = {
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#666666"
}

export const projectionChartTooltipDivStyle = {
    background: "#101828",
    borderRadius: "8px",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.5rem",
    height:"4rem",
    cursor:"pointer"
}

export const projectionChartTooltipValueStyle = {
    color: "#FFFFFF",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "18px",
}