export const ApiMessage: any = {
    "EMAIL_ALREADY_EXISTS": "Account with this email already exists",
    "ACCOUNT_SETUP_INCOMPLETE": "Account setup is incomplete",
    "NO_STOCK_BUY": "No stock buy",
    "NO_MARKET_FEED": "Latest price not found",
    "LESS_BALANCE": "Could not process due to insufficient balance",
    "INSUFFICIENT_BALANCE": "Could not process due to insufficient balance",
    "BASKET_NOT_FOUND": "No basket found",
    "BATCH_ORDER_FAIL": "Something went wrong request canceled",
    "PAYMENT_RECEIPT_UPLOAD_FAIL": "Payment receipt upload failed",
    "BASKET_NAME_INVALID": "Basket name is invalid",
    "INVALID_TYPE": "Could not process, invalid type",
    "IBAN_INVALID": "Invalid IBAN Number",
    "UNABLE_TO_EXTRACT_IDENTITY_CARD": "Unable to extract your CNIC",
    "ACCOUNT_NUMBER_INVALID": "Account number invalid",
    "GOAL_ID_INVALID": "Goal ID is invalid contact your support center",
    "PAYMENT_RECEIPT_INVALID": "Proof of payment must be a PDF, PNG or JPEG file",
    "ACCOUNT_TITLE_INVALID": "Account title invalid",
    "BANK_NAME_INVALID": "Bank name invalid",
    "PAID_AMOUNT_INVALID": "Paid amount invalid",
    "PAYMENT_FAIL": "Payment failed",
    "CASH_FUND_ALREADY_EXIST": "Fund is already exist",
    "ACCOUNT_TITLE_REQUIRED": "Account title is required",
    "ACCOUNT_TITLE_MAXIMUM_LENGTH_INVALID": "Account title max length is invalid",
    "ACCOUNT_NUMBER_REQUIRED": "Account number is required",
    "ACCOUNT_NUMBER_ALREADY_EXIST": "Account number already exist",
    "IBAN_NUMBER_INVALID": "IBAN number invalid",
    "INVALID_BANK_NAME_IN_IBAN": "Invalid bank name",
    "TRANSACTION_REFERENCE_NUMBER_ALREADY_EXIST": "Transaction id already exist",
    "SAVING_AMOUNT_INVALID": "Saving amount invalid",
    "City_INVALID": "City invalid",
    "Province_INVALID": "Province invalid",
    "IBAN_NOT_ALLOWED": "Bank not found",
    "MailingAddress_Lines_INVALID": "Mailing address is required",
    "PermanentAddress_Lines_INVALID": "Permanent address is required",
    "PermanentAddress_City_INVALID": "Permanent address city invalid",
    "MailingAddress_City_INVALID": "Mailing address city invalid",
    "PermanentAddress_Province_INVALID": "Permanent address province invalid",
    "MailingAddress_Province_INVALID": "Mailing address province invalid",
    "IdentityCardNumber_DUPLICATE":"CNIC is already exists",
    "PHONE_NUMBER_ALREADY_EXISTS": "Phone number already exists",
    "DUPLICATE_USERNAME":"Email address is already registered",
    "Invalid phone number format." : "Invalid phone number format.",
    "IMAGE_ALREADY_EXISTS":"Image already exists",
    "IMAGE_INVALID":"Invalid Image",
    "DOCUMENT_SIZE_INVALID" :"Invalid Size",
    "INCOME_PROOF_INVALID_SIZE":"Invalid Size",
    "INCOME_PROOF_TYPE_INVALID":"Invalid file type",
    "CNIC_INVALID_SIZE":"Cnic invalid size",
    "DOCUMENT_TYPE_INVALID":"PNG, JPG, PDF allowed only",
    "DOCUMENT_ALREADY_EXISTS":"Document already exists",
    "USER_CNIC_INVALID_SIZE":"Invalid size",
    "CNIC_CORRUPTED":"Cnic corrupted",
    "REDEMPTION_ID_OR_CODE_INVALID":"Invalid or expired OTP!",
    "FAILED_TO_DOWNLOAD_ACCOUNT_STATEMENT":"Try again in a while"
}