import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import useStylesAlertWarning from 'Latest/ComponentV1/CustomMakeStyle/customWarningAlert';
import { useAppSelector } from 'hooks/reduxHooks';
import React from 'react'
import { useHistory } from 'react-router-dom';
import IconWarning from "Latest/Images/svg/IconWarning.svg"


const ReuploadCnicWarningAlert = ({ handleClose }: any) => {

    const classes = useStylesAlertWarning();
    const { push } = useHistory();
    const { userAccountDetails } = useAppSelector(state => state.auth);

    const getLastestUserNicFront = (data: any) => {
          
        if (data) {
            for (let i = data?.length - 1; i >= 0; i--) {
                if (data[i].type === "UserNicFront") {
                    return data[i];
                }
            }
        }

        return null;
    };
    console.log(">>>>>data.length", userAccountDetails?.documentsa)   
    const lastUserNicFront = getLastestUserNicFront(userAccountDetails?.documents);

    return (
        <>
            <Box>
                <Alert severity="warning" className={classes.customAlert}
                    // onClose={handleClose} 
                    icon={false}>
                    <AlertTitle className={classes.customAlertTitle} sx={{ fontWeight: 500 }}>
                        <img src={IconWarning} />
                        Please reupload your CNIC images
                    </AlertTitle>
                    <Typography className={classes.customText}>{lastUserNicFront?.comment || "Your picture is not clear enough. Kindly reupload your CNIC again."}</Typography>
                    <Button className={classes.customButton} sx={{ borderRadius: "100px", height: "37px", textTransform: "unset", fontWeight: 500, }} onClick={() => push("/myprofile?value=3")}>Reupload my CNIC</Button>

                    {/* <Button color="warning" variant="contained" sx={{ mt: 1 }} onClick={() => push("/myprofile?value=3")}>Reupload my CNIC</Button> */}
                </Alert>
            </Box>
        </>
    )
}

export default ReuploadCnicWarningAlert;