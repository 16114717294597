import CustomCard from 'Latest/ComponentV1/CustomCard'
import React, { useEffect, useState } from 'react'
import CustomCard1 from 'Latest/ComponentV1/Customcard1'
import { useHistory } from 'react-router-dom';
import InvestLogo from "Latest/Images/investLog.png"
import { componentV1Style } from 'Latest/ComponentV1/style/style';
import { Box, Button, ClickAwayListener, Divider, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import TableStatusLabel from 'Latest/ComponentV1/TableStatusLabel';
import { CurrencyFormatterWithDecimal } from 'utils/calculation';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/system';
import CustomChip from 'Latest/ComponentV1/customChip';
import { summaryTab } from '../style/tabsStyle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { tokens } from 'theme';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import Big from 'big.js';
import CustomSliderTwoColor from 'Latest/ComponentV1/customSliderTwoColor';
import { goalFlowIdAction, goalFlowPathAction, goalIdAction } from 'redux/actions';
import { addGoalBodyAction } from 'redux/reducers/sharedStateData';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import { ApprovedCard, DepositPendingCard, VerificationPendingCard } from './Cards';
import infoIconSvg from "Latest/Images/svg/Info.svg";

const tootltipContent = "Return is extended to a one year period with the assumption that performance remains the same as before. This is also called an annualized value."

const InvestCard = () => {

    const { push } = useHistory();
    const dispatch = useAppDispatch();
    const { isGIAllowed } = useAppSelector(state => state.auth);
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
    const [investGoal, setInvestGoal] = useState([])
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if (dashboardDetail?.goals?.length > 0) {
            const goal = dashboardDetail?.goals?.filter((item: any, ind: number) => item?.category == "GeneralInvestment")
            setInvestGoal(goal)
        }
    }, [dashboardDetail])

    const handleClick = () => {
        if (isGIAllowed) {
            dispatch(goalIdAction(""))              //save Goal id i.e cashfundId , GI id  
            dispatch(goalFlowIdAction(""))        //save Goal category i.e cashfund == 100 
            dispatch(goalFlowPathAction(''))    //save Path i.e invest , wizard 
            dispatch(addGoalBodyAction({}));
            push("/Invest", { RedirectLink: "dashboard" })
        }
    }

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setIsTooltipOpen(true);
    };

    const customTooltip = () => {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={isTooltipOpen}
                    title={tootltipContent}
                >
                    <img src={infoIconSvg} alt='info' onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleTooltipOpen();
                    }} style={{ cursor: "pointer" }} />
                </Tooltip>
            </ClickAwayListener>
        )
    }

    const renderGrids = ({ item }: any, num: number) => {
        if (
            !item?.isApprovedPayment && item?.potDetails?.initialInvestmentAmount == 0
        ) {
            return <DepositPendingCard data={item} margin={num} />;
        }
        else if (
            !item?.isApprovedPayment && item?.potDetails?.initialInvestmentAmount > 500 && item?.lastTransactionStatus?.toLowerCase()?.includes("fail")
        ) {
            return <DepositPendingCard data={item} margin={num} />;
        }
        else if (
            !item?.isApprovedPayment && item?.potDetails?.initialInvestmentAmount >= 500 && !item?.lastTransactionStatus?.toLowerCase()?.includes("fail")
        ) {
            return <VerificationPendingCard data={item} margin={num} />
        }
        else if (
            item?.isApprovedPayment && item?.goalStatus == "Abandon"
        ) {
            return <ApprovedCard data={item} component={customTooltip()} margin={num} />
        }
        else if (
            item?.isApprovedPayment && (item?.potDetails?.initialInvestmentAmount >= 500 || item?.potDetails?.portfolioAmount > 0)
        ) {
            return <ApprovedCard data={item} component={customTooltip()} margin={num} onClick={() => push("/viewDetail", { goalInfo: item })} />
        }
        else {
            return null; // Return null or another default component if none of the conditions are met
        }
    };

    return (
        <>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"}>
                <Typography variant='h4' color={colors.purple[100]} lineHeight={"30px"}>Mahaana Invest</Typography>
                <LoadingBtnCustom disabled={!isGIAllowed} btnTitle={isGIAllowed ? "Add goal" : "Coming soon"} style={{ py: 0, height: "37px", fontWeight: 400, minWidth: "fit-content" }} handleOnClick={handleClick} />
            </Box>

            {investGoal?.length > 0 && <Grid container mt={2} style={{ gap: "16px" }} >
                {investGoal?.map((item: any, index: number) => renderGrids({ item }, 2))}
            </Grid>
            }
        </>
    )
}

export default InvestCard