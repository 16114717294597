export const HeadingStyle = {
    color: "#202020",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: { md: "32px", sm: "32px", xs: "22px" },
    lineHeight: "140%",
    textAlign: { md: 'left', sm: 'left', xs: 'center' }
}
export const subHeadingStyle = {
    color: "#202020",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "140%",
}
export const subHeading2Style = {
    color: "#202020",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "140%",
}

export const subHeadingBlueStyle = {
    color: "#403081",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: { md: "20px", sm: '20px', xs: '18px' },
    lineHeight: "140%",
}

export const subHeadingSecStyle = {
    color: "#422E84",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: { md: "16px", sm: '16px', xs: '18px' },
    lineHeight: "normal",
}


export const topInfoWithLabelStyles = {
    '& .field-label': {
        margin: '0 !important',
    },
    '& ul': {
        pb: '20px',
        pt: '5px',
        pl: '20px',
        '& .small-info': {
            mt: '0 !important',
            mb: '3px !important',
            //color: "#344054",
            fontFamily: 'Inter,sans-serif',
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: { md: "13px", sm: '13px', xs: '10px' },
            lineHeight: "140%",
            listStyle: 'disc'
        }
    },
}
export const juniorHeadingStyle = {
    color: "#89879f",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: { md: "13px", sm: '13px', xs: '10px' },
    lineHeight: "140%",
    pt: '3px',
}

export const alignCenterStyle = { display: "flex", justifyContent: "center" }
export const containerMain = { my: "2rem" }
export const containerMainBox = {
    background: "#FFFFFF", borderRadius: "12px", border: "1px solid #EDEDED",
    '& .mui-date-picker .MuiInputBase-root': {
        pr: '14px',
        borderRadius: '8px !important',
        '& fieldset': {
            border: '1px solid #D0D5DD',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        },
        '& input': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
        }
    }
}
export const mainColMargin = { marginLeft: "1rem", marginTop: "2.5rem", marginBottom: "2rem", paddingLeft: 0 }
export const linkSpan = {
    fontWeight: 500,
    lineHeight: "155%",
    color: "#422E84",
    cursor: "pointer",
    "a:hover": {
        color: "#43bed8f0",
    }
}


export const accountsetupBtnStyles = {
    '& .col-md-12': {
        p: 0,
        '& .MuiBox-root': {
            ml: 0,
            '& button': {
                m: { md: '5px 0px', sm: '5px 0px', xs: '5px 10px' }
            }
        }
    }
}


export const WrongCnicModalStyles = {
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: "#FFFFFF",
        border: "1px solid #EDEDED",
        borderRadius: "12px",
        overflow: "auto",
        maxHeight: { xs: "80vh", sm: "90vh" },
        width: { md: 'auto', sm: 'auto', xs: "90%" },
        p: '25px 20px  !important',
    },
    imageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        pt: 2,
        '& img': {
            width: '99.29px',
            height: 'auto',
            maxWidth: '100%'
        }
    },

    contentWrapper: {
        '& p': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '135%',
            textAlign: 'center',
            color: '#202020',
            py: 4,
        },
        '& .col-md-12': {
            p: 0,
            '& div': {
                justifyContent: 'space-evenly !important',
                flexWrap: 'wrap'
            },
            '& .BackButton': {
                background: '#FFFFFF',
                border: '1px solid #D0D5DD',
                color: '#344054'
            },
            '& .NextButton': {
                background: '#43BED8',
                border: '1px solid #43BED8',
                color: '#FFFFFF'
            },
        },
        '& button': {
            minWidth: '189px',
            minHeight: '44px',
            borderRadius: '8px',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
        }
    },

    containerV2: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: "#FFFFFF",
        borderRadius: "12px",
        overflow: "auto",
        maxHeight: { xs: "80vh", sm: "90vh" },
        width: { md: 'auto', sm: 'auto', xs: "90%" },
        padding: "24px 0 !important",
        boxShadow: "0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)",
    },
    wrapperV2: {
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        '& .btnWrapper .MuiBox-root': {
            flexDirection: { md: "row", xs: "column" },
            '& button': {
                width: { md: "auto", xs: "100%" }
            }
        }
    },
};