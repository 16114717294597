
export const ProvinceName = [
    {
        "id": "637f8d28bd710b7245a0e0a0",
        "name": "Sindh",
        "country": "Pakistan"
    },
    {
        "id": "637f8d3abd710b7245a0e0a1",
        "name": "Punjab",
        "country": "Pakistan"
    },
    {
        "id": "63a5b9b8e0201df34a78b669",
        "name": "Khyber Pakhtunkhwa",
        "country": "Pakistan"
    },
    {
        "id": "63a5b9d0e0201df34a78b66b",
        "name": "Balochistan",
        "country": "Pakistan"
    },
    {
        "id": "63a5b9dfe0201df34a78b66d",
        "name": "Federal Capital",
        "country": "Pakistan"
    },
    {
        "id": "63a5ba01e0201df34a78b66f",
        "name": "A.J.K.",
        "country": "Pakistan"
    },
    {
        "id": "63a5ba1fe0201df34a78b671",
        "name": "FATA / FANA",
        "country": "Pakistan"
    }
]
