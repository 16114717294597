import { Box, Divider, Grid, Snackbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import CustomUpload from 'Latest/ComponentV1/CustomUpload'
import DepositAmtCard from 'Latest/ComponentV1/DepositAmtCard'
import React, { useState } from 'react'
import copyicon from "Latest/Images/svg/copyicon.svg"
import qrIcon from "Latest/Images/svg/qr.svg"
import Checkmark from 'react-typescript-checkmark'
import QRCodeModal from 'Latest/ModalV1/QRcode'


const DetailCardComp = ({ depositAmt, depositDetailArr, fieldName, formik, singleCard = false, upperPDFErr }: any) => {

    const [textToCopy, setTextToCopy] = useState(false)

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleSaveClipBoard = async (copyMe: string) => {
        await navigator.clipboard.writeText(copyMe)
        setTextToCopy(true)
        setTimeout(() => {
            setTextToCopy(false)
        }, 3000);
    }


    return (
        <>
            <QRCodeModal handleOpen={handleOpenModal} handleClose={handleCloseModal} open={openModal} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", flex: 1 }}>
                {depositDetailArr.map((item: any, index: number) => (

                    <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: "2px" }} >
                        <Typography variant='body2' color="#666" lineHeight={"normal"} sx={{ width: "157px!important", flex: { xs: 0.7, md: "none" } }}>{item.title}</Typography>

                        <Typography variant='h6' color="#202020" lineHeight={"normal"} sx={{ display: "flex", alignItems: "center", gap: "17px", flex: { xs: 1, md: "none" }, width: "100%" }}>
                            {item.value}
                            {index == 2 &&
                                <Box sx={{ display: "flex", }}>
                                    <Box>
                                        {textToCopy ?
                                            <Box sx={{ backgroundColor: "#e8ebec", borderRadius: "8px" }}>
                                                <Checkmark size='md' backgroundColor={"transparent"} checkColor={"green"} />
                                            </Box>
                                            :
                                            <img src={copyicon} style={{ cursor: "pointer" }} onClick={() => handleSaveClipBoard(item.value)} />}

                                    </Box>
                                    {/* {!smScreen && <img src={qrIcon} style={{ cursor: "pointer", paddingLeft: "8px" }} onClick={handleOpenModal} />} */}
                                </Box>
                            }</Typography>

                    </Box>
                ))}
            </Box>

            <Divider sx={{ my: "20px" }} />

            <Grid container spacing={"24px"}>

                <Grid item xs={12} lg={singleCard ? 6 : 12} >
                    <DepositAmtCard title={"Deposit Amount"} amt={depositAmt} />
                </Grid>

                <Grid item xs={12} lg={singleCard ? 6 : 12}>
                    <CustomUpload formik={formik} fieldName={fieldName} upperPDFErr={upperPDFErr} tooltip={"File format: JPG, PNG, PDF"} />
                </Grid>



            </Grid>
        </>
    )
}

export default DetailCardComp