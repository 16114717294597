import { useAppLoading } from "hooks/appLoading";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Box, Collapse, Skeleton } from '@mui/material'
import { useState } from "react";
import ReuploadCnicWarningAlert from "../Alerts/reuploadCnicWarningAlert";
import { onPatchNotifications } from "redux/reducers/sharedStateData";
import FreezAccWarningAlert from "../Alerts/freezAccWarningAlert";

const AccFreezNotification = () => {

    const loading = useAppLoading('auth');
    const dispatch = useAppDispatch();
    const [isCloseLocal, setIsCloseLocal] = useState(true);
    const { userAccountDetails } = useAppSelector(state => state.auth);

    return (
        <>
            {loading ?
                <SkeletonLoading />
                :
                <Collapse in={isCloseLocal} timeout={{ enter: 500, exit: 500 }} >
                    {userAccountDetails?.restriction?.accountFreeze &&
                        <FreezAccWarningAlert />
                    }
                </Collapse>
            }
        </>
    )
}

export default AccFreezNotification;


const SkeletonLoading = () => {
    return (
        <Box sx={{ px: { xs: "16px", sm: "32px" }, pb: { xs: "16px", sm: "24px" } }}>
            <Skeleton variant="rounded" width={"100%"} height={20} />
        </Box>
    )
}