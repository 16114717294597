import { Box, Button, Container, Grid, IconButton, Modal, Skeleton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef, useState, useCallback, useEffect, useLayoutEffect } from 'react';
import Webcam from 'react-webcam';
import CloseIcon from '@mui/icons-material/Close';
import { webCamStyle ,SelfieWebcamStyle } from './style';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { HeadingStyle, subHeadingStyle } from 'Latest/Style/Main';
import LoadingButton from '@mui/lab/LoadingButton';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import videoIcon from "Latest/Images/svg/videoIcon.svg"
import useStylesNeutralBtn from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral'
import { useAppLoading } from 'hooks/appLoading';
import 'react-image-crop/dist/ReactCrop.css';
import { cryptoGraphy } from 'utils/cryptoGraphyFile';
import { base64ToFile } from 'utils/convertToFile';

const SelfieWebcamTierKyc = ({ fieldName, formik, loading }: any) => {


    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const classes = useStylesNeutralBtn();

    const webcamRef: any = useRef(null);
    const [isWebcamOpen, setIsWebcamOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState<any>(null);
    const [hasWebcamAccess, setHasWebcamAccess] = useState(false);
    const [hasWebcamAvailable, setHasWebcamAvailable] = useState(false);
    const [showFrame, setShowFrame] = useState(false);
    const [isDisablePermisson, setIsDisablePermisson] = useState(false);
    const [capturedCnic, setCapturedCnic] = useState<any>(null)

    const dispatch = useAppDispatch();

    const [loadingCam, setLoadingCam] = useState(false)

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);

    const checkWebcamAvailability = useCallback(async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter((device) => device.kind === 'videoinput');
            const hasWebcam = videoDevices.length > 0;
            setHasWebcamAvailable(hasWebcam);
        } catch (error) {
            console.log('Error checking webcam availability:', error);
            setHasWebcamAvailable(false);
        }
    }, []);

    useEffect(() => {
        checkWebcamAvailability();

        // Add event listener for device changes
        navigator.mediaDevices.addEventListener('devicechange', checkWebcamAvailability);

        // Clean up the event listener on component unmount
        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', checkWebcamAvailability);
        };
    }, [checkWebcamAvailability]);


    const checkWebcamAccess = async () => {
        setLoadingCam(true)
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setHasWebcamAccess(true);
            stream.getTracks().forEach((track) => track.stop()); // Release the webcam stream
            setLoadingCam(false)

            return true
        } catch (error) {
            console.log("Webcam access denied:", error);
            setHasWebcamAccess(false);
            setLoadingCam(false)
        }
    };

    const openWebcam = useCallback(async () => {
        setIsDisablePermisson(false)
        setLoadingCam(true);

        // If webcam access is not available, there's no need to continue
        if (!hasWebcamAvailable) {
            setLoadingCam(false);
            return;
        }

        // Wait for the checkWebcamAccess function to complete and update the state
        await checkWebcamAccess();

        if (hasWebcamAccess || await checkWebcamAccess()) {
            handleOpen();
            setIsWebcamOpen(true);
            formik.setFieldTouched(fieldName, true)
            setTimeout(() => {
                setShowFrame(true);
            }, 2000); // Change the delay (in milliseconds) as needed
        } else {
            setIsDisablePermisson(true)
            // alert("You have disabled access to the webcam. Please reset this permission by clicking on the lock icon next to the page URL.");
        }
        setLoadingCam(false);
    }, [hasWebcamAvailable, hasWebcamAccess]);

    const closeWebcam = useCallback(() => {
        setIsWebcamOpen(false);
        setShowFrame(false);
    }, []);

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        // setImgSrc(imageSrc);
        // cropImage(imageSrc)
        setImgSrc(imageSrc);
    }

    const captureSubmit = async () => {
        handleClose();

        const blob = new Blob([imgSrc]);

        formik?.setFieldValue(fieldName, imgSrc);
        const hashingPromises = cryptoGraphy(base64ToFile(imgSrc));
        const hashedFiles = await hashingPromises;
        formik?.setFieldValue("hash", hashedFiles);
    }

    const handleClose = () => {
        closeWebcam()
        setOpen(false)
        setImgSrc(null);
    }

    const handleRetry = () => {
        setImgSrc(null)
        setShowFrame(false);

        setTimeout(() => {
            setShowFrame(true);
        }, 1000); // Change the delay (in milliseconds) as needed
    }

    return (
        <>


            <Box display="flex" sx={{ justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "32px", mb: "32px" }}>

                {formik.values[fieldName] ?
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center", }} >
                        <img src={formik.values[fieldName]} style={{ width: mdScreen ? "100%" : "auto", height: !mdScreen ? "500px" : "auto" }} />
                    </Box>
                    :
                    <img src={videoIcon} />
                }

                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                    {formik.values[fieldName] ?
                        <LoadingButton disabled={!hasWebcamAvailable || isDisablePermisson} loading={loadingCam || loading} onClick={openWebcam} startIcon={<CameraAltIcon />} variant='contained'>
                            Retry
                        </LoadingButton>
                        :
                        < LoadingButton disabled={!hasWebcamAvailable || isDisablePermisson} loading={loadingCam || loading} onClick={openWebcam} variant='contained' color="secondary">
                            Upload with webcam
                        </LoadingButton>

                    }

                    {loadingCam ?
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="12rem" />
                        :
                        isDisablePermisson ?
                            <Typography variant='body2' textAlign={"center"} mt="12px">You have disabled access to the webcam. Please reset this permission by clicking on the lock icon next to the page URL.
                                A device with a webcam is required to proceed forward (mobile / laptop).</Typography>
                            :
                            !hasWebcamAvailable ?
                                <Typography variant='body2' textAlign={"center"} mt="12px">Webcam is not available</Typography>
                                :
                                ""
                        // !hasWebcamAccess && <Typography sx={{ ...subHeadingStyle }}> Allow access to the camera once clicked</Typography>
                    }

                    {formik?.touched[fieldName] && <span className='text-danger' > {formik?.errors[fieldName]}</span>}

                </Box>

            </Box >

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Container disableGutters maxWidth={"lg"} sx={{ ...webCamStyle.moadlStyle, maxHeight: "100%" }}  >
                    <Grid container spacing={1}>
                        <Grid item md={12} xs={12} >
                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: "2px" }}>
                                <Typography variant='h5'>Take a picture of yourself</Typography>
                                
                                <CloseIcon onClick={handleClose} sx={{ height: "1.2rem" }} />
                            </Box>
                            <Typography sx={subHeadingStyle}> Please take off your glasses or any headwear before taking the selfie. Ensure that the image is well lit and clear.</Typography>
                        </Grid>



                        {imgSrc ?
                            <Grid item md={12} xs={12} sx={{ position: "relative", py: '40px !important', display: 'flex', justifyContent: 'center' }} >
                                <img src={imgSrc} style={{ borderRadius: '12px' }} />
                            </Grid>
                            :
                            loadingCam ?
                                <CircularIndeterminate />
                                :
                                <Grid item md={12} xs={12} sx={{ position: "relative" }} >

                                    {isWebcamOpen && (
                                        <>
                                            <Webcam
                                                ref={webcamRef}
                                                audio={false}
                                                screenshotFormat="image/jpeg"
                                                screenshotQuality={1}
                                                imageSmoothing={true}
                                                // mirrored={true}
                                                style={{
                                                    width: "100%",
                                                    // height: height,
                                                    objectFit: 'cover', // Preserve aspect ratio and cover the container
                                                }}
                                            />

                                            {showFrame && <Box sx={SelfieWebcamStyle.frameStyleKyc} />} {/* Add the frame style */}
                                            {!showFrame && <Box sx={webCamStyle.loaderOnWebcam}><CircularIndeterminate /></Box>}

                                        </>
                                    )}

                                </Grid>

                        }

                        {/* {hasWebcamAccess === false && <Grid item md={12} xs={12} >
                            Webcam access is denied. Please enable from your browser setting
                        </Grid>} */}


                        <Grid item md={12} xs={12} sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                            {imgSrc ?
                                <>
                                    <Button size={"small"} variant='outlined' className={classes.neutralButton} onClick={handleRetry}>Retry</Button>
                                    <Button size={"small"} variant='contained' onClick={captureSubmit}>Submit</Button>
                                </>
                                :
                                <>
                                    <Button size={"small"} variant='outlined' className={classes.neutralButton} onClick={handleClose}>Cancel</Button>
                                    <Button size={"small"} variant='contained' onClick={capture}>Capture</Button>
                                </>
                            }
                        </Grid>

                    </Grid>

                </Container>
            </Modal >

        </>
    );
};

export default SelfieWebcamTierKyc;

const CircularIndeterminate = () => {
    return (
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center", height: "25rem", alignItems: "center" }}>
            <CircularProgress size={100} />
        </Box>
    );
}

interface CustomFile extends File {
    preview: string;
}