import React, { useState } from 'react'
import Help from './help'
import { Box, Container, Divider, Grid, useTheme, Typography, Button } from '@mui/material'
import { tokens } from 'theme'
import { customCard } from 'Latest/GoalDetail/style'
import TopBarLogo from 'Latest/ComponentV1/topBarLogo'
import { useAppSelector } from 'hooks/reduxHooks'
import TabsHeading from 'Latest/ComponentV1/TabsHeading'

const Index = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);

  return (
    <Grid container>
      {dashboardDetail?.status == "New" && <TopBarLogo />}

      <Grid item xs={12} sx={{ ...customCard.mainBoxV2, border: 0 }} mb={3}>
        {dashboardDetail?.status == "New" ?
          <Container disableGutters maxWidth={"xl"}>
            <Typography variant='h1' color={colors.grey[800]} p={3}>Need help?</Typography>
          </Container>
          :
          <>
          <TabsHeading heading='Need help?' />
          <Typography variant='body1' color={colors.grey[600]} sx={{width:{md:"60%",sm:"60%",xs:"90%"},marginLeft:{md:"32px",xs:"16px"} ,marginBottom:"32px",fontsize:"16px",fontWeight:"400"}}>Let us know if you’re facing any issues. We usually respond within a few hours during business hours.</Typography>
          </>
          
        }
      </Grid>

      {
        dashboardDetail?.status == "New" ?
          <Box width={"100%"} sx={{ p: { xs: "24px 16px", md: "30px" }, background: "#fff" }}>
            <Container maxWidth={"xl"}>
              <Grid container>
                <Help ismodal={false}/>
              </Grid>
            </Container>
          </Box>
          :
          <Box width={"100%"} sx={{ p: { xs: "24px 16px", md: "30px" }, background: "#fff" }}>
            <Container maxWidth="lg" sx={{ px: "0px!important", ml: 0 }}>
              <Grid container>
                <Help ismodal={false}/>
              </Grid>
            </Container >
          </Box>
      }

    </Grid>
  )
}

export default Index