
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import React, { useEffect } from 'react'
import { onGetAllUserQuestionAnswers } from 'redux/actions';
import UpdateProfile from "./index"
import { QuestionAnswerCategoryEnum } from '../../../../constants';

const UpdateCashFundProfile = ({ checkNic, formikRiskProfile, goalId, goalValue, nextStep, previousStep, setShowUpdateRiskProfile, userAccountDetails }: any) => {

    const dispatch = useAppDispatch();
    useEffect(() => {
        handleAPIOnUpdate()
    }, [])

    const { allUserSelectedAnswer, cashFundProfileUserQuestions } = useAppSelector(state => state.questionAnswer);

    const handlePrevStep = (formikCallback: any) => {

        const vpValues = formikCallback?.values;
        Object.keys(vpValues).forEach((key) => {
            formikRiskProfile.setFieldValue(key, vpValues[key]);
        });

        previousStep()
    }

    const handleAPIOnUpdate = () => {
        dispatch(onGetAllUserQuestionAnswers(QuestionAnswerCategoryEnum.CashFund));
    }

    const handleNextStep = (formikCallback: any) => {
        if (userAccountDetails?.iban) {
            nextStep(undefined, 6);
        }
        else if (userAccountDetails?.permanentAddress) {
            nextStep(undefined, 5);
        }
        else if (userAccountDetails?.isAccountSetupComplete && checkNic) {
            nextStep(undefined, 4);
        } else {
            nextStep();
        }
        const vpValues = formikCallback;
        Object.keys(vpValues).forEach((key) => {
            formikRiskProfile.setFieldValue(key, vpValues[key]);
        });
    }

    return (
        <>
            <UpdateProfile
                setShowUpdateRiskProfile={setShowUpdateRiskProfile}
                previousStep={previousStep}
                selectedAnswer={allUserSelectedAnswer}
                profileQuestions={cashFundProfileUserQuestions}
                handleAPIOnUpdate={handleAPIOnUpdate}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}
            />
        </>
    )
}

export default UpdateCashFundProfile
