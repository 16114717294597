import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Avatar, IconButton, ListItemAvatar, Typography, useTheme } from '@mui/material';
import NotificationImg from "../Images/notificationIcon.png"
import MahaanaImg from "../Images/MahaanaIcon.png"
import CloseIcon from '@mui/icons-material/Close';
import UnreadDot from "../Images/GreenDot.png"
import { tokens } from 'theme';
import MenuIcon from '@mui/icons-material/Menu';
import mahaanaFullLogoSvg from "../Images/svg/logoMahaana.svg"
import RouteList from './routeList';
import { Link } from 'react-router-dom';
import hamburgerIcon from "Latest/Images/hamburger.png";

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function SideBarDrawer({ anchor = "left", newUserCustomRoute }: any) {

    const themes = useTheme();
    const colors = tokens(themes.palette.mode);

    const [state, setState] = React.useState<any>({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box sx={{ position: "absolute", right: 15, top: 17 }}>
                <IconButton aria-label="upload picture" component="label">
                    <CloseIcon onClick={toggleDrawer(anchor, false)} sx={{ color: colors.grey[700] }} />
                </IconButton>
            </Box>
            <Box sx={{ ml: "13px", mt: "12px" }}>
                <Box sx={{ py: "15px" }}>
                    <Link to={"/"}>
                        <img src={mahaanaFullLogoSvg} style={{ width: "125px", height: "20px" }} />
                    </Link>
                </Box>
            </Box>
            <List>
                <RouteList open={true} newUserCustomRoute={newUserCustomRoute} />
            </List>
        </Box >
    );

    return (
        <>
            <React.Fragment>
                <IconButton onClick={toggleDrawer(anchor, true)} sx={{ "&:hover": { transform: "scale(1.1)" } }} >
                    {/* <MenuIcon sx={{ color: colors.grey[700] }} /> */}
                    <img src={hamburgerIcon} height={24} width={24} />
                </IconButton>

                <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    sx={{
                        ".MuiDrawer-paper": {
                            background: colors.grey[100],
                            width: { sm: "240px", xs: "99%" },
                            maxWidth: "360px"
                        },

                    }}
                >
                    {list(anchor)}
                </Drawer>
            </React.Fragment>
        </>
    );
}