import React from 'react'

const CardIcon = ({ fill = "#475467", height = "24px", width = "24px" }: any) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 17" fill="none">
                <path d="M14.5 3.64282H2.5C2.23478 3.64282 1.98043 3.74818 1.79289 3.93572C1.60536 4.12325 1.5 4.37761 1.5 4.64282V12.6428C1.5 12.908 1.60536 13.1624 1.79289 13.3499C1.98043 13.5375 2.23478 13.6428 2.5 13.6428H14.5C14.7652 13.6428 15.0196 13.5375 15.2071 13.3499C15.3946 13.1624 15.5 12.908 15.5 12.6428V4.64282C15.5 4.37761 15.3946 4.12325 15.2071 3.93572C15.0196 3.74818 14.7652 3.64282 14.5 3.64282ZM9 11.6428H8C7.86739 11.6428 7.74021 11.5901 7.64645 11.4964C7.55268 11.4026 7.5 11.2754 7.5 11.1428C7.5 11.0102 7.55268 10.883 7.64645 10.7893C7.74021 10.6955 7.86739 10.6428 8 10.6428H9C9.13261 10.6428 9.25979 10.6955 9.35355 10.7893C9.44732 10.883 9.5 11.0102 9.5 11.1428C9.5 11.2754 9.44732 11.4026 9.35355 11.4964C9.25979 11.5901 9.13261 11.6428 9 11.6428ZM13 11.6428H11C10.8674 11.6428 10.7402 11.5901 10.6464 11.4964C10.5527 11.4026 10.5 11.2754 10.5 11.1428C10.5 11.0102 10.5527 10.883 10.6464 10.7893C10.7402 10.6955 10.8674 10.6428 11 10.6428H13C13.1326 10.6428 13.2598 10.6955 13.3536 10.7893C13.4473 10.883 13.5 11.0102 13.5 11.1428C13.5 11.2754 13.4473 11.4026 13.3536 11.4964C13.2598 11.5901 13.1326 11.6428 13 11.6428ZM2.5 6.14282V4.64282H14.5V6.14282H2.5Z" fill={fill} />
            </svg>
        </div>
    )
}

export default CardIcon