import React from 'react'

const BankIconV2 = ({ fill = "#475467", height = "24px", width = "24px" }: any) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
                <path d="M23.25 19.5C23.25 19.6989 23.171 19.8897 23.0303 20.0303C22.8897 20.171 22.6989 20.25 22.5 20.25H1.5C1.30109 20.25 1.11032 20.171 0.96967 20.0303C0.829018 19.8897 0.75 19.6989 0.75 19.5C0.75 19.3011 0.829018 19.1103 0.96967 18.9697C1.11032 18.829 1.30109 18.75 1.5 18.75H22.5C22.6989 18.75 22.8897 18.829 23.0303 18.9697C23.171 19.1103 23.25 19.3011 23.25 19.5ZM1.52812 9.20437C1.48352 9.04709 1.49152 8.87954 1.55093 8.72722C1.61033 8.57491 1.71787 8.44618 1.85719 8.36062L11.6072 2.36062C11.7253 2.28798 11.8613 2.24953 12 2.24953C12.1387 2.24953 12.2747 2.28798 12.3928 2.36062L22.1428 8.36062C22.2822 8.44607 22.3898 8.57471 22.4493 8.72696C22.5088 8.87922 22.517 9.04674 22.4725 9.20404C22.428 9.36135 22.3334 9.49981 22.2029 9.59837C22.0725 9.69692 21.9135 9.75016 21.75 9.75H19.5V15.75H21C21.1989 15.75 21.3897 15.829 21.5303 15.9697C21.671 16.1103 21.75 16.3011 21.75 16.5C21.75 16.6989 21.671 16.8897 21.5303 17.0303C21.3897 17.171 21.1989 17.25 21 17.25H3C2.80109 17.25 2.61032 17.171 2.46967 17.0303C2.32902 16.8897 2.25 16.6989 2.25 16.5C2.25 16.3011 2.32902 16.1103 2.46967 15.9697C2.61032 15.829 2.80109 15.75 3 15.75H4.5V9.75H2.25C2.08668 9.75005 1.9278 9.6968 1.79751 9.59831C1.66721 9.49983 1.57263 9.36152 1.52812 9.20437ZM13.5 15C13.5 15.1989 13.579 15.3897 13.7197 15.5303C13.8603 15.671 14.0511 15.75 14.25 15.75C14.4489 15.75 14.6397 15.671 14.7803 15.5303C14.921 15.3897 15 15.1989 15 15V10.5C15 10.3011 14.921 10.1103 14.7803 9.96967C14.6397 9.82902 14.4489 9.75 14.25 9.75C14.0511 9.75 13.8603 9.82902 13.7197 9.96967C13.579 10.1103 13.5 10.3011 13.5 10.5V15ZM9 15C9 15.1989 9.07902 15.3897 9.21967 15.5303C9.36032 15.671 9.55109 15.75 9.75 15.75C9.94891 15.75 10.1397 15.671 10.2803 15.5303C10.421 15.3897 10.5 15.1989 10.5 15V10.5C10.5 10.3011 10.421 10.1103 10.2803 9.96967C10.1397 9.82902 9.94891 9.75 9.75 9.75C9.55109 9.75 9.36032 9.82902 9.21967 9.96967C9.07902 10.1103 9 10.3011 9 10.5V15Z" fill={fill} />
            </svg>
        </div>
    )
}

export default BankIconV2