import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { zakatStyle } from '../Style/style';
import InfoIcon from "Latest/Images/svg/infoIcon.svg"
import { Container, Divider, IconButton } from '@mui/material';
import CustomTab from 'Latest/ComponentV1/CustomTab';
import ForMuslim from './ForMuslim';
import ForNonMuslim from './ForNonMuslim';
import CloseIcon from '@mui/icons-material/Close';
import { downloadZakatDecFile } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { toast } from 'react-toastify';
import ZakaatOnlineForm from './zakaatOnlineForm';
import LoadingButton from '@mui/lab/LoadingButton';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "95%", sm: "80%", md: "auto", lg: 850 },
    maxHeight: "90%",
    bgcolor: 'background.paper',
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    borderRadius: "12px",
    // p: "24px",
    padding: "18px 0px",
    overflow: "auto"
};

export default function BasicModal({ handleUpload, isloading }: any) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useAppDispatch();

    return (
        <>
            <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", alignItems: "center" }} >
                <LoadingBtnCustom handleOnClick={handleOpen} btnTitle={"Sign Zakat form online"} style={{ padding: "12px 24px", fontSize: "1rem" }} />
                <Typography variant='body2' textAlign={"center"}>Submit from the comfort of your home (up to 2 mins)</Typography>
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box sx={{ px: "24px" }}>
                        <ZakaatOnlineForm handleClose={handleClose} handleUpload={handleUpload} loadingNext={isloading} />
                    </Box>

                </Box>
            </Modal>
        </>
    );
}