import React from 'react';
import './button.css'
interface ButtonProps {
    Text?: string;
    Icon?: any;
    IsBorder?: boolean // true when border only else bakckground False
    ClickHandler?: any;
    disabled?: boolean;
}

const BorderButton = (props: ButtonProps) => {
    return (
        <>
            <button className={props.IsBorder ? 'buttonClass' : 'buttonClass'} disabled={props.disabled} onClick={props.ClickHandler} >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {props.Icon ? <i className={props.Icon} style={{ color: props.IsBorder ? '' : ' white', marginRight: '8px' }} /> : ''}
                    <span className='mr-0'>
                        {props.Text}
                    </span>
                </div>
            </button>
        </>
    )
}
export default BorderButton;