import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { PayNowStylesV1 } from '../style/indexV1'
import DepositAmtCard from 'Latest/ComponentV1/DepositAmtCard'
import CustomUpload from 'Latest/ComponentV1/CustomUpload'
import FormikReactSelect from "jsx/components/Shared/V1/FormikReactSelectV1"
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import BankDetails from 'Latest/MyProfile/BankDetails/bankDetailGrid';
import CustomTab from 'Latest/ComponentV1/CustomTab'
import CustomSingleCheckBoxV1 from 'jsx/components/Shared/V1/CustomSingleCheckBoxV1'
import LoadingButton from '@mui/lab/LoadingButton'
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral'
import bankDepositIcon from "Latest/Images/svg/bankDepositIcon.svg"
import * as Yup from 'yup';
import DetailCardComp from '../Component/detailCardComp'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useHistory } from 'react-router-dom'
import { CASH_FUND_ID, depositDetailCFArr, depositDetailGIArr, goalCategory } from '../../../constants'
import { links } from 'static/links'
import { OnlineBankFormValue } from 'redux/actions'
import { AxiosResponse } from 'axios'
import { CurrencyFormatterWithDecimal } from 'utils/calculation'
import BankDetailCard from '../Component/bankDetailCard'
import { customStyleSelectPayment } from './BankDepositSavePlus'
import { useAppLoading } from 'hooks/appLoading'
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1'

const upperPDFErr = "Please upload receipt."
const impArray = [
    "Your investment is being made into Mahaana cash fund & the stock market. Please make 2 separate transactions for each.",
    "Both transactions must be made from 1 bank account. Please check that your daily withdrawal limit is not exceeded.",
    "Deposits must be made from a bank account that's in your name."
]

const BankDepositSaveGI = ({ banksList, categoryAcc, formik, goalId, onSuccessTransaction, originalAmt, paymentAccList, previousStep, productsSegregation, submitBankTransfer, ...props }: any) => {

    const loading = useAppLoading('payment');
    const dispatch = useAppDispatch();
    const [openAccModal, setOpenAccModal] = useState(false);
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const { goalIdPost } = useAppSelector(state => state?.portfolio);
    const { location, push } = useHistory();
    const loc: any = location.state
    const [openErrModal, setOpenErrModal] = useState(false);
    const { addGoalBody } = useAppSelector(state => state.sharedData);
    const [filterBankList, setFilterBankList] = useState([])

    const amt = goalId ? (goalId === CASH_FUND_ID ? (addGoalBody?.initialAmount) : formik?.values?.initial_amount) : loc?.amount?.investAmount

    const DepositAmt = loc?.productsSegregation


    const UserAccIban = userAccountDetails?.iban || "";

    // const flowAccCategory = goalId == CASH_FUND_ID ? goalCategory.CashFund : goalCategory.GeneralInvest

    // const categoryAcc = loc?.key == "Sell" ? loc?.category : flowAccCategory

    const classes = useStyles();

    const { userBankAcc } = useAppSelector(state => state.sharedData)

    const formikIbft = useFormik({
        enableReinitialize: true,
        initialValues: { proofOfPayment: '', selectedAccNo: UserAccIban, term1: false, transactionID: "", proofOfPaymentStock: "" },
        onSubmit: (values: any) => {
            const { proofOfPayment, proofOfPaymentStock, selectedAccNo, transactionID } = values;
            const FilterAccDetail = userBankAcc.filter((item: any, index: number) => item.accountNumber == selectedAccNo)

            const formData = new FormData();
            formData.append('accountNumber', selectedAccNo);
            formData.append('accountTitle', FilterAccDetail[0]?.accountTitle);
            formData.append('bankName', FilterAccDetail[0]?.bankName);

            formData.append('paymentReceipts[0].key', goalCategory.CashFund);
            formData.append('paymentReceipts[0].value', proofOfPayment);
            formData.append('paymentReceipts[1].key', goalCategory.GeneralInvest);
            formData.append('paymentReceipts[1].value', proofOfPaymentStock);

            formData.append('goalId', loc?.key == "Sell" ? loc?.idCashFundInvestment : goalIdPost);
            formData.append('paymentGateway', "IBFT");
            formData.append('accountCategory', categoryAcc);
            formData.append('transactionReferenceNumber', transactionID || "-");
            formData.append('originalAmount', originalAmt);


            dispatch(OnlineBankFormValue(formData, onSuccessResponse));
            return;
            // eslint-disable-next-line no-console
        },
        validationSchema: Yup.object().shape({
            term1: Yup.bool().oneOf([true], 'Please read and agree to our terms & conditions.'),
            selectedAccNo: Yup.string().required('Please select a bank account for deposit.'),
            proofOfPayment: Yup.mixed().required(upperPDFErr)
                .test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed.', (value: any) => {
                    if (value instanceof File) {
                        const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
                        const fileType = value.type;
                        return allowedFileTypes.includes(fileType);
                    }
                    return true;
                }),
            proofOfPaymentStock: Yup.mixed().required(upperPDFErr)
                .test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed.', (value: any) => {
                    if (value instanceof File) {
                        const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
                        const fileType = value.type;
                        return allowedFileTypes.includes(fileType);
                    }
                    return true;
                }),
        })
    });

    const onSuccess = (message: string, response: any) => {
        push(links.PayReceipt, { ...loc, "transactionReferenceNumber": formikIbft.values.transactionID });
    }
    const onFail = (message: string, response: AxiosResponse) => {
        console.log(response.status)
        setOpenErrModal(true)
    }

    const onSuccessResponse = (message: string, response: AxiosResponse) => {
        onSuccessTransaction({ ...loc, "transactionReferenceNumber": formikIbft.values.transactionID })
        // push(links.PayReceipt, { ...loc, "transactionReferenceNumber": formikIbft.values.transactionID });

        return;
    };

    React.useEffect(() => {
        if (userBankAcc?.length > 0) {
            const filterKey = userBankAcc?.map((item: any, ind: number) => (
                {
                    "label": `${item.bankName}<br/>${item.accountTitle}<br/> ${item.accountNumber}`,
                    "value": item.accountNumber
                }))
            setFilterBankList(filterKey)
        }
    }, [userBankAcc])

    const handleGoBack = (e: any) => {
        if (loc?.key == "Sell") {
            push(links.home)
        } else {
            e.preventDefault();
            previousStep();
        }
    }

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>

                <Box sx={PayNowStylesV1.blueChip}>
                    <Typography variant='subtitle2'>Important:</Typography>
                    <Stack component="ul" spacing={"8px"}>
                        {impArray.map((item: string, index: number) => (
                            <Stack
                                key={item}
                                alignItems="center"
                                component="li"
                                direction="row"
                                spacing={1.5}
                                sx={{ alignItems: "baseline" }}
                            >
                                <FiberManualRecordIcon sx={{ fontSize: "7px", color: "black" }} />
                                <Typography variant='body2'>{item} </Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Box>

                <Box sx={PayNowStylesV1.cardBox}>

                    <Typography className='mainCardHead' variant='h6' > <img src={bankDepositIcon} />Bank Deposit:</Typography>

                    <Box className="GITotalAmtBox">
                        <Typography variant='h6' color={"#666"} >Total Deposit Amount</Typography>
                        <Typography variant='h5' fontWeight={600} >{CurrencyFormatterWithDecimal(originalAmt, true, "never", false)}</Typography>
                    </Box>

                    <Grid container>

                        <Grid item xs={12} lg={6} >
                            <Typography variant='h6' color="secondary"
                                sx={{ p: "12px 20px", borderRight: "1px solid #EAECF0", background: "#F9FAFB" }}> Mahaana Islamic Cash Fund (MICF)</Typography>

                            <Box className="innerMainBox" sx={{ borderRight: "1px solid #EAECF0", }}>
                                <DetailCardComp
                                    formik={formikIbft}
                                    upperPDFErr={upperPDFErr}
                                    depositDetailArr={depositDetailCFArr}
                                    fieldName={"proofOfPayment"}
                                    depositAmt={CurrencyFormatterWithDecimal(productsSegregation?.CashFund || 0, true, "never", false)} />
                            </Box>

                        </Grid>

                        <Grid item xs={12} lg={6} display={"flex"} flexDirection={"column"}>
                            <Typography variant='h6' color="secondary" sx={{ p: "12px 20px", background: "#F9FAFB" }}> Stock Market</Typography>
                            <Box className="innerMainBox" height={"100%"} display={"flex"} flexDirection={"column"}>
                                <DetailCardComp
                                    formik={formikIbft}
                                    upperPDFErr={upperPDFErr}
                                    depositDetailArr={depositDetailGIArr}
                                    fieldName={"proofOfPaymentStock"}
                                    depositAmt={CurrencyFormatterWithDecimal(productsSegregation?.GeneralInvestment || 0, true, "never", false)} />
                            </Box>
                        </Grid>

                    </Grid>

                </Box>

                <Box>
                    {userBankAcc?.length > 1 ?
                        <FormikReactSelect
                            required
                            disabled={false}
                            fieldName='selectedAccNo'
                            fields={filterBankList}
                            formik={formikIbft}
                            label='Select your deposit bank account'
                            customStyle={customStyleSelectPayment}
                            onChange={(val: any, option: any) => {
                                formikIbft.setFieldValue('selectedAccNo', option.value);
                            }}
                            placeholder='Select your bank account'
                            value={filterBankList.find((el: any) => el?.value == formikIbft?.values?.selectedAccNo)}
                        />
                        :
                        <BankDetailCard iban={userAccountDetails?.iban} userBankList={userBankAcc} />}
                </Box>

                <Box>
                    <BankDetails screen="Paynow" />
                </Box>

                <Grid item xs={12} sx={{ display: "flex", gap: "26px" }}>
                    <CustomSingleCheckBoxV1
                        formik={formikIbft}
                        fieldName={`term1`}
                        label={`I have read and agree to Mahaana’s <span style=color:#43BED8 ; cursor:pointer>terms and conditions.</span>`}
                    />
                </Grid>


                <Grid item xs={12} sx={{ display: "flex", gap: "26px" }}>
                    <ButtonRowV1 btnTitleNext={"Submit"} handleNext={() => formikIbft.handleSubmit()} loadingNextBtn={loading} handleBack={handleGoBack} />
                    {/* <Button fullWidth size={"large"} variant='outlined' className={classes.neutralButton} onClick={handleGoBack}>Back</Button> */}
                    {/* <LoadingButton disableElevation fullWidth loading={loading} size={"large"} variant='contained' color='secondary' onClick={() => formikIbft.handleSubmit()}>Submit</LoadingButton> */}
                </Grid>
            </Box>
        </>
    )
}

export default BankDepositSaveGI