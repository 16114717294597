/* eslint-disable no-param-reassign */
/* eslint-disable no-self-assign */
import { Box, debounce, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import Big from 'big.js';
import { DefaultAgeEstimate } from 'constants/globalVariables';
import { useAppSelector } from 'hooks/reduxHooks';
import { memo, useCallback, useEffect, useState } from 'react';
import { Goals, PLAN_TYPES } from '../../../../../constants';
import '../../styles/PlannedSaving.scss';
import { monthlySavingReturnsCalculation } from 'utils/monthlySavingReturnsCalculation';
import ProjectionAreaChart from 'Latest/ChartV1/projectionAreaChart';
import moment from 'moment';


const ProjectionChart = ({
    colorGraph,
    formik,
    handleInterestCompute,
    height = 350,
    initialAmount,
    interestData,
    investmentAmount,
    isEmployer,
    monthlySaving,
    planType,
    projectionProfitPercentage,
    retirementAgeTaken,
    retirementAmount,
    travelPlan
}: Props) => {
    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [range, setRange] = useState<number>(-1);
    const [retirementAge, setRetirementAge] = useState(formik?.values?.desired_retirement_age);
    const [maxFutureValue, setMaxFutureValue] = useState<number>(100000);
    const [featureSavingValue, setFeatureSavingValue] = useState<number>(0);
    const { userAccountDetails } = useAppSelector((state: any) => state.auth);
    const [futureValue, setFutureValue] = useState<number>(0);
    const [rangeMax, setRangeMax] = useState<number>(100);
    const [rangeMin, setRangeMin] = useState<number>(10);
    const [graphData, setGraphData] = useState<any>();
    const [selectedMonthlySavings, setSelectedMonthlySavings] = useState<number>(0);
    const [selectedFeatureMonthly, setSelectedFeatureMonthly] = useState<number>(0);
    const [maxExpectedAmount, setMaxSelectedExpectedAmount] = useState<number>(0);
    const [expectedAmount, setSelectedExpectedAmount] = useState<number>(-1);
    const [hoveredDot, setHoveredDot] = useState<any>(-1);
    const age = userAccountDetails?.identityCard?.age ?? DefaultAgeEstimate;
    const oneBillion = 1000000000;
    const fiftyThousand = 50000;

    const setRangeAmountData = (amount: number) => {
        let maxRange = new Big(Number(amount)).mul(5).round().toNumber();
        let minRange = new Big(Number(amount)).div(100).div(5).round().toNumber();

        if (planType === PLAN_TYPES.ACCUMULATE_AMOUNT) {
            if (minRange < fiftyThousand) minRange = fiftyThousand;
            if (maxRange > oneBillion) maxRange = oneBillion - 1;
        }

        setRangeMax(maxRange);
        if (minRange > 10) {
            setRangeMin(minRange);
        }

        const maxExpected = maxRange * 100;
        setMaxSelectedExpectedAmount(maxExpected);
        setSelectedExpectedAmount(amount);
        formik?.setFieldValue('future_values', amount);
    };

    const calculateNumberOfPayments = (retirementAge: number) => {
        let numberOfPayments;
        if (formik?.initialValues?.goalId === Goals.VoluntaryPension) {
            numberOfPayments = (retirementAge - age) * 12;
        } else {
            if (formik?.initialValues?.goalId === Goals.TravelTime) {
                numberOfPayments = retirementAge;
            } else {
                numberOfPayments = retirementAge * 12;
            }
        }
        if (numberOfPayments < 0) {
            numberOfPayments = 0;
        }

        formik?.setFieldValue('desired_retirement_age', retirementAge);

        return numberOfPayments;
    };

    useEffect(() => {
        if (retirementAgeTaken) {
            setRetirementAge(retirementAgeTaken)
        } else {
            setRetirementAge(formik?.values?.desired_retirement_age)
        }

    }, [formik?.values?.desired_retirement_age, retirementAgeTaken])

    useEffect(() => {
        const numberOfPayments = calculateNumberOfPayments(retirementAge);

        setSelectedMonthlySavings(Number(monthlySaving));
        setRangeAmountData(Number(monthlySaving));

        // const monthly: number = FormulaeForRetirementSavings({
        //     numberOfPayments: numberOfPayments,
        //     retirementAmount: Number(retirementAmount),
        //     initialAmount: Number(initialAmount)
        // });

        // if (planType === PLAN_TYPES.ACCUMULATE_AMOUNT) {
        //     setSelectedMonthlySavings(monthly);
        //     setRangeAmountData(Number(retirementAmount));
        // } else {
        //     setSelectedMonthlySavings(Number(monthlySaving));
        //     setRangeAmountData(Number(monthlySaving));
        // }
    }, [monthlySaving]);

    useEffect(() => {
        if (expectedAmount > -1) {
            // setRange(expectedAmount);
            setRange(Number(monthlySaving));
        }
    }, [expectedAmount, monthlySaving, formik?.values?.desired_retirement_age]);

    const debouncedDrawGraph = useCallback(
        debounce(
            (expectedAmount, retirementAge, maxMonthlyAmount) =>
                HandleFormulae(expectedAmount, retirementAge, selectedMonthlySavings, selectedFeatureMonthly),
            500
        ),
        [selectedMonthlySavings, selectedFeatureMonthly, initialAmount, projectionProfitPercentage]
    );

    useEffect(() => {
        if (range > -1) {
            const expectedChangeAmount = range;
            // handleInterestCompute && handleInterestCompute({
            //     initial_amount: initialAmount,
            //     monthly_saving: selectedMonthlySavings
            // });

            if (planType !== PLAN_TYPES.ACCUMULATE_AMOUNT) {
                // formik?.setFieldValue('monthly_saving', expectedChangeAmount);
            } else {
                formik?.setFieldValue('monthly_saving', expectedChangeAmount);
            }

            debouncedDrawGraph(
                expectedChangeAmount,
                retirementAge,
                maxExpectedAmount
            );

            if (planType !== PLAN_TYPES.ACCUMULATE_AMOUNT) {
                formik?.setFieldValue('retirement_amount', Math.round(expectedChangeAmount));
            } else {
            }

        }

    }, [range, retirementAge, selectedMonthlySavings, initialAmount, projectionProfitPercentage]);

    const HandleFormulae = (expectedAmount: number, retirementAge: number, monthlySavingValue: number, selectedFeatureMonthly: number) => {
        const monthlySelectedSavingValue = expectedAmount;
        // if (planType !== PLAN_TYPES.ACCUMULATE_AMOUNT) {
        //     monthlySelectedSavingValue = expectedAmount;
        // } else {
        //     monthlySelectedSavingValue = monthlySavingValue;
        // }

        const numberOfPayments = calculateNumberOfPayments(retirementAge);
        formik?.setFieldValue('number_of_payments', numberOfPayments);
        const total = setGraphDataFunc(numberOfPayments, monthlySelectedSavingValue);

        const totalMax = total.plus(total.mul(new Big(Math.random() * 0.1).add(Math.random() * 0.15)))
            .div(1000)
            .round()
            .mul(1000)
            .toNumber();

        const monthlyValue = Number(monthlySaving);
        setFeatureSavingValue(Math.ceil(Number(total)));

        // let monthlyValue:any;
        // if (planType !== PLAN_TYPES.ACCUMULATE_AMOUNT) {
        //     monthlyValue = Number(monthlySaving);
        //     setFeatureSavingValue(Math.ceil(Number(total)));
        // } else {
        //     monthlyValue = FormulaeForRetirementSavings({
        //         numberOfPayments: numberOfPayments,
        //         retirementAmount: expectedAmount,
        //         initialAmount: Number(initialAmount)
        //     });
        //     setFeatureSavingValue(monthlyValue);
        // }

        setMaxFutureValue(Math.ceil(Number(totalMax)));
        setFutureValue(Math.ceil(Number(total)));
        setSelectedFeatureMonthly(monthlyValue);
        setSelectedMonthlySavings(monthlyValue);

        formik?.setFieldValue('future_values', Number(total.toFixed(2, 0)));
    };

    const monthShortKey = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", 'Nov', "Dec"
    ]

    const setGraphDataFunc = (numberOfPayments: number, monthlySavingValue: number) => {
        const data = [];
        let months = numberOfPayments;
        if (months < 0) { months = 0 }
        let startDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
        );
        let futureAge = new Big(age);
        const initValue = new Big(initialAmount || 0)
        let amount = new Big(monthlySavingReturnsCalculation(initValue || 0, undefined, projectionProfitPercentage));

        // old one 
        // data.push({
        //     key: months > 48 ? `${startDate.getFullYear()}` : `${monthShortKey[startDate.getMonth()]}-${startDate.getFullYear()}`,
        //     value: amount.toFixed(2, 0),
        //     yAxisKey: '0'
        // });

        // for (let i = 1; i < months; i++) {

        //     startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        //     futureAge = futureAge.add(0.08333);
        //     amount = monthlySavingReturnsCalculation(amount, monthlySavingValue, projectionProfitPercentage)

        //     if (months > 48 && ((i + 1) % 12 === 0 || months - 1 === i)) {
        //         data.push({
        //             key: `${startDate.getFullYear()}`,
        //             value: amount.toFixed(2, 0)
        //         });
        //     } else if (months <= 48) {
        //         data.push({
        //             key: `${monthShortKey[startDate.getMonth()]}-${startDate.getFullYear()}`,
        //             value: amount.toFixed(2, 0)
        //         });
        //     }
        // }

        data.push({
            key: months > 48 ? moment(startDate).valueOf() : moment(startDate).valueOf(),
            value: amount.toFixed(2, 0),
            yAxisKey: '0',
            date: moment(startDate, "DD/MM/YYYY").format('DD-MMM-YYYY'),
        });


        for (let i = 1; i < months; i++) {

            startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
            futureAge = futureAge.add(0.08333);
            amount = monthlySavingReturnsCalculation(amount, monthlySavingValue, projectionProfitPercentage)

            if (months > 48 && ((i + 1) % 12 === 0 || months - 1 === i)) {
                data.push({
                    key: moment(startDate).valueOf(),
                    value: amount.toFixed(2, 0)
                });
            } else if (months <= 48) {
                data.push({
                    key: moment(startDate).valueOf(),
                    value: amount.toFixed(2, 0)
                });
            }
        }

        setGraphData(data);
        return amount;
    };


    return (
        <Box>
            {graphData && retirementAge > 0 ?
                <ProjectionAreaChart graphData={graphData} height={smScreen ? 350 : 370} retirementAge={retirementAge} gradient={colorGraph} />
                :
                <>
                    <Skeleton variant="rectangular" height={height} />
                </>
            }
        </Box>
    );
};

export default memo(ProjectionChart);


interface interestDataProps {
    interest: number[];
    principal: number[];
    result: string;
    total: number[];
}
interface Props {
    interestData?: interestDataProps | Record<string, never>;
    monthlySaving?: number | string;
    initialAmount?: number | string;
    handleInterestCompute?: (values: Record<string, number | string>) => void;
    isEmployer?: boolean;
    future_values?: any;
    investmentAmount?: any;
    formik?: any;
    retirementAmount?: number | undefined;
    planType?: string | undefined;
    travelPlan?: string | undefined;
    height?: number;
    projectionProfitPercentage?: any;
    retirementAgeTaken?: number;
    colorGraph?: string
}

