export const fileUploadFieldStyles = {

    titleStyle: {
        fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "150%",
        color: "#202020"
    },


    titleStyleV2: {
        color: "rgba(29, 41, 57, 0.70)",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "normal",
        letterSpacing: "0.56px",
        textTransform: "uppercase",
        mb: "8px"
    },

    uploadBtnTitle: {
        marginTop: "0.5rem",
        fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: { md: "16px", sm: "16px", xs: '12px' },
        lineHeight: "155%",
        color: "#ADADAD"
    },

    uploadBtnTitleV2: {
        fontWeight: 400,
        fontSize: { md: "16px", xs: '14px' },
        lineHeight: "155%",
        color: "#403081",
    },

    uploadBtnSubTitleV2: {
        color: "rgba(29, 41, 57, 0.70)",
        fontSize: { md: "12px", xs: '12px' },
        fontWeight: 400,
        lineHeight: "normal",
        mt: "8px"
    },

    uploadBtnWrap: {
        borderRadius: "8px",
        border: "1px solid #D0D5DD",
        minHeight: "42px",
        padding: "10px 16px",
    },

    uploadSecBtnWrap: {
        borderRadius: "100px",
        backgroundColor: "#432F87",
        padding: "8px 16px",
    }
}