import React, { useEffect, useState } from 'react'
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { onGetTransactionDetail } from 'redux/reducers/sharedStateData'
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { accountStatementStatus, transactionTableStatus } from '../../constants'
import { downloadAccountStatement } from 'redux/actions'
import { useAppLoading } from 'hooks/appLoading'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { paramFinder, paramRemover } from 'utils/paramFInder'


const Statement = ({ enddates, startdates }: { enddates: any, startdates: any }) => {

    const [enddate, setEndDateParmVal] = useState<any>();
    const [startdate, setStartDateParmVal] = useState<any>();

    useEffect(() => {
        getDashboardDetail()
        setEndDateParmVal(enddates);
        setStartDateParmVal(startdates);

        setTimeout(() => {
            paramRemover()
        }, 1000)

    }, []);

    const urlParamsObj = {
        defaultRoute: "?value=5&startdate=&enddate=",
        paramsRoute: `?value=5&startdate=${startdate}&enddate=${enddate}`
    }

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const convertDateFunc = () => {
        if (enddate) {
            const dateString = enddate.substring(0, 8); // Extract "31052024"

            const month = dateString.substring(2, 4); // Extract month (05)
            const year = dateString.substring(4); // Extract year (2024)

            // Convert month from string to number
            const monthNumber = parseInt(month, 10);
            const monthName = monthNames[monthNumber - 1]; // Subtract 1 since array index starts from 0

            // Construct the desired date string
            return ` ${monthName} ${year}`;
        } else {
            return;
        }
    }

    // const loadingSkeleton = () => {
    //     return (
    //         <>
    //             <Stack spacing={2} marginTop={"10px"}>
    //                 {[1, 2, 3].map((i) => {
    //                     return (
    //                         <Skeleton key={i} sx={{ width: { sm: "100%", md: "50%" } }} height={25} />
    //                     )
    //                 })}
    //             </Stack>
    //         </>
    //     )
    // }

    const loading = useAppLoading("downloadAccountStatement");
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);

    const dispatch = useAppDispatch();
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const [currentGoalTransactions, setCurrentGoalTransactions] = useState<any>(undefined);
    // const [isCdc, setIsCdc] = useState<boolean>(false)

    // useEffect(() => {
    //     if (currentGoalTransactions.length > 0) {
    //         const cdcFound = userAccountDetails?.accountStatements.find((eachVal: string) => eachVal.toLowerCase() === "Cdc".toLowerCase());
    //         if (cdcFound) {
    //             setIsCdc(false)
    //         } else {
    //             setIsCdc(true)
    //         }
    //     }
    // }, [currentGoalTransactions])



    const getDashboardDetail = () => {
        const completed = accountStatementStatus?.completedStatus
        const progress = accountStatementStatus?.progressStatus

        const found: any = dashboardDetail?.goals.find((item: any) => item?.brokerType.toLowerCase() === "Cdc".toLowerCase() && (item?.goalStatus.toLowerCase() === progress.toLowerCase() || item?.goalStatus.toLowerCase() === completed.toLowerCase()));
        setCurrentGoalTransactions(found)
    }

    const formatDate = (dateString: string) => {
        // Extract the date part (first 8 characters)
        const datePart = dateString.substring(0, 8);

        // Extract day, month, and year
        const day = datePart.substring(0, 2);
        const month = datePart.substring(2, 4);
        const year = datePart.substring(6, 8); // Extract last two digits of the year

        // Format the date as DD/MM/YY
        return `${day}-${month}-${year}`;
    };

    const handleDocumentOpener = () => {
        const defaultDate = new Date();
        const getDay = String(defaultDate.getDate()).padStart(2, '0');
        const getMonth = String(defaultDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const getYear = defaultDate.getFullYear();

        const getDate = formatDate(enddate ? enddate : `${getDay}${getMonth}${getYear}`);
        const onSuccess = (message: any, response: any) => {
            console.log(message, response);
            const blob = new Blob([message], { type: "application/pdf" });
            const pdfStreamUrl = URL.createObjectURL(blob);

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = pdfStreamUrl;
            link.download = `AccountStatement_${getDate}.pdf`;  // Specify the download name

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);

            // Optionally, revoke the object URL to free up memory
            URL.revokeObjectURL(pdfStreamUrl);
        };

        const onFail = (message: any, response: any) => {
            console.log(message, response.status, "OPOOP")
            // toast.error('Unable to download. Try again in a while');
        }


        dispatch(downloadAccountStatement(!startdate && !enddate ? urlParamsObj?.defaultRoute : urlParamsObj?.paramsRoute, onSuccess, onFail))
    }

    return (
        <>
            <Grid item >
                {
                    enddate ? (
                        <Box display={"flex"} alignItems={"center"}>
                            <Typography variant='h4'>Download your Mahaana Save+ account statement until {convertDateFunc()}</Typography>

                        </Box>
                    ) : (
                        <Typography variant='h4'>Download your Mahaana Save+ account statement</Typography>
                    )
                }

                {

                    currentGoalTransactions ? (
                        <>
                            <Typography variant='body1' sx={{ mt: "10px" }}>The statement will show your data for the past 1 year.</Typography>
                            <LoadingBtnCustom loading={loading} btnTitle={"Download statement"} handleOnClick={handleDocumentOpener} style={{ mt: "30px", pt: "15px", pb: "15px", pl: "30px", pr: "30px" }} />

                        </>
                    ) : (

                        <>

                            <Typography variant='body1' sx={{ mt: "10px" }}>Your Mahaana Save+ account must be successfully opened before you can download the account statement.</Typography>
                        </>
                    )



                }
            </Grid>
        </>
    )
}

export default Statement