import React from 'react'
import StaticContent from '../staticContent'
import { Box, Typography } from '@mui/material'

const Progress = ({noteTextShow}:any) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: "flex-start", flexDirection: { xs: "column", md: "row" } , gap: { xs: "20px", md: "32px" } }}>
        <StaticContent noteTextShow ={true}  />

        <Typography sx={{
          borderRadius: "64px", p:"6px 12px", background: '#43BED8', color: "white", fontSize: "15px!important",
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.14px",
          whiteSpace: "nowrap",
        }}>
          Verification in progress
        </Typography>

      </Box>
    </>
  )
}

export default Progress