import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { memo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { OnlineBankFormSchema } from 'validate/paymnet-options/bank-transfer';
import InputField from '../Shared/FormikInputField';
import SelectField from '../Shared/FormikSelectField';
import { paymentOptionProps } from './PaymentOptionsConent';
import './index.css';
import ConfirmPayment from '../Shared/ConfirmationPayment';
import { CurrencyFormatter } from 'utils/calculation';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  OnlineBankFormValue,
  confirmPaymentModalAction
} from 'redux/reducers/payment';
import { toast } from 'react-toastify';
import { Box, Typography, IconButton } from '@mui/material';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import MultipleAccount from './MultipleAccDialog';
import { AxiosResponse } from 'axios';
import { addGoal } from 'redux/actions';

const OnlineBankForm = ({
  banksList,
  paymentAccList,
  submitBankTransfer
}: paymentOptionProps) => {
  const [openAccModal, setOpenAccModal] = useState(false);
  const investment = localStorage.getItem('InvestmentAmount');
  const initial = localStorage.getItem('InitialAmount');
  const [investmentAmount] = useState(Math.ceil(Number(investment)));
  const [initialAmount] = useState(Math.ceil(Number(initial)));
  const { userAccountDetails } = useAppSelector(state => state.auth);
  const { confirmPayment } = useAppSelector(state => state.payment);

  const dispatch = useAppDispatch();

  useEffect(() => {
    OnlineBankFormIk.setFieldValue('bank', banksList[0]);
    OnlineBankFormIk.setFieldValue('bankID', banksList[0]?.label);
  }, [banksList]);

  const handleSelectedAcc = (val: any) => {
    const valueTest = banksList?.filter(i => i.label == val?.bankName);
    OnlineBankFormIk.setFieldValue('bank', valueTest[0]);
    OnlineBankFormIk.setFieldValue('accountNumber', val?.accountNumber);
    OnlineBankFormIk.setFieldValue('accountTitle', val?.accountTitle);
    OnlineBankFormIk.setFieldValue('bankID', valueTest[0].label);
  };

  const accTitle = userAccountDetails?.userName
    ? userAccountDetails?.userName
    : '';

  const defaultValues = {
    accountNumber: '',
    accountTitle: accTitle || '',
    bank: null,
    bankID: '',
    proofOfPayment: ''
  };

  const { addGoalBody } = useAppSelector(state => state.sharedData);
  const OnlineBankFormIk = useFormik({
    enableReinitialize: true,
    initialValues: defaultValues,
    onSubmit: (values: OnlineBankFormValues) => {
      const { accountNumber, accountTitle, bankID, proofOfPayment } = values;

      if (proofOfPayment == '') {
        toast.error('Proof of payment is required',{theme: "colored"});
        return;
      }
      const formData = new FormData();
      formData.append('accountNumber', accountNumber);
      formData.append('accountTitle', accountTitle);
      formData.append('bankName', bankID);
      formData.append('PaymentReceipts', proofOfPayment);

      dispatch(
        OnlineBankFormValue(formData, onSuccessResponse)
      );
      return;
      // eslint-disable-next-line no-console
    },
    validationSchema: OnlineBankFormSchema
  });

  const onSuccessResponse = (message: string, response: AxiosResponse) => {
    return;
  };

  const instructoions = [
    'Please enter your bank details carefully',
    'To complete the investment, you are requested to make an interbank funds transfer for your selected amount',
    'Your account details will be saved for future transactions.'
  ];
  // useEffect(() => {
  //   if (paymentAccList.length > 0 && !confirmPayment) {
  //     setOpenAccModal(true)
  //   }
  // }, [])

  return (
    <>
      <form onSubmit={OnlineBankFormIk.handleSubmit}>
        <ConfirmPayment
          deleteConfirm={confirmPayment}
          handleDeleteCancel={() => dispatch(confirmPaymentModalAction(false))}
          handleDeleteConfirm={() => null}
        />
        <MultipleAccount
          Close={() => setOpenAccModal(false)}
          Open={openAccModal}
          accDetail={paymentAccList}
          selectAcc={(val: any) => {
            handleSelectedAcc(val);
          }}
        />
        <Row>
          <Col className='' md='7'>
            <InputField
              disabled
              required
              col={7}
              formik={OnlineBankFormIk}
              label='Your account title'
              name='accountTitle'
              placeholder='Enter Account Title'
              type='text'
            />

            <div className='mt-4'>
              <SelectField
                col={7}
                fieldName='bank'
                fields={banksList}
                formik={OnlineBankFormIk}
                label='Select your bank'
                onChange={e => {
                  const selectedField = banksList.filter(i => i.value == e);
                  OnlineBankFormIk.setFieldValue('bank', selectedField[0]);
                  OnlineBankFormIk.setFieldValue(
                    'bankID',
                    selectedField[0].label
                  );
                }}
                required={true}
                value={OnlineBankFormIk.values.bank}
              />
            </div>
            <InputField
              required
              col={7}
              formik={OnlineBankFormIk}
              label='Enter your account number'
              name='accountNumber'
              placeholder='Enter account number'
            />
            <br />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontFamily: 'PlayFair'
              }}
            >
              <Row>
                <label className='ml-3 mb-2 field-label1'>
                  <strong>
                    Proof of payment
                    <span className='ml-1 text-danger'>*</span>
                  </strong>
                </label>
              </Row>
              <input
                accept='image/* , .pdf'
                name='proofOfPayment'
                onChange={e => {
                  if (e.currentTarget.files) {
                    OnlineBankFormIk.setFieldValue(
                      'proofOfPayment',
                      e.currentTarget.files[0]
                    );
                  }
                }}
                type='file'
              />
              {!!OnlineBankFormIk?.errors?.proofOfPayment && (
                <Row>
                  <span className='ml-3 mt-1 text-danger'>
                    {OnlineBankFormIk?.errors?.proofOfPayment}
                  </span>
                </Row>
              )}
            </div>
            {paymentAccList.length > 0 ? (
              <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ cursor: 'pointer' }}>
                  You have multiple Bank Accounts. You want to select from them
                  ?
                </Typography>
                <IconButton
                  component='label'
                  onClick={() => setOpenAccModal(true)}
                >
                  <AddLocationIcon sx={{ color: '#49bdd8' }} />
                </IconButton>
              </Box>
            ) : (
              ''
            )}
          </Col>
          <Col className='' md='5' style={{ color: 'black' }}>
            <div></div>
            <p className='heading'>Payment Instructions</p>
            <ul>
              {instructoions.map((points: string, index: number) => {
                return (
                  <li
                    key={index}
                    className='m-4'
                    style={{ listStyleType: 'unset', fontSize: '16px' }}
                  >
                    {points}
                  </li>
                );
              })}
            </ul>
            <div className='payment-detail-div'>
              <p className='heading'>Payment Details</p>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <p className='detailDiv detail-font-weight'>
                    Account title :
                  </p>
                  <p className='detailDiv detail-font-weight'>Bank :</p>
                  <p className='detailDiv detail-font-weight'>IBAN :</p>
                  <p className='detailDiv detail-font-weight'>Amount:</p>
                </div>
                <div>
                  <p className='ml-4 detailDiv'>JS Global Capital Limited</p>
                  <p className='ml-4 detailDiv'>JS Bank Limited</p>
                  <p className='ml-4 detailDiv'>
                    PK73 JSBL 9005 0000 0013 0612
                  </p>
                  <p className='ml-4 detailDiv'>
                    {CurrencyFormatter(initialAmount, true)}
                  </p>
                </div>
              </div>
            </div>
            <div className='text-center'>
              <button className='continue-payment mt-2' type='submit'>
                Continue
              </button>
            </div>
          </Col>
        </Row>
        <div className='text-right'></div>
      </form>
    </>
  );
};
export default memo(OnlineBankForm);

interface OnlineBankFormValues {
  accountNumber: string;
  accountTitle: string;
  bank: null;
  proofOfPayment: File | string;
  bankID: string;
}
