export const CardinfoStyle = {
    itemGridLeft: {
        borderTop: "1px solid #D0D5DD40", borderBottom: "1px solid #D0D5DD40",
        p: 2, backgroundColor: "#fff"
    },
    itemGridRight: {
        border: "1px solid #D0D5DD40", p: 2,
        backgroundColor: "#fff"
    },
}
