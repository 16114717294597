import { Grid, Box, Typography, Checkbox, IconButton, useTheme, Button, useMediaQuery } from '@mui/material'
import { withdrawStyle } from '../Style/withdrawStyle'
import Checkmark from 'react-typescript-checkmark';
import { useEffect, useState } from 'react';
import NoDataScreen from "../../../components/NodataScreen"
import { onDeleteUserBankAccount, onGetUserBankAccount, postUserAcc } from 'redux/reducers/sharedStateData';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import CustomModal from "Latest/MyProfile/ModalV2/confirmationModal";
// import BankIcon from "Latest/Images/bankIcon.png"
import BankIconSelected from "Latest/Images/bankIconSelected.png"
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import SaveIcon from "Latest/Images/saveIcon.png"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { tokens } from 'theme';
import { useAppLoading } from 'hooks/appLoading';
import { toast } from 'react-toastify';
import BankIcon from 'Latest/Images/svg/BankIcon';
import FormikConfirmPasswordField from 'jsx/components/MUI_components/FormikConfirmPasswordField';
import NoAccdataScreen from 'Latest/Illustration/noAccount';

const BankCheckBoxCard = ({ defaultBank = true, defaultViewLimit = 1, deleteBtn = false, formik, isAccTitleShow = true, list, selectedBank, userSelectedBank = false }: any) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const byDefaultViewLimit = defaultViewLimit
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [listLength, setListLength] = useState(byDefaultViewLimit)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedItem, setSelectedItem] = useState<any>({})
    const dispatch = useAppDispatch();
    const loading = useAppLoading('sharedStateData');

    const handleClick = (item: any, index: number) => {
        setSelectedItem(item)
        selectedBank && selectedBank(item)
    }

    const handleDelete = () => {
        const body: postUserAcc = {
            accountNumber: selectedItem?.accountNumber,
            bankName: selectedItem?.bankName
        }
        const onSuccess = () => {
            dispatch(onGetUserBankAccount())
            handleClose()
            toast.success('Deleted successfully', {
                autoClose: 5000,
                closeOnClick: true,
                draggable: true,
                hideProgressBar: false,
                pauseOnHover: true,
                position: 'bottom-right',
                progress: undefined
            });

        }
        const onFail = () => {
            console.log("onFail")
        }
        dispatch(onDeleteUserBankAccount(body, onSuccess, onFail))
    }

    const confirmationContent = () => {
        return (
            <>
                <Typography sx={ModalStyle.headStyle}>Save changes?</Typography>
                <Typography sx={ModalStyle.subheadStyle}>Do you want to delete your account?</Typography>
            </>
        )
    }

    const handleView = () => {
        if (listLength == byDefaultViewLimit) {
            setListLength(list?.length)
        } else {
            setListLength(byDefaultViewLimit)
        }
    }

    useEffect(() => {
        defaultBank && list?.length > 0 && handleClick(list[0], 0); //default bank select
    }, [list]);

    useEffect(() => {
        const userSelected = userSelectedBank && list.filter((item: any, ind: number) => item.accountNumber == formik?.values?.accountNumber) //default bank selected on modal
        userSelectedBank && userSelected && setSelectedItem(userSelected[0])
    }, []);


    return (
        <>
            <CustomModal image={SaveIcon} handleOpen={handleOpen} open={open} handleClose={handleClose} handleConfirm={handleDelete} Content={confirmationContent} loadingConfirmBtn={loading} />

            {list?.length > 0 ?
                <>
                    {list?.slice(0, listLength)?.map((item: any, index: number) => {
                        return (
                            <Grid key={item.accountNumber}
                                sx={{
                                    background: selectedItem?.accountNumber == item.accountNumber ? "#F4F2FF" : "#FFFFFF", ...withdrawStyle.keyGrid
                                    , mb: list.length !== index + 1 ? "24px" : "0px",
                                }}
                                onClick={() => handleClick(item, index)}
                            >
                                <Box sx={{ position: "absolute", top: { xs: 92, sm: 12 }, right: 19 }}>
                                    {selectedItem?.accountNumber == item.accountNumber ?
                                        <Checkmark backgroundColor='#7F56D9' size='sm' />
                                        :
                                        <Box sx={{
                                            height: "15px",
                                            width: "15px",
                                            backgroundColor: "#FFFFFF",
                                            borderRadius: "50%",
                                            display: "inline-block",
                                            border: "1px solid #D0D5DD"
                                        }}></Box>
                                    }
                                </Box>

                                {deleteBtn && !item?.isDefault && <Box sx={{ position: "absolute", top: { xs: 110, sm: 34 }, right: 6 }}>
                                    <IconButton aria-label="delete" onClick={() => handleOpen()}>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Box>}

                                {<Grid item xs={12} sm={2} md={1.5} sx={{ ...withdrawStyle.imgGrid }} >
                                    {/* <img src={`/Images/BankIcon/${item?.bankName?.split(" ")[0]}.png`} alt={"a"} />  //for dynamic bank icon */}
                                    <BankIcon fill={selectedItem?.accountNumber == item.accountNumber ? "#432F87" : "#475467"} />
                                </Grid>}

                                <Grid item xs={12} sm={10} md={10.5} sx={{
                                    py: "17px", px: "22px",
                                    background: { xs: "#FFFFFF", sm: selectedItem?.accountNumber == item.accountNumber ? "#F4F2FF" : "#FFFFFF" }
                                }}>
                                    <Box sx={{ ...withdrawStyle.detailBox, pb: "11.5px" }}>
                                        <Box sx={withdrawStyle.detailSubBoxKey}><Typography variant='body2'>Bank Name</Typography></Box>
                                        <Box sx={withdrawStyle.detailSubBoxValue}><Typography variant='h6'>{item.bankName}</Typography></Box>
                                    </Box>
                                    {isAccTitleShow && <Box sx={{ ...withdrawStyle.detailBox, pb: "11.5px" }}>
                                        <Box sx={withdrawStyle.detailSubBoxKey}><Typography variant='body2'>Account Title</Typography></Box>
                                        <Box sx={withdrawStyle.detailSubBoxValue}><Typography variant='h6'>{item.accountTitle}</Typography></Box>
                                    </Box>}
                                    <Box sx={withdrawStyle.detailBox}>
                                        <Box sx={withdrawStyle.detailSubBoxKey}><Typography variant='body2'>Account Number/IBAN</Typography></Box>
                                        <Box sx={withdrawStyle.detailSubBoxValue}><Typography variant='h6'>{item.accountNumber}</Typography></Box>
                                    </Box>
                                </Grid>

                            </Grid>

                        )
                    })}

                    {list?.length > defaultViewLimit && <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                        <Button sx={{ background: colors.grey[100], color: colors.purple[100] }} onClick={handleView}>
                            {listLength == byDefaultViewLimit ? "view all" : "view less"}
                        </Button>
                    </Box>}

                </>
                :
                <NoAccdataScreen subHeadContent={"There is no account. Please add your account"} loading={loading} />
            }
        </>
    )
}

export default BankCheckBoxCard