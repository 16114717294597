import {
  AreaChart as Chart,
  Area,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { memo } from 'react';
import Big from 'big.js';
import { CurrencyFormatter } from 'utils/calculation';
import { getFromYmdNumToDmyFormat } from 'utils/getDateDMYFormat';

const formatYAxis = (value: any, index: number): string => {
  return Number(value).toLocaleString();
};

const AmountAndTimeAreaChart = ({
  data,
  height,
  maxY,
  showYAxis = true,
  xAxis,
  yAxis
}: AreaChartProps) => (
  <>
    <div>
      <small className='ml-0 mb-5' style={{ fontWeight: 'bold', color: 'black' }}>Savings in PKR</small>
    </div>
    <ResponsiveContainer
      aspect={height ? undefined : 5.0}
      height={height}
      width='100%'
    >
      <Chart data={data} margin={height ? { top: 10 } : {}}>
        <Tooltip
          formatter={(value: string, name: string, props: any) => [CurrencyFormatter(Number(value), true), "Savings"]}
          labelFormatter={(label: any, payload: Array<any>) => getFromYmdNumToDmyFormat(label)}
        />
        <Area
          allowReorder='yes'
          dataKey={yAxis}
          fill='#4012A0'
          fillOpacity={0.1}
          isAnimationActive={false}
          stroke='#4012A0'
          strokeWidth={2}
          type='monotone'
        />
        <XAxis dataKey={xAxis} />
        {showYAxis && <YAxis domain={[() => new Big(data[0].value).div(1000).round(0).mul(1000), maxY == 0 ? 'dataMax+10' : maxY]} interval={'preserveStartEnd'} tickCount={20} tickFormatter={(value, index) => formatYAxis(value, index)} type='number' />}
      </Chart>
    </ResponsiveContainer>
  </>
);

export default memo(AmountAndTimeAreaChart);

interface AreaChartProps {
  data: Array<KeyValuePair>;
  height?: number;
  maxY: number;
  showYAxis?: boolean;
  xAxis: string;
  yAxis: string;
}

interface KeyValuePair {
  key: string,
  value: number
}
