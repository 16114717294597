import { useEffect, useState } from 'react';
import { Col, Button } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { EmployerEmployee } from 'redux/reducers/employer';
import { genderParser, maritalStatusParser } from 'utils/stringParser';
import { number } from 'yup';
const EmployesTable = ({
  employerEmployees,
  employerEmployeesTotalRecords,
  fetchEmployeeById,
  setCurrentPage
}: EmployesTableProps) => {
  const [currentItems, setCurrentItems] =
    useState<Array<EmployerEmployee>>(employerEmployees);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    setCurrentItems(employerEmployees);
    // setPageCount(Math.ceil(employerEmployeesTotalRecords / itemsPerPage));
  }, [employerEmployeesTotalRecords, employerEmployees]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageClick = (event: Record<string, number>) => {
    const selectedPage = event.selected + 1;
    // setCurrentPage(selectedPage);
  };

  // const fetchEmployeeById_ = (employeeId: string) => {
  //   fetchEmployeeById(employeeId)
  // }

  return (
    <>
      <Col lg={12}>
        <div>
          <Items currentItems={currentItems} fetchEmployeeById={fetchEmployeeById}/>
          {currentItems.length ? (
            <ReactPaginate
              activeClassName='active'
              breakClassName='page-item'
              breakLabel='...'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              disabledClassName='disabledBtn'
              marginPagesDisplayed={3}
              nextClassName='page-item'
              nextLabel={
                <i
                  aria-hidden='true'
                  className='fa fa-chevron-right cursor-pointer '
                />
              }
              nextLinkClassName='page-link'
              onPageChange={handlePageClick}
              pageClassName='page-item'
              pageCount={pageCount}
              pageLinkClassName='page-link'
              pageRangeDisplayed={3}
              previousClassName='page-item'
              previousLabel={
                <i
                  aria-hidden='true'
                  className='fa fa-chevron-left cursor-pointer '
                />
              }
              previousLinkClassName='page-link'
            />
          ) : null}
        </div>
      </Col>
    </>
  );
};

export default EmployesTable;

interface EmployesTableProps {
  employerEmployees: Array<EmployerEmployee>;
  employerEmployeesTotalRecords?: number;
  setCurrentPage?: (page: number) => void;
  fetchEmployeeById: (employeeId: string) => void
}
interface ItemsProps {
  currentItems: Array<EmployerEmployee>;
  fetchEmployeeById: (employeeId: string) => void
}

const Items = ({ currentItems, fetchEmployeeById }: ItemsProps) => {
  const heads = [
    { colSpan: 0, heading: 'Name', rowSpan: 2 },
    { colSpan: 0, heading: 'Gender', rowSpan: 2 },
    { colSpan: 0, heading: 'Marital Status', rowSpan: 2 },
    { colSpan: 0, heading: 'Date of Birth', rowSpan: 2 },
    { colSpan: 0, heading: 'NIC Number', rowSpan: 5 },
    { colSpan: 0, heading: 'Income Source', rowSpan: 2 }
  ];
  return (
    <div className='table-responsive'>
      <table
        cellPadding={0}
        cellSpacing={0}
        className='table table-bordered table-striped'
      >
        <thead className='customTableHeader'>
          <tr>
            {heads.map((head, index) => (
              <>
                {head.rowSpan > 0 ? (
                  <th colSpan={head.colSpan} rowSpan={head.rowSpan}>
                    {head.heading}
                  </th>
                ) : (
                  <th colSpan={head.colSpan}>{head.heading}</th>
                )}
              </>
            ))}
          </tr>
        </thead>
        <tbody className='customTableBody table'>
          {currentItems.length
            ? currentItems?.map((employee: EmployerEmployee, index: number) => (
              <tr key={index}>
                <td className='fs-20'>
                  <p className='mb-0 fs-18'>{employee.name}</p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-18'>{genderParser(employee.gender)}</p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-18'>{maritalStatusParser(employee.maritalStatus)}</p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-18'>
                    {new Date(employee.dateOfBirth).toLocaleDateString()}
                  </p>
                </td>
                <td className='fs-20'>
                  {' '}
                  <p className='mb-0 fs-18'>
                    {employee.nationalIdentityNumber}
                  </p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-18'>
                    {employee.incomeSource}
                  </p>
                </td>
                <td className='fs-20'>
                  <Button
                    className='mr-2'
                    onClick={() => fetchEmployeeById(employee?.id)}
                    type='submit'
                  >
                    Update
                  </Button>
                </td>
              </tr>
            ))
            : null}
        </tbody>
      </table>
    </div>
  );
};
