import { useAppLoading } from 'hooks/appLoading';
import { FadeIn } from 'jsx/components/Shared/FadeIn';
import FormikInputField from 'jsx/components/Shared/FormikInputField';
import AppSelectWithLabel from 'jsx/components/Shared/AppSelectWithLabel';
import { InputCategoryEnum, FieldPattern } from '../../../constants';
import FormikRadioField from 'jsx/components/Shared/FormikRadioField';
import FormikCheckboxField from '../Shared/FormikCheckboxField';

export default function DynamicQuestionAnswers({
  activeStep,
  formik,
  nestedQuestion,
  questionAnswers
}: AccountSetupFormProps) {
  const loading = useAppLoading('auth');

  const handleType = (pattern: string) => {
    if (pattern === FieldPattern.Number) {
      return 'number';
    } else if (pattern === FieldPattern.Date) {
      return 'date';
    } else {
      return 'text';
    }
  };

  const TextBox_ = (item: any) => {
    return (
      <FormikInputField
        required
        col={12}
        formik={formik}
        label={item.question}
        maxLength={50}
        name={item.id}
        placeholder='Enter here...'
        thousandSeparator={true}
        type={handleType(item.pattern)}
      />
    );
  };

  const TextBoxNested_ = (parentItem: any, nestedQuestion: any) => {
    return (
      <FormikInputField
        required
        col={12}
        formik={formik}
        maxLength={50}
        name={`${parentItem?.id}-nested`}
        placeholder='Enter here...'
      />
    );
  };

  const RadioButton_ = (item: any) => {
    return (
      <FormikRadioField
        required
        singleLine
        col={12}
        fieldName={item.id}
        fields={item.answer.answerOptions}
        formik={formik}
        label={item.question}
      />
    );
  };

  const RadioButtonNested_ = (parentItem: any, nestedQuestion: any) => {
    return (
      <FormikRadioField
        singleLine
        col={12}
        fieldName={`${parentItem?.id}-nested`}
        fields={nestedQuestion?.answerOptions}
        formik={formik}
        label={''}
      />
    );
  };

  const CheckBox_ = (item: any) => {
    return (
      <FormikCheckboxField
        required
        fieldName={item.id}
        fields={item.answer.answerOptions}
        formik={formik}
        label={item.question}
      />
    );
  };

  const CheckboxNested_ = (parentItem: any, nestedQuestion: any) => {
    return (
      <FormikCheckboxField
        singleLine
        col={12}
        fieldName={`${parentItem?.id}-nested`}
        fields={nestedQuestion?.answerOptions}
        formik={formik}
        label={''}
      />
    );
  };

  const DropDown_ = (item: any) => {
    return (
      <AppSelectWithLabel
        required
        col={6}
        fieldName={item.id}
        fields={item.answer.answerOptions}
        formik={formik}
        label={item.question}
        loading={loading}
        onChange={(val, option) => {
          formik.setFieldValue(item.id, val);
        }}
        placeholder={`Select ${item.question}`}
      />
    );
  };

  return (
    <>
      <FadeIn>
        <>
          {questionAnswers?.questionAnswer[activeStep]?.answer
            ?.answerFieldType === InputCategoryEnum.TextBox &&
            TextBox_(questionAnswers?.questionAnswer[activeStep])}
          {questionAnswers?.questionAnswer[activeStep]?.answer
            ?.answerFieldType === InputCategoryEnum.RadioButton &&
            RadioButton_(questionAnswers?.questionAnswer[activeStep])}
          {questionAnswers?.questionAnswer[activeStep]?.answer
            ?.answerFieldType === InputCategoryEnum.CheckBox &&
            CheckBox_(questionAnswers?.questionAnswer[activeStep])}
          {questionAnswers?.questionAnswer[activeStep]?.answer
            ?.answerFieldType === InputCategoryEnum.DropDownSelection &&
            DropDown_(questionAnswers?.questionAnswer[activeStep])}
        </>
        {nestedQuestion?.answerOptions && (
          <>
            {nestedQuestion.answerFieldType === InputCategoryEnum.TextBox &&
              TextBoxNested_(
                questionAnswers?.questionAnswer[activeStep],
                nestedQuestion
              )}
            {nestedQuestion.answerFieldType === InputCategoryEnum.RadioButton &&
              RadioButtonNested_(
                questionAnswers?.questionAnswer[activeStep],
                nestedQuestion
              )}
            {nestedQuestion.answerFieldType === InputCategoryEnum.CheckBox &&
              CheckboxNested_(
                questionAnswers?.questionAnswer[activeStep],
                nestedQuestion
              )}
          </>
        )}
      </FadeIn>
    </>
  );
}

interface AccountSetupFormProps {
  questionAnswers: any;
  formik?: any;
  activeStep: number;
  nestedQuestion?: any;
}
