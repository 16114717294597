import React, { useRef } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import HeaderText from 'jsx/components/AccountSetupForm/HeaderText';
import { useEffect, useState } from 'react';
import {
  getUserAccountInfo,
  onGetAccountSetup,
  onPostUserAnswers,
  onPutUserAnswers
} from 'redux/actions';
import { onGetCountries } from 'redux/reducers/sharedStateData';
import { getFormattedDate } from 'utils/getDateDMYFormat';
import { useHistory } from 'react-router-dom';
import { useAppLoading } from 'hooks/appLoading';
import { links } from 'static/links';
import { Box, Divider, IconButton, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { gender } from 'constants/dropdownValues';
import FormikInputField from '../../components/Account_Fields/FormikInputFieldLatest';
import FormikRadioField from '../../components/Account_Fields/FormikRadioFieldV2';
import FormikSelectFieldWithLabel from '../../components/Account_Fields/AppSelectWithLabelLatest';
import PhoneField from 'jsx/components/Account_Fields/PhoneFieldFormikLatest';
import { FadeIn } from 'jsx/components/Shared/FadeIn';
import ConfirmationModal from 'jsx/components/AccountSetupForm/ConfirmationModal';
import LoaderWithText from 'jsx/components/Shared/LoaderWithText';
import DynamicQuestionAnswers from 'jsx/components/AccountSetupForm/DynamicQuestionAnswers';
import * as Yup from 'yup';
import { AnswerFieldType, CASH_FUND_ID, InputCategoryEnum, mixPannelTrackerEventName } from '../../../constants';
import '../../../scss/login/_Login.scss';
import { Container, Grid } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Loader from 'jsx/components/Shared/Loader';
import InfoIcon from '@mui/icons-material/Info';
import FormikInputFieldLifeTime from 'jsx/components/Account_Fields/FormikInputFieldLifeTime';
import { accountSetupFormValidation } from 'validate/Screen/AccountSetupFormValidation';
import { goalMainHeadTypoStyle } from 'Latest/Goal/style';
import ButtonsRow from 'jsx/components/WizardFormModal/ButtonsRowLatest';
import { containerMain, containerMainBox, HeadingStyle, subHeadingBlueStyle, accountsetupBtnStyles, juniorHeadingStyle, topInfoWithLabelStyles } from 'Latest/Style/Main';
import ErrModal from 'Latest/MyProfile/Modal/errModal';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import dayjs from 'dayjs';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';

const AccountSetupForm = ({ formikVP, goalId, loading, nextStep, previousStep }: any) => {
  const dispatch = useAppDispatch();
  const { countries } = useAppSelector(state => state.sharedData);
  const { user, userAccountDetails } = useAppSelector(state => state.auth);
  const [isConfirmationModalOpen, setConfirmationModal] = useState(false);
  const [dynamicFormikInitialValues, setDynamicFormikInitialValues] = useState<any>({});
  const [isTrading, setIsTrading] = useState('No');
  const [UKN, setUKN] = useState('');
  const [isLifeTime, setisLifeTime] = useState(userAccountDetails?.identityCard?.lifeTimeExpiry);
  const [openErrModal, setOpenErrModal] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const resLoading = useAppLoading('questionAnswer');

  const { user: { email } } = useAppSelector((state: any) => state.auth);

  const idCard = useRef(userAccountDetails?.identityCard?.identityCardNumber);

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setisLifeTime(userAccountDetails?.identityCard?.lifeTimeExpiry)
  }, [userAccountDetails?.identityCard?.lifeTimeExpiry])

  const onChangeValueTrading = (e: any) => {
    setIsTrading(e.target.value);
    if ((e.target.value = 'No')) {
      setUKN('');
    }
  };

  const onChangeLifeTimeCheck = () => {
    setisLifeTime(!isLifeTime);
    if (isLifeTime) {
      formik.setFieldValue(
        'nationalIdentityExpiryDate',
        getFormattedDate(userAccountDetails?.identityCard?.dateOfExpiry, '/')
      );
    } else {
      formik.setFieldValue('nationalIdentityExpiryDate', '');
    }
    formik.setFieldValue('lifeTimeBit', !isLifeTime);
  };

  useEffect(() => {
    dispatch(onGetCountries());
    dispatch(onGetAccountSetup());
  }, []);

  useEffect(() => {
    if (isLoading) {
      formik.validateForm().then(errors => {
        formik.setErrors(errors);
      });
    }
  }, [isLoading]);


  useEffect(() => {
    if (userAccountDetails?.identityCard?.identityCardNumber) {
      setIsLoading(false)
    } else {
      checkDetail()
    }
  }, [userAccountDetails?.identityCard?.identityCardNumber]);

  useEffect(() => {
    if (userAccountDetails?.identityCard?.countryOfStay) {
      formik.setFieldValue(
        'countryOfStay',
        userAccountDetails?.identityCard?.countryOfStay
      );
    }
  }, [dispatch, userAccountDetails?.identityCard]);


  const checkDetail = () => {
    let intervalId: any;
    let x: any = 1;
    const timer: any = 2000;
    if (!userAccountDetails?.identityCard?.identityCardNumber) {
      intervalId = setInterval(() => {
        setIsLoading(true)
        dispatch(onGetAccountSetup());
        x = x + 1
        if (idCard && x <= 5) {
          clearInterval(intervalId);
          setIsLoading(false)
          return;
        }
        if (x == 5) {
          clearInterval(intervalId);
          setIsLoading(false)
          setOpenErrModal(true)
        }
      }, timer);
    }
    return () => clearInterval(intervalId);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId: user?.id,
      accountType: 0,
      name: userAccountDetails?.identityCard?.name,
      fatherName: userAccountDetails?.identityCard?.fatherName,
      countryOfStay: userAccountDetails?.identityCard?.countryOfStay,
      nationalIdentityNumber: userAccountDetails?.identityCard?.identityCardNumber?.replace(/-/g, ''),
      nationalIdentityIssuanceDate: getFormattedDate(userAccountDetails?.identityCard?.dateOfIssue, '/'),
      nationalIdentityExpiryDate: getFormattedDate(userAccountDetails?.identityCard?.dateOfExpiry, '/'),
      gender: userAccountDetails?.identityCard?.gender === 'Female' ? '0' : '1',
      dateOfBirth: getFormattedDate(userAccountDetails?.identityCard?.dateOfBirth, '/'),
      email: user?.email,
      mobile: `92${user?.phoneNumber?.slice(1)}`,
      lifeTimeBit: userAccountDetails?.identityCard?.lifeTimeExpiry,
      IBANnumber: userAccountDetails?.iban || "",

      // ...dynamicFormikInitialValues
    },
    onSubmit: async (values: any) => {
      onSubmit()
    },
    validationSchema: accountSetupFormValidation
  });

  const onSubmit = () => {
    setConfirmationModal(false);
    const genderEnum = formik?.values?.gender;

    const body = {
      name: formik.values.name,
      fatherOrHusbandName: formik.values.fatherName,
      identityCardNumber: formik.values.nationalIdentityNumber,
      identityCardIssuanceDate: formik.values.nationalIdentityIssuanceDate,
      identityCardExpiryDate: formik.values.nationalIdentityExpiryDate,
      gender:
        genderEnum == "0"
          ? 'Female'
          : genderEnum == "1"
            ? 'Male'
            : genderEnum == "2"
              ? 'Other'
              : 'None'
      ,
      countryOfStay: formik.values.countryOfStay,
      identityCardLifeTimeExpiry: isLifeTime == undefined ? false : isLifeTime,
      ukn: UKN,
      rememberUKN: isTrading != "No",
      iban: formik?.values?.IBANnumber,
      accountType: goalId === CASH_FUND_ID ? "Cashfund" : "Invest",
      dateOfBirth: formik.values.dateOfBirth
    };

    const onSuccess = () => {
      nextStep();
      MixPannelTracker({ eventName: mixPannelTrackerEventName.cnic_info_submitted, email })
    }

    dispatch(onPutUserAnswers(body, onSuccess));
  };


  useEffect(() => {
    let modifiedValue: any = formik.values.IBANnumber || '';

    modifiedValue = modifiedValue.toUpperCase();
    modifiedValue = modifiedValue.replace(/\s/g, '');

    formikVP.setFieldValue('IBANnumber', modifiedValue)
    formik.setFieldValue('IBANnumber', modifiedValue)

  }, [formik.values.IBANnumber])

  const ContentFailed = () => {
    return (
      <Grid item xs={12} sx={{ pt: "8px" }}>
        <Typography sx={ModalStyle.headStyle}>Oops!</Typography>
        <Typography sx={ModalStyle.subheadStyle}>Something went wrong. Please try again.</Typography>
      </Grid>
    )
  }

  if (isLoading) {
    return <Loader multilineTitle={userAccountDetails?.identityCard?.identityCardNumber == undefined ? ["Please be patient with us"] : ["We are extracting your CNIC details.", "Please be patient with us"]} />
  }

  const FemaleGender = formik.values.gender == "0";

  const goBack = (e: any) => {
    e.preventDefault();
    previousStep();
  }

  const goNext = (e: any) => {
    e.preventDefault();
    formik.submitForm();
  }

  const buttonHtml = () => {
    return (
      <ButtonRowV1 handleNext={goNext} handleBack={goBack}
        btnTitleNext={"Continue"} loadingNextBtn={loading || resLoading}
        disableNext={
          !!formik?.errors?.nationalIdentityIssuanceDate ||
          !!formik?.errors?.nationalIdentityExpiryDate ||
          !!formik?.errors?.fatherName ||
          !!formik?.errors?.nationalIdentityNumber ||
          !!formik?.errors?.countryOfStay
        }
      />
    )
  }

  return (
    <>
      <ErrModal customOnClose={() => previousStep(undefined, 1)} handleOpen={() => setOpenErrModal(true)} open={openErrModal} handleClose={() => setOpenErrModal(false)} Content={ContentFailed} btnTitle={"Please Try again"} />

      <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ py: { xs: "24px", md: "40px" } }}>

        <Grid container>

          <Grid item md={12} xs={12} sx={mainContainerV2}>

            <Grid item md={12} xs={12}>
              <Typography variant='h4' color={"#1D2939"} lineHeight={"normal"} textAlign={"center"}>CNIC details</Typography>
              <Typography variant='body1' color={"rgba(29, 41, 57, 0.70)"} lineHeight={"normal"} pt={1} textAlign={"center"}>The information below should match with your CNIC.</Typography>
            </Grid>

            <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"40px"}>
              <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"16px"}>

                {/* name / father name */}
                <Grid item md={12} xs={12} display={"flex"} columnGap={"24px"} rowGap={"16px"} flexWrap={{ md: "nowrap", sm: "nowrap", xs: "wrap" }}>
                  <Grid item md={12} xs={12}>
                    <FormikInputField
                      formik={formik}
                      FieldTouchInput={true}
                      label="Name"
                      name='name'
                      placeholder="Enter name"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormikInputField
                      formik={formik}
                      FieldTouchInput={true}
                      label={FemaleGender ? "Father's / Husband's name" : "Father's name"}
                      name='fatherName'
                      placeholder={FemaleGender ? "Enter Father's / Husband's name" : "Enter Father's name"}
                    />
                  </Grid>
                </Grid>

                {/* cnic number / cnic issue date */}
                <Grid item md={12} xs={12} display={"flex"} columnGap={"24px"} rowGap={"16px"} flexWrap={{ md: "nowrap", sm: "nowrap", xs: "wrap" }}>
                  <Grid item md={12} xs={12}>
                    <FormikInputField
                      formik={formik}
                      FieldTouchInput={true}
                      label='CNIC number'
                      maxLength={13}
                      name='nationalIdentityNumber'
                      placeholder='Enter CNIC number'
                    />
                  </Grid>

                  <Grid item md={12} xs={12} className="dateOfCNICIssuanceInput">
                    <FormikInputField
                      formik={formik}
                      FieldTouchInput={true}
                      label='CNIC issue date'
                      name='nationalIdentityIssuanceDate'
                      placeholder=''
                      type='Date'
                      maxDate={dayjs()}
                    />
                  </Grid>
                </Grid>

                {/* cnic expiry date / date of birth */}
                <Grid item md={12} xs={12} display={"flex"} columnGap={"24px"} rowGap={"16px"} flexWrap={{ md: "nowrap", sm: "nowrap", xs: "wrap" }} className="dateOfCNICIssuanceInput">
                  <Grid item md={12} xs={12}>
                    <FormikInputFieldLifeTime
                      disabled={isLifeTime}
                      FieldTouchInput={true}
                      formik={formik}
                      isLifeTime={isLifeTime}
                      label='CNIC expiry date'
                      name='nationalIdentityExpiryDate'
                      onChangeLifeTimeCheck={onChangeLifeTimeCheck}
                      placeholder=''
                      type='Date'
                    />
                  </Grid>

                  <Grid item md={12} xs={12} className="dateOfCNICIssuanceInput">
                    <FormikInputField
                      formik={formik}
                      FieldTouchInput={true}
                      label='Date of birth'
                      name='dateOfBirth'
                      placeholder=''
                      type='Date'
                      maxDate={dayjs()}
                    />
                  </Grid>
                </Grid>

                {/* gender / country */}
                <Grid item md={12} xs={12} display={"flex"} columnGap={"24px"} rowGap={"16px"} flexWrap={{ md: "nowrap", sm: "nowrap", xs: "wrap" }}>

                  <Grid item md={12} xs={12}>
                    <FormikRadioField
                      fieldName='gender'
                      fields={gender}
                      formik={formik}
                      label='Gender'
                      singleLine={true}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormikSelectFieldWithLabel
                      disabled={false}
                      fieldName='countryOfStay'
                      fields={countries}
                      formik={formik}
                      label='Country of stay'
                      onChange={(val, option) => {
                        formik.setFieldValue('countryOfStay', val);
                      }}
                      placeholder='Select'
                      value={formik.values.countryOfStay}
                      FieldTouchInput={true}
                    />
                  </Grid>

                </Grid>

              </Box>

              {!smScreen && buttonHtml()}
            </Box>
          </Grid>


          {smScreen && <Box sx={smallScreenBtnWrap}>
            {buttonHtml()}
          </Box>}


        </Grid>

      </Container>

      {/* <Box sx={containerMainBox}>
            <Grid container spacing={2} >
              <Grid item md={12} xs={12} sx={{ p: "1rem", pb: "2rem" }} >
                <>
                  <Container sx={{ ml: "0.55rem", minHeight: "30rem", mt: "2rem", mb: "2rem" }} >
                    <Box>
                      <Grid container spacing={2}>

                        <Grid item md={12} xs={12} >
                          <Typography sx={HeadingStyle}>Confirm Information</Typography>
                        </Grid>

                        <Grid item md={12} xs={12} >
                          <Typography sx={subHeadingBlueStyle}>CNIC Details</Typography>
                          <Typography sx={juniorHeadingStyle}>The information below must match the information on your CNIC. Please verify the data.</Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <FormikInputField
                            required
                            formik={formik}
                            FieldTouchInput={true}
                            label="Name"
                            name='name'
                            placeholder="Enter name"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <FormikInputField
                            required
                            formik={formik}
                            FieldTouchInput={true}
                            label={FemaleGender ? "Father's / Husband's name" : "Father's name"}
                            name='fatherName'
                            placeholder={FemaleGender ? "Enter Father's / Husband's name" : "Enter Father's name"}
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <FormikInputField
                            required
                            formik={formik}
                            FieldTouchInput={true}
                            label='CNIC Number'
                            maxLength={13}
                            name='nationalIdentityNumber'
                            placeholder='Enter CNIC Number'
                          />
                        </Grid>

                        <Grid item md={12} xs={12} className="dateOfCNICIssuanceInput">
                          <FormikInputField
                            required
                            formik={formik}
                            FieldTouchInput={true}
                            label='Date of CNIC Issuance'
                            name='nationalIdentityIssuanceDate'
                            placeholder=''
                            type='Date'
                            maxDate={dayjs()}
                          />
                        </Grid>

                        <Grid item md={12} xs={12} className="dateOfCNICIssuanceInput">
                          <FormikInputFieldLifeTime
                            required
                            disabled={isLifeTime}
                            FieldTouchInput={true}
                            formik={formik}
                            isLifeTime={isLifeTime}
                            label='Date of CNIC Expiry'
                            name='nationalIdentityExpiryDate'
                            onChangeLifeTimeCheck={onChangeLifeTimeCheck}
                            placeholder=''
                            type='Date'
                          />
                        </Grid>

                        <Grid item md={12} xs={12} className="dateOfCNICIssuanceInput">
                          <FormikInputField
                            required
                            formik={formik}
                            FieldTouchInput={true}
                            label='Date of Birth'
                            name='dateOfBirth'
                            placeholder=''
                            type='Date'
                            maxDate={dayjs()}
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <FormikSelectFieldWithLabel
                            required
                            disabled={false}
                            fieldName='countryOfStay'
                            fields={countries}
                            formik={formik}
                            label='Country of stay'
                            onChange={(val, option) => {
                              formik.setFieldValue('countryOfStay', val);
                            }}
                            placeholder='Select'
                            value={formik.values.countryOfStay}
                            FieldTouchInput={true}
                          />
                        </Grid>

                        <Box sx={{ pl: "16px" }}>
                          <FormikRadioField
                            required
                            fieldName='gender'
                            fields={gender}
                            formik={formik}
                            label='Gender'
                            singleLine={true}
                          />
                        </Box>

                      </Grid>

                      <Divider sx={{ my: 1 }} />

                      <Grid item md={12} xs={12} sx={{ my: 2.5 }}>
                        <Typography sx={subHeadingBlueStyle}>Additional Details</Typography>
                      </Grid>

                      {goalId != CASH_FUND_ID &&
                        <><Grid item md={12} xs={12}>
                          <label className=' field-label'>
                            <strong
                              style={{ fontFamily: 'Inter,sans-serif', color: '#000000' }}
                            >
                              Existing stock trading account
                              <Tooltip title='Your Unique Know Your Customer (UKN) is provided by NCCPL. Please contact your broker.'>
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </strong>
                          </label>

                          <div
                            onChange={onChangeValueTrading}
                            style={{ display: 'flex' }}
                          >
                            <Form.Check
                              checked={isTrading === 'No'}
                              id={`stock`}
                              label={`No`}
                              name='stock'
                              style={{ marginTop: '5px' }}
                              type='radio'
                              value='No'
                            />

                            <Form.Check
                              checked={isTrading === 'Yes'}
                              id={`stock`}
                              label={`Yes`}
                              name='stock'
                              style={{ marginLeft: '5px', marginTop: '5px' }}
                              type='radio'
                              value='Yes'
                            />
                          </div>
                        </Grid>


                          <Grid item md={12} xs={12}>
                            <input
                              className='text-box-custom'
                              disabled={isTrading === 'No'}
                              onChange={e => setUKN(e.target.value)}
                              placeholder='Enter UKN number'
                              // type={'number'}
                              style={{ width: "100%", marginTop: 10 }}
                              value={UKN}
                            />
                          </Grid>
                        </>
                      }
                      <Grid item md={12} xs={12} sx={{ my: 1, ...topInfoWithLabelStyles }}>
                        <FormikInputField
                          required
                          upperCaseEnable={true}
                          removeWhitespace={true}
                          FieldTouchInput={true}
                          formik={formik}
                          label='IBAN Number'
                          minLength={24}
                          maxLength={100}
                          name='IBANnumber'
                          placeholder='Enter IBAN Number'
                          topInfo={['Add a bank account that’s in your name', 'For your security, all withdrawals will be sent to this bank account']}
                        />
                      </Grid>

                    </Box>


                    <Grid item md={12} xs={12} sx={{ mt: '2rem', ...accountsetupBtnStyles }}>
                      <ButtonsRow
                        backButtonOnClick={(e: any) => {
                          e.preventDefault();
                          previousStep();
                        }}
                        loading={loading}
                        nextButtonDisabled={
                          !!formik?.errors?.nationalIdentityIssuanceDate ||
                          !!formik?.errors?.nationalIdentityExpiryDate ||
                          !!formik?.errors?.fatherName ||
                          !!formik?.errors?.nationalIdentityNumber ||
                          !!formik?.errors?.IBANnumber
                        }
                        nextButtonOnClick={(e: any) => {
                          e.preventDefault();
                          formik.submitForm();

                        }}
                      />
                    </Grid>
                  </Container>
                </>
              </Grid>
            </Grid>
          </Box> */}
    </>
  );
}

export default AccountSetupForm