import React, { useEffect, useState } from "react";
import { Button, Card, Container, Table, } from "react-bootstrap";
import './index.css'
import { useHistory, useLocation } from 'react-router-dom';
import { links } from 'static/links';

import { LocalUrl } from "redux/middlewares/api";
import axios from "axios";
import DeleteEquity from "jsx/components/DeleteEquity/DeleteEquity";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks/reduxHooks";
import { onGetHolding } from "redux/reducers/sharedStateData";
// import {Card} from ''

const EquityAllocation = () => {
    const location = useLocation();
    const history = useHistory();
    const [getData, setGetData] = useState<any>([]);
    const [modalOpen, setModalopen] = useState(false)
    const [objid, setObjeId] = useState('')
    const dispatch = useDispatch();
    const { holdings } = useAppSelector(state => state.sharedData)

    useEffect(() => {
        dispatch(onGetHolding());
    }, [dispatch])
    const Addhandler = () => {
        history.push(links.EquityForm)

    }
    return (
        <Container>
            <Card>
                <Card.Body>
                    <div>
                        {
                            modalOpen === true && <DeleteEquity id={objid} openModal={modalOpen} setOpenModal={setModalopen} />
                        }
                        <div>
                            <h3>
                                Risk Profile
                            </h3>
                        </div>
                        <Table hover>
                            <thead style={{}}>
                                <tr>

                                    <th>
                                        Name:
                                    </th>
                                    <th>
                                        is Default:
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        Holdings :
                                    </th>
                                </tr>
                                {/* <th>
                        Holdings:
                    </th> */}
                                {/* <th> */}
                                {/* <svg fill="currentColor" height="20" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" >
                            <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z" />
                            <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                        </svg> */}
                                {/* <div style={{ backgroundColor: 'red' }}>

                            <Button className="NewButton" onClick={() => Addhandler()} style={{ backgroundColor: 'transparent', color: 'black', width: '10rem', height: '3rem' }}  >
                                <svg fill="currentColor" height="20" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />  </svg>
                                Add
                            </Button>
                        </div> */}
                                {/* </th> */}
                            </thead>

                            <tbody style={{}}>
                                {
                                    holdings?.map((data: any, id: number) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>
                                                        {data.name}
                                                    </td>
                                                    <td>
                                                        {data.isRetired === true ? "Yes" : "False"}
                                                    </td>
                                                    {/* {data.riskScoreHoldingAllocations.map((holdings: any, index: any) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        {holdings.riskScoreFrom} to {holdings.riskScoreTo}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            holdings.holdingAllocated.map((allocations: any, index: any) => {
                                                                                return (
                                                                                    <>
                                                                                        <div>
                                                                                            {getData.holdingDtos[index]?.name} -
                                                                                            {allocations.value}
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })} */}
                                                    <td>
                                                        <svg className="bi bi-three-dots-vertical" height="16" onClick={() => { setModalopen(true); setObjeId(data.id) }} viewBox="0 0 16 16" width="26" xmlns="http://www.w3.org/2000/svg" >
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        </Container>
    )
}
export default EquityAllocation;