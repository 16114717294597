export const customCard = {
    mainBox: {
        borderRadius: { xs: "0px", sm: "6px" }, border: { xs: "0px", sm: "1px solid #EAECF0" }, background: "#FFF"
    },
    headBox: {
        py: "12px", px: "20px", borderRadius: "8px 8px 0px 0px", borderBottom: "1px solid #EAECF0", background: "#FFF"
    },
    mainBoxV2: {
        background: "#fff", border: "1px solid #D0D5DD40"
    },
    headBoxV2: {
        py: "12px", px: "20px"
    },
    menuWrapper: {
        '& .MuiPaper-root': {
            backgroundColor: "#fff", border: "1px solid #D0D5DD40", boxShadow: "0px 1px 4px 0px #D0D5DD40",
            mt: "10px", transform: "translateX(-10px) !important", borderRadius: "8px",
            '& ul': {
                padding: 0,
                '& li': {
                    padding: "10px 16px"
                }
            }
        }
    }
}
