import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import reportWebVitals from 'reportWebVitals';

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
  if (publicUrl.origin !== window.location.origin) {
    // Service worker won't work if PUBLIC_URL is on a different origin
  }


  window.addEventListener('load', async () => {
    const registration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`);
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // New content is available and ready to replace the old one
            const updateBox = document.createElement('iframe');
            updateBox.style.display = 'none';
            updateBox.src = `${process.env.PUBLIC_URL}/update.html`;
            document.body.appendChild(updateBox);
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          } else {
            // First load
          }
        }
      };
    };
  });
}

const rootElement = document.getElementById('root');

const root = ReactDOM.createRoot(rootElement);

root.render(
   <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
   </React.StrictMode>
);

reportWebVitals();


