import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Container, Grid, Stack, Toolbar, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import { HoldingsShare } from 'redux/reducers/sharedStateData';
import { accordianListBoxStyle, accordianListDetailStyle, accordianListItemStyle, accordianListTitleStyle } from '../Style/accordianListStyle';

export default function AccordionList({ colors, data }: AccordianListProps) {
  const menuItem = false;
  const box = (index: number, item: any) => {
    return (
      <>
        <Grid item lg={12} md={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Typography sx={accordianListItemStyle}>  {item.name} </Typography>
            <Typography sx={accordianListItemStyle}><strong>{item.percentage}%</strong> </Typography>
          </Box>
        </Grid>
      </>
    );
  };

  const [expanded, setExpanded] = React.useState<string | false>('panel0');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const section = (index: number, share: HoldingsShare) => {
    return (
      <Accordion
        key={index}
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
      // sx={{ width: 350 }}
      >
        <AccordionSummary
          aria-controls='panel1a-content'
          expandIcon={<ExpandMoreIcon />}
          id='panel1a-header'
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Typography sx={{ ...accordianListTitleStyle, fontWeight: 500, }}>
              {colors?.length > 0 && <div style={{ width: "12px", height: "12px", background: colors[index] }}></div>}
              {share.name}</Typography>
            <Typography sx={accordianListTitleStyle}> {share.percentage}%</Typography>
          </Box>
        </AccordionSummary>
        {share?.portfolioAllocations?.length > 0 &&
          <AccordionDetails sx={accordianListDetailStyle}>
            {share.portfolioAllocations?.map((item, idx) => {
              const { percentage } = item as any;
              if (percentage != 0.0) {
                if (!menuItem) {
                  return box(idx, item);
                } else {
                  return <p key={idx}> No record found</p>;
                }
              }
            })}

          </AccordionDetails>
        }
      </Accordion>
    );
  };

  return (
    <div>
      {data?.map((data, index) => data?.percentage > 0 && section(index, data))}
    </div>
  );
}
export interface AccordianListProps {
  data?: HoldingsShare[];
  colors?: any
}

