import { Card, Col, Row } from 'react-bootstrap';
import { memo } from 'react';
import InputField, {
    FormikFieldProps
} from 'jsx/components/Shared/FormikInputField';
import FormikRadioField from 'jsx/components/Shared/FormikRadioField';
import { investmentType } from 'static/selection';
import { useLocation } from 'react-router-dom';
import { checkProperties } from 'utils/validateObject';


const ButtonsRow = ({ backButtonDisabled = false, backButtonLabel, backButtonOnClick, isBackbutton = true, loading, nextButtonDisabled = false, nextButtonLabel, nextButtonOnClick }: ButtonsRowProps) => {
    return (
        <Col md="12">
            <div style={{ background: '', display: 'flex', justifyContent: 'end', rowGap: '12px' }} >
                {isBackbutton && <button
                    className='BackButton'
                    disabled={backButtonDisabled || loading}
                    onClick={backButtonOnClick}
                >
                    {backButtonLabel != undefined ? backButtonLabel : 'Back'}
                </button>}
                <button
                    className='NextButton'
                    disabled={nextButtonDisabled || loading}
                    onClick={nextButtonOnClick}
                >
                    {nextButtonLabel != undefined ? nextButtonLabel : 'Next'}
                </button>
            </div>
        </Col>
    )
};

export default memo(ButtonsRow);

export interface ButtonsRowProps {
    backButtonDisabled?: boolean;
    backButtonOnClick?: any;
    nextButtonDisabled?: boolean;
    nextButtonOnClick?: any;
    nextButtonLabel?: string;
    backButtonLabel?: string;
    loading?: any;
    isBackbutton?: boolean
}