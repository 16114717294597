import { memo, useState, useEffect } from 'react';
import { Button, Card, Col, Row, Spinner, NavDropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { links } from 'static/links';
import BorderButton from '../Border Button/BorderButton';
import Performance from '../FundDetails/Performance';
import RiskMeter from '../FundDetails/RiskMeter';
import '../FundDetails/style.css';
import YieldTable from '../FundDetails/YieldTable';
import {
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  ResponsiveContainer,
  Area,
  AreaChart,
  CartesianGrid
} from 'recharts';
// import './thematic.css';
import UpArrow from '../../../images/upArrow.png';
import DownArrow from '../../../images/downArrow.png';
import Loader from '../Shared/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import axios from 'axios';
import Big from 'big.js';
import Grid from '@mui/material/Grid';
import { Container, Stack, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import ConfirmationModal from '../Shared/ConfirmationModal';
import { STOCK_DETAIL_BASE_URL } from '../../../constants/index';
import { getFromYmdNumToDmyFormat } from 'utils/getDateDMYFormat';
import './styles/table.scss';
import { CurrencyFormatter } from 'utils/calculation';
import Statistics from '../Shared/Statistics';
import { onGetEtfBasketData } from 'redux/reducers/etfBasket';
import { PostStock } from 'redux/reducers/equityBasket';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));
interface Statistics {
  minGraphY: number;
  maxGraphY: number;
}

const TabContent = ({
  basketId,
  basketName,
  bg,
  items,
  title
}: TabContentProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { push } = useHistory();
  const [confirmatonModal, setConfirmatonModal] = useState(false);
  const [stockID, setStockID] = useState<any>('');
  const { user } = useAppSelector(state => state.auth);
  const { userAccountInfo } = useAppSelector(state => state.auth);
  const { allDashboardData } = useAppSelector(state => state.dashboard);
  const { allEtfBasketData } = useAppSelector(state => state.etfBasket);

  const [stats, setStats] = useState<Statistics>({
    maxGraphY: 0,
    minGraphY: 0
  });

  const activeBar = () => ({
    borderLeft: `7px solid ${bg}`,
    paddingLeft: 3,
    paddingRight: 0
  });

  const formatXAxis = (value: any, index: number) => {
    const str = value.toString();
    const y = Number(str.substring(0, 4));
    const m = Number(str.substring(4, 6));
    const d = Number(str.substring(6, 8));

    const date = new Date(y, m, d);
    if (allEtfBasketData.eftHistories.length < 90) {
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    } else if (date.getMonth() == 0) {
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    } else {
      return `${date.getFullYear()}`;
    }
  };

  useEffect(() => {
    dispatch(onGetEtfBasketData());
  }, []);

  useEffect(() => {
    const values = allEtfBasketData?.eftHistories?.map(
      (a: any) => a.sectorIndex
    );
    const min = Math.min(...values);
    const max = Math.max(...values);

    const statsData: Statistics = {
      maxGraphY: max,
      minGraphY: min
    };
    setStats(statsData);
  }, [allEtfBasketData]);

  const handleInvest = async () => {
    const body = {
      basketId: basketId,
      basketName: basketName,
      type: 'TETF'
    };

    const onSuccess = () => {
      toast.success(`Your basket execution is in process`);
      return;
    }

    dispatch(PostStock(body, onSuccess))
  };

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: theme =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1
      }}
    >
      {confirmatonModal && (
        <ConfirmationModal
          basketName={allEtfBasketData.displayName}
          handleCancel={() => setConfirmatonModal(false)}
          handleConfirm={() => handleInvest()}
          show={confirmatonModal}
        />
      )}
      <Container
        maxWidth='xl'
        sx={{ mt: 4, mb: 4, minHeight: 390, maxHeight: 'auto' }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            xs={12}
          >
            <Grid item lg={8} md={8} xs={12}></Grid>

            <Grid
              item
              lg={4}
              md={4}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
              xs={12}
            >
              <BorderButton
                ClickHandler={() => setConfirmatonModal(true)}
                IsBorder={false}
                Text=' Invest Now'
                disabled={userAccountInfo.isNewUser}
              />
            </Grid>
          </Grid>

          <Grid item lg={8} md={8} xs={12}>
            <Paper
              className='cardanimation'
              sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
            >
              <Typography
                sx={{
                  width: '100%',
                  marginLeft: '1.5rem',
                  marginBottom: '1rem',
                  marginTop: '1rem'
                }}
              >
                <h3 className='tableProduct_header_heading'>Performance</h3>
              </Typography>
              <ResponsiveContainer height={270} width='100%'>
                <AreaChart
                  data={allEtfBasketData?.eftHistories}
                  height={250}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  width={730}
                >
                  {/* <CartesianGrid stroke="#ccc" strokeDasharray="5 5" /> */}
                  <XAxis
                    allowDuplicatedCategory={false}
                    axisLine={false}
                    dataKey='date'
                    tick={{ fill: '#899CA8' }}
                    tickFormatter={formatXAxis}
                    type='category'
                  />
                  <YAxis
                    allowDataOverflow={true}
                    allowDecimals={true}
                    axisLine={false}
                    domain={[
                      (dataMin: number) =>
                        stats.minGraphY > 0 && isFinite(stats.minGraphY)
                          ? new Big(stats.minGraphY).mul(
                              1 -
                                ((stats.maxGraphY - stats.minGraphY) /
                                  stats.maxGraphY) *
                                  0.001
                            )
                          : 0,
                      (dataMax: number) =>
                        stats.maxGraphY > 0 && isFinite(stats.maxGraphY)
                          ? new Big(stats.maxGraphY).mul(
                              1 +
                                ((stats.maxGraphY - stats.minGraphY) /
                                  stats.maxGraphY) *
                                  0.1
                            )
                          : 0
                    ]}
                    interval={'preserveStartEnd'}
                    tickCount={20}
                    tickFormatter={(value, index) =>
                      CurrencyFormatter(value, false, 'never', false)
                    }
                    type={'number'}
                  />
                  <defs>
                    <linearGradient id='colorUv' x1='0' x2='0' y1='0' y2='1'>
                      <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#8884d8' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                      color='red'
                      id='colorPv'
                      x1='0'
                      x2='0'
                      y1='0'
                      y2='1'
                    >
                      <stop offset='5%' stopColor='#82ca9d' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#82ca9d' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Tooltip
                    formatter={(value: string, name: string, props: any) => [
                      CurrencyFormatter(Number(value), true),
                      name
                    ]}
                    labelFormatter={(label: any, payload: Array<any>) =>
                      getFromYmdNumToDmyFormat(label)
                    }
                  />
                  <Area
                    dataKey='sectorIndex'
                    fill='url(#colorUv)'
                    fillOpacity={1}
                    name='sector Index'
                    stroke='#443087'
                    type='monotone'
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
            <Paper
              className='cardanimation'
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                minHeight: 250,
                maxHeight: 'auto'
              }}
            >
              <YieldTable
                data={allEtfBasketData?.pricesYieldList}
                heading={'ETF Snapshot'}
              />
            </Paper>
          </Grid>

          <Grid item lg={8} md={8} xs={12}></Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Paper
              className='cardanimation'
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                minHeight: 390,
                maxHeight: 'auto'
              }}
            >
              <Typography
                sx={{
                  width: '100%',
                  marginBottom: '1rem',
                  marginTop: '1rem'
                }}
              >
                <h3 className='tableProduct_header_heading'> Overview</h3>
              </Typography>
              <div style={{ padding: ' 0.25rem 2rem 0.25rem 0rem' }}>
                <p className='tableProduct_over_text'>
                  {allEtfBasketData?.productSummary}
                </p>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default memo(TabContent);

interface TabContentProps {
  bg: string;
  title: string;
  items: any;
  basketName?: string;

  basketId: any;
}
