import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Grid, Typography } from '@mui/material'
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack'
import UploadCustomInputFieldV1 from 'jsx/components/Shared/V1/uploadCustomInputFieldV1'
import React, { useRef } from 'react'
import { zakatStyle } from '../Style/style'
import zakatFormCheckIcon from "Latest/Images/svg/zakatFormCheck.png"
import zakatFormRemoveIcon from "Latest/Images/svg/removeX.png"
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom'

const SubmitCard = ({ formik, handleCloseModal, loading, path }: any) => {

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileSelect = (event: any) => {
        if (event.target.files.length > 0) {
            const file = event.target.files?.[0];
            formik.setFieldValue("document", file);
        }
    };

    const clearFile = () => {
        formik.setFieldValue("document", null); // Reset the file field value
    };

    const formikTouch = formik.touched as any;
    const formikError = formik.errors as any;
    const fieldName = "document"

    return (
        <>
            <Grid item xs={12} sx={zakatStyle.zakatFormWrap}>
                {formik?.values[fieldName]?.name ?
                    <>

                        <Typography color={"#667085"} variant='subtitle1' lineHeight={"24px"} mb={"4px"}>
                            <img src={zakatFormCheckIcon} width={"19.5px"} style={{ marginRight: "8px" }} />
                            {formik?.values[fieldName]?.name}
                        </Typography>

                        {path != "modal" && <LoadingBtnCustom handleOnClick={formik.submitForm} loading={loading} btnTitle={"Submit form"} style={{ padding: "8px 24px", fontSize: "1rem", minWidth: "149px", lineHeight: "24px" }} />}

                        <Box sx={{ ...zakatStyle.removeIcon }} onClick={clearFile}>
                            <img src={zakatFormRemoveIcon} width={20} />
                        </Box>
                    </>

                    :

                    <>
                        <Box sx={{ display: "flex" }}>
                            <input
                                ref={fileInputRef}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileSelect}
                                accept={".png, .jpg, .jpeg, .pdf"}
                            />
                            <LoadingBtnCustomBack btnTitle="Upload an available Zakat form" style={zakatStyle.uploadZakatBtn} handleOnClick={handleButtonClick} />
                        </Box>
                        <Typography sx={zakatStyle.captionText}>Accepts PDFs and images</Typography>
                    </>
                }

            </Grid>

            <Typography ><span className='mt-1 text-danger'>{formik.errors.document}</span></Typography>


            {/* <Typography variant='body2' mb="6px">Zakat declaration file</Typography>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "12px" }}>
                <Box sx={{ width: "100%", display: "flex", }}>
                    <UploadCustomInputFieldV1
                        formik={formik}
                        fieldName="document"
                    />
                </Box>

                {path != "modal" && <LoadingButton size="large" loading={loading} onClick={formik.submitForm} variant='contained' color={"secondary"} sx={{ width: { xs: "100%", sm: "320px" } }}>Submit for approval</LoadingButton>}

            </Box>

            <Typography ><span className='mt-1 text-danger'>{formik.errors.document}</span></Typography>

            <Typography sx={{
                color: "#475467",
                fontSize: "12px!important",
                fontWeight: 400,
                lineHeight: "18px",
                mt: "6px"
            }}>PNG, JPG, PDF</Typography> */}

        </>
    )
}

export default SubmitCard