import { useContext, useEffect, useState } from 'react';


// // Extend the Window interface with HelpCrunch property
// interface CustomWindow extends Window {
//   HelpCrunch?: (action: string) => void;
// }

// // Use CustomWindow instead of Window
// const helpCrunchTrigger = () => {
//   const customWindow = window as CustomWindow;

//   if (customWindow.HelpCrunch) {
//     customWindow.HelpCrunch('openChat');
//     console.log(customWindow, "fancy");
//     return;
//   }
// }

export const helpCrunchTrigger = () => {
  const customWindow = window;
  if (customWindow.HelpCrunch) {
    customWindow.HelpCrunch('openChat');
    return;
  }
}


export const helpCrunchHideWidget = () => {
  const customWindow = window;
  if (customWindow.HelpCrunch) {
    customWindow.HelpCrunch('hideChatWidget')
    return;
  }
}

export const helpCrunchShowWidget = () => {
  const customWindow = window;
  if (customWindow.HelpCrunch) {
    customWindow.HelpCrunch('showChatWidget')
    return;
  }
}

export const checkHelpCrunch = () => {
  const customWindow = window;
  if (customWindow.HelpCrunch) {
    return true;
  }
}

export function HelpCrunchWidget({ user, userAccountDetails }) {

  useEffect(() => {
    const customer = userAccountDetails;
    const allKeysHaveValues =
      userAccountDetails?.userName &&
      userAccountDetails?.email &&
      userAccountDetails?.userId &&
      userAccountDetails?.contactNumber;

    if (allKeysHaveValues) {
      initializeHelpCrunch(customer);
    } else {
      const interval = setInterval(() => {
        if (allKeysHaveValues) {
          initializeHelpCrunch(customer);
          clearInterval(interval);
        }
      }, 1000); // Check every second
    }
  }, [user, userAccountDetails?.userName]);

  const initializeHelpCrunch = (customer) => {
    window.helpcrunchSettings = {
      organization: window.env.HCS_ORG,
      appId: window.env.HCS_APP_ID,
      user: {
        name: customer.userName,
        email: customer.email,
        user_id: customer.userId,
        phone: customer.contactNumber,
        custom_data: {
          approch: 'User dashboard',
        },
      },
    };

    (function (w, d) {

      let hS = w.helpcrunchSettings;
      if (!hS || !hS.organization) {
        return;
      }
      let widgetSrc = 'https://' + hS.organization + '.widget.helpcrunch.com/';
      w.HelpCrunch = function () {
        w.HelpCrunch.q.push(arguments);
      };
      w.HelpCrunch.q = [];

      if (window.innerWidth <= 599) {
        w.HelpCrunch('hideChatWidget')
      }


      function r() {
        if (d.querySelector('script[src="' + widgetSrc + '"')) {
          return;
        }
        let s = d.createElement('script');
        s.async = 1;
        s.type = 'text/javascript';
        s.src = widgetSrc;
        (d.body || d.head).appendChild(s);
      }
      if (d.readyState === 'complete' || hS.loadImmediately) {
        r();
      } else if (w.attachEvent) {
        w.attachEvent('onload', r);
      } else {
        w.addEventListener('load', r, false);
      }
    })(window, document);

  };

  return <div></div>;
}