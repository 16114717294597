import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { navBarOptions, sideBarRoute } from 'Latest/DashboardScreen/FreshUserScreen/constant';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { onLogout } from 'redux/actions';
import Link from '@mui/material/Link';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  MemoryRouter,
  useLocation
} from 'react-router-dom';
import { customDrawerStyle, customDrawerStylelistItemHover } from './style/customDrawerStyle';
import { tokens } from 'theme';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import LogoutIcon from 'Latest/Images/svg/LogoutIcon';

const RouteList = ({ newUserCustomRoute, open }: any) => {
  const dispatch = useAppDispatch();
  const logoutHandle = () => dispatch(onLogout());
  const location = useLocation()
  const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);


  const themes = useTheme();
  const colors = tokens(themes.palette.mode);
  const smScreen = useMediaQuery(themes.breakpoints.up('md'));

  const [openListItem, setOpenListItem] = React.useState<any>({});

  const handleClick = (item: any) => {
    setOpenListItem((prevState: any) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    logoutHandle();
  }

  const removeRoute = dashboardDetail?.status == "New" ? ["/myprofile"] : [];
  const mergeAllRoute = navBarOptions?.concat(sideBarRoute())
  const removeRouteItems = (array: any, removeRoute: any) => {
    return array?.filter((item: any) => !removeRoute?.includes(item?.route));
  };

  const updatedFilteredRoutes = removeRouteItems(mergeAllRoute, removeRoute);
  const routes = newUserCustomRoute ? newUserCustomRoute : updatedFilteredRoutes

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {dashboardDetail.status === undefined || dashboardDetail.status === null ?

          <LoadingSkeleton open={open} />
          :
          <>
            <List sx={{ mx: "16px", mt: "24px", p: 0 }}>
              {routes.map((item: any, index: number) => {
                return (
                  <>
                    {(() => {
                      if (item?.isNavlink && !item?.target) {
                        return (
                          <>
                            <ListItem key={index} disablePadding component={RouterLink} to={item.route}
                              sx={{
                                ...customDrawerStyle.listItem,
                                ...(location.pathname === item.route ? {
                                  ...customDrawerStylelistItemHover
                                }
                                  : {
                                    '& .MuiListItemButton-root:hover': {
                                      bgcolor: 'white',
                                      color: "#344054",
                                      borderRadius: "10px"
                                    },
                                  }),
                              }}
                            >
                              <ListItemButton sx={{ ...customDrawerStyle.listItemBtn, justifyContent: open ? 'initial' : 'center' }} >
                                <ListItemIcon sx={{ ...customDrawerStyle.listItemIcon, mr: open ? "12px" : 'auto', }} >
                                  <item.icon
                                    fill={location.pathname === item.route ? "#FFFFFF" : "#667085"}
                                  />
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{ variant: 'body2', }}
                                  primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                              </ListItemButton>
                            </ListItem >
                          </>
                        )
                      }
                      else if (item.functionTrigger) {
                        return (
                          <>
                            <ListItem key={index} disablePadding onClick={item.onClick}
                              sx={{
                                ...customDrawerStyle.listItem,
                                ...(location.pathname === item.route ? {
                                  ...customDrawerStylelistItemHover
                                }
                                  : {
                                    '& .MuiListItemButton-root:hover': {
                                      bgcolor: 'white',
                                      color: "#344054",
                                      borderRadius: "10px"
                                    },
                                  }),
                              }}
                            >
                              <ListItemButton sx={{ ...customDrawerStyle.listItemBtn, justifyContent: open ? 'initial' : 'center' }}>
                                <ListItemIcon sx={{ ...customDrawerStyle.listItemIcon, mr: open ? "12px" : 'auto', }} >
                                  <item.icon
                                    fill={location.pathname === item.route ? "#FFFFFF" : "#667085"}
                                  />
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{ variant: 'body2', }}
                                  primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                              </ListItemButton>
                            </ListItem >
                          </>
                        )
                      }
                      // else if (item?.target) {
                      //   return (
                      //     <>
                      //       <ListItem key={index} disablePadding component={Link} href={item.route}
                      //         target="_blank"
                      //         rel="noreferrer"
                      //         sx={{
                      //           ...customDrawerStyle.listItem,
                      //           ...(location.pathname === item.route && {
                      //             ...customDrawerStylelistItemHover
                      //           })
                      //         }}
                      //       >
                      //         <ListItemButton sx={{ ...customDrawerStyle.listItemBtn, justifyContent: open ? 'initial' : 'center' }} >
                      //           <ListItemIcon sx={{ ...customDrawerStyle.listItemIcon, mr: open ? "12px" : 'auto', }} >
                      //             <item.icon fontSize='medium' />
                      //           </ListItemIcon>
                      //           <ListItemText primaryTypographyProps={{ variant: 'h6', }} primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                      //         </ListItemButton>
                      //       </ListItem>
                      //     </>
                      //   )
                      // }
                      // else {
                      //   return (
                      //     <>
                      //       <ListItemButton onClick={() => handleClick(item.title)}
                      //         sx={{
                      //           ...customDrawerStyle.listItem,
                      //           ...(location.pathname === item.route && {
                      //             ...customDrawerStylelistItemHover
                      //           }),
                      //           display: "flex",
                      //           ml: open ? "4px" : -0.5
                      //         }}
                      //       >
                      //         <ListItemIcon sx={{ ...customDrawerStyle.listItemIcon, mr: open ? "12px" : 'auto', }} >
                      //           <item.icon fontSize='medium' />
                      //         </ListItemIcon>
                      //         <ListItemText primaryTypographyProps={{ variant: 'h6', }} primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                      //         {openListItem[item.title] ? <ExpandLess /> : <ExpandMore />}
                      //       </ListItemButton>
                      //       <Collapse unmountOnExit in={openListItem[item.title]} timeout="auto" >
                      //         <List disablePadding component="div" >
                      //           {item?.navDropDown?.map((dropdownItem: any, dropdownIndex: number) => (
                      //             dropdownItem.href ?
                      //               <ListItemButton key={dropdownIndex} sx={{ pl: 4 }} component={Link} href={dropdownItem.href} target="_blank" rel="noreferrer">
                      //                 <ListItemIcon sx={{ ...customDrawerStyle.listItemIcon, mr: open ? "12px" : 'auto', }} >
                      //                   <dropdownItem.icon fontSize='medium' />
                      //                 </ListItemIcon>
                      //                 <ListItemText primaryTypographyProps={{ variant: 'h6', }} primary={dropdownItem.title} />
                      //               </ListItemButton>
                      //               :
                      //               <ListItemButton key={dropdownIndex} sx={{ pl: 4 }} component={RouterLink} to={dropdownItem.route}  >
                      //                 <ListItemIcon sx={{ ...customDrawerStyle.listItemIcon, mr: open ? "12px" : 'auto', }} >
                      //                   <dropdownItem.icon fontSize='medium' />
                      //                 </ListItemIcon>
                      //                 <ListItemText primaryTypographyProps={{ variant: 'h6', }} primary={dropdownItem.title} />
                      //               </ListItemButton>
                      //           ))}
                      //         </List>
                      //       </Collapse>
                      //     </>
                      //   )
                      // }

                    })()}
                  </>
                )
              }
              )}

            </List>
          </>

        }
      </div >
    </>
  )
}

export default RouteList

const LoadingSkeleton = ({ open }: any) => {

  return (
    <Stack spacing={1} sx={{ mx: open ? 2 : 1, mt: 2.5 }}>
      {[1, 2, 3, 4, 5, 6].map((item) => {
        return (
          <Skeleton key={item} variant="rounded" width={"100%"} height={40} />
        )
      })}
    </Stack>
  )
}