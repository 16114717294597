import { Goals } from './../../../constants';

export const LABELS = {
    [Goals.HouseBuy]: {
      INITIAL_AMOUNT: 'Initial amount',
      I_KNOW_THE_AMOUNT: 'I know the amount I want to buy a house',
      PLAN_MONTHLY: 'I would like to contribute each month.',
      AMOUNT_HEADING: 'Expected amount required​​',
      AMOUNT_DESCRIPTION: 'How much money would you like to save for a house?​​',
      SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
      SAVING_AMOUNT_DESCRIPTION:
        'How much money would you like to save every month?​​',
      AGE_HEADING: 'At what age you plan on buying a house​​',
      AGE_DESCRIPTION: 'Choose age ​​',
      YEAR_HEADING: 'In how many years do you plan on buying a house?​​',
      YEAR_DESCRIPTION: 'Number of years​​'
    },
    [Goals.CollegeFee]: {
      INITIAL_AMOUNT: 'Initial amount',
      I_KNOW_THE_AMOUNT: 'I know the amount I want to save for education',
      PLAN_MONTHLY: 'I would like to contribute each month.​​',
      AMOUNT_HEADING: 'Expected amount required​',
      AMOUNT_DESCRIPTION:
        '​How much money would you like to save for school/ college? ​​',
      SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
      SAVING_AMOUNT_DESCRIPTION:
        'How much money would you like to save every month?​​',
      AGE_HEADING: 'At what age you plan on to utilize the fees​​',
      AGE_DESCRIPTION: 'Choose age ​​',
      YEAR_HEADING: 'In how many years do you plan to utilize the fees?​​',
      YEAR_DESCRIPTION: 'Number of years​ ​​'
    },
    [Goals.LargeExpense]: {
      INITIAL_AMOUNT: 'Initial amount',
      I_KNOW_THE_AMOUNT: 'I know the amount I want for my planned expense',
      PLAN_MONTHLY: 'I would like to contribute each month.​​',
      AMOUNT_HEADING: 'Expected amount you want to receive​',
      AMOUNT_DESCRIPTION:
        'How much money would you like to save for this expense?  ​​',
      SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
      SAVING_AMOUNT_DESCRIPTION:
        'How much money would you like to save every month?​​',
      AGE_HEADING: 'At what age you plan to incur the expense?​​',
      AGE_DESCRIPTION: 'Choose age ​​',
      YEAR_HEADING: 'In how many years do you plan to incur this expense?​​​​',
      YEAR_DESCRIPTION: 'Number of years​ ​​'
    },
    [Goals.WindFallReceive]: {
      INITIAL_AMOUNT: 'Initial amount',
      I_KNOW_THE_AMOUNT: 'I know the amount I want for my planned goal​',
      PLAN_MONTHLY: 'I would like to contribute each month.​',
      AMOUNT_HEADING: 'Expected amount you want to accumulate​',
      AMOUNT_DESCRIPTION:
        'How much money would you like to save for this goal? ​ ​​',
      SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
      SAVING_AMOUNT_DESCRIPTION:
        'How much money would you like to save every month?​​',
      AGE_HEADING: 'At what age you plan to achieve the goal?​​',
      AGE_DESCRIPTION: 'Choose age ​​',
      YEAR_HEADING: 'In how many years do you plan to achieve this goal?​​​',
      YEAR_DESCRIPTION: 'Number of years​ ​​',
      GOAL_NAME: 'What would you like to name your goal?'
    },
    [Goals.TravelTime]: {
      INITIAL_AMOUNT: 'Initial amount',
      I_KNOW_THE_AMOUNT: 'I know the amount I want for travel',
      PLAN_MONTHLY: 'I would like to contribute each month.​​',
      AMOUNT_HEADING: 'Expected amount for travel plans​',
      AMOUNT_DESCRIPTION:
        'How much money would you like to save for travelling? ​​',
      SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
      SAVING_AMOUNT_DESCRIPTION:
        'How much money would you like to save every month?​​',
      AGE_HEADING: 'At what age you like to travel?​​',
      AGE_DESCRIPTION: 'Choose age ​​',
      YEAR_HEADING: 'When would you like to travel?​​',
      MONTH_HEADING: 'In how many months do you plan to travel?',
      YEAR_DESCRIPTION: 'Choose year​ ​​',
      Month_DESCRIPTION: 'Number of months ​​'
    },
    [Goals.VoluntaryPension]: {
      INITIAL_AMOUNT: 'Initial amount',
      I_KNOW_THE_AMOUNT: 'I know the amount I want at retirement',
      PLAN_MONTHLY: 'I would like to contribute each month.​​',
      AMOUNT_HEADING: 'Expected amount at retirement​​',
      AMOUNT_DESCRIPTION:
        'How much money would you like to have at your retirement?​​',
      SAVING_AMOUNT_HEADING: 'Expected monthly saving​',
      SAVING_AMOUNT_DESCRIPTION:
        'How much money would you like to save every month?​​',
      AGE_HEADING: 'Expected age at retirement​',
      AGE_DESCRIPTION: '​​',
      YEAR_HEADING: 'Expected age at retirement​​',
      YEAR_DESCRIPTION: '​ ​​'
    },
    [Goals.MahaanaCashAccount]: {
      INITIAL_AMOUNT: 'Initial amount',
      I_KNOW_THE_AMOUNT: 'I know the amount I want at retirement',
      PLAN_MONTHLY: 'I would like to contribute each month.​​',
      AMOUNT_HEADING: 'Expected amount at retirement​​',
      AMOUNT_DESCRIPTION:
        'How much money would you like to have at your retirement?​​',
      SAVING_AMOUNT_HEADING: 'Monthly Contribution​',
      SAVING_AMOUNT_DESCRIPTION:
        'How much money would you like to save every month?​​',
      AGE_HEADING: 'Expected age at retirement​',
      AGE_DESCRIPTION: '​​',
      YEAR_HEADING: 'Time Horizon​​',
      YEAR_DESCRIPTION: '​ ​​'
    }
  };

  