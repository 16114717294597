import { Button, Col, Row } from 'react-bootstrap';
import { memo, useCallback, useEffect, useState } from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const FileUploadField = ({
  acceptedType,
  buttonTitle,
  col = 6,
  fieldName,
  formik,
  imageError,
  inputFiles,
  maxFiles,
  required = false,
  setImageError,
  setImages
}: FileUploadFieldProps) => {

  const [files, setFiles] = useState<Files>([]);
  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   files.forEach(file => URL.revokeObjectURL(file.preview));
  // }, [files]);
  useEffect(() => {
    if (inputFiles) {
      setFiles(inputFiles);
    }
  }, []);

  const formikError = formik.errors as any;

  const onDrop = useCallback(
    (acceptedFiles: Files, fileRejections: FileRejection[]) => {
      const allFIles = [...acceptedFiles, ...files];
      if (allFIles.length > maxFiles) {
        toast.error('Please select only one file',{theme: "colored"});
        return;
      }
      fileRejections.forEach((file: FileRejection) => {
        file.errors.forEach((err: FileError) => {
          if (err.code === 'file-too-large') {
            return toast.error('File is too large',{theme: "colored"});
          }
        });
      });
      const newFiles = [...acceptedFiles];
      if (acceptedFiles.length > maxFiles || files.length === maxFiles) {
        return toast.error('You can only upload one file',{theme: "colored"});
      }
      if (acceptedFiles.length === 1) {
        acceptedFiles.push(...files);
      }
      acceptedFiles.forEach((el, index) => (el.id = index));
      formik?.setFieldValue(fieldName, acceptedFiles[0]);
      setImages(acceptedFiles);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const previewFiles = newFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setFiles([...previewFiles, ...files]);
      setImageError(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [files, setImageError, setImages]
  );
  const MAX_SIZE = 5000000;
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: acceptedType,
    maxFiles,
    maxSize: MAX_SIZE,
    onDrop
  });
  const handleDeleteImage = (id: number) => {
    setFiles(files.filter(el => el.id !== id));
    setImages(files.filter(el => el.id !== id));
    formik?.setFieldValue(fieldName, "");
  };
  const thumbs = files.map(file => (
    <div key={file.name} className='thumb' id={file.id}>
      <div className='thumb-inner'>
        <a href={file.preview} rel="noopener noreferrer" target="_blank">
          <img alt='file' className='thumb-image' src={file.preview} />
        </a>
      </div>
      <span
        className='fa fa-minus-circle fa-lg'
        onClick={e => handleDeleteImage(file.id)}
      />
    </div>
  ));

  // const thumbs_ = inputFiles?.map((file: any) => (
  //   <div key={file.name} className='thumb' id={file.id}>
  //     <div className='thumb-inner'>
  //       <a href={file.preview} rel="noopener noreferrer" target="_blank">
  //         <img alt='file' className='thumb-image' src={file.preview} />
  //       </a>
  //     </div>
  //     <span
  //       className='fa fa-minus-circle fa-lg'
  //       onClick={e => handleDeleteImage(file.id)}
  //     />
  //   </div>
  // ));

  return (
    <>
      {/* <ToastContainer /> */}
      <Col md={col} style={{ marginTop: '5px' }}>
        <div {...getRootProps()} className=''>
          <input {...getInputProps()} />
          {thumbs.length === 0
            ?
            <div className='img_cont' >
              <div style={{ display: 'contents' }}>
                <CloudUploadIcon sx={{ fontSize: 120 }} />
                <p>{buttonTitle}</p>
              </div>
            </div>
            :
            <div className='img_cont' >
              <aside className='thumb-container'>{thumbs}</aside>
            </div>
          }
        </div>

        {imageError && (
          <span className='ml-2 text-danger'>Images are required.</span>
        )}

        {formikError[fieldName] && (
          <Row>
            <span className='ml-4 mt-1 text-danger'>
              {formikError[fieldName]}
            </span>
          </Row>
        )}

      </Col>
    </>
  );
};

export default memo(FileUploadField);

interface FileUploadFieldProps {
  acceptedType: string;
  buttonTitle: string;
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  fieldName: string;
  inputFiles?: any;
  required?: boolean;
  setImages: (images: Files) => void;
  imageError: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxFiles: number;
  setImageError: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Files = Array<Record<string, any>>;
