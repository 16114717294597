import { Box, Typography } from '@mui/material';
import React from 'react'

const DepositAmtCard = ({ amt, title }: Props) => {
    return (
        <>
            <Box sx={{
                p: { xs: " 8px 16px", md: "16px" }, background: "#422E84", display: "flex", gap: "10px", flexDirection: "column", borderRadius: "6px",
                alignItems: { xs: "center", md: "flex-start" }
            }}>
                <Typography variant='body2' color="#FFFFFFE5"> {title}   </Typography>
                <Typography variant='h1' color={"#FFFFFFE5"}>   {amt}  </Typography>
            </Box>
        </>
    )
}

export default DepositAmtCard

interface Props {
    title?: string;
    amt?: string | number;
}