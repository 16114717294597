import { Link } from 'react-router-dom';
import { memo } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import Img from "Latest/Images/404Img.png"
import { screen404Style } from '../style/style';
import { linkSpan } from 'Latest/Style/Main';
import { bankDetailStyle } from 'Latest/MyProfile/Style/bankDetailStyle';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { links } from 'static/links';
import { useHistory } from 'react-router-dom';


const Error404 = ({ pageName, url }: Error404Props) => {
    const { push } = useHistory();
    return (
        <>
            <Container maxWidth={'xl'} sx={{ my: 5, }} >
                <Box sx={{ background: "#FFFFFF", borderRadius: "12px", border: "1px solid #EDEDED", p: { xs: 4, sm: 10, md: 10, lg: 10 } }}>

                    <Grid container sx={{ gap: { xs: 2, sm: 2, md: 0, lg: 0 } }} >
                        <Grid item md={6} xs={12} sx={screen404Style.contentGrid} >

                            <Typography sx={screen404Style.blueTitle}>404 error</Typography>
                            <Typography sx={screen404Style.head} >Page not found</Typography>
                            <Typography sx={screen404Style.para} >Sorry, the page you are looking for doesn't exist.</Typography>
                            <Typography sx={screen404Style.para} >Here are some helpful links:</Typography>

                            <Box sx={{ display: "flex", gap: 2, mt: 2, flexWrap: "wrap" }}>
                                <Button sx={{ ...screen404Style.addBackBtn }} startIcon={<ArrowBackIcon sx={{ color: '#344054' }} />} >
                                    Go back
                                </Button>
                                <Button sx={{ ...screen404Style.homeBtn, background: "rgba(64, 48, 129, 1)", color: "white" }} onClick={() => push(links.home)} >
                                    Take me home
                                </Button>
                            </Box>

                        </Grid>
                        <Grid item md={6} xs={12} sx={{
                            background: "#F3F4F6", display: "flex",
                            justifyContent: "center", 
                            flexDirection:"row",
                            pb: "4.5rem !important",
                            pt: "4.5rem !important" ,

                            pl: "0px !important"
                        }} >
                            <img src={Img} />
                        </Grid>

                    </Grid>
                </Box>
            </Container>

        </>
    );
};

export default memo(Error404);

interface Error404Props {
    pageName?: string;
    url?: string;
}