export const paramFinder = (params: string) => {
    const paths = new URLSearchParams(window.location.search);
    return paths.get(params)
}

export const paramRemover = () => {
    let url = window.location.href;

    // Remove all parameters
    url = url.split('?')[0];

    // Update the URL in the address bar
    window.history.replaceState({}, '', url);
}