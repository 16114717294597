import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCustomHead from "./tableHead"
import { Divider, Typography, Button, Fab, Menu, MenuItem } from '@mui/material';
import logo from "./logoCut.png"
import PaymentModal from './paymentModal';
import SuccessPaymentModal from './successPaymentModal';
import MenuBar from "./menu"
import { head } from './constant';
import { useFormik } from 'formik';
import { sellSchema } from './validation';

export default function BasicTable({ basketSummaries }: any) {

    const [openPaymentModal, setOpenPaymentModal] = React.useState(false);
    const [openSuccessPaymentModal, setOpenSuccessPaymentModal] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = React.useState<any>('');
    const [selectedOption, setSelectedOption] = React.useState<any>('');

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleBuy = () => {
        // setOpenPaymentModal(true);
        // setSelectedOption("Buy")
    };

    const handleSell = () => {
        setOpenPaymentModal(true)
        setSelectedOption("Sell")
    };

    const handleSwitch = () => {
        // alert("switch");
        // setSelectedOption("Switch")
    };

    const formikSell = useFormik({
        enableReinitialize: true,
        initialValues: { qty: '' },
        onSubmit: values => {
            setOpenPaymentModal(false)
            setOpenSuccessPaymentModal(true)
        },
        validationSchema: sellSchema
    })

    return (
        <TableContainer component={Paper}>

            <PaymentModal formik={formikSell} handleClose={() => setOpenPaymentModal(false)} open={openPaymentModal} selectedItem={selectedItem} selectedOption={selectedOption} />

            <SuccessPaymentModal formik={formikSell} handleClose={() => setOpenSuccessPaymentModal(false)} open={openSuccessPaymentModal} selectedItem={selectedItem} selectedOption={selectedOption} />

            <Typography sx={{ m: 1.5 }} variant='h6'>
                <img src={logo} style={{ width: 50 }} />
                General Investments
            </Typography>
            <Divider />
            <Typography sx={{ m: 1.5 }} variant='body1'>
                Investments
            </Typography>
            <Divider />

            <Table aria-label='simple table'>
                <TableCustomHead head={head} />
                <TableBody>
                    {basketSummaries?.map((row: any) => {
                        const { currentPrice, name, quantity, totalValue } = row;
                        return (
                            <TableRow
                                key={name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component='th' scope='row'>
                                    {name}
                                </TableCell>
                                <TableCell align='center'>{quantity}</TableCell>
                                <TableCell align='center'>{currentPrice}</TableCell>
                                <TableCell align='center'>{totalValue}</TableCell>

                                <TableCell align='center'>
                                    <MenuBar
                                        anchorEl={anchorEl}
                                        handleBuy={handleBuy}
                                        handleClick={handleClick}
                                        handleClose={handleClose}
                                        handleSell={handleSell}
                                        handleSwitch={handleSwitch}
                                        open={open}
                                        row={row}
                                        selectedItem={selectedItem}
                                        setAnchorEl={setAnchorEl}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
