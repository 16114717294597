import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Box, ClickAwayListener, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { tokens } from 'theme';
import { ApprovedCard, DepositPendingCard, VerificationPendingCard } from './Cards';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import infoIconSvg from "Latest/Images/svg/Info.svg"

const tootltipContent = "Return is extended to a one year period with the assumption that performance remains the same as before. This is also called an annualized value."

const SavePlus = ({ Data }: any) => {

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const { push } = useHistory();
    const [savePlusGoal, setSavePlusGoal] = useState<any>([]);

    const handleInvest = () => {
        push("/Cashfund", { RedirectLink: "dashboard" })
    }

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setIsTooltipOpen(true);
    };

    const filterSavePlus = Data?.goals?.filter((item: any, index: number) => item.category == "CashFund")
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if (Data?.goals?.length > 0) {
            const goal = Data?.goals?.filter((item: any, ind: number) => item?.category == "CashFund")
            setSavePlusGoal(goal)
        }
    }, [Data])

    const depositPendingData = { title: "Cash management account", ...savePlusGoal[0] }

    const verificationPendingData = {
        title: "Cash management account",
        ...savePlusGoal[0]
    }

    const customTooltip = () => {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={isTooltipOpen}
                    title={tootltipContent}
                >
                    <img src={infoIconSvg} alt='info' onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleTooltipOpen();
                    }} style={{ cursor: "pointer" }} />
                </Tooltip>
            </ClickAwayListener>
        )
    }

    const renderGrids = (num: number) => {
        if (!savePlusGoal[0]?.isApprovedPayment && savePlusGoal[0]?.potDetails?.initialInvestmentAmount == 0) {
            return <DepositPendingCard data={depositPendingData} margin={num} />;
        }
        else if (!savePlusGoal[0]?.isApprovedPayment && savePlusGoal[0]?.potDetails?.initialInvestmentAmount > 500 && savePlusGoal[0]?.lastTransactionStatus?.toLowerCase()?.includes("fail")) {
            return <DepositPendingCard data={depositPendingData} margin={num} />;
        }
        else if (!savePlusGoal[0]?.isApprovedPayment && savePlusGoal[0]?.potDetails?.initialInvestmentAmount >= 500 && !savePlusGoal[0]?.lastTransactionStatus?.toLowerCase()?.includes("fail")) {
            return <VerificationPendingCard data={verificationPendingData} margin={num} />
        }
        else if (savePlusGoal[0]?.isApprovedPayment && (savePlusGoal[0]?.potDetails?.initialInvestmentAmount >= 500 || savePlusGoal[0]?.potDetails?.portfolioAmount > 0)) {
            return <ApprovedCard data={savePlusGoal[0]} component={customTooltip()} margin={num} onClick={() => push("/viewDetail", { goalInfo: filterSavePlus[0] })} />
        }
        else {
            return null; // Return null or another default component if none of the conditions are met
        }
    };

    return (
        <>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant='h4' color={colors.purple[100]} lineHeight={"30px"}>Mahaana Save+</Typography>
                {!savePlusGoal.length && <LoadingBtnCustom btnTitle="Create account" style={{ py: 0, height: "37px", fontWeight: 400, minWidth: "fit-content" }} handleOnClick={handleInvest} />}
            </Box>


            {savePlusGoal.length > 0 ? <Grid container mt={2}>
                {renderGrids(2)}
            </Grid> : null}

        </>
    )
}

export default SavePlus