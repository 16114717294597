import { Box, Typography } from "@mui/material"
import { useAppLoading } from "hooks/appLoading";
import { useAppSelector } from "hooks/reduxHooks";
import LoadingBtnCustom from "Latest/ComponentV1/LoadingBtnCustom"
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { downloadWNineForm } from "redux/actions"

const Index = () => {

    const { userAccountDetails } = useAppSelector(state => state.auth);
    const loading = useAppLoading("downloadWNineForm");
    const dispatch = useDispatch();

    const paraSTyles = {
        width: { md: "80%", sm: "75%", xs: "100%" }
    }

    const handleDownloadForm = () => {
        const onSucces = (message: any, response: any) => {

            const blob = new Blob([message], { type: 'application/pdf' });

            // Create a URL for the Blob
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';

            link.click();

            // Revoke the object URL after some time to free up memory
            setTimeout(() => {
                URL.revokeObjectURL(downloadUrl);
            }, 100);

        }

        const onFail = (message: any, response: any) => {
            console.log(message, response.status, "OPOOP")
            toast.error('Please try again later' , {theme:"colored"});
        }

        dispatch(downloadWNineForm(userAccountDetails?.userId, onSucces, onFail))
    }



    return (
        <>
            <Box>
                <Typography variant='h4' color={"#101828"}>Download your W-9 Form</Typography>
                <Typography variant='body2' fontWeight={400} color={"var(--Text-Para, rgba(29, 41, 57, 0.70))"} marginTop={"10px"} sx={{ ...paraSTyles }}>A W-9 form is an Internal Revenue Service (IRS) document used by U.S. taxpayers to provide their taxpayer identification number for tax reporting purposes.</Typography>
            </Box>
            <LoadingBtnCustom handleOnClick={handleDownloadForm} loading={loading} btnTitle={"View Your W-9 Form"} style={{ marginTop: "30px", padding: "16px 32px;", minWidth: "90px", lineHeight: "24px", }} />
        </>
    )
}

export default Index