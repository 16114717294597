import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row, } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { LocalUrl } from 'redux/middlewares/api';


interface IProps {
    openModal: boolean;
    setOpenModal: any
    id: any
    DropDownMoadl: any
    flag: any
}

const EditModal = (props: IProps) => {
    const [questionID, setQuestionID] = useState('')
    const [selectedValue, setSelectedValue] = useState('')
    const [getArray, setGetArray] = useState<any>([])
    const [riskProfileArray, setRiskProfileArray] = useState<any>([])
    const [dropDown, setDropDown] = useState<any>([]);
    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = async () => {
        setDropDown(props.DropDownMoadl)
        setQuestionID(props.id)
        const response = await axios.get(LocalUrl + `QuestionAnswer/${(props.id).toString()}`)
        setGetArray(response.data)
        setRiskProfileArray(props.DropDownMoadl)
    }
    const EditHandler = async () => {
        const body = getArray;
        const resp = await axios.put(LocalUrl + 'QuestionAnswer', body);
        if (resp) {
            props.setOpenModal(false);
            props.flag(1)
        }

    }
    return (
        <Modal animation={false} onHide={() => props.openModal} show={props.openModal} >
            <Modal.Header >
                <Modal.Title>Edit Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <Form.Label>
                        Question:
                    </Form.Label>
                    <Form.Control
                        onChange={(e) => { const newArr: any = { ...getArray }; newArr.question = e.target.value; setGetArray(newArr) }}
                        value={getArray.question}
                    />
                </p>
                {
                    getArray.answer?.answerOptions.map((fields: any, id: any) => {
                        // alert(id)
                        return (
                            <Row key={id} style={{}}>
                                <Col md="6">
                                    {
                                        fields.label === null ? "" : <Form.Control
                                            onChange={(e) => { const newArr: any = { ...getArray }; newArr.answer.answerOptions[id].label = e.target.value; newArr.answer.answerOptions[id].value = e.target.value; setGetArray(newArr) }}
                                            value={fields.label}
                                        />
                                    }
                                    <br />
                                </Col>
                                <Col md="6">
                                    <Form.Control
                                        onChange={(e) => { const newArr: any = { ...getArray }; newArr.answer.answerOptions[id].score = e.target.value; newArr.riskProfileCategory = 1; setGetArray(newArr); }}
                                        value={fields.score}
                                    />
                                </Col>
                            </Row>
                        )
                    })
                }
                <br />
                <Select
                    onChange={(e: any) => { const newArr: any = { ...getArray }; setSelectedValue(e); newArr.riskProfileCategory = (e?.value); setGetArray(newArr) }}
                    options={props.DropDownMoadl.riskProfileCategory}
                    value={props.DropDownMoadl.riskProfileCategory[(getArray?.riskProfileCategory?.id - 1)]}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.setOpenModal(false)} variant="secondary">
                    Close
                </Button>
                <Button onClick={() => EditHandler()} variant="primary">
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default EditModal;