import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { useTheme } from '@mui/material/styles';
import { pxToRem, responsiveFontSizes } from "./utils/V1/getFontValues"
import componentsOverride from "./utils/V1/overrides"
import typography from "./utils/V1/typography"
import colorLight from "./utils/V1/Color/colorLight"
import colorDark from "./utils/V1/Color/colorDark"
import { TypographyProps } from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';



declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tableHeader?: true;
    alertTitle?: true;
    subtitle3?: true;
  }
}
// interface CustomTheme extends Theme {
//   typography: Theme['typography'] & {
//     tableHeader?: React.CSSProperties;
//   };
// }
// color design tokens export
export const tokens = (mode: any) => ({
  ...(mode === "dark" ? colorDark : colorLight),
});

// mui theme settings
export const ThemeSettings = (mode: any) => {
  const colors = tokens(mode);
  const theme = useTheme()
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            main: colors.primary[1],
          },
          secondary: {
            main: colors.primary[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: colors.primary[1],
            dark: colors.grey[700],
            main: colors.grey[500],
          },
        }
        : {
          // palette values for light mode
          primary: {
            main: colors.primary[1],
            contrastText: '#fff',
          },
          secondary: {
            main: colors.primary[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          warning: {
            main: "#F79009",
            contrastText: '#fff',
          },
          background: {
            default: "#ffffff",
          },
        }),
    },
    typography
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => { "light" },
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => {
          const newMode = prev === "light" ? "dark" : "light";
          return newMode;
        });
      },
    }),
    []
  );
  const theme = useMemo(() => createTheme(ThemeSettings(mode)), [mode]);

  // const theme:CustomTheme  = useMemo(() => createTheme(ThemeSettings(mode)), [mode]);
  // theme.components = componentsOverride(theme);

  return [theme, colorMode];
};
