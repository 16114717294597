import { Box } from "@mui/material"
import SubscribtionPage from "Latest/Subscription/SubscribtionLatest"
import Error404 from "jsx/pages/Error404"
import { Route, Switch } from "react-router-dom"
import { links } from "static/links"

const Subscribtions = () => {
    return (
        <Box>
            <Switch>
                <Route
                    exact
                    path={links.status}
                    render={() => <SubscribtionPage />}
                />
                <Route
                    path='*'
                    render={() => (
                        <Error404 pageName='subscribe' url={"/promotional-email/"} />
                    )}
                />
            </Switch>

        </Box>
    )
}
// const a = subscribe

// email:gh@yop.com

export default Subscribtions