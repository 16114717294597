import React from 'react'

const HelpIconV2 = ({ fill = "#667085" }: any) => {
    return (
        <div>
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.1934 3.86093C16.7792 2.43768 14.9743 1.46638 13.0074 1.07014C11.0405 0.673909 9.00027 0.870589 7.1453 1.63525C5.29033 2.39991 3.70416 3.69812 2.58787 5.36531C1.47158 7.0325 0.875432 8.99359 0.875 11V16.25C0.875 16.9462 1.15156 17.6139 1.64384 18.1061C2.13613 18.5984 2.80381 18.875 3.5 18.875H5C5.69619 18.875 6.36387 18.5984 6.85616 18.1061C7.34844 17.6139 7.625 16.9462 7.625 16.25V12.5C7.625 11.8038 7.34844 11.1361 6.85616 10.6438C6.36387 10.1516 5.69619 9.87499 5 9.87499H3.20375C3.47525 8.00081 4.41264 6.28709 5.84434 5.04753C7.27603 3.80797 9.10626 3.12548 11 3.12499H11.06C12.9453 3.13306 14.7647 3.81965 16.1853 5.05914C17.6059 6.29864 18.5327 8.00817 18.7962 9.87499H17C16.3038 9.87499 15.6361 10.1516 15.1438 10.6438C14.6516 11.1361 14.375 11.8038 14.375 12.5V16.25C14.375 16.9462 14.6516 17.6139 15.1438 18.1061C15.6361 18.5984 16.3038 18.875 17 18.875H18.8375C18.7511 19.2985 18.5209 19.6791 18.1861 19.9525C17.8512 20.2258 17.4322 20.3751 17 20.375H11.75C11.4516 20.375 11.1655 20.4935 10.9545 20.7045C10.7435 20.9155 10.625 21.2016 10.625 21.5C10.625 21.7984 10.7435 22.0845 10.9545 22.2955C11.1655 22.5065 11.4516 22.625 11.75 22.625H17C18.0936 22.6237 19.1421 22.1888 19.9154 21.4154C20.6888 20.6421 21.1238 19.5936 21.125 18.5V11C21.1302 9.67566 20.8739 8.36333 20.3708 7.13826C19.8677 5.91318 19.1278 4.79945 18.1934 3.86093ZM5 12.125C5.09946 12.125 5.19484 12.1645 5.26517 12.2348C5.33549 12.3052 5.375 12.4005 5.375 12.5V16.25C5.375 16.3494 5.33549 16.4448 5.26517 16.5152C5.19484 16.5855 5.09946 16.625 5 16.625H3.5C3.40054 16.625 3.30516 16.5855 3.23484 16.5152C3.16451 16.4448 3.125 16.3494 3.125 16.25V12.125H5ZM16.625 16.25V12.5C16.625 12.4005 16.6645 12.3052 16.7348 12.2348C16.8052 12.1645 16.9005 12.125 17 12.125H18.875V16.625H17C16.9005 16.625 16.8052 16.5855 16.7348 16.5152C16.6645 16.4448 16.625 16.3494 16.625 16.25Z" fill={fill} />
            </svg>
        </div>
    )
}

export default HelpIconV2