import { Modal } from 'react-bootstrap';
import './styling.css';

const ConfirmationModal = ({
  basketName,
  handleCancel,
  handleConfirm,
  show
}: IConfirmationProps) => {
  return (
    <Modal
      centered
      aria-labelledby='contained-modal-title-vcenter'
      onHide={handleCancel}
      show={show}
      size='sm'
    >
      <Modal.Body className='px-3'>
        <div className='text-center'>
          <h4 className='pb-3'>Do you want to buy {basketName} basket?</h4>
          <div>
            <span className='ConfirmationContentButton'>
              <button
                className='btn btn-primary mr-2 confirmation_yes_button'
                onClick={handleConfirm}
              >
                Yes
              </button>
            </span>
            <span className='ConfirmationContentButton'>
              <button
                className='btn btn-danger confirmation_no_button'
                onClick={handleCancel}
              >
                No
              </button>
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;

interface IConfirmationProps {
  show: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  basketName: string | undefined;
}
