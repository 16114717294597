import React, { useState } from 'react'
import ZakaatWarningAlerts from 'Latest/Notification/Alerts/zakaatWarningAlert'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { zakatStatus } from '../../../constants'
import { useAppLoading } from 'hooks/appLoading'
import SubmitInfo from 'Latest/Notification/Info/SubmitInfoNextOfKin'
import NoPaymentAlert from 'Latest/Notification/Alerts/noPaymentAlert'
import AccDetailSuccessAlert from 'Latest/Notification/Alerts/accDetailSuccessAlert'
import { onPatchNotifications } from 'redux/reducers/sharedStateData'
import { Box, Collapse, Typography } from '@mui/material'
import SubmitInfoZakaat from '../Info/SubmitInfoZakaat'
import SubmitInfoNextOfKin from 'Latest/Notification/Info/SubmitInfoNextOfKin'
import moment from 'moment'
import { submitInfoStyle } from '../style'
import IconInfo from "Latest/Images/svg/IconInfo.svg";

const Zakat_NextOfKIn_Notification = () => {

    const [isCloseLocal, setIsCloseLocal] = useState(true)
    const dispatch = useAppDispatch();
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const { zakaatDeclNotiStatus } = useAppSelector(state => state.notificationStatus);
    const { transactionDetail } = useAppSelector(state => state.sharedData);

    const dateToCheck = moment();
    const isZakaatRange = dateToCheck.isBetween('2024-01-01', '2024-03-01');

    const checkZakaatDocDefault = userAccountDetails?.zakatAffidavit?.documentStatus === "Default" || userAccountDetails?.zakatAffidavit?.documentStatus === "Rejected"

    const checkZakaatDocReject = zakatStatus[userAccountDetails?.zakatAffidavit?.documentStatus]?.status == "failed"
    const checkZakaatDocApproval = userAccountDetails?.zakatAffidavit?.documentStatus == "Approved"

    const checkNextOfKinbeneficiaries = userAccountDetails?.beneficiaries?.length > 0
    const checkApprovedPaymentStatus = transactionDetail?.length > 0 && transactionDetail?.some((i: any) => i?.status == 'ApprovalSucceeded')

    const { notficationHub } = useAppSelector(state => state.sharedData);

    const handleSubmitInfoNextOfKin = () => {
        // if (!checkApprovedPaymentStatus && transactionDetail?.length > 0) {
        if (transactionDetail?.length > 0) {
            // if (!checkNextOfKinbeneficiaries || checkZakaatDocDefault) {
            if (!checkNextOfKinbeneficiaries) {
                return true
            }
            return false
        } else {
            return false
        }
    }

    const handleSubmitInfoZakat = () => {
        // if (!checkApprovedPaymentStatus && transactionDetail?.length > 0) {
        if (transactionDetail?.length > 0) {
            // if (!checkNextOfKinbeneficiaries || checkZakaatDocDefault) {
            if (checkZakaatDocDefault) {
                return true
            }
            return false
        } else {
            return false
        }
    }

    const handleAccDetailSuccessAlert = () => {
        if (!checkApprovedPaymentStatus && transactionDetail?.length > 0) {
            if (checkNextOfKinbeneficiaries) {
                return true
            }
        } else {
            return false
        }
    }

    const handleClose = () => {
        setIsCloseLocal(false)
        const formData: any = new FormData();
        formData.append('EnableNotifications', false);
        formData.append('NotificationType', "zakatAffidavit");
        dispatch(onPatchNotifications(formData))
    }

    return (
        <>

            {transactionDetail?.length == 0 &&
                <NoPaymentAlert />
            }

            {handleSubmitInfoNextOfKin() &&
                <SubmitInfoNextOfKin checkNextOfKinbeneficiaries={checkNextOfKinbeneficiaries} checkZakaatDocDefault={!checkZakaatDocDefault} />
            }

            {isZakaatRange && handleSubmitInfoZakat() &&
                <SubmitInfoZakaat checkNextOfKinbeneficiaries={checkNextOfKinbeneficiaries} checkZakaatDocDefault={!checkZakaatDocDefault} />
            }

            {/* <Collapse in={isCloseLocal} timeout={{ enter: 500, exit: 500 }} >
                {notficationHub?.ZakatAffidavit == "True" && checkZakaatDocReject && <ZakaatWarningAlerts content={userAccountDetails?.zakatAffidavit?.documentComment} />}
            </Collapse> */}

            {handleAccDetailSuccessAlert() &&
                <AccDetailSuccessAlert handleClose={handleClose} />
            }

        </>
    )
}

export default Zakat_NextOfKIn_Notification


