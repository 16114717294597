import { Box, Button, Grid, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AmtCard from './component/AmtCardV2'
import PerformanceCard from './component/PerformanceCardV2'
import PerformanceCardGI from './component/PerformanceCardV2ForGI'
import PortfolioCard from './component/PortfolioCardV2'
import TransactionTableCard from './component/TransactionTableCardV2'
import GoalSlider from './component/GoalSliderV2'
import { useHistory } from 'react-router-dom'
import { tokens } from 'theme'
import { customCard } from './style'
import { useAppSelector } from 'hooks/reduxHooks'
import MenuUpdateGoals from "./updateDltComp";
import { quickLinksArray } from 'Latest/DashboardScreen/FreshUserScreen/constant';
import { CardinfoStyle } from '../DashboardScreenV1/style/cardinfoStyle'
import { mixPannelTrackerEventName } from '../../constants/index'
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker'
import { Disclaimer } from './Disclaimer'

const Index = () => {

    const { goBack, location, push } = useHistory<any>();
    const goalInfo: any = location?.state?.goalInfo || undefined;
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
    const { user: { email } } = useAppSelector((state: any) => state.auth);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [selectedGoal, setSelectedGoal] = useState<any>(goalInfo);

    const mainHeading = selectedGoal?.category === "GeneralInvestment" ? `${selectedGoal.name}` : "Mahaana Save+"

    const isCashfund = selectedGoal?.category == "CashFund";

    useEffect(() => {
        dashboardDetail?.goals?.filter((item: any) => {
            if (item?.idGoal == goalInfo?.idGoal) {
                setSelectedGoal(item)
            }
        })
    }, [dashboardDetail])

    if (!goalInfo || Object.keys(goalInfo).length === 0) {
        push("/")
        if (email) {
            MixPannelTracker({ eventName: mixPannelTrackerEventName.View_Detail, email });
        }
        return;
    }

    return (
        <>
            <Grid container rowGap={"24px"}>
                <Grid item xs={12} p={3} sx={customCard.mainBoxV2}>

                    <Grid container rowGap={"20px"}>

                        <Grid item flex={1}>
                            <Grid container justifyContent={"space-between"}>
                                <Box flex={1}>
                                    <Typography variant='h1' color={colors.grey[800]} sx={{ wordBreak: "break-all" }}>{mainHeading}</Typography>
                                    {(selectedGoal?.yieldCategory && !isCashfund) && <Typography variant='subtitle2' color={"#344054"} fontWeight={400} lineHeight={"21px"} display={"flex"} alignItems={"center"} mt={"4px"}><Box sx={{ height: 8, width: 8, borderRadius: 100, backgroundColor: selectedGoal?.yieldColorCode, ml: "2px", mr: "8px" }} />{selectedGoal?.yieldCategory}</Typography>}
                                </Box>
                                {(mdScreen && selectedGoal?.category?.toLowerCase() != "cashfund") && <MenuUpdateGoals goalInfo={goalInfo} onDltSuccess={goBack} />}
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={9} md={8} sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
                            <Grid container justifyContent={{ xs: "flex-start", sm: "flex-end" }} alignItems={"center"} gap={{ xs: "10px", sm: 0 }}>
                                {quickLinksArray.map((item: any, index: number) => (
                                    <Box key={index}
                                        sx={{
                                            marginRight: index == 0 && !smScreen ? "10px" : 0,
                                            '& .dep-with-btn': { padding: "8px 16px", minHeight: 40 },
                                            '& .childBox .MuiBox-root': { height: "24px", width: "24px" },
                                            '& .childBox h6': { fontSize: "14px" },
                                            '& .childBox .MuiBox-root svg': { height: "14px", width: "14px" }
                                        }}>
                                        <item.modalComp Data={selectedGoal} item={item} />
                                    </Box>
                                ))}

                                {(!mdScreen && selectedGoal?.category?.toLowerCase() != "cashfund") && <MenuUpdateGoals goalInfo={goalInfo} onDltSuccess={goBack} />}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} py={3}>
                        <AmtCard goalInfo={selectedGoal} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {isCashfund ?
                            <PerformanceCard goalInfo={selectedGoal} />
                            :
                            <PerformanceCardGI goalInfo={selectedGoal} />
                        }
                    </Grid>

                </Grid>
                <Grid container rowGap={"24px"}>
                    {mdScreen && <Grid item xs={12} sx={{ ...CardinfoStyle.itemGridRight }}>
                        <Box >
                            <Typography variant='h4' color={colors.purple[100]} mb={2} lineHeight={"36px"}>Quick links</Typography>

                            {quickLinksArray.map((item: any, index: number) => (
                                <>
                                    <Box key={index} mb={index == 0 ? 1 : 0}>
                                        <item.modalComp Data={goalInfo} item={item} />
                                    </Box>
                                    {/* {index == 0 && <Divider />} */}
                                </>
                            ))}

                        </Box >
                    </Grid>}
                </Grid>

                <Grid item xs={12} sx={{ background: "#fff" }}>
                    <PortfolioCard goalInfo={selectedGoal} />
                </Grid>

                <Grid item xs={12} sx={{ background: "#fff" }}>
                    <TransactionTableCard goalInfo={selectedGoal} />
                </Grid>

                <Grid item xs={12} p={3} mt={3} sx={{ background: "#fff" }}>
                   <Disclaimer />
                </Grid>
            </Grid>
        </>
    )
}

export default Index