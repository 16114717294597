import { Chip } from '@mui/material'
import React from 'react'


const CustomChip = ({ label, pendingWidth, status = "success", style, verifiedWidth }: any) => {
    return (
        <>

            {status == "success" ?
                <Chip label={label} variant={"filled"} color="error" size={"small"} sx={{ fontSize: { md: "14px", xs: "12px" }, color: "#05603A", background: "#D1FADF", ...style, ...verifiedWidth }} />
                :
                status == "progress" ?
                    <Chip label={label} variant={"filled"} color="error" size={"small"} sx={{ fontSize: { md: "14px", xs: "12px" }, color: "#7A4510", background: "#FCDEC0", ...pendingWidth, ...style }} />
                    :
                    status == "pending" ?
                        <Chip label={label} variant={"filled"} color="error" size={"small"} sx={{ fontSize: { md: "14px", xs: "12px" }, color: "#7A4510", background: "#FCDEC0", ...style, ...pendingWidth }} />
                        :
                        status == "neutral" ?
                            <Chip label={label} variant={"filled"} color="error" size={"small"} sx={{ fontSize: { md: "14px", xs: "12px" }, color: "rgba(0, 0, 0, 0.87)", background: "transparent", ...style, ...pendingWidth }} />
                            :
                            status == "failed" ?

                                <Chip label={label} variant={"outlined"} color="error" size={"small"} sx={{ border: "1px solid #D92D20", fontSize: { md: "14px", xs: "12px" }, color: "#D92D20", background: "#FFF3F2", ...style, ...verifiedWidth }} />
                                :
                                ""
            }
        </>
    )
}

export default CustomChip