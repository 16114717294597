import { Box, Grid, Typography } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Col, Form } from 'react-bootstrap';
import { specialCheckBoxChildGrid, specialCheckBoxLabel } from '../style/specialCheckBox';

const FormikCheckboxField = ({
  col = 6,
  fieldName,
  fields,
  formik,
  label,
  onChange = () => null,
  required = false,
  singleLine = false,

}: FormikCheckboxFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  return (

    <Grid container spacing={1}>

      <Grid item xs={12}>
        <Typography variant='body2'>
          {label}
          {required && <span className='ml-1 text-danger'>*</span>}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap", gap: "16px", pt: "24px !important", justifyContent: { md: "flex-start", xs: "center" } }}>
        {fields.map(val => {
          return (
            <Grid key={val.label} item onClick={() => formik.getFieldProps(fieldName).onChange}>
              <Box onClick={() => formik.getFieldProps(fieldName).onChange}
                sx={{
                  '& .specialCheckboxV2 label': {
                    border: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "1px solid #422E84" : "1px solid rgba(208, 213, 221, 0.25)",
                  },
                  '& .form-check-label': {
                    color: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "#422E84" : "#1D2939",
                  },
                  ...specialCheckBoxChildGrid,
                }}
              >
                <Form.Check
                  key={val.label}
                  className="specialCheckbox specialCheckboxV2"
                  checked={formik?.values[fieldName] && formik.values[fieldName].includes(val?.value)}
                  disabled={!!val.disabled}
                  id={val.value.toString()}
                  label={val.label || val.value}
                  name={fieldName}
                  onChange={formik.getFieldProps(fieldName).onChange}
                  type='checkbox'
                  value={val.value}
                />
              </Box>
            </Grid>
          )
        })}

      </Grid>
      <span className='ml-2 text-danger'>
        {formikTouch[fieldName] && formikError[fieldName]}
      </span>
    </Grid>

  );
};

export default memo(FormikCheckboxField);

interface FormikCheckboxFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  fields: Array<{ label: string; value: string; disabled?: boolean }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  label?: string;
  required?: boolean;
  singleLine?: boolean;
  onChange?: any
}
