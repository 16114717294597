import { Col, Row } from 'react-bootstrap';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { memo } from 'react';
import { NumericFormat } from 'react-number-format';
import { max } from 'vendor/bootstrap-datetimepicker/js/moment';
import { removeCommaFormatting } from 'utils/commaSeparateMasking';

const AuthInputField = ({
  autoFocus = false,
  bottomInfo,
  col = 12,
  disabled = false,
  formik,
  isMutual = false,
  isStep,
  label,
  max,
  maxLength,
  minLength,
  name,
  nestedFieldName,
  placeholder,
  required = false,
  thousandSeparator = false,
  topInfo,
  type = 'text'
}: AuthInputFieldProps) => {
  const formikTouch = formik.touched as any;
  const formikError = formik.errors as any;
  let fields: any = formik.getFieldProps(name);
  fields = {
    ...fields,
    value: fields.value || ''
  };

  let FieldError = '';
  let FieldTouch = false;
  let NestedFieldError = '';
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[name?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[name?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[name];
    FieldTouch = formikTouch[name];
  }

  return (
    <Col md={col} style={{ fontFamily: 'PlayFair' }}>
      {label && (
        <Row>
          <label className='mb-2 field-label1'>
            <strong>
              {label}
              {required && <span className='ml-1 text-danger'>*</span>}
            </strong>
          </label>
        </Row>
      )}
      {topInfo && (
        <Row>
          <div className='small-info  text-muted'>{topInfo}</div>
        </Row>
      )}
      <Row>
        {type == 'number' ? (
          <NumericFormat
            allowNegative={false}
            autoComplete='off'
            className='text-box-custom'
            decimalScale={2}
            max={max}
            maxLength={maxLength}
            name={name}
            onBlur={() => {
              formik.setFieldTouched(name, true);
            }}
            onChange={(e: any) => {
              const value: any = removeCommaFormatting(e.currentTarget.value);
              formik.setFieldValue(name, value);
            }}
            placeholder={placeholder}
            thousandSeparator={thousandSeparator}
            thousandsGroupStyle='thousand'
            value={formik.values[name]}
          />
        ) : (
          <input
            autoComplete='off'
            autoFocus={autoFocus}
            className='text-box-custom'
            disabled={disabled}
            max={max}
            maxLength={maxLength}
            min={type === 'number' && '0'}
            minLength={minLength}
            name={name}
            placeholder={placeholder}
            step={isStep ? 1000 : 1}
            type={type}
            {...fields}
          />
        )}
      </Row>

      {bottomInfo && (
        <Row>
          <div className='small-info mb-2 mt-1 text-muted'>{bottomInfo}</div>
        </Row>
      )}

      {FieldTouch && FieldError && (
        <Row>
          <span className='ml-2 mt-1 text-danger'>{FieldError}</span>
        </Row>
      )}

      {NestedFieldTouch && NestedFieldError && (
        <Row>
          <span className='ml-2 mt-1 text-danger'>{NestedFieldError}</span>
        </Row>
      )}
    </Col>
  );
};

export default memo(AuthInputField);

interface AuthInputFieldProps {
  autoFocus?: boolean;
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  formik: FormikFieldProps;
  label?: string | JSX.Element;
  max?: number;
  maxLength?: number;
  minLength?: number;
  name: any;
  nestedFieldName?: any;
  placeholder: string;
  required?: boolean;
  topInfo?: string;
  type?: string;
  bottomInfo?: string | JSX.Element;
  isMutual?: boolean;
  isStep?: boolean;
  thousandSeparator?: boolean;
  min?: number;
}

export interface FormikFieldProps {
  getFieldProps: (field: string) => void;
  touched: FormikTouched<unknown>;
  errors: FormikErrors<unknown>;
  values: FormikValues;
  setFieldValue: any;
  setFieldError: any;
  setFieldTouched: any;
}
