import { Col, Row } from 'react-bootstrap';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { memo } from 'react';
import { NumericFormat } from 'react-number-format';
import { removeCommaFormatting } from 'utils/commaSeparateMasking';
import { Box, Typography } from '@mui/material';

const AuthInputField = ({
  FieldTouchInput = false,
  InputRight,
  accept,
  autoFocus = false,
  bottomInfo,
  className,
  col = 12,
  customOnChange,
  disabled = false,
  formik,
  gotoNextStep,
  inputEnd,
  inputEndStyles,
  isMutual = false,
  isStep,
  label,
  max,
  maxLength,
  min,
  minLength,
  multiline = false,
  name,
  nestedFieldName,
  placeholder,
  readOnly = false,
  removeWhitespace = false,
  required = false,
  rows,
  style,
  styleLabel,
  styleNumericInput,
  thousandSeparator = false,
  topInfo,
  type = 'text',
  upperCaseEnable = false
}: AuthInputFieldProps) => {
  const formikTouch = formik.touched as any;
  const formikError = formik.errors as any;
  let fields: any = formik.getFieldProps(name);

  let modifiedValue: any = fields?.value || '';

  if (upperCaseEnable) {
    modifiedValue = modifiedValue.toUpperCase();
  }

  if (removeWhitespace) {
    modifiedValue = modifiedValue.replace(/\s/g, '');
  }

  fields = {
    ...fields,
    value: modifiedValue || ''
  };

  let FieldError = '';
  let FieldTouch = false;
  let NestedFieldError = '';
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[name?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[name?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[name];
    FieldTouch = formikTouch[name];
  }

  return (
    <Col md={col}>


      {label && (<Row>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "6px" }}>
          <Typography variant='body2' color="#1D2939">
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </Typography>
        </Box>

      </Row>)}

      <Row className='position-relative'>
        {type == 'number' ? (
          <NumericFormat
            autoFocus={autoFocus}
            allowNegative={false}
            autoComplete='off'
            className={'text-box-custom' + " " + className}
            decimalScale={2}
            min={min}
            minLength={minLength}
            max={max}
            maxLength={maxLength}
            name={name}
            onBlur={() => {
              formik.setFieldTouched(name, true);
            }}
            onChange={(e: any) => {
              const value: any = removeCommaFormatting(e.currentTarget.value);
              formik.setFieldValue(name, value == undefined ? null : value);
              (customOnChange && customOnChange(e.target.value))
            }}
            placeholder={placeholder}
            style={{ width: "100%", border: FieldTouch && FieldError ? "1px solid red" : "", ...styleNumericInput }}
            thousandSeparator={thousandSeparator}
            thousandsGroupStyle='thousand'
            value={formik.values[name]}
            disabled={disabled}
            readOnly={readOnly}
            onKeyDownCapture={(e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                gotoNextStep && gotoNextStep()
              }
            }}
          />
        ) : type == 'file' ?
          <input
            accept={accept}
            name={name}
            onChange={e => {
              if (e.currentTarget.files) {
                formik.setFieldValue(name, e.currentTarget.files[0]);
              }
            }}
            type='file'
          />
          : type == 'textArea' ?
            (<textarea
              autoComplete='off'
              autoFocus={autoFocus}
              className='textArea-box-custom'
              disabled={disabled}
              readOnly={readOnly}
              max={max}
              maxLength={maxLength}
              minLength={minLength}
              name={name}
              placeholder={placeholder}
              style={{ width: "100%", border: FieldTouch && FieldError ? "1px solid red" : "", ...style }}
              type={type}
              rows={rows}
              {...fields}
            />)
            :
            (
              <input
                autoComplete='off'
                autoFocus={autoFocus}
                className='text-box-custom'
                disabled={disabled}
                readOnly={readOnly}
                max={max}
                maxLength={maxLength}
                min={type === 'number' && '0'}
                minLength={minLength}
                name={name}
                placeholder={placeholder}
                step={isStep ? 1000 : 1}
                style={{ width: "100%", border: FieldTouch && FieldError ? "1px solid red" : "", ...style }}
                type={type}
                {...fields}
                // multiline={multiline}
                rows={rows}
              />
            )}


        {InputRight && <InputRight />}
        {inputEnd && <div style={{ position: "absolute", right: "3%", top: "0.8rem", ...inputEndStyles }}>{inputEnd}</div>}

      </Row>

      {bottomInfo && (
        <Row>
          <div className='small-info mb-2 mt-1 text-muted'>{bottomInfo}</div>
        </Row>
      )}

      {(FieldTouch || FieldTouchInput) && FieldError && (
        <Row>
          <span className='ml-2 mt-1 text-danger'>{FieldError}</span>
        </Row>
      )}

      {NestedFieldTouch && NestedFieldError && (
        <Row>
          <span className='ml-2 mt-1 text-danger'>{NestedFieldError}</span>
        </Row>
      )}
    </Col>
  );
};

export default memo(AuthInputField);

interface AuthInputFieldProps {
  autoFocus?: boolean;
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  formik: FormikFieldProps;
  label?: string | JSX.Element;
  max?: number;
  maxLength?: number;
  minLength?: number;
  name: any;
  nestedFieldName?: any;
  placeholder?: string;
  required?: boolean;
  topInfo?: string;
  type?: string;
  bottomInfo?: string | JSX.Element;
  isMutual?: boolean;
  isStep?: boolean;
  thousandSeparator?: boolean;
  min?: number;
  inputEnd?: any;
  InputRight?: any;
  className?: string;
  customOnChange?: any;
  readOnly?: boolean,
  style?: any,
  styleNumericInput?: any,
  styleLabel?: any,
  accept?: any,
  multiline?: boolean,
  rows?: any;
  FieldTouchInput?: boolean;
  removeWhitespace?: boolean,
  upperCaseEnable?: boolean,
  gotoNextStep?: any,
  inputEndStyles?: any
}

export interface FormikFieldProps {
  getFieldProps: (field: string) => void;
  touched: FormikTouched<unknown>;
  errors: FormikErrors<unknown>;
  values: FormikValues;
  setFieldValue: any;
  setFieldError: any;
  setFieldTouched: any;
}
