import { AxiosResponse } from 'axios';
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import Error404 from 'jsx/pages/Error404';
import Forgot from 'jsx/pages/ForgotPassword';
import LoginPage from 'jsx/pages/Login';
import Signup from 'jsx/pages/Signup';
import VerifyEmail from 'jsx/pages/VerifyEmail';
import ResendEmailVerification from 'jsx/pages/ResendEmailVerification';
import ResetPassword from 'jsx/pages/ResetPassword';
import ValidateOTP from 'jsx/pages/ValidateOTP';
import { memo, useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
  resendSmsCode,
  ForgotParams,
  LoginParams,
  onClearSignupProcess,
  onForgotPassword,
  onGetAccountSetup,
  onLogin,
  onRegister,
  verifySmsCode,
  onVerifyEmail,
  resendEmailVerifcation,
  RegisterParams,
  verifySmsCodeParams,
  setClearEmailPassword,
  onEmailVerifiactionFail,
  setUserId,
  UserDetails
} from 'redux/actions';
import {
  checkEmailInInvitees,
  getUserAccountInfo,
  getWaitlistUsers,
  name,
  onResendEmailCode,
  onResendPhoneCodeHanele,
  onResetPassword,
  onSmsCodeVerifiactionSuccess,
  onSmsCodResendSuccess,
  resendSmsCodeParams,
  ResetPassParams,
  setUserDetails,
  setUserTempToken,
  userVerificationStatus,
  userVerificationStatusParams,
  verifyEmailParams,
  verifyResetPassToken
} from 'redux/reducers/auth';
import { links } from 'static/links';
import { ScopedDashboardRoutes } from '../routes/AppRoutes';
import { AuthMsgs, mixPannelTrackerEventName } from '../../constants';
import { WaitListResponseModal, WaitListModal, ContentWithLogoModal } from 'jsx/components/Modal';
import Loader from 'jsx/components/Shared/Loader';
import { ApiMessage } from 'constants/Error/errorMessage';
import { OtpTracker } from 'Latest/MixPanel/utils';
import { ResponseMessage } from 'Latest/DashboardScreen/GoalDashboard/LeftSection/ModalComponent/responseMessage';
import { Box } from '@mui/material';
import TopBarLogo from 'Latest/ComponentV1/topBarLogo';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import { paramFinder } from 'utils/paramFInder';

const EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED';
const PHONE_NUMBER_NOT_VERIFIED = 'PHONE_NUMBER_NOT_VERIFIED';
const RESEND_PHONE_NUMBER_VERIFICATION = 'RESEND_PHONE_NUMBER_VERIFICATION'
const RESEND_EMAIL_VERIFICATION = "RESEND_EMAIL_VERIFICATION"
const EmailAlreadyRegistered = 'Email address is already registered';

const Auth = () => {
  const [phoneNo, setPhoneNo] = useState<string>('null');
  const [modalVisible, setModalVisible] = useState(false)
  const [waitlistModalVisible, setWaitlistModalVisible] = useState(false)
  const [successModalVisible, setSuccessModalVisible] = useState(false)
  const [userInfo, setUserInfo] = useState<any>()
  const dispatch = useAppDispatch();
  const appLoading = useAppLoading('auth');
  const { push, replace } = useHistory();
  const {
    clearEmailPassword, inviteesUsers, isAuth, isEmailVerified, isRiskProfileCompleted, msg,
    otpUserId, scopes, user, userAccountInfo, userId, userTempToken, waitlistUsers
  }: any = useAppSelector((state) => state.auth);

  const [userEmail, setUserEmail] = useState("")
  const verifyMobileNumber = (mobNum: any) => {
    if (mobNum.startsWith('+92') || mobNum.startsWith('92')) {
      return true
    } else {
      return false
    }

  }

  const onLoginFail = (message: string, response: AxiosResponse) => {
    if (response?.data?.errorMessages?.includes(RESEND_EMAIL_VERIFICATION)) {
      dispatch(setUserId(response?.data?.userId));
      dispatch(setUserTempToken(response?.data?.token));
      dispatch(resendEmailVerifcation(response?.data?.userId,onFailResendCode,true))
      push(links.otpVerify, response?.data?.phoneNumber);
    }
    else if (response?.data?.errorMessages?.includes(RESEND_PHONE_NUMBER_VERIFICATION)) {
      dispatch(setUserId(response?.data?.userId));
      dispatch(setUserTempToken(response?.data?.token));
      const isLocalNumber = verifyMobileNumber(response?.data?.phoneNumber)
      if(isLocalNumber){
        dispatch(resendSmsCode('sms',response?.data?.userId,()=>{console.log('send sms successfully')},()=>{console.log('sms failed to sent')}))
      }
      else{
        dispatch(resendSmsCode('WhatsappMesage',response?.data?.userId,()=>{console.log('send sms successfully')},()=>{console.log('sms failed to sent')}))
      }
    }
    
    if (response?.data?.errorMessages?.includes(PHONE_NUMBER_NOT_VERIFIED)) {
      dispatch(setUserId(response?.data?.userId));
      dispatch(setUserTempToken(response?.data?.token));
      push(links.otpVerify, response?.data?.phoneNumber);
      
      return;
    } else if (response?.data?.errorMessages?.includes(EMAIL_NOT_VERIFIED)) {
      dispatch(setUserId(response?.data?.userId));
      dispatch(setUserTempToken(response?.data?.token));
      push(links.resendEmailVerification);
      return;
    }
    
    else if (response?.status === 401) {
      toast.error('Invalid email or password', {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        onOpen: () => dispatch(onClearSignupProcess()),
        pauseOnHover: true,
        position: 'bottom-right',
        progress: undefined,
        toastId: 'error1',
        theme: "colored",
      });
    }
  };

  const onRegisterionFail = (
    message: string,
    response: AxiosResponse | any
  ) => {
    switch (response?.status) {
      case 409:
        toast.error(ApiMessage[response?.data], {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          onOpen: () => dispatch(onClearSignupProcess()),
          pauseOnHover: true,
          position: 'bottom-right',
          progress: undefined,
          toastId: 'error1',
          theme: "colored",
        });
        break;
      case 500:
        toast.error('Internal server error', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          onOpen: () => dispatch(onClearSignupProcess()),
          pauseOnHover: true,
          position: 'bottom-right',
          progress: undefined,
          toastId: 'error1',
          theme: "colored",
        });
        break;
      case 400:
        toast.error(
          response?.data[0]?.errorMessage == 'MOBILE_NUMBER_INVALID'
            ? 'Invalid mobile number'
            : 'Please recheck your information',
          {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            onOpen: () => dispatch(onClearSignupProcess()),
            pauseOnHover: true,
            position: 'bottom-right',
            progress: undefined,
            toastId: 'error1',
            theme: "colored",
          }
        );
        break;
      default:
        toast.error(
          response?.data[0]?.errorMessage ||
          response?.data ||
          response ||
          'Something went wrong',
          {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            onOpen: () => dispatch(onClearSignupProcess()),
            pauseOnHover: true,
            position: 'bottom-right',
            progress: undefined,
            toastId: 'error1',
            theme: "colored",
          }
        );
        break;
    }
  };

  const onEmailVerificationFail = (
    message: string,
    response: AxiosResponse
  ) => {
    switch (response?.status) {
      case 401:
        dispatch(onEmailVerifiactionFail());
        break;
      default:
        break;
    }
  };

  const onEmailResendFail = (message: string, response: AxiosResponse) => {
    toast.error('Network Failure', {
      autoClose: 5000,
      closeOnClick: true,
      draggable: true,
      hideProgressBar: false,
      // onOpen: () => dispatch(onClearSignupProcess()),
      pauseOnHover: true,
      position: 'bottom-right',
      progress: undefined,
      toastId: 'error1',
      theme: "colored",
    });
  };

  const onSmsVerificationFail = (message: string, response: AxiosResponse) => {
    // OtpTracker({ eventName: 'Otp Status Tracker', message: response?.statusText, status: response?.status, email: user?.email })
    MixPannelTracker({ eventName: mixPannelTrackerEventName.otp_Failed, email: user?.email })
    switch (response?.status) {
      case 401:
        toast.error('Invalid OTP', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          onOpen: () => dispatch(onClearSignupProcess()),
          pauseOnHover: true,
          position: 'bottom-right',
          progress: undefined,
          toastId: 'error1',
          theme: "colored",
        });
        break;
      default:
        break;
    }
  };

  const onForgotPasswordFail = (message: string, response: AxiosResponse) => {
    switch (response?.status) {
      case 401:
        toast.error('Invalid Email Address', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          onOpen: () => dispatch(onClearSignupProcess()),
          pauseOnHover: true,
          position: 'bottom-right',
          progress: undefined,
          toastId: 'error1',
          theme: "colored",
        });
        break;
      default:
        break;
    }
  };

  const onResetPasswordFail = (message: string, response: AxiosResponse) => {
    switch (response?.status) {
      case 400:
        toast.error(response?.data?.title, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          onOpen: () => dispatch(onClearSignupProcess()),
          pauseOnHover: true,
          position: 'bottom-right',
          progress: undefined,
          toastId: 'error1',
          theme: "colored",
        });
        break;
      case 401:
        toast.error('Invalid Information', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          onOpen: () => dispatch(onClearSignupProcess()),
          pauseOnHover: true,
          position: 'bottom-right',
          progress: undefined,
          toastId: 'error1',
          theme: "colored",

        });
        break;
      default:
        break;
    }
  };

  const loginHandle = (loginParams_: LoginParams) => {
    dispatch(setUserDetails({ email: loginParams_.email }))

    const data_ = new URLSearchParams();
    data_.append('client_id', 'default-client');
    data_.append('response_type', 'token');
    data_.append('grant_type', 'password');
    data_.append('username', loginParams_.email);
    data_.append('password', loginParams_.password);
    data_.append('scope', 'offline_access');
    data_.append(
      'resource',
      'sample-app default-client risk-profile account-manager wealth-engine equity-manager cash-fund paymob raast cloud-connector'
    );
    dispatch(onLogin(data_, onLoginFail));
    dispatch(setClearEmailPassword(true));
    // dispatch(UserDetails());
  };

  const handleResendEmail = () => {
    const data: userVerificationStatusParams = {
      token: userTempToken,
      userId
    }
    const onSuccessUserVerification = (message: any, response: AxiosResponse) => {
      if (message?.emailConfirmed) {
        push({ pathname: links.login });
      } else {
        dispatch(resendEmailVerifcation(userId, onEmailResendFail))
      }
    }
    dispatch(userVerificationStatus(data, onSuccessUserVerification))
  }

  const onSuccessSmsVerification = (message: string, response: AxiosResponse) => {
    const data: userVerificationStatusParams = {
      token: userTempToken,
      userId
    }
    // OtpTracker({ eventName: 'Otp Status Tracker', message: response?.statusText, status: response?.status, email: user?.email })
    MixPannelTracker({ eventName: mixPannelTrackerEventName.otp_verified, email: user?.email })
    const onSuccessUserVerification = (message: any, response: AxiosResponse) => {
      if (!message?.emailConfirmed) {
        dispatch(onSmsCodeVerifiactionSuccess(""))
      } else {
        push({ pathname: links.login });
      }
    }

    dispatch(userVerificationStatus(data, onSuccessUserVerification))
  }

  const onFailResendCode = (message: string, response: AxiosResponse) => {
    OtpTracker({ eventName: 'Resend OTP', message: response?.statusText || 'OTP send failed', status: response?.status || 400, email: user?.email })
    switch(response?.status){
      case 500:
        toast.error("Something went wrong.",{ theme: "colored",})
        break;
    }

  }

  const onSucessResendCode = (message: string, response: AxiosResponse) => {
    OtpTracker({ eventName: 'Resend OTP', message: response?.statusText || 'OTP send sucessfully', status: response?.status || 200, email: user?.email })

    toast.success(AuthMsgs.resendOtpWhatsapp, {
      autoClose: 5000,
      closeOnClick: true,
      draggable: true,
      hideProgressBar: false,
      pauseOnHover: true,
      position: 'bottom-right',
      progress: undefined
    });
  }

  const emailCodeHandle = (data: string) => dispatch(onResendEmailCode(data));
  const phoneCodeHanele = (data: string) => dispatch(onResendPhoneCodeHanele(data));
  const resetPasswordHandle = (data: ResetPassParams) => dispatch(onResetPassword(data, onResetPasswordFail));
  const resendEmail = () => { handleResendEmail() };
  const verifyEmail = (data: verifyEmailParams) => dispatch(onVerifyEmail(data, onEmailVerificationFail));
  const verifySmsCodeHandle = (data: verifySmsCodeParams) => dispatch(verifySmsCode(data, onSuccessSmsVerification, onSmsVerificationFail));

  const resendSmsCode_ = (data:resendSmsCodeParams) => dispatch(resendSmsCode(data?.messageMethod,userId, onSucessResendCode, onFailResendCode));
  const forgotPasswordHandle = (data: ForgotParams) => dispatch(onForgotPassword(data, onForgotPasswordFail));
  const registerHandle = (data: RegisterParams) => dispatch(onRegister(data, onRegisterionFail));

  const registerHandleOTPVerify = () => {

    push({
      pathname: links.otpVerify,
      state: phoneNo
    });
    MixPannelTracker({ eventName: mixPannelTrackerEventName.signed_up_on_app, email: userEmail })
  }

  const notify = useCallback(() => {
    msg?.length && dispatch(onClearSignupProcess());

    msg === AuthMsgs.otpVerify && registerHandleOTPVerify()

    msg === AuthMsgs.emailVerify &&
      push({
        pathname: links.resendEmailVerification
      });
    msg === AuthMsgs.registeredSuccessfuly &&
      push({
        pathname: links.login
      });
    msg === AuthMsgs.passwordResetSuccessfully &&
      push({
        pathname: links.login
      });
  }, [dispatch, msg, push]);

  useEffect(() => {
    notify();
  }, [notify]);

  useEffect(() => {
    const startDate = paramFinder("startdate");
    const endDate = paramFinder("enddate");
    const value = paramFinder("value")

    const filteredRoutes = ScopedDashboardRoutes?.filter(item => item.scope === scopes?.filter((el: any) => el === item.scope)[0]) || [];
    if (value === "5"){
      isAuth && filteredRoutes.length && push(`${filteredRoutes[0]?.url}?value=${value}&startdate=${startDate}&enddate=${endDate}`);
    }
    else{
      isAuth && filteredRoutes.length && push(filteredRoutes[0]?.url);
    }
  }, [
    dispatch,
    isAuth,
    push,
    scopes,
    isRiskProfileCompleted,
    clearEmailPassword,
    isEmailVerified,
    user?.id,
    userAccountInfo?.isNewUser,
    phoneNo
  ]);

  const verifyUser = (data: any) => {
    setUserInfo(data)
    const userEmail = {
      email: data?.email
    }

    const onSuccess = (message: string, response: any) => {
      if (message) {
        registerHandle(data)
        setUserEmail(data?.email)
      } else {
        setModalVisible(true)
      }
    }

    const onFail = (message: string, response: any) => {
      console.debug(message, response?.status)
    }
    // registerHandle(data)
    // setUserEmail(data?.email)
    dispatch(checkEmailInInvitees(userEmail, onSuccess, onFail))
  }

  const [apiLoading, setApiLoading] = useState(false)
  const [errorModal, setErrorModal] = useState(false)

  const handleVerifyResetPassToken = (data: any) => {
    setApiLoading(true)
    dispatch(verifyResetPassToken(data, onVerifyTokenSuccess, onVerifyTokenFail))
  }
  const onVerifyTokenSuccess = (message: string, response: any) => { setApiLoading(false) };

  const onVerifyTokenFail = (message: string, response: any) => {
    if (response?.status) {
      setApiLoading(false)
      setTimeout(() => {
        setErrorModal(true)
      }, 10);
    }
  }
  const handleCloseMsg = () => {
    replace(links.login);
    setErrorModal(false)
  }

  return (
    <>
      {apiLoading && <Loader />}
      {/* {appLoading && <Loader />} */}

      <Box>
        <TopBarLogo showNavbarItems={false} />

        <Switch>
          <Route
            exact
            path={links.signup}
            render={() => (
              <Signup
                loading={appLoading}
                phoneNo={(e: any) => setPhoneNo(e)}
                registerHandle={verifyUser}
              />
            )}
          />
          <Route
            exact
            path={links.verifyEmail}
            render={() => (
              <VerifyEmail
                isEmailVerified={isEmailVerified}
                resendEmail={resendEmail}
                verifyEmail={verifyEmail}
              />
            )}
          />
          <Route
            exact
            path={links.verifyEmailToken}
            render={() => (
              <VerifyEmail
                isEmailVerified={isEmailVerified}
                resendEmail={resendEmail}
                verifyEmail={verifyEmail}
              />
            )}
          />
          <Route
            exact
            path={links.forgot}
            render={() => (
              <Forgot
                forgotPasswordHandle={forgotPasswordHandle}
                loading={appLoading}
              />
            )}
          />
          <Route
            exact
            path={links.resetPassword}
            render={() => (
              <ResetPassword
                emailCodeHanele={emailCodeHandle}
                loading={appLoading}
                phoneCodeHanele={phoneCodeHanele}
                resetPasswordHandle={resetPasswordHandle}
                handleVerifyToken={handleVerifyResetPassToken}
              />
            )}
          />
          <Route
            exact
            path={links.login}
            render={() => (
              <LoginPage
                clearEmailPassword={clearEmailPassword}
                loading={appLoading}
                loginHandle={loginHandle}
              />
            )}
          />
          <Route
            exact
            path={links.otpVerify}
            render={() => (
              <ValidateOTP
                loading={appLoading}
                otpUserId={otpUserId}
                phoneNo={phoneNo}
                resendSmsCode={resendSmsCode_}
                userId={userId}
                verifySmsCode={verifySmsCodeHandle}
              />
            )}
          />
          <Route
            exact
            path={links.resendEmailVerification}
            render={() => <ResendEmailVerification resendEmail={resendEmail} />}
          />
          <Route
            path='*'
            render={() => <Error404 pageName='Login' url={links.login} />}
          />
        </Switch>

        {/* <ToastContainer
          closeOnClick
          draggable
          pauseOnFocusLoss
          limit={1}
          pauseOnHover
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          position='bottom-right'
          rtl={false}
        /> */}

        {/* <ResponseMessage
          modalVisible={errorModal}
          handleClose={handleCloseMsg}
          message={'Oops! Your reset password link has expired.'}
        /> */}

        <WaitListResponseModal
          isVisible={modalVisible}
          handleClose={() => setModalVisible(false)}
          nextButtonOnClick={() => {
            setWaitlistModalVisible(true)
            setModalVisible(false)
          }}
        />

        <WaitListModal
          isVisible={waitlistModalVisible}
          handleClose={() => setWaitlistModalVisible(false)}
          userData={userInfo}
          callback={() => {
            setSuccessModalVisible(true)
            setWaitlistModalVisible(false)
          }}
        />

        <ContentWithLogoModal
          isVisible={successModalVisible}
          handleClose={() => setSuccessModalVisible(false)}
          title={"Thank you for joining us!"}
          description={"We will be in touch shortly"}
        />

      </Box>
    </>
  );
};

export default memo(Auth);

