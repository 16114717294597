import { Box, Divider, IconButton, TableBody, Typography, useTheme } from '@mui/material'
import React from 'react'
import { tokens } from 'theme';
import depositIcon from "Latest/Images/depositIcon.png"
import withdrawIcon from "Latest/Images/withdrawIcon.png"
import CustomTable, { StyledTableCell, StyledTableRow } from 'Latest/TableV1/customTable';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useAppSelector } from 'hooks/reduxHooks';
import { CurrencyFormatterWithDecimal } from 'utils/calculation';
import { marketDataTableChange } from '../../../constants';
import { CardinfoStyle } from '../style/cardinfoStyle';

const Index = () => {


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { marketData } = useAppSelector((state: any) => state.sharedData);

    const tableStyle = { fontWeight: 400, fontSize: "12px", lineHeight: "18px", color: colors.grey[800], padding: "8px 0 8px 16px" }
    const tableHead = [{ text: "Item" }, { text: "Current rate" }, { text: "Change/day" }]
    const tableBodyValue = marketData?.marketDataFeeds

    const marketDataRateManupulate = (row: any) => {
        if (row.name == "Crude Oil (bbl)") {
            return (`${row.symbol}${CurrencyFormatterWithDecimal(row?.currentRate, false, "never", false)}`)
        } else if (row.name == "Kibor") {
            return (`${CurrencyFormatterWithDecimal(row?.currentRate, false, "never", false)}${row.symbol}`)
        } else {
            return (`${row.symbol} ${CurrencyFormatterWithDecimal(row?.currentRate, false, "never", false)}`)
        }
    }

    const marketData1DayManupulate = (row: any) => {
        if (row.name == "Kibor") {
            return (`${CurrencyFormatterWithDecimal(row?.dailyRateOfChange, false, "never", false)} bps`)
        } else {
            return (`${CurrencyFormatterWithDecimal(row?.changePercentage, false, "never", false)}%`)
        }
    }

    const handleArrowKey = (row: any) => {
        if (row.name == "Kibor") {
            return row?.dailyRateOfChange >= 0
        } else {
            return row?.changePercentage >= 0
        }
    }

    const TabelBody = () => {
        return (
            <TableBody>
                {tableBodyValue?.map((row: any, index: number) => (
                    <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row" style={tableStyle}> {row.name} </StyledTableCell>
                        <StyledTableCell style={tableStyle}> {marketDataRateManupulate(row)} </StyledTableCell>

                        <StyledTableCell
                            style={{
                                ...tableStyle,
                                color: handleArrowKey(row) ? colors.success[150] : colors.red[150],
                                // color: handleArrowKey(row) ? colors.success[500] : colors.redAccent[600],
                                padding: "14px 16px",
                                fontWeight: 600
                            }} >
                            {/* {handleArrowKey(row) ? <ArrowUpwardIcon sx={{ color: colors.success[500], width: "18px" }} />
                                :
                                <ArrowDownwardIcon sx={{ color: colors.redAccent[600], width: "18px" }} />
                            } */}
                            {marketData1DayManupulate(row)}
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }

    return (
        <Box sx={{...CardinfoStyle.itemGridRight ,borderRadius:"8px"}}>
            <Typography variant='h4' color={colors.purple[100]} mb={2} lineHeight={"36px"}>Market data</Typography>
            <Box >
                <CustomTable tableHead={tableHead} TabelBody={TabelBody()} tableMinWidth={240} />
            </Box>
        </Box >
    )
}

export default Index