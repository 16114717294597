import { Col } from 'react-bootstrap';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { memo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const FormikDatePicker = ({
  col = 12,
  formik,
  handleDateChange,
  label,
  name,
  required = false
}: FormikDatePickerProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;  
  return (
    <Col lg={col}>
      <div className='form-group'>
        <label className='mb-1 field-label'>
          <strong>
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </strong>
        </label>
        <DatePicker
          className='form-control h-38'
          onChange={(date: Date) => handleDateChange(date)}
          placeholderText='Enter Date of Birth'
          selected={formik.values.birthDate}
        />
        {
          <span className='ml-2 text-danger'>
            {formikTouch[name] && formikError[name]}
          </span>
        }
      </div>
    </Col>
  );
};

export default memo(FormikDatePicker);

interface FormikDatePickerProps {
  autoFocus?: boolean;
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  formik: FormikFieldProps;
  label: string | JSX.Element;
  required?: boolean;
  type?: string;
  name: string;
  handleDateChange: (date: Date) => void;
}

export interface FormikFieldProps {
  getFieldProps: (field: string) => void;
  touched: FormikTouched<unknown>;
  errors: FormikErrors<unknown>;
  values: FormikValues;
}
