import LoadingButton from '@mui/lab/LoadingButton'
import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box } from '@mui/material';

const buttonCustomStyles = {
    // minHeight: "42px",
    // padding: { lg: "10px 16px", md: "0 !important", xs: "0 !important" },
    minWidth: "118px",
    fontWeight: 500,
    fontSize: "0.875rem"
}


const LoadingBtnCustomBack = ({ btnTitle = `Back`, color = "secondary", handleOnClick, icon = false, loading, style, variant = 'outlined' }: any) => {

    return (
        <LoadingButton
            loading={loading}
            variant={variant}
            sx={{
                color: "#403081", border: "1px solid #D0D5DD", borderRadius: "100px", textTransform: "initial",
                ...buttonCustomStyles,
                '&:hover': {
                    borderColor: '#D0D5DD', // Set your desired border color for hover
                },
                ...style,
            }}
            // startIcon={<ArrowBackIosIcon />}
            onClick={handleOnClick}
            size='large'
            endIcon={icon ? icon : null}
        // Display icon 
        >{btnTitle} </LoadingButton>
    )
}

export default LoadingBtnCustomBack