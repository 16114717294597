import React, { useState, useEffect } from 'react';
import '../../scss/login/_Login.scss';
import { Card, Box, Container, InputAdornment, IconButton, useTheme, useMediaQuery, Typography } from '@mui/material';
import { ResetPassParams } from 'redux/actions';
import { resetPassword } from 'validate/auth/resetPassword';
import { useLocation, useParams } from 'react-router-dom';
import { memo } from 'react';
import { useFormik } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Logo from 'Latest/Images/mahanna2.png'
import AuthPasswordField from '../components/MUI_components/FormikPasswordField';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import { authStyles } from 'Latest/Style/Style';
import { styleAuth } from './style';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import { passwordMaxLength } from '../../constants/index';

interface State {
  ConfirmPassword: string;
  password: string;
  showPassword: boolean;
  ConfirmshowPassword: boolean;
}

const ResetPassword = ({
  emailCodeHanele,
  handleVerifyToken,
  loading,
  phoneCodeHanele,
  resetPasswordHandle
}: ResetPasswordProps) => {
  const { search } = useLocation();
  const { userId } = useParams<{ userId: string }>();
  const { token } = useParams<{ token: string }>();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const data = {
      "userId": userId,
      "token": token
    }
    handleVerifyToken(data)
  }, [])


  const formik = useFormik({
    initialValues: {
      userId,
      password: '',
      confirmPassword: '',
      token
    },
    onSubmit: (values: ResetPassParams) => {
      resetPasswordHandle({
        userId: values.userId,
        password: values.password,
        confirmPassword: values.confirmPassword,
        token: values.token
      });
    },
    validationSchema: resetPassword
  });

  const [values, setValues] = useState<State>({
    ConfirmPassword: '',
    password: '',
    showPassword: false,
    ConfirmshowPassword: false
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeConfirmPassword = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      ConfirmshowPassword: !values.ConfirmshowPassword,
    });
  };

  const handleMouseConfirmDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    formik.setFieldValue('password', formik.values.password.replace(/\s/g, '').trim())
    formik.setFieldValue('confirmPassword', formik.values.confirmPassword.replace(/\s/g, '').trim())
  }, [formik.values.confirmPassword, formik.values.password])

  return (
    <>
      <Container disableGutters={smScreen} sx={{ mt: { xs: "24px", md: "40px" } , maxWidth:"540px !important" }}>
        <Box sx={styleAuth.cardContainer}>
          <Typography variant='h1' color='#1D2939' pb="12px">Reset your password</Typography>

          <AuthPasswordField
            // required
            removeWhitespace={true}
            maxLength={Number(passwordMaxLength.maxLength)}
            disabled={loading}
            endAdornment={<InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>}
            formik={formik}
            label='Password'
            name='password'
            onChange={handleChange('password')}
            placeholder='••••••••••'
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
          />
          <AuthPasswordField
            // required
            removeWhitespace={true}
            maxLength={Number(passwordMaxLength.maxLength)}
            disabled={loading}
            endAdornment={<InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={handleClickShowConfirmPassword}
                onMouseDown={handleMouseConfirmDownPassword}
              >
                {values.ConfirmshowPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>}
            formik={formik}
            label='Confirm password'
            name='confirmPassword'
            onChange={handleChangeConfirmPassword('password')}
            placeholder='••••••••••'
            type={values.ConfirmshowPassword ? 'text' : 'password'}
            value={values.ConfirmPassword}
          />

          <Box sx={{ pt: "12px" }}>
            <LoadingBtnCustom btnTitle="Reset password" size={"large"} fullWidth={true} handleOnClick={formik.handleSubmit} loading={loading} />
          </Box>

        </Box>
      </Container>


      {/* <Container className="Login" component="main" maxWidth="sm" sx={authStyles.container}>
        <Card className='login_Card'>
          <Box className='Form_Main'
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <img alt="Mahaana" className='img logo' src={Logo} />
            <h1 className='Login_heading'>Update Your Password</h1>
            <Box className='Form_Secondary'>
              <AuthPasswordField
                required
                removeWhitespace={true}
                disabled={loading}
                endAdornment={<InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>}
                formik={formik}
                label='Password'
                name='password'
                onChange={handleChange('password')}
                placeholder='••••••••••'
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
              />
              <AuthPasswordField
                required
                removeWhitespace={true}
                disabled={loading}
                endAdornment={<InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseConfirmDownPassword}
                  >
                    {values.ConfirmshowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>}
                formik={formik}
                label='Confirm Password'
                name='confirmPassword'
                onChange={handleChangeConfirmPassword('password')}
                placeholder='••••••••••'
                type={values.ConfirmshowPassword ? 'text' : 'password'}
                value={values.ConfirmPassword}
              />
              <AuthSubmitButton
                loading={loading}
                isAuth={true}
                title='Update'
              />

            </Box>
          </Box>
        </Card>
      </Container> */}

    </>
  );
};

export default memo(ResetPassword);

interface ResetPasswordProps {
  resetPasswordHandle: (data: ResetPassParams) => void;
  loading: boolean;
  emailCodeHanele: (email: string) => void;
  phoneCodeHanele: (email: string) => void;
  handleVerifyToken: (data: any) => void;
}
