import { memo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select, { StylesConfig, components } from 'react-select';
import { Grid, Typography } from '@mui/material';
import { CSSObject } from 'styled-components';

type Field = { label: string; value: string };

const styles: StylesConfig<Field, false> = {
    option: (provided: CSSObject, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? '#F3F4F6' // Background color for selected option
            : state.isFocused
                ? '#E5E5E5' // Background color for focused (hovered) option
                : 'transparent', // Default background color
        color: state.isSelected ? '#4C4C4C' : '#444',
        // height: 40,
        cursor: "pointer",

    }),
    dropdownIndicator: (provided: CSSObject, state) => {
        return {
            ...provided,
            color:"#202020"
        };
    },
};



const AppSelectWithLabel = ({
    customStyle,
    disabled = false,
    fieldName,
    fieldSelect = false,
    fields,
    formik,
    isMulti = false,
    label,
    loading = false,
    name,
    nestedFieldName,
    onChange,
    onInputChange,
    placeholder,
    required,
    value
}: AppSelectWithLabelProps) => {

    const [isFieldTouched, setIsFieldTouched] = useState(false);
    const changeHandle = (option: Field) => onChange?.(option.label, option);
    const formikTouch = formik.touched as any;
    const formikError = formik.errors as any;
    if (!fields.length) return null;
    if (loading) return null;

    let FieldError = "";
    let FieldTouch = false;
    let NestedFieldError = "";
    let NestedFieldTouch = false;

    if (nestedFieldName) {
        const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
        const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
        if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
            NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
            NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
        }
    } else {
        FieldError = formikError[fieldName];
        FieldTouch = formikTouch[fieldName];
    }

    const CustomOption = ({ children, ...props }: any) => (
        <components.Option {...props}>
            <div dangerouslySetInnerHTML={{ __html: children }} />
        </components.Option>
    );

    const formatOptionLabel = (option: any) => (
        <div dangerouslySetInnerHTML={{ __html: option.label }} />
    );

    return (
        <>
            <Grid item xs={12}>
                {
                    label && (
                        <Typography sx={{ mb: "6px" }} variant='body2'>
                            {label}
                            {required && <span className='ml-1 text-danger'>*</span>}
                        </Typography>
                    )
                }

                <Select
                    className=''
                    defaultValue={fields.filter(el => el.label == formik.values[fieldName])}
                    isDisabled={disabled}
                    isLoading={loading}
                    name={name}
                    onMenuOpen={() => formik.setFieldTouched(fieldName, true)}
                    // onBlur={() => nestedFieldName ? formik.setFieldTouched(nestedFieldName, true) : formik.setFieldTouched(fieldName, true)}
                    onChange={option => option && changeHandle(option)}
                    onInputChange={onInputChange}
                    options={fields}
                    placeholder={placeholder ?? label}
                    required={required || false}
                    styles={{ ...styles, ...customStyle }}
                    //   styles={
                    //     fieldSelect
                    //       ? undefined
                    //       : {
                    //         option: (provided, state) => ({
                    //           ...provided,
                    //           backgroundColor: 'transparent',
                    //           // borderRadius: '0.75rem !important',
                    //           color: state.isSelected ? '#4012A0' : '#444',
                    //           height: 40,
                    //           borderColor: state.isFocused ? 'grey' : 'red',
                    //         })
                    //       }
                    //   }
                    // components={{ Option: CustomOption }}
                    formatOptionLabel={formatOptionLabel}
                    getOptionLabel={(option) => option.label}
                    touched={isFieldTouched}
                    value={value ? value : fields.find(el => el.label == formik.values[fieldName])}

                />

                {
                    formik && FieldTouch && FieldError && (
                        <span className='ml-2 mt-1 text-danger' style={{ display: "flex" }}>{FieldError}</span>
                    )
                }

                {
                    formik && NestedFieldTouch && NestedFieldError && (
                        <span className='ml-2 mt-3 text-danger'> {NestedFieldError}</span>
                    )
                }

            </Grid>
        </>
    );
};

export default memo(AppSelectWithLabel);
interface AppSelectWithLabelProps {
    fieldSelect?: boolean;
    disabled?: boolean;
    fieldName: string;
    nestedFieldName?: any;
    fields: Array<Field>;
    formik: any;
    isMulti?: boolean;
    label?: string;
    name?: string;
    placeholder?: string;
    required?: boolean;
    onChange?: (
        option: string,
        optionObject: Record<string, string | number>
    ) => void;
    onInputChange?: (label: string) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
    loading?: boolean;
    customStyle?:any
}
