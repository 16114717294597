import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useFormik } from 'formik';
import FormikInputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import PhoneField from 'jsx/components/Shared/PhoneField';
import { authStyles } from 'Latest/Style/Style';
import { phoneFieldStyle } from 'jsx/components/Shared/style/phoneFieldStyle';
import { nextKinValidation } from 'validate/Screen/NextKinValidation';
import { onGetAccountSetup, onPostNextKin } from 'redux/reducers/auth';
import { ToastContainer, toast } from 'react-toastify';
import { onGetUserBankAccount } from 'redux/reducers/sharedStateData';
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack';

const SaveNextKin = ({
  checkNextKin,
  handleCloseModal,
  loading,
  path,
  setCheckNextKin
}: props) => {
  const { userAccountDetails } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userAccountDetails?.beneficiaries?.[0]?.name || '',
      CNIC: userAccountDetails?.beneficiaries?.[0]?.cnic || '',
      phoneNumber: userAccountDetails?.beneficiaries?.[0]?.phone || ''
    },
    onSubmit: values => {
      handleApi();
    },
    validationSchema: nextKinValidation
  });

  const handleApi = () => {
    const { CNIC, name, phoneNumber } = formik.values;

    const data = {
      beneficiaries: [
        {
          name: name,
          cnic: CNIC,
          phone: phoneNumber
        }
      ]
    };

    const onSuccess = (message: string, response: any) => {
      dispatch(onGetAccountSetup('accountSetup'));
      handleCloseModal && handleCloseModal();
      setCheckNextKin(true);
      toast.success('Saved successfully', {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined
      });
    };
    const onFail = (message: string, response: any) => {
      console.log(message, response.status, 'OPOOP');
    };

    dispatch(onPostNextKin(data, onSuccess, onFail));
  };

  const dropdownStyles = {
    width: "245px",
  }

  const phoneNumberStylesLatest = {
    '& .react-tel-input .flag-dropdown, .react-tel-input .selected-flag': {
      borderRadius: "8px 0 0 8px"
    }
  }

  return (
    <>
      <ToastContainer />
      <Grid item xs={12} md={12}>
        <Grid container rowSpacing={'20px'}>
          <Grid item md={12} xs={12}>
            <FormikInputField
              required
              formik={formik}
              maxLength={100}
              label='Name (as per CNIC)'
              name='name'
              placeholder='Enter name'
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <FormikInputField
              required
              formik={formik}
              label='CNIC Number'
              maxLength={13}
              name='CNIC'
              placeholder='Enter CNIC Number'
            />
          </Grid>

          <Grid item md={12} xs={12} sx={phoneNumberStylesLatest}>
            <PhoneField
              required
              country={'pk'}
              countryCodeEditable={false}
              disableCountryCode={false}
              disableDropdown={false}
              dropdownStyle={dropdownStyles}
              enableSearch={true}
              fieldName='phoneNumber'
              formik={formik}
              label='Mobile number'

              value={formik.values.phoneNumber}
            />
          </Grid>

          {path == 'modal' ? (
            <Grid item md={12} xs={12} display={'flex'} gap={2}>
              <LoadingBtnCustomBack btnTitle={"Cancel"} handleOnClick={handleCloseModal} />
              <LoadingBtnCustom loading={loading} btnTitle={"Submit"} handleOnClick={() => formik.submitForm()} />
            </Grid>
          ) : (
            <Grid
              item
              md={12}
              xs={12}
              display={'flex'}
              gap={2}
              justifyContent={'end'}
            >
              {userAccountDetails?.beneficiaries?.length > 0 && (
                <LoadingBtnCustomBack disabled={loading} btnTitle={"Cancel"} handleOnClick={() => setCheckNextKin(true)} />
              )}

              <LoadingBtnCustom
                disabled={JSON.stringify(formik.values) === JSON.stringify(formik.initialValues) ? true : false}
                loading={loading} btnTitle={"Save"} handleOnClick={() => formik.submitForm()}
              />

            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SaveNextKin;

interface props {
  setCheckNextKin?: any;
  checkNextKin?: boolean;
  loading?: boolean;
  path?: string;
  handleCloseModal?: any;
}
