import * as React from 'react';
import '../../scss/login/_Login.scss';
import { useHistory, useParams } from 'react-router-dom';
import { memo, useEffect } from 'react';
import { verifyEmailParams } from 'redux/reducers/auth';
import { links } from 'static/links';
import { authStyles } from 'Latest/Style/Style';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import success from 'Latest/Images/success.png';
import error from 'Latest/Images/error.png';
import failedEmailImg from 'Latest/Images/svg/failedEmail.svg';
import verifiedEmail from 'Latest/Images/svg/verifiedEmail.svg';

import { styleAuth } from './style';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import { mixPannelTrackerEventName } from '../../constants/index';
import { useAppSelector } from 'hooks/reduxHooks';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';


const VerifyEmail = ({ isEmailVerified, resendEmail, verifyEmail }: EmailVerificationProps) => {
    const { push } = useHistory();

    const { userId } = useParams<{ userId: string }>();
    const { token } = useParams<{ token: string }>();
    const { email } = useParams<{ email: string }>();

    const theme = useTheme();
    // const { user: { email } } = useAppSelector((state: any) => state.auth);

    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        const data: verifyEmailParams = {
            userId,
            token
        }
        verifyEmail(data)
    }, [userId]);

    useEffect(() => {
        if (isEmailVerified == "true") {
            MixPannelTracker({ eventName: mixPannelTrackerEventName.email_verified, email: email })
        }

    }, [isEmailVerified]);

    // if (isEmailVerified === 'true') {
    //     setTimeout(() => {
    //         push(links.login);
    //     }, 1500);
    // }

    return (
        <>
            <Container disableGutters={smScreen} maxWidth="sm" sx={{ mt: { xs: "24px", md: "40px" }, maxWidth: "500px!important" }}>
                <Box sx={styleAuth.cardContainer}>
                    {isEmailVerified == 'false' &&
                        <>
                            <img src={failedEmailImg} style={{ margin: '0 auto 2.5rem' }} />

                            <Typography variant='h1' textAlign={"center"} color="#1D2939">Email could not be verified</Typography>

                            <Box >
                                <Typography variant='body2' textAlign={"center"} color={"rgba(29, 41, 57, 0.70)"}>
                                    Try again by clicking on the button below.
                                </Typography>
                            </Box>

                            <Box sx={{ pt: "12px" }}>
                                <LoadingBtnCustom btnTitle="Resend email" size={"large"} fullWidth={true} handleOnClick={resendEmail} />
                            </Box>
                        </>
                    }
                    {isEmailVerified === 'true' &&
                        <>
                            <img src={verifiedEmail} style={{ margin: '0 auto 2.5rem' }} />

                            <Typography variant='h1' textAlign={"center"} color="#1D2939">Email successfully verified</Typography>

                            <Box >
                                <Typography variant='body2' textAlign={"center"} color={"rgba(29, 41, 57, 0.70)"}>
                                    You can now proceed to opening your investment account.
                                </Typography>
                            </Box>

                            <Box sx={{ pt: "12px" }}>
                                <LoadingBtnCustom btnTitle="Sign in" size={"large"} fullWidth={true} handleOnClick={() => push(links.login)} />
                            </Box>
                        </>
                    }
                </Box>
            </Container>

            {/* <Container className="Login" component="main" maxWidth="sm" sx={{ ...authStyles.container, ...authStyles.resendEmail, ...authStyles.emailVerification }}>
                <Card className='login_Card'>
                    <Box className='Form_Main'
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                        }}
                    >
                        {isEmailVerified === 'false' &&
                            <>
                                <img src={error} alt="error" />
                                <h1 className='ReEmail_heading' style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignSelf: 'center' }}> Email Verification Unsuccessful </h1>
                                <AuthSubmitButton isAuth={true} title='Resend Email' onClick={resendEmail} />
                            </>
                        }
                        {isEmailVerified === 'true' &&
                            <>
                                <img src={success} alt="success" />
                                <h1 className='ReEmail_heading' style={{ display: 'flex', textAlign: 'center', alignSelf: 'center' }}>Your email address has been confirmed!</h1>
                                <Button
                                    className='btn_Signin'
                                    fullWidth={true}
                                    onClick={() => push(links.login)}
                                    type="submit"
                                    variant="contained"
                                >
                                    Sign in
                                </Button>
                            </>
                        }
                    </Box>
                </Card>
            </Container> */}
        </>
    );
};

export default memo(VerifyEmail);

interface EmailVerificationProps {
    isEmailVerified: string
    resendEmail: () => void;
    verifyEmail: (data: verifyEmailParams) => void;
}