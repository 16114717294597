import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import Loader from 'jsx/components/Shared/Loader';
import React, { useCallback, useEffect } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { onGetEmployerInvestmentDetail } from 'redux/reducers/employer';
import { employerContributionFilters } from 'static/EmployerDashboard/employerContributionFilters';
import InvestmentsTable from './InvestmentsTable';
import Investments_ from 'mockData/Investments';


export default function EmployerInvestments() {
  const dispatch = useAppDispatch();
  const {
    employee_investment_detail,
    employer_investment_detail,
    fund_detail
  } = useAppSelector(state => state.employer);
  const loading = useAppLoading('employer');

  const getInitialData = useCallback(() => {
    // dispatch(onGetEmployerInvestmentDetail());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);
  return (
    <div>
      {loading ? (
        <div className='text-center'>
          <Loader width={200} />
        </div>
      ) : (
        <>
          <Card className='pb-2 ' style={{ height: 'auto' }}>
            <Card.Header className='d-block'>
              <Row>
                <Col className='custom-padding' lg={6} sm={12}>
                  <p className='mb-0 text-black text-capitalize '>
                    {/* {employer_investment_detail[0]?.employeer_name ||
                      'no company'}
                    /Investments */}
                  </p>
                  <h2 className='mb-0 text-primary '>Investments</h2>
                </Col>
                <Col className='custom-padding' lg={6} sm={12}>
                  <Dropdown className='dropdown bootstrap-select form-control style-1 default-select mb-3 float-right'>
                    <Dropdown.Toggle
                      aria-expanded='false'
                      className='btn dropdown-toggle h-38'
                      data-toggle='dropdown'
                      role='button'
                      title='Latest'
                      type='button'
                      variant=''
                    >
                      Last Month
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className='dropdown-menu'
                      role='combobox'
                      style={{
                        left: 0,
                        maxHeight: 174,
                        minHeight: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        top: 0,
                        transform: 'translate3d(0px, 41px, 0px)',
                        willChange: 'transform'
                      }}
                      x-placement='bottom-start'
                    >
                      {employerContributionFilters.map(filter => (
                        <Dropdown.Item key={filter.key}>
                          {filter.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className='mt-2'>
                <Col md={12} sm={12}>
                  <InvestmentsTable
                    // employee_investment_detail={Investments_.holdings}
                    employer_investment_detail={Investments_}
                    fund_detail={Investments_.employees}
                    investmentEmployeesTotalRecords={fund_detail.length}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
    </div>
  );
}
