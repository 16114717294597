import FormikRadioField from 'jsx/components/Shared/V2/FormikRadioFieldV2';
import AppSelectWithLabel from 'jsx/components/Account_Fields/AppSelectWithLabelLatest';
import FormikCheckboxField from 'jsx/components/Shared/V1/FormikCheckboxFieldV1';
import AuthInputField from '../../../../jsx/components/MUI_components/FormikTextField';
import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import FormikSelectFieldWithLabel from 'jsx/components/Shared/FormikSelectFieldWithLabel';

const Index: React.FC<FatcaAndCrsPropsTypes> = ({ fieldData, fieldName, formik, maxLen, types }) => {

    const matches = useMediaQuery('(max-width:1121px)');


    return (
        <>
            {
                types === "radio" ? (
                   
                        <FormikRadioField
                        required
                        singleLine={false}
                        col={12}
                        fieldName={fieldName}
                        fields={fieldData?.answer}
                        formik={formik}
                        label={fieldData?.question}
                    />
                  
                ) : types === "textField" ? (
                    <Box sx={{ width: "100%" }}>

                        <AuthInputField
                            required
                            formik={formik}
                            label={fieldData?.question}
                            maxLength={maxLen}
                            name={fieldName}
                            // nestedFieldName='completeAddress'
                            placeholder="Enter here..."

                        />
                    </Box>

                ) : types === "select" ? (
                    <Box sx={{ width: "100%" }}>
                        <Typography variant='body2' sx={{ display: "flex", mb: "9px" }}>
                            {fieldData?.question}
                            <span className='ml-1 text-danger'>*</span>
                        </Typography>
                        <AppSelectWithLabel

                            fieldName={fieldName}
                            fields={fieldData?.answer}
                            formik={formik}
                            label={''}
                            // loading={loading}
                            onChange={(val, option) => {
                                formik.setFieldValue(fieldName, val);
                            }}
                            placeholder={'Select a reason'}
                            customStyle={{
                                option: (styles: any) => ({
                                    ...styles,
                                })
                            }}

                        // value={Number(formik.values.city)}
                        />

                    </Box>

                ) : types === "checkBox" ? (
                    <FormikCheckboxField
                        required
                        fieldName={fieldName}
                        fields={fieldData?.answer}
                        formik={formik}
                        label={''}
                    />
                ) : types === "selectWithLabel" ? (
                    <Box sx={{ width: "100%" }}>
                    <Typography variant='body2' sx={{ display: "flex",mb:"-17px" }}>
                        Country
                        <span className='ml-1 text-danger'>*</span>
                    </Typography>
                    <FormikSelectFieldWithLabel
                      disabled={false}
                      fieldName={fieldName}
                      fields={fieldData}
                      formik={formik}
                      isAlert={false}
                      label=''
                      onChange={(val, option) => {
                        formik.setFieldValue('country', val);
                      }}
                      placeholder='Select country'
                      value={formik.values.country}
                      
                    />
                    </Box>
                )
                :(
                    ""
                )
            }
        </>
    )
}

export default Index

interface FatcaAndCrsPropsTypes {
    types: string;
    maxLen: number;
    formik: any;
    fieldData: any;
    fieldName: string;
}