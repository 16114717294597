import React, { useEffect, useState } from 'react'
import InputField from '../../Shared/V2/FormikInputFieldV2';
import { Grid, Typography } from '@mui/material';
import { CityName } from './city';
import { ProvinceName } from './province';
import { subHeadingSecStyle } from 'Latest/Style/Main';
import MailingFields from './mailingFieldsV2';
import SelectField from '../../Shared/FormikSelectFieldLatest';
import { useAppSelector } from 'hooks/reduxHooks';

const PermanentField = ({ formik, style, type = "flow" }: any) => {

    const [cityList, setcityList] = useState<any>([])
    const { countries } = useAppSelector(state => state.sharedData);
    const [provinceList, setProvinceList] = useState<any>([])
    const [maxTextLength, setMaxTextLength] = useState(120);

    useEffect(() => {
        const data = CityName?.map((val) => ({ 'value': val.name, 'label': val.name }));
        setcityList(data)

        const stateList = ProvinceName?.map((val) => ({ 'value': val.name, 'label': val.name }));
        setProvinceList(stateList)

        const largestCity = CityName?.reduce((max, city) => Math.max(max, city.name.length), 0);
        const largestProvince = ProvinceName?.reduce((max, province) => Math.max(max, province.name.length), 0);
        const newLength = 120 - (largestProvince + largestCity);
        const roundedNumber = Math.round(newLength / 10) * 10;
        setMaxTextLength(roundedNumber);
        
    }, [CityName, ProvinceName])

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                    <Typography sx={subHeadingSecStyle}>Permanent address</Typography>
                </Grid>

                <Grid item md={12} xs={12}>
                    <InputField
                        maxLength={maxTextLength}
                        formik={formik}
                        label='Address'
                        name='permanentAddress1'
                        placeholder='Address line 1'
                        type="text"
                    />
                </Grid>

                {/* <Grid item md={12} xs={12}>
                    <InputField
                        maxLength={maxTextLength}
                        formik={formik}
                        label='Address line 2 (optional)'
                        name='permanentAddress2'
                        placeholder='Address line 2'
                        type="text"
                    />
                </Grid> */}

                <Grid item lg={6} md={6} xs={12}>
                    <SelectField
                        col={12}
                        fieldName='cityPermanent'
                        fields={cityList}
                        formik={formik}
                        label='City'
                        onChange={(e) => {
                            const selectedField = cityList.filter((i: any) => i.value == e)
                            formik.setFieldValue('cityPermanent', selectedField[0])
                        }}
                        placeholder={"Select City"}
                        value={formik.values.cityPermanent.value == '' ? "" : formik.values.cityPermanent}
                    />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                    <SelectField
                        col={12}
                        fieldName='provincePermanent'
                        fields={provinceList}
                        formik={formik}
                        label='Province'
                        onChange={(e) => {
                            const selectedField = provinceList.filter((i: any) => i.value == e)
                            formik.setFieldValue('provincePermanent', selectedField[0])
                        }}
                        placeholder={"Select Province"}
                        value={formik.values.provincePermanent.value == '' ? "" : formik.values.provincePermanent}
                    />
                </Grid>
            </Grid>

            <MailingFields formik={formik} />
        </>
    )
}

export default PermanentField