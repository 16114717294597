import { FormikErrors, FormikTouched } from 'formik';
import { memo, useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
// import { RadioGroup } from '@mui/material';
import '../Shared/styling.css';
import { Box, Grid, Typography } from '@mui/material';
import { specialRadioFieldChildGrid, specialRadioFieldChildGridV2 } from '../../components/Shared/style/specialRadioField';


const FormikRadioField = ({
  fieldName,
  fields,
  formik,
  label,
  nestedFieldName,
  required = false,
  singleLine = false
}: FormikRadioFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  let FieldError = "";
  let FieldTouch = false;
  let NestedFieldError = "";
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }


  const handleClick = (selectedOption: any) => {
    formik.setFieldValue(fieldName, selectedOption);
  }

  const textInput: any = useRef(null);

  return (
    <Col>
      <div className='form-group mb-0'>

        {label && <Row>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "6px" }}>
            <Typography variant='body2' color="#1D2939">
              {label}
              {required && <span className='ml-1 text-danger'>*</span>}
            </Typography>
          </Box>
        </Row>}

        {/* <Row>
          <div className={singleLine ? 'singleLine' : ''}>
            {fields.map(val => (
              <div key={val.label}>
                <Form.Check
                  key={val.label}
                  checked={formik.values[fieldName] === val.value}
                  className='radio-button-custom'
                  disabled={!!val.disabled}
                  id={val.label.toString()}
                  label={val.label}
                  name={fieldName}
                  onChange={formik.getFieldProps(fieldName).onChange}
                  style={{ display: 'flex', alignItems: 'center' }}
                  type='radio'
                  value={val.value}
                />
              </div>
            ))}
          </div>
        </Row> */}

        <Row>
          <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap", cursor: "pointer", gap: "8px" }}>
            {fields.map(val => {
              return (
                <Grid key={val.label} item onClick={() => handleClick(val.value)}>
                  <Box
                    onClick={() => handleClick(val.value)}
                    sx={{
                      ...specialRadioFieldChildGridV2,
                      textAlign: "center",
                      border: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "1px solid #422E84" : "1px solid rgba(208, 213, 221, 0.25)",
                      backgroundColor: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "#422E84" : "#FFF",

                      '& .form-check-label': {
                        color: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "#fff" : "#1D2939",
                        margin: 0,
                      }
                    }}
                  >
                    <Form.Check
                      ref={textInput}
                      key={val.label}
                      checked={formik.values[fieldName] === val.value}
                      className="specialRadio"
                      disabled={!!val.disabled}
                      id={val?.label?.toString() + label?.toString()}
                      label={val.label}
                      name={fieldName}
                      style={{ paddingLeft: 0 }}
                      type='radio'
                      value={val.value}
                    />
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Row>

        {FieldTouch && FieldError && (
          <Row>
            <span className='ml-2 mt-1 text-danger'>
              {FieldError}
            </span>
          </Row>
        )}
        {NestedFieldTouch && NestedFieldError && (
          <Row>
            <span className='ml-2 mt-1 text-danger'>
              {NestedFieldError}
            </span>
          </Row>
        )}
      </div>
    </Col>
  );
};

export default memo(FormikRadioField);

interface FormikRadioFieldProps {
  disabled?: boolean;
  fieldName: string;
  nestedFieldName?: string,
  fields: Array<{ label: string; value: string; disabled?: boolean }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
    setFieldValue?: any;
  };
  label?: string;
  required?: boolean;
  singleLine?: boolean;
}
