import { Box, Grid, Typography } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { formikRadioFieldStyle } from './style/formikRadioFieldStyle';
import { specialCheckBoxChildGrid, specialCheckBoxLabel } from './style/specialCheckBox';
// import { RadioGroup } from '@mui/material';
import './styling.css';

const FormikRadioField = ({
  col = 6,
  fieldName,
  fields,
  formik,
  label,
  nestedFieldName,
  required = false,
  singleLine = false
}: FormikRadioFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  let FieldError = '';
  let FieldTouch = false;
  let NestedFieldError = '';
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }

  return (

    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Typography sx={specialCheckBoxLabel}>
          {label} {required && <span className='ml-1 text-danger'>*</span>}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap", gap: "16px", pt: "24px !important", justifyContent: { md: "flex-start", xs: "center" } }}>

        {fields.map(val => (
          <Grid key={val.label} item >
            <Box onChange={formik.getFieldProps(fieldName).onChange}
              sx={{
                '& .specialCheckboxV2 label': {
                  border: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "1px solid #422E84" : "1px solid rgba(208, 213, 221, 0.25)",
                },
                '& .form-check-label': {
                  color: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "#422E84" : "#1D2939",
                },
                ...specialCheckBoxChildGrid,
              }}
            >
              <Form.Check
                key={val.label}
                checked={formik.values[fieldName] === val.value}
                className='specialCheckbox specialCheckboxV2'
                disabled={!!val.disabled}
                id={val?.label?.toString() + label?.toString()}
                label={val.label}
                name={fieldName}
                onChange={formik.getFieldProps(fieldName).onChange}
                type='radio'
                value={val.value}
              />
            </Box>
          </Grid>

        ))}

      </Grid>

      {FieldTouch && FieldError && (
        <Grid item xs={12}>
          <span className='ml-2 mt-1 text-danger'>{FieldError}</span>
        </Grid>
      )}

      {NestedFieldTouch && NestedFieldError && (
        <Grid item xs={12}>
          <span className='ml-2 mt-1 text-danger'>{NestedFieldError}</span>
        </Grid>
      )}

    </Grid>

  );
};

export default memo(FormikRadioField);

interface FormikRadioFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  nestedFieldName?: string;
  fields: Array<{ label: string; value: string; disabled?: boolean }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  label?: string;
  required?: boolean;
  singleLine?: boolean;
}
