import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { memo, useEffect, useState } from 'react';
import '../components/CustomDrawer/sidebar.scss'
import { onGetAccountSetup, UserDetails, onGetDashboard } from 'redux/actions';
import Loader from 'jsx/components/Shared/Loader';
import { onGetArticlesData, onGetMarketData, onGetNotifications } from 'redux/reducers/sharedStateData';
import FreshUserScreen from 'Latest/DashboardScreen/FreshUserScreen/FreshUserScreen';
import DashboardV1 from "Latest/DashboardScreenV1"
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import SubscribtionComp from 'Latest/SubscriptionComp/SubscriptionComp';
import { useHistory } from 'react-router-dom';
import { paramFinder } from 'utils/paramFInder';
import { links } from 'static/links';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const [paramsState,setParamState] = useState<string>("")

  const { user } = useAppSelector((state: any) => state.auth);
  const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
  const { articlesData } = useAppSelector((state: any) => state.sharedData);
  const {push} = useHistory();
  // const {endParam,startParam} = useAppSelector(state => state.profileParams)

  useEffect(() => {
    dispatch(UserDetails());
  }, []);

  useEffect(() => {
    if (user?.id) {
      dispatch(onGetAccountSetup());
      dispatch(onGetDashboard())
      dispatch(onGetMarketData())
      dispatch(onGetNotifications())
      dispatch(onGetArticlesData())
      // UseDailyAPICall(onGetMarketData())
    }
  }, [user?.id]);

  useEffect(() => {
    const startDate = paramFinder("startdate");
    const endDate = paramFinder("enddate");
    const value = paramFinder("value");

    if (dashboardDetail && user?.id) {
      if (value) {
        setParamState(value)
        if (paramsState==="5"){
          push(`${links.myprofile}?value=${value}&startdate=${startDate}&enddate=${endDate}`);
        }

      }
    }
  })

  return (
    <>
      {
        dashboardDetail.status === undefined || dashboardDetail.status === null || paramsState==="5" ?
          <Loader width={200} />
          :
          dashboardDetail && dashboardDetail?.status == "New" ?
            <FreshUserScreen />
            :
            dashboardDetail && (dashboardDetail?.status == "Verified" || dashboardDetail?.status == "UnVerified") &&
            <DashboardV1 Data={dashboardDetail} />
      }

    </>
  );
};

export default memo(Dashboard);