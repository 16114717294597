import { Col } from 'react-bootstrap';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { memo } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { Box, Typography } from '@mui/material';
const AuthPasswordField = ({
  autoFocus = false,
  bottomInfo,
  disabled = false,
  endAdornment,
  formik,
  isMutual = false,
  label,
  maxLength,
  minLength,
  name,
  nestedFieldName,
  onChange,
  placeholder,
  removeWhitespace = false,
  required = false,
  topInfo,
  type = 'text',
  value
}: AuthPasswordFieldProps) => {
  const formikTouch = formik.touched as any;
  const formikError = formik.errors as any;
  let fields: any = formik.getFieldProps(name);

  let modifiedValue: any = fields?.value;
  if (removeWhitespace) {
    modifiedValue = modifiedValue.replace(/\s/g, '');
  }
  fields = {
    ...fields,
    value: modifiedValue || ''
  }
  let FieldError = "";
  let FieldTouch = false;
  let NestedFieldError = "";
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[name?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[name?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[name];
    FieldTouch = formikTouch[name];
  }
  return (
    <Box >
      {label && (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "6px" }}>
          <Typography variant='body2' color="#1D2939">
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </Typography>
        </Box>
      )}

      <OutlinedInput
        // className='pass'
        inputProps={{
          autoComplete: 'new-password',
          maxlength: maxLength
        }}
        endAdornment={endAdornment}
        fullWidth={true}
        id="outlined-adornment-password"
        onChange={onchange}
        placeholder={placeholder}
        type={type}
        value={value}
        {...fields}
      />
      {bottomInfo && (
        <Box className='small-info mb-2 mt-1 text-muted d-flex'>{bottomInfo}</Box>
      )}

      {FieldTouch && FieldError && (
        <span className='mt-1 text-danger'>
          {FieldError}
        </span>
      )}

      {NestedFieldTouch && NestedFieldError && (
        <span className='mt-1 text-danger'>
          {NestedFieldError}
        </span>
      )}

    </Box>
  );
};
export default memo(AuthPasswordField);
interface AuthPasswordFieldProps {
  autoFocus?: boolean;
  disabled?: boolean;
  endAdornment: any;
  formik: FormikFieldProps;
  label: string | JSX.Element;
  maxLength?: number;
  minLength?: number;
  name: any;
  nestedFieldName?: any;
  onChange: any;
  placeholder: any;
  required?: boolean;
  topInfo?: string;
  type?: any;
  bottomInfo?: string | JSX.Element;
  isMutual?: boolean;
  value?: any;
  removeWhitespace?: boolean
}
export interface FormikFieldProps {
  getFieldProps: (field: string) => void;
  touched: FormikTouched<unknown>;
  errors: FormikErrors<unknown>;
  values: FormikValues;
}
