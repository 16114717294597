/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import UploadField from '../Shared/FileUploadField';

const NationalIdentityDetails = ({ formik, imageError, setImageError, setImages }: CnicProps) => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col lg={12}>
            <h3 className='text-center text-info mb-4'>Uploads</h3>
          </Col>
          <UploadField
            required
            acceptedType='image/*'
            buttonTitle="Profile Picture"
            col={4}
            fieldName="UserSelfie"
            formik={formik}
            imageError={imageError}
            maxFiles={1}
            setImageError={setImageError}
            setImages={setImages}
          />
          <UploadField
            required
            acceptedType='image/*'
            buttonTitle="Image of your ID Card front"
            col={4}
            fieldName="UserNICFront"
            formik={formik}
            imageError={imageError}
            maxFiles={1}
            setImageError={setImageError}
            setImages={setImages}
          />
          <UploadField
            required
            acceptedType='image/*'
            buttonTitle="Image of your ID Card back"
            col={4}
            fieldName="UserNICBack"
            formik={formik}
            imageError={imageError}
            maxFiles={1}
            setImageError={setImageError}
            setImages={setImages}
          />
          <UploadField
            required
            acceptedType='image/*,.pdf'
            buttonTitle="Zakat Declaration Form"
            col={4}
            fieldName="UserZakatDeclarationForm"
            formik={formik}
            imageError={imageError}
            maxFiles={1}
            setImageError={setImageError}
            setImages={setImages}
          />
          <UploadField
            required
            acceptedType='image/*'
            buttonTitle="Image of Next of Kin's ID Card back"
            col={4}
            fieldName="NextOfKinNICFront"
            formik={formik}
            imageError={imageError}
            maxFiles={1}
            setImageError={setImageError}
            setImages={setImages}
          />
          <UploadField
            required
            acceptedType='image/*'
            buttonTitle="Image of Next of Kin's ID Card back"
            col={4}
            fieldName="NextOfKinNICBack"
            formik={formik}
            imageError={imageError}
            maxFiles={1}
            setImageError={setImageError}
            setImages={setImages}
          />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default memo(NationalIdentityDetails);

interface CnicProps {
  formik: any;
  setImages: any;
  imageError: boolean;
  setImageError: (isImage: boolean) => void;
}
