const EmployerContribution: any =
{
    "employeeParticipation": {
        "payroll": "333111",
        "vpsContributions": "666555",
        "totalEmployeeParticipating": "15050",
        "matchContributions": "444666"
    },
    "employees": [
        {
            "name": "Aneela Kanwal",
            "contribution": "111",
            "contributionPercentage": "20",
            "matchContribution": "111",
            "matchContributionPercentage": "25"
        },
        {
            "name": "Omair Ahmed",
            "contribution": "222",
            "contributionPercentage": "30",
            "matchContribution": "444",
            "matchContributionPercentage": "45"
        },
        {
            "name": "Omer Javed",
            "contribution": "666",
            "contributionPercentage": "63",
            "matchContribution": "785",
            "matchContributionPercentage": "68"
        }
    ]
}

export default EmployerContribution;