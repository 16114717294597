import * as Yup from 'yup';
import { passwordSchema } from '../../constants';
import { isValidPhoneNumber } from 'react-phone-number-input'

function calculateAge(birthday: any) {
  const ageDifMs = Date.now() - birthday;
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
export const signup = Yup.object({
  // birthDate: Yup.date().required().label('Date of birth').max(new Date(), "Age should be less then today"),
  // dateOfBirth: Yup.date()
  //   .required(`Date of birth is required`)
  //   .typeError('Invalid date')
  //   .test("birthday", "You should be atleast 18 years old", function (val: any) {
  //     return calculateAge(new Date(val)) > 18;
  //   }),

  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], 'Passwords do not match')
    .label('Password Confirmation'),
  email: Yup.string().email().required().label('Email'),
  name: Yup.string()
    .matches(
      /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
      'Name must contain only alphabetic value'
    )
    .required().label('Name'),
  // lastName: Yup.string()
  //   .matches(
  //     /^[a-zA-Z]+$/,
  //     'Name must contain only alphabetic value'
  //   )
  //   .required().label('Last Name'),
  phoneNumber: Yup.string().required('Mobile number is required').test('require', '', function (value, context) {
    const val = value;
    const isValid = isValidPhoneNumber(`+${String(val)}`)
    if (!isValid) {
        return this.createError({
            message: "Mobile number is Invalid"
        });
    }
    return true;
}),/*.length(12, 'Mobile number is not valid'),*/
  password: Yup.string()
    .matches(
      passwordSchema, 
      'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character.'
    ).required().min(8).label('Password')
});

export const changePasswordSchema = Yup.object({
  email: Yup.string().required().label('Email'),
  currentPassword: Yup.string().required().label('Current Password'),
  confirmNewPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword')], 'Password does not match')
    .label('Password Confirmation'),
  newPassword: Yup.string()
    .matches(
      passwordSchema, 
      'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character.'
    ).required().min(8).label('Password')
});