export const genderParser = (status: number) => {
    switch (status) {
        case 0:
            return "Female"
        case 1:
            return "Male"
        case 2:
            return "other"
        default:
            break;
    }
};

export const maritalStatusParser = (status: number) => {
    switch (status) {
        case 0:
            return "Unmarried"
        case 1:
            return "Married"
        default:
            break;
    }
};

export const employmentStatusParser = (status: number) => {
    switch (status) {
        case 0:
            return "FullTime"
        case 1:
            return "PartTime"
        case 2:
            return "Freelancer"
        case 3:
            return "Contract"
        case 4:
            return "Internship"
        case 5:
            return "Apprenticeship"
        case 6:
            return "Seasonal"
        default:
            break;
    }
};