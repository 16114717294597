import { Grid } from '@mui/material'
import CFProfile from "./QuestionProfile/CFProfile"
import BankDetails from "./BankDetails"
import PersonalDetails from "./PersonalDetail"
import IDCard from "./IDCard"
import CustomTab from 'Latest/ComponentV1/CustomTab'
import Settings from 'Latest/Settings'
import TabsHeading from 'Latest/ComponentV1/TabsHeading'
import AccountStatement from "Latest/AccountStatement"
import Dividend from './Dividend'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect } from 'react'
import { onGetDashboard } from 'redux/actions'



const Index = () => {
    const dispatch = useAppDispatch();
    const { userAccountDetails } = useAppSelector((state:any) => state.auth);
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);


    const filterDividendPayout = userAccountDetails?.alerts && userAccountDetails?.alerts.filter((x: any) => x?.type?.includes('DividendPayout'))
    const brokerType: any = dashboardDetail?.goals?.some((x: any) => x?.brokerType?.toLowerCase() === "cdc");
    // const currentDate = moment();
    const date =  filterDividendPayout?.length && filterDividendPayout[0]?.details?.EndingDate
    // const EndingDate = moment(date, 'YYYY-MM-DD')

    const contentTabs = [
        { label: 'Personal Details', value: "PersonalDetails", comp: <PersonalDetails /> },
        // {  label: 'Risk Profile', value: "RiskProfile" },
        { label: 'Risk Profile', value: "CashFundProfile", comp: <CFProfile /> },
        { label: 'Bank Details', value: "BankDetails", comp: <BankDetails /> },
        { label: 'Documents', value: "IDCard", comp: <IDCard /> },
        { label: 'Update password', value: "Settings", comp: <Settings /> },
        { label: 'Account Statement', value: "AccountStatement", comp: <AccountStatement /> },
        // { label: 'Dividend', value: "Dividend", comp: <Dividend /> },
    ]

    if ((filterDividendPayout && filterDividendPayout?.length > 0 )  && brokerType) {
        contentTabs.push({ label: 'Dividend', value: "Dividend", comp: <Dividend /> });
    }

    useEffect(() => {
        if (!dashboardDetail) {
            dispatch(onGetDashboard())
        }
    }, [])


    return (
        <Grid container>
            <TabsHeading heading='My Profile' />
            <CustomTab contentTabs={contentTabs} />
        </Grid>
    )
}

export default Index