import { Box, Button, CircularProgress, Grid, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import settingCircleIcon from "Latest/Images/svg/settingCircleIcon.svg"
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom'
import VerticalLinearStepper from './dummyStepper'
import copyicon from "Latest/Images/svg/copyIconWhite.svg"
import infoIcon from "Latest/Images/svg/infoIcon2.svg"
import Checkmark from 'react-typescript-checkmark'
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack'
import { useHistory } from 'react-router-dom'
import { CurrencyFormatterWithDecimal } from 'utils/calculation'
import { useAppLoading } from 'hooks/appLoading'
import { useAppSelector } from 'hooks/reduxHooks'


const impContent = [
    "Transfer must be made from a bank account that's in your name",
    "Please contact support via our livechat in case of any issues"
]

const GenerateIdViewRaast = ({ categoryAcc, formik, goalId, originalAmt, previousStep, raastId }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { push } = useHistory();
    const { userAccountDetails } = useAppSelector(state => state.auth);

    const loading = useAppLoading('onGenerateRaastId');

    const [textToCopy, setTextToCopy] = useState(false)

    const handleSaveClipBoard = async (copyMe: string) => {
        await navigator.clipboard.writeText(copyMe)
        setTextToCopy(true)
        setTimeout(() => {
            setTextToCopy(false)
        }, 3000);
    }

    const buttonHtml = () => {
        return (
            <>
                {previousStep && <LoadingBtnCustomBack handleOnClick={previousStep} btnTitle={"Back"} />}
                <LoadingBtnCustom handleOnClick={() => push("/")} btnTitle={"Go to dashboard"} loading={loading} fullWidth={smScreen} />
            </>
        )
    }

    return (
        <>

            <Box sx={{ pt: { xs: "8px", md: "8px" } }}>
                {/* <Typography variant='h1' color={"#422E84"} sx={{ padding: "12px 0px 16px 0px" }}>Make a Raast transfer from your bank app</Typography> */}

                <Grid container spacing={2}>


                    <Grid item xs={12} md={6} sx={{
                        display: 'flex', flexDirection: "column",
                        justifyContent: "space-between", gap: { xs: "8px", md: "24px" }, py: { xs: "16px", md: 0 }
                    }}>

                        <Box>
                            <Typography variant='h5' color={"#1D2939"} pb="8px">Your Raast Investment IBAN generated by <a href="https://www.cdcpakistan.com/"
                                style={{ textDecorationLine: "underline", color: "#422E84" }} target="_blank" rel="noreferrer"
                            >CDC:</a></Typography>


                            <Box sx={{ padding: { xs: "16px", md: "32px 16px" }, background: "#422E84", borderRadius: "8px" }}>

                                <Box>
                                    <Typography variant='body2' color={"#EFECF8"} fontSize={"14px"} pb="2px">Account title</Typography>

                                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", gap: "8px" }}>
                                        <Typography variant='h1' color={"#FFF"} sx={{ wordBreak: "break-all" }}>MICP - {userAccountDetails?.identityCard?.name}</Typography>
                                    </Box>

                                </Box>

                                <Box pt="24px">
                                    <Typography variant='body2' color={"#EFECF8"} fontSize={"14px"} pb="2px">Raast Investment IBAN</Typography>

                                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", gap: "8px" }}>
                                        <Typography variant='h1' color={"#FFF"} sx={{ wordBreak: "break-all" }}>{raastId}</Typography>
                                        {textToCopy ?
                                            <Box sx={{ backgroundColor: "#e8ebec", borderRadius: "8px" }}>
                                                <Checkmark size='md' backgroundColor={"transparent"} checkColor={"green"} />
                                            </Box>
                                            :
                                            <img src={copyicon} style={{ cursor: "pointer" }} onClick={() => handleSaveClipBoard(raastId)} />
                                        }
                                    </Box>
                                </Box>

                            </Box>
                        </Box>

                        <Box sx={{ padding: { xs: "16px", md: "32px 16px" }, background: "#F2F4F7", borderRadius: "6px" }}>
                            <Typography variant='body2' color={"#666"} pb="2px" textAlign={smScreen ? "center" : "left"}>Transfer amount</Typography>
                            <Typography fontSize={"24px!important"} fontWeight={600} color={"#403081"} textAlign={smScreen ? "center" : "left"}>{CurrencyFormatterWithDecimal(originalAmt, true, "never", false)}</Typography>
                        </Box>

                        <Typography variant='h5' color={"##1D2939"} fontWeight={500}
                            sx={{ p: { xs: "8px 16px 8px", md: "0px 16px 32px" }, border: { xs: "1px solid rgba(208, 213, 221, 0.25)", md: "0px" }, borderRadius: "8px" }} >
                            You’ll receive a confirmation email once you transact.</Typography>

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <VerticalLinearStepper />
                    </Grid>



                    <Grid item xs={12} md={12} sx={{ pt: "24px" }}>

                        <Box sx={{ border: "1px solid rgba(208, 213, 221, 0.25)", background: "#EAF4FF", p: "12px 16px", borderRadius: "8px", }}>

                            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                <img src={infoIcon} />
                                <Typography variant='h5' color="#0A3F7A" >Important</Typography>
                            </Box>

                            <Box sx={{ pt: "8px" }}>
                                {impContent?.map((item: string, index: number) => {
                                    return (
                                        <li key={index} style={{ listStyle: "disc", color: "#1D2939B2", fontSize: "14px" }}>
                                            {item}
                                        </li>
                                    )
                                })}
                            </Box>

                        </Box>
                    </Grid>




                </Grid>
                {<Box sx={{ mt: { xs: "24px", md: "40px" }, display: "flex", justifyContent: "center", gap: "8px" }}>
                    {buttonHtml()}
                </Box>}
            </Box>


        </>
    )
}

export default GenerateIdViewRaast