import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import HeaderText from 'jsx/components/DocumentsUpload/HeaderText';
import NationalIdentityDetails from 'jsx/components/DocumentsUpload/NationalIdentityDetails';
import AccountConfirmation from 'jsx/components/Shared/AccountConfirmation';
import { memo, useCallback, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import {
    onClearSignupProcess,
    onGetAccountProfile,
    onSubmitAccountSetup
} from 'redux/actions';
import {
    onGetCities,
    onGetCountries,
    onGetOccupations,
    onGetSourcesOfIncome,
    onGetMobileNetworks,
    onGetBanks,
    onGetNationalities
} from 'redux/reducers/sharedStateData';
import { mutualAccountForm } from 'validate/mutual-account/form';
import { useLocation } from 'react-router-dom';
import { useAppLoading } from 'hooks/appLoading';
import Loader from 'jsx/components/Shared/Loader';

const DocumentsUpload = () => {
    const { state }: any = useLocation();
    const dispatch = useAppDispatch();
    const [images, setImages] = useState([]);
    const [countryCode, setCountryCode] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('');
    const [residentialOfficeNumberError, setResidentialOfficeNumberError] = useState('');
    const [kinMobileNumberError, setKinMobileNumberError] = useState('');
    const [imageError, setImageError] = useState(false);
    const [displayAccountConfirmation, setDisplayAccountConfirmation] = useState(false);
    const loading = useAppLoading('auth');

    // useEffect(() => {
    // }, [dispatch]);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            UserSelfie: '',
            UserNICFront: '',
            UserNICBack: '',
            UserZakatDeclarationForm: '',
            NextOfKinNICFront: '',
            NextOfKinNICBack: '',
        },
        onSubmit: async (values: any) => {
            if (!values.UserNICFront) {
                toast.error('User CNIC front is required',{theme: "colored"});
                return;
            }
            if (!values.UserNICBack) {
                toast.error('User CNIC back is required',{theme: "colored"});
                return;
            }
            if (!values.UserZakatDeclarationForm) {
                toast.error('Zakat declaration form is required',{theme: "colored"});
                return;
            }
            if (!values.NextOfKinNICFront) {
                toast.error("Next of Kin's CNIC front is required",{theme: "colored"});
                return;
            }
            if (!values.NextOfKinNICBack) {
                toast.error("Next of Kin's CNIC back is required",{theme: "colored"});
                return;
            }
            const formData = new FormData();
            for (const key in values) {
                // formData.append(key, values[key]);
            }
            //   dispatch(onSubmitAccountSetup(data));
        },
        // validationSchema: mutualAccountForm
    });


    return (
        <>
            {/* <ToastContainer /> */}
            {state && <Alert variant={'danger'}>Please complete your account.</Alert>}
            {loading ? (
                <div className='text-center'>
                    <Loader width={200} />
                </div>
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <HeaderText />
                    <NationalIdentityDetails
                        formik={formik}
                        imageError={imageError}
                        setImageError={setImageError}
                        setImages={setImages}
                    />
                    <div className='text-right'>
                        <Button
                            // disabled={
                            //   phoneError.length > 0 ||
                            //   preferredContactNumberError.length > 0 ||
                            //   kinMobileNumberError.length > 0
                            // }
                            type='skip'
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            )}
        </>
    );
};

export default memo(DocumentsUpload);
