import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import FormikInputField from 'jsx/components/Shared/FormikInputField';
import FormikDatePicker from 'jsx/components/Shared/FormikDatePicker';
import FormikSelectField from 'jsx/components/Shared/FormikSelectField';
import FormikSelectFieldWithLabel from 'jsx/components/Shared/FormikSelectFieldWithLabel';
import axios from 'axios';
import { employmentStatus, gender, maritalStatus } from 'constants/dropdownValues';
import { useDispatch } from 'react-redux';
import { onGetCountries, onGetCountryStates, onGetEmployee, onGetOccupations, onGetSourcesOfIncome } from 'redux/reducers/sharedStateData';
import { useAppSelector } from 'hooks/reduxHooks';
const baseUrl = "https://dev-mahaana-dfa-accountmanagement.azurewebsites.net/api";

export default function EmployeeDetailsModal({
    formik,
    isAddEmployeeModalOpen,
    setAddEmployeeModalOpen
}: any) {
    const [occupationsList, seoOccupationsList] = useState<any>([]);
    const [incomeSourcesList, setIncomeSourcesList] = useState<any>([]);
    const [countriesList, setCountriesList] = useState([]);
    const { employee } = useAppSelector(state => state.sharedData)
    const [statesList, setStatesList] = useState([{ label: "Empty", value: "" }]);
    const [citiesList, setCitiesList] = useState([{ label: "Empty", value: "" }]);
    const dispatch = useDispatch();
    const { countries, countryStates, occupations, sourcesOfIncome } = useAppSelector(state => state.sharedData)
    const handleDateChange = (date: Date) => {
        formik.setFieldValue('birthDate', date);
    };

    useEffect(() => {
        dispatch(onGetSourcesOfIncome());
        dispatch(onGetCountries());
        fetchCountries();
        fetchOccupations();
        fetchIncomeSources();
    }, [dispatch]);


    const fetchOccupations = async () => {
        // const resp = await axios.get(`${baseUrl}/Occupation`);
        dispatch(onGetOccupations());

        const occupationsList_: any = [];
        occupations?.map((item: any, index: any) => {
            occupationsList_.push({
                label: item.name,
                value: item.id
            })
        })
        seoOccupationsList(occupations);
    }

    const fetchIncomeSources = async () => {
        // const resp = sourcesOfIncome;
        // const incomeSourcesList_: any = [];
        // resp?.data?.incomeSources?.map((item: any, index: any) => {
        //     incomeSourcesList_.push({
        //         label: item.name,
        //         value: item.id
        //     })
        // })
        setIncomeSourcesList(sourcesOfIncome);
    }

    const fetchCountries = async () => {
        // const resp = await axios.get(`${baseUrl}/country`);

        const countriesList_: any = [];
        countries?.map((item: any, index: any) => {
            countriesList_.push({
                label: item.label,
                value: item.value
            })
        })
        setCountriesList(countriesList_);
        if (formik.values.country) {
            fetchStates(formik.values.country)
        }
    }

    const fetchStates = async (country: string) => {
        setCitiesList([{ label: "Empty", value: "" }]);
        setStatesList([{ label: "Empty", value: "" }]);
        // const resp = await axios.get(`${baseUrl}/country/${country}/states`);        
        dispatch(onGetCountryStates(country))
        const statesList_: any = [];
        countryStates?.map((item: any, index: any) => {
            statesList_.push({
                label: item.name,
                value: item.id
            })
        })
        formik.setFieldValue('country', country);
        setStatesList(statesList_);
        if (formik.values.state) {
            fetchStates(formik.values.state)
        }
    }

    const fetchCities = async (state: any) => {
        setCitiesList([{ label: "Empty", value: "" }]);
        // const resp = await axios.get(`${baseUrl}/state/${state}/cities`);
        
        const citiesList_: any = [];
        countryStates?.map((item: any, index: any) => {
            citiesList_.push({
                label: item.name,
                value: item.id
            })
        })
        formik.setFieldValue('state', state);
        setCitiesList(citiesList_);
    }

    return (
        <>
            <Modal
                centered
                aria-labelledby='contained-modal-title-vcenter'
                // onHide={closeInvite}
                show={isAddEmployeeModalOpen}
                size="lg"
            >
                <Modal.Header>Please Enter Employee's Information</Modal.Header>
                <Modal.Body className=''>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='form-group'>
                            <Row>
                                <FormikInputField
                                    col={6}
                                    formik={formik}
                                    label='Full Name'
                                    name='name'
                                    placeholder='Enter Full Name'
                                    required={true}
                                />
                                <FormikInputField
                                    col={6}
                                    formik={formik}
                                    label='National Identity Number'
                                    name='nationalIdentityNumber'
                                    placeholder='Enter CNIC No.'
                                    required={true}
                                />
                            </Row>
                            <Row>
                                <FormikSelectField
                                    col={4}
                                    fieldName='gender'
                                    fields={gender}
                                    formik={formik}
                                    label='Select Gender'
                                    onChange={e => formik.setFieldValue('gender', e)}
                                    required={true}
                                />
                                <FormikSelectField
                                    col={4}
                                    fieldName='maritalStatus'
                                    fields={maritalStatus}
                                    formik={formik}
                                    label='Marital Status'
                                    onChange={e => formik.setFieldValue('maritalStatus', e)}
                                    required={true}
                                />
                                <FormikDatePicker
                                    col={4}
                                    formik={formik}
                                    handleDateChange={handleDateChange}
                                    label='Date of Birth'
                                    name='birthDate'
                                    required={true}
                                />
                            </Row>
                            <Row>
                                <FormikSelectFieldWithLabel
                                    col={4}
                                    fieldName='occupation'
                                    fields={occupationsList}
                                    formik={formik}
                                    label='Occupation'
                                    onChange={e => formik.setFieldValue('occupation', e)}
                                    required={true}
                                />
                                <FormikSelectFieldWithLabel
                                    col={4}
                                    fieldName='incomeSource'
                                    fields={incomeSourcesList}
                                    formik={formik}
                                    label='Income Source'
                                    onChange={e => formik.setFieldValue('incomeSource', e)}
                                    required={true}
                                />
                                <FormikSelectField
                                    col={4}
                                    fieldName='employmentStatus'
                                    fields={employmentStatus}
                                    formik={formik}
                                    label='Employment Status'
                                    onChange={e => formik.setFieldValue('employmentStatus', e)}
                                    required={true}
                                />
                            </Row>
                            <Row>
                                <FormikSelectFieldWithLabel
                                    col={4}
                                    fieldName='country'
                                    fields={countriesList}
                                    formik={formik}
                                    label='Country'
                                    onChange={(e) => fetchStates(e)}
                                    required={true}
                                />
                                <FormikSelectFieldWithLabel
                                    col={4}
                                    disabled={statesList[0]?.value === ""}
                                    fieldName='state'
                                    fields={statesList}
                                    formik={formik}
                                    label='State'
                                    onChange={(e) => fetchCities(e)}
                                    required={true}
                                />
                                <FormikSelectFieldWithLabel
                                    col={4}
                                    disabled={citiesList[0]?.value === ""}
                                    fieldName='city'
                                    fields={citiesList}
                                    formik={formik}
                                    label='City'
                                    onChange={e => formik.setFieldValue('city', e)}
                                    required={true}
                                />
                            </Row>
                            <Row>
                                <FormikInputField
                                    col={12}
                                    formik={formik}
                                    label='Address'
                                    name='address'
                                    placeholder='Enter Address'
                                    required={true}
                                />
                            </Row>
                        </div>
                        <Button className='mr-2' type='submit'>
                            Submit
                        </Button>
                        <Button onClick={() => setAddEmployeeModalOpen(false)} variant='dark'>
                            Cancel
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
