import { useAppLoading } from 'hooks/appLoading';
import FormikInputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import AppSelectWithLabel from 'jsx/components/Shared/AppSelectWithLabel';
import { InputCategoryEnum, FieldPattern } from '../../../../constants';
import FormikBooleanButtonsField from 'jsx/components/Shared/V1/FormikBooleanBtnFieldBoxV1';
import FormikCheckboxField from 'jsx/components/Shared/V1/FormikCheckboxFieldV1';
import { Box, Grid } from '@mui/material';
// import FormikRadioField from 'jsx/components/Shared/V1/FormikRadioFieldV1';
import FormikRadioField from 'jsx/components/Shared/V2/FormikRadioFieldV2';
import FormikCustomSelectFieldV2 from 'jsx/components/Shared/V2/FormikCustomSelectFieldV2';

const newInputStyles = {
    '& .text-box-custom': { border: "1px solid rgba(208, 213, 221, 0.25)" },
    '& .input-end': { color: "rgba(29, 41, 57, 0.70)", fontSize: "14px", fontWeight: 400, lineHeight: "normal", letterSpacing: "1px", }
}

export default function DynamicQuestionAnswersV2({ formik, nestedQuestion, questionAnswers }: AccountSetupFormProps) {
    const loading = useAppLoading('auth');

    const handleType = (pattern: string) => {
        if (pattern === FieldPattern.Number) {
            return 'number';
        } else if (pattern === FieldPattern.Date) {
            return 'date';
        } else if (pattern === FieldPattern.TextArea) {
            return 'textArea';
        } else {
            return 'text';
        }
    };

    const TextBox_ = (item: any) => {
        return (
            <Grid maxWidth={"572px"} sx={newInputStyles}>
                <FormikInputField
                    col={12}
                    formik={formik}
                    label={item.question}
                    maxLength={50}
                    inputEnd={"PKR"}
                    name={item.id}
                    placeholder='Enter here...'
                    thousandSeparator={true}
                    type={handleType(item.pattern)}
                />
            </Grid>
        );
    };

    const TextBoxNested_ = (parentItem: any, nestedQuestion: any) => {
        const questionId = parentItem?.id
        if (formik.values[`${questionId}-nested`] == undefined) {
            formik.setFieldValue(`${questionId}-nested`, "");
        }

        return (
            <FormikInputField
                required
                col={12}
                formik={formik}
                maxLength={50}
                name={`${parentItem?.id}-nested`}
                placeholder='Enter description...'
                label={nestedQuestion.question}
                type={handleType(nestedQuestion.pattern)}
                rows={5}
            />
        );
    };

    const BooleanButtonsField = (item: any) => {
        if (formik.errors[item.id] && formik.values[item.id] != "") {
            formik.setFieldError(item.id, '');
        }

        return (
            <FormikBooleanButtonsField
                required
                // singleLine={item?.answer?.answerOptions?.length == 2}
                singleLine={true}
                col={12}
                fieldName={item.id}
                fields={item.answer.answerOptions}
                formik={formik}
                label={item.question}
            />
        );
    };

    const BooleanButtonsFieldNested_ = (parentItem: any, nestedQuestion: any) => {
        const questionId = parentItem?.id
        if (formik.values[`${questionId}-nested`] == undefined) {
            formik.setFieldValue(`${questionId}-nested`, "");
        }
        return (
            <FormikBooleanButtonsField
                singleLine={false}
                col={12}
                fieldName={`${parentItem?.id}-nested`}
                fields={nestedQuestion?.answerOptions}
                formik={formik}
                // label={''}
                style={{ mt: "20px" }}
                label={nestedQuestion.question}
            />
        );
    };

    const RadioButton_ = (item: any) => {
        if (formik.errors[item.id] && formik.values[item.id] != "") {
            formik.setFieldError(item.id, '');
        }
        return (
            <FormikRadioField
                // singleLine={item?.answer?.answerOptions?.length == 2}
                singleLine={true}
                col={12}
                fieldName={item.id}
                fields={item.answer.answerOptions}
                formik={formik}
                label={item.question}
            />
        );
    };

    const RadioButtonNested_ = (parentItem: any, nestedQuestion: any) => {
        const questionId = parentItem?.id
        if (formik.values[`${questionId}-nested`] == undefined) {
            formik.setFieldValue(`${questionId}-nested`, "");
        }
        return (
            <FormikRadioField
                singleLine={false}
                col={12}
                fieldName={`${parentItem?.id}-nested`}
                fields={nestedQuestion?.answerOptions}
                formik={formik}
                label={''}
            />
        );
    };

    const CheckBox_ = (item: any) => {
        if (formik.errors[item.id] && formik.values[item.id] != "") {
            formik.setFieldError(item.id, '');
        }
        return (
            <FormikCheckboxField
                required
                fieldName={item.id}
                fields={item.answer.answerOptions}
                formik={formik}
                label={item.question}
            />
        );
    };

    const CheckboxNested_ = (parentItem: any, nestedQuestion: any) => {
        const questionId = parentItem?.id
        if (formik.values[`${questionId}-nested`] == undefined) {
            formik.setFieldValue(`${questionId}-nested`, []);
        }
        return (
            <FormikCheckboxField
                singleLine={false}
                col={12}
                fieldName={`${parentItem?.id}-nested`}
                fields={nestedQuestion?.answerOptions}
                formik={formik}
                label={''}
                style={{ ml: "2px" }}
            />
        );
    };

    const CheckboxNestedV2_ = (parentItem: any, nestedQuestion: any) => {
        const questionId = parentItem?.id
        if (formik.values[`${questionId}-nested`] == undefined) {
            formik.setFieldValue(`${questionId}-nested`, []);
        }
        return (
            <FormikCustomSelectFieldV2
                singleLine={false}
                col={12}
                fieldName={`${parentItem?.id}-nested`}
                fields={nestedQuestion?.answerOptions}
                formik={formik}
                label={''}
                style={{ ml: "2px" }}
            />
        );
    };

    const DropDown_ = (item: any) => {
        return (
            <AppSelectWithLabel
                required
                col={6}
                fieldName={item.id}
                fields={item.answer.answerOptions}
                formik={formik}
                label={item.question}
                // loading={loading}
                onChange={(val, option) => {
                    formik.setFieldValue(item.id, val);
                }}
                placeholder={`Select ${item.question}`}
            />
        );
    };

    return (
        <>

            {questionAnswers?.answer?.answerFieldType === InputCategoryEnum.TextBox && TextBox_(questionAnswers)}
            {questionAnswers?.answer?.answerFieldType === InputCategoryEnum.BooleanButtons && BooleanButtonsField(questionAnswers)}
            {questionAnswers?.answer?.answerFieldType === InputCategoryEnum.RadioButton && RadioButton_(questionAnswers)}
            {questionAnswers?.answer?.answerFieldType === InputCategoryEnum.CheckBox && CheckBox_(questionAnswers)}
            {questionAnswers?.answer?.answerFieldType === InputCategoryEnum.DropDownSelection && DropDown_(questionAnswers)}

            {nestedQuestion?.item?.answerOptions && nestedQuestion.activeStep == questionAnswers.id && (
                <>
                    <Box sx={{ pt: "20px" }}>
                        {nestedQuestion?.item?.answerFieldType === InputCategoryEnum.TextBox && TextBoxNested_(questionAnswers, nestedQuestion?.item)}
                        {nestedQuestion?.item?.answerFieldType === InputCategoryEnum.BooleanButtons && BooleanButtonsFieldNested_(questionAnswers, nestedQuestion?.item)}
                        {nestedQuestion?.item?.answerFieldType === InputCategoryEnum.RadioButton && RadioButtonNested_(questionAnswers, nestedQuestion?.item)}
                        {nestedQuestion?.item?.answerFieldType === InputCategoryEnum.CheckBox && CheckboxNestedV2_(questionAnswers, nestedQuestion?.item)}
                        {/* {nestedQuestion?.item?.answerFieldType === InputCategoryEnum.CheckBox && CheckboxNested_(questionAnswers, nestedQuestion?.item)} */}
                    </Box>
                </>

            )}

        </>
    );
}

interface AccountSetupFormProps {
    questionAnswers: any;
    formik?: any;
    nestedQuestion?: any;
}
