import { Box } from '@mui/material';
import React from 'react'
import { Form } from 'react-bootstrap'

const labelStyle = {
    display: 'flex',
    alignItems: 'center',
    fontStyle: "normal",
    fontWeight: 400,
    color: "#1D2939",
}

const newErrStyles = {
    color: "#E8655A",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
}

const CustomSingleCheckBox = ({ containerStyleCustom, fieldName, formik, label, styleErr ,styleForm }: any) => {
    const formikTouch = formik.touched as never;
    const formikError = formik.errors as never;


    const containerStyles = {
        borderRadius: "8px", backgroundColor: "rgba(208, 213, 221, 0.25)",
        border: formikError[fieldName] && formikTouch[fieldName] ? "1px solid #E8655A" : "none",
        padding: "8px 16px",
        fontSize: { md: "16px", xs: "14px" },
        ...containerStyleCustom
    }

    return (
        <Box width={"100%"} sx={containerStyles}  className="v2SingleCheckBox">

            <Form.Check
                checked={formik.values[fieldName]}
                id={`${fieldName}`}
                label={label}
                className="sameAsAddressCheckBox"
                onChange={formik.getFieldProps(fieldName).onChange}
                type={"checkbox"}
                style={{ ...labelStyle, ...styleForm }}
            />

            <span className='text-danger' style={{ ...newErrStyles, ...styleErr }}>
                {formikTouch[fieldName] && formikError[fieldName]}
            </span>

        </Box>
    )
}

export default CustomSingleCheckBox