import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useStylesAlertInfo = makeStyles((theme) => ({
    customAlert: {
        // borderRadius: "4px",
        // border: "1px solid #84CAFF",
        // background: "#EFF8FF",

        padding: "1rem !important",
        border: "1px solid #D0D5DD40 !important",
        backgroundColor: "#EAF4FF !important",
        '& .MuiAlert-message': {
            padding: 0
        },
        '& .MuiAlert-action': {
            paddingTop: 0
        }
    },
    customAlertTitle: {
        color: "#175CD3",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
        letterSpacing: "0.15px",
        display: "flex",
        alignItems: "center",
        gap: "6px",
        marginBottom: '8px !important'
    },
    customText: {
        color: "#175CD3",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
    },
    customIcon: {
        color: '#fff', // Change the icon color here
      },
}));

export default useStylesAlertInfo;