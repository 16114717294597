import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { onGetTransactionDetail } from 'redux/reducers/sharedStateData';
import { useAppDispatch } from 'hooks/reduxHooks';
import TransactionHistoryTable from './TransactionHistoryTable';
import TransactionHistoryTableMobile from './TransactionHistoryTableMobile';
import { tokens } from 'theme';
import TabsHeading from 'Latest/ComponentV1/TabsHeading';
import { ManipulateTransactionList } from 'utils/transactionFilterForBonus';

const TransactionHistory = ({ Data }: any) => {

  const dispatch = useAppDispatch();
  // const loading = useAppLoading('sharedStateData');
  const [transactionList, setTransactionList] = useState([])

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true)

  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    getTransactionHistory()
  }, [Data])

  const getTransactionHistory = () => {

    const onSuccess = (message: any, response: any) => {

      const newTransactions =  ManipulateTransactionList(message)
   
      setTransactionList(newTransactions)
      setLoading(false)
    }

    const onFail = (message: string, response: any) => {
      console.log(message, response.status, "OPOOP")
      setTransactionList([])
      setLoading(false)
    }
    const param = {
      id: ""
    }
    dispatch(onGetTransactionDetail(param, onSuccess, onFail))
  }


  return (
    <>
      <TabsHeading heading='Transactions' />

      {smScreen ?
        <Box sx={{ px: '16px', background: 'white' }}>
          <TransactionHistoryTableMobile lists={transactionList} loading={loading} />
        </Box>
        :
        <TransactionHistoryTable lists={transactionList} loading={loading} />
      }
    </>

  )
}

export default TransactionHistory