import { useAppSelector } from 'hooks/reduxHooks';
import Dashboard from 'jsx/pages/EmployerDashboard/Dashboard';
import DashboardEmployees from 'jsx/pages/EmployerDashboard/DashboardEmployees';
import EmploerDashboardSideBar from 'jsx/pages/EmployerDashboard/EmploerDashboardSideBar';
import EmployerContributions from 'jsx/pages/EmployerDashboard/EmployerContributions';
import EmployerInvestments from 'jsx/pages/EmployerDashboard/EmployerInvestments';
import Error404 from 'jsx/pages/Error404';
import { memo, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route, Switch, useHistory } from 'react-router-dom';
import { links } from 'static/links';

const EmployerDashboard = () => {
  const { isAuth, scopes } = useAppSelector(state => state.auth);
  const { push } = useHistory();
  return (
    <div className='mh100vh'>
      <div>
        <div style={{ minHeight: window.screen.height - 60 }}>
          <Row>
            
            <Col lg={12}>
              <Switch>
                <Route
                  exact
                  path={links.employerDashboard}
                  render={() => <Dashboard />}
                />
                <Route
                  exact
                  path={links.employerEmployees}
                  render={() => <DashboardEmployees />}
                />
                <Route
                  exact
                  path={links.employerContributions}
                  render={() => <EmployerContributions />}
                />
                <Route
                  exact
                  path={links.investments}
                  render={() => <EmployerInvestments />}
                />
                <Route
                  path='*'
                  render={() => (
                    <Error404 pageName='Dashboard' url={links.home} />
                  )}
                />
              </Switch>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default memo(EmployerDashboard);
