const colorDark = {
    primary: {
        1: "#43BED8",
        25: "#16102D",
        50: "#211743",
        100: "#2D1F5A",
        200: "#382770",
        300: "#432F87",
        400: "#62529B",
        500: "#8274AF",
        600: "#A197C3",
        700: "#C0BAD7",
        750: "#6941C6",
        800: "#D9D5E7",
        900: "#FCFAFF",
    },
    grey: {
        5:'#1D2939B2',
        10:'rgba(29, 41, 57, 1)',
        15:'#DEE3E7',
        20:'#272E35',
        25: "#101828",
        50: "#1D2939",
        100: "#344054",
        200: "#475467",
        300: "#667085",
        400: "#98A2B3",
        500: "#D0D5DD",
        550: "#6B6F80",
        600: "#EAECF0",
        700: "#F2F4F7",
        800: "#F9FAFB",
        900: "#FCFCFD",
    },
    greyMuted: {
        1: "#DEE3E7"
    },
    greyPrimary: {
       1: "#272E35"
    },
    greyIron: {
        500: "#667085",
        600: "#51525C"
    },
    orangeMuted: {
        1: "#FCDEC0"
    },
    orangePrimary: {
        1: "#7A4510"
    },
    greenAccent: {
        1: "#12B76A",
        100: "#DEF7EC",
        200:"#C6F1DA",
        300:'#0E4E30',
        500: "#A0E2C380",
        800: "#03543F"
    },
    redAccent: {
        600: "#D92D20"
    },
    blue: {
        25: "#194185",
        50: "#1849A9",
        100: "#175CD3",
        200: "#1570EF",
        300: "#2E90FA",
        400: "#53B1FD",
        500: "#84CAFF",
        600: "#B2DDFF",
        700: "#D1E9FF",
        800: "#EFF8FF",
        900: "#F5FAFF",
    },
    purpleAccent: {
        100: "#422E84"
    },
    purple: {
        100: "#432F87",
    },
    background: {
        100: "#fff"
    },
    red: {
        1: "#E62E05",
        2: "#FFF4ED",
        100: "#FDE8E8",
        150: "#821B13",
        500: "#F0ABA680",
        800: "#9B1C1C",
    },
    error: {
        25: "#7A271A",
        50: "#912018",
        100: "#B42318",
        200: "#D92D20",
        300: "#F04438",
        400: "#F97066",
        500: "#FDA29B",
        600: "#FECDCA",
        700: "#FEE4E2",
        800: "#FEF3F2",
        900: "#FFFBFA",
    },
    warning: {
        25: "7A2E0E",
        50: "#93370D",
        100: "#B54708",
        200: "#DC6803",
        300: "#F79009",
        400: "#FDB022",
        500: "#FEC84B",
        600: "#FEDF89",
        700: "#FEF0C7",
        800: "#FFFAEB",
        900: "#FFFCF5",
    },
    newWarning: {
        25: "#F4EFE3",
        100: "#5E4200"
    },
    success: {
        25: "#054F31",
        50: "#05603A",
        100: "#027A48",
        150: "#0D804A",
        200: "#039855",
        300: "#12B76A",
        400: "#32D583",
        500: "#6CE9A6",
        600: "#A6F4C5",
        700: "#D1FADF",
        800: "#ECFDF3",
        900: "#F6FEF9",
    }
} as const;

export default colorDark;