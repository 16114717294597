export const Cash_Fund_T_C = [
    { para: "I confirm that the information provided by me is accurate to the best of my knowledge.", link: "" },
    // { para: "I confirm I am the ultimate beneficiary of my investment account.", link: "" },
    // { para: "", link: "" },

]

export const DisclaimersArray = [
    "All investments in mutual funds are subject to market risks. Past performance is not necessarily indicative of the future results. Please read the <a style='text-decoration-line: underline; color:#422E84; font-weight: 400;' href='https://www.mahaana.com/micf#fund' target='_blank'>Offering document</a> to understand the investment policies and the risks involved.",
    "All return data is provided for informative reasons only and is not an assurances of future performance outcomes, the quality of an investment, or projections. They are also subject to change at any time and may differ from opinions held by others.",
    "It is advised that you seek obtain independent professional advice where deemed fit.",
    "It is important to note that the initial capital worth of investments and the income from them may fluctuate and perhaps lose value."
    // "It is important to note that the initial capital worth of investments and the income from them may fluctuate and perhaps lose value. For further information, read additional <a style='text-decoration-line: underline; color:#422E84; font-weight: 400;' href='https://mahaana.com/terms-conditions'  target='_blank'>terms & conditions</a>."
]