/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';
import UpdateRiskProfile from './UpdateRiskProfile/UpdateRiskProfile';


const RiskProfileView = () => {
  return (
    <>
      <UpdateRiskProfile />
    </> 
  );
};

export default memo(RiskProfileView);
