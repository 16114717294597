/* eslint-disable no-param-reassign */
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Big from 'big.js';
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { CurrencyFormatter, CurrencyFormatterWithDecimal , kFormatter } from 'utils/calculation';
import "./style.css"
import { graphStyle, graphTabStyle } from './style';

const CustomCFBarChart = ({ LabelListDataKey, LabelListShow = false, barData, barDataKey1, barDataKey2, fund = "CashFund", height = 400, maxFutureValue }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const XAxisYearTick = ({ label, payload, width, x, y }: any) => {
        const name = payload.value;
        return <text x={x} y={y} dy={18} textAnchor="middle" fill="#6B6F80" fontSize={!smScreen ? 12 : "inherit"} style={graphStyle.xAxis}>{name}</text>;
    }

    const renderCusomizedLegend = (props: any) => {
        const year = barData[0].year || "-";
        return (
            <Grid item display={"flex"} justifyContent={"center"} sx={{ gap: "12px", mt: 2 }}>
                {
                    props?.payload?.map((item: any) => {
                        const showText = item?.dataKey?.includes("return") ? "Profit" : "Contribution";
                        return (
                            <Box key={item.dataKey} display={"flex"} alignItems={"center"} sx={{ gap: "4px" }}>
                                <Box width={"12px"} height={"12px"} sx={{ borderRadius: "100px", backgroundColor: item.color }} />
                                <Typography textAlign={"center"} variant='subtitle3' color='#1D2129'>{showText}</Typography>
                            </Box>
                        )
                    })
                }
            </Grid>

        )
    }

    const CustomTooltip = ({ active, label, payload, ...props }: any) => {
        if (active) {
            return (
                <Box sx={{ display: "flex", gap: "14px", flexDirection: "column", background: "#fff", borderRadius: '8px', padding: '12px' }}>
                    {payload.map((item: any, index: number) => {
                        const type = item.dataKey == barDataKey1 ? "Your Contribution" : "Return"
                        return (
                            <Box key={index} sx={{ display: "flex", gap: "6px", alignItems: "baseline" }}>
                                <Box sx={{ ...graphTabStyle.tooltipCircle, background: item.color }} />
                                <Box>
                                    <Typography variant="caption" color="#667085">{type}</Typography>
                                    <Typography variant="subtitle2" color={"#1D2939"}>{CurrencyFormatterWithDecimal(item.value, true ,"never",true , true)}</Typography>
                                </Box>
                            </Box>


                        )
                    })}
                </Box>
            );
        }
        return null;
    };

    const RenderCustomizedLabel = (props: any) => {
        const { value, width, x, y } = props;
        const kFormatedValue = kFormatter(value);

        const addByLength = kFormatedValue?.length >= 10 ? 2 : 3
        const textWidth = kFormatedValue?.length >= 8 ? (kFormatedValue?.length + addByLength) * 10 : 100;

        return (
            <g>
                <rect x={x + width / 2 - textWidth / 2} y={y - 30} width={textWidth} height="25" fill="white" stroke='#EFEFEF' strokeWidth={1} rx={10} ry={10} />
                <text x={x + width / 2} y={y - 17} textAnchor="middle" dominantBaseline="middle" >
                    {kFormatedValue}
                </text>
            </g>
        );
    };

    function generateTickValues(start: any, end: any, count: any) {
        const interval = (end - start) / (count - 1);
        return Array.from({ length: count }, (_, i) => Math.round(start + interval * i));
    }

    const yTicks = generateTickValues(0, maxFutureValue * 1.13, 6);

    return (
        <>
            <ResponsiveContainer height={height} width='100%' className={'cashfundBar'} >

                <BarChart
                    data={barData}
                    margin={{ top: 15, right: 0, left: 0, bottom: 15 }}
                // barSize={smScreen ? '20%' : 100}
                // maxBarSize={100}
                >

                    <XAxis
                        dataKey='name'
                        axisLine={false}
                        tickLine={false}
                        fontSize={12}
                        interval={0}
                        tick={<XAxisYearTick />}
                    />

                    {fund == "CashFund" ?
                        <YAxis
                            interval={0}
                            tickFormatter={kFormatter}
                            minTickGap={0}
                            tickCount={6}
                            fontSize={14}
                            color={'#838799'}
                            fontWeight={400}
                            yAxisId="left"
                            orientation="left"
                            axisLine={false}
                            tickLine={false}
                            ticks={yTicks}
                            domain={[0, new Big(maxFutureValue).round().toNumber() == 0 ? 'dataMax+10' : new Big(maxFutureValue).round().toNumber()]}
                            style={graphStyle.yAxis}
                        />
                        :
                        <YAxis
                            interval={0}
                            tickFormatter={kFormatter}
                            minTickGap={0}
                            padding={{}}
                            tickCount={6}
                            fontSize={14}
                            color={'#838799'}
                            fontWeight={400}
                            yAxisId="left" orientation="left"
                            axisLine={false}
                            tickLine={false}
                        />
                    }
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Legend content={renderCusomizedLegend} />


                    <Bar yAxisId="left" stackId={'bar'} dataKey={barDataKey1} fill='#62529B' radius={[0, 0, 12, 12]} />
                    <Bar yAxisId="left" stackId={'bar'} dataKey={barDataKey2} fill='#84CAFF' radius={[12, 12, 0, 0]}  >
                        {LabelListShow && <LabelList dataKey={LabelListDataKey} position="top" fill='#1F2937' content={<RenderCustomizedLabel />} />}
                    </Bar>

                </BarChart>


            </ResponsiveContainer>


        </>
    )
}

export default CustomCFBarChart