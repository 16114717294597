import { useAppSelector } from 'hooks/reduxHooks';
import { HelpCrunchWidget } from 'Latest/HelpCrunch';

import React, { memo, useState, useEffect } from 'react';

const HelpCrunchTrigger = () => {

  const { isAuth, userAccountDetails } = useAppSelector((state) => state.auth);
  const [helpCrunchData, setHelpCrunchData] = useState(null);

  useEffect(() => {
    if (!helpCrunchData) {
      setHelpCrunchData(userAccountDetails)
    }
  }, [userAccountDetails]);
  return (
    <>
      {isAuth && helpCrunchData && <HelpCrunchWidget user={helpCrunchData} userAccountDetails={helpCrunchData} />}
    </>
  )
}
export default memo(HelpCrunchTrigger);