export const tabComponentStyle = {
    mainBoxCard: {
        border: { sm: "1px solid #EAECF0" }, borderRadius: { sm: "8px" }, p: { sm: "20px" } , position:"relative"
    }
}
export const mobTransactionTable = {
    tableCellStyleHead: {
        // py: "4px",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#475467"
    },
    tableCellStyleBody: {
        pt: "4px",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#1D2939"

    },
    primaryText: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "18px",
    },
    secondaryText: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
    },
    primaryMainBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    primarySubBox: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center"
    }
}