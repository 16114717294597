import { useEffect, useState } from 'react';
import { styled, useTheme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';

import PvtRoutes from './PvtRoutes';
import TopBar from './TopBar';
import DrawerCustom, { DrawerHeader } from './DrawerCustom';
import { Container, useMediaQuery } from '@mui/material';
import SmallScreenTopBar from './SmallScreenTopBar';
import { isSideBarOpenAction } from 'redux/reducers/sharedStateData';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation  } from 'react-router-dom';
import { checkHelpCrunch, helpCrunchHideWidget, helpCrunchShowWidget } from 'Latest/HelpCrunch';

const drawerWidth = 210;

const pageVariants: any = {
  in: { opacity: 1 },
  initial: { opacity: 0 },
  transition: { duration: 0.5 }
};

export default function MiniDrawer() {

  const location = useLocation();

  const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { isSideBarOpen } = useAppSelector(state => state.sharedData)

  const handleDrawerOpen = () => {
    dispatch(isSideBarOpenAction(true))
  };

  const handleDrawerClose = () => {
    dispatch(isSideBarOpenAction(false))
  };

  const smScreen = useMediaQuery(theme.breakpoints.up('md'));
  const checkNewUserScreen = dashboardDetail && dashboardDetail?.status == "New";
  const routesList = ["/newUser", "/Cashfund", "/Invest", "/Goal", "/payment", "/Receipt" ]
  // Check if the current path is in the routesList
  const isPathInList = routesList.includes(location.pathname);

  const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const checkHC = checkHelpCrunch();

  useEffect(() => {
    if (xsScreen && checkHC) {
      setTimeout(() => {
        helpCrunchHideWidget();
      }, 500);
        helpCrunchHideWidget();
    } else {
      helpCrunchShowWidget();
    }
  }, [xsScreen, checkHC]);

  return (
    <>
      <Box sx={{ display: '-webkit-inline-box' }}  >

        <CssBaseline />

        {(smScreen && !checkNewUserScreen) && !isPathInList &&
          <>
            {/* <TopBar open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} drawerWidth={drawerWidth} /> */}
            <DrawerCustom
              open={isSideBarOpen}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              drawerWidth={drawerWidth}
            />
          </>
        }

        <Box component="main" sx={{ flexGrow: 1 }} >
          {/* {smScreen ? <DrawerHeader /> : <SmallScreenTopBar />} */}
          {(!smScreen && !checkNewUserScreen) && !isPathInList && <SmallScreenTopBar />}

          {/* <Container disableGutters maxWidth={"xl"}> */}
          <AnimatePresence>
            <motion.div
              animate='in'
              exit='out'
              initial='initial'
              variants={pageVariants}
            >
              <PvtRoutes />
            </motion.div>
          </AnimatePresence>
          {/* </Container> */}
        </Box>

      </Box>
    </>
  );
}