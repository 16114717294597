import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useAppLoading } from 'hooks/appLoading';
import { useAppSelector } from 'hooks/reduxHooks';
import CustomPieChart from 'Latest/ChartV2/customPieChart';
import CustomScrollerCard from 'Latest/ComponentV1/customScrollerCard'
import NoData from 'Latest/Illustration/noData';
import React, { useEffect, useState } from 'react'
import { tokens } from 'theme';
import { savePieColorsHsl } from "../../constants"

const AssetChart = () => {
    const PortfolioColors = ['#403081', '#43BED8', '#F2994A', '#F97066', '#5D9631'];
    const { dashboardPotAllocation } = useAppSelector((state: any) => state.dashboard);
    const [portfolioData, setPortfolioData] = React.useState<any>([]);
    const loading = useAppLoading('CFPotAllocation');
    const [loadingState, setLoadingState] = useState(true);
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [activeIndex, setActiveIndex] = useState(-1)
    const colorTheme = tokens(theme.palette.mode);
    const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        const CashFundPortfolioDataArray = dashboardPotAllocation?.customerAssetAllocation && dashboardPotAllocation?.customerAssetAllocation.map((item: any, index: number) => ({ "name": item?.key, "value": item.value1, "amount": item.value2 })).filter((item: any) => item.value > 0);

        let sorted_Arr;
        if (CashFundPortfolioDataArray) {
            sorted_Arr = CashFundPortfolioDataArray.slice().sort((a:any, b:any) => Number(b.amount) - Number(a.amount));
        }

        setPortfolioData(sorted_Arr);
        setLoadingState(false)
    }, [dashboardPotAllocation])



    return (
        <>
            {loading || loadingState ?
                <CircularLoader />
                :
                portfolioData?.length >= 1 ?
                    portfolioData && <>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center", borderTop: '1px solid #D0D5DD40', borderBottom: '1px solid #D0D5DD40' }}>
                            <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
                                {portfolioData && <CustomPieChart
                                    hoverDotVal={(hoverDotVal: any) => { setActiveIndex(hoverDotVal) }} innerRadiusPie={"84%"}
                                    outerRadiusPie={"100%"} colors={savePieColorsHsl} data={portfolioData} showCustomActiveShape={false}
                                    customTooltipTrue={false} hoverToolTip={false} customHeight={smScreen ? 230 : xsScreen ? 210 : 310}
                                />}
                            </Box>
                            <CustomScrollerCard data={portfolioData} colors={savePieColorsHsl} activeIndex={activeIndex} />
                        </Box>
                    </>
                    :
                    <NoData />
            }
        </>
    )
}

export default AssetChart


const CircularLoader = () => {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Box sx={{ position: 'absolute', right: "50%", top: smScreen ? "40%" : "50%" }}>
            <CircularProgress color="primary" size={smScreen ? "6rem" : "2rem"} />
        </Box>
    )
}
