import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { graphStyle, graphTabStyle } from './style'
import moment from 'moment'
import { CurrencyFormatter, CurrencyFormatterWithDecimal, formatYAxis, kFormatter } from 'utils/calculation'
import { add, differenceInCalendarDays, format } from 'date-fns'

const dateFormatter = (date: any) => {
    const formatMM = format(new Date(date), "MMM-Y")
    return formatMM;
};

const getTicks = (startDate: any, endDate: any, num: any) => {
    const diffDays = differenceInCalendarDays(endDate, startDate);

    const current: any = startDate,
        velocity = Math.round(diffDays / (num - 1));

    const ticks = [startDate.getTime()];

    for (let i = 1; i < num - 1; i++) {
        ticks.push(add(current, { days: i * velocity }).getTime());
    }

    ticks.push(endDate.getTime());
    return ticks;
};


const ProjectionAreaChart = ({ gradient, graphData, height, retirementAge, stroke }: any) => {

    const theme = useTheme()
    const [hoveredDot, setHoveredDot] = useState<any>(-1);
    const xlScreen = useMediaQuery(theme.breakpoints.up('xl'));
    const mdScreen = useMediaQuery(theme.breakpoints.only('md'));
    const smScreen = useMediaQuery(theme.breakpoints.only('xs'));


    const ticksCountSet = mdScreen || smScreen ? 3 : 4

    //x-axis-ticks
    const firstObj = graphData[0]
    const lastObj = graphData[graphData?.length - 1]

    const startDate = new Date(firstObj?.key);
    const endDate = new Date(lastObj?.key);

    const domainXAXIS: any = [(dataMin: any) => dataMin, () => endDate.getTime()];
    const ticks = getTicks(startDate, endDate, ticksCountSet);
    //


    const CustomTooltip = ({ active, label, payload, }: any) => {

        if (active) {
            const hoverPayload = payload && payload[0]?.payload
            const Date = moment(hoverPayload?.key)?.format("MMM YYYY")
            return (

                <Box sx={graphTabStyle.tooltipMainBox} color="#344054">
                    <Typography variant="body2" >{Date}</Typography>
                    <Box sx={{ display: "flex", gap: "14px", mt: "8px" }}>
                        {payload?.map((item: any, index: number) => {
                            const title = item.name == "value" ? "projected value" : item.name
                            return (
                                <Box key={index} sx={{ display: "flex", gap: "6px", alignItems: "baseline" }}>
                                    {/* <Box sx={{ ...graphTabStyle.tooltipCircle, background: item.color }} /> */}
                                    <Box>
                                        <Typography variant="caption" color="#667085">{title}</Typography>
                                        <Typography variant="subtitle2" color={"#1D2939"}>{CurrencyFormatterWithDecimal(item.value, true,"never",true,true)}</Typography>
                                    </Box>
                                </Box>
                            )
                        })}

                    </Box>

                </Box >
            );
        }
        return null;
    }

    const CustomXAxis = (props: any) => {
        const activeDotIndex = hoveredDot >= 0 ? hoveredDot : -1;
        const data = props.payload
        const isActive = data.index === activeDotIndex ? true : false;
        const fillColor = isActive ? "#344054" : "#6B6F80"
        const fillBgColor = isActive ? "#f4f3f8" : "transparent"
        const { payload, x, y } = props;
        const dx = retirementAge <= 4 ? -3 : 9
        const rectX = retirementAge <= 4 ? -13 : 4.5
        const payloadVal = dateFormatter(payload.value)

        return (
            <g transform={`translate(${x - 25}, ${y})`}>
                <rect
                    x={rectX}  // Adjust the x position to cover the text
                    y={2} // Adjust the y position to cover the text
                    width={payloadVal.length * 10.5} // Adjust the width based on the text length
                    height={25}  // Adjust the height to cover the text
                    fill={fillBgColor} // Set the desired background color
                />
                <text x={0} y={0} dy={20} dx={dx} fill={fillColor} style={graphStyle.xAxis}
                // style={isActive ? { ...ProjectionStyles.activeXAxisPorfolioStyles } : { ...ProjectionStyles.xAxisPorfolioStyles }}
                >
                    {payloadVal}
                </text>
            </g>
        );
    };

    const xAxisInterval = () => {
        if (retirementAge <= 4) {
            if (xlScreen) {
                return retirementAge - 1
            }
            return "preserveEnd"
        }
        return "preserveStartEnd"
    }

    const maxYValue = graphData?.length && Math.max(...graphData?.map((item: any) => item?.value));    //only use this for err handling if portfolio < contributionAmount
    const minYValue = 0;
    const tickCount = 6;
    const diffYValue = maxYValue - minYValue;
    // Calculate the raw interval between values
    const rawTickInterval = diffYValue / tickCount;
    const tickValues: number[] = [];
    const domainEnd = Math.floor(maxYValue + rawTickInterval / 15);

    const getDivisible = (lastVal: string) => {

        const maxValue = Number(lastVal);

        // Calculate the result based on the formula
        // Math.log10(maxValue): This part calculates the base-10 logarithm of maxValue. eg: if maxValue is 12000, then Math.log10(12000) would be approximately 4.079.
        // Math.floor(Math.log10(maxValue) - 1): Here, we subtract 1 from the base-10 logarithm, after the roundoff to the nearest integer the result would be 3, which corresponds to moving from 12000 to 1000.
        // Math.pow(10, Math.floor(Math.log10(maxValue) - 1)): we use Math.pow to raise 10 to the power of the integer. This effectively generates a number with the desired number of zeros at the end
        const subtractBy = maxValue > 1000 ? 1 : 0;
        const result = Math.pow(10, Math.floor(Math.log10(maxValue) - subtractBy));
        return result;
    }

    const getYAxisTicks = () => {
        const divisibleBy = getDivisible(graphData[graphData?.length - 1]?.value);
        const tickInterval = Math.ceil(rawTickInterval / divisibleBy) * divisibleBy
        const endingValue = Math.ceil(maxYValue / divisibleBy) * divisibleBy;

        for (let i = 0; i <= tickCount; i++) {
            const tickValue = endingValue - tickInterval * i;
            if (tickValue >= 0) {
                tickValues.push(tickValue);
            }
        }

        // Check if the last item in tickValues is not 0 and add 0 if necessary
        // if (tickValues[tickValues.length - 1] !== 0 && (tickValues[tickValues.length - 1] !== 100 && tickValues[tickValues.length - 1] !== 1000)) {
        //     tickValues.push(0);
        // }

        return tickValues
    }


    return (
        <>
            <ResponsiveContainer width="100%" height={height} className='projectionChart'>
                <AreaChart
                    data={graphData}
                    margin={{ top: 15, bottom: 15 }}
                    onMouseMove={e => { setHoveredDot(e?.activeTooltipIndex) }}
                    onMouseLeave={() => setHoveredDot(-1)}
                >

                    <defs>
                        <linearGradient id="InvestedAmountColor" x1="0" y1="0" x2="0" y2="1" >
                            <stop stopColor={gradient} stopOpacity="0.58" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                    </defs>


                    {/* <XAxis
                        interval={xAxisInterval() || "preserveStartEnd"}
                        dataKey="key"
                        axisLine={true}
                        tickLine={false}
                        mirror={false}
                        // padding="no-gap"
                        // tickCount={3}
                        tick={<CustomXAxis />}
                        stroke="#F2F4F7"
                    // interval={2} 
                    // tickCount={calculateTickCount(graphData)} 
                    // ticks={['Dec-2023', 'Feb-2024', 'Aug-2024', 'Feb-2025', 'Jun-2025', 'Jun-2026', 'Aug-2026', 'Nov-2026']}
                    /> */}

                    <XAxis
                        dataKey="key"
                        tick={<CustomXAxis />}
                        axisLine={true}
                        tickLine={false}
                        stroke="#F2F4F7"
                        domain={domainXAXIS}
                        ticks={ticks}
                        scale="time"
                        type="number"
                    />


                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        interval={0}
                        tickCount={8}
                        tickFormatter={kFormatter}
                        type='number'
                        style={graphStyle.yAxis}
                        domain={[0, domainEnd]}
                        ticks={getYAxisTicks()}
                    />
                    <Tooltip content={<CustomTooltip />}
                    />
                    <CartesianGrid stroke="#F2F4F7" strokeWidth={1} horizontal={false}
                        // vertical={true} 
                        vertical={false}
                    />

                    <Area type="monotone" dataKey={'value'} stroke={stroke ?? gradient} fill="url(#InvestedAmountColor)" fillOpacity={1} strokeWidth={2}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    )
}

export default ProjectionAreaChart