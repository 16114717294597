import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import diamondIcon from "Latest/Images/svg/diamondIcon.svg"
import { useHistory } from 'react-router-dom'
import StaticContent from './staticContent'
import Null from './status/null'
import Progress from './status/progress'
import Verified from './status/Verified'
import Failed from './status/failed'
import Pending from './status/pending'
import { useAppSelector } from 'hooks/reduxHooks'

const UpgradeCard = ({ borderHighlight, setBorderHighlight }: any) => {

    const { push } = useHistory()
    const { userAccountDetails } = useAppSelector(state => state.auth);

    useEffect(() => {
        //remove url param
        handleRemoveParam()
    }, [])

    const handleRemoveParam = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('scroll')) {

            setBorderHighlight(true)
            setTimeout(() => {
                setBorderHighlight(false)
                // Remove the 'scroll' parameter from the URL
                urlParams.delete('scroll');

                // Construct the new URL without the 'scroll' parameter
                const newUrl = urlParams.toString() ? window.location.pathname + '?' + urlParams.toString() : window.location.pathname;

                // Replace the current URL with the new URL to remove the 'scroll' parameter and '?'
                window.history.replaceState({}, '', newUrl);
            }, 6000);
        }
    }
    const statusEnum: any = userAccountDetails?.kyc == null ? "Default" : userAccountDetails?.kyc?.status

    const upgradeStatusComp: any = {
        "Default": <Null noteTextShow={true} />,
        "ApprovalPending": <Null isPending={true} noteTextShow={true} />,
        "Pending": <Null isPending={true} noteTextShow={true} />,
        "ApprovalSucceeded": <Verified />,
        "Succeeded": <Verified />,
        "ApprovalFailed": <Failed />,
        "Failed": <Failed />,
        "ApprovalInProgress": <Progress noteTextShow={true} />,
        "InProgress": <Progress noteTextShow={true} />,
        "ApprovalHalted": <Pending noteTextShow={true}  />,
        "Halted": <Pending noteTextShow={true}  />
    }

    return (
        <>
            <Grid item md={12} xs={12} >
                {upgradeStatusComp[statusEnum]}
            </Grid>
        </>
    )
}

export default UpgradeCard