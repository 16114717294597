import { Box, Container, Grid } from '@mui/material'
import BankDetailGrid from 'Latest/MyProfile/BankDetails/bankDetailGrid'
import React from 'react'

const Index = () => {
 
  return (
    <>
      <Container disableGutters maxWidth="lg" sx={{ px: 0, ml: 0 }}>
        <Grid container sx={{ p: { xs: "24px 16px", md: "30px 32px" } }}>
          <BankDetailGrid />
        </Grid>
      </Container>
    </>
  )
}

export default Index