import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import useStylesAlertWarning from 'Latest/ComponentV1/CustomMakeStyle/customWarningAlert';
import { useAppSelector } from 'hooks/reduxHooks';
import React from 'react'
import { useHistory } from 'react-router-dom';
import IconWarning from "Latest/Images/svg/IconWarning.svg"
import moment from 'moment';


const DevidendWarningAlert = ({ handleClose }: any) => {

    const classes = useStylesAlertWarning();
    const { push } = useHistory();
    const { userAccountDetails } = useAppSelector(state => state.auth);


    const filterDividendPayout = userAccountDetails?.alerts && userAccountDetails?.alerts.filter((x: any) => x?.type?.includes('DividendPayout'))

    const date = filterDividendPayout?.length && filterDividendPayout[0]?.details?.EndingDate
    const EndingDate = moment(date, 'YYYY-MM-DD').format("Do MMMM YYYY")
    const EndingDateMY = moment(date, 'YYYY-MM-DD').format("MMMM YYYY")

    return (
        <>
            <Box>
                <Alert severity="warning" className={classes.customAlert}
                    onClose={handleClose} 
                    icon={false}>
                    <AlertTitle className={classes.customAlertTitle} sx={{ fontWeight: 500 }}>
                        <img src={IconWarning} />
                        Upcoming dividend payout in {EndingDateMY}
                    </AlertTitle>

                    <Typography variant='body2'>Deadline: {EndingDate}</Typography>
                    <Typography variant='body2' sx={{ py: 1 }}>We’ll be distributing Mahaana Save+ dividends to customers in {EndingDateMY}. By default your dividends will be reinvested into Mahaana Save+. This method provides you the highest tax savings available. However you may change your dividend distribution method.</Typography>
                    <Button className={classes.customButton} sx={{ borderRadius: "100px", height: "37px", textTransform: "unset", fontWeight: 500, }} onClick={() => push("/myprofile?value=6")}>
                        Select distribution method
                    </Button>

                    {/* <Button color="warning" variant="contained" sx={{ mt: 1 }} onClick={() => push("/myprofile?value=3")}>Reupload my CNIC</Button> */}
                </Alert>
            </Box>
        </>
    )
}

export default DevidendWarningAlert;