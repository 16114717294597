import React, { useState, useEffect } from 'react'
import InputField from '../../Shared/FormikInputFieldLatest';
import SelectField from '../../Shared/FormikSelectFieldLatest';
import Form from 'react-bootstrap/Form';
import { Box, Container, Grid, Typography } from '@mui/material';
import { CityName } from './city';
import { ProvinceName } from './province';
import { subHeadingBlueStyle } from 'Latest/Style/Main';
import CustomSingleCheckBox from 'jsx/components/Shared/CustomSingleCheckBox';

const labelStyle = {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#344054",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    display: 'flex',
    alignItems: 'center',
    paddingLeft: "0px"
    // justifyContent: 'flex-end'
}

const MailingFields = ({ formik }: any) => {

    const [cityList, setcityList] = useState<any>([])
    const [provinceList, setProvinceList] = useState<any>([])


    useEffect(() => {
        const data = CityName?.map((val) => ({ 'value': val.name, 'label': val.name }));
        setcityList(data)

        const stateList = ProvinceName?.map((val) => ({ 'value': val.name, 'label': val.name }));
        setProvinceList(stateList)

    }, [CityName, ProvinceName])

    useEffect(() => {
        if (formik.values.sameAsParmanent) {
            formik.setFieldValue('mailingAddress1', formik.values.permanentAddress1)
            formik.setFieldValue('cityMailing', formik.values.cityPermanent)
            formik.setFieldValue('provinceMailing', formik.values.provincePermanent)
            formik.setFieldValue('mailingAddress2', formik.values.permanentAddress2)
        }
    }, [formik.values.permanentAddress1, formik.values.cityPermanent, formik.values.provincePermanent, formik.values.permanentAddress2, formik.values.sameAsParmanent])

    return (
        <>
        <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
                <Typography  sx={subHeadingBlueStyle}>Mailing address</Typography>
            </Grid>

            <Grid item md={12} xs={12}>
                <CustomSingleCheckBox
                    formik={formik}
                    fieldName={`sameAsParmanent`}
                    label={`Same as permanent address`}
                    type={"checkbox"}
                    styleForm={{ paddingLeft: "0px" }}
                    styleErr={{ marginLeft: "0px" }}
                />
            </Grid>

            <Grid item md={12} xs={12}>
                <InputField
                    required
                    maxLength={120}
                    disabled={formik.values.sameAsParmanent}
                    formik={formik}
                    label='Address line 1'
                    name='mailingAddress1'
                    placeholder='Enter mailing address here'
                    type="text"
                />
            </Grid>

            <Grid item md={12} xs={12}>
                <InputField
                    maxLength={120}
                    disabled={formik.values.sameAsParmanent}
                    formik={formik}
                    label='Address line 2 (optional)'
                    name='mailingAddress2'
                    placeholder='Enter mailing address here'
                    type="text"
                />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
                <SelectField
                    col={12}
                    disabled={formik.values.sameAsParmanent}
                    fieldName='cityMailing'
                    fields={cityList}
                    formik={formik}
                    label='City'
                    onChange={(e) => {
                        const selectedField = cityList.filter((i: any) => i.value == e)
                        formik.setFieldValue('cityMailing', selectedField[0])
                    }}
                    placeholder={"Select City"}
                    required={true}
                    value={formik.values.cityMailing.value == '' ? "" : formik.values.cityMailing}

                />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
                <SelectField
                    col={12}
                    disabled={formik.values.sameAsParmanent}
                    fieldName='provinceMailing'
                    fields={provinceList}
                    formik={formik}
                    label='Province'
                    onChange={(e) => {
                        const selectedField = provinceList.filter((i: any) => i.value == e)
                        formik.setFieldValue('provinceMailing', selectedField[0])
                    }}
                    placeholder={"Select Province"}
                    required={true}
                    value={formik.values.provinceMailing.value == '' ? "" : formik.values.provinceMailing}
                />
            </Grid>
            </Grid>
        </>
    )
}

export default MailingFields