import { GoalValueEnum } from '../../constants';
import { useAppLoading } from 'hooks/appLoading';
import WizardFormBody from 'jsx/components/WizardFormModal/WizardFormBody';
import { Modal } from 'react-bootstrap';
import './styles/modalMedia.scss'
interface IModalProps {
  goalId: number;
  goalName: string;
  goalValue: string
  modalOpen: any;
  setModalOpen: any;
  isThisGoalCreated?: boolean;
  proceedButtonHandler: any;
}

const WizardFormModal = (props: IModalProps) => {
  const loading = useAppLoading('WizardFormModal');
  return (
    <Modal
      centered
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      show={props.modalOpen}
      size="xl" >
      <Modal.Header closeButton onHide={() => props.setModalOpen(!props.modalOpen)} >
        <Modal.Title id="contained-modal-title-vcenter">
          {props.goalName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  >
        <WizardFormBody
          goalId={props.goalId}
          goalValue={props.goalValue}
          isThisGoalCreated={props.isThisGoalCreated}
          loading={loading}
          proceedButtonHandler={props.proceedButtonHandler}
        />
      </Modal.Body>
    </Modal>
  )
}
export default WizardFormModal;