import React from 'react'

const HomeIconV2 = ({ fill = "#667085" }: any) => {
    return (
        <div>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.7712 8.45468L11.2759 1.37937L11.2609 1.36531C10.9165 1.05008 10.4665 0.875244 9.99953 0.875244C9.5326 0.875244 9.08259 1.05008 8.73812 1.36531L8.72219 1.37937L1.22875 8.45468C1.03872 8.6305 0.887016 8.84364 0.78313 9.08077C0.679244 9.3179 0.625411 9.57392 0.625 9.83281V18.5C0.625 18.9973 0.822544 19.4742 1.17417 19.8258C1.52581 20.1775 2.00272 20.375 2.5 20.375H7C7.49728 20.375 7.97419 20.1775 8.32583 19.8258C8.67746 19.4742 8.875 18.9973 8.875 18.5V14.375H11.125V18.5C11.125 18.9973 11.3225 19.4742 11.6742 19.8258C12.0258 20.1775 12.5027 20.375 13 20.375H17.5C17.9973 20.375 18.4742 20.1775 18.8258 19.8258C19.1775 19.4742 19.375 18.9973 19.375 18.5V9.83281C19.3746 9.57392 19.3208 9.3179 19.2169 9.08077C19.113 8.84364 18.9613 8.6305 18.7712 8.45468ZM17.125 18.125H13.375V14C13.375 13.7538 13.3265 13.5099 13.2323 13.2825C13.138 13.055 12.9999 12.8483 12.8258 12.6742C12.6517 12.5001 12.445 12.362 12.2175 12.2677C11.99 12.1735 11.7462 12.125 11.5 12.125H8.5C8.00272 12.125 7.52581 12.3225 7.17417 12.6742C6.82254 13.0258 6.625 13.5027 6.625 14V18.125H2.875V9.995L10 3.26843L17.125 9.995V18.125Z" fill={fill} />
            </svg>
        </div>
    )
}

export default HomeIconV2