import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Container, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { goalCardHeadTypoStyle, otherInputField } from './style';
import GoalCard from './goalCard';
import GoalWizrad from './GoalWizard';
import { GoalValueEnum, GoalIdEnum, GoalNameEnum, QuestionAnswerCategoryEnum, PendingQuestionAnswerCategoryEnum } from "../../constants";
import ButtonsRow from 'jsx/components/WizardFormModal/ButtonsRowLatest';
import { containerMain, containerMainBox, HeadingStyle, subHeadingStyle } from 'Latest/Style/Main';
import { links } from 'static/links'
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getInvestorGoals, onGetAccountSetup, onGetUserPendingRiskProfileQuestion, onGetUserQuestionAnswers } from 'redux/actions';
import { onGetGoal, onGetInvestGoalConfig, onGetInvestPortfolioAllocation } from 'redux/reducers/sharedStateData';
// import expenseIcon from "Latest/Images/svg/wedding.svg"
import expenseIcon from "Latest/Images/svg/largeExpense.svg"
import wealthIcon from "Latest/Images/svg/wealth.svg"
import planIcon from "Latest/Images/svg/travel.svg"
import eduIcon from "Latest/Images/svg/education.svg"
import houseIcon from "Latest/Images/svg/House.svg"
import { useAppLoading } from 'hooks/appLoading';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import { toast } from 'react-toastify';
import TopBarLogo from 'Latest/ComponentV1/topBarLogo';
import ExitIcon from "Latest/Images/svg/ExitIcon";
import { mainContainerV2 } from 'Latest/Style/Style';
import { useIsOnline } from 'react-use-is-online';
import { isNullObj } from '../../utils/nullChecker';

export default function Index() {

    const { isOnline } = useIsOnline();
    const dispatch = useAppDispatch();
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
    const { location, push } = useHistory();
    const [showQuestion, setshowQuestion] = React.useState(false)
    const [selectedGoal, setSelectedGoal] = React.useState<any>({})
    const [otherGoalName, setOtherGoalName] = React.useState("")
    const { investorGoal } = useAppSelector(state => state.portfolio);
    const { isGIAllowed, user } = useAppSelector(state => state.auth);
    const { investGoalConfig, investPortfolioAlloc } = useAppSelector((state: any) => state.sharedData);
    const [goalArray, setGoalArray] = React.useState<any>([])
    const [count, setCount] = useState(0)


    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const loc: any = location?.state

    const loadingStates = [
        useAppLoading('auth'),
        useAppLoading('personalizedPortfolio'),
        useAppLoading('portfolio'),
        useAppLoading('questionAnswer'),
        useAppLoading('sharedStateData'),
    ];

    const loading = loadingStates.some((loadingState) => loadingState);

    useEffect(() => {
        if (!isGIAllowed) {
            push("/");
            toast.error(`Your are not allowed to access Mahaana Invest. Contact to your admin.`);
            return;
        }
    }, [isGIAllowed])

    const handleAPIs = () => {
        dispatch(onGetAccountSetup());
        dispatch(onGetGoal(user?.id));
        dispatch(getInvestorGoals(user?.id));
        dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.RiskProfile));
        dispatch(onGetUserPendingRiskProfileQuestion(PendingQuestionAnswerCategoryEnum.RiskProfile));
        dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.TermCondition));
        dispatch(onGetInvestGoalConfig())
        dispatch(onGetInvestPortfolioAllocation())
    }
    useEffect(() => {
        if (user?.id) {
            handleAPIs()
        }

    }, [dispatch]);

    const handleGoal = (item: any) => {
        setSelectedGoal(item)
        setOtherGoalName('')
    }

    useEffect(() => {   // for internet check
        if (count != 0 && !isNullObj(investGoalConfig) && investPortfolioAlloc?.length) {
            setshowQuestion(true)
        }
    }, [investGoalConfig, investPortfolioAlloc])



    const handleNext = () => {

        // setshowQuestion(true)            //old


        if (isOnline) {  // for internet check
            if (!isNullObj(investGoalConfig) && investPortfolioAlloc?.length) {
                setshowQuestion(true)
            } else {
                handleAPIs()
                setCount(count + 1)
            }
        } else {
            toast.error('Internet is required to proceed', { theme: "colored" });
        }

    }

    const handleBack = () => {
        const isNewUser: any = loc?.NewUser
        if (isNewUser) {
            push("/")
        } else {
            push(links.Invest, location?.state)
        }

    }

    const handleOtherGoal = () => {
        const item = {
            "id": 4,
            "val": GoalValueEnum.WindFallReceive,
            "name": otherGoalName
        }
        setSelectedGoal(item)
    }

    React.useEffect(() => {
        if (otherGoalName != "") {
            handleOtherGoal()
        }
    }, [otherGoalName])


    const checkDisable = (val: any) => {
        return investorGoal?.some((e: any) => e.goalType.value === val)
    }

    React.useEffect(() => {
        setGoalArray([
            { id: GoalIdEnum.HouseBuy, val: GoalValueEnum.HouseBuy, name: GoalNameEnum.HouseBuy, isDisable: checkDisable(GoalValueEnum.HouseBuy), icon: houseIcon },
            { id: GoalIdEnum.CollegeFee, val: GoalValueEnum.CollegeFee, name: GoalNameEnum.CollegeFee, isDisable: checkDisable(GoalValueEnum.CollegeFee), icon: eduIcon },
            { id: GoalIdEnum.Travel, val: GoalValueEnum.Travel, name: GoalNameEnum.Travel, isDisable: checkDisable(GoalValueEnum.TravelTime), icon: planIcon },
            { id: GoalIdEnum.IncreaseWealth, val: GoalValueEnum.IncreaseWealth, name: GoalNameEnum.IncreaseWealth, isDisable: checkDisable(GoalValueEnum.IncreaseWealth), icon: wealthIcon },
            { id: GoalIdEnum.LargeExpense, val: GoalValueEnum.LargeExpense, name: GoalNameEnum.LargeExpense, isDisable: checkDisable(GoalValueEnum.LargeExpense), icon: expenseIcon },
        ])
    }, [investorGoal])

    const handleGoalBack = () => {
        setshowQuestion(false)
    }


    const disableNext = selectedGoal?.name == "" || Object.keys(selectedGoal).length === 0
    const { goalIdPost } = useAppSelector(state => state?.portfolio);

    const addExtraRoutes = [{ title: smScreen ? "Exit" : "Exit", route: null, icon: ExitIcon, isNavlink: false, navDropDown: [], target: false, onClick: () => push("/"), functionTrigger: true },]

    const buttonHtml = () => {
        return (
            <ButtonRowV1 btnTitleNext={"Continue"} handleNext={handleNext} disableNext={disableNext} loadingNextBtn={loading} handleBack={handleBack} />
        )
    }

    const currentStep = 1;
    const totalSteps = 13;
    const progressValue = (currentStep / totalSteps) * 100;

    const mdUpScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <>
            {showQuestion ?
                <GoalWizrad handleGoalBack={handleGoalBack} selectedGoal={selectedGoal} />
                :
                <>
                    {/* {dashboardDetail && dashboardDetail?.status == "New" && <TopBarLogo addExtraRoutes={addExtraRoutes} />} */}
                    <TopBarLogo addExtraRoutes={addExtraRoutes} />

                    <Box sx={{ background: "#F8FAFB", height: "100vh" }}>
                        <Container maxWidth={'lg'} sx={{ pt: { xs: "24px", md: "40px" }, }} disableGutters={smScreen}>
                            <Box
                                sx={{
                                    ...mainContainerV2,
                                    // borderRadius: "3px 3px 8px 8px",
                                    // background: "#FFF",
                                    // boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
                                    // padding: { xs: "16px", md: "20px 40px" }
                                }}
                            >

                                {/* <Box sx={{ width: '100%', position: 'absolute', top: 0, borderRadius: "8px 8px 0px 0px", overflow: "hidden" }}>
                                    <LinearProgress variant="determinate" color='secondary' value={progressValue} />
                                </Box> */}

                                <Typography variant='h1' color={"#1D2939"} textAlign={"center"}>Create a goal</Typography>

                                <Box sx={{ width: "100%" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "516px", margin: "0 auto" }}>
                                        <Typography sx={{ pb: "8px", fontSize: "16px", fontWeight: 500 }}>Goal name</Typography>
                                        <input style={otherInputField} placeholder="Enter a custom goal name" value={otherGoalName} onChange={(e) => setOtherGoalName(e.target.value)} />
                                    </Box>
                                </Box>

                                <Typography color={"#202020"} sx={{ fontSize: "16px", fontWeight: 500 }} textAlign={"center"}>Or select a popular goal</Typography>

                                <Grid container spacing={"16px"} >
                                    {goalArray && goalArray?.map((i: any) => (
                                        <>
                                            <Grid key={i.id} item md={4} sm={4} xs={6} >
                                                <GoalCard item={i} handleGoal={handleGoal} selectedGoal={selectedGoal} />
                                            </Grid>
                                        </>
                                    )
                                    )}
                                </Grid>

                                {!smScreen && <Box sx={{ mt: { xs: "24px", md: "40px" } }} width={"100%"}>
                                    {buttonHtml()}
                                </Box>}

                            </Box>
                            {smScreen && <Box sx={{ p: "8px 16px", mt: "24px", background: "white" }}>
                                {buttonHtml()}
                            </Box>}
                        </Container>
                    </Box>

                    {/* 
                    <Container maxWidth={'lg'} disableGutters={smScreen}>

                        <Typography variant={"h5"} sx={{ p: { xs: '16px', md: "40px 0px 0px 0px" }, background: { xs: "#f9fafb", md: "transparent" } }} >Align your goal</Typography>
                        <Typography sx={{ p: { xs: '16px 16px 0px 16px', md: "16px 0px 16px 0px" } }}>Let's work together to align your life goals and save money in the process!</Typography>


                        <Grid container sx={{ borderRadius: "12px", border: { xs: "none", md: "1px solid #EAECF0" }, background: "#FFF", p: { xs: "16px", md: "24px" } }}>
                            <Grid item md={12} xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                                <Typography variant='h6' sx={{ pb: "8px" }}>Goal</Typography>
                                <input style={otherInputField} placeholder="Enter your goal here" value={otherGoalName} onChange={(e) => setOtherGoalName(e.target.value)} />
                            </Grid>

                            <Grid item md={12} xs={12} sx={{ py: "24px" }}>
                                <Grid container spacing={2} >
                                    {goalArray && goalArray?.map((i: any) => (
                                        <>
                                            <Grid key={i.id} item md={3} sm={4} xs={12} >
                                                <GoalCard item={i} handleGoal={handleGoal} selectedGoal={selectedGoal} />
                                            </Grid>
                                        </>
                                    )
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item md={12} xs={12} >
                                <ButtonRowV1 handleNext={handleNext} disableNext={disableNext} loadingNextBtn={loading} handleBack={handleBack} />
                            </Grid>

                        </Grid>


                    </Container> */}
                </>
            }

        </>
    );
}