import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { NavLink } from 'react-router-dom';
import logo from 'images/Logo1.png';
import { footerOptions } from 'Latest/DashboardScreen/FreshUserScreen/constant';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
}));

const AppFooter = () => {
    const [value, setValue] = React.useState(0);

    return (
        <div className='AppFooter'>
            <Box sx={{ flexGrow: 1, background: "#F8FAFB", px: "3%" }}>
                <Grid container spacing={0} sx={{ justifyContent: { xs: 'center', md: 'space-between' } }}>
                    <Grid item xl={2} md={4} sm={4} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <NavLink to='/'>
                                <img
                                    src={logo}
                                    className="d-inline-block align-top mahaanaLogo"
                                    alt="Mahanna"
                                />
                            </NavLink>
                        </Item>
                    </Grid>
                    <Grid item xl={10} md={8} sm={8} xs={12} sx={{ background: "#F8FAFB" }}>
                        <Item>
                            <BottomNavigation
                                showLabels
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                sx={{ background: "#F8FAFB", justifyContent: { xs: 'center', sm: 'center', md: 'flex-end' }, height: { xs: 'auto', md: '56px' }, flexWrap: { md: 'nowrap', sm: 'nowrap', xs: 'wrap' } }}
                            >
                                {footerOptions.map((item, index) => {
                                    return (
                                        <Typography key={String(index)} sx={footerTabsContentStyle} component={'a'} href={item?.href} target='_blank' rel="noopener noreferrer">{item.title}</Typography>
                                    )
                                })}
                            </BottomNavigation>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default AppFooter;

const footerTabsContentStyle = {
    minWidth: 'fit-content',
    maxWidth: 'fit-content',
    padding: { md: '5px 25px', sm: '5px 20px', xs: '5px 5px' },
    fontSize: { md: '16px', sm: '16px', xs: '14px' },
    fontFamily: 'Inter',
    fontWeight: '500',
    color: '#adadad',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        color: '#4012A0'
    }
}