import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const steps = [
    {
        label: 'Log into your bank app',
        stepLabel: "1",
    },
    {
        label: 'Create a new beneficiary / payee (with IBAN)',
        stepLabel: "2a"
    },
    {
        label: '',
        stepLabel: "OR",
        backgroundColor: "#49BDD8"
    },
    {
        label: 'Select Raast payment (with IBAN)',
        stepLabel: "2b"
    },
    {
        label: 'Select Central Depository Company in the list of banks',
        stepLabel: "3"
    },
    {
        label: 'Enter your Raast Investment IBAN and submit your transaction',
        stepLabel: "4"
    },

];

export default function VerticalLinearStepper() {
    return (
        <Box sx={{ maxWidth: 500, display: 'flex', flexWrap: 'wrap' }}>
            <Typography variant='h5' color={"#1D2939"} pb="14px">Steps to make a transaction:</Typography>
            {steps.map((item, index) => (
                <Box key={index} sx={{ flex: '0 0 100%', }}>
                    <Box
                        sx={{ display: "flex", alignItems: "center", gap: "14px", position: "relative" }}
                    >
                        <Box
                            sx={{
                                minWidth: '36px',
                                height: '36px',
                                borderRadius: '50%',
                                backgroundColor: item.backgroundColor ?? '#281C51',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#fff',
                            }}
                        >
                            {item.stepLabel}
                        </Box>
                        <Typography sx={{ fontSize: "16px", color: "#281C51", position: "absolute", left: 54 }}>{item.label}</Typography>
                    </Box>

                    {index + 1 != steps.length && <Box
                        sx={{
                            borderColor: "#281C51",
                            borderLeftStyle: "solid",
                            borderLeftWidth: "2px",
                            minHeight: "24px",
                            ml: "17px"
                        }}
                    ></Box>}
                </Box>
            ))}
        </Box>
    );
}
