import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useAppSelector } from 'hooks/reduxHooks';
import { links } from 'static/links';

const PrivateRoute = ({ component, render, ...rest }: PrivateRouteProps) => {
  const Component = component ?? render;
  const auth = useAppSelector(state => state.auth);

  return (
    <>
      <Route
        {...rest}
        render={props =>
          auth.isAuth ? (

            <>
              <Component {...rest} auth={auth} />
            </>

          ) : (
            <Redirect
              to={{ pathname: links.login, state: { from: props.location } }}
            />
          )
        }
      />
    </>
  );
};

export default PrivateRoute;

interface PrivateRouteProps extends RouteProps {
  component?: any;
  sideBarActive?: boolean
}
