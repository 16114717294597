import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { assestmentStyle } from '../style'
import PizzaIcon from "Latest/Images/svg/pizzaicon.svg"
import { useAppSelector } from 'hooks/reduxHooks'
import pencilSvg from "Latest/Images/svg/pencil.svg"
import PortfolioCard from './PortfolioRecommendCard'
const AssesssmentCard = ({ RedirectLink, colorGraph, formik, previousStep, selectedPorfolio, setColorGraph, setShowUpdateRiskProfile }: any) => {

    const { user, userAccountDetails } = useAppSelector(state => state.auth);
    const NAME = userAccountDetails?.userName ? userAccountDetails?.userName : user.email

    const handleUpdate = () => {
        setShowUpdateRiskProfile(true)
        RedirectLink == "dashboard" ? previousStep(undefined, 2) : previousStep(undefined, 2)
    }
    const [recommendedColor, setRecommendedColor] = useState<any>(null)

    return (
        <>
            <Box sx={{ borderRadius: "8px", width: "100%" }}>

                <Typography variant='h1' color="#422E84" sx={{ p: "12px 20px" }}>Select portfolio</Typography>
                <Box sx={{ borderBottom: "1px solid rgba(208, 213, 221, 0.25)" }} />
                <Box p="16px">
                    <Typography variant='body1' color="#1D2939">By assessing your risk tolerance we’ve recommended a portfolio that suits you.</Typography>
                    <Typography variant='body1' color="#1D2939">You may also select a different portfolio.</Typography>

                    <PortfolioCard formik={formik} selectedPorfolio={selectedPorfolio} setColorGraph={setColorGraph} colorGraph={colorGraph} setRecommendedColor={setRecommendedColor} recommendedColor={recommendedColor} />

                </Box>



                {/* <Grid item md={12} xs={12} sx={{ mt: "16px", background: "#F9FAFB", borderRadius: '8px' }}>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ background: colorGraph, borderRadius: "8px", width: "6px" }} />

                        <Box sx={{ padding: "14px 23px" }} >
                            <Typography variant='body1' color="#1D2939">You typically seek a balance between risk and reward when making investment decisions. You are comfortable with taking some level of risk to achieve higher returns, but also prefer to avoid significant losses. (Not for Low-risk)</Typography>
                            <Typography variant='body1' color="#667085" sx={{ pt: "14px", display: "flex", alignItems: "center", gap: 1 }}>Don’t agree with this? <Typography variant='h6' color="#2E90FA" sx={{ cursor: "pointer" }} onClick={handleUpdate}><img src={pencilSvg} /> Edit Response</Typography></Typography>
                        </Box>

                    </Box>
                </Grid> */}

                {/* <PortfolioCard formik={formik} selectedPorfolio={selectedPorfolio} setColorGraph={setColorGraph} colorGraph={colorGraph} setRecommendedColor={setRecommendedColor} recommendedColor={recommendedColor} /> */}

            </Box>

        </>
    )
}

export default AssesssmentCard