import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomBreadcrumb from './CustomBreadcrumb'
import backArrowSvg from "Latest/Images/svg/backArrow.svg"

const TopBarHeading = ({ breadcrumbsLinks, chipTitle, handleBackArrow, heading }: props) => {
    return (
        <>
            <Box sx={{ px: { xs: "16px", sm: "32px" }, pt: { xs: "16px", sm: "32px" }, pb: { xs: "16px", sm: "24px" } }} >

                <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                    {handleBackArrow && <img src={backArrowSvg} onClick={handleBackArrow} style={{ cursor: "pointer" }} />}
                    <Typography variant='h5' dangerouslySetInnerHTML={{ __html: heading }} />
                    {chipTitle && <Typography sx={{ display: "flex", p: "2px 10px", alignItems: "center", borderRadius: "16px", background: "#EFF8FF" , color:"#2E90FA" }}>{chipTitle}</Typography>}
                </Box>
                {breadcrumbsLinks && <Box sx={{ pt: "16px" }}><CustomBreadcrumb breadcrumbsLinks={breadcrumbsLinks} /> </Box>}
            </Box>
        </>
    )
}

export default TopBarHeading

interface props {
    heading: string;
    breadcrumbsLinks?: any[];
    handleBackArrow?: any;
    chipTitle?: any
}