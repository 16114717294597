import * as React from 'react';
import { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from 'images/Logo1.png';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { onLogout } from 'redux/actions';
import { Container } from '@mui/material';
import { navBarOptions, sideBarRoute } from 'Latest/DashboardScreen/FreshUserScreen/constant';
import { links } from 'static/links';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { paramFinder, paramRemover } from 'utils/paramFInder';


type Anchor = 'top' | 'left' | 'bottom' | 'right';

const AppHeader = ({ isAuth, scopes }: { isAuth: boolean; scopes: Array<string>; }) => {
    const { push } = useHistory();
    const dispatch = useAppDispatch();
    const logoutHandle = () => dispatch(onLogout())
    
    const { user, userAccountDetails } = useAppSelector((state: any) => state.auth);

    const NAME = userAccountDetails?.userName ? userAccountDetails?.userName : user.email

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    useEffect(() => {

        if (!isAuth) {
            const startDate = paramFinder("startdate");
            const endDate = paramFinder("enddate");
            const value = paramFinder("value")

            if (value==="5") {
                push(`${links.login}?value=${value}&startdate=${startDate}&enddate=${endDate}`);
            } else {
                push(links.login);
            }

        }
    }, [isAuth, push]);
    if (!isAuth) return null;


    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const logout = () => {
        localStorage.clear();
        window.location.reload();
        logoutHandle();
    }

    return (
        <></>
    );
};

export default AppHeader;