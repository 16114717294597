import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, ModalBody, Row, Table, } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


interface IProps {
    openModal: boolean;
    setOpenModal: any;
    setData: any;
    Data: any;
    index: any;
    flag: any;
}
const EditEquity = (props: IProps) => {
    const [editText, setEditText] = useState([...props.Data])
    const EditHandler = () => {
        props.setData(editText)
        // props.setData(
        //     [
        //         {
        //             "id": "630dfcfe9537c744e2c5b88c",
        //             "value": "120",
        //             "label": "Equity"
        //         },
        //         {
        //             "id": "630dfcff9537c744e2c5b88d",
        //             "value": "90",
        //             "label": "Bonds"
        //         },
        //         {
        //             "id": "630dfcff9537c744e2c5b88e",
        //             "value": "90",
        //             "label": "Money Market"
        //         }
        //     ]
        // )
        // alert(props.index)
        props.flag(props.index)
        props.setOpenModal(false)

    }
    return (
        <Modal animation={false} onHide={() => props.openModal} show={props.openModal} >
            <Modal.Header >
                <Modal.Title>Edit Score</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Row>
                    {
                        editText.map((data: any, id: any) => {
                            return (
                                <Col key={id} md="12" style={{ backgroundColor: '' }} >
                                    <p>
                                    </p>
                                    {data.label}
                                    <input
                                        className='form-control h-38 inputBox'
                                        onChange={(e) => { const temp: any = [...editText]; temp[id].value = e.target.value; setEditText(temp) }}
                                        value={data.value}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </ModalBody>
            <Modal.Footer>
                <Button onClick={() => EditHandler()}>
                    Edit
                </Button>
                <Button onClick={() => props.setOpenModal(false)}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>
    )
}
export default EditEquity;