import { Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { tokens } from 'theme';
import { customCard } from 'Latest/GoalDetail/style';

const TabsHeading = ({ heading }: props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Grid item xs={12}
            sx={{
                ...customCard.mainBoxV2, border: 0,
                padding: { md: "32px 32px 10px 32px", xs: "16px 16px 10px 16px" }
            }}
        >
            <Typography variant='h1' color={colors.grey[800]} lineHeight={{ md: "32.02px", xs: "36px" }}>{heading}</Typography>
        </Grid>
    )
}

export default TabsHeading

interface props {
    heading: string;
}