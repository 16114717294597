import { Box, Container, Grid, Typography } from '@mui/material'
import WizardFormBody from 'jsx/components/WizardFormModal/WizardFormBodyLatest'
import React, { useEffect, useState } from 'react'
import { links } from 'static/links'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onGetEquityBasketData } from 'redux/reducers/equityBasket';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import ErrModal from 'Latest/MyProfile/Modal/errModal';


const Index = () => {
    const dispatch = useDispatch()
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
    const [isCFCreated, setIsCFCreated] = useState(false)
    const { push } = useHistory();

    const handleGoalBack = () => {
        push(links.home)
    }

    useEffect(() => {
        const check = dashboardDetail?.goals?.some((item: any) => item.category == "CashFund")
        setIsCFCreated(check)
    }, [])

    const ContentFailedModal = () => {
        return (
            <Grid item xs={12} sx={{ pt: "8px" }}>
                <Typography sx={ModalStyle.headStyle}>Oops!</Typography>
                <Typography sx={ModalStyle.subheadStyle}>
                    Your Mahaana Save+ is already created.
                </Typography>
            </Grid>
        )
    }
    return (
        <>
            <ErrModal handleOpen={() => setIsCFCreated(true)} open={isCFCreated} link={true} handleClose={() => setIsCFCreated(false)} Content={ContentFailedModal} btnTitle={"Go to dashboard"} />

            <WizardFormBody
                goalId={100}
                goalValue={"CashFund"}
                goalName={"Mahanna Cash Account"}
                isThisGoalCreated={true}
                loading={false}
                proceedButtonHandler={() => push(links.accountSetupForm)}
                handleGoalBack={handleGoalBack}
            />
        </>

    )

}

export default Index