export const proofOfIncome = {
    uploadBox: {
        cursor: 'pointer',
        borderRadius: '12px',
        gap: '20px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        py: '29px',
    },
    selectedFileMainBox: {
        borderRadius: "12px", border: "1px solid #EBEFF2", p: "16px"
    },

    selectedFileMapMainBox: { display: "flex", gap: "14px", alignItems: "center", justifyContent: "space-between" , pb:"16px" },

    uploadBtn: {
        borderRadius: "40px",
        border: "1px solid #CDD3D8",
        background: "#FFF",
        color:"#242634"
    }

}