// import DropDown from "jsx/components/Shared/FormikSelectField";
import { links } from 'static/links';
import CheckBox from "jsx/components/Shared/FormikCheckboxField";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Dropdown, Form, Row, Table, } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { parentPort } from "worker_threads";
import axios from 'axios';
import { LocalUrl } from 'redux/middlewares/api';
import EditEquity from 'jsx/components/EditEquityModal/EditEquity';
import { data } from 'static/portfolioPieChartData';
import Loader from 'jsx/components/Shared/Loader';
import { useDispatch } from 'react-redux';
import { onGetHolding } from 'redux/reducers/sharedStateData';
import { useAppSelector } from 'hooks/reduxHooks';


const EquityForm = () => {
    const [inputBox, setInputBox] = useState<any>([]);
    const [inputField, setInputField] = useState<any>([]);
    const [viewFields, setViewFields] = useState<any>([]);
    const [states, setStates] = useState<any>([]);
    const [dropDown, setDropDown] = useState<any>([]);
    const [checkArray, setCheckArray] = useState<any>([]);
    const [edidData, setEditData] = useState<any>([]);
    const [riskFrom, setRiskFrom] = useState('');
    const [loading, setLoading] = useState(true);
    const [riskTo, setRiskTo] = useState('');
    const [name, setName] = useState('');
    const [sum, setSum] = useState(0);
    const [riskSum, setRiskSum] = useState(0);
    const [checkSum, setCheckSum] = useState(0);
    const { holdings } = useAppSelector(state => state.sharedData)
    const [editIndex, setIndex] = useState('');
    const [modalOpen, setModalOpen] = useState(false)
    const [flag, setFlag] = useState('');
    const dispatch = useDispatch();

    const inputHandler = () => {
        const tempArray = [inputField]
        tempArray.map((data: any) => {
            viewFields.push(data)
        });

        // viewFields.push(inputField)
        states.push({
            name: name,
            riskTo: riskTo,
            riskFrom: checkSum
        })
        // setName('');
        setRiskTo('');
        // setRiskFrom('');
        setInputBox([]);
        setInputField([]);
    }
    const AddFormFields = () => {
        setInputField([...inputField, { id: "", value: "", label: "" }])
    }
    const textBoxHandler = (e: any, i: number) => {
        const newFormValues = [...inputField];
        newFormValues[i].value = e.target.value;
        newFormValues[i].label = e.target.name;
        newFormValues[i].id = e.target.id;
        let sum_ = 0;
        inputField.map((val: any) => {
            sum_ = Number(val.value) + Number(sum_);
        })
        setSum(sum_);
        return setInputField(newFormValues);
    }


    useEffect(() => {
        fetchData();
        viewFields[editIndex] = checkArray;
    }, [flag])


    const fetchData = async () => {
        dispatch(onGetHolding())
        // const resp = await axios.get(LocalUrl + 'Holding');
        setLoading(false)
        // if (holdings) {
        // }

        setDropDown(holdings);

    }

    const checkk = () => {
        viewFields[editIndex] = checkArray;
    }

    useEffect(() => {
        const cal = Number(riskTo);
        setRiskSum(cal)
    }, [riskTo, riskFrom])
    const checkAdd = () => {
        setCheckSum(riskSum + checkSum);
    }
    const submitHandler = async () => {
        let temp;
        const bodyArray: any = [];
        states.map((data: any, index: any) => {
            temp = {
                "riskScoreFrom": Number((data.riskFrom) + 1),
                "riskScoreTo": (Number(data.riskTo) + Number(data.riskFrom)),
                "holdingAllocated": viewFields[index],

            };
            bodyArray.push(temp)
        })
        // states.map((data: any, index: any) => {
        //     [
        //         "riskScoreFrom": Number((data.riskFrom) + 1),
        //         "riskScoreTo": (Number(data.riskTo) + Number(data.riskFrom)),
        //         "holdingAllocated": viewFields[index]

        //     ]
        // })
        // // viewFields.map((data: any) => {
        // //     temp = {
        // //         "riskScoreHoldingAllocations": [{
        // //             "riskScoreFrom": 1.0,
        // //             "riskScoreTo": 100.0,
        // //             "holdingAllocated": data,
        // //         }
        // //         ],
        // //         "name": name,
        // //         "isDefault": false
        // //     }
        // // })

        const body = { "riskScoreHoldingAllocations": bodyArray.map((val: any) => val), "name": name, "isDefault": false }
        await axios.post(LocalUrl + 'HoldingAllocation', body)
        setTimeout(() => {
            window.location.reload();
        }, 2000);
        // setViewFields([]);
        // const resp = await axios.post(LocalUrl + 'HoldingAllocation', body)
    }

    return loading === true ?
        <Loader /> :
        <div style={{ backgroundColor: '' }}>
            <p style={{ textAlign: 'end' }}>
                Ramining Score: {100 - checkSum}
            </p>
            {
                modalOpen === true && < EditEquity Data={edidData} flag={setFlag} index={editIndex} openModal={modalOpen} setData={setCheckArray} setOpenModal={setModalOpen} />
            }
            <Card style={{}}>
                <Card.Body>
                    <Row style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    </Row>
                    <Row style={{}}>
                        <Col md="4">
                            <div style={{}}>
                                <strong style={{ fontWeight: 'bold' }}>
                                    Name
                                    <span className='ml-1 text-danger'>
                                        *
                                    </span>
                                    :
                                </strong>
                                <input
                                    className='form-control h-38 inputBox'
                                    onChange={(e) => setName((e.target.value))}
                                    type="name"
                                    value={name}
                                />
                            </div>
                        </Col>
                        <Col md="4">
                            <div style={{}}>
                                <strong style={{ fontWeight: 'bold' }}>
                                    Risk Score From
                                    <span className='ml-1 text-danger'>
                                        *
                                    </span>
                                    :
                                </strong>
                                <input
                                    className='form-control h-38 inputBox'
                                    // onChange={(e) => setRiskFrom((e.target.value))}
                                    type="number"
                                    value={checkSum + 1}
                                />
                            </div>
                        </Col>
                        <Col md="4">
                            <strong style={{ fontWeight: 'bold' }}>
                                Risk Score To
                                <span className='ml-1 text-danger'>
                                    *
                                </span>
                                :
                            </strong>
                            <input
                                className='form-control h-38 inputBox'
                                onChange={(e) => setRiskTo((e.target.value))}
                                type="number"
                                value={riskTo}
                            />
                        </Col>
                        <Col md="3" style={{ marginTop: '1%' }}>
                            <strong style={{ fontWeight: 'bold' }}>
                                Holding
                                <span className='ml-1 text-danger'>
                                    *
                                </span>
                                :
                            </strong>
                            <div style={{}}>
                                <Select
                                    onChange={(e: any) => {
                                        setInputBox([...inputBox, e]);
                                        const index = dropDown.indexOf(e)
                                        dropDown.splice(index, 1); AddFormFields();
                                    }}
                                    options={dropDown}
                                />
                            </div>
                        </Col>
                        <Col md="1" />

                        {
                            inputBox.map((key: any, id: any) => {
                                return (
                                    <Col key={id} md="4" >
                                        <div style={{ marginTop: '4%' }}>
                                            <strong style={{ width: '15rem', fontWeight: 'bold' }}>
                                                {key.label}
                                                <span className='ml-1 text-danger'>
                                                    *
                                                </span>
                                                :
                                            </strong>
                                            <input
                                                className='form-control h-38 inputBox'
                                                id={key.id}
                                                name={key.label}
                                                onChange={(e) => textBoxHandler(e, id)}
                                                type="number"
                                            // value={key.value}
                                            />
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <div style={{ marginTop: '1%' }}>

                        <Form.Check
                            label="Is Default"
                        />
                    </div>
                </Card.Body>
                <Card.Body style={{ textAlign: 'center' }}>
                    <button className="buttonDisable"
                        disabled={(riskTo) === '' || sum < 100 || sum > 100 || name === ""}
                        onClick={() => { inputHandler(); checkAdd(); fetchData(); }} style={{ borderRadius: '10rem', height: '2.5rem', width: '20rem', alignSelf: 'center', color: 'white', backgroundColor: '#603AB0', borderColor: 'white' }}>
                        Add
                    </button>
                    <button className="buttonDisable"
                        disabled={checkSum != 100}
                        onClick={() => submitHandler()} style={{ borderRadius: '10rem', height: '2.5rem', width: '20rem', alignSelf: 'center', color: 'white', backgroundColor: '#603AB0', borderColor: 'white' }}>
                        Submit
                    </button>
                    {/* <button className="buttonDisable"
                        // disabled={ riskSum <= 100 || riskSum > 100}
                        onClick={() => console.log(states)} style={{ borderRadius: '10rem', height: '2.5rem', width: '20rem', alignSelf: 'center', color: 'white', backgroundColor: '#603AB0', borderColor: 'white' }}>
                        check
                    </button> */}

                </Card.Body>

            </Card>
            {
                viewFields.length > 0 ? <Card>
                    <Card.Body >
                        <Table>
                            <thead style={{}} >
                                <tr style={{}}>
                                    <th>Name</th>
                                    <th>Risk Score From</th>
                                    <th>Risk Score To</th>
                                    <th>Holding</th>
                                </tr>
                            </thead>
                            <tbody style={{}}>

                                {viewFields?.map((key: any, id: any) => {
                                    return (
                                        <tr key={''}>
                                            <td>
                                                {name}
                                            </td>
                                            <td>
                                                {(states[id].riskFrom) + 1}
                                            </td>
                                            <td>
                                                {Number(states[id].riskTo) + Number(states[id].riskFrom)}
                                            </td>
                                            {
                                                key?.map((data: any) => {
                                                    return (
                                                        <tr key={id}>
                                                            <td>
                                                                {data.label}
                                                            </td>
                                                            <td>
                                                                {data.value}
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                            <td>
                                                <svg className="bi bi-three-dots-vertical" fill="currentColor" height="16" onClick={() => { setModalOpen(true); setEditData(key); setIndex(id) }} viewBox="0 0 16 16" width="26" xmlns="http://www.w3.org/2000/svg" >
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                    <path d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                </svg>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                    :
                    <h2 style={{ textAlign: 'center' }}>
                        No Data Added
                    </h2>
            }
        </div >

}
export default EquityForm;