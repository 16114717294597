export const formikRadioFieldStyle = {
  label: {

    fontFamily: 'Inter,sans-serif',
    fontSize: '16px',
    color: '#202020'
  },
  formCheck: {
    display: 'flex', fontFamily: 'Inter,sans-serif', fontStyle: "normal",
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: "24px",
    marginTop: 6
  },
  formCheckV1: {
    display: 'flex',
    alignItems:"flex-end",
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: "20.02px",
    color: "#344054",
    letterSpacing: "0.17px",
  }
}

export const formikCheckBoxFieldStyle = {
  formCheckV1: {
    display: 'flex',
    // alignItems:"flex-end",
    fontWeight: 400,
    fontSize: '14px',
    // lineHeight: "20.02px",
    color: "#344054",
    letterSpacing: "0.17px"
  }

}