import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useState } from 'react';

const AdvisorCard = ({ description, imageSrc, name, title }:{description: string, imageSrc: string, name: string, title: string}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
    <Box sx={{ width: '100%', maxWidth: '500px' }}>
    <img src={imageSrc} style={{ width: '100%' }} alt={name} />
    <Typography variant="body1" sx={{ marginTop: '10px', fontWeight: '600' }}>
      {name}
    </Typography>
    <Typography variant="body2" color="#000000">
      {title}
    </Typography>
    <Box sx={{ position: 'relative', marginTop: '20px' }}>
      <Typography
        className={`${isExpanded ? '' : 'EllipsesStyles'}`}
        color="#000000"
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          ...(isExpanded ? {} : { WebkitLineClamp: 3 })
        }}
      >
        {description}
      </Typography>
      <Button
        size="small"
        color='secondary'
        onClick={toggleExpansion}
        sx={{ marginTop: '10px', textTransform: 'none',padding:0 }}
      >
        {isExpanded ? 'Read Less' : 'Read More'}
      </Button>
    </Box>
  </Box>
  );
};

export default AdvisorCard;
