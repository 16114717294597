import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Container, Grid, Stack, Toolbar, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import './index.css';
import { STOCK_DETAIL_BASE_URL } from '../../constants/index';
import { CurrencyFormatter } from 'utils/calculation';

const StockDetail = (props: any) => {
  const [fetchData, setFetchData] = useState<any>([]);
  // const { search } = useLocation();
  // const navigation = usenavi
  // const param =
  const handleFetch = async () => {
    const userID = '2a0f0d35-29eb-4564-b951-0fdfb89195d8';
    const resp = await axios.get(
      `${STOCK_DETAIL_BASE_URL}/api/Stock?id=${props?.location?.state?.stockId}`
    );
    setFetchData(resp?.data);
  };
  useEffect(() => {
    handleFetch();
  }, []);
  return (
    <Box
      component='main'
      sx={{
        backgroundColor: theme =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1
      }}
    >
      <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              <h3>Stock Details</h3>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper
              className='cardanimation'
              sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
            >
              <Grid item xs={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Stock Name and Symbol</th>
                      <th>status</th>
                      <th>Price (PKR)</th>
                      <th>Quantity</th>
                      <th>Sub Total (PKR)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchData?.stockOrders?.map((val: any, index: number) => {
                      // console.log(val);

                      return (
                        <>
                          <tr key={index}>
                            <td>{index}</td>
                            <td>
                              {val.stockName}- {val.stockSymbol}
                            </td>
                            <td>{val.status}</td>
                            <td>{CurrencyFormatter(val.buyRequestPrice, false)}</td>
                            <td>{val.quantity} </td>
                            <td>
                              {CurrencyFormatter(
                                val.buyRequestPrice * val.quantity
                              )}{' '}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    {/* <tr>
                            <td colSpan={4}></td>
                            <td colSpan={1}>Total: 000</td>
                        </tr> */}
                    {/* {fetchData.length === 0 && <p className='text-center'></p>} */}
                  </tbody>
                </Table>
                {/* {fetchData.length === 0 && <p className='text-center nullValues'>No Content Available</p>} */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default StockDetail;
