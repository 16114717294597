import { Avatar, Box, IconButton, Stack, Typography, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { topBarStyle } from './style/topBarStyle'
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { ColorModeContext, tokens } from 'theme';
import NotificationDrawer from 'Latest/Notification';
// import mahaanaFullLogo from "../Images/mahaanaFullLogo.png"
import mahaanaFullLogoSvg from "../Images/svg/logoMahaana.svg"

import SideBarSmallScreen from "./sideBarSmallscreen"
import AvatarMale from "Latest/Images/UserAvatar.png"
import AvatarFemale from "Latest/Images/AvatarFemale.png"
import { useAppSelector } from 'hooks/reduxHooks';
import { Link } from 'react-router-dom';
import TopBarAvatar from './topBarAvatar';

const SmallScreenTopBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { userAccountDetails } = useAppSelector((state: any) => state.auth);

  const gender = userAccountDetails?.identityCard?.gender == "Male"

  const iconWidthHeight = { width: { xs: "31px", sm: "40px" }, height: { xs: "31px", sm: "40px" } }

  return (
    <>
      <Box sx={{
        borderBottom: "1px solid #D0D5DD40", px: { xs: "16px", md: "32px" },
        display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center",
        position: "sticky",
        top: 0,
        minHeight: "57.14px",
        backgroundColor: "white",
        zIndex: 100
      }}>
        <Link to={"/"}>
          <img src={mahaanaFullLogoSvg} style={{ width: "150px", height: "24px" }} />
        </Link>
        <Box>
          <SideBarSmallScreen />
        </Box>
      </Box>

      <Box sx={{padding: "12px", backgroundColor: "#F8FAFB"}} />

      {/* <Box sx={topBarStyle.box}>
        <Box>
          <Typography noWrap variant="h5" sx={{ color: colors.grey[900] }}>
            Your Personal Dashboard
          </Typography>
        </Box>

        <Stack direction="row">
          <Box >
            <IconButton onClick={colorMode.toggleColorMode} sx={{ "&:hover": { transform: "scale(1.1)" } }}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
          </Box>

          <Box>
            <NotificationDrawer iconWidthHeight={iconWidthHeight} />
          </Box>

          <TopBarAvatar />

        </Stack>

      </Box> */}

    </>
  )
}

export default SmallScreenTopBar