import { Box, Grid, Typography } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useRef } from 'react';
import './styling.css';
import { specialRadioFieldChildGrid, specialRadioFieldChildGridTitle, specialRadioFieldLabel } from './style/specialRadioField';

const FormikRadioField = ({
  FieldTouchInput = false,
  col = 6,
  customStyle,
  fieldName,
  fields,
  formik,
  label,
  nestedFieldName,
  required = false,
  singleLine = false,
  styleForm

}: FormikRadioFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  let FieldError = '';
  let FieldTouch = false;
  let NestedFieldError = '';
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }

  const textInput: any = useRef(null);

  const handleClick = (selectedOption: any) => {
    formik.setFieldValue(fieldName, selectedOption);
  }

  return (
    <Grid container spacing={1}>

      {label && <Grid item xs={12}>
        <Typography variant='body2'>
          {label}
          {required && <span className='ml-1 text-danger'>*</span>}
        </Typography>
      </Grid>}

      <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap", cursor: "pointer", rowGap: "8px" }}>
        {fields.map(val => (
          <Grid key={val.label} item md={fields.length == 2 ? 6 : fields.length == 4 ? 6 : 4} xs={12} onClick={() => handleClick(val.value)}>
            <Box sx={{
              ...specialRadioFieldChildGrid,
              padding: fields.length == 2 && val.title ? "2rem" : { md: "16px 32px", xs: "8px 16px" },
              border: formik.values[fieldName] === val.value ? "1px solid #422E84" : "1px solid rgba(208, 213, 221, 0.25)",
              ...customStyle,
              '& .form-check-label': {
                color: formik.values[fieldName] === val.value ? "#422E84" : "#1D2939",
                margin: 0
              }
            }}
            >
              {val.title && <h3 style={specialRadioFieldChildGridTitle}>
                {val.title}
              </h3>}
              <Form.Check
                ref={textInput}
                key={val.label}
                checked={formik.values[fieldName] === val.value}
                className="specialRadio"
                disabled={!!val.disabled}
                id={val?.label?.toString() + label?.toString()}
                label={val.label}
                name={fieldName}
                // onChange={formik.getFieldProps(fieldName).onChange}
                style={{ paddingLeft: 0, ...styleForm }}
                type='radio'
                value={val.value}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      {(FieldTouch || FieldTouchInput) && FieldError && (
        <Grid item xs={12}>
          <span className='ml-2 mt-1 text-danger'>{FieldError}</span>
        </Grid>
      )}
      {NestedFieldTouch && NestedFieldError && (
        <Row>
          <span className='ml-2 mt-1 text-danger'>{NestedFieldError}</span>
        </Row>
      )}
    </Grid >

  );
};

export default memo(FormikRadioField);

interface FormikRadioFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  nestedFieldName?: string;
  customStyle?: any;
  fields: Array<{ label: string; value: string; disabled?: boolean; title?: string }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
    setFieldValue: any
  };
  label?: string;
  required?: boolean;
  singleLine?: boolean;
  styleForm?: any;
  FieldTouchInput?: boolean;
}
