import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Box, Button, Divider, Grid } from '@mui/material'
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import React from 'react'


const BottomBtn = ({ handleNext, handlePrevious, handleSaveExit, loading = false ,nextBtnTitle = "Next" ,showBackBtn = true }: any) => {
    const classes = useStyles();
    return (
        <Grid item xs={12} md={12} >

            <Divider sx={{ borderColor: "#EAECF0" }} />

            <Box sx={{ display: "flex", justifyContent: "space-between", mt: "24px" }}>
                <Box>
                    <LoadingButton loading={loading} variant="outlined" color="secondary" onClick={handleSaveExit}>Save & Exit</LoadingButton>
                </Box>

                <Box sx={{ display: "flex", gap: "8px" }}>
                    {showBackBtn && <LoadingButton  variant='outlined' className={classes.neutralButton} onClick={handlePrevious} >Back</LoadingButton>}
                    <LoadingButton loading={loading} variant="contained" color="secondary" onClick={handleNext}>{nextBtnTitle}</LoadingButton>
                </Box>
            </Box>

        </Grid>
    )
}

export default BottomBtn