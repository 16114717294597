import { Box, Typography, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { onGetDashboardContributionGraph, onGetDashboardAccGraph } from 'redux/reducers/sharedStateData'
import { useDispatch } from 'react-redux';
import { useAppLoading } from 'hooks/appLoading';
import moment from 'moment'
import DashboardAreaChart from 'Latest/ChartV1/DashboardAreaChart';
import NoData from 'Latest/Illustration/noData';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CalenderIcon from 'Latest/Images/svg/calenderIcon.svg';
import { tokens } from 'theme';

const testData3 = {
    "id": "643cf7ad353d639f1c1eed9d",
    "durationMonths": 12,
    "periodicContribution": 500,
    "consumedMonths": 10,
    "returnPercentage": 0,
    "contributionsGraph": [
        {
            "date": "19/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1000.9614936
        },
        {
            "date": "20/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1001.4367896
        },
        {
            "date": "21/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1001.9110954
        },
        {
            "date": "22/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1002.3863914
        },
        {
            "date": "23/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1002.8606972
        },
        {
            "date": "24/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1003.3359932
        },
        {
            "date": "25/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1003.8192108
        },
        {
            "date": "26/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "27/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "28/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "29/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "30/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1006.2491616
        },
        {
            "date": "01/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1006.7175262
        },
        {
            "date": "02/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1007.2195576
        },
        {
            "date": "03/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1007.2195576
        },
        {
            "date": "04/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1007.7087164
        },
        {
            "date": "05/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1007.7087164
        },
        {
            "date": "06/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1007.7087164
        },
        {
            "date": "07/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1009.177183
        },
        {
            "date": "08/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1009.6297044
        },
        {
            "date": "09/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1010.1168828
        },
        {
            "date": "10/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1010.6040612
        },
        {
            "date": "11/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1011.127877
        },
        {
            "date": "12/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1011.7546736
        },
        {
            "date": "13/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1011.7546736
        },
        {
            "date": "14/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1012.8240896
        },
        {
            "date": "15/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1013.3587976
        },
        {
            "date": "16/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1013.9063782
        },
        {
            "date": "17/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1014.4559392
        },
        {
            "date": "18/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1015.0064904
        },
        {
            "date": "19/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1015.5580318
        },
        {
            "date": "20/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1015.5580318
        },
        {
            "date": "21/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1016.6601244
        },
        {
            "date": "22/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1017.2106756
        },
        {
            "date": "23/05/2023",
            "contributionAmount": 2000,
            "portfolioValue": 2017.7634452
        },
        {
            "date": "24/05/2023",
            "contributionAmount": 2000,
            "portfolioValue": 2018.85690304
        },
        {
            "date": "25/05/2023",
            "contributionAmount": 2000,
            "portfolioValue": 2019.95036088
        },
        {
            "date": "26/05/2023",
            "contributionAmount": 2000,
            "portfolioValue": 2021.0418556
        },
        {
            "date": "27/05/2023",
            "contributionAmount": 2000,
            "portfolioValue": 2021.0418556
        },
        {
            "date": "28/05/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1623.22335
        },
        {
            "date": "29/05/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1624.09905
        },
        {
            "date": "30/05/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1624.976325
        },
        {
            "date": "31/05/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1625.7717
        },
        {
            "date": "01/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1626.645825
        },
        {
            "date": "02/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1627.54515
        },
        {
            "date": "03/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1627.54515
        },
        {
            "date": "04/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1629.345375
        },
        {
            "date": "05/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1630.32345
        },
        {
            "date": "06/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1631.2275
        },
        {
            "date": "07/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1632.15045
        },
        {
            "date": "08/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1633.0734
        },
        {
            "date": "09/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1633.9995
        },
        {
            "date": "10/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1633.9995
        },
        {
            "date": "11/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1635.846975
        },
        {
            "date": "12/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1636.769925
        },
        {
            "date": "13/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1632.69126404
        },
        {
            "date": "14/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1633.61041656
        },
        {
            "date": "15/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1634.53117599
        },
        {
            "date": "16/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1635.45193542
        },
        {
            "date": "17/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1635.45193542
        },
        {
            "date": "18/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1637.29184737
        },
        {
            "date": "19/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1638.2126068
        },
        {
            "date": "20/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1639.13175932
        },
        {
            "date": "21/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1640.06537403
        },
        {
            "date": "22/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1640.99095419
        },
        {
            "date": "23/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1641.91653435
        },
        {
            "date": "25/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1643.76930158
        },
        {
            "date": "26/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1637.66911206
        },
        {
            "date": "27/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1638.56439243
        },
        {
            "date": "28/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1638.56439243
        },
        {
            "date": "29/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1638.56439243
        },
        {
            "date": "30/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1638.56439243
        },
        {
            "date": "02/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1643.03915757
        },
        {
            "date": "03/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1643.93280123
        },
        {
            "date": "04/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1644.82480818
        },
        {
            "date": "05/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1645.86575574
        },
        {
            "date": "06/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1646.79868044
        },
        {
            "date": "07/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1646.79868044
        },
        {
            "date": "09/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1649.90842944
        },
        {
            "date": "10/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1650.83808072
        },
        {
            "date": "11/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1651.767732
        },
        {
            "date": "12/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1652.69738328
        },
        {
            "date": "13/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1653.62703456
        },
        {
            "date": "14/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1654.55668584
        },
        {
            "date": "16/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1656.41762511
        },
        {
            "date": "17/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1657.50767397
        },
        {
            "date": "18/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1658.44714551
        },
        {
            "date": "19/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1659.38661705
        },
        {
            "date": "20/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1660.32608859
        },
        {
            "date": "21/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1661.26556013
        },
        {
            "date": "23/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1663.1428665
        },
        {
            "date": "24/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1664.08233804
        },
        {
            "date": "25/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1665.02017287
        },
        {
            "date": "26/07/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81665.9637825
        },
        {
            "date": "27/07/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81723.00860652
        },
        {
            "date": "28/07/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81723.00860652
        },
        {
            "date": "30/07/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81862.61197332
        },
        {
            "date": "31/07/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81909.22666074
        },
        {
            "date": "01/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81955.76111634
        },
        {
            "date": "02/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82002.29557194
        },
        {
            "date": "03/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82049.070723
        },
        {
            "date": "04/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82095.68541042
        },
        {
            "date": "06/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82188.99501708
        },
        {
            "date": "07/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82239.05967276
        },
        {
            "date": "08/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82282.86624648
        },
        {
            "date": "09/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82329.64139754
        },
        {
            "date": "10/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82376.4165486
        },
        {
            "date": "11/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82423.19169966
        },
        {
            "date": "13/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82423.19169966
        },
        {
            "date": "14/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82423.19169966
        },
        {
            "date": "15/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82612.05740394
        },
        {
            "date": "16/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82658.75232318
        },
        {
            "date": "17/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82705.28677878
        },
        {
            "date": "18/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82751.98169802
        },
        {
            "date": "20/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82844.89014558
        },
        {
            "date": "21/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82891.66529664
        },
        {
            "date": "22/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 199938.43880805
        },
        {
            "date": "23/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200051.19895434
        },
        {
            "date": "24/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200163.95910063
        },
        {
            "date": "25/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200276.71924692
        },
        {
            "date": "27/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200502.43295313
        },
        {
            "date": "28/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200615.19309942
        },
        {
            "date": "29/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200727.95324571
        },
        {
            "date": "30/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200840.90680563
        },
        {
            "date": "31/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200955.40767459
        },
        {
            "date": "01/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201068.16782088
        },
        {
            "date": "03/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201294.07494072
        },
        {
            "date": "04/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201407.02850064
        },
        {
            "date": "05/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201522.88326501
        },
        {
            "date": "06/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201650.92308807
        },
        {
            "date": "07/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201765.81078429
        },
        {
            "date": "08/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201879.73141236
        },
        {
            "date": "10/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202107.76608213
        },
        {
            "date": "11/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202219.55916027
        },
        {
            "date": "12/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202331.35223841
        },
        {
            "date": "13/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202442.95190292
        },
        {
            "date": "14/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202442.95190292
        },
        {
            "date": "15/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202553.77791291
        },
        {
            "date": "16/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202664.6039229
        },
        {
            "date": "17/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202891.86493815
        },
        {
            "date": "18/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202891.86493815
        },
        {
            "date": "19/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203005.20532533
        },
        {
            "date": "20/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203118.93253977
        },
        {
            "date": "21/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203232.46634058
        },
        {
            "date": "22/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203346.19355502
        },
        {
            "date": "23/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203459.5339422
        },
        {
            "date": "24/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203685.8278893
        },
        {
            "date": "25/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203798.20120833
        },
        {
            "date": "26/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203910.1877001
        },
        {
            "date": "27/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204023.72150091
        },
        {
            "date": "28/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204173.81047779
        },
        {
            "date": "29/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204173.81047779
        },
        {
            "date": "01/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204511.31726214
        },
        {
            "date": "02/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204623.8839948
        },
        {
            "date": "03/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204736.64414109
        },
        {
            "date": "04/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204854.62645539
        },
        {
            "date": "05/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205050.55446258
        },
        {
            "date": "06/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205167.3762951
        },
        {
            "date": "08/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205400.82654651
        },
        {
            "date": "09/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205541.43825552
        },
        {
            "date": "10/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205655.93912448
        },
        {
            "date": "11/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205772.18071611
        },
        {
            "date": "12/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205888.03548048
        },
        {
            "date": "13/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 206004.08365848
        },
        {
            "date": "15/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 206235.98660085
        },
        {
            "date": "16/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 206352.03477885
        },
        {
            "date": "17/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 206467.69612959
        },
        {
            "date": "18/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 207416.19657111
        },
        {
            "date": "19/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 207544.23639417
        },
        {
            "date": "20/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 207671.69597634
        },
        {
            "date": "22/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 207913.8498411
        },
        {
            "date": "23/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 208041.5028369
        },
        {
            "date": "24/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 208162.57976928
        },
        {
            "date": "25/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 214773.12149566
        },
        {
            "date": "26/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 214905.09340674
        },
        {
            "date": "27/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 215032.08148432
        },
        {
            "date": "29/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 215285.6589328
        },
        {
            "date": "30/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 215422.2159707
        },
        {
            "date": "31/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 215789.82352966
        },
        {
            "date": "01/11/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 215927.77604094
        },
        {
            "date": "02/11/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 216523.84252754
        },
        {
            "date": "03/11/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 216653.2228452
        },
        {
            "date": "05/11/2023",
            "contributionAmount": 359509.1406234,
            "portfolioValue": 371418.59885325
        },
        {
            "date": "06/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 370691.00551974
        },
        {
            "date": "07/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 370961.9956483
        },
        {
            "date": "08/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 371163.19560556
        },
        {
            "date": "09/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 371402.52482714
        },
        {
            "date": "10/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 371675.89803472
        },
        {
            "date": "12/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 372105.53313804
        },
        {
            "date": "13/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 372343.50060018
        },
        {
            "date": "14/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 372560.70123086
        },
        {
            "date": "15/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 372795.2642944
        },
        {
            "date": "16/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 358013.1441664
        },
        {
            "date": "17/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 358239.25497956
        },
        {
            "date": "19/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 358657.49463396
        },
        {
            "date": "20/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 358866.94121089
        },
        {
            "date": "21/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 359092.07177486
        },
        {
            "date": "22/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 359303.47885017
        },
        {
            "date": "23/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 359480.90395356
        },
        {
            "date": "24/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 359707.66826618
        },
        {
            "date": "26/11/2023",
            "contributionAmount": 194009.1406234,
            "portfolioValue": 210499.129085
        },
        {
            "date": "27/11/2023",
            "contributionAmount": 194009.1406234,
            "portfolioValue": 210622.55223
        },
        {
            "date": "28/11/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365176.69394203
        },
        {
            "date": "29/11/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365288.59160349
        },
        {
            "date": "30/11/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365252.17520479
        },
        {
            "date": "01/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365301.17181395
        },
        {
            "date": "03/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365733.53378397
        },
        {
            "date": "04/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365933.49291865
        },
        {
            "date": "05/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 366150.99813634
        },
        {
            "date": "06/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 366350.29515468
        },
        {
            "date": "07/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 366550.25428936
        },
        {
            "date": "08/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 366749.88236587
        },
        {
            "date": "12/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 367557.3332425
        },
        {
            "date": "13/12/2023",
            "contributionAmount": 347438.1406234,
            "portfolioValue": 359114.86391616
        },
        {
            "date": "14/12/2023",
            "contributionAmount": 347438.1406234,
            "portfolioValue": 359311.26693132
        },
        {
            "date": "15/12/2023",
            "contributionAmount": 346938.1406234,
            "portfolioValue": 359031.33536078
        },
        {
            "date": "17/12/2023",
            "contributionAmount": 346938.1406234,
            "portfolioValue": 359457.89069066
        },
        {
            "date": "18/12/2023",
            "contributionAmount": 346938.1406234,
            "portfolioValue": 359661.52263206
        },
        {
            "date": "19/12/2023",
            "contributionAmount": 346938.1406234,
            "portfolioValue": 359812.63896752
        },
        {
            "date": "20/12/2023",
            "contributionAmount": 346938.1406234,
            "portfolioValue": 359987.3337383
        },
        {
            "date": "21/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210111.11317518
        },
        {
            "date": "22/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210222.60183588
        },
        {
            "date": "24/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210462.66713892
        },
        {
            "date": "25/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210582.69979044
        },
        {
            "date": "26/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210693.98006112
        },
        {
            "date": "27/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210790.25625036
        },
        {
            "date": "28/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210707.73380244
        },
        {
            "date": "29/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210762.33198768
        },
        {
            "date": "31/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210844.8544356
        },
        {
            "date": "01/01/2024",
            "contributionAmount": 46938.1406234,
            "portfolioValue": 60880.5784323
        },
        {
            "date": "02/01/2024",
            "contributionAmount": 46938.1406234,
            "portfolioValue": 60912.6912966
        },
        {
            "date": "03/01/2024",
            "contributionAmount": 201621.1406234,
            "portfolioValue": 215595.695211
        },
        {
            "date": "04/01/2024",
            "contributionAmount": 201621.1406234,
            "portfolioValue": 215736.38790425
        },
        {
            "date": "05/01/2024",
            "contributionAmount": 101621.1406234,
            "portfolioValue": 115803.52507586
        },
        {
            "date": "07/01/2024",
            "contributionAmount": 101621.1406234,
            "portfolioValue": 115937.35221642
        },
        {
            "date": "08/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 15946.30246926
        },
        {
            "date": "09/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 15946.30246926
        },
        {
            "date": "10/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 15963.86712132
        },
        {
            "date": "11/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 15973.20668162
        },
        {
            "date": "12/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 15982.56193866
        },
        {
            "date": "14/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16001.27245274
        },
        {
            "date": "15/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16011.64799788
        },
        {
            "date": "16/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16020.92477122
        },
        {
            "date": "17/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16029.85621628
        },
        {
            "date": "18/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16039.19577658
        },
        {
            "date": "19/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16048.28418904
        },
        {
            "date": "21/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16066.72785854
        },
        {
            "date": "22/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16075.64360686
        },
        {
            "date": "23/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16085.97206178
        },
        {
            "date": "24/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16094.5738753
        },
        {
            "date": "25/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16103.48962362
        },
        {
            "date": "26/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16112.84488066
        },
        {
            "date": "28/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16131.02170558
        },
        {
            "date": "29/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16140.06302782
        },
        {
            "date": "30/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16129.4206381
        },
        {
            "date": "31/01/2024",
            "contributionAmount": 2121.1406234,
            "portfolioValue": 16648.44099675
        },
        {
            "date": "01/02/2024",
            "contributionAmount": 2121.1406234,
            "portfolioValue": 16667.844114
        },
        {
            "date": "02/02/2024",
            "contributionAmount": 157627.1406234,
            "portfolioValue": 172173.84718872
        },
        {
            "date": "04/02/2024",
            "contributionAmount": 157627.1406234,
            "portfolioValue": 172470.05997156
        },
        {
            "date": "05/02/2024",
            "contributionAmount": 157627.1406234,
            "portfolioValue": 172569.85626465
        },
        {
            "date": "06/02/2024",
            "contributionAmount": 2627.1406234,
            "portfolioValue": 17569.8511992
        },
        {
            "date": "07/02/2024",
            "contributionAmount": 2627.1406234,
            "portfolioValue": 17590.12125696
        },
        {
            "date": "08/02/2024",
            "contributionAmount": 2627.1406234,
            "portfolioValue": 17600.09460192
        },
        {
            "date": "09/02/2024",
            "contributionAmount": 2627.1406234,
            "portfolioValue": 17610.1019856
        },
        {
            "date": "11/02/2024",
            "contributionAmount": 2627.1406234,
            "portfolioValue": 17630.15079168
        },
        {
            "date": "12/02/2024",
            "contributionAmount": 0,
            "portfolioValue": 7630.15418928
        },
        {
            "date": "13/02/2024",
            "contributionAmount": 0,
            "portfolioValue": 7638.33760419
        },
        {
            "date": "14/02/2024",
            "contributionAmount": 0,
            "portfolioValue": 7642.52875008
        },
        {
            "date": "15/02/2024",
            "contributionAmount": 0,
            "portfolioValue": 7632.77641764
        },
        {
            "date": "16/02/2024",
            "contributionAmount": 0,
            "portfolioValue": 7650.62377527
        }
    ]
}

const GraphSection = ({ Data }: any) => {
    const dispatch = useDispatch();
    const [graphData, setGraphData] = useState<any>([])
    // const loading = useAppLoading('sharedStateData');
    const [loading, setLoading] = useState(true)
    const [apiData, setApiData] = useState<any>()

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const endDate = new Date();

    const [period, setPeriod] = useState("1")

    const getDataByContribution = () => {
        const onSuccess = (message: string, response: any) => {
            setApiData(message)
            setLoading(false)
        }
        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
            setApiData([])
            setLoading(false)
        }
        dispatch(onGetDashboardContributionGraph(period, onSuccess, onFail))
    }

    useEffect(() => {
        getDataByContribution()
    }, [period])

    useEffect(() => {
        CustomXAxis()
    }, [apiData])


    function CustomXAxis() {
        if (apiData?.length > 0) {
            const dataContribution = apiData && apiData?.map((item: any, index: number): any => ({
                'date': moment(item.date, "YYYYMMDD").valueOf(),
                "contributionAmount": item?.contributionAmount, "portfolioValue": item?.portfolioValue
            }));
            if (apiData?.length > 1) {
                setGraphData(dataContribution);
            }
        }
    }

    const filterArr = [
        { title: "1M", value: "1", titleSm: "1M" },
        { title: "3M", value: "3", titleSm: "3M" },
        { title: "6M", value: "6", titleSm: "6M" },
        { title: "1Y", value: "12", titleSm: "1Y" },
        { title: "Since inception", value: "240", titleSm: "All" },
    ]


    return (
        <>
            <Box>
                {loading ?
                    <Skeleton variant="rectangular" sx={{ height: { xs: 212, sm: 400 } }} />
                    :
                    <>
                        {!smScreen && <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ pb: 1 }}>
                                {filterArr.map((item) => {
                                    return (
                                        <Button key={item.title} onClick={() => setPeriod(item.value)}
                                            sx={{
                                                background: period == item.value ? "#EFF8FF !important" : "white",
                                                color: period == item.value ? "#2E90FA !important" : "#667085",
                                                border: "1px solid #D0D5DD40",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                lineHeight: "18.2px",
                                                borderRadius: "8px",
                                                textTransform: "none",
                                                padding: "10px 12px",
                                                "&:hover": {
                                                    border: "1px solid #D0D5DD40 !important",
                                                },
                                            }}
                                        >
                                            {!mdScreen ? item.titleSm : item.title}
                                        </Button>
                                    )
                                })}
                            </ButtonGroup>
                        </Box>}

                        {graphData.length > 3 ? graphData &&
                            <DashboardAreaChart data={graphData} hideXAxis={smScreen} hideYAxis={smScreen} hideLegends={!smScreen} tab={1} />
                            :
                            <NoData message={"Your graph will be shown 3 days after a transaction is verified"} />
                        }

                        {smScreen && <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mt: "32px", rowGap: "10px", flexWrap: "wrap" }}>
                            {filterArr.map((item) => {
                                return (
                                    <Button key={item.title} onClick={() => setPeriod(item.value)}
                                        sx={{
                                            background: period == item.value ? colors.purpleAccent[100] : colors.background[100],
                                            color: period == item.value ? colors.background[100] : colors.grey[5],
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            borderRadius: "100px",
                                            textTransform: "none",
                                            width: "65.6px",
                                            padding: 0,
                                            "&:hover": {
                                                ...(smScreen && {
                                                    background: period === item.value ? colors.purpleAccent[100] : colors.background[100],
                                                    color: period === item.value ? colors.background[100] : colors.grey[5],
                                                })
                                            },
                                        }}
                                    >
                                        {!mdScreen ? item.titleSm : item.title}
                                    </Button>
                                )
                            })}
                        </Box>}
                    </>
                }
            </Box >
        </>
    )
}

export default GraphSection


