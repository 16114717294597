import * as Yup from 'yup';

export const fatcaValidationSchema = Yup.object().shape({
    usResident: Yup.string()
        .required("This field is Required"),
    usCitizen: Yup.string()
        .required("This field is Required"),
    hasGreenCard: Yup.string()
        .required("This field is Required"),
    hasTaxPayer: Yup.string()
        .required("This field is Required"),
    wNineForm: Yup.mixed()
        .test('required', '', function (value, context: any) {
            const isWNineForm = context?.from[0]?.value?.hasGreenCard === "Yes" || context?.from[0]?.value?.hasTaxPayer === "Yes" || context?.from[0]?.value?.usCitizen === "Yes" || context?.from[0]?.value?.usResident === "Yes"
            if (isWNineForm) {
                if (!value) {
                    return this.createError({
                        message: "This field is Required"
                    });
                }else if (value && value === "No"){
                    return this.createError({
                        message: "This field is Required"
                    });
                }
                else if (value && value.size > 10000000) {
                    return this.createError({
                        message: "File size should be 10 MB"
                    });
                }
                else if (value && value.size < 5000) {
                    return this.createError({
                        message: "File size should be 5 KB"
                    });
                }
                else if (value == undefined) {
                    return this.createError({
                        message: "W9 Form is required"
                    });
                }
                return true
            }
            return true
        }),
    hasTaxResident: Yup.string()
        .required("This field is Required"),
    country: Yup.string()
        .min(4, "Country is Invalid")
        .max(300, "Country is Invalid")
        .test('required', '', function (value, context: any) {
            const isResident = context?.from[0]?.value?.hasTaxResident === "Yes"
            if (isResident) {
                if (!value) {
                    return this.createError({
                        message: "This field is Required"
                    });
                }
                return true
            }
            return true
        }),
    city: Yup.string()
        .min(4, "City is Invalid")
        .max(300, "City is Invalid")
        .test('required', '', function (value, context: any) {
            const isResident = context?.from[0]?.value?.hasTaxResident === "Yes"
            if (isResident) {
                if (!value) {
                    return this.createError({
                        message: "This field is Required"
                    });
                }
                return true
            }
            return true
        }),
    hasTaxpayerNum: Yup.string()
        .test('required', '', function (value, context: any) {
            const isResident = context?.from[0]?.value?.hasTaxResident === "Yes"
            if (isResident) {
                if (!value) {
                    return this.createError({
                        message: "This field is Required"
                    });
                }
                return true
            }
            return true
        }),
    taxPayerNumber: Yup.string()
        .min(4, "Tin is Invalid")
        .max(100, "Tin is Invalid")
        .test('required', '', function (value, context: any) {
            const isResident = context?.from[0]?.value?.hasTaxResident === "Yes"
            const hasTaxPayNum = context?.from[0]?.value?.hasTaxpayerNum === "Yes"
            if (isResident && hasTaxPayNum) {
                if (!value) {
                    return this.createError({
                        message: "This field is Required"
                    });
                }
                return true
            }
            return true
        }),
    selectReason: Yup.string()
        .test('required', '', function (value, context: any) {
            const isResident = context?.from[0]?.value?.hasTaxResident === "Yes"
            const hasTaxPayNum = context?.from[0]?.value?.hasTaxpayerNum === "No"
            if (isResident && hasTaxPayNum) {
                if (!value) {
                    return this.createError({
                        message: "This field is Required"
                    });
                }
                return true
            }
            return true
        }),
    confirmAll: Yup.array()
        .test('required', '', function (value, context) {
            if (!value?.length) {
                return this.createError({
                    message: "This field is Required"
                });
            }
            return true
        }),

})