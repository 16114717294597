/* eslint-disable @typescript-eslint/no-explicit-any */
export const convertStreamToBase64 = (blob: any) => {
    return new Promise((resolve, reject) => {
        const reader: any = new FileReader();

        reader.onload = () => {
            const base64String = reader.result;
            resolve(base64String);
        };

        reader.onerror = (error: any) => {
            reject(error);
        };

        reader.readAsDataURL(blob);
    });
}