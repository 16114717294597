import { memo, useState, useEffect } from 'react';
import { Card, Col, Row, Spinner, NavDropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { links } from 'static/links';
import BorderButton from '../Border Button/BorderButton';
import Performance from '../FundDetails/Performance';
import RiskMeter from '../FundDetails/RiskMeter';
import '../FundDetails/style.css';
import YieldTable from '../FundDetails/YieldTable';
import {
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  ResponsiveContainer,
  Area,
  AreaChart,
  CartesianGrid
} from 'recharts';
// import './thematic.css';
import UpArrow from '../../../images/upArrow.png';
import DownArrow from '../../../images/downArrow.png';
import Loader from '../Shared/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import axios from 'axios';
import Big from 'big.js';
import Grid from '@mui/material/Grid';
import {
  Button,
  ButtonGroup,
  Container,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import ConfirmationModal from '../Shared/ConfirmationModal';
import { STOCK_DETAIL_BASE_URL } from '../../../constants/index';
import { getFromYmdNumToDmyFormat } from 'utils/getDateDMYFormat';
import './styles/table.scss';
import { CurrencyFormatter } from 'utils/calculation';
import { onGetBasketHistoryData } from 'redux/reducers/basketHistory';
import LoaderWithText from '../Shared/LoaderWithText';
import { PostStock } from 'redux/reducers/equityBasket';
import { performanceGraphMainBox, performanceGraphOption } from './styles';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const TabContent = ({
  basketId,
  basketName,
  bg,
  items,
  title
}: TabContentProps) => {
  
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { push } = useHistory();
  const [confirmatonModal, setConfirmatonModal] = useState(false);
  const [stockID, setStockID] = useState<any>('');
  const { user } = useAppSelector(state => state.auth);
  const { userAccountInfo } = useAppSelector(state => state.auth);
  const { allDashboardData } = useAppSelector(state => state.dashboard);
  const [basketData, setBasketData] = useState([]);
  const { allBasketHistoryData } = useAppSelector(state => state.basketHistory);

  const basketPerformanceHistory = basketData;

  const activeBar = () => ({
    borderLeft: `7px solid ${bg}`,
    paddingLeft: 3,
    paddingRight: 0
  });
  const handleInvest = async () => {
    const body = {
      basketId: basketId,
      basketName: basketName,
      type: 'Basket'
    };

    const onSuccess = () => {
      toast.success(`Your basket execution is in process`);
      return;
      setConfirmatonModal(false)
    }

    dispatch(PostStock(body, onSuccess))

  };

  const formatXAxis = (value: any, index: number) => {
    const str = value.toString();
    const y = Number(str.substring(0, 4));
    const m = Number(str.substring(4, 6));
    const d = Number(str.substring(6, 8));

    const date = new Date(y, m, d);
    if (date.getMonth() == 0) {
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    } else {
      return `${date.getFullYear()}`;
    }
  };

  useEffect(() => {
    if (!!allBasketHistoryData) {
      setBasketData(allBasketHistoryData[0]?.basketHistoricalData);
    }
  }, [allBasketHistoryData]);

  useEffect(() => {
    if (basketName !== undefined) {
      dispatch(onGetBasketHistoryData(basketName));
    }
  }, [basketName]);

  useEffect(() => {
    const data =
      allDashboardData?.goalSummary?.goalsPercentage?.map((val: any) => ({
        name: val.key,
        value: val.value
      })) ?? [];
    // setPieData(data);
    if (allDashboardData?.portfolioHistory?.length >= 2) {
      const oneDayChangeValue =
        allDashboardData.portfolioHistory[
          allDashboardData.portfolioHistory.length - 1
        ].value -
        allDashboardData.portfolioHistory[
          allDashboardData.portfolioHistory.length - 2
        ].value;
      // setOneDayChange(oneDayChangeValue);
    }
  }, [allDashboardData]);

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: theme =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1
      }}
    >
      {confirmatonModal && (
        <ConfirmationModal
          basketName={basketName}
          handleCancel={() => setConfirmatonModal(false)}
          handleConfirm={() => handleInvest()}
          show={confirmatonModal}
        />
      )}
      <Container
        maxWidth='xl'
        sx={{ mt: 4, mb: 4, minHeight: 390, maxHeight: 'auto' }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            xs={12}
          >
            <Grid item lg={8} md={8} xs={12}></Grid>
            <Grid
              item
              lg={4}
              md={4}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
              xs={12}
            >
              <BorderButton
                ClickHandler={() => setConfirmatonModal(true)}
                IsBorder={false}
                Text=' Invest Now'
                disabled={userAccountInfo.isNewUser}
              />
            </Grid>
          </Grid>

          <Grid item lg={8} md={8} xs={12}>
            <Paper
              className='cardanimation'
              sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
            >
              <Box sx={performanceGraphMainBox}>
                <Typography>
                  <h3 className='tableProduct_header_heading'> Performance</h3>
                </Typography>

                <ButtonGroup aria-label='outlined button group' variant='outlined'>
                  <Button sx={{ m: '1rem' }}>3 Month</Button>
                  <Button sx={performanceGraphOption}>6 Month</Button>
                  <Button>All</Button>
                </ButtonGroup>

              </Box>
              <ResponsiveContainer height={270} width='100%'>
                <AreaChart
                  data={basketPerformanceHistory}
                  height={250}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  width={730}
                >
                  {/* <CartesianGrid stroke="#ccc" strokeDasharray="5 5" /> */}
                  <XAxis
                    allowDuplicatedCategory={false}
                    axisLine={false}
                    dataKey='key'
                    tick={{ fill: '#899CA8' }}
                    tickFormatter={formatXAxis}
                    type='category'
                  />
                  <YAxis
                    allowDataOverflow={true}
                    allowDecimals={false}
                    axisLine={false}
                    domain={[
                      (dataMin: number) =>
                        dataMin > 0 && isFinite(dataMin)
                          ? new Big(dataMin).mul(0.99).round()
                          : 0,
                      (dataMax: number) =>
                        dataMax > 0 && isFinite(dataMax)
                          ? new Big(dataMax).mul(1.01).round()
                          : 0
                    ]}
                    interval={'preserveStartEnd'}
                    tickCount={20}
                    tickFormatter={(value, index) =>
                      CurrencyFormatter(value, false, 'never', false)
                    }
                    type={'number'}
                  />
                  <defs>
                    <linearGradient id='colorUv' x1='0' x2='0' y1='0' y2='1'>
                      <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#8884d8' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                      color='red'
                      id='colorPv'
                      x1='0'
                      x2='0'
                      y1='0'
                      y2='1'
                    >
                      <stop offset='5%' stopColor='#82ca9d' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#82ca9d' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Tooltip
                    formatter={(value: string, name: string, props: any) => [
                      CurrencyFormatter(Number(value), true),
                      name
                    ]}
                    labelFormatter={(label: any, payload: Array<any>) =>
                      getFromYmdNumToDmyFormat(label)
                    }
                  />
                  <Area
                    dataKey='value1'
                    fill='url(#colorUv)'
                    fillOpacity={1}
                    name='Stock price'
                    stroke='#443087'
                    type='monotone'
                  />
                  <Area
                    dataKey='value2'
                    fill='url(#colorPv)'
                    fillOpacity={1}
                    legendType='line'
                    name='PSX index'
                    stroke='#443087'
                    type='monotone'
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
            <Paper
              className='cardanimation'
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                minHeight: 250,
                maxHeight: 'auto'
              }}
            >
              <YieldTable
                data={items?.pricesYieldList}
                heading={'Basket Snapshot'}
              />
            </Paper>
          </Grid>

          <Grid item lg={8} md={8} xs={12}>
            <Paper
              className='cardanimation'
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                minHeight: 390,
                maxHeight: 'auto'
              }}
            >
              <h3 className='tableProduct_header_heading'>
                Basket Constituents
              </h3>
              <div className='table-responsive'>
                <table className='table  cursor-pointer'>
                  <thead className='text-black'>
                    <tr>
                      <th
                        className='tableProduct_header'
                        style={{ textTransform: 'unset', color: '#6E7C87' }}
                      >
                        Shares
                      </th>
                      <th
                        className='tableProduct_header'
                        style={{ textTransform: 'unset', color: '#6E7C87' }}
                      >
                        Weight  (%){' '}
                      </th>
                      <th
                        className='tableProduct_header'
                        style={{ textTransform: 'unset', color: '#6E7C87' }}
                      >
                        Last Price (PKR)
                      </th>
                      <th
                        className='tableProduct_header'
                        style={{ textTransform: 'unset', color: '#6E7C87' }}
                      >
                        Net Change (%){' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400'
                    }}
                  >
                    {items?.stockList?.map((names: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className='tableProduct_text'>
                              {names.name}
                            </span>
                          </td>

                          <td className='tableProduct_text'>
                            {names.weightage.toFixed(2)}%
                          </td>
                          <td className='tableProduct_text'>
                            {' '}
                            {CurrencyFormatter(
                              Number(names?.price),
                              false
                            )}{' '}
                          </td>
                          <td className='tableProduct_text'>
                            <div className='d-flex'>
                              <div style={{ width: '60px' }}>
                                {names.netChange}%
                              </div>
                              <div>
                                {names.netChange > 0 ? (
                                  <img src={UpArrow} />
                                ) : (
                                  <img src={DownArrow} />
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Paper>
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
            <Paper
              className='cardanimation'
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                minHeight: 390,
                maxHeight: 'auto'
              }}
            >
              <Typography
                sx={{ width: '100%', marginBottom: '1rem', marginTop: '1rem' }}
              >
                <h3 className='tableProduct_header_heading'> Overview</h3>
              </Typography>
              <div
                style={{
                  display: 'flex',
                  padding: ' 0.25rem 2rem 0.25rem 0rem'
                }}
              >
                <p className='tableProduct_over_text'>
                  {items?.productSummary}
                </p>
              </div>
              {/* <ul >
                {items?.productSummary?.map((points: any, index: any) => {
                  return (
                    <div key={index} style={{ display: 'flex', padding: ' 0.25rem 2rem 0.25rem 0rem' }}>
                      <p className='tableProduct_over_text'>
                        {points}
                      </p>
                    </div>
                  )
                })}
              </ul>  */}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default memo(TabContent);

interface TabContentProps {
  bg: string;
  title: string;
  items: any;
  basketName?: string;

  basketId: any;
}
