import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import GoalsList from 'jsx/components/GoalsList';
import { memo, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useAppLoading } from 'hooks/appLoading';
import WizardFormModal from './WizardFormModal';
import { getInvestorGoals, getUserAccountInfo, onGetAccountSetup, onGetUserQuestionAnswers,onGetUserPendingRiskProfileQuestion } from 'redux/actions';
import AddGoalModal from 'jsx/components/Modals/AddGoal';
import Loader from 'jsx/components/Shared/Loader';
import { QuestionAnswerCategoryEnum } from '../../constants';
import { links } from 'static/links';
import { useHistory } from 'react-router-dom';

const GoalsOverview = () => {
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const authLoading = useAppLoading('auth');
  const portfolioLoading = useAppLoading('portfolio');
  const questionAnswerLoading = useAppLoading('questionAnswer');
  const { user } = useAppSelector(state => state.auth);
  const [isAddGoalModalOpen, setIsAddGoalModalOpen] = useState(false)
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [goalId, setGoalId] = useState<number>(0);
  const [goalValue, setGoalValue] = useState<string>('');
  const [goalName, setGoalName] = useState<string>('');

  useEffect(() => {
    dispatch(getInvestorGoals(user?.id));
  }, [dispatch]);

  const onSelectGoal = (goalId: number, goalValue: string, goalName: string) => {
    setGoalId(goalId);
    setGoalValue(goalValue);
    setGoalName(goalName);
    setIsWizardOpen(true)
  }

  const onChangeModalState = () => {
    setIsWizardOpen(false);
    dispatch(onGetAccountSetup());
    // dispatch(getUserAccountInfo(user?.id));
    dispatch(getInvestorGoals(user?.id));
    dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.RiskProfile));
    dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.AccountSetup));
    dispatch(onGetUserPendingRiskProfileQuestion('RiskProfile'))
  }

  return (
    <>
    {
      authLoading && <div className='text-center p-5'>
      <Loader width={200} />
    </div>
    }
      {isAddGoalModalOpen &&
        <AddGoalModal
          onSelectGoal={(id: number, val: string, name: string) => onSelectGoal(id, val, name)}
          setShow={setIsAddGoalModalOpen}
          show={isAddGoalModalOpen}
        />
      }
      <ToastContainer
        closeOnClick
        draggable
        pauseOnFocusLoss
        pauseOnHover
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        position='bottom-right'
        rtl={false}
      />
      <GoalsList
        setIsAddGoalModalOpen={setIsAddGoalModalOpen}
      />
      {(isWizardOpen && goalId > 0) &&
        <WizardFormModal
          goalId={goalId}
          goalName={goalName}
          goalValue={goalValue}
          modalOpen={isWizardOpen}
          proceedButtonHandler={() => push(links.accountSetupForm)}
          setModalOpen={onChangeModalState}
        />
      }
    </>
  );
};
export default memo(GoalsOverview);
