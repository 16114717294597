import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { ProjectionStyles } from '../Style/style'
import ProjectionChart from './ProjectChart'
import { CurrencyFormatter, CurrencyFormatterWithDecimal } from 'utils/calculation'
import { mahaanaSaveRate } from '../../../../../constants'
import FormikInputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import { useAppSelector } from 'hooks/reduxHooks'


const Projection = ({
    formik,
    handleInterestCompute,
    initialAmount,
    interestData,
    isEmployer,
    monthlySaving,
    planType,
    retirementAmount
}: any) => {

    const intialInvestment = formik.values.initial_amount
    const projectedValue = formik.values.future_values || formik.values.initial_amount || 0
    const newDate = new Date()
    const currentYear = newDate.getFullYear();
    const projectedYear = currentYear + formik.values.desired_retirement_age
    const { userAccountDetails } = useAppSelector(state => state.auth);

    return (
        <>
            <Grid item md={12} xs={12} sx={{ p: "12px 20px", borderBottom: "1px solid rgba(208, 213, 221, 0.25)" }}>
                <Typography variant='h1' color="#422E84" sx={{ fontSize: { md: "24px", xs: "16px" } }}>Projections</Typography>
            </Grid>

            <Grid item md={8} xs={12} sx={{ p: { xs: "16px", md: "24px" }, borderRight: "1px solid rgba(208, 213, 221, 0.25)" }}>

                <ProjectionChart
                    formik={formik}
                    initialAmount={initialAmount}
                    monthlySaving={monthlySaving}
                    retirementAmount={retirementAmount}
                    planType={planType}
                    handleInterestCompute={handleInterestCompute}
                    isEmployer={isEmployer}
                    interestData={interestData}
                    projectionProfitPercentage={mahaanaSaveRate}
                    colorGraph={"#403081"}
                    // colorGraph={"#422E84"}
                />

                <Box sx={{ p: "8px 12px", background: "#F2F4F7", borderRadius: "6px", mt: "16px" }}>
                    <Typography variant='body2' color='#667085' textAlign={"center"}>Note: Values shown are approximations based on historical performance and are not indicative of future results. </Typography>
                </Box>

            </Grid>

            <Grid item md={4} xs={12} sx={{ p: { xs: "16px", md: "24px" } }}>

                <Box sx={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                    <FormikInputField
                        // required
                        formik={formik}
                        label={"Initial investment"}
                        maxLength={8}
                        name={"initial_amount"}
                        placeholder='Enter here...'
                        thousandSeparator={true}
                        type='number'
                        labelChip={userAccountDetails?.kyc?.status != "ApprovalSucceeded" ? "Max 800,000" : undefined}
                        inputEnd={"PKR"}
                        FieldTouchInput={true}

                    />

                    <FormikInputField
                        // required
                        formik={formik}
                        label={ <><span>Your monthly contribution</span><span className='ml-1 text-red'>(optional)</span></>}
                        maxLength={10}
                        name={"monthly_saving"}
                        placeholder='Enter here...'
                        thousandSeparator={true}
                        type='number'
                        inputEnd={"PKR"}
                        FieldTouchInput={true}

                    />

                    <FormikInputField
                        // required
                        formik={formik}
                        label={"Duration of investing"}
                        maxLength={2}
                        name={"desired_retirement_age"}
                        placeholder='Enter here...'
                        thousandSeparator={true}
                        type='number'
                        inputEnd={formik?.values?.desired_retirement_age > 1 ? "Years" : "Year"}
                        FieldTouchInput={true}

                    />

                    <Box sx={{ height: "86px", background: "#F9FAFB", borderRadius: "8px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <Typography variant='h6' color="#667085">Projection</Typography>
                        <Typography variant='h4' color="#475467">
                            {CurrencyFormatterWithDecimal((formik?.values?.future_values || formik?.values?.initial_amount || 0), true, "never",true,true)}
                        </Typography>
                    </Box>
                </Box>
            </Grid>

        </>
    )
}

export default Projection