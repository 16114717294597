import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Container, Divider, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DashboardStyles } from './style';
import FreshUserCard from './component/FreshUserCard';
import { CardArray, CardArrayV2, expectContent } from './constant';
import Loader from 'jsx/components/Shared/Loader';
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getInvestorGoals, onGetUserPendingRiskProfileQuestion, onGetUserQuestionAnswers } from 'redux/actions';
import { onGetCashFundSummary, onGetGoal } from 'redux/reducers/sharedStateData';
import { PendingQuestionAnswerCategoryEnum, QuestionAnswerCategoryEnum } from '../../../constants';
import LinkdenImg from "Latest/Images/linkdenCircle.png";
import ShamoonImg from "Latest/Images/Shamoon.png";
import Avatar from '@mui/material/Avatar';
import tickIcon from "Latest/Images/svg/tickIcon.svg";
import shamoonImg from "Latest/Images/svg/shamoonImg.svg";
import LinkedinLogo from "Latest/Images/svg/LinkedinLogo.svg";
import saveImage from "Latest/Images/svg/save+Image.svg";
import illustration1 from "Latest/Images/svg/illustration1.svg"
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import FreshUserCardV2 from './component/FreshUserCardV2';
import TopBarLogo from 'Latest/ComponentV1/topBarLogo';




const types = ['Visible', 'Transparent', 'Secure']

export default function FreshUserScreen() {

    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state: any) => state.auth);

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        // dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.RiskProfile));
        // dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.CashFund));
        // dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.TermCondition));
        // dispatch(onGetUserPendingRiskProfileQuestion(PendingQuestionAnswerCategoryEnum.RiskProfile))
        // dispatch(onGetUserPendingRiskProfileQuestion(PendingQuestionAnswerCategoryEnum.CashFund))
        // dispatch(onGetCashFundSummary())
    }, [])

    const portfolioLoading = useAppLoading('portfolio');
    const questionAnswerLoading = useAppLoading('questionAnswer');

    // if (questionAnswerLoading || portfolioLoading)
    //     return (
    //         <Loader width={200} />
    //     );

    return (
        <>
            <TopBarLogo />

            <Box sx={{ background: "#F8FAFB", height: "100vh" }}>

                <Box sx={{ my: { xs: "24px", md: "0px" } }} >
                    <Box sx={{ pt: { xs: "16px", md: "26px" }, pb: { xs: "16px", md: "40px" }, background: "white", }}>
                        <Container maxWidth={"lg"}  >

                            <Box sx={{
                                display: "flex", gap: { xs: "24px", md: "64px" },
                                flexWrap: { xs: "wrap", md: "nowrap" }
                            }}>

                                <Box sx={{
                                    width: { xs: "100%", md: "350px" },
                                    display: "flex",
                                    alignItems: "center", gap: "24px",
                                    flexDirection: { xs: "row", md: "column" },
                                    justifyContent: "center"
                                }}>

                                    <img src={shamoonImg} />

                                    <Box>
                                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}> <Typography variant='h6' color={"#1D2939"}>Shamoon Tariq </Typography>
                                            <a href={"https://www.linkedin.com/in/muhammad-shamoon-tariq-cfa-fdp-24b40a80/"} rel="noopener noreferrer" target="_blank">
                                                <img src={LinkedinLogo} />
                                            </a>
                                        </Box>
                                        <Typography variant='body1' color={"rgba(29, 41, 57, 0.70)"}>CFA, FDP</Typography>
                                        <Typography variant='body1' color={"rgba(29, 41, 57, 0.70)"}>CEO | Mahaana</Typography>
                                    </Box>

                                </Box>

                                <Box>

                                    <Typography variant='h3' sx={{ pb: 2 }} color={"#422E84"}>Welcome to the future of wealth management</Typography>

                                    <Typography color={"rgba(29, 41, 57, 0.70)"} >
                                        As the CEO of Mahaana, I’m pleased to welcome you on our journey of empowering every Pakistani to become an informed and confident investor.
                                        <br /><br />
                                        For far too long, the traditional investment landscape has been intimidating and inaccessible. With Mahaana, you can forget about long forms, high fees, and closed doors as we rewrite the rules of wealth management and advisory services.
                                        <br /><br />
                                        Here’s what you can expect:

                                    </Typography>

                                    <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", pt: "16px" }}>
                                        {expectContent.map((item: string, index: number) => {
                                            return (
                                                <Typography key={index} variant='h6' color={"#422E84"} sx={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    gap: "8px"
                                                }}><img src={tickIcon} /> {item} </Typography>
                                            )
                                        })}

                                    </Box>
                                </Box>
                            </Box>


                        </Container>
                    </Box>
                </Box>

                <Box>
                    {!smScreen && <Typography variant='h1' color={"#1D2939"} sx={{ py: "24px" }} textAlign={"center"}>Let’s get you started</Typography>}

                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "16px",
                        flexWrap: "wrap",
                        px: "16px",
                        pb: "57px",
                        backgroundColor: "#F8FAFB"
                    }}>

                        {CardArrayV2().map((i) => (
                            <FreshUserCardV2 key={i} item={i} />
                        )
                        )}

                    </Box>

                </Box>

            </Box >
        </>
    );
}