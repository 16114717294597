import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import GreenTick from "../../../Latest/Images/greenTick.png"
import { ModalStyle } from '../Style/modalStyle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BlockIcon from '@mui/icons-material/Block';
import { links } from 'static/links';

import { useHistory } from 'react-router-dom';
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '400px', sm: '400px', xs: '90%' },
    bgcolor: '#FFFFFF',
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    p: 3,
    borderRadius: "12px"
};

export default function ErrModal({ Content, btnTitle = "Close", customOnClose, handleClose, handleConfirm, handleOpen, link, nextBtnTitle = false, open }: any) {
    const { push } = useHistory();
    const [modalContent, setModalContent] = React.useState<any>('');

    React.useEffect(() => {
        setModalContent(Content)
    }, [Content])

    const handleCloseBtn = () => {
        handleClose()
        customOnClose && customOnClose()
        if (link) {
            push(links.home)
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseBtn}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ position: "absolute", right: 10, top: 10 }}>
                        <IconButton aria-label="upload picture" component="label">
                            <CloseIcon onClick={handleCloseBtn} />
                        </IconButton>
                    </Box>
                    <Grid container spacing={2} >
                        <Grid item  >

                            <Box sx={{
                                background: "#ff00001a", width: "50px", height: '50px', display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%"
                            }} >
                                <Box sx={{
                                    background: "#ff00002b", width: "33px", height: '33px', display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50%"
                                }} >
                                    <BlockIcon sx={{ color: "red" }} />
                                </Box>
                            </Box>

                        </Grid>

                        {modalContent}

                        <Grid item xs={12}>
                            <ButtonRowV1 btnTitleBack={btnTitle} handleBack={handleCloseBtn} isShowNext={nextBtnTitle} btnTitleNext={nextBtnTitle} handleNext={handleCloseBtn} />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <Button fullWidth sx={ModalStyle.cancelBtn} onClick={handleCloseBtn}>{btnTitle}</Button>
                        </Grid>

                        {nextBtnTitle && <Grid item xs={12}>
                            <Button fullWidth sx={ModalStyle.nextBtn} onClick={handleCloseBtn}>{nextBtnTitle}</Button>
                        </Grid>} */}

                    </Grid>
                </Box>
            </Modal>
        </div >
    );
}