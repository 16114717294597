import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import React, { PureComponent, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { graphStyle, graphTabStyle } from './style';
import { CurrencyFormatter, CurrencyFormatterWithDecimal, formatYAxis, kFormatter } from 'utils/calculation';

import { add, format, differenceInCalendarDays, isFuture } from "date-fns";



const dateFormatter = (date: any) => {
  const formatMM = format(new Date(date), "MMM-Y")
  return formatMM;
};

const getTicks = (startDate: any, endDate: any, num: any) => {
  const diffDays = differenceInCalendarDays(endDate, startDate);

  const current: any = startDate,
    velocity = Math.round(diffDays / (num - 1));

  const ticks = [startDate.getTime()];

  for (let i = 1; i < num - 1; i++) {
    ticks.push(add(current, { days: i * velocity }).getTime());
  }

  ticks.push(endDate.getTime());
  return ticks;
};

const fillTicksData = (_ticks: any, data: any) => {
  const ticks = [..._ticks];
  const filled = [];
  let currentTick = ticks.shift();
  let lastData = null;
  for (const it of data) {
    if (ticks.length && it.date > currentTick && lastData) {
      filled.push({ ...lastData, ...{ date: currentTick } });
      currentTick = ticks.shift();
    } else if (ticks.length && it.date === currentTick) {
      currentTick = ticks.shift();
    }

    filled.push(it);
    lastData = it;
  }

  return filled;
};


export const PerformanceAreaChart = ({ data, gradient, retirementAge }: any) => {

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [hoveredDot, setHoveredDot] = useState(-1);

  const firstObj = data[0]
  const lastObj = data[data?.length - 1]

  const startDate = new Date(firstObj?.xAxisVal);
  const endDate = new Date(lastObj?.xAxisVal);

  const domainXAXIS: any = [(dataMin: any) => dataMin, () => endDate.getTime()];
  const ticks = getTicks(startDate, endDate, 5);
  const filledData = fillTicksData(ticks, data);


  const dataPaidAmtMax = data?.length && Math.max(...data?.map((item: any) => item.data1 > 0 && item.data1));
  const dataUnpaidAmtMax = data?.length && Math.max(...data?.map((item: any) => item.data2 > 0 && item.data2));


  //Y-AXIS
  const maxYValue = Math.max(dataPaidAmtMax, dataUnpaidAmtMax);
  const minYValue = 0;
  const tickCount = 6;
  const diffYValue = maxYValue - minYValue;
  // Calculate the raw interval between values
  const rawTickInterval = diffYValue / tickCount;
  const tickValues: number[] = [];

  const domainEndYaxis = Math.floor(maxYValue + rawTickInterval / 15);

  const getDivisible = (lastVal: string | number) => {

    const maxValue = Number(lastVal);

    // Calculate the result based on the formula
    // Math.log10(maxValue): This part calculates the base-10 logarithm of maxValue. eg: if maxValue is 12000, then Math.log10(12000) would be approximately 4.079.
    // Math.floor(Math.log10(maxValue) - 1): Here, we subtract 1 from the base-10 logarithm, after the roundoff to the nearest integer the result would be 3, which corresponds to moving from 12000 to 1000.
    // Math.pow(10, Math.floor(Math.log10(maxValue) - 1)): we use Math.pow to raise 10 to the power of the integer. This effectively generates a number with the desired number of zeros at the end
    const subtractBy = maxValue > 1000 ? 1 : 0;
    const result = Math.pow(10, Math.floor(Math.log10(maxValue) - subtractBy));
    return result;
  }

  const getYAxisTicks = () => {
    const divisibleBy = getDivisible(maxYValue);
    const tickInterval = Math.ceil(rawTickInterval / divisibleBy) * divisibleBy
    const endingValue = Math.ceil(maxYValue / divisibleBy) * divisibleBy;

    for (let i = 0; i <= tickCount; i++) {
      const tickValue = endingValue - tickInterval * i;
      if (tickValue >= 0) {
        tickValues.push(tickValue);
      }
    }
    // Check if the last item in tickValues is not 0 and add 0 if necessary
    // if (tickValues[tickValues.length - 1] !== 0 && (tickValues[tickValues.length - 1] !== 100 && tickValues[tickValues.length - 1] !== 1000)) {
    //   tickValues.push(0);
    // }
    return tickValues
  }

  const CustomizedXAxisTick = (props: any) => {
    const activeDotIndex = hoveredDot >= 0 ? hoveredDot : -1;
    const data = props.payload
    const isActive = data.index === activeDotIndex ? true : false;
    const fillColor = isActive ? "#344054" : "#6B6F80"
    const fillBgColor = isActive ? "#f4f3f8" : "transparent"
    const { payload, x, y } = props;
    const dx = retirementAge <= 4 ? -3 : 9
    const rectX = retirementAge <= 4 ? -13 : 4.5

    return (
      <g transform={`translate(${x - 25}, ${y})`}>
        <rect
          x={rectX}  // Adjust the x position to cover the text
          y={2} // Adjust the y position to cover the text
          width={payload.value.length * 10.5} // Adjust the width based on the text length
          height={25}  // Adjust the height to cover the text
          fill={fillBgColor} // Set the desired background color
        />
        <text x={0} y={0} dy={20} dx={dx} fill={fillColor} style={graphStyle.xAxis}
        // style={isActive ? { ...ProjectionStyles.activeXAxisPorfolioStyles } : { ...ProjectionStyles.xAxisPorfolioStyles }}
        >
          {dateFormatter(payload.value)}
        </text>
      </g>
    );
  };


  return (
    <ResponsiveContainer width="100%" height={smScreen ? 350 : 370}>

      <AreaChart
        data={data} onMouseMove={e => setHoveredDot(Number(e.activeTooltipIndex))} onMouseLeave={() => setHoveredDot(-1)}
      // margin={{ top: 15, right: 0, left: -15, bottom: 0 }}
      >
        <defs>
          <linearGradient id="InvestedAmountColor" x1="0" y1="0" x2="0" y2="1.2" >
            <stop stopColor={gradient} stopOpacity="0.80" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>

        {/* <CartesianGrid stroke="#F2F4F7" strokeWidth={1} horizontal={false} vertical={true} /> */}

        <XAxis
          // dataKey="date"
          dataKey="xAxisVal"
          tick={<CustomizedXAxisTick />}
          axisLine={true}
          tickLine={false}
          stroke="#F2F4F7"
          domain={domainXAXIS}
          ticks={ticks}
          scale="time"
          type="number"
        />

        <YAxis
          axisLine={false}
          tickLine={false}
          interval={0}
          // tickCount={5}
          tickFormatter={kFormatter}
          type='number'
          style={graphStyle.yAxis}
          domain={[0, domainEndYaxis]}
          ticks={getYAxisTicks()}
        />

        <Tooltip content={<CustomTooltip />} cursor={{
          stroke: "#1D2939",
          strokeWidth: 1,
          strokeDasharray: "3 2",
        }} />

        <Area
          type="monotone"
          dataKey="data1"
          stroke={gradient}
          fill="url(#InvestedAmountColor)" // Set the fill color for non-"connectNull" parts
          fillOpacity={1}
          strokeWidth={2}
        />
        <Area
          type="monotone"
          dataKey="data2"
          stroke={gradient}
          fill="transparent" // Set the fill color for non-"connectNull" parts
          strokeDasharray="5 5"
          strokeWidth={2}
        />

      </AreaChart>
    </ResponsiveContainer>
  )
}

function CustomTooltip({ active, label, payload, }: any) {

  if (active) {
    const hoverPayload = payload && payload[0]?.payload
    const Date = hoverPayload?.date
    const manuluplatePayloadArr = payload[0].payload?.tooltipTitle == "Merged Portfolio" ? payload : (payload?.length > 1 ? [payload[1]] : [payload[0]])

    return (

      <Box sx={graphTabStyle.tooltipMainBox} color="#344054">
        <Typography variant="body2" color={"#1D2939B2"} fontWeight={500} fontSize={{ md: "12px" }}>{Date}</Typography>
        <Box sx={{ display: "flex", gap: "14px", mt: "8px", flexDirection: "column" }}>

          {manuluplatePayloadArr && manuluplatePayloadArr?.map((item: any, index: number) => {


            const name = item?.payload?.tooltipTitle == "Merged Portfolio" ? (item?.dataKey == "data1" ? "Portfolio Amount" : "Projected Value") : item?.payload?.tooltipTitle;
            const styles = (item?.payload?.tooltipTitle == "Merged Portfolio" && item?.dataKey == "data2") ? { border: `1.8px dashed ${item.color}` } : { background: item.color }

            return (
              <Box key={index} sx={{ display: "flex", gap: "6px", alignItems: "baseline" }}>
                <Box sx={{ ...graphTabStyle.tooltipCircle, ...styles }} />
                <Box>
                  <Typography variant="caption" color="#1D2939B2" textTransform={"capitalize"} fontSize={"14px"}>{name}</Typography>
                  {/* <Typography variant="subtitle2" color={"#1D2939"} fontSize={"14px"}>{CurrencyFormatter(item.value, true, "auto", true)}</Typography> */}
                  <Typography variant="subtitle2" color={"#1D2939"} fontSize={"14px"}>{CurrencyFormatterWithDecimal(item.value, true, "auto" , true ,true)}</Typography>
                </Box>
              </Box>
            )
          })}

        </Box>

      </Box >
    );
  }
  return null;
}