import React, { useEffect, useState } from 'react'
import InputField from '../../Shared/FormikInputFieldLatest';
import SelectField from '../../Shared/FormikSelectFieldLatest';
import * as Yup from 'yup';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import { CityName } from './city';
import { ProvinceName } from './province';
import { HeadingStyle, subHeadingBlueStyle } from 'Latest/Style/Main';
import MailingFields from './mailingFields';

const containerStyleFlow = { ml: "0.55rem", minHeight: "30rem", mt: "2rem", mb: "2rem" }


const PermanentField = ({ formik, style, type = "flow" }: any) => {

    const [cityList, setcityList] = useState<any>([])
    const [provinceList, setProvinceList] = useState<any>([])

    useEffect(() => {
        const data = CityName?.map((val) => ({ 'value': val.name, 'label': val.name }));
        setcityList(data)

        const stateList = ProvinceName?.map((val) => ({ 'value': val.name, 'label': val.name }));
        setProvinceList(stateList)

    }, [CityName, ProvinceName])

    return (
        <>

            <Grid container spacing={1}>
                <Grid item md={12} xs={12} >
                    <Typography sx={subHeadingBlueStyle}>Permanent Address</Typography>
                </Grid>

                <Grid item md={12} xs={12}>
                    <InputField
                        required
                        maxLength={120}
                        formik={formik}
                        label='Address line 1'
                        name='permanentAddress1'
                        placeholder='Enter permanent address here'
                        type="text"
                    />
                </Grid>

                <Grid item md={12} xs={12} >
                    <InputField
                        maxLength={120}
                        formik={formik}
                        label='Address line 2 (optional)'
                        name='permanentAddress2'
                        placeholder='Enter permanent address here'
                        type="text"
                    />
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                    <SelectField
                        col={12}
                        fieldName='cityPermanent'
                        fields={cityList}
                        formik={formik}
                        label='City'
                        onChange={(e) => {
                            const selectedField = cityList.filter((i: any) => i.value == e)
                            formik.setFieldValue('cityPermanent', selectedField[0])
                        }}
                        placeholder={"Select City"}
                        required={true}
                        value={formik.values.cityPermanent.value == '' ? "" : formik.values.cityPermanent}
                    />
                </Grid>

                <Grid item lg={6} md={6} xs={12} >
                    <SelectField
                        col={12}
                        fieldName='provincePermanent'
                        fields={provinceList}
                        formik={formik}
                        label='Province'
                        onChange={(e) => {
                            const selectedField = provinceList.filter((i: any) => i.value == e)
                            formik.setFieldValue('provincePermanent', selectedField[0])
                        }}
                        placeholder={"Select Province"}
                        required={true}
                        value={formik.values.provincePermanent.value == '' ? "" : formik.values.provincePermanent}
                    />
                </Grid>
            </Grid>

            <Divider sx={{ my: "24px" }} />
            
            <MailingFields formik={formik} />
        </>
    )
}

export default PermanentField