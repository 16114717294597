/* eslint-disable sort-keys */
import ReactApexChart from 'react-apexcharts';

const Statistics = props => {
  const state = {
    series: props?.series,
    options: {
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toLocaleString();
          }
        }
      },

      chart: { height: 360, toolbar: { show: false }, type: 'area' },
      colors: ['#4012A0', '#AC4CBC'],
      legend: { show: false },
      dataLabels: { enabled: false },
      stroke: { width: 4, curve: 'smooth' },
      grid: { borderColor: '#eee', strokeDashArray: 8 },
      xaxis: {
        categories: props?.series[0]?.labels,
        labels: {
          formatter: function (val) {
            return val ? val : props.isDashboard ? 66 : val;
          },
          style: { colors: '#787878', fontSize: '10px', fontWeight: 100 }
        }
      },
      fill: { opacity: 0.1, type: 'solid' },
      tooltip: { x: { format: 'dd/MM/yy HH:mm' } }
    }
  };

  return (
    <div id='chart'>
      <ReactApexChart
        height={320}
        options={state.options}
        series={state.series}
        type='area'
        width={props.width}
      />
      <small
        className='text-black'
        style={{
          position: 'absolute',
          bottom: 0,
          transform: 'translateX(50%)',
          left: '50%'
        }}
      >
        Age
      </small>
    </div>
  );
};

export default Statistics;
