export const componentV1Style = {
    customCardMainBox: {
        borderRadius: { xs: "0px", sm: "9px" }, p: { xs: "16px", sm: "30px" }, background: "#F2F4F7", borderBottom: "2px solid #FFFFFF",
    },
    customCard1MainBox: {
        p: "20px", background: "#FFFFFF", border: "1px solid #EAECF0", boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06)", borderRadius: "10px",
        display: "flex",
        gap: "15px",
        alignItems: { xs: "flex-start", sm: "center" },
        ".contentBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            flexWrap: "wrap",
            gap: 1
        }
    },
    boxTab: {
        tabs: {
            borderRadius: "8px",
            border: "1px solid  #F2F4F7",
            // background: "#F9FAFB",
            height: { md: '50px', sm: '56px', xs: '46px' },
            padding: 0,
            '& .MuiTabs-indicator': {
                display: 'none'
            },
            '& .MuiTabs-flexContainer': {
                height: '100%',
                padding: '5px',
                '& button': {
                    width: { md: '25%', sm: '25%', xs: 'auto' },
                },
            },
            ".css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root": {
                width: 15,
                background: "white"
            },
        },

        tab: {
            minWidth: '50%',
            minHeight: '100%',
            textTransform: 'capitalize',

            '&.Mui-selected': {
                backgroundColor: '#FFF',
                color: '#344054',
                borderRadius: '6px',
                fontWeight: '600',
                minHeight: '100%',
                height: '100%',
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)!important"
            },

        },
    },
    cfStatusWrapper: {
        padding: "2px 8px", borderRadius: "100px",
        fontSize: { xs: "14px" }, lineHeight: "21px",
        textAlign: "center", display: "inline-block"
    },
    oneLineTypo: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
    }
}

export const componentV2Style = {
    customCardMainBox: {
        // borderRadius: { xs: "0px", sm: "9px" }, p: { xs: "16px", sm: "30px" }, background: "#F2F4F7", borderBottom: "2px solid #FFFFFF",
    },
    customCard1MainBox: {
        // p: "20px", background: "#FFFFFF", border: "1px solid #EAECF0", boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06)", borderRadius: "10px",
        // display: "flex",
        // gap: "15px",
        // alignItems: { xs: "flex-start", sm: "center" },
        // ".contentBox": {
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "space-between",
        //     width: "100%",
        //     flexWrap: "wrap",
        //     gap: 1
        // }
    },
    boxTab: {
        tabs: {

            padding: 0,
            '& .MuiTabs-indicator': {
                display: 'none'
            },
            '& .MuiTabs-flexContainer': {
                height: '100%',
                gap: "16px", // Adjust the gap using theme.spacing
                paddingRight: { xs: "16px", md: "16px" }, // Adjust the padding to accommodate for the gap
            },
            ".css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root": {
                width: 15,
                background: "white"
            },
        },

        tab: {
            // minWidth: 'calc((100% - 16px) / 3)', // Adjust the width based on the number of tabs and the desired gap
            minHeight: '100%',
            textTransform: 'capitalize',
            border: "1px solid rgba(208, 213, 221, 0.25)",
            boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)!important",
            p: { xs: "8px 16px", md: "16px 32px" },
            borderRadius: '8px',
            fontSize: "16px",
            fontWeight: 400,
            color: "#1D2939",
            '&.Mui-selected': {
                backgroundColor: '#FFF',
                color: '#422E84',
                borderRadius: '8px',
                minHeight: '100%',
                height: '100%',
                border: "1px solid  #422E84",
                boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)!important"
            },
        },
    }


}



export const CustomScrollerCardStyle = {
    contentScroller: {
        width: { xs: "-webkit-fill-available", sm: "40%" },
        mx: { xs: "16px", sm: "0px" },
        mb: { xs: "16px", sm: "0px" },

        borderRadius: { xs: "8px", sm: "0px" },

        border: { xs: "1px solid #EAECF0", sm: "0px" },

        borderLeft: { sm: "1px solid #EAECF0" }, overflowY: "auto", height: { xs: "auto", md: "370px" },

        '&::-webkit-scrollbar': {
            width: '12px', // Set the width of the scrollbar
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D0D5DD', // Set the desired color
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#F2F4F7', // Set the background color of the track
            borderRadius: "0px 0px 7px 0px",
            padding: "152px 4px 148px 4px",
        },
        // For Firefox
        scrollbarColor: '#D0D5DD #ffffff', // Set the desired colors

    }
}
