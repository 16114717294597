import React from 'react'
import UpgradeCard from './upgradeCard'

const Index = ({ borderHighlight, setBorderHighlight }: any) => {

    return (
        <>
            <UpgradeCard setBorderHighlight={setBorderHighlight} borderHighlight={borderHighlight} />
        </>
    )

}

export default Index