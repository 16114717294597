import { Box, Grid, Typography, useTheme } from "@mui/material"
import SealCheck from "Latest/Images/svg/SealCheck"
import { tokens } from "theme";
import Advisor1 from "../../../Latest/Images/Advisor1.png"
import Advisor2 from "../../../Latest/Images/Advisor2.png"
import Advisor3 from "../../../Latest/Images/Advisor3.png"
import "../styles/index.css"
import { useAppSelector } from "hooks/reduxHooks";
import AdvisorCard from "./AdvisorCard";

const AllAdvisors = ({data}:{data:any}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const totalPortfolioAmount = useAppSelector((state)=>state.dashboard.dashboardDetail?.summary?.totalPortfolioAmount)

    return(
        <Grid >
            <Box maxWidth={"xl"}>
                <Typography variant="h1" color={colors.grey[800]}>Looking for a dedicated financial advisor?</Typography>
                <Typography variant="body2" color={"#475467"} sx={{marginTop:{md:"24px",xs:"8px"},maxWidth:"md"}}>Invest over PKR 1 million to receive a dedicated advisor. For investments exceeding PKR 5 million, Mubashir Zuberi, our Chief Investment Officer, will personally advise you. For investments exceeding PKR 10 million, Shamoon Tariq, our Chief Executive Officer, will serve as your dedicated advisor.</Typography>
                <Box sx={{display:"flex", alignItems:"center",marginTop:"32px" }}>
                    <SealCheck />
                    <Typography variant="body2" color={"#443087"} fontWeight={"400"} sx={{marginLeft:"20px"}}>Get exclusive booking slots with your investment advisor</Typography>
                </Box>
                <Box sx={{display:"flex",alignItems:"center",marginTop:"13px"}}>
                    <SealCheck />
                    <Typography variant="body2" color={"#443087"} fontWeight={"400"} sx={{marginLeft:"20px"}}>More dedicated time to answer your queries and understand your goals </Typography>
                </Box>
            </Box>
            <Grid gap={"48px"} maxWidth={"xl"} md={12} xs={12} sx={{display:"flex" , marginTop:"24px" , flexDirection:{md:"row",sm:"row",xs:"column"}}}>
               
               <AdvisorCard imageSrc={Advisor1}  name={data?.CEO.name} title={data?.CEO.title} description={data?.CEO.description}/>
               <AdvisorCard imageSrc={Advisor2}  name={data?.CIO.name} title={data?.CIO.title} description={data?.CIO.description}/>        
                {/* {
                totalPortfolioAmount >= 1000000 && <AdvisorCard imageSrc={Advisor3}  name={data?.SO.name} title={data?.SO.title} description={data?.SO.description}/>                  
                } */}
                
            </Grid>
        </Grid>
    )
}

export default AllAdvisors