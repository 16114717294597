import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';

const FormikCheckboxField = ({
  col = 6,
  fieldName,
  fields,
  formik,
  label,
  onChange = () => null,
  required = false,
  singleLine = false,

}: FormikCheckboxFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;
  return (
    <Col lg={col}>
      <div className='form-group'>
        {label &&
          <label className='mb-2 field-label'>
            <strong>
              {label}
              {required && <span className='ml-1 text-danger'>*</span>}
            </strong>
          </label>
        }
        <Row>
          <div className={singleLine ? 'singleLine ' : ''}>
            {fields.map(val => (
              <Form.Check
                key={val.label}
                checked={formik?.values[fieldName] && formik.values[fieldName].includes(val?.value)}
                disabled={!!val.disabled}
                id={val.value.toString()}
                label={val.label || val.value}
                name={fieldName}
                onChange={formik.getFieldProps(fieldName).onChange}
                type='checkbox'
                value={val.value}
              />
            ))}
          </div>
        </Row>

        <span className='ml-2 text-danger'>
          {formikTouch[fieldName] && formikError[fieldName]}
        </span>
      </div>
    </Col>
  );
};

export default memo(FormikCheckboxField);

interface FormikCheckboxFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  fields: Array<{ label: string; value: string; disabled?: boolean }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  label?: string;
  required?: boolean;
  singleLine?: boolean;
  onChange?: any
}
