/* eslint-disable sort-keys */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
    zakaatDeclNotiStatus: true,
};

const name = 'notificationStatus';
const notificationSlice = createSlice({
    initialState,
    name,
    reducers: {
        zakaatDeclNotiAction: (state, action: PayloadAction<any>) => {
            state.zakaatDeclNotiStatus = action.payload;
        },
    }
});
export const { zakaatDeclNotiAction, } = notificationSlice.actions;
export default notificationSlice.reducer;

//? APIS ACTIONS


//? Types
interface InitialState {
    zakaatDeclNotiStatus: boolean
}
