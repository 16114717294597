import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { formikRadioFieldStyle } from '../style/formikRadioFieldStyle';
import { Box, Grid, Typography } from '@mui/material';

const FormikRadioField = ({
  FieldTouchInput = false,
  col = 6,
  fieldName,
  fields,
  formik,
  label,
  nestedFieldName,
  required = false,
  singleLine = false,
  style
}: FormikRadioFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  let FieldError = '';
  let FieldTouch = false;
  let NestedFieldError = '';
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }

  return (

    <>
      <Grid item xs={12}>
        {label && (
          <Typography sx={{ mb: "16px" }} variant='body1'>
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </Typography>
        )}
      </Grid >

      <Box sx={{ display: 'inline-flex', ".form-check-label": { p: "6px 16px", m: 0 }, borderRadius: "4px 4px 4px 4px", border: "1px solid  #D0D5DD" }} >
        {fields.map((val, ind) => (
          <Grid key={val.label} item >
            <Box onChange={formik.getFieldProps(fieldName).onChange}
              sx={{
                borderRight: ind == 0 ? "1px solid  #D0D5DD" : "",
                borderRadius: ind == 0 ? "4px 0px 0px 4px" : "0px 4px 4px 0px",
                background: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "#432F87" : "#FFF",
                textAlign: "center",

                "& label": {
                  color: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "#FFF" : "#344054",
                }
              }}
            >
              <Form.Check
                key={val.label}
                checked={formik.values[fieldName] === val.value}
                className='specialCheckbox'
                disabled={!!val.disabled}
                id={val?.label?.toString() + label?.toString()}
                label={val.label}
                name={fieldName}
                onChange={formik.getFieldProps(fieldName).onChange}
                type='radio'
                value={val.value}
                style={{ width: "60px" }}
              />
            </Box>
          </Grid>

        ))}
      </Box>

      <br />
      {(FieldTouch || FieldTouchInput) && FieldError && (
        <span className='mt-1 text-danger'>{FieldError}</span>
      )}
      {NestedFieldTouch && NestedFieldError && (
        <span className='mt-1 text-danger'>{NestedFieldError}</span>
      )}
    </>

  );
};

export default memo(FormikRadioField);

interface FormikRadioFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  nestedFieldName?: string;
  fields: Array<{ label: string; value: string; disabled?: boolean }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  label?: string;
  required?: boolean;
  singleLine?: boolean;
  style?: any;
  FieldTouchInput?: boolean,
}
