import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
    Legend,
} from "recharts";
import { CurrencyFormatter, CurrencyFormatterWithDecimal, formatYAxis, kFormatter } from "utils/calculation";
import { graphTabStyle } from "./style";
import { graphStyle } from "./style";
import moment from 'moment'
import { add, differenceInCalendarDays, format } from "date-fns";
import { tokens } from "theme";


const dateFormatter = (date: any) => {
    const formatMM = format(new Date(date), "MMM-Y")
    return formatMM;
};

const getTicks = (startDate: any, endDate: any, num: any) => {
    const diffDays = differenceInCalendarDays(endDate, startDate);

    const current: any = startDate,
        velocity = Math.round(diffDays / (num - 1));

    const ticks = [startDate.getTime()];

    for (let i = 1; i < num - 1; i++) {
        ticks.push(add(current, { days: i * velocity }).getTime());
    }

    ticks.push(endDate.getTime());
    return ticks;
};


export default function Contribution({ data, hideLegends = true, hideXAxis = false, hideYAxis = false }: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [hoveredDot, setHoveredDot] = useState(-1);

    const xlScreen = useMediaQuery(theme.breakpoints.up('xl'));
    const mdScreenOnly = useMediaQuery(theme.breakpoints.only('md'));
    const mdScreenDown = useMediaQuery(theme.breakpoints.down('md'));


    const ticksCountSetX = mdScreenDown ? 3 : mdScreenOnly ? 4 : 6

    const findMaxYValuePortfolioValue = Math.max(...data?.map((item: any) => item?.portfolioValue));    //only use this for err handling if portfolio < contributionAmount
    const findMaxYValueContributionAmount = Math.max(...data?.map((item: any) => item?.contributionAmount)); //only use this for err handling if portfolio < contributionAmount

    const findYMinValuePortfolioValue = Math.min(...data?.map((item: any) => item?.portfolioValue)); //only use this for err handling if portfolio < contributionAmount
    const findMinYValueContributionAmount = Math.min(...data?.map((item: any) => item?.contributionAmount)); //only use this for err handling if portfolio < contributionAmount

    const minYValue = Math.min(findYMinValuePortfolioValue, findMinYValueContributionAmount); //only use this for err handling if portfolio < contributionAmount
    const maxYValue = Math.max(findMaxYValuePortfolioValue, findMaxYValueContributionAmount); //only use this for err handling if portfolio < contributionAmount

    const tickCount = 6;
    const range = maxYValue - minYValue;
    // Calculate the raw interval between values
    const rawTickInterval = range / tickCount;
    const domainEnd = Math.floor(maxYValue + rawTickInterval / 2);
    let domainStart = minYValue - rawTickInterval / 1;
    domainStart = domainStart > 0 ? domainStart : minYValue;

    const getDivisible = (maxValue: number) => {
        // Calculate the result based on the formula
        // Math.log10(maxValue): This part calculates the base-10 logarithm of maxValue. eg: if maxValue is 12000, then Math.log10(12000) would be approximately 4.079.
        // Math.floor(Math.log10(maxValue) - 1): Here, we subtract 1 from the base-10 logarithm, after the roundoff to the nearest integer the result would be 3, which corresponds to moving from 12000 to 1000.
        // Math.pow(10, Math.floor(Math.log10(maxValue) - 1)): we use Math.pow to raise 10 to the power of the integer. This effectively generates a number with the desired number of zeros at the end
        const strLength = String(maxValue).length == 15 ? 2 : 1
        const resultBy100 = Math.pow(10, Math.floor(Math.log10(maxValue) - strLength));
        // Got the multiple by 5
        const result = resultBy100;
        return result;
    }

    const getYAxisTicks = () => {
        // Start from max value
        const tickValues: any = [];
        const divisibleBy = getDivisible(maxYValue);

        // Calculate the adjusted tick interval to ensure multiples of divisibleBy
        const tickInterval = Math.ceil(rawTickInterval / divisibleBy) * divisibleBy;
        // Calculate the last max value as the nearest multiple of divisibleBy above maxYValue
        const endingValue = Math.ceil(maxYValue / divisibleBy) * divisibleBy;
        // Round off the starting value
        let startingValue = Math.floor(minYValue / divisibleBy) * divisibleBy;
        startingValue = startingValue > 0 ? startingValue : Math.floor(minYValue)

        const strLength = String(maxYValue).length == 15

        if (strLength) {
            for (let i = 0; i <= tickCount; i++) {
                const tickValue = startingValue + tickInterval * i;
                if (tickValue > 0 && tickValue < endingValue) {
                    tickValues.push(tickValue);
                }
            }
            const arrLastItem = tickValues[tickValues.length - 1]
            if (arrLastItem < maxYValue) {
                tickValues.push(endingValue)
            }
        } else {
            for (let i = 0; i <= tickCount; i++) {
                const tickValue = endingValue - tickInterval * i;
                if (tickValue > 0) {
                    tickValues.push(tickValue);
                }
            }
        }


        return tickValues
    }

    const CustomizedXAxisTick = (props: any) => {
        const { payload, x, y } = props;
        const activeDotIndex = hoveredDot ? hoveredDot : -1;
        const data = props.payload
        const isActive = data.index === activeDotIndex ? true : false;
        const fillColor = isActive ? "#344054" : "#6B6F80"
        const fillBgColor = isActive ? "#f4f3f8" : "transparent"

        return (
            <g transform={`translate(${x},${y})`}>
                <rect
                    x={-40}  // Adjust the x position to cover the text
                    y={0} // Adjust the y position to cover the text
                    width={props.payload.value.length * 10} // Adjust the width based on the text length
                    height={20}  // Adjust the height to cover the text
                    fill={fillBgColor} // Set the desired background color
                />
                <text x={0} y={-10} dy={35} textAnchor="middle" fill={fillColor} style={graphStyle.xAxis}>
                    {payload?.value && moment(payload?.value).format("DD MMM YY")}
                </text>
            </g>
        );

    };

    const dataLength = data.length;
    const ticks = [];

    if (dataLength >= 3) {
        // Display the first, last, and middle intervals
        ticks.push(data[0].date); // Add the first interval
        const middleIndex = Math.floor(dataLength / 2);
        ticks.push(data[middleIndex].date); // Add the middle interval
        ticks.push(data[dataLength - 1].date); // Add the last interval
    } else if (dataLength > 0) {
        // Display all available intervals if you have less than 3 data points
        data.forEach((item: any) => {
            ticks.push(item.date);
        });
    }

    //x-axis-ticks
    const firstObj = data[0]
    const lastObj = data[data?.length - 1]

    const startDate = new Date(firstObj?.date);
    const endDate = new Date(lastObj?.date);

    const domainXAXIS: any = [(dataMin: any) => dataMin, () => endDate.getTime()];
    const ticksX = getTicks(startDate, endDate, ticksCountSetX);
    //


    const renderCusomizedLegend = (props: any) => {
        const { payload } = props;
        return (
            <Grid container gap={"10px"} justifyContent={"center"} mt={2}>
                {
                    payload.map((entry: any, index: any) => (
                        <Grid key={index} item md={12} display={"flex"} alignItems={"center"} gap={"8px"}>
                            <Box sx={{width: "6px", height: "6px", borderRadius: "100px", backgroundColor: entry?.payload?.stroke}} />
                            <Typography variant="subtitle2" fontWeight={500} color={colors.grey[550]}>{entry?.value}</Typography>
                        </Grid>
                    ))
                }
            </Grid >
        )
    }

    return (
        <>
            <ResponsiveContainer width="100%" height={mdScreenDown ? 338 : 340}>

                <AreaChart
                    onMouseMove={e => setHoveredDot(Number(e.activeTooltipIndex))}
                    onMouseLeave={() => setHoveredDot(-1)}
                    data={data}
                    margin={{ top: mdScreenDown ? -10 : 15, right: 0, left: hideYAxis ? 2 : -10, bottom: 15 }}

                >
                    <defs>

                        <linearGradient id="TotalValueColor" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="30.2%" stopColor="#82ca9d" stopOpacity={0.8} />
                            <stop offset="85.95%" stopColor="#82ca9d" stopOpacity={0} />
                        </linearGradient>

                        <linearGradient id="InvestedAmountColor" x1="0" y1="0" x2="0" y2="1" >
                            {/* <stop stopColor="#62529B" stopOpacity="0.8" /> */}
                            <stop stopColor="#432F87" stopOpacity="0.6" />
                            <stop offset="1" stopColor="#432F87" stopOpacity="0" />
                        </linearGradient>
                    </defs>

                    {/* <XAxis
                        dataKey="date"
                        tick={<CustomizedXAxisTick />}
                        axisLine={true}
                        tickLine={false}
                        stroke="#F2F4F7"
                        ticks={smScreen ? ticks : []}
                    /> */}


                    <XAxis
                        hide={hideXAxis}
                        dataKey="date"
                        tick={<CustomizedXAxisTick />}
                        axisLine={true}
                        tickLine={false}
                        stroke="#F2F4F7"
                        domain={domainXAXIS}
                        ticks={ticksX}
                        scale="time"
                        type="number"
                        interval={"preserveStartEnd"}
                    />

                    <YAxis
                        hide={hideYAxis}
                        style={graphStyle.yAxis}
                        type="number"
                        interval={0}
                        domain={[domainStart, domainEnd]}
                        ticks={getYAxisTicks()}
                        tickFormatter={kFormatter}
                        axisLine={false}
                        tickLine={false}
                    />
                    <Tooltip content={<CustomTooltip />}
                        cursor={{
                            stroke: "#1D2939",
                            strokeWidth: 1,
                            strokeDasharray: "3 2",
                        }}
                    />
                    <CartesianGrid stroke="#D0D5DD40" strokeWidth={1} horizontal={false} vertical={true} />

                    {!hideLegends && <Legend content={renderCusomizedLegend} verticalAlign="bottom" height={10} />}

                    <>
                        <Area type="monotone" name="Total value" dataKey="portfolioValue" strokeWidth={2} stroke="#12B76A" fill="transparent" />
                        {/* <Area type="monotone" name="Total Value" dataKey="portfolioValue" stroke="#12B76A" fill="url(#TotalValueColor)" fillOpacity={1} /> */}
                        <Area type="monotone" name="Invested" dataKey="contributionAmount" strokeWidth={2} stroke="#62529B" fill="url(#InvestedAmountColor)" fillOpacity={1} />
                    </>

                </AreaChart>

            </ResponsiveContainer>



        </>
    );
}



function CustomTooltip({ active, label, payload, }: any) {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    if (active) {
        const hoverPayload = payload && payload[0]?.payload
        const Date = smScreen ? moment(hoverPayload?.date)?.format("DD MMM YYYY") : moment(hoverPayload?.date)?.format("Do MMM YYYY")

        return (
            <Box sx={graphTabStyle.tooltipMainBox} color="#344054">

                <Typography variant="subtitle3" fontWeight={500} color={"#1D2939B2"} letterSpacing={0.5}>{Date}</Typography>

                <Box sx={{ display: "flex", gap: { md: "14px", xs: "8px" }, mt: "8px", flexDirection: { xs: "column", md: "row" } }}>
                    {payload?.map((item: any, index: number) => {
                        return (
                            <Box key={index} sx={{ display: "flex", gap: "6px", alignItems: "baseline" }}>
                                <Box sx={{ ...graphTabStyle.tooltipCircle, background: item.color }} />
                                <Box>
                                    {!smScreen && <Typography variant="subtitle2" color="#1D2939B2" fontWeight={400} lineHeight={"21px"}>{item.name}</Typography>}
                                    <Typography variant="subtitle2" color="#1D2939" fontWeight={400} lineHeight={"21px"}>{CurrencyFormatterWithDecimal(item.value, true,"never",true,true)}</Typography>
                                </Box>
                            </Box>
                        )
                    })}

                </Box>

            </Box >
        );
    }
    return null;
}
