import React from 'react'
import StaticContent from '../staticContent'
import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import useStylesErrorAlert from 'Latest/ComponentV1/CustomMakeStyle/customErrorAlert';
import { useAppSelector } from 'hooks/reduxHooks';

const Failed = () => {

  const classes = useStylesErrorAlert();
  const { userAccountDetails } = useAppSelector(state => state.auth);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: "flex-start", flexDirection: { xs: "column", md: "row" } }}>
        <Box sx={{ width: "100%" }}>
          <Alert severity="error" className={classes.customAlert} >
            <AlertTitle className={classes.customAlertTitle}>Upgrade could not be approved</AlertTitle>
            {userAccountDetails?.kyc?.comment ?
              <Typography className={classes.customText}>{userAccountDetails?.kyc?.comment}</Typography>
              :
              <Typography className={classes.customText}>Our compliance team has marked your account as high risk</Typography>
            }
          </Alert>

          <Box sx={{ mt: "10px" }}>
            <StaticContent />
          </Box>

        </Box>
      </Box>
    </>
  )
}

export default Failed