/* eslint-disable sort-keys */
import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import { HeaderSection } from './Component/HeaderSection';
import { CurrencyFormatterWithDecimal } from 'utils/calculation';
import { useAppSelector } from 'hooks/reduxHooks';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Projection from './Component/Projection';
import Portfolio from './Component/Portfolio';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import FlowAmtLimitModal from 'Latest/ModalV1/flowAmtLimitModal';
import { intialInvestmentMaxLimit, mixPannelTrackerEventName } from '../../../../constants';
import CustomModal from "Latest/MyProfile/Modal/confirmationModal";
import { useHistory } from 'react-router';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';

const CashFundAssetAllocation = ({
    RedirectLink, formik, handleInterestCompute, initialAmount, interestData,
    isEmployer, loading, monthlySaving, nextStep,
    planType, previousStep, retirementAmount,
    submitGoal
}: any) => {

    const [openMoadal, setOpenMoadal] = useState(false);
    const [openAmtLimit, setOpenAmtLimit] = useState(false);

    const handleOpenAmtLimit = () => setOpenAmtLimit(true);
    const handleCloseAmtLimit = () => setOpenAmtLimit(false);

    const { user: { email } } = useAppSelector((state: any) => state.auth);

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
    const { cashFundSummary } = useAppSelector(state => state.sharedData);
    const { push } = useHistory();

    const amtLimitContent = () => (
        <>
            <Typography variant="h6">Account limit reached</Typography>
            <Typography variant="body2">
                You can only invest up to PKR 800,000. Your remaining limit is {CurrencyFormatterWithDecimal(dashboardDetail?.summary?.remainingLimitAmount || 0, true, "never", false)}. 
                To invest more, please upgrade your account.
            </Typography>
        </>
    );

    const handlePressAccountSetup = (e: any) => {
        if (formik?.errors?.initial_amount || formik?.errors?.desired_retirement_age || formik?.errors?.monthly_saving) {
            if (RedirectLink === "dashboard") {
                handleConfirmAmtLimit();
                return;
            }
            formik?.values?.initial_amount > intialInvestmentMaxLimit && setOpenMoadal(true);
            return;
        }
        e.preventDefault();
        nextStep();
        MixPannelTracker({ eventName: mixPannelTrackerEventName.projections_reviewed, email });
    };

    const handlePressGoBack = (e: any) => {
        e.preventDefault();
        previousStep();
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleConfirmAmtLimit = () => {
        if (formik?.values?.initial_amount > dashboardDetail?.summary?.remainingLimitAmount && userAccountDetails?.kyc?.status !== "ApprovalSucceeded") {
            handleOpenAmtLimit();
            return;
        }
    };

    const buttonHtml = () => (
        <ButtonRowV1 handleBack={handlePressGoBack} handleNext={handlePressAccountSetup} btnTitleNext="Continue" />
    );

    const handleConfirm = () => {
        push("/myprofile?scroll=true");
    };

    return (
        <Box style={{ backgroundColor: '#f9fafb', minHeight: '100vh' }}>
            <FlowAmtLimitModal handleClose={() => setOpenMoadal(false)} handleModalSubmitBtn={() => setOpenMoadal(false)} open={openMoadal} />
            <CustomModal
                handleOpen={handleOpenAmtLimit}
                open={openAmtLimit}
                handleClose={handleCloseAmtLimit}
                handleConfirm={handleConfirm}
                Content={amtLimitContent}
                loadingConfirmBtn={loading}
                confirmBtnVariant={"secondary"}
                btnTitle={"Upgrade account"}
            />
            <HeaderSection handlePressAccountSetup={handlePressAccountSetup} handlePressGoBack={handlePressGoBack} cashFundSummary={cashFundSummary} smScreen={smScreen} />
            <Container maxWidth={'xl'} disableGutters={smScreen}>
                <Grid container sx={{ boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)", background: "#ffff", border: "1px solid rgba(208, 213, 221, 0.25)", borderRadius: "8px", mt: { xs: "24px", md: "40px" }, minHeight: '60vh' }}>
                    <Projection
                        formik={formik}
                        initialAmount={initialAmount}
                        monthlySaving={monthlySaving}
                        retirementAmount={retirementAmount}
                        planType={planType}
                        handleInterestCompute={handleInterestCompute}
                        isEmployer={isEmployer}
                        interestData={interestData}
                    />
                </Grid>
                <Grid container>
                    <Box sx={{ boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)", width: "100%", background: "#ffff", border: "1px solid rgba(208, 213, 221, 0.25)", borderRadius: "8px", mt: { xs: "24px", md: "40px" }, minHeight: '60vh' }}>
                        <Portfolio formik={formik} />
                    </Box>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={12} sx={{ mt: { xs: "24px", md: "40px" }, mb: { xs: "24px", md: "40px" }, borderRadius: "8px", boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)" }}>
                        <Box sx={{ background: "white", p: "8px 16px" }}>
                            {buttonHtml()}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default CashFundAssetAllocation;
