import { Box, IconButton, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { quickLinkStyle } from 'Latest/DashboardScreenV1/style/quickLinkStyle';
import { tokens } from 'theme';
import ArrowUp from 'Latest/ComponentV1/ArrowUp';

const RenderItem = ({ handleOpen, item }: props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isHover, setIsHover] = useState("");

    const isDown = item.type == "down";

    return (
        <>
            <Box
                onClick={handleOpen}
                sx={{
                    ...quickLinkStyle.mainBoxV2,
                    border: isDown ? `1px solid ${colors.success[150]}` : `1px solid ${colors.red[150]}`,
                    "&:hover": {
                        background: isDown ? "#f1fef7" : "#fef9f8"
                    }
                }}
                onMouseEnter={() => setIsHover(item?.title)} onMouseLeave={() => setIsHover("")}
                className="dep-with-btn"
            >
                <Box className="childBox">
                    <Typography variant='h6' color={isDown ? colors.success[150] : colors.red[150]}>
                        {item.title}
                    </Typography>
                    <ArrowUp type={item.type} />
                </Box>
            </Box >
        </>
    )
}

export default RenderItem

interface props {
    handleOpen: any,
    item: {
        icon: string;
        title: string;
        link: string;
        modalComp: React.ComponentType<any>;
        type?: string
    };
}