/* eslint-disable sort-keys */
const PoppinsFamily = 'Poppins !important'
const InterFamily = 'Inter !important'

export const customStyle = {
    updateBtn: {
        fontFamily: 'Inter,sans-serif', fontStyle: "normal",
        fontWeight: 600,
        // fontSize: "18px",
        lineHeight: "150%",
        background: "#403081", border: "1px solid #403081", borderRadius: "6px", color: "#FFFFFF", padding: "14px 48px",
        "&:hover": { background: "#413082e6" },
        "&:disabled": {
            background: "gray !important",
            color: "#FFFFFF",
            border: "1px solid gray",
        }
    }
}

export const WaitListResponseModalStyles = {
    container: {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        overflow: 'hidden',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        width: { md: '400px', sm: '75%', xs: '95%' },
        '& .MuiGrid-container': {
            p: 3,
            maxHeight: '100%',
            overflow: 'auto',
            backgroundColor: '#ffffff',
            boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
            borderRadius: '12px',
        },
        '& .head': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            pb: 1
        },
        '& .closeImg': {
            mt: 1,
            "&:hover": {
                cursor: 'pointer'
            }
        },
        '& .body': {
            pt: 1
        },
        '& h5': {
            fontFamily: PoppinsFamily,
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '28px',
            color: '#101828'
        },
        '& p': {
            fontFamily: PoppinsFamily,
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            color: '#475467'
        },
        '& .footer': {
            backgroundColor: 'transparent',
            pt: 5,
            '& .col-md-12': {
                padding: 0,
                '& .MuiBox-root': {
                    margin: 0,
                    '& button': {
                        margin: '0 !important',
                        minHeight: '44px !important',
                        height: 'auto !important',
                        width: '48% !important',
                        borderRadius: '8px',
                        fontSize: '16px',
                        '&:first-child': {
                            border: '1px solid #D0D5DD',
                            backgroundColor: 'transparent'
                        }
                    }
                }
            }
        }
    }
}

export const WaitListModalStyles = {
    container: {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        py: '10px',
        pl: { md: 0, sm: 0, xs: 2 },
        pr: { md: 0, sm: 0, xs: 2 },
        overflow: 'hidden',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        width: { md: '500px', sm: '75%', xs: '100%' },
        '& .MuiGrid-container': {
            px: 3,
            py: 2,
            maxHeight: '100%',
            overflow: 'auto',
            backgroundColor: '#ffffff',
            boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
            borderRadius: '12px',
            width: '100%'
        },
        '& p': {
            pt: '5px',
            fontFamily: InterFamily,
            fontStyle: 'italic',
            fontWeight: 300,
            fontSize: '13px',
            lineHeight: '20px',
            textAlign: 'center',
            color: '#475467',
            width: { md: '401.98px', xs: 'auto' },
            margin: '0 auto'
        },
        '& h2': {
            fontFamily: PoppinsFamily,
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '26px',
            lineHeight: '28px',
            textAlign: 'center',
            background: 'linear-gradient(248.22deg, #43BED8 34.33%, #432F87 78.15%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            pb: 1
        },
        '& h3': {
            fontFamily: PoppinsFamily,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '22px',
            lineHeight: '28px',
            textAlign: 'center',
            color: '#403081'
        },
    },
    header: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        '& img': {
            width: '175px',
            height: 'auto',
            objectFit: 'contain'
        },
        '& .closeIcon': {
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            right: 0,
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    body: {},
    firstContentSec: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px 24px',
        gap: '18px',
        backgroundColor: '#F3F4F6',
        borderRadius: '10px',
        mt: 2,
        '& button, & a': {
            minWidth: { md: '220px', sm: '220px', xs: '100%' },
            minHeight: "44px",
            borderRadius: '8px',
            fontFamily: InterFamily,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#fff',
            backgroundColor: 'rgb(64, 48, 129)',
            border: '1px solid rgb(64, 48, 129)',
            boxShadow: 'rgba(16, 24, 40, 0.05) 0px 1px 2px',
            textTransform: 'capitalize',
            padding: '10px 18px',
            transition: 'all 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s',

            '&:hover': {
                transform: 'translateY(-3px)',
                backgroundColor: 'rgb(51, 38, 103)',
                border: '1px solid rgb(51, 38, 103)',
                boxShadow: 'rgba(45, 34, 90, 0.3) 0px 10px 20px 0px',
            },
        }
    },
    secContentSec: {
        pt: 2, pb: 2,
    },
    formSec: {
        pt: 3,
        '& label': {
            fontFamily: InterFamily,
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#344054',
            mb: 0
        },

    },
    inputStylesContainer: {
        '&:first-child': {
            pb: 2
        },
        '& label': {
            fontFamily: InterFamily,
            fontWeight: '500 !important',
            fontSize: '14px !important',
            lineHeight: '20px',
            color: '#344054',
            '& span': {
                fontFamily: InterFamily,
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                color: '#DC2626'
            },
        },
        '& input': {
            fontFamily: InterFamily,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            minHeight: '42px',
            padding: '0px 14px',
            color: '#000',
            boxShadow: 'none',
            outline: 'none',
            fontStyle: 'normal',
            border: '1px solid #D0D5DD',
            borderRadius: '4px !important',

            '&::placeholder': {
                fontFamily: InterFamily,
                color: '#667085'
            }
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        pt: 5,
        '& .col-md-12': {
            p: 0,
            '& .MuiBox-root': {
                m: 0
            }
        },
        '& button': {
            width: { md: '48% !important', sm: '48% !important', xs: '100% !important' },
            borderRadius: '8px',
            fontFamily: InterFamily,
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            m: 0,
            transition: 'all 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s',
            '&:first-child': {
                backgroundColor: 'rgb(255, 255, 255)',
                border: ' 1px solid rgb(208, 213, 221)',
                color: 'rgb(52, 64, 84)',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px',
                mb: { md: 0, sm: 0, xs: 2 },
                '&:hover': {
                    transform: 'translateY(-3px)',
                    backgroundColor: ' rgb(255, 255, 255)',
                    border: '1px solid  rgb(208, 213, 221)',
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 20px 0px',
                },
            },
            '&:last-child': {
                backgroundColor: 'rgb(60, 171, 194)',
                border: '1px solid rgb(60, 171, 194)',
                boxShadow: 'rgba(16, 24, 40, 0.05) 0px 1px 2px',
                '&:hover': {
                    transform: 'translateY(-3px)',
                    backgroundColor: 'rgb(60, 171, 194)',
                    border: '1px solid rgb(60, 171, 194)',
                    boxShadow: 'rgba(22, 93, 108, 0.3) 0px 10px 20px 0px',
                },
            }
        },
    },
    contentModal: {
        backgroundColor: 'transparent',
        p: 0,
        gap: 'unset',
        m: 0,
        '& h2': {
            p: 0,
            lineHeight: 'unset'
        }
    }
}

export const authStyles = {
    container: {
        // pt: 10, pb: 10,
        '& .Form_Main': {
            p: { md: '1rem 1.188rem 2rem', sm: '1rem 1.188rem 2rem', xs: '1rem 10px 2rem' }
        },
        '& .logo': {
            mt: '0 !important',
            height: 'auto !important',
            width: 'auto !important',
            maxWidth: '100%',
            objectFit: 'contain',
            mb: 6
        },
        '& .login_Card': {
            borderRadius: '24px',
            background: '#FFFFFF',
            boxShadow: '0px 32px 64px - 12px rgba(16, 24, 40, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: { md: '50px 80px', sm: '50px 80px', xs: '20px' },
            gap: '25px',
            minWidth: { md: '552px', xs: 'auto' },
        },
        '& .Login_heading': {
            fontFamily: PoppinsFamily,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '27.21px',
            lineHeight: '110%',
            letterSpacing: '-0.02em',
            color: '#000000',
        },
        '& label, & .label_login': {
            // fontFamily: InterFamily,
            fontStyle: 'normal !important',
            fontWeight: '600 !important',
            fontSize: '16px !important',
            lineHeight: '150% !important',
            color: '#202020 !important',
            '& span': {
                pl: 0.5
            }
        },
        '& .MuiInputBase-root, .react-tel-input': {
            minHeight: '48px',
            border: '1px solid #EDEDED',
            borderRadius: '4px !important',
            padding: 0
        },
        '& .react-tel-input': {
            display: 'flex',
            flexDirection: 'row-reverse',
            padding: '0px 20px',
        },
        '& .react-tel-input .flag-dropdown': {
            position: 'relative'
        },
        '& .react-tel-input .selected-flag, & .react-tel-input .flag-dropdown, & .react-tel-input .form-control': {
            border: 'none !important',
        },
        '& .react-tel-input .form-control, & .react-tel-input .selected-flag': {
            pl: 0
        },
        '& fieldset': {
            border: 'none'
        },
        '& .inputWrapper': {
            mb: '1.5rem !important'
        },
        '& input, & .react-tel-input .form-control': {
            padding: '0px 20px',
            height: '100%',
            fontFamily: InterFamily,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            color: '#202020 !important',
            '&::placeholder': {
                color: '#ADADAD !important',
                fontFamily: InterFamily,
                fontSize: '16px',
                opacity: 1
            }
        },
        '& .selected-flag': {
            width: '100%'
        },
        '& .react-tel-input .selected-flag .flag': {
            position: 'relative'
        },
        '& .MuiIconButton-root': {
            pr: '0',
            mr: '20px'
        },
        '& .btn_Signin': {
            mt: '10px !important',
            padding: '17px 20px',
            height: '56px',
            backgroundColor: '#403081 !important',
            border: '1px solid #403081',
            borderRadius: '6px !important',
            boxShadow: 'none !important',
            fontFamily: InterFamily,
            fontSize: '18px !important',
            fontWeight: '600 !important',
            lineHeight: '22px !important',
            mb: '0 !important'
        },
        '& .acc_label': {
            flexDirection: 'row !important',
            justifyContent: 'center !important',
            fontFamily: PoppinsFamily,
            fontWeight: '600 !important',
            color: '#4B5563 !important',
            lineHeight: '17.6px !important',
            fontSize: '16px !important',
            '& a': {
                flexDirection: 'row !important',
                justifyContent: 'center !important',
                fontFamily: PoppinsFamily,
                fontWeight: '600 !important',
                color: '#43BED8 !important',
                lineHeight: '17.6px !important',
                fontSize: '16px !important',
                pl: 0.5
            }
        },
        '& .label_login': {
            fontWeight: '500 !important',
        },
        '& .label_login ~ .btn_Signin': {
            marginTop: '25px !important'
        }
    },
    verifyEmail: {
        '& .otpForm': {
            p: 0,
            width: '100%',
            '& img': {
                margin: '0 auto 2.5rem'
            },
            '& h1': {
                mb: '8px !important',
            },
            '& p': {
                fontFamily: PoppinsFamily,
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                color: '#475467',
            },
            '& #otp-field': {
                '& p': {
                    fontFamily: PoppinsFamily,
                    fontStyle: 'normal',
                    // fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: 'rgba(29, 41, 57, 0.70)',
                },
                '& input': {
                    border: '1px solid #D0D5DD !important',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '8px !important',
                    marginLeft: '0px !important',
                    marginRight: '8px !important',
                    width: { md: '58.67px !important', sm: '58.67px !important', xs: '45px !important' },
                    height: { md: '64px !important', sm: '64px !important', xs: '50px !important' },
                    fontFamily: InterFamily,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '48px',
                    lineHeight: '60px',
                    color: '#D0D5DD !important',
                    textAlign: 'center !important',
                    letterSpacing: '-0.02em',
                    padding: 0,
                    mb: 1,
                    '&.focused': {
                        border: '1px solid #43BED8 !important',
                        outline: '#43BED8',
                        boxShadow: '0 0 5px #007bff'
                    },
                    '&::selection': {
                        color: '#D0D5DD !important',
                        background: 'transparent',
                    }
                }
            }
        },
        '& button.btn_Signin': {
            mt: '40px !important'
        },
        '& .counter-text': {
            color: '#475467',
            fontWeight: '400 !important',
            fontFamily: InterFamily,
            fontSize: '14px !important',
            lineHeight: '20px !important'
        },
    },
    counter: {
        justifyContent: 'end',
        color: "#43BED8 !important",
        ml: '5px !important',
        fontWeight: '500 !important',
        fontFamily: InterFamily,
        fontSize: '14px !important',
        lineHeight: '20px !important'
    },
    resendEmail: {
        '& img.logo': {
            margin: '0 auto 2.5rem'
        },
        '& .ReEmail_heading': {
            color: '#202020',
            fontWeight: '600 !important',
            fontFamily: InterFamily,
            fontSize: '18px !important',
            lineHeight: '27px !important',
            textAlign: 'center',
            margin: '20px auto'
        },
        '& #otp-field': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 1,
            '& img': {
                height: 'auto',
                width: 'auto',
                maxWidth: '100%'
            }
        }
    },
    emailVerification: {
        '& .login_Card': {
            padding: '24px',
            borderRadius: '14px',
            background: '#FFFFFF',
            boxShadow: '0px 32px 64px - 12px rgba(16, 24, 40, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '25px',
            minWidth: { md: '400px', xs: 'auto' },
            width: { md: '400px', sm: '500px', xs: 'auto' },
        },
        '& .ReEmail_heading': {
            color: '#202020',
            fontWeight: '600 !important',
            fontFamily: InterFamily,
            fontSize: '18px !important',
            lineHeight: '27px !important',
            textAlign: 'left !important',
            margin: '20px auto 5px 0'
        },
        '& .description': {
            color: '#202020',
            fontWeight: '400 !important',
            fontFamily: InterFamily,
            fontSize: '14px !important',
            lineHeight: '20px !important',
            textAlign: 'left',
            mb: '20px !important'
        },
        '& .Form_Main .btn_Signin': {
            height: '44px',
            padding: '10px 18px',
            fontSize: '16px !important'
        }
    }
}

export const authStylesOTP = {
    '& .otpForm': {
        p: 0,
        width: '100%',
        '& img': {
            margin: '0 auto 2.5rem'
        },
        '& h1': {
            mb: '8px !important',
        },
        '& p': {
            fontFamily: PoppinsFamily,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#475467',
        },

        '& #otp-field': {
            '& p': {
                fontFamily: PoppinsFamily,
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
                color: '#344054',
            },
            '& input': {
                border: '1px solid #D0D5DD !important',
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                borderRadius: '8px !important',
                marginLeft: '0px !important',
                marginRight: '8px !important',
                width: { md: '58.67px !important', sm: '58.67px !important', xs: '45px !important' },
                height: { md: '64px !important', sm: '64px !important', xs: '50px !important' },
                fontFamily: InterFamily,
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '48px',
                lineHeight: '60px',
                color: '#D0D5DD !important',
                textAlign: 'center !important',
                letterSpacing: '-0.02em',
                padding: 0,
                mb: 1,
                '&.focused': {
                    border: '1px solid #43BED8 !important',
                    outline: '#43BED8',
                    boxShadow: '0 0 5px #007bff'
                },
                '&::selection': {
                    color: '#D0D5DD !important',
                    background: 'transparent',
                }
            }
        }
    }
}

export const mainContainerV2 = {
    display: "flex",
    padding: { md: "20px 40px 20px", xs: "16px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    borderRadius: "3px 3px 8px 8px",
    border: "0.98px solid rgba(208, 213, 221, 0.25)",
    background: "#FFF",
    boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
    position: "relative",

    '& input': {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "normal",
        '&::placeholder': {
            color: "#667085"
        }
    }
}

export const smallScreenBtnWrap = {
    borderRadius: "8px",
    borderTop: "1px solid rgba(208, 213, 221, 0.25)",
    borderBottom: "1px solid rgba(208, 213, 221, 0.25)",
    background: "#FFF",
    boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
    padding: "8px 16px",
    mt: "16px",
    width: "100%"
}

export const secBg = {
    backgroundColor: "#422E84",
    borderRadius: "8px"
}

