import { Box, Button, Checkbox, Container, Divider, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ButtonsRow from '../ButtonsRowLatest';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Cash_Fund_T_C, DisclaimersArray } from './constant/index';
import { featureBox, featureBoxBtn, featureBoxTypo, featureMainBox } from './style';
import { containerMain, containerMainBox, HeadingStyle, linkSpan } from 'Latest/Style/Main';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/reduxHooks';
import { addCashFund, addCashFundPut, addGoal, addGoalPut, goalIdAction, goalFlowIdAction, goalFlowPathAction } from 'redux/reducers/portfolio';
import { CASH_FUND_ID, CASH_FUND_WEALTH_BASE_URL, FlowPath, mixPannelTrackerEventName } from '../../../../constants';
import Big from 'big.js';
import CustomSingleCheckBox from 'jsx/components/Shared/CustomSingleCheckBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LandingPageURL } from "constants/landingPageURL"
import { useAppLoading } from 'hooks/appLoading';
import Loader from 'jsx/components/Shared/Loader';
import { createInvestGoal, createInvestGoalPut, onGetBanks } from 'redux/reducers/sharedStateData';
import { getPaymentAcc, onGetAccountSetup, onPostUserAnswersRiskProfile, onPutUserAnswers } from 'redux/actions';
import FormikBooleanButtonsField from 'jsx/components/Shared/V1/FormikBooleanBtnFieldBoxV1';
import DynamicQuestionAnswers from 'Latest/2ndTierKyc/steps/Declaration/dynamicQuestion';
import CFDownloadTab from './CFDownloadTab';
import GIDownloadTab from './GIDownloadTab';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import { motherNameValidation } from 'validate/Screen/AccountSetupFormValidation';
import InputField from 'jsx/components/Shared/FormikInputFieldLatest';
import { links } from 'static/links';
import { useHistory } from 'react-router-dom';


const TermCondition2 = ({ formik, goalId, loading, nextStep, previousStep, submitGoalBig, ...props }: any) => {
    const dispatch = useDispatch();
    const { goBack, push } = useHistory();

    // useEffect(() => {
    //     dispatch(onGetBanks());
    //     dispatch(getPaymentAcc())
    // }, [dispatch]);

    const { userAccountDetails } = useAppSelector(state => state.auth);
    const { termConditionUserQuestions } = useAppSelector((state) => state.questionAnswer);

    const appLoading = useAppLoading('portfolio');

    const intialFormikTermValue: any = {
        term1: false,
        term2: false,
        term3: false,
    }

    if (termConditionUserQuestions.questionAnswer?.length) {
        termConditionUserQuestions?.questionAnswer?.map((item: any) => {
            intialFormikTermValue[item?.id] = ""
        })
    }

    const generateValidationSchema = (questions: any) => {
        const validationSchema: any = {};
        validationSchema["term1"] = Yup.bool().oneOf([true], 'Required')
        validationSchema["term2"] = Yup.bool().oneOf([true], 'Required')
        validationSchema["term3"] = Yup.bool().oneOf([true], 'Required')

        if (questions?.questionAnswer?.length > 0) {
            questions.questionAnswer.forEach((item: any) => {
                const questionId = item?.id;
                validationSchema[questionId] = Yup.string().required('Required');
            });
        }

        return Yup.object().shape(validationSchema);
    };

    const motherFieldFormik = useFormik({
        enableReinitialize: true,
        initialValues: { motherName: userAccountDetails?.motherName, },
        onSubmit: async (values: any) => {
            // onSubmitMotherName();
        },
        validationSchema: motherNameValidation
    });

    const formikTerm = useFormik({
        enableReinitialize: true,
        initialValues: intialFormikTermValue,

        onSubmit: values => {
            const body = {
                name: userAccountDetails?.identityCard?.name,
                fatherOrHusbandName: userAccountDetails?.identityCard?.fatherName,
                motherName: motherFieldFormik.values?.motherName,
                dateOfBirth: userAccountDetails?.identityCard?.dateOfBirth,
                identityCardNumber: userAccountDetails?.identityCard?.identityCardNumber,
                identityCardIssuanceDate: userAccountDetails?.identityCard?.dateOfIssue,
                identityCardExpiryDate: userAccountDetails?.identityCard?.dateOfExpiry,
                gender: userAccountDetails?.identityCard?.gender,
                countryOfStay: userAccountDetails?.identityCard?.countryOfStay,
                identityCardLifeTimeExpiry: userAccountDetails?.identityCard?.lifeTimeExpiry,
                ukn: userAccountDetails?.identityCard?.ukn,
                rememberUKN: userAccountDetails?.rememberUKN,
                iban: userAccountDetails?.iban,
                accountType: userAccountDetails?.accountType,
            };

            const onSuccess = () => {
                push(links.PayNow, props.location.state);
                setTimeout(() => {
                    dispatch(onGetAccountSetup());
                }, 1000);
            }

            dispatch(onPutUserAnswers(body, onSuccess));

        },
        validationSchema: generateValidationSchema(termConditionUserQuestions)
    });

    const checkCashFund = props.location.state.category === "CashFund";

    const handleContinue = () => {
        if (!userAccountDetails?.motherName) {
            motherFieldFormik.handleSubmit();
            if (motherFieldFormik.isValid) {
                formikTerm.handleSubmit();
            } else {
                console.log('Mother\'s Name is error-free');
            }
        } else {
            formikTerm.handleSubmit();
        }
    }

    const declerationQuestion = () => {
        return (
            termConditionUserQuestions?.questionAnswer?.map((value: any, index: number) => {
                return (
                    <Box key={index} sx={{
                        ...HeadingStyle, fontWeight: 400, fontSize: "16px",
                        '& p.MuiTypography-root, & label': { fontFamily: 'Inter,sans-serif', textAlign: 'left' }
                    }}>
                        <DynamicQuestionAnswers
                            formik={formikTerm}
                            nestedQuestion={{}}
                            questionAnswers={value}
                        />
                    </Box>
                )
            })
        )
    };

    return (
        <Container maxWidth={'md'} sx={containerMain}  >
            {appLoading && <Loader />}
            <Box sx={containerMainBox}>
                <Grid container spacing={2} >
                    <Grid item md={12} xs={12} sx={{ py: "2rem", pr: "1.5rem" }} >

                        <Container sx={{ ml: "1rem", minHeight: "30rem", mt: "2rem", mb: "2rem" }} >
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <Typography sx={HeadingStyle}>Conditions</Typography>
                                    </Grid>


                                    {!userAccountDetails?.motherName &&
                                        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <InputField
                                                required
                                                formik={motherFieldFormik}
                                                label={"Mother maiden name"}
                                                name='motherName'
                                                placeholder="Enter mother maiden name"
                                                type="text"
                                            />
                                        </Grid>}

                                    <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
                                        <Typography sx={{ ...HeadingStyle, fontSize: "24px" }}>{"Declaration"}</Typography>
                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <Stack component="ul" spacing={2} sx={{ pl: 1 }}>
                                            {Cash_Fund_T_C.map((item, index: number) => (
                                                <Stack
                                                    key={item.para}
                                                    alignItems="center"
                                                    component="li"
                                                    direction="row"
                                                    spacing={1.5}
                                                    sx={{ alignItems: "baseline" }}
                                                >
                                                    <FiberManualRecordIcon sx={{ fontSize: "xx-small", color: "black" }} />
                                                    <Typography sx={{ ...HeadingStyle, fontWeight: 400, fontSize: "16px", textAlign: 'left' }}>
                                                        {item.para} &nbsp;
                                                        {item.link && <span style={{ fontWeight: 700, lineHeight: "155%", color: "#43BED8", cursor: "pointer" }}>{item.link}</span>}
                                                    </Typography>
                                                    {index == Cash_Fund_T_C.length - 1 ?
                                                        declerationQuestion()
                                                        :
                                                        <Typography sx={{ ...HeadingStyle, fontWeight: 400, fontSize: "16px", textAlign: 'left' }}>
                                                            {item.para} &nbsp;
                                                            {item.link && <span style={{ fontWeight: 700, lineHeight: "155%", color: "#43BED8", cursor: "pointer" }}>{item.link}</span>}
                                                        </Typography>
                                                    }

                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Grid>


                                </Grid>

                                <Divider sx={{ my: 4 }} />

                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <Typography sx={{ ...HeadingStyle, fontSize: "24px" }}>{checkCashFund ? "Mahaana Save+ Terms and Conditions" : "Disclaimers:"}</Typography>
                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', }}>
                                        <Stack component="ul" spacing={2} sx={{ pl: 1 }}>
                                            {DisclaimersArray.map((item: string, index: number) => (
                                                <Stack
                                                    key={item}
                                                    alignItems="center"
                                                    component="li"
                                                    direction="row"
                                                    spacing={1.5}
                                                    sx={{ alignItems: "baseline" }}
                                                >
                                                    <FiberManualRecordIcon sx={{ fontSize: "xx-small", color: "black" }} />
                                                    <Typography sx={{
                                                        ...HeadingStyle, fontWeight: 400,
                                                        fontSize: "16px", textAlign: 'left'
                                                    }}>{item} {DisclaimersArray.length == index + 1 && <a href={LandingPageURL.TC} target='_blank' rel="noreferrer" style={linkSpan}>terms and conditions.</a>} </Typography>
                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={2} sx={{ mt: 1 }}>
                                    <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <Typography sx={{ ...HeadingStyle, fontSize: "24px" }}>MICF Information:</Typography>
                                    </Grid>
                                    <CFDownloadTab />

                                    {!checkCashFund &&
                                        <>
                                            <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <Typography sx={{ ...HeadingStyle, fontSize: "24px" }}>MIIETF Information:</Typography>
                                            </Grid>
                                            <GIDownloadTab />
                                        </>
                                    }


                                    <Grid item md={12} xs={12}>
                                        <CustomSingleCheckBox
                                            formik={formikTerm}
                                            fieldName={`term1`}
                                            label={
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: "I have read the <a style='color: #422E84;text-decoration-line: underline;' href='https://mahaana.com/terms-conditions' target='_blank'>terms and conditions</a> and agree to them." }}
                                                />
                                            }
                                            type={"checkbox"}
                                            styleForm={{ paddingLeft: "0px" }}
                                            styleErr={{ marginLeft: "0px" }}
                                        />
                                    </Grid>

                                    <Grid item md={12} xs={12} >
                                        <CustomSingleCheckBox
                                            formik={formikTerm}
                                            fieldName={`term2`}
                                            label={`I confirm I am the ultimate beneficiary of my investment account.`}
                                            type={"checkbox"}
                                            styleForm={{ paddingLeft: "0px" }}
                                            styleErr={{ marginLeft: "0px" }}
                                        />

                                    </Grid>

                                    <Grid item md={12} xs={12} >
                                        <CustomSingleCheckBox
                                            formik={formikTerm}
                                            fieldName={`term3`}
                                            label={`I certify that I am Pakistani resident for tax purposes.`}
                                            type={"checkbox"}
                                            styleForm={{ paddingLeft: "0px" }}
                                            styleErr={{ marginLeft: "0px" }}
                                        />

                                    </Grid>

                                    <Grid item md={12} xs={12} >
                                        {declerationQuestion()}
                                    </Grid>

                                </Grid>

                            </Box>
                        </Container>

                        <ButtonsRow
                            backButtonOnClick={(e: any) => {
                                e.preventDefault();
                                goBack();
                            }}
                            loading={loading}
                            nextButtonOnClick={(e: any) => {
                                e.preventDefault();
                                handleContinue();
                            }}
                        />

                    </Grid>
                </Grid>
            </Box>
        </Container >
    )
}

export default TermCondition2