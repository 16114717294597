/* eslint-disable sort-keys */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { CASH_FUND_WEALTH_BASE_URL, PAYMENT_GATEWAY_BASER_URL, RISK_PROFILE_BASE_URL, WEALTH_API_BASE_URL } from '../../constants';

const initialState: InitialState = {
    investorGoal: undefined,
};

const name = 'paymentGateway';
const paymentGateway = createSlice({
    initialState,
    name,
    reducers: {}
});
// eslint-disable-next-line no-empty-pattern
export const { } = paymentGateway.actions;
export default paymentGateway.reducer;

//? APIS ACTIONS
export const onPayMobIframeLink = (data: any, onSuccess: (message: any, response: any) => void, onFail: (message: any, response: any) => void) => ({
    payload: {
        apiName: name,
        data,
        method: 'post',
        onSuccess: onSuccess,
        onFail: onFail,
        url: `${PAYMENT_GATEWAY_BASER_URL}/api/CashIn`,
    },
    type: 'onPayMobIframeLink'
});

//? Types
interface InitialState {
    investorGoal: any;
}
