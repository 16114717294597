import * as React from 'react';
import Alert from '@mui/material/Alert';
import { AlertTitle, Box, Button, IconButton, Skeleton, Typography } from '@mui/material';
import useStylesAlertInfo from 'Latest/ComponentV1/CustomMakeStyle/customInfoAlert';
import { submitInfoStyle } from '../style';
import IconInfo from "Latest/Images/svg/IconInfo.svg";

export default function TierKycInfoAlert({ handleClose }: any) {

    const classes = useStylesAlertInfo();

    return (
        <>
            <Box>

                <Alert
                    severity="info" className={classes.customAlert} sx={{ position: "relative" }} icon={false}
                    onClose={handleClose}
                >
                    <AlertTitle sx={{ ...submitInfoStyle.mainHeading, marginBottom: 0 }}>
                        <img src={IconInfo} />
                        Your account has been successfully upgraded. Deposit limits have been removed.
                    </AlertTitle>
                </Alert>
            </Box>
        </>
    );
}