export const cnicViewStyle = {
    imgBox: {
        width: { xs: "50%", sm: "50%", md: "100%" },
        border: "1px solid #EAECF0", borderRadius: "1rem",
        p: 3, display: "flex", justifyContent: "center",
        alignItems: "center", minHeight: "230px",
    },
    heading: {
        color: "#101828", fontFamily: 'Poppins', fontWeight: 600, fontSize: "22px", lineHeight: "38px",
    },
    cnicGrid:
        { display: "flex", gap: 4, flexDirection: { xs: "column", sm: "column", md: "row" }, mt: 2, mb: 4 },
    headingGrid: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    cnicLockBox: {
        alignItems: "center", flexDirection: "column",
    },
    cnicLockImage:{
        alignItems: "center", flexDirection: "column",width: { xs: "100%", sm: "100%", md: "100%" },
        border: "1px solid #EAECF0", borderRadius: "1rem",
        p: 3, display: "flex", justifyContent: "center",
        minHeight: "230px",
    },
    cnicLockBoxTypo: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "28px",
        color: "#403081"
    },

    approvalBtn: {
        background: "#432F87", border: "1px solid #432F87",
        borderRadius: "100px",
        padding: { md: "0px 22px", xs: "0 16px" }, minHeight: { md: 44, xs: 40 },
        "&:hover": { background: "#35256c" },
        color: "#FFFFFF", fontWeight: { md: 500, xs: 400 }, fontSize: { md: "1rem", xs: "14px" }, textTransform: "capitalize"
    },
    checkBoxWrapper: {
        '& .sameAsAddressCheckBox': {
            backgroundColor: "#D0D5DD40",
            borderRadius: "8px",
            padding: "8px 16px",
            '& label[for=consentCheck]': {
                fontSize: { md: "1rem", xs: "14px" }
            }
        }
    },
    title: {
        fontSize: { md: "1.5rem", xs: "1rem" }
    }
}

export const zakatStyle = {
    linkButon: {
        color: "#432F87",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "151%",
        textDecoration: "underline",
    },
    checkBoxWrapper2: {
        backgroundColor: "#D0D5DD40",
        borderRadius: "8px",
        padding: "8px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        '& label[for=consentCheck]': {
            fontSize: { md: "1rem", xs: "14px" }
        },
        '& .v2SingleCheckBox': {
            border: "none !important"
        }
    },
    zakatFormWrap: {
        border: "0.66px dashed #8A8A8A", minHeight: "120px", padding: { md: "32px", xs: "24px" },
        display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",
        borderRadius: "8px", position: "relative"
    },
    captionText: {
        color: "#616161", fontSize: { md: "12px", xs: "12px" }, fontWeight: 500,
        lineHeight: "16px", mt: "6px"
    },
    uploadZakatBtn: {
        padding: { md: "12px 24px", xs: "8px 16px" },
        borderColor: "#432F87",
        fontSize: { md: "1rem", xs: "14px" },
        fontWeight: 500,
        "&:hover": { borderColor: "#432F87" }
    },
    removeIcon: {
        position: "absolute", right: "65px", cursor: "pointer"
    },
    formSubmitText: {
        fontWeight: 500, color: "#422E84",
        marginLeft: { xs: "0", sm: "20px", md: "20px" },
        display: { xs: "none", sm: "block", md: "block" },
        padding: "8px 0",
        textAlign: "right"
    },
    formSubmitTextXs: {
        fontWeight: 500, color: "#422E84",
        textAlign: "center",
        display: { xs: "block", sm: "none", md: "none" },
        padding: "8px 22px"
    }
}