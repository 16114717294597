import * as React from 'react';
import { memo, useCallback, useState } from 'react';
import { Cell, Legend, Pie, PieChart as Chart, ResponsiveContainer, Sector, Tooltip, Text } from 'recharts';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PorfolioStyles } from 'jsx/components/WizardFormModal/CashFundAssetAllocation/Style/style';
import { Box, Typography } from '@mui/material';
import { tokens } from 'theme';
import { customPieChartStyle } from './style';
import { CurrencyFormatter, CurrencyFormatterWithDecimal } from 'utils/calculation';

const PortfolioChart = ({
    colors,
    customHeight,
    customTooltipTrue = false,
    data,
    hoverDotVal,
    hoverToolTip = false,
    innerRadiusPie = "57%",
    outerRadiusPie = "90%",
    route,
    savings,
    selection,
    showCustomActiveShape = true
}: PortfolioChartProps) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [hoverData, setHoveredDot] = useState(-1)

    const customBreakpoint = '(max-width: 1326px)';
    const xlScreen = useMediaQuery(customBreakpoint);

    const colorTheme = tokens(theme.palette.mode);
    const heightSet = smScreen ? 230 : xsScreen ? 210 : 210
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);

        },
        [setActiveIndex]
    );

    const renderCusomizedLegend = (props: any) => {
        const { payload } = props
        return (
            <ul>
                {
                    payload.map((entry: any, index: any) => (
                        <div key={`item-${index}`} style={PorfolioStyles.pieChartLegentCustomMainDivStyle}>
                            <div style={PorfolioStyles.legendContentWrapper}>
                                <div style={{ ...PorfolioStyles.pieChartLegentCustomColorBoxStyle, background: entry?.color }} />
                                <div style={PorfolioStyles.pieChartLegentCustomTitleStyle}>{entry?.value}</div>
                            </div>
                            <div style={PorfolioStyles.pieChartLegentCustomAmountStyle}>{entry?.payload?.value?.toFixed(2)}%</div>
                        </div>
                    ))
                }
            </ul >
        )
    }

    // const renderHorizontalCusomizedLegend = (props: any) => {
    //     const { payload } = props
    //     return (
    //         <Box sx={customPieChartStyle.legendMainBox}>
    //             {payload.map((entry: any, index: any) => (
    //                 <Box key={`item-${index}`} className="legendSubBox">
    //                     <Typography variant='h6' sx={{ color: colorTheme.greyIron[600] }}>
    //                         <Box className={"colorSubBox"} sx={{ background: entry.color, }} />
    //                         {entry.value}
    //                     </Typography>

    //                     <Box className="amountBox">
    //                         <Typography variant='h5' sx={{ color: colorTheme.greyIron[600] }}> {CurrencyFormatter(entry.payload.amount, true)}   </Typography>
    //                         <Typography variant='h6' sx={{ color: colorTheme.greyIron[600] }}> ({entry.payload.value}%)  </Typography>
    //                     </Box>
    //                 </Box>
    //             ))}
    //         </Box>
    //     )
    // }

    const customRenderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            endAngle,
            fill,
            innerRadius,
            midAngle,
            outerRadius,
            payload,
            percent,
            startAngle,
            value
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path
                    d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                    stroke={fill}
                    fill="none"
                />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    textAnchor={textAnchor}
                    fill={colorTheme.greyIron[600]}
                    style={{ ...customPieChartStyle.renderActiveShapeStyleText1 }}>
                    {payload.name}
                </text>
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    dy={18}
                    textAnchor={textAnchor}
                    fill={colorTheme.greyIron[600]}
                    style={{ ...customPieChartStyle.renderActiveShapeStyleText2 }}
                >
                    {CurrencyFormatterWithDecimal(payload.amount, true, "never", false)} &nbsp; ({payload.value}%)
                    {/* {CurrencyFormatterWithDecimal(payload.amount, true, "never", false)}&nbsp;{!xlScreen && `(${payload.value}%)`} */}
                </text>
            </g>
        );
    };

    const renderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            endAngle,
            fill,
            innerRadius,
            midAngle,
            outerRadius,
            payload,
            percent,
            startAngle,
            value
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        let valueDy = 20;

        const breakWords = (text: any, maxLength: any) => {
            if (text.length > maxLength) {
                const words = text.split(' ');
                let currentLine = '';
                const lines = [];

                for (const word of words) {
                    if ((currentLine + word).length <= maxLength) {
                        currentLine += word + ' ';
                    } else {
                        lines.push(currentLine.trim());
                        currentLine = word + ' ';
                    }
                }

                if (currentLine.trim() !== '') {
                    lines.push(currentLine.trim());
                }

                return lines.map((line, index) => {

                    if (index == 1) { valueDy = 40 }
                    else { valueDy = 20 }

                    return (
                        index == 0 ? <tspan key={index} x={cx} fill='#667085' fontWeight='500' fontFamily='Poppins' fontSize={'16px'}>{line}</tspan> : <tspan key={index} fill='#667085' fontWeight='500' fontFamily='Poppins' fontSize={'16px'} x={cx} dy={20}>{line}</tspan>
                    )
                });
            }

            return <tspan>{text}</tspan>;
        };

        return (
            <g>
                {!customTooltipTrue &&
                    <>
                        {<text
                            fill={'#667085'} fontSize={'16px'}
                            fontStyle='normal' fontWeight='400' textAnchor='middle'
                            x={cx} y={cy}
                            dy={-18}
                        >
                            {breakWords(payload.name, 17)}
                        </text>}

                        <text
                            color='#1D2939'
                            fontSize={"28px"} fontWeight='600' textAnchor='middle'
                            x={cx} y={cy}
                            dy={valueDy}
                        >
                            {`${(percent * 100).toFixed(2)}%`}
                        </text>
                    </>
                }

                {/* {!customTooltipTrue &&
                    <>
                       

                        {<text
                            fill={'#667085'} fontSize={'16px'}
                            fontStyle='normal' fontWeight='400' textAnchor='middle'
                            x={cx} y={cy}
                            dy={-18}
                        >
                            {payload.name}
                        </text>}

                        <text
                            color='#1D2939'
                            fontSize={"28px"} fontWeight='600' textAnchor='middle'
                            x={cx} y={cy}
                            dy={15}
                        >
                            {`${(percent * 100).toFixed(2)}%`}
                        </text>

                    </>} */}

                <Sector
                    cx={cx}
                    cy={cy}
                    endAngle={endAngle}
                    fill={fill}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    stroke={'26.5667px solid #3D2B7D'}
                />
            </g >
        );
    };

    const customRedenderActive = smScreen ? {} : customRenderActiveShape

    const CustomTooltip = ({ active, label, payload }: any) => {

        if (active && payload && payload.length) {

            const amt = payload[0]?.payload?.payload?.amount
            const percentage = payload[0]?.value

            const shouldntShowBrackets = amt == null

            return (
                <Box sx={{ borderRadius: "6px", border: "1px solid #EAECF0", background: "#FFF", boxShadow: "0px 0px 37px 0px rgba(16, 24, 40, 0.07)", p: "11px 12px" }}>
                    <Typography color="#51525C" fontSize={"12px"} fontWeight={"400"}>  {payload[0]?.name}  </Typography>
                    <Typography color="#667085" fontSize={"12px"} fontWeight={"400"}>
                        {amt && <strong> {CurrencyFormatterWithDecimal((amt || 0), true, "never", false)}</strong>} {shouldntShowBrackets ? `${percentage}%` : `(${percentage}%)`}
                    </Typography>
                </Box>
            );
        }
        return null;
    };

    React.useEffect(() => {
        hoverDotVal && hoverDotVal(activeIndex)
    }, [activeIndex])


    return (
        <ResponsiveContainer width="100%" height={customHeight ? customHeight : heightSet}>
            <Chart style={{ position: 'absolute' }}>
                {/* <Legend
                    layout='horizontal'
                    align='center'
                    // wrapperStyle={PorfolioStyles.legendHorizontalWrapperStyles}
                    // content={renderHorizontalCusomizedLegend}
                /> */}
                <Pie
                    activeIndex={activeIndex}
                    activeShape={showCustomActiveShape ? customRedenderActive : renderActiveShape}
                    // activeShape={customRedenderActive}
                    // className='chartOpacity'
                    data={data}
                    dataKey='value'
                    fill='#8884d8'
                    innerRadius={innerRadiusPie}
                    onMouseEnter={onPieEnter}
                    outerRadius={outerRadiusPie}
                    stroke={'26.5667px solid #3D2B7D'}
                    startAngle={90}
                    endAngle={-360}
                    paddingAngle={0.5}
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            className='chartOpacity'
                            fill={colors[index % colors?.length]}
                            style={PorfolioStyles.pieCellBorder}
                        />

                    ))}
                </Pie>
                {hoverToolTip && <Tooltip content={<CustomTooltip />} />}
            </Chart>
        </ResponsiveContainer>
    );
};

export default memo(PortfolioChart);

interface Selection {
    active: number;
    setActive: (active: number) => void;
}
type Shape = { name: string; value: number };
interface PortfolioChartProps {
    colors: any;
    data: Array<Shape>;
    selection?: Selection;
    heading?: string;
    route?: any;
    savings?: number;
    showCustomActiveShape?: boolean;
    customHeight?: any;
    customTooltipTrue?: boolean;
    hoverToolTip?: boolean;
    innerRadiusPie?: string;
    outerRadiusPie?: string;
    hoverDotVal?: any
}
