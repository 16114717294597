import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import TransactionHistoryTable from 'Latest/DashboardScreenV1/Tab/TransactionHistoryTableV2'
import TransactionHistoryTableMobile from 'Latest/DashboardScreenV1/Tab/TransactionHistoryTableMobileV2'
import React, { useEffect, useState } from 'react'
import { customCard } from '../style'
import NoData from 'Latest/Illustration/noData'
import { onGetTransactionDetail } from 'redux/reducers/sharedStateData'
import { useAppDispatch } from 'hooks/reduxHooks'
import { useAppLoading } from 'hooks/appLoading'
import { ManipulateTransactionList } from 'utils/transactionFilterForBonus'

const TransactionTableCard = ({ goalInfo }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useAppDispatch();

    const loading = useAppLoading('sharedStateData');
    const [transactionList, setTransactionList] = useState([])


    useEffect(() => {
        getTransactionHistory()
    }, [])

    const getTransactionHistory = () => {
        const onSuccess = (message: any, response: any) => {
            const newTransactions =  ManipulateTransactionList(message)
            setTransactionList(newTransactions)
        }
        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
            setTransactionList([])
        }
        const param = {
            id: goalInfo.idGoal,
            category: goalInfo.category
        }
        dispatch(onGetTransactionDetail(param, onSuccess, onFail))
    }
    const tableComp = () => {
        return (
            smScreen ?
                <Box sx={{ pb: "1rem" }}>
                    <TransactionHistoryTableMobile lists={transactionList} loading={loading} goalDetailView={true} row={5} />
                </Box>
                :
                <TransactionHistoryTable lists={transactionList} loading={loading} goalDetailView={true} minWidth={350} row={5} />
        )
    }

    return (
        <Box sx={customCard.mainBoxV2}>
            <Box sx={customCard.headBoxV2}>
                <Typography variant='h1' color={"secondary"} lineHeight={"36px"}>Transactions</Typography>
            </Box>
            <Box>
                {true ?
                    tableComp()
                    :
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                        <NoData />
                    </Box>
                }
            </Box>

        </Box>
    )
}

export default TransactionTableCard