export const thematicTabs = [
  {
    color: '#ffce00',
    icon: 'fa-pagelines',
    items: [
      { code: 'LUCK', name: 'Lucky cement' },
      { code: 'DGKC', name: 'DG Cement ' },
      { code: 'PIOC', name: 'Pinoneer Cement Limited ' },
      { code: 'MUGH', name: 'Mughal' },
      { code: 'ASTL', name: 'Amerli Steels Limited ' },
      { code: 'EPCL', name: 'Engro Polymer & Chemicals Limited ' }
    ],
    subTitle: 'Strive for sustainable outcomes along with financial returns',
    title: 'CONSTRUCTION KINGS'
  },
  {
    color: '#f78bdb',
    icon: 'fa-compass',
    items: [
      { code: 'OGDC', name: 'Oil and Gas Development Company Limited' },
      { code: 'PPL', name: 'Pakistan Petroleum Limited ' },
      { code: 'POL', name: 'Pakistan Oilfields Limited ' },
      { code: 'PSO', name: 'Pakistan State Oil ' },
      { code: 'SNGP', name: 'Sui Northern Gas Pipelines Limited ' },
      { code: 'APL', name: 'Attock Petroleum Limited ' }
    ],
    subTitle: 'Pursue long-term opportunities in the stock market',
    title: 'ENERGY TRACKER'
  },
  {
    color: '#9062bc',
    icon: 'fa-dot-circle-o',
    items: [
      { code: 'POL', name: 'Pakistan Oilfields Limited ' },
      { code: 'APL', name: 'Attock Petroleum Limited ' },
      { code: 'HUBC', name: 'The Hub Power Company Limited ' },
      { code: 'NPL', name: 'Nishat Power Limited ' },
      { code: 'NCPL', name: 'Nishat Chunian Power Ltd ' },
      { code: 'PPL', name: 'Pakistan Petroleum Limited ' }
    ],
    subTitle: 'Seek dividend and interest payments from stocks and bonds',
    title: 'FOCUS ON INCOME'
  },
  {
    color: '#F2bc',
    icon: 'fa-line-chart',
    items: [
      { code: 'MEBL', name: 'Meezan' },
      { code: 'UBL', name: 'United Bank Limited' },
      { code: 'HBL', name: 'Habib Bank Limited' },
      { code: 'MCB', name: 'MCB Bank Limited ' },
      { code: 'BAFL', name: 'Bank Alfalah ' },
      { code: 'EFU', name: 'EFU Life Assurance Ltd ' }
    ],
    subTitle: 'Seek dividend and interest payments from stocks and bonds',
    title: 'STABLE GROWTH'
  },
  {
    color: '#00a9e0',
    icon: 'fa-shield',
    items: [
      { code: 'NML', name: 'Nishat Mills Limited ' },
      { code: 'NCL', name: 'Nishat Chunian Limited ' },
      { code: 'ILP', name: 'Interloop' },
      { code: 'SYS', name: 'Systems ltd ' },
      { code: 'AVN', name: 'Avanceon Limited ' },
      { code: 'OCTOPUS', name: 'Octopus Digital Limited (' }
    ],
    subTitle:
      'Hedge against potential market pitfalls, seek to minimize volatility',
    title: 'EXPORT LED'
  },
  {
    color: '#1062bc',
    icon: 'fa-laptop',
    items: [
      { code: 'SYS', name: 'Systems ltd' },
      { code: 'AVN', name: 'Avanceon Limited  ' },
      { code: 'NETSOL', name: 'Netsol' },
      { code: 'AIRLINK', name: 'Airlink' },
      { code: 'TRG', name: 'TRG Pakistan Limited' },
      { code: 'OCTOPUS', name: 'Octopus Digital Limited (' }
    ],
    subTitle: 'Seek dividend and interest payments from stocks and bonds',
    title: 'TECHNOLOGY'
  },
  {
    color: '#72ca33',
    icon: 'fa-pie-chart',
    items: [
      { code: 'SEA', name: 'The Searle Company Limited' },
      { code: 'AGP', name: 'AGP Limited' },
      { code: 'ABOT', name: 'Abbott Laboratories Limited(' },
      { code: 'SHFA', name: 'Shifa Hospitals ' }
    ],
    subTitle:
      'Use a short-term investment strategy to pursue income from cash alternatives',
    title: 'HEALTHCARE TRACKER'
  }
];
export const thematicMockData = [
  {
    "id": "62393A3B38726652",
    "code": "CNS10",
    "name": "ConstructionKings",
    "displayName": "Construction Kings",
    "subTitle": "Strive for Sustaniable outcomes",
    "iconUrl": "fa-leaf",
    "basketColor": "purple",
    "productSummary": [
      "Text1",
      "Text2"
    ],
    "productPerformance": {
      "toDate": "05/09/2022",
      "fromDate": "",
      "graph": [
        {
          "year": "",
          "percentage": ""
        }
      ]
    },
    "priceYeild": [
      {
        "id": "Price 89 of 1029032",
        "value": "PKR 12"
      },
      {
        "id": "change",
        "value": "0 PKR + 0.17% +"
      }
    ],
    "stockWeights": [
      {
        "id": "Lucky Cements",
        "code": "LUCKY",
        "value": "10"
      },
      {
        "id": "DG Cement",
        "code": "DG",
        "value": "20"
      }
    ],
    "riskMeter": "50"
  }
];
export const CompanyInfoMock = [
  {
    "companyInformation": [
      { name: 'Industry', value: 'demo values' },
      { name: 'Sector', value: 'demo values' },
      { name: 'Employee', value: 'demo values' },
      { name: 'CEO', value: 'demo values' },
      { name: 'Phone', value: 'demo values' },
      { name: 'Phone', value: 'demo values' },
      { name: 'Description', value: 'demo values' },
    ],
  }
]