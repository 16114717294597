import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useStylesAlertError = makeStyles((theme) => ({
    customAlert: {
        // borderRadius: "4px",
        // border: "1px solid #D32F2F",
        border: "1px solid #D0D5DD40",
        backgroundColor: "#FEE9E8 !important",
        padding: "1rem !important",
        '& .MuiAlert-message': {
            padding: 0
        },
        '& .MuiAlert-action': {
            paddingTop: 0
        }
    },
    customAlertTitle: {
        // color: "#541313",
        // fontSize: "16px",
        // fontStyle: "normal",
        // fontWeight: 500,
        // lineHeight: "150%",
        // letterSpacing: "0.15px"
        color: "#541313 !important",
        fontSize: "16px !important",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150% !important",
        letterSpacing: "0.15px !important",
        display: "flex",
        alignItems: "center",
        gap: "6px",
        marginBottom: '8px !important'
    },
    customText: {
        // color: "#541313",
        // fontSize: "14px",
        // fontStyle: "normal",
        // fontWeight: 400,
        // lineHeight: "20px",
        color: "#541313 !important",
        fontSize: "14px !important",
        lineHeight: "21px !important",
        marginBottom: '8px !important'
    },
    customText2: {
        color: "#1D2939 !important",
        fontSize: "14px !important",
        lineHeight: "21px !important",
        display: "flex"
    },
    underlineText: {
        color: "#541313 !important",
        fontSize: "14px !important",
        lineHeight: "21px !important",
        textDecoration: "underline"
    },
    customIcon: {
        color: '#fff', // Change the icon color here
    },
}));

export default useStylesAlertError;