import React, { useEffect, useState } from 'react';
import {
  EmployerInvestmentDetail,
  FundDetail,
  EmployeeInvestmentDetail
} from 'redux/reducers/employer';
import InvestmentTableTopBar from './InvestmentTableTopBar';

const InvestmentsTable = ({
  // employee_investment_detail,
  employer_investment_detail,
  fund_detail,
  investmentEmployeesTotalRecords
}: InvestmentsTableProps) => {
  const [currentItems, setCurrentItems] =
    useState<Array<FundDetail>>(fund_detail);

  useEffect(() => {
    setCurrentItems(fund_detail);
  }, [investmentEmployeesTotalRecords, fund_detail]);
  return (
    <>
      <InvestmentTableTopBar
        // employee_investment_detail={employee_investment_detail}
        employer_investment_detail={employer_investment_detail}
      />
      <div>
        <Items currentItems={currentItems} />
      </div>
    </>
  );
};

export default InvestmentsTable;

interface InvestmentsTableProps {
  employer_investment_detail: EmployerInvestmentDetail;
  fund_detail: Array<FundDetail>;
  investmentEmployeesTotalRecords: number;
  // employee_investment_detail: Array<EmployeeInvestmentDetail>;
}
interface ItemsProps {
  currentItems: Array<FundDetail>;
}
const Items = ({ currentItems }: ItemsProps) => {
  return (
    <div className='table-responsive '>
      <table className='table table-bordered table-striped'>
        <thead>
          <tr className='customContributionTableHeader'>
            <th colSpan={0} rowSpan={2}>
              Employee Name
            </th>
            <th colSpan={0} rowSpan={2}>
              Age
            </th>
            <th colSpan={0} rowSpan={2}>
              Total Contributions
            </th>
            <th colSpan={1}>
              <p className='text-center  m-0'>Category</p>
            </th>
            <th colSpan={3}>
              <p className='text-center m-0'>Allocations</p>
            </th>
            <th colSpan={0} rowSpan={2}>
              Current Value
            </th>
            <th colSpan={0} rowSpan={2}>
              Return % (absolute)
            </th>
            <th colSpan={0} rowSpan={2}>
              Return % (annualized)
            </th>
          </tr>
          <tr className='customContributionTableHeader'>
            <th rowSpan={0}>Conventional/Islamic</th>
            <th rowSpan={0}>Money Market</th>
            <th rowSpan={0}>Dept</th>
            <th rowSpan={0}>Equities</th>
          </tr>
        </thead>
        <tbody className='table'>
          {currentItems.map((item, index) => (
            <>
              <tr>
                <td className='fs-20'>
                  <p className='mb-0 fs-15'>
                    {item.name || 'n/a'}
                  </p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-15'>{item.age || 'n/a'}</p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-15'>
                    {item.totalContributions || 'n/a'}
                  </p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-15'>Islamic</p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-15'>{item.allocation.moneyMarket || 'n/a'}</p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-15'>{item.allocation.debt || 'n/a'}</p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-15'>{item.allocation.equity || 'n/a'}</p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-15'>
                    {item.currentBalanceValue || 'n/a'}
                  </p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-15'>
                    {item.absoluteReturn || 'n/a'}
                  </p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-15'>
                    {item.annualizedReturn || 'n/a'}
                  </p>
                </td>
              </tr>
            </>
          ))
          }
        </tbody>
      </table>
    </div>
  );
};
