import * as Yup from 'yup';

export const permanentAddressFormSchema = Yup.object({
    permanentAddress1: Yup.string().required('Address is required'),
    cityPermanent: Yup.object().required("City is required'").nullable(),
    provincePermanent: Yup.object().required("Province is required'").nullable(),
});


export const mailingAddressFormSchema = Yup.object({
    // mailingAddress1: Yup.string().required('Address is required'),
    cityMailing: Yup.object().required("City is required'").nullable(),
    provinceMailing: Yup.object().required("Province is required'").nullable(),
})


export const permanentAddressInitialValue = {
    permanentAddress1: '',
    permanentAddress2: '',
    cityPermanent: { value: "", label: "" },
    provincePermanent: { value: "", label: "" },
}

export const mailingAddressInitialValue = {
    mailingAddress1: '',
    mailingAddress2: '',
    cityMailing: { value: "", label: "" },
    provinceMailing: { value: "", label: "" },
}

export const addressInitialValue = {
    sameAsParmanent: false,
    permanentAddress1: '',
    permanentAddress2: '',
    cityPermanent: { value: "", label: "" },
    provincePermanent: { value: "", label: "" },
    mailingAddress1: '',
    mailingAddress2: '',
    cityMailing: { value: "", label: "" },
    provinceMailing: { value: "", label: "" },
}

export const addressFormSchema = Yup.object({
    permanentAddress1: Yup.string().required('Address is required'),
    
    cityPermanent: Yup.object().shape({
        value: Yup.string().required("City value is required"),
        label: Yup.string().required("City label is required"),
    }).nullable().required("City is required"),

    provincePermanent: Yup.object().shape({
        value: Yup.string().required("Province value is required"),
        label: Yup.string().required("Province label is required"),
    }).nullable().required("Province is required"),

    mailingAddress1: Yup.string().required('Address is required'),

    cityMailing: Yup.object().required("City is required'").nullable(),

    provinceMailing: Yup.object().required("Province is required'").nullable(),
});
