import { useFormik } from 'formik';
import React, { useState } from 'react'
import { onGetAccountSetup, onPostAddressV1 } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { addressFormSchema, addressInitialValue, mailingAddressFormSchema, mailingAddressInitialValue, permanentAddressFormSchema, permanentAddressInitialValue } from 'jsx/components/WizardFormModal/AccountSetup/validation';
import PermanentField from 'jsx/components/WizardFormModal/AccountSetup/permanentField';
import { Box, Button, Grid, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ModalStyle } from '../Style/modalStyle';
import Loader from 'jsx/components/Shared/Loader';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: { sm: 500, md: 600, lg: 700 },
    width: { xs: "95%", sm: '90%', md: "auto", lg: 700 },
    maxHeight: { xs: "80vh", sm: "90vh", md: "90vh", lg: "98%" },
    overflow: "scroll",
    bgcolor: '#FFFFFF',
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    p: 4,
    borderRadius: "12px"
};

const Index = ({ handleClose, handleOpen, open }: any) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { userAccountDetails } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    const formikAddress = useFormik({
        initialValues: addressInitialValue,
        onSubmit: values => {
            console.log(values, 'values');

            const permanentAddress = [values.permanentAddress1, values.permanentAddress2].filter(
                (x: any) => x != ''
            );
            const mailingAddress = [values.mailingAddress1, values.mailingAddress2].filter(
                (x: any) => x != ''
            );

            const body = {
                "permanentAddress": {
                    "lines": permanentAddress,
                    "city": values?.cityPermanent?.value,
                    "province": values?.provincePermanent?.value
                },
                "mailingAddress": {
                    "lines": mailingAddress,
                    "city": values?.cityMailing?.value,
                    "province": values?.provinceMailing?.value
                }
            }

            const onSuccess = (message: any, response: any) => {
                dispatch(onGetAccountSetup())
                formikAddress.resetForm()
                handleClose()
                toast.success(`Address updated successfully`, { autoClose: 8000 });
            }
            const onFail = (message: string, response: any) => {
                console.log(message, response.status, "OPOOP")
            }
            dispatch(onPostAddressV1(body, onSuccess, onFail));
        },
        validationSchema: addressFormSchema
    });

    const disabledBtn =
        formikAddress.values.permanentAddress1 == '' ||
        formikAddress.values.cityPermanent.value == '' ||
        formikAddress.values.provincePermanent.value == '' ||
        formikAddress.values.mailingAddress1 == '' ||
        formikAddress.values.cityMailing == null ||
        formikAddress.values.provinceMailing == null


    return (
        <>
            {isLoading && <Loader />}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ position: "absolute", right: 15, top: 18 }}>
                        <IconButton aria-label="upload picture" component="label">
                            <CloseIcon onClick={handleClose} />
                        </IconButton>
                    </Box>
                    <PermanentField
                        formik={formikAddress}
                        type="myprofile"
                    />

                    <Grid container spacing={2} sx={{ px: "18px" }} >
                        <Grid item xs={6} sx={{ mt: 2 }}>
                            <Button fullWidth sx={ModalStyle.cancelBtn} onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item xs={6} sx={{ mt: 2 }}>
                            <Button fullWidth disabled={disabledBtn} sx={{
                                ...ModalStyle.confirmBtn, background: "#403081", border: "1px solid #403081",
                                "&:hover": {
                                    background: "#403081f0"
                                },
                                "&:disabled": {
                                    background: "gray!important",
                                    color: "#FFFFFF",
                                    border: "1px solid gray"
                                }
                            }} onClick={() => formikAddress.submitForm()} >Submit</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

        </>
    )
}

export default Index