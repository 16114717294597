import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Chip, Grid, Stack, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { CurrencyFormatterWithDecimal } from 'utils/calculation'
import { links } from 'static/links'
import CloseIcon from '@mui/icons-material/Close';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom'
import checkImage from 'Latest/Images/svg/failReceipts.svg';


const FaildScreen = ({ initAmount, refId, transacId }: any) => {
  const { location, push } = useHistory();
  return (
    <>


      <Typography variant='h1' textAlign={"center"} sx={{ mb: "24px" }} color="#1D2939">Deposit unsuccessful</Typography>

      <Box sx={{
        width: { md: "620px", sm: '520px', xs: "auto" }, borderRadius: "8px",
        border: { xs: "", sm: "1px solid #821B13" },
        background: { xs: "transparent", sm: "#FFEEEC" },
        p: '32px 16px', textAlign: "center", margin: "auto",
      }}>

        <img src={checkImage} />
        <Typography variant='h5' fontWeight={"500"} color={"#821B13"} sx={{ m: "8px" }}>Your deposit of <Typography component={"span"} variant='h5' fontWeight={"600"}>
          {CurrencyFormatterWithDecimal(initAmount, true, "never", false)}</Typography> has been initiated.</Typography>

        <Typography variant='body2' color={"rgba(29, 41, 57, 0.70)"}> Please try a different payment method or <span style={{
          color: "#821B13", textDecorationLine: "underline", fontWeight: 500
        }}>reach out</span> to us in case of any issues. </Typography>

      </Box>

      {/* <Box sx={{ mt: "40px" }}>
        <LoadingBtnCustom handleOnClick={() => push(links.home)} btnTitle={"Go to home"} />

      </Box> */}



      {/* <Grid item md={12} xs={12} sx={{ mb: 3, mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            border: '4px solid #7A271A',
          }}
        >
          <CloseIcon sx={{ width: 60, height: 60, color: "#7A271A" }} />
        </Box>


      </Grid>

      <Grid item md={12} xs={12}>
        <Typography variant='h5' fontWeight={"400"} color="#7A271A"><strong style={{ fontWeight: 600 }}>Error:</strong> Transaction failed.</Typography>
      </Grid>

      <Grid item md={12} xs={12} mt={1}>
        <Typography variant='body1'>Please try again later</Typography>
      </Grid>

      {refId && <Grid item md={12} xs={12} mt={1} >
        <Typography variant='body1'>Payment reference:<Typography component={"span"} fontWeight={"600"}> {refId}</Typography></Typography>
      </Grid>}

      {transacId && <Grid item md={12} xs={12} mt={1} >
        <Chip sx={{background:"#EFF8FF" , color:"#2E90FA"}}
         label={<Typography  variant='body1'>Transaction Id: {transacId}</Typography>} />
      </Grid>}

      <Stack spacing={2} margin={'auto'} mt={2} mb={3}>
        <LoadingButton variant="contained" color='secondary' onClick={() => push(links.home)}>Go to Dashboard</LoadingButton>
      </Stack> */}
    </>
  )
}

export default FaildScreen