import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormikInputField from '../Shared/FormikInputField';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { CloseIconStyle, ModalStyle } from './style';
import FormikInputBox from '../Shared/FormikInputBox';

export default function BasicModal({ formik, handleClose, open, selectedItem, selectedOption }: any) {

    const { currentPrice, name, quantity, totalValue } = selectedItem;

    React.useEffect(() => {
        formik.setFieldValue("selectedItem", selectedItem);
    }, [quantity])

    const estimatedAmount = (currentPrice * formik.values.qty).toFixed(2)

    React.useEffect(() => {
        formik.setFieldValue("totalAmount", estimatedAmount);
    }, [estimatedAmount])

    const handleCloseModal = () => {
        handleClose();
        formik.handleReset()
    }

    return (
        <div>
            <Modal
                aria-describedby="modal-modal-description"
                aria-labelledby="modal-modal-title"
                onClose={handleCloseModal}
                open={open}
            >
                <Box sx={ModalStyle}>

                    <IconButton aria-label="close" component="label" onClick={handleCloseModal} sx={CloseIconStyle}>
                        <CloseIcon />
                    </IconButton>

                    <Typography align='center' component="h1" sx={{ color: "black" }} variant="h6" >
                        {selectedOption} investments
                    </Typography>
                    <Typography align='center' sx={{ color: "black !important" }}>
                        {name}
                    </Typography>
                    <Typography align='center' sx={{ mt: 1 }}>
                        Total investement fee: 0.00
                    </Typography>

                    <Box sx={{ mt: 4 }}>
                        <FormikInputBox
                            required
                            col={12}
                            formik={formik}
                            label={"How much do you want to sell?"}
                            maxLength={50}
                            name={"qty"}
                            placeholder='Fill in quantity'
                            thousandSeparator={true}
                            type={"number"}
                        />
                    </Box>

                    <Typography sx={{ color: "black" }} >
                        Your holding in the basket: PKR {totalValue}
                    </Typography>

                    <Typography align='center' sx={{ mt: 4, color: "black !important", fontWeight: "bold" }} >
                        Estimated amount
                    </Typography>

                    <Typography align='center' sx={{ mb: 2, color: "black" }} variant='h5'>
                        PKR: {Number(estimatedAmount)}
                    </Typography>

                    <Typography sx={{ my: 4, mx: 5 }} variant='body2'>
                        Estimated amount is calculated at the latest rate and may therefore differ slightly from the final amount when the fund is actually sold.
                    </Typography>

                    <Grid container spacing={2} sx={{ justifyContent: "center", mt: 4, mb: 2 }}>
                        <Grid item md={6} xs={12} >
                            <Button fullWidth color={selectedOption == "Buy" ? "primary" : "error"} onClick={() => formik.submitForm()} variant="contained" >{selectedOption}</Button>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </div>
    );
}