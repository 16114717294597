import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material'
import { tabCustomStyle } from 'Latest/DashboardScreenV1/style/tabsStyle';
import React, { useEffect, useRef } from 'react'
import { tokens } from 'theme'
import { TabPanel } from 'Latest/SharedFunction'
import a11yProps from 'Latest/SharedFunction'
import {
    animateScroll as scroll,
} from "react-scroll"

const Index = ({ contentTabs, customTabsStyle, tabPanelStyle }: any) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent | null, newValue: number) => {
        setValue(newValue);
    };

    const urlParams = new URLSearchParams(window.location.search); //paymob
    const urlValue = urlParams.get('value');  //paymob
    const scrollValue = urlParams.get('scroll');  //paymob

    useEffect(() => {
        handleChange(null, Number(urlValue))
    }, [])

    useEffect(() => {
        scrollValue == "true" && scroll.scrollToBottom({
            duration: 3000,  // Duration of the scroll animation in milliseconds
            delay: 200,      // Delay before the scroll animation starts
            smooth: 'easeInOutQuart'  // Easing function for the scroll animation
        });

    }, []);

    return (
        <>
            <Box sx={{ width: '100%', borderBottom: "1px solid #EAECF0" }}>
                <Box sx={{ borderBottom: 1, borderColor: '#EAECF0', paddingBottom: {md: 0, xs: "16px"} }}>
                    <Tabs allowScrollButtonsMobile value={value}
                        // scrollButtons={scrollMobileScreen}
                        onChange={handleChange} variant="scrollable" aria-label="basic tabs example"
                        sx={{
                            ...tabCustomStyle.tabsStyle,
                            ".css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root": {
                                width: 5,
                                background: "white"
                            },
                            ".MuiSvgIcon-root": {
                                color: "black",
                                fontSize: 25
                            },
                            '& .MuiTab-textColorSecondary': {
                                color: colors.grey[500], // color for inactive tab
                            },
                            '& .MuiTab-textColorSecondary.Mui-selected': {
                                color: colors.primary[750], // color for active tab
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: colors.primary[750],
                            },
                            ...customTabsStyle
                        }}
                        textColor="secondary" indicatorColor={"secondary"} >

                        {contentTabs?.map((item: any, index: number) => {
                            return (
                                <Tab key={index} label={<Typography variant='h6' fontSize={{ xs: "14px", md: "1rem" }}>{item.label}</Typography>} {...a11yProps(index)} sx={{ ...tabCustomStyle.tabWrapper }} />
                            )
                        })}
                    </Tabs>
                </Box>
            </Box>

            {contentTabs?.map((item: any, index: number) => {
                return (
                    <TabPanel key={index} value={value} index={index} style={tabPanelStyle} >
                        <Box mt={3} pb={{ md: 6, xl: 3 }} sx={{ background: "#fff" }}>
                            {item.comp}
                        </Box>
                    </TabPanel>
                )
            })}
        </>
    )
}

export default Index