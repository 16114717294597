import React, { useEffect, useState } from 'react'
import GenerateIdRaast from './generateIdRaast'
import GenerateIdViewRaast from './generateIdViewRaast'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { onGenerateRaastId } from 'redux/actions'
import { useAppLoading } from 'hooks/appLoading'
import Loader from 'jsx/components/Shared/Loader'

const Index = ({ categoryAcc, formik, goalId, goalPostId, originalAmt, previousStep }: any) => {

    const dispatch = useAppDispatch();
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const loading = useAppLoading('onGenerateRaastId');

    const handleGenerateRaastId = () => {
        const data = {
            "cashFundInvestmentId": goalPostId,
            "goalId": null,
            "grossAmount": originalAmt,
            "userId": userAccountDetails?.userId,
            "cnic": userAccountDetails?.identityCard?.identityCardNumber,
            "accountHolderName": userAccountDetails?.userName
        }
        dispatch(onGenerateRaastId(data));
    }

    return (
        <>
            {loading ? <Loader title="Please wait. This can take up to 10 seconds."/>
                :
                (userAccountDetails?.raastPseudoIban ?
                    <GenerateIdViewRaast originalAmt={originalAmt} previousStep={previousStep} raastId={userAccountDetails?.raastPseudoIban} />
                    :
                    <GenerateIdRaast handleGenerateRaastId={handleGenerateRaastId} />)
            }
        </>
    )
}

export default Index