import React from 'react'
import GeneralInvestmentsTable from './generalInvestmentsTable'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { Grid } from '@mui/material';

const Index = () => {

    const { allDashboardData } = useAppSelector(state => state.dashboard);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12} >
                    <GeneralInvestmentsTable basketSummaries={allDashboardData.basketSummaries} />
                </Grid>

            </Grid>

        </>
    )
}

export default Index