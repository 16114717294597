import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, useTheme } from '@mui/material';
import { tokens } from 'theme';

const ArrowUp = ({ styles, type = "up" || "down", versionLight = true }: any) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <>
            {type == "up" ?
                <Box sx={{
                    display: "flex",
                    flexSirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2px 10px",
                    width: "36px",
                    height: "36px",
                    background: versionLight ? colors.red[500] : colors.red[1],
                    borderRadius: "66px",
                    ...styles
                }}>
                    <ArrowUpwardIcon sx={{ color: versionLight ? colors.red[150] : colors.background[100] }} />
                </Box>
                :
                type == "down" ?
                    <Box sx={{
                        display: "flex",
                        flexSirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "2px 10px",
                        width: "36px",
                        height: "36px",
                        background: versionLight ? colors.greenAccent[500] : colors.greenAccent[1],
                        borderRadius: "66px",
                        transform: "matrix(1, 0, 0, -1, 0, 0)",
                        ...styles
                    }}>
                        <ArrowUpwardIcon sx={{ color: versionLight ? colors.success[150] : colors.background[100] }} />
                    </Box>
                    :
                    <Box sx={{
                        display: "flex",
                        flexSirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "2px 10px",
                        width: "36px",
                        height: "36px",
                        background: colors.blue[200],
                        borderRadius: "66px",
                        transform: "matrix(1, 0, 0, -1, 0, 0)",
                        ...styles
                    }}>
                        {/* <ArrowUpwardIcon sx={{ color: versionLight ? colors.success[150] : colors.background[100] }} /> */}
                    </Box >

            }

        </>
    )
}

export default ArrowUp