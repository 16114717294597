import React, { useEffect, useState } from 'react'
import UpdateCnic from './updateCnic'
import { useFormik } from 'formik';
import Modal from '@mui/material/Modal';
import { Box, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { CnicValidation } from 'validate/cnicValidation';
import { onGetAccountSetup, onGetUserAccountDetailsSuccess, onSubmitDocuments } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import Loader from 'jsx/components/Shared/Loader';
import { useAppLoading } from 'hooks/appLoading';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import ErrModal from 'Latest/MyProfile/Modal/errModal';
import WrongCnicModal from 'Latest/DashboardScreen/GoalDashboard/LeftSection/ModalComponent/wrongCnic';
import { onPatchNotifications } from 'redux/reducers/sharedStateData';
import { ToastContainer, toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: { sm: 500, md: 600, lg: 700 },
    width: { xs: "95%", sm: '90%', md: "90%", lg: "90%", xl: "auto" },
    maxHeight: { xs: "80vh", sm: "90vh" },
    overflow: "scroll",
    bgcolor: '#FFFFFF',
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    p: 4,
    borderRadius: "12px"
};

const Index = ({ handleClose, handleOpen, open, setUpdateCnic, updateCnic }: any) => {
    const [openErrModal, setOpenErrModal] = useState(false);
    const [modalContent, setModalContent] = useState<any>('');
    const [openWrongCnicModal, setOpenWrongCnicModal] = useState(false);


    const authLoading = useAppLoading('auth');
    const dispatch = useAppDispatch();
    const defaultValues = {
        UserNICFront: '',
        UserNICBack: '',
        consentCheck: false
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: defaultValues,
        onSubmit: values => {
            dispatchUplaodCNICAPI()
        },
        validationSchema: CnicValidation
    });

    const dispatchUplaodCNICAPI = () => {
        const formData = new FormData();
        formData.append('UserNicFront', formik.values.UserNICFront);
        formData.append('UserNicBack', formik.values.UserNICBack);
        formData.append("UpdateUser", "false")

        const onFail = (message: string, response: any) => {
            if (response != undefined) {
                if (response?.status == 409) {
                    setModalContent(ContentFailedModal("It seems that you’ve already registered with this CNIC. Investors can only register for one individual account"))
                    setOpenErrModal(true)
                }
                else if (response?.status == 400) {
                    handleClose()
                    setUpdateCnic(!updateCnic)
                    return;
                } else {
                    setOpenWrongCnicModal(true)
                }
            }


        };
        const onSuccess = (message: string, response: any) => {
            toast.success(`CNIC Upload successfully`);
            // handleClose()
            dispatch(onGetAccountSetup());
            setUpdateCnic(!updateCnic);

            const formData: any = new FormData();
            formData.append('EnableNotifications', false);
            formData.append('NotificationType', "IdentityCardReupload");
            dispatch(onPatchNotifications(formData))

            return;
        };

        dispatch(onSubmitDocuments(formData, onSuccess, onFail));
    }

    const ContentFailedModal = (customMess: any) => {
        return (
            <Grid item xs={12} sx={{ pt: "8px" }}>
                <Typography sx={ModalStyle.headStyle}>Your account already exists</Typography>
                <Typography sx={ModalStyle.subheadStyle}>
                    {customMess}
                </Typography>
            </Grid>
        )
    }


    return (
        <>
            <UpdateCnic formik={formik} inputFiles={[]} handleClose={handleClose} loading={authLoading} />
            <ErrModal
                handleOpen={() => setOpenErrModal(true)} open={openErrModal} handleClose={() => setOpenErrModal(false)}
                Content={modalContent} btnTitle={"OK"}
            />
            <WrongCnicModal
                handleClose={() => setOpenWrongCnicModal(false)} handleOpen={openWrongCnicModal}
                type={"myprofile"} btnTitle={"Please try again"}
            />
        </>
    )
}

export default Index