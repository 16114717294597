import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ConfirmationImage from '../../../../src/images/confirmation.png'
import { CheckmarkBoxStyle, CloseIconStyle, ConfirmationMessageStyle, ModalStyle, OkBtnGridStyle, totalAmountStyle } from './style';
import Checkmark from 'react-typescript-checkmark';


export default function BasicModal({ formik, handleClose, open, selectedItem, selectedOption }: any) {

    const { currentPrice, name, quantity, totalValue } = selectedItem;

    const handleCloseModal = () => {
        handleClose();
        formik.handleReset()
    }

    return (
        <div>
            <Modal
                aria-describedby="modal-modal-description"
                aria-labelledby="modal-modal-title"
                onClose={handleCloseModal}
                open={open}
            >
                <Box sx={ModalStyle}>

                    <IconButton aria-label="close" component="label" onClick={handleCloseModal} sx={CloseIconStyle}>
                        <CloseIcon />
                    </IconButton>

                    <Typography align='center' component="h1" sx={{ color: "black" }} variant="h6" >
                        {selectedOption} investment
                    </Typography>

                    <Typography align='center' sx={{ color: "black !important" }}>
                        {name}
                    </Typography>

                    <Typography align='center' sx={{ mt: 1 }}>
                        Total investement fee: 0.00
                    </Typography>

                    <Box sx={CheckmarkBoxStyle}>
                        <Checkmark size='xxl' />
                    </Box>

                    <Typography align='center' sx={{ color: "black", fontWeight: "bold" }} >
                        Thank you!
                    </Typography>

                    <Typography align='center' sx={ConfirmationMessageStyle} >
                        We are sending you a confirmation email.
                    </Typography>

                    <Typography align='center' sx={totalAmountStyle} variant='h5'>
                        PKR: {formik.values.totalAmount}
                    </Typography>

                    <Typography sx={{ my: 2, mx: 5 }} variant='body2'>
                        Estimated amount is calculated at the latest rate and may therefore differ slightly from the final amount when the fund is actually sold.
                    </Typography>

                    <Grid container spacing={2} sx={OkBtnGridStyle}>
                        <Grid item md={6} xs={12} >
                            <Button fullWidth color="success" variant="contained" >Ok</Button>
                        </Grid>
                    </Grid>


                </Box>
            </Modal>
        </div>
    );
}