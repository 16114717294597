export function commaFormatted(amount: string | number): string {
    if (amount) {
        return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    return "";
}

export function removeCommaFormatting(amount: string | number): number | undefined {
    if (!amount) {
        return undefined;
    }

    if (amount == "0.00" || amount == "0") {
        return 0;
    }

    let amountWithoutCommas = amount.toString().replace(/\,/g, "");

    if (amountWithoutCommas.endsWith('.')) {
        amountWithoutCommas = amountWithoutCommas.replace('.', '');
    }

    amountWithoutCommas = amountWithoutCommas.toString().replace('.00', "");

    if (amountWithoutCommas.includes(".")) {
        return parseFloat(amountWithoutCommas)
    }

    return parseInt(amountWithoutCommas);
}