import { memo } from 'react';

const TableHead = () => (
  <thead>
    <tr role='row' style={{textAlign:'center'}}>
      <th
        aria-sort='ascending'
        className='sorting_asc'
        colSpan={1}
        rowSpan={1}
        style={{ width: 63 }}
        tabIndex={0}
      >
        SYMBOL
      </th>
      <th
        className='sorting'
        colSpan={1}
        rowSpan={1}
        style={{ width: '118.667px' }}
        tabIndex={0}
      >
        FUND NAME
      </th>
      <th
        className='sorting'
        colSpan={1}
        rowSpan={1}
        style={{ width: '76.6667px' }}
        tabIndex={0}
      >
        NAV
      </th>
      <th
        className='sorting'
        colSpan={1}
        rowSpan={1}
        style={{ width: 104 }}
        tabIndex={0}
      >
        NAV%
      </th>
      <th
        className='sorting'
        colSpan={1}
        rowSpan={1}
        style={{ width: '113.333px' }}
        tabIndex={0}
      >
        1 Year
      </th>
      <th
        className='sorting'
        colSpan={1}
        rowSpan={1}
        style={{ width: '124.667px' }}
        tabIndex={0}
      >
        3 Years
      </th>
      <th
        className='sorting'
        colSpan={1}
        rowSpan={1}
        style={{ width: '124.667px' }}
        tabIndex={0}
      >
        5 Years
      </th>
      <th className='sorting' colSpan={1} rowSpan={1} tabIndex={0}>
        Since Inception
      </th>
      <th className='sorting' colSpan={1} rowSpan={1} tabIndex={0}>
        Expense Ratio
      </th>
      <th className='sorting' colSpan={1} rowSpan={1} tabIndex={0}>
        Asset Class
      </th>

      <th
        className='bg-none width50 sorting'
        colSpan={1}
        rowSpan={1}
        style={{ width: '58.6667px' }}
        tabIndex={0}
      />
    </tr>
  </thead>
);

export default memo(TableHead);
