import { Box, Container, Grid, IconButton, Modal, useTheme , Typography } from "@mui/material"
import { tokens } from 'theme'
import CloseIcon from '@mui/icons-material/Close';
import Help from "../Help/help"
import { useState } from "react";

export const HelpModal = ({ handleCLose, open }: any) => {


    const theme = useTheme();
  const colors = tokens(theme.palette.mode);

    return (
        <div>

            <Modal
                open={open}
                onClose={handleCLose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box width={{ md: "50%", sm:"50%" ,  xs: "80%" }} sx={{ p: { xs: "24px 16px", md: "30px" }, background: "#fff", position: "äbsolute", left: {md:"50%" , sm:"50%" , xs:"12%"}, top: "20%", transform: {md:"translate(50%,20%)" , sm:"translate(50%,20%)" , xs:"translate(12%,20%)"} }}>
                <Box sx={{ position: "absolute", right: 5, top: 5 }}>
                                    <IconButton aria-label="upload picture" component="label">
                                        <CloseIcon onClick={handleCLose} />
                                    </IconButton>
                                </Box>
                    <Container maxWidth={"xl"}>
                        <Grid container>
                            <Container disableGutters maxWidth={"xl"}>
                                <Typography variant='h1' pt={3} pb={3} color={colors.grey[800]}>?</Typography>
                            </Container>
                            <Help ismodal={true}/>
                        </Grid>
                    </Container>
                </Box>
            </Modal>
        </div>
    )
}