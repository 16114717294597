import WebSidebar from './webSidebar'
import React from 'react'

const Index = () => {

    return (
        <>
            <WebSidebar />
        </>
    )
}

export default Index