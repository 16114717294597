export const investStyle = {
    heading: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "25px",
        lineHeight: "28px",
    },
    subHeading: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
    },
    buyBtn: {
        color: "white", background: "#43BED8", boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        border: "1px solid #43BED8", padding: "10px 18px", borderRadius: "4px", width: "100%",
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        textTransform: "capitalize",
        '&:hover': {
            backgroundColor: '#43bed8e6'
        },
    },
    cancelBtn: {
        color: "#344054", background: "#FFFFFF", boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        border: "1px solid #D0D5DD", padding: "10px 18px", borderRadius: "4px", width: "100%", fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        textTransform: "capitalize"
    },
    inputLabel: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
    },
    NumericInput: { borderRadius: "8px" },
    optionAmount: {
        borderRadius: "30px", cursor: "pointer",
        '&:hover': {
            backgroundColor: '#DCF9FF'
        },
    },
    optionAmountTypo: {  lineHeight: "20px", color: "#475467", padding: "7px 16px",  }
}

export const successReceiptModalStyle={
    mainGrid:{ py: "40px", display: 'flex', justifyContent: 'center', flexDirection: "column", alignItems: "center", gap: "12px" },
    head1:{
        fontSize:{xs:"18px!important",md:"22px!important"} ,  fontWeight:400 , color:"#202020" , textAlign: "center"
    },
    head2:{
        fontSize:"18px !important" , lineHeight:"27px !important !important", fontWeight:400 , color:"#AFA7A7",
        textAlign: "center"

    }
}