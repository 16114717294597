import axios from 'axios';
import { memo, useEffect, useState } from 'react';
import Loader from '../components/Shared/Loader';
import TabContent from './../components/Thematic/TabContent';
import './../components/Thematic/thematic.css';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { LocalUrl1 } from 'redux/middlewares/api';
import { useAppLoading } from 'hooks/appLoading';

const Basket = () => {
    const authLoading = useAppLoading('auth');
    const portfolioLoading = useAppLoading('portfolio');
    const questionAnswerLoading = useAppLoading('questionAnswer');
    // const loading = useAppLoading('Thematic');
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>([]);
    const [selected, setSelected] = useState<any>([]);
    const [basketId, setBasketId] = useState<any>('');
    useEffect(() => {
        fetchData();
    }, [])
    const fetchData = async () => {
        const resp = await axios.get(LocalUrl1 + 'Basket')
        resp && setLoading(false)
        setSelected(resp.data.equityBaskets[0]);
        setData(resp.data.equityBaskets);
        setBasketId(resp.data?.id)
    }

    if (loading)
        return (
            <div className='text-center p-5'>
                <Loader width={200} />
            </div>
        );

    return (
        <div className='thematic'>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                }}
            >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        {data.map((val: any, index: any) => {
                            return (
                                <Grid key={val.id} item className='text-center ptr' onClick={() => setSelected(val)}>
                                    <span className={`fa ${val.iconUrl} thematic-icons  ${val?.name === selected?.name ? 'activeColor' : 'notActive'}`} />
                                    <p className={val?.name === selected?.name ? 'borderColordiv' : 'greyScale'}>{val.displayName} ---</p>
                                </Grid>
                            )
                        })}
                        <TabContent
                            basketId={basketId}
                            basketName={selected.name}
                            bg={selected?.basketColor}
                            items={selected}
                            title={selected?.name}
                        />
                    </Grid>
                </Container>
            </Box>
        </div>
    );
};

export default memo(Basket);