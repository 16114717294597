const Investments: any =
{
    "employerContributionToDate": "545454",
    "matchContributionToDate": "622662",
    "totalContributionToDate": "959595",
    "accumulatedValue": "550050",
    "absoluteReturn": "66",
    "annualizedReturn": "77",
    "holdings": [
        {
            "name": "ABC",
            "value": "11",
            "allocation": "35"
        },
        {
            "name": "XYZ",
            "value": "22",
            "allocation": "55"
        }
    ],
    "employees": [
        {
            "name": "John Doe",
            "age": 35,
            "totalContributions": 5.8,
            "category": "",
            "allocation": {
                "equity": "11",
                "moneyMarket": "22",
                "debt": "33"
            },
            "currentBalanceValue": "44",
            "absoluteReturn": "55",
            "annualizedReturn": "66"
        },
        {
            "name": "Alex Snow",
            "age": 26,
            "totalContributions": 5.8,
            "category": "",
            "allocation": {
                "equity": "88",
                "moneyMarket": "77",
                "debt": "55"
            },
            "currentBalanceValue": "22",
            "absoluteReturn": "99",
            "annualizedReturn": "11"
        }
    ]
}


export default Investments;