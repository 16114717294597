export const tabCustomStyle = {
    tabsStyle: {
        px: { xs: "0px", md: "32px" }, backgroundColor: "#fff",
        '& button': {
            padding: "14px 20px"
        }
    },
    tabWrapper: {
        // fontFamily: 'Inter,sans-serif',
        // fontStyle: "normal",
        // fontWeight: 600,
        // fontSize: "16px",
        // lineHeight: "24px",
        textTransform: 'capitalize',
    }
}

export const summaryTab = {
    mainBox: {
        background: "#F9FAFB", mt: { xs: "-16px", sm: "-30px" }, mx: { xs: "-16px", sm: "-30px" }, py: "20px", px: { xs: "16px", sm: "30px" }, mb: "30px",
        borderBottom: "1px solid #EAECF0",
        display: "flex", alignItems: { xs: "start", sm: "center" }, flexDirection: { xs: "column", sm: "row" }
    },
}