import { Col, Dropdown } from 'react-bootstrap';
import { memo, useCallback, useEffect, useState } from 'react';

import AppSelect from '../Shared/AppSelect';
import { useAppDispatch } from 'hooks/reduxHooks';
import { onGetFunds } from 'redux/actions';

const Topbar = ({ activeName, filters, isLoading, setActiveName }) => {
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState({
    Category: 0,
    Company: 0,
    FundType: 0,
    Risk: 0
  });

  const loadData = useCallback(() => {
    dispatch(onGetFunds(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className='d-flex align-items-center flex-wrap '>
      <AppSelect
        disabled={isLoading}
        fieldName='fundType'
        fields={filters.types}
        onChange={val => setFilter({ ...filter, FundType: val })}
        placeholder='Fund Type'
      />
      <AppSelect
        disabled={isLoading}
        fieldName='categoryType'
        fields={filters.categories}
        onChange={val => setFilter({ ...filter, Category: val })}
        placeholder='Category'
      />
      <AppSelect
        disabled={isLoading}
        fieldName='companyType'
        fields={filters.companies}
        onChange={val => setFilter({ ...filter, Company: val })}
        placeholder='Company'
      />
      <AppSelect
        disabled={isLoading}
        fieldName='risk'
        fields={filters.risks}
        onChange={val => setFilter({ ...filter, Risk: val })}
        placeholder='Risk'
      />
      <Col md={1} />
      <Col
        className='d-flex align-items-start flex-wrap justify-content-end '
        md={3}
      >
        <span className='btn btn-primary pt-2 mr-3 h-38'>
          <i className='las la-download scale5 mr-2' />
          Get Report
        </span>
        <Dropdown className='dropdown bootstrap-select form-control style-1 default-select mb-3 '>
          <Dropdown.Toggle
            aria-expanded='false'
            className='btn dropdown-toggle btn-light h-38'
            data-toggle='dropdown'
            role='button'
            title='Latest'
            type='button'
            variant=''
          >
            {activeName}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className='dropdown-menu'
            role='combobox'
            style={{
              left: 0,
              maxHeight: 174,
              minHeight: 0,
              overflow: 'hidden',
              position: 'absolute',
              top: 0,
              transform: 'translate3d(0px, 41px, 0px)',
              willChange: 'transform'
            }}
            x-placement='bottom-start'
          >
            <Dropdown.Item onClick={() => setActiveName('Newest')}>
              Newest
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setActiveName('Oldest')}>
              Oldest
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setActiveName('Latest')}>
              Latest
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </div>
  );
};

export default memo(Topbar);
