import { Box, Grid, Typography } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useRef } from 'react';
import { specialRadioFieldChildGrid, specialRadioFieldChildGridTitle, specialRadioFieldLabel } from '../style/specialRadioField';


const FormikRadioField = ({
  col = 6,
  customStyle,
  fieldName,
  fields,
  formik,
  label,
  nestedFieldName,
  required = false,
  singleLine = false,
  styleForm

}: FormikRadioFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  let FieldError = '';
  let FieldTouch = false;
  let NestedFieldError = '';
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }

  const textInput: any = useRef(null);

  const handleClick = (selectedOption: any) => {
    formik.setFieldValue(fieldName, selectedOption);
  }

  return (
    <Box >
      <Box sx={{ display: "flex", flexWrap: "wrap", cursor: "pointer", gap: "24px", justifyContent: 'center' }} >
        {fields.map(val => (
          <>
            <Box key={val.label}
              sx={{
                borderRadius: "8px", p: "16px 32px",
                boxShadow: formik.values[fieldName] === val.value ? "0px 0px 0px 2px #43BED8" : "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
                display: "flex", flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                flex: { xs: "1 1 100%", md: 1 },
              }}
              onClick={() => handleClick(val.value)}

            >
              <img src={val?.icon} />
              <Typography variant='body1' color={"#1D2939"}>{val?.label}</Typography>

            </Box>
            {/* 
            <Grid key={val.label} item md={fields.length == 2 ? 6 : fields.length == 4 ? 6 : 4} xs={12} onClick={() => handleClick(val.value)}>


              <Box sx={{
                ...specialRadioFieldChildGrid,
                padding: fields.length == 2 && val.title ? "2rem" : "1rem",
                boxShadow: formik.values[fieldName] === val.value ? "0px 0px 0px 2px #43BED8" : "0px 0px 0px 1px #EDEDED",
                background: formik.values[fieldName] === val.value ? "#EAF8FB" : "",
                fontWeight: formik.values[fieldName] === val.value ? 600 : 400,
                ...customStyle
              }}
              >
                {val.title && <h3 style={specialRadioFieldChildGridTitle}>
                  {val.title}
                </h3>}


                <Form.Check
                  ref={textInput}
                  key={val.label}
                  checked={formik.values[fieldName] === val.value}
                  className="specialRadio"
                  disabled={!!val.disabled}
                  id={val?.label?.toString() + label?.toString()}
                  label={val.label}
                  name={fieldName}
                  // onChange={formik.getFieldProps(fieldName).onChange}
                  style={{ paddingLeft: 0, ...styleForm }}
                  type='radio'
                  value={val.value}
                />
              </Box>
            </Grid> */}

          </>
        ))}
      </Box>

      {FieldTouch && FieldError && (
        <Row>
          <span className='mt-1 text-danger'>{FieldError}</span>
        </Row>
      )}
      {NestedFieldTouch && NestedFieldError && (
        <Row>
          <span className='mt-1 text-danger'>{NestedFieldError}</span>
        </Row>
      )}

    </Box >

  );
};

export default memo(FormikRadioField);

interface FormikRadioFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  nestedFieldName?: string;
  customStyle?: any;
  fields: Array<{ label: string; value: string; disabled?: boolean; title?: string; icon?: any }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
    setFieldValue: any
  };
  label?: string;
  required?: boolean;
  singleLine?: boolean;
  styleForm?: any;


}
