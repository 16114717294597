import ErrorScreen from 'Latest/ErrorScreen/undefinedErr';
import React from 'react';

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error: Error) {
    //   eslint-disable-next-line
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          {/* <p>Loading failed! Please reload.</p> */}
          <ErrorScreen />
        </>
      )
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
