import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
  data: [],
  totalRecords: 0
};

const name = 'transactionHistory';
const transactionHistorySlice = createSlice({
  initialState,
  name,
  reducers: {
    getFail: state => {
      state.data = [];
      state.totalRecords = 0;
    },
    getSuccess: (state, action: PayloadAction<SuccessPayload>) => {
      if (action.payload.status_code === 200) {
        state.data = JSON.parse(action.payload.data);
        state.totalRecords = action.payload.toatl_records;
      }
    }
  }
});

export default transactionHistorySlice.reducer;

//? APIS ACTIONS

export const onGetTransactionHistory = (pageNum: number) => ({
  payload: {
    apiName: name,
    data: {},
    method: 'get',
    url: `User/GetUserTransactionHistory?page_no=${pageNum}`
  },
  type: 'get'
});

// ? Types

export interface History {
  amount: number;
  name: string;
  fund_symbol: string;
  price: number;
  status: string;
  unit_purchase: number;
}

interface InitialState {
  data: Array<History>;
  totalRecords: number;
}

interface SuccessPayload {
  data: string;
  status_code: number;
  message: string;
  toatl_records: number;
}
