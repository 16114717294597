import { Col, Row } from 'react-bootstrap';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { memo } from 'react';

const AuthInputField = ({
  autoFocus = false,
  bottomInfo,
  disabled = false,
  formik,
  isMutual = false,
  label,
  maxLength,
  minLength,
  name,
  nestedFieldName,
  placeholder,
  required = false,
  topInfo,
  type = 'text'
}: AuthInputFieldProps) => {
  const formikTouch = formik.touched as any;
  const formikError = formik.errors as any;
  let fields: any = formik.getFieldProps(name);
  fields = {
    ...fields,
    value: fields.value || ''
  }

  let FieldError = "";
  let FieldTouch = false;
  let NestedFieldError = "";
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[name?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[name?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[name];
    FieldTouch = formikTouch[name];
  }

  return (
    <Col style={{ fontFamily: 'PlayFair' }}>
      <Row>
        <label className='mb-2 field-label'>
          <strong style={{ fontFamily: 'PlayFair' ,color:'#000000'}}>
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </strong>
        </label>
      </Row>
      {topInfo && (
        <Row>
          <div className='small-info mb-2 mt-1 text-muted'>{topInfo}</div>
        </Row>
      )}
      <Row>
        <input
          autoComplete="off"
          autoFocus={autoFocus}
          className='text-box-custom'
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          name={name}
          placeholder={placeholder}
          type={type}
          {...fields}
        />
      </Row>
      {/* <div style={{ display: 'flex' }}>

          {type === 'number' && < p style={{ marginLeft: '-60px', marginTop: '5px', fontWeight: 'bold' }}>
            PKR
          </p>}
        </div> */}
      {bottomInfo && (
        <Row>
          <div className='small-info mb-2 mt-1 text-muted'>{bottomInfo}</div>
        </Row>
      )}

      {FieldTouch && FieldError && (
        <Row>
          <span className='ml-2 mt-1 text-danger'>
            {FieldError}
          </span>
        </Row>
      )}

      {NestedFieldTouch && NestedFieldError && (
        <Row>
          <span className='ml-2 mt-1 text-danger'>
            {NestedFieldError}
          </span>
        </Row>
      )}
    </Col>
  );
};

export default memo(AuthInputField);

interface AuthInputFieldProps {
  autoFocus?: boolean;
  disabled?: boolean;
  formik: FormikFieldProps;
  label: string | JSX.Element;
  maxLength?: number;
  minLength?: number;
  name: any;
  nestedFieldName?: any;
  placeholder: string;
  required?: boolean;
  topInfo?: string;
  type?: string;
  bottomInfo?: string | JSX.Element;
  isMutual?: boolean;
}

export interface FormikFieldProps {
  getFieldProps: (field: string) => void;
  touched: FormikTouched<unknown>;
  errors: FormikErrors<unknown>;
  values: FormikValues;
}
