export const submitInfoStyle = {
    mainCard: {
        // borderRadius: "4px",
        // border: "1px solid  #EAECF0",
        // background: "#F9FAFB",
        // display: "flex",
        // padding: {xs:"8px",sm:"14px 20px"},
        // gap: "12px",
        // flexDirection:"column"
        padding: "1rem",
        border: "1px solid #D0D5DD40",
        backgroundColor: "#EAF4FF",
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    },
    mainHeading: {
        color: "#0A3F7A",
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: "24px",
        display: "flex",
        alignItems: "flex-start",
        gap: "4px",
        '& img': {
            position: "relative",
            top: 1
        }
    },
    secondaryCard: {
        border: "1px solid #EAECF0",
        background: "#FFF",
        display: "flex",
        padding: "8px",
        gap: "8px",
        whiteSpace: 'nowrap',
    },
    secondaryCardMainBox: {
        display: "flex",
        gap: "8px",
        flexDirection: "column"
    },
    alignContent: {
        display: "flex", alignItems: "center",
        flexWrap: "wrap"
    },
    text: {
        color: "#1D2939B2",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px"
    },
    underLineContent: {
        color: "#0A3F7A",
        fontWeight: 500,
        textDecorationLine: "underline",
        cursor: "pointer",
        fontSize: "14px"
    }
}