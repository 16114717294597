import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStylesAlertWarning = makeStyles((theme) => ({
    customAlert: {
        border: "1px solid #D0D5DD40",
        backgroundColor: "#FFF1E3 !important",
        padding: "1rem !important",
        '& .MuiAlert-message': {
            padding: 0
        },
        '& .MuiAlert-action': {
            paddingTop: 0
        }
    },
    customAlertTitle: {
        color: "#5E4200 !important",
        fontSize: "16px !important",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150% !important",
        letterSpacing: "0.15px !important",
        display: "flex",
        alignItems: "flex-start",
        gap: "6px",
        marginBottom: '8px !important',
        '& img': {
            position: "relative",
            top: 2
        }
    },
    customText: {
        color: "#5E4200 !important",
        fontSize: "14px !important",
        lineHeight: "21px !important",
        marginBottom: '8px !important'
    },
    customIcon: {
        color: '#fff', // Change the icon color here
    },
    customButton: {
        padding: "8px 16px !important",
        border: "1px solid #5E4200 !important",
        backgroundColor: "#fff !important",
        color: "#5E4200 !important",
        fontSize: "14px !important",
        lineHeight: "21px !important"
    }
}));

export default useStylesAlertWarning;