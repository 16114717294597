export const performanceGraphMainBox ={
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}

export const performanceGraphOption = {
  background: '#49bdd8',
  margin: '1rem',
  color: 'white !important',
  '&:hover': {
    color: '#49bdd8 !important'
  }
}