import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import Form from 'react-bootstrap/Form';
import { onGetPersonalisedScore } from 'redux/reducers/sharedStateData';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';


const style = {
    color: "white", borderRadius: 3,
    px: 0.75, py: 0.5, fontSize: "0.7rem",
    display: "flex", alignItems: "center"
}

const ConfirmationRiskProfileScreen = ({ formik, goalValue, previousRiskScore, setPreviousRiskScore }: any) => {

    const dispatch = useAppDispatch();

    const { personalizeScore } = useAppSelector(state => state.sharedData);

    const getScore = () => {
        const numbersOfPayment = formik.values.number_of_payments
        dispatch(onGetPersonalisedScore(goalValue, numbersOfPayment));
    }

    useEffect(() => {
        if (formik.values.number_of_payments > 0) {
            getScore()
        }
    }, [formik.values.number_of_payments])

    // const handleChange = () => {
    //     setPreviousRiskScore(!previousRiskScore)
    // }

    const selectQUestionAnswers = (category: number) => {
        if (category >= 0 && category <= 10) {
            return <Box component={"span"} sx={{ ...style, background: "#2E7D32" }}>Conservative</Box>
        } else if (category >= 11 && category <= 21) {
            return <Box component={"span"} sx={{ ...style, background: "#4CAF50" }}>Moderately Conservative</Box>
        } else if (category >= 22 && category <= 32) {
            return <Box component={"span"} sx={{ ...style, background: "#FF5722" }}>Moderately Aggressive</Box>
        } else if (category >= 33 && category <= 43) {
            return <Box component={"span"} sx={{ ...style, background: "#DD2C00" }}>Aggressive</Box>
        }
    }

    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Typography sx={{ display: "flex", alignItems: "center" }} variant="h5">
                        Risk Profile:&nbsp;
                        {selectQUestionAnswers(personalizeScore)}
                    </Typography>

                </Grid>

                <Grid item xs={12}>
                    Same as the Previous risk profile Answer
                </Grid>

                <Grid item xs={12}>
                    You may update your Risk profile responses <a href='/risk-profile' style={{ color: "#49bdd8", textDecoration: "underline", fontStyle: "italic" }}>here </a>
                </Grid>

            </Grid>
        </>
    )
}

export default ConfirmationRiskProfileScreen