import React, { useState } from 'react'
import Checkmark from 'react-typescript-checkmark';
import { Box, Link, Card, CardHeader, Typography, Stack, CardProps, IconButton, Grid, Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import Modal from '@mui/material/Modal';
import { withdrawStyle } from 'Latest/DashboardScreen/GoalDashboard/Style/withdrawStyle';
import BankIcon from "Latest/Images/bankIcon.png"
import BankIconSelected from "Latest/Images/bankIconSelected.png"
import BankCheckBoxCard from 'Latest/DashboardScreen/GoalDashboard/Component/bankCheckBoxCard';
import Flag from "Latest/Images/flag.png"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "95%", sm: "80%", md: "auto", lg: 700 },
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: "24px",
    maxHeight: "90%"
};

const MultipleAccDialog = ({ Close, Open, accDetail, formik, selectAcc }: any) => {

    const handleOnClick = (item: any, index: any) => {
        selectAcc(item)
        setTimeout(() => {
            Close()
        }, 1600);
    }

    const handleClose = () => {
        Close()
    }

    return (
        <Modal
            onClose={handleClose}
            open={Open}
        >
            <Container disableGutters maxWidth={"md"} sx={{ ...style, overflow: "auto" }} >

                <IconButton component="label" onClick={handleClose} sx={{ position: "absolute", right: 32, top: 32 }}>
                    <CloseIcon />
                </IconButton>

                <img src={Flag} style={{ marginBottom: "16px" }} />

                <Typography variant='h5' color={"secondary"} sx={{ mb: "16px" }} >Select Account</Typography>
                <Grid container>
                    <Grid item xs={12} md={12} sx={{}}  >
                        <BankCheckBoxCard list={accDetail} defaultBank={false} selectedBank={handleOnClick} formik={formik} userSelectedBank={true} defaultViewLimit={3} />
                    </Grid>
                </Grid>


            </Container>
        </Modal>
    )
}

export default MultipleAccDialog