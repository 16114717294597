import { memo } from 'react';

const HeaderText = () => (
  <>
    <p style={{ color: 'black', fontWeight: '700', fontFamily: 'PlayFair', fontSize: '28px' }}>
      Payment details
    </p>
  </>
);

export default memo(HeaderText);
