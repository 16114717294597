import { Card } from 'react-bootstrap';
import { CurrencyFormatter } from 'utils/calculation';
import UpArrow from '../../../images/upArrow.png';
import DownArrow from '../../../images/downArrow.png';
import FlatLine from '../../../images/flatLine.png';

const YieldTable = (props: any) => {
  const ArrowSelection = (value: any) => {
    return value > 0 ? UpArrow : value < 0 ? DownArrow : FlatLine;
  };

  return (
    <Card className='pb-2' style={{ height: 'auto' }}>
      <Card.Header className='pb-0 border-0'>
        <h4 className='tableProduct_header_heading'>{props.heading}</h4>
      </Card.Header>
      <Card.Body className='pb-0'>
        <div className='table-responsive'>
          <table className='table'>
            <tbody>
              {/* <tr> */}
              {props?.data?.map((val: any) => {
                return (
                  <>
                    <tr>
                      <td>{val.label}</td>
                      <td>
                        {CurrencyFormatter(val.value, true, 'never')}&nbsp;
                        <span>
                          <img src={ArrowSelection(val?.value)} />
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default YieldTable;
