import { Row } from 'react-bootstrap';
import { memo, useCallback, useEffect, useState } from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify';
import { Box, Grid, Typography } from '@mui/material';
import uploadImg from "Latest/Images/uploadIcon.png"
import { fileUploadFieldStyles } from '../style/fileUploadFieldStyle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import closeIcon from 'Latest/Images/svg/secondary-bg-close.svg';

const genericToastMessage = 'File must be a PNG or JPEG'

const FileUploadField = ({
  acceptedType,
  buttonTitle,
  col = 6,
  fieldName,
  formik,
  inputFiles,
  margin,
  maxFiles,
  permissionGranted,
  required = false,
  style,
  subTitle,
  title,
  toastErrMessage=genericToastMessage
}: FileUploadFieldProps | any) => {

  const [files, setFiles] = useState<Files>([]);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (inputFiles) {
      setFiles(inputFiles);
    }
  }, []);

  useEffect(() => {
    return () => {
      formik?.setErrors({})
    }
  }, [])

  const formikError = formik.errors as any;

  const onDrop = useCallback(
    (acceptedFiles: Files, fileRejections: FileRejection[]) => {

      const allFIles = [...acceptedFiles, ...files];
      if (allFIles.length > maxFiles) {
        toast.error('Please select only one file', { theme: "colored" });
        return;
      }

      fileRejections.forEach((file: FileRejection) => {
        file.errors.forEach((err: FileError) => {
          if (err.code === "file-invalid-type") {
            formik?.setFieldError(fieldName, toastErrMessage);
            return toast.error(toastErrMessage, { theme: "colored" });
          }
          else if (err.code === 'file-too-large') {
            formik?.setFieldError(fieldName, 'File is too large. Only files up to 10 MB are allowed.');
            return toast.error('File is too large. Only files up to 10 MB are allowed.', { theme: "colored" });
          }
        });
      });
      const newFiles = [...acceptedFiles];
      if (acceptedFiles.length > maxFiles || files.length === maxFiles) {
        return toast.error('You can only upload one file', { theme: "colored" });
      }
      if (acceptedFiles.length === 1) {
        acceptedFiles.push(...files);
      }
      acceptedFiles.forEach((el, index) => (el.id = index));
      acceptedFiles[0] && formik?.setFieldValue(fieldName, acceptedFiles[0]);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const previewFiles = newFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setFiles([...previewFiles, ...files]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [files]
  );
  const MAX_SIZE = 10000000;
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: acceptedType,
    maxFiles,
    maxSize: MAX_SIZE,
    onDrop
  });
  const handleDeleteImage = (id: number) => {
    setFiles(files.filter(el => el.id !== id));
    formik?.setFieldValue(fieldName, "");
  };

  const thumbs = files.map(file => (
    <div key={file?.name} className='thumb' id={file?.id} style={{ width: "100%", height: "100%", margin: 0 }}>
      <div className='thumb-inner' style={{ justifyContent: "center" }}>
        <a href={file?.preview} rel="noopener noreferrer" target="_blank" style={{ padding: "20px" }}>
          <img alt='file' className='thumb-image' src={file?.type === "application/pdf" ? "/Images/pdfTHumbnail/pdfThumbnail.png" : file?.preview} />
        </a>
      </div>
      <img
        src={closeIcon} onClick={e => handleDeleteImage(file?.id)} height={19.5} width={19.5}
        style={{ position: 'absolute', right: "10.25px", top: "10.25px", }} alt='removeImage'
      />
    </div>
  ));

  return (
    <>
      <Grid item md={12} xs={12} sx={{ cursor: "pointer" , ...margin }}>

        <Typography sx={fileUploadFieldStyles.titleStyleV2}>{title}</Typography>

        <div {...getRootProps()} className=''>
          {thumbs.length === 0 && <input {...getInputProps()} />}
          {thumbs.length === 0
            ?
            <Box className='img_cont' sx={{ ...style }}>

              {!smScreen ?
                <Grid item>
                  <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"10px"} sx={fileUploadFieldStyles.uploadBtnWrap}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                      <path d="M17.5234 12.375V16.75C17.5234 17.0815 17.3917 17.3995 17.1573 17.6339C16.9229 17.8683 16.605 18 16.2734 18H3.77344C3.44192 18 3.12397 17.8683 2.88955 17.6339C2.65513 17.3995 2.52344 17.0815 2.52344 16.75V12.375C2.52344 12.2092 2.58929 12.0503 2.7065 11.9331C2.82371 11.8159 2.98268 11.75 3.14844 11.75C3.3142 11.75 3.47317 11.8159 3.59038 11.9331C3.70759 12.0503 3.77344 12.2092 3.77344 12.375V16.75H16.2734V12.375C16.2734 12.2092 16.3393 12.0503 16.4565 11.9331C16.5737 11.8159 16.7327 11.75 16.8984 11.75C17.0642 11.75 17.2232 11.8159 17.3404 11.9331C17.4576 12.0503 17.5234 12.2092 17.5234 12.375ZM6.89844 7.375H9.39844V12.375C9.39844 12.5408 9.46429 12.6997 9.5815 12.8169C9.69871 12.9342 9.85768 13 10.0234 13C10.1892 13 10.3482 12.9342 10.4654 12.8169C10.5826 12.6997 10.6484 12.5408 10.6484 12.375V7.375H13.1484C13.2721 7.3751 13.3931 7.3385 13.4959 7.26983C13.5988 7.20116 13.679 7.10351 13.7263 6.98925C13.7737 6.87498 13.786 6.74924 13.7619 6.62794C13.7377 6.50663 13.6781 6.39523 13.5906 6.30782L10.4656 3.18282C10.4076 3.12471 10.3386 3.07861 10.2628 3.04715C10.1869 3.0157 10.1056 2.99951 10.0234 2.99951C9.9413 2.99951 9.85997 3.0157 9.7841 3.04715C9.70823 3.07861 9.6393 3.12471 9.58125 3.18282L6.45625 6.30782C6.36874 6.39523 6.30914 6.50663 6.28498 6.62794C6.26083 6.74924 6.2732 6.87498 6.32055 6.98925C6.36789 7.10351 6.44808 7.20116 6.55095 7.26983C6.65382 7.3385 6.77475 7.3751 6.89844 7.375Z" fill="#403081" />
                    </svg>
                    <Typography sx={fileUploadFieldStyles.uploadBtnTitleV2}>{buttonTitle}</Typography>
                  </Box>
                </Grid>
                :
                <Grid item>
                  <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"10px"} sx={fileUploadFieldStyles.uploadSecBtnWrap}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                      <path d="M16.7734 4.875H14.6078L13.543 3.27813C13.4859 3.19262 13.4087 3.1225 13.318 3.07399C13.2274 3.02548 13.1262 3.00006 13.0234 3H8.02344C7.92065 3.00006 7.81946 3.02548 7.72884 3.07399C7.63821 3.1225 7.56096 3.19262 7.50391 3.27813L6.43828 4.875H4.27344C3.77616 4.875 3.29924 5.07254 2.94761 5.42417C2.59598 5.77581 2.39844 6.25272 2.39844 6.75V15.5C2.39844 15.9973 2.59598 16.4742 2.94761 16.8258C3.29924 17.1775 3.77616 17.375 4.27344 17.375H16.7734C17.2707 17.375 17.7476 17.1775 18.0993 16.8258C18.4509 16.4742 18.6484 15.9973 18.6484 15.5V6.75C18.6484 6.25272 18.4509 5.77581 18.0993 5.42417C17.7476 5.07254 17.2707 4.875 16.7734 4.875ZM13.3359 10.8125C13.3359 11.3688 13.171 11.9125 12.8619 12.375C12.5529 12.8376 12.1137 13.198 11.5997 13.4109C11.0858 13.6238 10.5203 13.6795 9.97475 13.571C9.42917 13.4624 8.92804 13.1946 8.5347 12.8012C8.14136 12.4079 7.8735 11.9068 7.76498 11.3612C7.65646 10.8156 7.71215 10.2501 7.92503 9.7362C8.1379 9.22229 8.49838 8.78303 8.9609 8.47399C9.42341 8.16495 9.96718 8 10.5234 8C11.2694 8 11.9847 8.29632 12.5122 8.82376C13.0396 9.35121 13.3359 10.0666 13.3359 10.8125Z" fill="white" />
                    </svg>
                    <Typography sx={{ ...fileUploadFieldStyles.uploadBtnTitleV2, color: "#fff" }}>{buttonTitle}</Typography>
                  </Box>
                </Grid>
              }

              {subTitle && <Typography sx={fileUploadFieldStyles.uploadBtnSubTitleV2}>{subTitle}</Typography>}

            </Box>
            :
            <Box className='img_cont' sx={{ ...style, padding: 0 }} >
              <aside className='thumb-container' style={{ width: "100%", height: "100%", margin: 0 }}>{thumbs}</aside>
            </Box>
          }
        </div>

        {formikError[fieldName] && (
          <Row>
            <span className='ml-4 mt-1 text-danger'>
              {formikError[fieldName]}
            </span>
          </Row>
        )}

      </Grid>
    </>
  );
};

export default memo(FileUploadField);

interface FileUploadFieldProps {
  acceptedType: string;
  buttonTitle: string;
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  fieldName: string;
  inputFiles?: any;
  required?: boolean;
  setImages?: (images: Files) => void;
  imageError?: boolean;
  maxFiles: number;
  setImageError?: any;
  formik?: any;
  title?: any;
  style?: any;
  toastErrMessage?:string;
  permissionGranted?: any;
  subTitle?: string;
  margin?: object;
  
}

type Files = Array<Record<string, any>>;
