import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { links } from 'static/links'
import { useHistory } from 'react-router-dom';
import { option } from './constant'
import 'jsx/components/Portfolio/wizard.css';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1'
import TopBarLogo from 'Latest/ComponentV1/topBarLogo';
import ExitIcon from "Latest/Images/svg/ExitIcon";

const Index = () => {

    const { location, push } = useHistory();
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const addExtraRoutes = [{ title: smScreen ? "Exit" : "Exit", route: null, icon: ExitIcon, isNavlink: false, navDropDown: [], target: false, onClick: () => push("/"), functionTrigger: true },]

    return (
        <>
            <Box sx={{ background: "#F8FAFB", minHeight: "100vh"}}>
                <TopBarLogo addExtraRoutes={addExtraRoutes} />
                <Container maxWidth={'md'} disableGutters={smScreen} sx={{ backgroundColor: "#fff", mt: { md: "40px" }, border: { md: "1px solid #EAECF0" }, borderRadius: { xs: "0px", md: "12px" } }}>
                    <Grid container>
                        <Grid item md={12} xs={12} >
                            <Typography variant='h5' sx={{ backgroundColor: { xs: '#f9fafb', md: "transparent" }, px: { xs: "16px", md: 0 }, py: "16px" }}>Mahaana Invest</Typography>
                        </Grid>

                        <Grid item md={12} xs={12} sx={{ px: { xs: "16px", md: 0 }, py: "16px" }}>
                            <li style={{ listStyle: "disc" }}>Personalized investment starter kit with portfolio allocation suggestions</li>
                            <li style={{ listStyle: "disc" }}>Automatic rebalancing tool for portfolio protection against market volatility</li>
                            <li style={{ listStyle: "disc" }}>Invest in Pakistan equities and fixed income</li>
                            <li style={{ listStyle: "disc" }}>Start with as low as <strong>PKR 10,000</strong></li>
                        </Grid>

                        <Grid item md={12} xs={12} sx={{ px: { xs: "16px", md: 0 }, py: "16px" }}>
                            <ButtonRowV1 handleBack={() => push(links.home)} handleNext={() => push(links.Goal, location.state)} btnTitleNext={"Next"} />
                        </Grid>

                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Index