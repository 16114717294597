import { ThemeContext } from 'context/ThemeContext';
import { AnimatePresence, motion } from 'framer-motion';
import { ScopedAppRoutes } from '../routes/AppRoutes';
import Auth from 'jsx/containers/Auth';
import { useContext, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { links } from 'static/links';
import ErrorBoundary from './ErrorBoundary';
import PrivateRoute from './PrivateRoute';
import Scroller from './Scroller';
import AccountConfirmation from 'jsx/pages/AccountConfirmation/AccountConfirmation';
import { useAppSelector } from 'hooks/reduxHooks';
import Error404 from 'Latest/ErrorScreen/404Screen';
import Grid from '@mui/material/Grid';
import 'css/index.css';
import 'css/step.css';
import 'Latest/DashboardScreen/FreshUserScreen/style/styles.css';
import AppHeader from 'Latest/components/AppHeader';
import AppFooter from 'Latest/components/AppFooter';
import Drawer from 'Latest/components/drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { drawerStyle } from 'Latest/Style/drawer';
import Sidebar from "../../Latest/SideBar"
import { NavLink, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Subscribtions from 'jsx/containers/Subscribtion';
import { useDispatch } from 'react-redux';


const pageVariants = {
  in: { opacity: 1 },
  initial: { opacity: 0 },
  transition: { duration: 0.5 }
};

const Routes = () => {

  const { isAuth, scopes } = useAppSelector(state => state.auth);
  const location = useLocation();
  // const {endParam,startParam} = useAppSelector(state => state.profileParams)

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = () => {
    const header = document.querySelector('.AppHeader');
    const scrollTop = window.scrollY;
    if (scrollTop >= 250) {
      header?.classList.add('is-sticky')
    } else {
      header?.classList.remove('is-sticky');
    }
  };

  


  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <ErrorBoundary>
        <Scroller>
          <Switch key={location.pathname} location={location}>
            <Route component={Auth} path={links.auth} />
            <Route component={Subscribtions} path={links.subscribtion} />
            <Box id='main-wrapper'>
              <AppHeader isAuth={isAuth} scopes={scopes} />
              <Sidebar />
            </Box>
          </Switch>
        </Scroller>
      </ErrorBoundary>
    </>
  );
};

export default Routes;
