import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ProfileIcon from "Latest/Images/svg/profileIcon.svg"
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import SaveNextKin from './saveNextKin';
import NextOfKin from './index';
import { submitInfoStyle } from 'Latest/Notification/style';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "95%", sm: "80%", md: "auto", lg: 700 },
    maxHeight: "90%",
    bgcolor: 'background.paper',
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    borderRadius: "12px",
    p: "24px",
    overflow: "auto"
};

export default function ModalNextOfKin({checkNextOfKinbeneficiaries}:any) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleModalOpen =()=>{
        !checkNextOfKinbeneficiaries && handleOpen()
    }

    return (
        <>
            
            <Typography variant='body1' component="span" onClick={handleModalOpen} sx={submitInfoStyle.underLineContent}>Submit</Typography>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", mb: "16px" }}>
                        <img src={ProfileIcon} />

                        <IconButton aria-label="upload picture" component="label">
                            <CloseIcon onClick={handleClose} />
                        </IconButton>

                    </Box>
                    <NextOfKin path="modal" handleCloseModal={handleClose} />
                </Box>
            </Modal>
        </>
    );
}