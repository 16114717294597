/* eslint-disable no-param-reassign */
export const Compound = (
  initial: number | string,
  amount: number | string,
  years: number | string,
  interest: number | string,
  period = 1
) => {
  initial = Number(initial);
  amount = Number(amount);
  years = Number(years);
  interest = Number(interest);
  period = Number(period);

  const rate = interest / period;
  let result;
  let totalFromPrincipal;
  let totalFromAnnuity;
  const principal = [initial];
  const int = [0];
  const total = [initial];
  for (let i = 1; i < years + 1; i++) {
    // What is current value of initial investment
    totalFromPrincipal = initial * (1 + rate) ** (i * period);
    // what is current value factor of the annuity
    totalFromAnnuity = ((1 + rate) ** (i * period) - 1) / rate;
    // multiply value factor by amount
    totalFromAnnuity = totalFromAnnuity * amount;

    const totalForYear = totalFromAnnuity + totalFromPrincipal;
    total.push(Number(totalForYear.toFixed(0)));

    // total amount of contributed capital
    const contribution = amount * i * period + initial;
    principal.push(Number(contribution.toFixed(2)));

    const intAmt = totalForYear - contribution;
    int.push(Number(intAmt.toFixed(2)));
    if (i === years) {
      result = Number(totalForYear.toFixed(2));
    }
  }
  return { interest: int, principal: principal, result: result, total: total };
};
