import React from 'react'
import { Box, Container, Grid, IconButton, Modal, Stack, Typography } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import checkImage from '../Images/svg/check.svg';
import { styled } from '@mui/material/styles';
import { links } from 'static/links';
import { commaFormatted } from 'utils/commaSeparateMasking';
import { successReceiptModalStyle } from './style';
import { CurrencyFormatter } from 'utils/calculation';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    px: { xs: "24px", sm: "24px", md: "40px" },
    borderRadius: "12px",
    maxWidth: '100%',
    width: { xs: "95%", sm: "auto", md: "auto" },
};

const SuccessReceiptModal = ({ amount, customhandleBtn, handleClose, handleOpen, isCustomhandleClick, open,productName }: any) => {
    const { location, push } = useHistory();

    const handleBtn = () => {
        customhandleBtn ? customhandleBtn() : push(links.home);
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ position: "absolute", right: 12, top: 20 }}>
                        <IconButton aria-label="upload picture" component="label">
                            <CloseIcon onClick={handleClose} />
                        </IconButton>
                    </Box>

                    <Grid sx={successReceiptModalStyle.mainGrid}>
                        <Grid item md={12} xs={12} sx={{}}>
                            <img
                                src={checkImage}
                                alt={'check'}
                                loading="lazy"
                            />
                        </Grid>

                        <Grid item md={12} xs={12} >
                            <Typography sx={successReceiptModalStyle.head1} variant='h5'>Your redemption of <Typography component={"span"} sx={{ ...successReceiptModalStyle.head1, fontWeight: 600 }}>{CurrencyFormatter(amount, true)}</Typography> has been initiated.</Typography>
                            <Typography textAlign={"center"} mt="8px">Product: {productName == "Cash Fund" ? "Save+" : productName}</Typography>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Typography variant='body2' color='#475467' textAlign={"center"}>Your balance will be updated in 1-2 working days </Typography>
                        </Grid>

                        {/* <Grid item md={12} xs={12} mt="5px">
                            <Typography variant='body2' color='#667085' textAlign={"center"} sx={{ background: "#F3F4F6", p: '12px', borderRadius: "8px" }}>
                                Note: You may receive withdrawals from 2 different bank accounts.
                            </Typography>
                        </Grid> */}

                        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: "center" }}>
                            <Button variant='contained' color="secondary" onClick={handleBtn}>Ok</Button>
                        </Grid>
                    </Grid>
                </Box>

            </Modal >
        </>
    )
}

export default SuccessReceiptModal