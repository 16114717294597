import { Box, Container, Grid, Typography } from '@mui/material';

export const HeaderSection = ({ cashFundSummary, handlePressAccountSetup, handlePressGoBack, smScreen }: any) => {

    return (
        <Box sx={{
            p: { xs: "24px 16px 24px 16px", md: "24px 0px 24px 0px" }, 
            background: "#fff",
            borderBottom: "1px solid #EAECF0"
        }}>
            <Container maxWidth={'xl'} disableGutters={smScreen}>

                <Grid item md={12}>
                    <Box sx={{ width: "100%", display: "flex", flex: 1, alignItems: "center", gap: "10px", flexDirection: "row" }}>
                        <Typography variant="h1" color={"#422E84"}>Mahaana Save+</Typography>
                        <Typography variant="h6" color={"#2E90FA"} sx={{ p: "2px 10px", borderRadius: "16px", background: "#EFF8FF" , whiteSpace: "nowrap" }}>{"Low Risk"}</Typography>
                    </Box>

                    <Typography variant="body1" color={"rgba(29, 41, 57, 0.70)"} sx={{ width: "100%" }}>
                        All Mahaana Save+ funds are invested into <a href="https://www.mahaana.com/micf" target="_blank" rel="noreferrer" style={{ textDecorationLine: "underline", color: "#422E84", fontWeight: 500 }}>Mahaana Islamic Cash Fund (MICF)</a>
                    </Typography>
                </Grid>

            </Container>
        </Box>
    )
}
