import { Box, Collapse, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useRef } from 'react'
import fileUplaodIcon from "Latest/Images/svg/fileUplaodIcon.svg"
import infoIcon from "Latest/Images/svg/_infoIcon.svg"
import greenFileIcon from "Latest/Images/svg/greenFileIcon.svg"
import redFileIcon from "Latest/Images/svg/redFileIcon.svg"
import CloseIcon from '@mui/icons-material/Close';
import CustomTooltip from './customTooltip'

const CustomUpload = ({ fieldName, formik, tooltip, upperPDFErr }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const fileInputRef: any = useRef(null);

    // const handleFileInput = (e: any) => {
    //     const files = e.target.files;
    //     formik.setFieldValue(fieldName, files[0]);
    // };

    const handleFileInput = (e: any) => {
        const files = e.target.files;
        formik.setFieldValue(fieldName, files[0]);

        // Clear the input value to allow re-uploading the same file
        e.target.value = null;
    };

    const handleBoxClick = () => {
        fileInputRef.current.click();
    };

    const handleClear = () => {
        formik.setFieldValue(fieldName, "");
    }

    const formikTouched = formik.touched[fieldName]

    return (
        <>
            <input
                ref={fileInputRef}
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileInput}
                accept=".png, .jpg, .jpeg, .pdf"
                id='upload-input'
            />

            <Box id="main-Upload-Field" sx={{ display: "flex", flexDirection: "column", gap: "8px", cursor: "pointer" }}>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant='body2' color="#344054" >Proof of Payment {tooltip && <CustomTooltip tootltipContent={tooltip} />}</Typography>
                    {!smScreen && formikTouched && formik?.errors[fieldName] == upperPDFErr && <Typography variant='body2' color="#FC5555">{formik?.errors[fieldName]}</Typography>}
                </Box>

                {formik.values[fieldName] ?
                    <StatusButton name={formik?.values[fieldName]?.name} handleClear={handleClear} error={formik?.errors[fieldName]} />
                    :
                    <>
                        <UploadBtn handleBoxClick={handleBoxClick} />
                        {smScreen && formik?.errors[fieldName] == upperPDFErr && <Typography variant='body2' className='ml-1 mt-1 text-danger'>{formik?.errors[fieldName]}</Typography>}
                    </>}


            </Box>


        </>
    )
}

export default CustomUpload

const UploadBtn = ({ handleBoxClick }: any) => {
    return (
        <Box sx={{ borderRadius: "6px", border: "1px solid #432F87", display: "flex" }} onClick={handleBoxClick}>

            <Box sx={{ width: "58px", height: "58px", background: "#432F87", display: "flex", justifyContent: "center", alignItems: "center", p: "17px" }}>
                <img src={fileUplaodIcon} />
            </Box>

            <Typography color="#432F87" sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px", fontWeight: 500 }}>
                Upload Receipt
            </Typography>

        </Box>
    )
}

const StatusButton = ({ error, handleClear, name }: any) => {
    return (
        <Box sx={{ borderRadius: "6px", border: "1px solid #D0D5DD", borderLeft: `4px solid ${error ? "#FC5555" : "#29CC6A"}`, display: "flex", gap: "8px" }}>
            <img src={error ? redFileIcon : greenFileIcon} style={{ background: error ? "#fef3f2" : "#ecfdf3" }} />

            <Box sx={{ p: "8px 16px 8px 4px", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography sx={{ fontSize: "13px!important", wordBreak: "break-all" }} color="#475467">{name}</Typography>
                {error && <Typography sx={{ fontSize: "10px!important", lineHeight: "14px" }} color="#FC5555">{error}</Typography>}
            </Box>
            <Box sx={{ alignItems: "center", display: "flex", pr: "12px" }}>
                <IconButton aria-label="CloseIcon" size="small" onClick={handleClear}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
    )
}