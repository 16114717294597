import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useAppSelector } from "hooks/reduxHooks";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { tokens } from "theme";
import "../style/elipses.css"
import Loader from "jsx/components/Shared/Loader";
import { ENVIRONMENT_NAME } from "constants/settings";
import { LandingPageURL } from "constants/landingPageURL";

const Index = () => {
    const defaultView = 3;
    const [showMore, setShowMore] = useState<number>(3);
    const { articlesData } = useAppSelector((state: any) => state.sharedData);
    const mahaanaArticles:any = articlesData?.articles;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery('(max-width:600px)');
    const updatedData = mahaanaArticles?.slice(1);

    const handleView = () => {
        if (showMore === defaultView) {
            setShowMore(updatedData?.length)
        } else {
            setShowMore(defaultView)
        }
    }
    const handleViewMore = () => {
        if(ENVIRONMENT_NAME =="stg" || ENVIRONMENT_NAME =="dev"){
            window.open(LandingPageURL.STG, '_blank');

        }
        else{
            window.open(LandingPageURL.MAIN, '_blank');
        }
      };

    const buttonHtml = (name: string, style = {}) => {
        return (
            <Button  sx={{ width: "130px", textTransform: "none", backgroundColor: "white", color: "#422E84", border: "1px solid #422E84", borderRadius: "20px" }} onClick={handleViewMore}>
                {name}
                
            </Button>
        )
    }

    if (!mahaanaArticles || !mahaanaArticles.length) {
        return null
    }
    return (

        <Grid >
            <Box>
                <Typography variant='h4' color={colors.purple[100]} lineHeight={"36px"} paddingBottom={2}>From Mahaana’s Desk</Typography>
            </Box>
            <a href={mahaanaArticles[0]?.link} target="_blank" rel="noreferrer"   >
            <Box sx={{ background: "#017CA3", padding: "20px", borderRadius: "12px", color: "rgba(255, 255, 255, 0.87)" }}>
                <Box sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" } }}>
                    {
                        smScreen ? (

                            <img src={mahaanaArticles[0]?.image} alt="Alied" style={{ width: "100%", height: "auto",objectFit: "cover", borderRadius: "8px", }} />
                        ) : (

                            <img src={mahaanaArticles[0]?.image} alt="Alied" style={{  width: "200px",
                                height: "200px", 
                                maxWidth: "200px", 
                                maxHeight: "200px", 
                                objectFit: "cover", 
                                borderRadius: "8px"}} />


                        )
                    }

                    <Box sx={{ minWidth: "10%", marginTop: { xs: "20px", sm: "0px", md: "0px" }, display: "flex", flexDirection: "column", justifyContent: "space-between", color: "white", marginLeft: { md: "15px", sm: "15px", xs: "0px" } }}>
                        <Box>
                            <Typography variant="body2" fontWeight={300}>{mahaanaArticles[0]?.category.toUpperCase()}</Typography>
                            <Typography variant="h4" marginTop={"10px"} className="elipseStyleText"> {mahaanaArticles[0]?.name}</Typography>
                            <Typography variant="body2" fontWeight={300} marginTop={"13px"} marginBottom={"10px"} className="elipseStylePara">{mahaanaArticles[0]?.body}</Typography>
                        </Box>
                        {
                            mahaanaArticles[0]?.linkButton &&
                            <Box>
                                <Button sx={{ width: { md: "180px", sm: "180px", xs: "100%" }, color: "white", fontWeight: "400", fontSize: "14px", border: "1px solid rgba(255, 255, 255, 0.87)", borderRadius: "100px", textTransform: "none" }} onClick={()=>  window.open(mahaanaArticles[0]?.link, '_blank', 'noopener,noreferrer')}>{mahaanaArticles[0]?.linkButtonText}</Button>
                            </Box>
                        }

                    </Box>
                </Box>
            </Box>
            </a>
            <Box>
                {
                    updatedData?.slice(0, showMore)?.map((vals: any, index: any) => {
                        return (
                            <a key={index} href={vals?.link} target="_blank" rel="noreferrer" style={{textDecoration:"none", color:"#000"}} >
                            <Box  sx={{ display: "flex", marginTop: "20px", marginBottom: "20px" }} >
                                <img src={vals?.image} alt="Alied" style={{  width: "94px", 
                                height: "94px", 
                                maxWidth: "94px", 
                                maxHeight: "94px", 
                                objectFit: "cover", 
                                borderRadius: "8px"}} />

                                <Box sx={{ marginLeft: "20px" }}>
                                    <Typography  variant="body1" sx={{ fontWeight: "400" }}>{vals?.category.toUpperCase()}</Typography>
                                    <Typography className="elipseStyleText" variant="body1" style={{ color: "#443087", fontWeight: "500" }} >
                                        {vals?.name}</Typography>
                                    <Typography variant="body2" className="elipseStylePara">{vals?.body}</Typography>
                                </Box>
                            </Box>
                            </a>
                        )
                    })
                }
            </Box>

            {
                updatedData?.length &&
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {
                            buttonHtml("View More")

                    }
                </Box>
            }
        </Grid>
    )
}

export default Index