export const specialCheckBoxLabel = {
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "150%",
    color: "#202020"

}
export const specialCheckBoxChildGrid = {
    cursor: "pointer",
    '& .specialCheckbox label': {
        display: "flex",
        padding: { md: "16px 32px", xs: "8px 16px" },
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        flex: 1,
        borderRadius: "8px",
        background: "#FFF",
        boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
        fontSize: { md: "1rem", xs: "0.875rem" }
    },
    ".form-check-label": {
        m: 0
    }
}
