/* eslint-disable sort-keys */
export const CashFundAssetsAllocation = {
    caption: {
        width: '103px',
        minHeight: '26px !important',
        background: '#43BED8 !important',
        borderRadius: '4px !important',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '13px !important',
        lineHeight: '20px !important',
        color: '#FFFFFF !important',
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important'
    },
    allocationChartWrapper: {
        background: "#FFFFFF",
        borderRadius: "12px",
        border: { md: "1px solid #EDEDED", xs: 'none' },
        pt: { md: '40px', sm: 2, xs: 2 }, //request changes
        px: { md: '40px', sm: 2, xs: 2 }, //request changes
        // padding: { md: '40px', xs: '0' }  //previous 
    },
    allocationSecondtabsContainer: {
        border: '1px solid #EAEAEA',
        backgroundColor: '#F9FAFB',
        height: { md: '66px', sm: '56px', xs: '46px' },
        padding: 0,
        '& .MuiTabs-flexContainer': {
            height: '100%',
            padding: '5px',
            '& button': {
                width: { md: '25%', sm: '25%', xs: 'auto' },
            },
        }
    },
    allocationtabsContainer: {
        backgroundColor: '#F5F5F5',
        borderRadius: '8px',
        p: '5px',
        '& .MuiTabs-indicator': {
            display: 'none'
        }
    },
    allocationTabsSecondWrapper: {
        minWidth: '25%',
        minHeight: '100%',

        height: { md: '54px', xs: '34px' },
        width: { md: '321px', xs: 'auto' },

        '&.Mui-selected': {
            backgroundColor: '#43BED8',
            color: '#ffffff',
            borderRadius: '6px',
            fontWeight: '600',
            fontFamily: 'Inter',
            minHeight: '100%',
            height: '100%',
        }
    },
    allocationTabsWrapper: {
        minWidth: '50%',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: { md: '16px', sm: '14px', xs: '12px' },
        lineHeight: '19px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#666666',
        textTransform: 'capitalize',
        minHeight: '40px',
        height: '40px',


        '&.Mui-selected': {
            backgroundColor: '#fff',
            borderRadius: '8px',
            color: '#202020',
            fontWeight: '600',
            fontFamily: 'Inter',
            minHeight: '40px',
            height: '40px',
            padding: 0
        }
    },
    valueCardsContainer: {
        alignItems: "center", pl: { md: 4, xs: 0 }
    },
    container: {
        mt: { md: 1, lg: 8 } //request changes
        // mt: 8        //previous
    },
    flexWrapper: {
        display: 'flex', alignItems: 'flexStart',
        flexWrap: 'wrap'
    },
    valuesCard: {
        display: 'flex',
        flexDirection: 'column',
        alingItems: 'flexStart',
        padding: '20px',
        gap: '4px',
        minHeight: '92px',
        background: '#F3F4F6',
        borderRadius: '10px',
        marginBottom: { md: '20px', xs: '0' },
        marginTop: { md: 0, xs: '20px' },
        width: { md: 'auto', sm: '360px', xs: '100%' },

        '& h5': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '150%',
            textTransform: 'capitalize',
            color: '#666666',
        },

        '& h6': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#202020',
        }
    }

}

export const DistributionsContentStyles = {
    distributionsContentContainer: {
        '& h3': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '30px',
            lineHeight: '38px',
            color: '#101828',
        }
    },
    distributionsContentThirdWrapper: {
        display: 'flex',
        background: '#FFFFFF',
        border: '1px solid #D0D5DD',
        borderRadius: '8px',
        padding: '16px',
        gap: '12px',
        '& img': {
            width: 'auto',
            height: 'auto',
        },
        '& .MuiTypography-body1': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '15px',
            lineHeight: '20px',
            color: '#344054',
            paddingBottom: '5px'
        },
        '& .MuiTypography-body2': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '13px',
            lineHeight: '20px',
            color: '#475467',
        }
    }
}

export const OverViewContentStyles = {
    viewFull: {
        pt: 2,
        '& button': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            color: '#403081',
            textTransform: 'capitalize',
            '& img': {
                marginLeft: '10px'
            },
            '&:hover, img:hover': {
                cursor: 'pointer'
            }
        }
    },
    overViewChartWrapper: {
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #EAECF0',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.06)',
        borderRadius: '4px',

    },
    overViewContentContainer: {
        '& h6': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: { md: '24px', xs: '18px' },
            lineHeight: {md: '38px', xs: '28px'},
            color: '#101828'
        },
    },
    overViewFirstWrapper: {
        display: 'flex',
        '& p': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: { md: '17px', xs: '14px' },
            lineHeight: '28px',
            color: '#475467',
            paddingTop: '15px'
        }
    },
    overViewTableWrapper: {
        background: '#FFFFFF',
        border: '1px solid #EAECF0',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.06) !important',
        borderRadius: '4px',
        boxSizing: 'border-box',
        '& .MuiPaper-rounded': {
            borderRadius: '12px',
            border: '1px solid #EAECF0'
        },
        '& thead': {
            backgroundColor: '#F9FAFB',
            '& th': {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#475467',
            }
        },
        '& tbody': {
            '& th': {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                // lineHeight: '11px',
                color: '#101828',
                '& p': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#747474',
                    paddingTop: '5px'
                }
            },
            '& td': {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                color: '#475467',
            }
        }
    },
    overViewThirdWrapper: {
        display: 'flex',
        background: '#FFFFFF',
        border: '1px solid #D0D5DD',
        borderRadius: '8px',
        padding: '16px',
        gap: '12px',
        '& img': {
            width: 'auto',
            height: 'auto',
        },
        '& .MuiTypography-body1': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            color: '#344054',
            paddingBottom: '5px'
        },
        '& .MuiTypography-body2': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#475467',
        }
    },
    secondaryTextNext: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '13px',
        pt: 2,
        '& p': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#403081',
        },
        '& img': {

        }
    },
    graphBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    graphOption: {
        color: '#34bcff !important',
        fontSize: '10px',
        fontFamily: 'Inter',
        fontWeight: '400',
        border: 'none !important',
        padding: '3px',
        margin: '0 3px 20px !important',
        minWidth: 'auto !important'
    },
    graphOptionActive: {
        color: '#0046a7 !important',
        fontSize: '10px',
        fontFamily: 'Inter',
        fontWeight: '700',
        backgroundColor: '#efefef',
        borderRadius: '3px !important',
        border: 'none !important',
        padding: '3px',
        margin: '0 3px 20px !important',
        minWidth: 'auto !important'
    },
}

export const FundExposureContentStyles = {
    fundExposureContentContainer: {
        '& h3': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '30px',
            lineHeight: '38px',
            color: '#101828',
        }
    },
    expoGridFirstWrapper: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #EAECF0',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px'
    },
    expoGridSecondWrapper: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #EAECF0',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderLeftWidth: '0'
    },
    expoLablesStyle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '11px',
        color: '#747474',
    },
    exposureChildContainerStyle: {
        '& h4': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '28px',
            color: '#101828',
            padding: '12px 24px',
            gap: '16px',
            display: 'flex'
        }
    },
    expoTabContainerStyle: {
        padding: '4px',
        gap: '8px',
        backgroundColor: '#F9FAFB',
        border: '1px solid #E6E6E6',
        borderRadius: '8px',
        height: '44px',

        '& .MuiTableCell-root': {
            border: 'none',
        },

        '& .MuiTabs-indicator': {
            display: 'none'
        },
        '& .MuiButtonBase-root': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#667085',
            padding: '8px 12px',
            gap: '8px',
            textTransform: 'capitalize',
            minHeight: '100%'
        },
        '& .Mui-selected': {
            color: '#344054 !important',
            background: '#FFFFFF',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '6px',
        }
    },

    expoTabWrapper: {
        display: 'flex',
        '& .MuiPaper-root': {
            boxShadow: 'none',
            borderRadius: '0'
        },
        '& table': {
            border: 'none'
        },
        '& thead tr': {
            height: '44px',
            backgroundColor: '#F9FAFB',
            borderBottom: '1px solid #EAECF0',
            borderTop: '1px solid #EAECF0',

            '& th:first-child': {
                width: { xl: '75%', md: '70%', xs: 'auto' },
                border: 'none'
            }
        },
        '& th, td': {
            padding: '16px 24px'
        },
    },
    lineStyles: {
        height: '7.37px'
    }
}

export const KeyFactsContentStyles = {
    keyFactsContentContainer: {
        '& h3': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: { md: '30px', xs: '18px' },
            lineHeight: '38px',
            color: '#101828',
        }
    },

    keyFactContentWrapper: {

        padding: '16px 20px',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        margin: '15px 0',
        flexWrap: "wrap",
        '& h6': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: { md: '18px', xs: '16px' },
            lineHeight: '28px',
            color: '#000000',
        },
        '& .MuiTypography-subtitle1': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: { md: '15px', xs: '14px' },
            lineHeight: '20px',
            color: '#475467',
        },
        '& .MuiTypography-body2': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: { md: '15px', xs: '14px' },
            lineHeight: '23px',
            color: '#475467',
        },
        '& .successContentWrapper': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#FFFFFF',
            backgroundColor: '#039855',
            borderRadius: '30px',
            padding: '6px 12px',
            marginRight: '8px'

        },
        '& .primaryContentWrapper': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#FFFFFF',
            padding: '6px 12px',
            backgroundColor: '#43BED8',
            borderRadius: '30px',
            marginRight: '8px'
        },
        '& .secondaryContentWrapper': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#FFFFFF',
            padding: '6px 12px',
            backgroundColor: '#403081',
            borderRadius: '30px',
        },
    },

    keyFactContentThirdWrapper: {
        display: 'flex',
        background: '#FFFFFF',
        border: '1px solid #D0D5DD',
        borderRadius: '8px',
        padding: '16px',
        gap: '12px',
        '& img': {
            width: 'auto',
            height: 'auto',
        },
        '& .MuiTypography-body1': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '15px',
            lineHeight: '20px',
            color: '#344054',
            paddingBottom: '5px'
        },
        '& .MuiTypography-body2': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '13px',
            lineHeight: '20px',
            color: '#475467',
        }
    },
}

export const ProjectionStyles = {
    projectionsMainTypoStyle: {
        color: "#202020",
        fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "155%"
    },
    projectionsMainBox: {
        padding: { lg: "40px", md: '0px', xs: '0' } //request changes
        //  padding: {md: '40px', xs: '0'} //Previous
    },
    projectionsAmountStyle: {
        color: "#202020",
        fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "32px",
        lineHeight: "140%"
    },
    projectionsAmountTitleStyle: {
        fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#666666"
    },

    projectionChartTooltipDivStyle: {
        background: "#101828",
        borderRadius: "8px",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: "0.5rem",
        height: "4rem",
        boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    },

    projectionChartTooltipBottomArrowStyle: {
        position: 'absolute',
        width: '12px',
        height: '12px',
        left: 'calc(50% - 12px/2 + 0px)',
        bottom: '-4px',
        background: '#101828',
        borderRadius: '1px',
        transform: 'rotate(45deg)',
    },

    projectionChartTooltipValueStyle: {
        color: "#FFFFFF",
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "18px",
    },
    xAxisPorfolioStyles: {
        fontWeight: 400,
        fontSize: '13px',
        fontFamily: "Inter",
        backgroundColor: '#403081',
    },
    activeXAxisPorfolioStyles: {
        fontWeight: 600,
        fontSize: '13px',
        fontFamily: "Inter",
        backgroundColor: '#F5F8FF',
        borderRadius: '4px',
        padding: '6px 10px',
    },
    yAxisPorfolioStyles: {
        fill: "#6B6F80",
        fontWeight: 400,
        fontSize: '13px',
        fontFamily: "Inter",
        letterSpacing: "-0.25px"
    },
    xAxisWrapperStyles: {
        backgroundColor: "#403081"
    }
}

export const PorfolioStyles = {
    projectionsMainTypoStyle: {
        color: "#202020",
        fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "155%"
    },
    projectionsAmountStyle: {
        color: "#202020",
        fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "32px",
        lineHeight: "140%"
    },
    projectionsAmountTitleStyle: {
        fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#666666"
    },

    projectionChartTooltipDivStyle: {
        background: "#101828",
        borderRadius: "8px",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: "0.5rem",
        height: "4rem"
    },
    pieChartLegentCustomMainDivStyle: {
        display: 'flex',
        alignItems: "baseline",
        paddingBottom: '20px',
        justifyContent: "space-between"
    },

    pieChartLegentCustomColorBoxStyle: {
        width: "12px",
        height: "12px"
    },

    pieChartLegentCustomTitleStyle: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#000000",
        marginLeft: "0.5rem"
    },

    pieChartLegentCustomAmountStyle: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "20px",
        color: "#000000",
    },
    legendWrapperStyles: {
        right: 0,
        width: '45%'
    },
    legendHorizontalWrapperStyles: {
        right: 0,
        width: '100%'
    },
    legendContentWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minWidth: '250px',
    },
    pieCellBorder: {
        border: '26.5667px solid #3D2B7D'
    },
    pieSectorStyles: {
        filter: 'drop-shadow(rgb(152,152,152) 1.32084px 10.9813px 3.6229px)',
        cursor: 'pointer'
    }
}

export const boxStyle = {
    width: "18rem",
    height: "128px",
    left: "112px",
    top: "185px",
    background: "#EAF8FB",
    borderRadius: "6px",
    p: 4
}

export const CashFundAllocationStyles = {
    HeaderContainerStyle: {
        backgroundColor: '#403081',
        paddingTop: 6,
        paddingBottom: 6
    },
    headingWrapper: {
        display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between'
    },

    mahaanLogoWrapperStyle: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start',
        padding: '25px 15px',
        '& img': {
            height: { md: 'auto' },
            width: { md: 'auto', xs: '50px' },
            objectFit: 'contain'
        }
    },

    HeaderContentWrapperStyle: {
        pl: { md: 4, xs: 2 },
        '& h4': {
            color: '#FFFFFF',
            fontWeight: 600,
            fontFamily: 'Poppins',
            pt: '10px',
            pb: '10px',
            fontSize: { md: '32px', sm: '30px', xs: '26px' }
        }
    },

    HeaderCaptionStyle: {
        color: '#fff',
        backgroundColor: '#43BED8',
        borderRadius: '4px',
        padding: '3px 10px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        fontSize: '13px'
    },

    optionsGridWrapperStyle: {
        backgroundColor: 'rgba(255, 255, 255, 0.09)',
        borderRadius: '4px',
        padding: '6px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mr: 2,
        mt: 2,
        minWidth: '138px',
        minHeight: '40px',
        "& img": {
            width: 'auto',
            height: 'auto'
        },
        '& span': {
            color: '#fff',
            fontWeight: 500,
            textTransform: 'capitalize',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            pl: 1,
            fontSize: '14px'
        }
    },

    backWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pr: 3,
        "& img": {
            width: 'auto',
            height: 'auto'
        },
        '& p': {
            color: '#fff',
            fontWeight: 500,
            textTransform: 'capitalize',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            pl: 1
        },
        '& p:hover, & img:hover': {
            cursor: 'pointer'
        }
    },

    accountSetupWrapperStyle: {
        backgroundColor: '#F99537',
        color: '#fff',
        borderRadius: '6px',
        padding: '10px 18px',
        width: '162px',
        height: '54px',
        '& h6': {
            color: '#fff',
            fontWeight: 500,
            textTransform: 'capitalize',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontSize: '16px'
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#E08632',
            transition: '1s ease-in-out'
        }
    },

    cardsWrapperStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: { md: '24px', xs: '15px' },
        gap: '24px',
        isolation: 'isolate',
        minWidth: { lg: '350px', md: '280px', sm: '250px', xs: '65%' },
        minHeight: { md: '140px', xs: '100px' },
        backgroundColor: '#FFFFFF',
        border: '1px solid #EAECF0',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        borderRadius: '12px',

        '& .MuiGrid-root': {
            position: 'relative'
        },
        '& p': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            color: '#101828',
            fontSize: '16px'
        },
        '& h5': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '30px',
            lineHeight: '44px',
            letterSpacing: '-0.02em',
            color: '#101828',
        },
        '& h4': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: { md: '34px', xs: '32px' },
            lineHeight: '44px',
            letterSpacing: '-0.02em',
        },
        '& img': {
            position: 'absolute',
            width: '100px',
            right: '0',
            // width: { md: '85px', xs: '75px' }
        },
    },

    backOptionWrapperStyle: {
        paddingBottom: { sm: '20px', xs: '0px' },
        paddingTop: { sm: '0px', xs: '40px' },
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'flex-start',
        width: { sm: '20%', xs: '100%' , md:"20%" }
    },

    HeaderContentBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: { sm: 'noWrap', xs: 'wrap' },
        flexDirection: { md: 'row', sm: 'row', xs: 'column' },
        width: { sm: '100%', xs: '100%' }
    },

    cardsBoxWrapperStyle: {
        display: 'flex',
        justifyContent: { md: 'flex-start', sm: 'flex-start', xs: 'center' },
        pt: 4,
        flexWrap: 'wrap',
        '& > .MuiGrid-root': {
            marginBottom: { md: '10px', sm: '10px', xs: '0' },
            marginTop: { md: '10px', sm: '10px', xs: '0' }
        },
        '& > .MuiGrid-root:nth-child(2)': {
            margin: { md: '10px 20px', sm: '10px 20px', xs: '10px 0px' }
        }
    },
    amountBox :{
        position: "relative", display: "flex", border: "1px solid #EDEDED",
        background: "#FFFFFF", borderRadius: "4px", padding: "9px 20px", width: "100%",
    },
    errBox:{ color: "red", padding: "0px 10px", fontSize: "12px", fontFamily: 'Poppins', },
    editInput:{
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        border: "none",
        width: "100%"
    }
}

export const flexRow = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

export const flexColumn = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px'
}

export const HeaderSectionStyles = {
    HeaderContainerStyle: {
        backgroundColor: 'white',
        paddingTop: { lg: 6, xs: 4, md: 2 },
        paddingBottom: { lg: 6, xs: 4, md: 2 },
        borderBottom:"2px solid #F3F4F6"
    },
    mahaanLogoWrapperStyle: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start',
        padding: '25px 15px',
        width: { md: '94.62px', xs: '60px' },
        height: { md: '94.62px', xs: '60px' },
        boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
        '& img': {
            height: { md: 'auto', xs: 'auto' },
            width: { md: 'auto', xs: '33.72px' },
            objectFit: 'contain'
        }
    },
    HeaderContentWrapperStyle: {
        pl: { md: 4, xs: 2 },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& h4': {
            color: '#2C2C2C',
            fontWeight: 600,
            fontFamily: 'Poppins',
            letterSpacing: '-0.5px',
            lineHeight: { md: '46px', xs: '28px' },
            pb: 1,
            fontSize: { md: '31px!important', sm: '25px!important', xs: '21px!important' }
        },
        '& p': {
            color: '#666666',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: { md: '16px', xs: '14px' },
            lineHeight: '19px',
        },
        '& .MuiTypography-caption': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: { md: '3px 10px', xs: '0 10px' },
            gap: '10px',
            height: { md: '27px', xs: '24px' },
            borderRadius: '11px',
            backgroundColor: '#432F87',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: { md: '15px', xs: '13px' },
            lineHeight: '140%',
            color: '#FFFFFF',
            marginLeft: '8px'
        }
    },
    navParaStyles: {
        display: 'flex',
        alignItems: 'center'
    },
    backWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pr: 3,
        height: { md: '48px', xs: '40px' },
        "& img": {
            width: 'auto',
            height: 'auto'
        },
        '& p': {
            color: '#000000',
            fontSize: '16px',
            fontWeight: 500,
            textTransform: 'capitalize',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            pl: 1
        },
        '& p:hover, & img:hover': {
            cursor: 'pointer'
        }
    },
    nextWrapperStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 18px',
        gap: '8px',
        width: '100px',
        height: { md: '48px', xs: '40px' },
        backgroundColor: '#403081',
        borderRadius: '6px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#332667',
        }
    }
}