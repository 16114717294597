import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { CurrencyFormatterWithDecimal } from 'utils/calculation'
import checkImage from 'Latest/Images/svg/successdepositIcon.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { onGetDashboard, payMobServiceFeeActions } from 'redux/actions';
import { init } from 'mixpanel-browser';


const SuccessScreen = ({ initAmount, paymentType, refId, transacId }: any) => {

  const dispatch = useAppDispatch();
  const { payMobServiceFee } = useAppSelector(state => state?.portfolio);
  const isPaymentTypePaymob = paymentType.toLowerCase() === "Paymob".toLowerCase()
  const paymobFee = isPaymentTypePaymob ? Number(payMobServiceFee || 0) : 0;
  const initialAmt = Number(initAmount || 0) - paymobFee


  useEffect(() => {
    dispatch(onGetDashboard())

  }, []);

  return (
    <>
      <Typography variant='h1' textAlign={"center"} sx={{ mb: "24px" }} color="#1D2939">Deposit successful</Typography>

      <Box sx={{
        width: { md: "620px", sm: '520px', xs: "auto" }, borderRadius: "8px",
        border: { xs: "", sm: "1px solid #32D583" },
        background: { xs: "transparent", sm: "#F6FEF9" },
        p: '32px 16px', textAlign: "center", margin: "auto",
      }}>

        <img src={checkImage} />
        <Typography variant='h5' fontWeight={"500"} color={"#0D804A"} sx={{ m: "8px" }}>Your deposit of <Typography component={"span"} variant='h5' fontWeight={"600"}>
          {CurrencyFormatterWithDecimal(initialAmt, true, "never", false)}</Typography> has been initiated.</Typography>

        <Typography variant='body2' color={"rgba(29, 41, 57, 0.70)"}>Your money will be invested within 1 working day upon verification.</Typography>

      </Box>

      {/* <Box sx={{ mt: "40px" }}>
        <LoadingBtnCustom handleOnClick={() => push(links.home)} btnTitle={"Go to home"} />
      </Box> */}


      {/* 
      <Grid item md={12} xs={12} sx={{ mb: 3, mt: 4 }}>
        <img
          src={checkImage}
          alt={'check'}
          loading="lazy"
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography variant='h5' fontWeight={"500"}>Your investment of <Typography component={"span"} variant='h5' fontWeight={"600"}>
          {CurrencyFormatterWithDecimal(initAmount, true, "never", false)}</Typography> has been initiated.</Typography>
      </Grid>

      <Grid item md={12} xs={12} mt={1}>
        <Typography variant='body1'>Your money will be invested within 1 working day upon verification.</Typography>
      </Grid>

      {refId && <Grid item md={12} xs={12} mt={1} >
        <Typography variant='body1'>Payment reference:<Typography component={"span"} fontWeight={"600"}> {refId}</Typography></Typography>
      </Grid>}

      {transacId && <Grid item md={12} xs={12} mt={1} >
        <Chip sx={{ background: "#EFF8FF", color: "#2E90FA" }}
          label={<Typography variant='body1'>Transaction Id: {transacId}</Typography>} />
      </Grid>}

      <Stack spacing={2} margin={'auto'} mt={2} mb={3}>
        <LoadingButton variant="contained" color='secondary' onClick={() => push(links.home)}>Go to Dashboard</LoadingButton>
      </Stack> */}
    </>
  )
}

export default SuccessScreen