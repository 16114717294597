import { Box, Container, Grid } from '@mui/material'
import React, { useState } from 'react'
import PersonalDetailContainer from './personalDetailContainer'
import NextOfKin from '../NextOfKin'
import UpgradeCard from '../Upgrade'
import "./style.css"
import { useAppSelector } from 'hooks/reduxHooks'
import { FatcaShowStatus } from '../../../constants/index'
import FatcaCrsDetails from '../FatcaAndCRss'

const Index = () => {

  const [borderHighlight, setBorderHighlight] = useState(false)
  const { userAccountDetails } = useAppSelector(state => state.auth);
  const statusEnum: any = userAccountDetails?.kyc == null ? "Default" : userAccountDetails?.kyc?.status;
  const isFatcaAndCrs:any = userAccountDetails?.fatcaCrs;

  const findFatcaStatus = FatcaShowStatus?.find((status: any) => status?.toLowerCase() === statusEnum?.toLowerCase())

  return (
    <>
      <Grid container sx={{ p: { xs: "24px 16px", md: "30px 32px" } }}>
        <Container disableGutters maxWidth="lg" sx={{ px: 0, ml: 0 }}>
          <PersonalDetailContainer />
        </Container>
      </Grid>

      {
        isFatcaAndCrs && findFatcaStatus &&
        <>
          <Box sx={{ height: "20px", width: "100%", backgroundColor: "#F8FAFB" }} />

          <Grid container sx={{ p: { xs: "24px 16px", md: "30px 32px" } }}>
            <Container disableGutters maxWidth="lg" sx={{ px: 0, ml: 0 }}>
              <FatcaCrsDetails fatcaCrsDetails={isFatcaAndCrs} />
            </Container>
          </Grid>
        </>
      }

      <Box sx={{ height: "20px", width: "100%", backgroundColor: "#F8FAFB" }} />

      <Grid container sx={{ p: { xs: "24px 16px", md: "30px 32px" } }}>
        <Container disableGutters maxWidth="lg" sx={{ px: 0, ml: 0 }}>
          <NextOfKin />
        </Container>
      </Grid>

      <Box sx={{ height: "20px", width: "100%", backgroundColor: "#F8FAFB" }} />

      <Grid container className={borderHighlight ? "gradient-border" : ""} sx={{ p: { xs: "24px 16px", md: "30px 32px" } }}>
        <Container disableGutters maxWidth="lg" sx={{ px: 0, ml: 0 }}>
          <UpgradeCard setBorderHighlight={setBorderHighlight} borderHighlight={borderHighlight} />
        </Container>
      </Grid>
    </>
  )
}

export default Index