

import { Box } from '@mui/material'
import NoData from 'Latest/Illustration/noData'
import React, { useEffect, useState } from 'react'
import { PerformanceAreaChart } from 'Latest/ChartV1/performanceAreaChart'
import Big from 'big.js'
import { monthlySavingReturnsCalculation } from 'utils/monthlySavingReturnsCalculation'
import { goalCategory, mahaanaSaveRate } from '../../../constants'
import moment from 'moment'
import { onGetCFPerformanceGraph, onGetGIPerformanceGraph } from 'redux/reducers/sharedStateData'
import { useDispatch } from 'react-redux'
import { useAppLoading } from 'hooks/appLoading'
import GoalSlider from './GoalSliderV2'


const PerformanceCard = ({ goalInfo }: any) => {

    const dispatch = useDispatch();
    const loading = useAppLoading('sharedStateData');

    const [apiData, setApiData] = useState<any>()
    const [graphData, setGraphData] = useState<any>([])
    const [paidProjectionPoints, setPaidProjectionPoints] = useState<any>([])

    const retirementAge = 1

    useEffect(() => {
        getDataByContribution();
    }, [])

    const getDataByContribution = () => {
        const onSuccess = (message: string | any, response: any) => {
            const updatedData = message?.contributionsGraph?.map((item: any) => ({
                xAxisVal: moment(item.date, "DD/MM/YYYY").valueOf(),
                date: moment(item.date, "DD/MM/YYYY").format('DD MMM YYYY'),
                data1: item.portfolioValue,
                tooltipTitle: "Merged Portfolio",
            }));
            // setApiData(updatedData)

            handleProjectionPointFunction(updatedData, message)
        }
        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
            setApiData([])
        }

        const payload = {
            goalId: goalInfo.idGoal,
            duration: 10000
        }

        if (goalCategory.CashFund == goalInfo.category) {
            dispatch(onGetCFPerformanceGraph(payload, onSuccess, onFail))
        } else {
            dispatch(onGetGIPerformanceGraph(payload, onSuccess, onFail))
        }

    }

    const handleProjectionPointFunction = (data: any, rawData: any) => {

        const paidArr: any = data

        if (paidArr.length > 0) {
            // Get the last object in the array
            const lastObject = paidArr[paidArr?.length - 1];
            // Add a new property to the last object
            // lastObject.data2 = lastObject.data1;

        } else {
            console.log('The array is empty.');
        }

        const retirementAgeMonth = rawData.durationMonths
        // const retirementAgeMonth = rawData.durationMonths < 24 ? 25 : rawData.durationMonths

        const monthlyContribution = 0
        // const monthlyContribution = rawData.periodicContribution
        const InvestedAmt = Number(paidArr[paidArr?.length - 1]?.data1)

        handleGraphDataFunc(retirementAgeMonth, monthlyContribution, InvestedAmt, paidArr, rawData)

    }

    const handleGraphDataFunc = (numberOfPayments: number, monthlySavingValue: number, InvestedAmt: number, paidArr: any, rawData: any) => {

        if (InvestedAmt > 0) {
            const data: any = paidArr;
            // const data: any = testingData3;
            const newData: any = [];
            const months = numberOfPayments;
            const saveRate = goalCategory?.CashFund == goalInfo?.category ? mahaanaSaveRate : new Big(rawData?.returnPercentage || 1).div(100);

            let startDate = moment(rawData?.contributionsGraph[0]?.date, 'DD/MM/YYYY').toDate(); // Start date set to "19 April 2023"
            const initValue = new Big(rawData?.contributionsGraph[0]?.contributionAmount || 0);
            let amount = initValue;

            newData.push({
                xAxisVal: moment(startDate).valueOf(),
                date: moment(startDate).format('DD MMM YYYY'),
                data2: Number(amount.toFixed(2)),
                tooltipTitle: "Merged Portfolio",
            });

            const endOfMonth = moment(startDate).endOf('month').toDate();
            const currentDate = moment(startDate).add(1, "day").toDate();

            // amount = monthlySavingReturnsCalculation(amount, monthlySavingValue, saveRate);

            while (currentDate <= endOfMonth) {

                newData.push({
                    xAxisVal: moment(currentDate).valueOf(),
                    date: moment(currentDate).format('DD MMM YYYY'),
                    data2: Number(amount.toFixed(2)),
                    tooltipTitle: "Merged Portfolio",
                });
                currentDate.setDate(currentDate.getDate() + 1); // Increment date by one day
            }

            for (let i = 0; i < months; i++) { // Iterate 11 times to have a total of 12 objects

                startDate = moment(startDate).add(1, 'months').toDate();
                amount = monthlySavingReturnsCalculation(amount, monthlySavingValue, saveRate);

                const totalDaysInMonth = moment(startDate).daysInMonth();

                for (let day = 1; day <= totalDaysInMonth; day++) {
                    const currentDate = moment(startDate).date(day).toDate();

                    newData.push({
                        xAxisVal: moment(currentDate).valueOf(),
                        date: moment(currentDate).format('DD MMM YYYY'), // Format the date as desired
                        data2: Number(amount.toFixed(2)), // Assuming you want to round to 2 decimal places
                        tooltipTitle: "Merged Portfolio",
                    });
                }

                // newData.push({
                //     xAxisVal: moment(startDate).valueOf(),
                //     date: moment(startDate).format('DD MMM YYYY'),
                //     data2: Number(amount.toFixed(2)),
                //     tooltipTitle: "Merged Portfolio",
                // });
            }


            const mergedData = data.map((item: any) => {
                const matchingData2 = newData.find((newItem: any) =>
                    moment(newItem.date, 'DD MMM YYYY').isSame(moment(item.date, 'DD MMM YYYY'), 'month')
                );

                return {
                    ...item,
                    data2: matchingData2 ? matchingData2.data2 : null
                };
            });

            // it return all objects which dates are not included in data1 object
            // const remainingNewData = newData.filter((newItem: any) =>
            //     !mergedData.some((mergedItem: any) =>
            //         moment(mergedItem.date, 'DD MMM YYYY').isSame(moment(newItem.date, 'DD MMM YYYY'), 'date')
            //     )
            // );

            const firstDate = moment(rawData?.contributionsGraph[0]?.date, 'DD/MM/YYYY');
            const endDate = firstDate.clone().add(numberOfPayments, 'months');
            const formattedEndDate = endDate.format('DD MMM YYYY');
            const lastDataDate = moment(data[data.length - 1].date, 'DD MMM YYYY');

            const remainingNewData = newData.filter((newItem: any) =>
                moment(newItem.date, 'DD MMM YYYY').isAfter(lastDataDate, 'day') &&
                moment(newItem.date, 'DD MMM YYYY').isBefore(formattedEndDate, 'day')
            );

            const finalMergedData = mergedData.concat(remainingNewData);

            setGraphData(finalMergedData);
            return amount;
        }



    };
   
    const lastWithData2 = graphData.slice().reverse().find((obj: any) => obj.data2 !== undefined && obj.data1 !== undefined);
    const isUserOnTrack = lastWithData2?.data1 >= lastWithData2?.data2 ? true : false;

    return (
        <>
            {goalInfo.category === "GeneralInvestment" && <GoalSlider goalInfo={goalInfo} userOnTrack={isUserOnTrack} />}

            <Box sx={{ pt: "20px", display: "flex", alignItems: "center" }}>
                {graphData?.length > 3 ? graphData &&
                    <PerformanceAreaChart data={graphData} retirementAge={retirementAge} gradient={"#422E84"} />
                    :
                    <NoData loading={loading} message={"Your graph will be shown 3 days after a transaction is verified"} />
                }
            </Box>
        </>

    )
}

export default PerformanceCard