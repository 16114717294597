import React from 'react';

const PerformanceProgressBar = () => {
  return (
    <div
      className='progress rounded-0'
      style={{ height: '20px', width: '100%' }}
    >
      <div
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={255}
        className='progress-bar bg-success rounded-0'
        role='progressbar'
      >
        12.9%
      </div>
    </div>
  );
};

export default PerformanceProgressBar;
