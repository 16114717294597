import 'bootstrap-daterangepicker/daterangepicker.css';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { links } from 'static/links';
import CompanyFundsInfo from './CompanyFundsInfo';
import CompanyInfo from './CompanyInfo';
import PerformanceProgressBar from './PerformanceProgressBar';

const CompanyProfile = () => {
  return (
    <>
      <div className='page-titles mt-1'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item active'>
            <Link to={links.thematic}>Invest in themes</Link>
          </li>
          <li className='breadcrumb-item'>
            <span>Theme Details</span>
          </li>
        </ol>
      </div>

      <div className='d-flex flex-wrap mb-3'>
        <h4 className='fs-24 text-black mr-auto font-w600 mb-3'>
          Theme Details
        </h4>
      </div>
      <div className='row'>
        <div className='col-xl-3 col-xxl-4'>
          <CompanyInfo />
        </div>
        <div className='col-xl-9 col-xxl-8'>
          <CompanyFundsInfo />

          <Row>
            <Col sm={7}>
              <Card className='pb-2' style={{ height: 'auto' }}>
                <Card.Header className='pb-0 border-0'>
                  <h4 className='mb-0 text-black fs-20'>
                    Detailed quote information
                  </h4>
                </Card.Header>
                <Card.Body className='pb-0'>
                  <Row>
                    <Col sm={6}>
                      <div
                        className='table-responsive'
                        style={{ overflowX: 'hidden' }}
                      >
                        <table className='table overflow-hidden'>
                          <tbody>
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                Open
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                High
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>{' '}
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                Low
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>{' '}
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                Prev close
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>{' '}
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                52 Wk high
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>{' '}
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                52 Wk Low
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>{' '}
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                Average Volume
                              </th>
                              <td className='text-right text-primary'>12.02</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div
                        className='table-responsive'
                        style={{ overflowX: 'hidden' }}
                      >
                        <table className='table '>
                          <tbody>
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                Bid (Size)
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                Ask (Size)
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>{' '}
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                Outstanding Shares
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>{' '}
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                Market Cap
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>{' '}
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                EPS
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>{' '}
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                P/E ratio
                              </th>
                              <td className='text-right'>12.02</td>
                            </tr>{' '}
                            <tr>
                              <th style={{ color: '#000', fontWeight: 600 }}>
                                Div yield
                              </th>
                              <td className='text-right text-primary'>12.02</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={5}>
              <Card className='pb-2' style={{ height: 'auto' }}>
                <Card.Header className='pb-0 border-0'>
                  <h4 className='mb-0 text-black fs-20'>Performance</h4>
                </Card.Header>
                <Card.Body className='pb-0'>
                  <div className='table-responsive'>
                    <table className='table table-borderless'>
                      <tbody>
                        <tr>
                          <td>1 Week</td>
                          <td className='text-right' >
                            {/* <PerformanceProgressBar /> */}
                            <ProgressBar label={`${'60'}%`} now={45} style={{ height: '1.3rem', width: '100%', color: 'green' }} variant="success" />
                          </td>
                        </tr>
                        <tr>
                          <td>1 Month</td>
                          <td className='text-right' width='170px'>
                            <PerformanceProgressBar />
                          </td>
                        </tr>
                        <tr>
                          <td>3 Month</td>
                          <td className='text-right' width='170px'>
                            <PerformanceProgressBar />
                          </td>
                        </tr>
                        <tr>
                          <td>1 Year</td>
                          <td className='text-right' width='170px'>
                            <PerformanceProgressBar />
                          </td>
                        </tr>
                        <tr>
                          <td>5 Year</td>
                          <td className='text-right' width='170px'>
                            <PerformanceProgressBar />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
