import { Alert, AlertTitle, Box, Button, IconButton, Typography } from '@mui/material';
import useStylesAlertWarning from 'Latest/ComponentV1/CustomMakeStyle/customWarningAlert';
import { useAppSelector } from 'hooks/reduxHooks';
import React from 'react'
import { useHistory } from 'react-router-dom';
import IconWarning from "Latest/Images/svg/IconWarning.svg"


const TierKycWarningAlert = ({ handleClose }: any) => {

    const classes = useStylesAlertWarning();
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const { push } = useHistory();

    return (
        <>
            <Box>
                <Alert severity="warning" className={classes.customAlert} sx={{ position: "relative" }} onClose={handleClose} icon={false} >
                    <AlertTitle className={classes.customAlertTitle} sx={{ fontWeight: 500 }}>
                        <img src={IconWarning} />
                        Edit your upgrade submission
                    </AlertTitle>
                    {userAccountDetails?.kyc?.comment && <Typography className={classes.customText}>{userAccountDetails?.kyc?.comment}</Typography>}

                    <Button
                        className={classes.customButton}
                        sx={{ borderRadius: "100px", height: "37px", textTransform: "unset", fontWeight: 500, }} 
                        onClick={() => push("myprofile?scroll=true")}>Update submission</Button>

                    {/* <Button color="warning" variant="contained" sx={{ mt: 1 }} onClick={() => push("myprofile?scroll=true")}>Update</Button> */}
                </Alert>
            </Box>
        </>
    )
}

export default TierKycWarningAlert