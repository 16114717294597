import { date } from "yup";

export const getDateDMYFormat = (time: string, divider = '/') => {
  const date = new Date(time);
  return `${String(date.getDate()).padStart(2, '0')}${divider}${String(
    date.getMonth() + 1
  ).padStart(2, '0')}${divider}${String(date.getFullYear()).padStart(2, '0')}`;
};

export const getDateYMDFormat = (time: string, divider = '-') => {
  if (time?.length) {
    const date = new Date(time);
    return `${String(date.getFullYear()).padStart(2, '0')}${divider}${String(
      date.getMonth() + 1
    ).padStart(2, '0')}${divider}${String(date.getDate()).padStart(2, '0')}`;
  }
  return '';
};

export const getFromYmdNumToDmyFormat = (time: string, divider = '-') => {

  const timeStr = time?.toString();
  if (timeStr?.length == 8) {
    const date = new Date(Number(timeStr.substring(0, 4)), Number(timeStr.substring(4, 6)) - 1, Number(timeStr.substring(6, 8)));
    return getDateDMYFormat(date.toString());
  } else {
    return timeStr;
  }
  return '';
};

export const getFromDmyNumToDmyFormat = (time: string, divider = '-') => {
  const timeStr = time?.toString();
  if (timeStr?.length == 8) {
    const date = `${Number(timeStr.substring(2, 4))} ${Number(timeStr.substring(0, 2))}   ${Number(timeStr.substring(4, 8))}`;
    return getDateDMYFormat(date.toString());
  } else {
    return timeStr;
  }
  return '';
};

export const getFormattedDate = (time: string, divider: string) => {
  if (time?.length) {
    const splitTime: Array<any> = time.split(divider);
    if (`${splitTime[2]}` == "0001") {
      return '';
    }
    return splitTime[2] + "-" + splitTime[1] + "-" + splitTime[0];
  }
  return '';
};

export const minus18Date = () => {
  const date = new Date();
  date.setDate(date.getDate() - 6);
  date.setFullYear(date.getFullYear() - 18);
  return date;
  // return new Date(date * 1000);
}

export const stringDateInput = () => {
  const x = minus18Date();
  const year = new Date(x).getFullYear();
  let month = Number(new Date().getMonth()) + 1;
  let date = Number(new Date().getDate());
  if (date < 10) {
    date = 10
  }
  if (month < 10) {
    month = Number(`0${month}`)
  }
  return `${year}-${month}-${date}`
}

export const minus2Date = () => {
  const date = new Date();
  date.setDate(date.getDate() - 6);
  date.setFullYear(date.getFullYear() - 2);
  return date;
  // return new Date(date * 1000);
}

export const stringDateInputCnicIssuance = () => {
  const x = minus2Date();
  const year = new Date(x).getFullYear();
  let month = Number(new Date().getMonth()) + 1;
  let date = Number(new Date().getDate());
  if (date < 10) {
    date = 10
  }
  if (month < 10) {
    month = Number(`0${month}`)
  }
  return `${year}-${month}-${date}`
}