export const cryptoGraphyString = (data: string) => {
    return new Promise<string>((resolve, reject) => {
        const encoder = new TextEncoder();
        const buffer = encoder.encode(data);

        crypto.subtle.digest('SHA-1', buffer)
            .then(hashBuffer => {
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashString = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
                resolve(hashString);
            })
            .catch(error => {
                console.error('Error calculating SHA-512 hash:', error);
                reject(error);
            });
    });
};