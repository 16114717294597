import { Box, Grid, Typography } from "@mui/material"
import { reasonSelected } from "../../../constants/index";
import { cnicViewStyle } from "Latest/MyProfileV1/Style/style";

const FatcaCrsDetails = ({ fatcaCrsDetails }: { fatcaCrsDetails: any }) => {

    return (
        <>
            <Grid item xs={12} md={12}  >
                <Grid item md={12} xs={12} >
                    <Typography variant='h4' sx={{ ...cnicViewStyle.title }}>FATCA</Typography>
                </Grid>


                <Grid item md={12} xs={12} >
                    <Typography variant='body2'> The Foreign Account Tax Compliance Act (FATCA) requires foreign financial institutions to report U.S. taxpayers' financial accounts to the IRS.</Typography>
                </Grid>

                <Grid item md={12} xs={12} sx={{ mt: "30px" }}>
                    <Grid container spacing={"20px"}>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'> Are you a U.S. Resident?* </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {fatcaCrsDetails?.resident || "-"} </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'>  Are you holding a U.S. Permanent Resident Card (Green Card)?* </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {fatcaCrsDetails?.permanentResident || "-"} </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'> Are you a U.S. Citizen?* </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {fatcaCrsDetails?.citizen || "-"} </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'>  Are you registered in the U.S. as a tax payer?* </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {fatcaCrsDetails?.taxPayer || "-"} </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12} xs={12} >
                    <Typography variant='h4' sx={{ ...cnicViewStyle.title , mt: "30px" }}>CRS</Typography>
                </Grid>


                <Grid item md={12} xs={12} >
                    <Typography variant='body2'> The Common Reporting Standard (CRS) is a global system for the automatic exchange of tax-related financial information</Typography>
                </Grid>

                <Grid item md={12} xs={12} sx={{ mt: "30px" }}>
                    <Grid container spacing={"20px"}>
                        <Grid item md={12} xs={12} >
                            <Typography variant='body2'> Are you a tax resident of any country apart from Pakistan or United States of America? </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {fatcaCrsDetails?.taxResident || "-"} </Typography>
                        </Grid>
                        {
                            fatcaCrsDetails?.taxResident === "Yes" &&
                            <>
                                <Grid item md={6} xs={12} >
                                    <Typography variant='body2'>  Country </Typography>
                                    <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {fatcaCrsDetails?.country || "-"} </Typography>
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Typography variant='body2'> City </Typography>
                                    <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {fatcaCrsDetails?.city || "-"} </Typography>
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Typography variant='body2'>  Do you have a Taxpayer Identification Number (TIN)? </Typography>
                                    <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {fatcaCrsDetails?.taxpayerIdentified || "-"} </Typography>
                                </Grid>
                                {
                                    fatcaCrsDetails?.taxpayerIdentified === "Yes" &&
                                    <Grid item md={6} xs={12} >
                                        <Typography variant='body2'>  Taxpayer Identification Number (TIN)  </Typography>
                                        <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {fatcaCrsDetails?.tin || "-"} </Typography>
                                    </Grid>
                                }

                                {
                                    fatcaCrsDetails?.taxpayerIdentified === "No" &&
                                    <Grid item md={6} xs={12} >
                                        <Typography variant='body2'>  Reason Selected </Typography>
                                        <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {reasonSelected[fatcaCrsDetails?.reason]?.reason || "-"} </Typography>
                                    </Grid>
                                }

                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default FatcaCrsDetails