import { memo, useState } from 'react';
import Alert from '@mui/material/Alert';
import BorderButton from '../Border Button/BorderButton';
import axios from 'axios';
import { LocalUrl } from 'redux/middlewares/api';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import DeleteConfirmation from '../Shared/DeleteConfirmation';
import TableMUI from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { GoalNameEnum, GoalValueEnum } from '../../../constants';
import { useAppLoading } from 'hooks/appLoading';
import Loader from '../Shared/Loader';
import { deleteInvestorGoal, getInvestorGoals } from 'redux/actions';
import './styles/table.scss';
import { CurrencyFormatter } from 'utils/calculation';
import { useDispatch } from 'react-redux';
import { goalIdAction } from "redux/reducers/portfolio";
import { USER_STATUS } from 'constants/globalVariables';

const GoalsList = (props: { setIsAddGoalModalOpen: any }) => {
  const dispatch = useAppDispatch();
  const loading = useAppLoading('GoalsList');
  const { user, userAccountDetails } = useAppSelector(state => state.auth);
  const { investorGoal } = useAppSelector(state => state.portfolio);
  const { goalList } = useAppSelector(state => state.sharedData);

  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [goalId, setGoalId] = useState<any>();

  const deleteHandle = async () => {
    setDeleteModalVisible(false);
    dispatch(deleteInvestorGoal(goalId));
    dispatch(getInvestorGoals(user?.id));
  }

  const openModal = () => {
    props.setIsAddGoalModalOpen(true)
    dispatch(goalIdAction(""))
  }

  if (loading)
    return (
      <div className='text-center p-5'>
        <Loader width={200} />
      </div>
    );
  return (
    <>
      {isDeleteModalVisible && <DeleteConfirmation deleteConfirm={isDeleteModalVisible} handleDeleteCancel={() => setDeleteModalVisible(false)} handleDeleteConfirm={() => deleteHandle()} />}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'light' ?
            theme.palette.grey[100] : theme.palette.grey[900], flexGrow: 1
        }}>
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4, }}>
          <p style={{ fontFamily: 'PlayFair', fontWeight: '700', fontSize: '28px', color: 'black' }}>
            Goals Overview
          </p>

          {userAccountDetails?.userStatus == USER_STATUS.JS_ACCOUNT_OPENED &&
            <Alert sx={{ background: 'rgb(153 224 254)', color: "black", mb: 4 }} >
              Your JS account is on pending !
            </Alert>
          }

          <Grid container spacing={3}>
            <Grid item sx={{ display: 'flex', width: '100%' }} xs={12} >
              <TableMUI className='cardanimation'>
                <TableHead >
                  <TableRow>
                    <TableCell className='table_header'>Goal Type</TableCell>
                    <TableCell className='table_header'>Saving Scheme</TableCell>
                    <TableCell className='table_header'>Duration</TableCell>
                    <TableCell className='table_header'>Amount (PKR)</TableCell>

                    {userAccountDetails?.userStatus == USER_STATUS.JS_ACCOUNT_OPENED && <TableCell className='table_header'>Status</TableCell>}

                    {/* {!userAccountInfo?.isNewUser && */}
                    <TableCell align="center">
                      <BorderButton ClickHandler={openModal} Icon={'fa fa-plus fa-1x'} IsBorder={false} Text={"Add Goal"} />
                    </TableCell>
                    {/* } */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {goalList?.length
                    ?
                    goalList?.map((val: any, index: any) => {
                      return (
                        <>
                          <TableRow key={index} style={{ color: '#363636' }}>
                            <TableCell className='table_text'>
                              <span style={{}}>
                                {getGoalName(val.goalType)}
                              </span>
                            </TableCell>
                            <TableCell className='table_text'>
                              {val.savingScheme}
                            </TableCell>
                            <TableCell className='table_text'>
                              {val.duration}
                            </TableCell>
                            <TableCell className='table_text'>
                              <span style={{ color: '#49BDD8' }}>
                                {CurrencyFormatter(val.initialAmount, false)}
                              </span>
                            </TableCell>

                            {userAccountDetails?.userStatus == USER_STATUS.JS_ACCOUNT_OPENED && <TableCell className='table_text'>
                              <span style={{ background: 'rgb(153 224 254)', color: "black", padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 10 }}>
                                Pending
                              </span>
                            </TableCell>
                            }

                            <TableCell >
                              {/* <span style={{ display: 'flex', background: '', justifyContent: 'center' }}>
                              <i className='fa fa-trash fa-1x'
                                onClick={e => { setDeleteModalVisible(true); setGoalId(val.id) }}
                                />
                                </span> */}
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })
                    :
                    <TableCell colSpan={4}>
                      <p style={{ textAlign: 'center' }}>
                        There are no goals yet.
                      </p>
                    </TableCell>
                  }
                </TableBody>
              </TableMUI>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default memo(GoalsList);

const getGoalName = (goalValue: string) => {
  switch (goalValue) {
    case GoalValueEnum.Vps:
      return GoalNameEnum.Vps;
    case GoalValueEnum.Ops:
      return GoalNameEnum.Ops;
    case GoalValueEnum.HouseBuy:
      return GoalNameEnum.HouseBuy;
    case GoalValueEnum.CollegeFee:
      return GoalNameEnum.CollegeFee;
    case GoalValueEnum.WindFallReceive:
      return GoalNameEnum.WindFallReceive;
    case GoalValueEnum.TravelTime:
      return GoalNameEnum.TravelTime;
    case GoalValueEnum.LargeExpense:
      return GoalNameEnum.LargeExpense;
    default:
      return goalValue;
      break;
  }
}

