import axios from "axios";
import { AnswerFieldType } from "../../constants";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import DeleteModal from "jsx/components/DeleteModal/DeleteModal";
import EditModal from "jsx/components/EditModal/EditModal";
import TableHead from "jsx/components/FundsOverview/TableHead";
import Loader from "jsx/components/Shared/Loader";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row, Table, } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { toast, ToastContainer } from "react-toastify";
import { onGetQuestionAnswers, onGetQuestionAnswerViewModel, onPostQuestionAnswers } from "redux/actions";
import { LocalUrl } from "redux/middlewares/api";
import { links } from 'static/links';
import './index.css'

const answerOptionsInitialValue = {
  id: 0,
  score: 0,
  label: "",
  value: "",
  answer: null,
};

const inputSubArr = [
  {
    label: "",
    id: "0",
    value: "",
    // score: ""
  }
]
const AdminForm = () => {
  const answerInitialValue = {
    answerOptions: [
      {
        id: 0,
        score: 0,
        label: "",
        value: "",
        answer: null,
      }
    ],
    answerFieldType: 0
  }
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { questionAnswerViewModel } = useAppSelector(state => state.questionAnswer);
  const [answerOptions, setAnswerOptions]: any = useState(answerOptionsInitialValue);
  const [subAnswerOptions, setSubAnswerOptions] = useState(inputSubArr);
  const [getAnswers, setGetAnswers] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState<any>(answerInitialValue);
  const [score, setScore] = useState(0);
  const [regex, setRegex] = useState('');
  const [pattern, setPattern] = useState('');
  const [questionID, setQuestionID] = useState('');
  const [answerFieldType, setAnswerFieldType] = useState<any>('');
  const [category, setCategory] = useState<any>('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [flag, setFlag] = useState(0);
  const [counter, setCounter] = useState(0);

  const addInput = () => {
    const answer_: any = { ...answer };
    answer_.answerOptions.push(
      answerOptionsInitialValue
    )
  };

  const addSubInput = (index: number) => {
    const answer_: any = { ...answer };
    const item = answer_.answerOptions[index];
    if (!item["answer"]) {
      item["answer"] = {
        answerOptions: []
      }
    }
    item["answer"]?.answerOptions.push(answerOptionsInitialValue);
    setAnswer(answer_);
  };

  const handleOptionChange = (e: any) => {
    e.preventDefault();
    const index = e.target.id;
    const answer_: any = { ...answer };
    answer_.answerOptions[index] = {
      ...answer_.answerOptions[index],
      value: e.target.value,
      label: e.target.value,
      id: 0
    }
    setAnswer(answer_);
  };

  const handleScoreChange = (e: any) => {
    e.preventDefault();
    const index = e.target.id;
    const answer_: any = { ...answer };
    answer_.answerOptions[index] = {
      ...answer_.answerOptions[index],
      score: Number(e.target.value),
    }
    setAnswer(answer_);
  };

  const AddSubQustions = (e: any) => {
    // e.preventDefault();
    const index = e;
    const demo = {
      id: 0,
      label: 'demo',
      value: 'demoo'
    }
    setAnswerOptions((s: any) => {
      const newArr = s.slice();
      newArr[index].answerOptions = demo;
      // newArr.push(demo)
      return newArr;
    });
  }

  const fetchData = async () => {
    dispatch(onGetQuestionAnswerViewModel());
    dispatch(onGetQuestionAnswers(2));
    dispatch(onGetQuestionAnswers(3));
  }

  useEffect(() => {
    fetchData();
    setFlag(0)
  }, [flag, dispatch])

  const ModalOpen = (id: any) => {
    setShow(true)
    setQuestionID(id)
  }

  const DeleteModalOpen = (id: any) => {
    setDeleteModal(true)
    setQuestionID(id)
  }

  const submitHandler = async () => {
    /*
        CATEGORIES:
            SignUp = 1
            RiskProfile = 2
            AccountSetup = 3
            Payment = 4
        --------------------
        ANSWER_FIELD_TYPES:
            TextBox = 1,
            RadioButton = 2,
            CheckBox = 3,
            DropDownSelection = 4,
    */
    const body: any = {
      question,
      category,
      regex: null,
      pattern: null,
      errorCode: "101",
      answer
    };
    // if (answerFieldType === AnswerFieldType.TextBox) {
    //   body["answer"] = {
    //     "answerOptions": [
    //       {
    //         "score": Number(score),
    //       }
    //     ],
    //     "answerFieldType": answerFieldType.toString()
    //   }
    // } else {
    //   body["answer"] = {
    //     answerOptions,
    //     "answerFieldType": answerFieldType.toString()
    //   }
    // }

    try {
      // dispatch(onPostQuestionAnswers(body));
      // history.push(links.adminForm)
    } catch (error) {
      toast.error('An Error Occured',{theme: "colored"})
    }
  }

  const handleClick = () => {
    setCounter(counter + 1);
  };

  const handleOnChange = (e: any, index1: number, index2: number) => {
    const tempArray = [...answerOptions]
    const tempArraykey = answerOptions[index1].answer?.answerOptions[index2];
    if (e.target.name === 'value') {
      tempArraykey['value'] = e.target.value;
      tempArraykey['label'] = e.target.value;
    } else {
      tempArraykey['score'] = e.target.value;
    }
    setAnswerOptions(tempArray);
  };

  return (
    <Container fluid >
      <h1 style={{ textAlign: 'center' }}>
        ADD QUESTION
      </h1>
      {show === true &&
        <EditModal DropDownMoadl={questionAnswerViewModel} flag={setFlag} id={questionID} openModal={show} setOpenModal={setShow} />}
      {deleteModal === true &&
        <DeleteModal id={questionID} openModal={deleteModal} setOpenModal={setDeleteModal} />}
      <Card className="mb-3" style={{ display: 'flex', flexDirection: 'column' }}>
        <Card.Body>
          <Row>
            <Col className="inputfield" md="4" style={{ paddingRight: '3%', marginTop: '0%' }}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Select Category
              </Form.Label>
              <Select
                className="TextBoxes"
                onChange={(e: any) => setCategory(e.value)}
                options={questionAnswerViewModel?.category}
                placeholder="Select Category"
                value={questionAnswerViewModel?.category?.find((obj: any) => obj === category)}
              />
            </Col>
            <Col md="4">
              <Form.Group className="inputfield">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Enter Question
                </Form.Label>
                <input
                  className='form-control h-38 TextBoxes'
                  onChange={(e) => { setQuestion(e.target.value) }}
                  placeholder="Enter Question"
                  style={{ textAlign: 'left' }}
                  value={question}
                />
              </Form.Group>
            </Col>
            {/* <Col md="1" /> */}
            <ToastContainer />
            <Col md="4" style={{ paddingRight: '3%', marginTop: '0%' }}>
              <Form.Group className="inputfield" style={{}}>
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Select Answer Field Type
                </Form.Label>
                <Select
                  className="TextBoxs"
                  onChange={(e: any) => setAnswer({
                    ...answer,
                    answerFieldType: Number(e.value)
                  })}
                  options={questionAnswerViewModel?.answerFieldType}
                  placeholder="Select Answer Field Type"
                  value={questionAnswerViewModel?.answerFieldType?.find((obj: any) => obj === answerFieldType)}
                />
              </Form.Group>
            </Col>
            {(answer?.answerFieldType != AnswerFieldType.TextBox && answer?.answerFieldType != 0) &&
              <Col md="12">
                <Row>
                  <Col md="2">
                    <div className='mr-3 icon-cont text-primary' onClick={addInput}>
                      <span className='fa fa-plus fa-lg' />
                    </div>
                  </Col>
                  <Col md="10">
                    {answer.answerOptions?.map((item: any, index: number) => {
                      return (
                        <div key={index}>
                          <Row>
                            <Col md="6" style={{ marginTop: '0%' }}>
                              <Form.Label style={{ fontWeight: 'bold' }}>
                                Enter Options
                              </Form.Label>
                              <Form.Control
                                className="TextBoxes"
                                id={index.toString()}
                                name="value"
                                onChange={handleOptionChange}
                                placeholder="Type Options Here"
                                style={{}}
                                value={item.value}
                              />
                            </Col>
                            <Col md="4" style={{ marginTop: '0%' }}>
                              <Form.Label style={{ fontWeight: 'bold' }}>
                                Enter Score
                              </Form.Label>
                              <Form.Control
                                className="TextBoxes"
                                id={index.toString()}
                                name="score"
                                onChange={handleScoreChange}
                                placeholder="Type Options Here"
                                style={{}}
                                value={item.score}
                              />
                            </Col>
                            <Col md="2">
                              <div style={{ backgroundColor: 'white', textAlign: 'end', marginTop: '25%' }}>
                                <button onClick={(id) => addSubInput(index)} style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>Add Sub Answers</button>
                              </div>
                            </Col>
                          </Row>
                          {item?.answer?.answerOptions.length > 0 &&
                            <Row>
                              <Col md="4" style={{ marginTop: '0%' }} />
                              <Col md="4" style={{ marginTop: '0%' }}>
                                <Form.Group className="inputfield" style={{}}>
                                  <Form.Label style={{ fontWeight: 'bold' }}>
                                    Select Sub Answer Field Type
                                  </Form.Label>
                                  <Select
                                    className="TextBoxs"
                                    onChange={(e: any) => setAnswerFieldType(e.value)}
                                    options={questionAnswerViewModel?.answerFieldType}
                                    placeholder="Select Answer Field Type"
                                    value={questionAnswerViewModel?.answerFieldType?.find((obj: any) => obj === answerFieldType)}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md="4" style={{ marginTop: '0%' }} />
                            </Row>
                          }
                          <Row>
                            <Col md="4" style={{ marginTop: '0%' }} />
                            <Col md="6" style={{ marginTop: '0%' }}>
                              <div className="App">
                                {item?.answer?.answerOptions?.map((c: any, index_: any) => {
                                  return (
                                    <Row key={index_}>
                                      <Col md="6" style={{ marginTop: '0%' }}>
                                        <Form.Label>
                                          Sub Options
                                        </Form.Label>
                                        <input
                                          className="TextBoxes"
                                          id={index_}
                                          name="value"
                                          onChange={(e) => handleOnChange(e, index, index_)}
                                          type="text"
                                          value={c.value}
                                        />
                                      </Col>
                                      <Col md="6" style={{ marginTop: '0%' }}>
                                        <Form.Label>
                                          Sub Score
                                        </Form.Label>
                                        <input
                                          className="TextBoxes"
                                          id={index_}
                                          name="score"
                                          onChange={(e) => handleOnChange(e, index, index_)}
                                          style={{ marginLeft: '4%' }}
                                          type="text"
                                          value={c.score}
                                        />
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </div>
                            </Col>
                            <Col md="2" style={{ marginTop: '0%' }} />
                          </Row>
                        </div>
                      )
                    })}
                  </Col>
                </Row>
              </Col>
            }
            {answer?.answerFieldType === AnswerFieldType.TextBox &&
              <>
                <Col md="4">
                  <Form.Group className="inputfield">
                    <Form.Label style={{ fontWeight: 'bold' }}>
                      Score
                    </Form.Label>
                    <input
                      className='form-control h-38 TextBoxes'
                      onChange={(e) => { setScore(Number(e.target.value)) }}
                      placeholder="Enter Score"
                      style={{}}
                      value={score}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="inputfield">
                    <Form.Label style={{ fontWeight: 'bold' }}>
                      Regex
                    </Form.Label>
                    <input
                      className='form-control h-38 TextBoxes'
                      onChange={(e) => { setRegex(e.target.value) }}
                      placeholder="Enter Regex"
                      style={{}}
                      value={regex}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="inputfield">
                    <Form.Label style={{ fontWeight: 'bold' }}>
                      Pattern
                    </Form.Label>
                    <input
                      className='form-control h-38 TextBoxes'
                      onChange={(e) => { setPattern(e.target.value) }}
                      placeholder="Enter Pattern"
                      style={{}}
                      value={pattern}
                    />
                  </Form.Group>
                </Col>
              </>
            }
          </Row>
        </Card.Body>
        <button className="buttonDisable"
          onClick={() => {
            submitHandler();
            // setTimeout(() => {
            //   window.location.reload();
            // }, 4000);
          }} style={{ borderRadius: '10rem', height: '2.5rem', width: '20rem', alignSelf: 'center', color: 'white', backgroundColor: '#603AB0', borderColor: 'white', marginBottom: '4%' }}>
          Submit
        </button>
      </Card>
    </Container >
  )
}
export default AdminForm;
export type Field = { label: string; value: string };
