import React from "react";
import { Alert, Button, Card, Col, Container, Form, Row, Table, } from "react-bootstrap";


const ListOfFiles = [
    { fileName: 'abc', status: 'submit' },
    { fileName: 'xyz', status: 'pending' },
    { fileName: 'mparsec', status: 'submit' },
    { fileName: 'Mahaana', status: 'pending' },
    { fileName: 'faizan', status: 'submit' }
]
const TradeFiles = () => {
    return (
        <>
            <h3 className='mb-3'> Upploaded Files</h3>
            <Card>
                <Card.Body>
                    <Row>
                        <Table bordered hover striped >
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        List Of Files
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ListOfFiles.map((val: any, id: number) => {
                                        return <tr key={id} className="RowCursor">
                                            <td>
                                                {id}
                                            </td>
                                            <td>
                                                {val.fileName}
                                            </td>
                                            <td>
                                                {val.status}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" >
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                </svg>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Card.Body>
                <div style={{ textAlign: 'center' }}>
                </div>
            </Card>
        </>
    )
}
export default TradeFiles;