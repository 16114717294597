import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  neutralButton: {
    borderColor: '#D0D5DD!important',
    color: '#344054!important',
    '&:hover': {
      borderColor: '#ccc!important',
      color: '#344054!important',
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)!important"
    },
  },
}));

export default useStyles;