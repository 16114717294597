import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = { error: null };

const name = 'globalErrors';
const globalErrorsSlice = createSlice({
  initialState,
  name,
  reducers: {
    clearError: (state, action: PayloadAction<number>) => {
      if (action.payload === state.error?.id) state.error = null;
    },
    get: (state, action: PayloadAction<ApiError>) => {
      state.error = { ...action.payload, id: new Date().getTime() };
    }
  }
});

export default globalErrorsSlice.reducer;
export const { clearError: onClearError } = globalErrorsSlice.actions;

// ? Types
interface InitialState {
  error: Error | null;
}
interface ApiError {
  message: string;
  statusCode: number;
  statusText: string;
}
interface Error extends ApiError {
  id: number;
}
