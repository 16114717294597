import React from 'react'
import NoData from "../../Illustration/noData"
import GraphSection from 'Latest/DashboardScreen/GoalDashboard/LeftSection/graphSection'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { summaryTab } from '../style/tabsStyle'
import { CurrencyFormatter, CurrencyFormatterWithDecimal } from 'utils/calculation'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { tokens } from 'theme'
import DashboardSummaryGraph from './dashboardSummaryGraph'
import Chip from '@mui/material/Chip';
import CustomChip from 'Latest/ComponentV1/customChip'
import Big from 'big.js'
import formatNumberForDisplay from 'utils/numberDisplay'

const Summary = ({ Data }: any) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const ReturnPercentage = Data && Big(Data?.summary?.simpleReturns || 0).toFixed(2)
  return (
    <>
      <Grid container mb={3}>
        <Grid item xs={12} pt={3} px={2} pb={2} sx={{ background: "#fff" }}>
          <Box mb={{ md: 0, xs: 2 }}>

            <Typography variant='h3' color="secondary" sx={{ lineHeight: "42px" }}>
              {CurrencyFormatterWithDecimal(Data?.summary?.totalPortfolioAmount, true, "never", true, true)}
            </Typography>

            {Data?.summary?.totalGainAndLoss >= 0 ?
              <>
                <Typography variant='subtitle2' component={"sub"} sx={{ color: "#12B76A", fontWeight: 500 }}>
                  <ArrowUpwardIcon sx={{ color: "#12B76A", fontSize: "16px", position: "relative", top: "-1.9px", mr: "2px" }} />
                  <span style={{ marginTop: "10px" }}>

                    {CurrencyFormatterWithDecimal(formatNumberForDisplay(Number(Data?.summary?.totalGainAndLoss)), true, "never", true, true)}
                  </span>
                  {ReturnPercentage > 0 && <span style={{ marginLeft: "10px", }}>
                    <CustomChip label={`${ReturnPercentage} %`} status={"success"} />
                  </span>}
                </Typography>

              </>
              :
              <>
                <Typography variant='h6' component={"sub"} sx={{ color: colors.redAccent[600] }} >
                  <ArrowDownwardIcon sx={{ color: colors.redAccent[600] }} fontSize={"small"} />
                  <span style={{ marginTop: "10px" }}>
                    {CurrencyFormatterWithDecimal(formatNumberForDisplay(Number(Data?.summary?.totalGainAndLoss)), true, "never", true, true)}
                  </span>
                  {/* <span style={{ marginLeft: "10px" }}>
                    <CustomChip label={`${ReturnPercentage} %`} status={"error"} />
                  </span> */}

                </Typography>
              </>
            }
          </Box>

          {/* <NoData /> */}
          <DashboardSummaryGraph Data={Data} />

        </Grid>
      </Grid >
    </>
  )
}

export default Summary