import { Env_Name } from "constants/index";
import { ENVIRONMENT_NAME, MIXPANEL_API_TOKEN_DEV, MIXPANEL_API_TOKEN_PROD } from "constants/settings";
import mixpanel from "mixpanel-browser";


const env_check = ENVIRONMENT_NAME == Env_Name.prod;

mixpanel.init(env_check ? MIXPANEL_API_TOKEN_PROD : MIXPANEL_API_TOKEN_DEV);
console.log(Env_Name.prod ,ENVIRONMENT_NAME)
const actions = {
    identify: (id: string | undefined) => {
        if (true) mixpanel.identify(id);
    },

    alias: (id: string) => {
        if (true) mixpanel.alias(id);
    },

    track: (name: string, props: any) => {
        if (true) mixpanel.track(name, props);
    },

    people: {
        set: (props: any) => {
            if (true) mixpanel.people.set(props);
        },
    },

};

export const Mixpanel = actions;