import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import CustomPieChart from 'Latest/ChartV2/customPieChart';
import CustomScrollerCard from 'Latest/ComponentV1/customScrollerCard'
import NoData from 'Latest/Illustration/noData';
import React, { useEffect, useState } from 'react'
import { onGetDashboardPotAllocation } from 'redux/actions';
import { tokens } from 'theme';
import { savePieColorsHsl } from "../../constants"
import AssetChart from './AssetChart';
import ProductChart from './ProductChart';

const Index = () => {
    const PortfolioColors = ['#403081', '#43BED8', '#F2994A', '#F97066', '#5D9631'];
    const { dashboardDetail, dashboardPotAllocation } = useAppSelector((state: any) => state.dashboard);
    const [portfolioData, setPortfolioData] = React.useState<any>([]);
    const loading = useAppLoading('CFPotAllocation');
    const [loadingState, setLoadingState] = useState(true);
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [activeIndex, setActiveIndex] = useState(-1)
    const colorTheme = tokens(theme.palette.mode);
    const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        Number(dashboardDetail?.goals[0]?.potDetails?.portfolioAmount) > 0 && dispatch(onGetDashboardPotAllocation(Number(dashboardDetail?.goals[0]?.potDetails?.portfolioAmount)))
    }, [])


    useEffect(() => {
        const CashFundPortfolioDataArray = dashboardPotAllocation?.customerEnrolledProduct &&
            dashboardPotAllocation?.customerEnrolledProduct?.map((item: any, index: number) =>
                ({ "name": item?.key, "value": item.value1, "amount": item.value2 })).filter((item: any) => item.value > 0);
        setPortfolioData(CashFundPortfolioDataArray)
        setLoadingState(false)
    }, [dashboardPotAllocation])

    return (
        <Box>

            <Box sx={{ background: 'white', }}>
                <Typography variant='h4' color={colorTheme.purple[100]} lineHeight={"36px"} sx={{ p: { xs: "12px 20px 12px 20px", md: '24px 20px 24px 20px' } }}>Breakdown by asset class</Typography>
                <Box sx={{ py: { xs: "8px", md: 0 } }}><AssetChart /></Box>
            </Box>
            <Box sx={{ background: 'white', mt: { xs: "24px", md: "40px" } }}>
                <Typography variant='h4' color={colorTheme.purple[100]} lineHeight={"36px"} sx={{ p: { xs: "12px 20px 12px 20px", md: '24px 20px 24px 20px' } }}>Breakdown by Product</Typography>
                <Box sx={{ py: { xs: "8px", md: 0 } }}><ProductChart /></Box>
            </Box>
        </Box>
    )
}

export default Index



