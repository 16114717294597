/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Typography, useTheme } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { tokens } from 'theme';

const FormikSelectField = ({
  col = 6,
  disabled = false,
  fieldName,
  fieldSelect = false,
  fields,
  formik,
  isMulti = false,
  label,
  onChange,
  onInputChange,
  placeholder,
  required = false,
  value
}: FormikSelectFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;
  const changeHandle = (option: Field) => onChange?.(option.value, option);

  const handleDefaultValue = (
    fields: Array<Field>,
    value: any,
    isMulti: boolean
  ) => {
    if (value === '') {
      return [];
    }
    if (isMulti) {
      const filteredArray: Array<Field> = [];
      fields?.forEach((item: any, index: any) =>
        value?.forEach((el: string) => {
          if (el === item.value) {
            filteredArray.push(item);
          }
        })
      );
      return filteredArray;
    } else {
      const temp: any = [];
      fields?.forEach((item: any) => {
        if (value === item.value) {
          temp.push(item);
        }
      });
      return temp[0];
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
          ? '#F3F4F6' // Background color for selected option
          : state.isFocused
              ? '#E5E5E5' // Background color for focused (hovered) option
              : 'transparent', // Default background color
      color: state.isSelected ? '#4C4C4C' : '#444',
      // height: 40,
      cursor: "pointer",

  }),
    dropdownIndicator: (provided: any, state: any) => {
      return {
          ...provided,
          color:"#202020"
      };
  },
  };

  return (
    <Grid item xs={12}>
      {label && <Typography sx={{ mb: "6px" }} variant='body2' color={colors.grey[700]}>
        {label}
        {required && <span className='ml-1 text-danger'>*</span>}
      </Typography>
      }

      <Select
        styles={customStyles}
        data-inputmask="'mask': '0399-99999999'"
        defaultValue={handleDefaultValue(
          fields,
          formik.values[fieldName],
          isMulti
        )}
        isDisabled={disabled}
        isMulti={isMulti}
        name={fieldName}
        // @ts-ignore
        onChange={option => option && changeHandle(option)}
        onInputChange={onInputChange}
        options={fields}
        placeholder={placeholder ?? label}
        value={value}
      />
      {formikTouch[fieldName] && formikError[fieldName] && <span className='ml-2 text-danger'>
        {formikError[fieldName]}
      </span>
      }
    </Grid>
  );
};

export default memo(FormikSelectField);

export type Field = { label: string; value: string };
interface FormikSelectFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  defaultValue?: Array<Field>;
  fields: Array<Field>;
  fieldSelect?: boolean;
  formik: {
    setFieldValue: (name: string, value: string | Array<string>) => void;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  isMulti?: boolean;
  label: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (
    option: string,
    optionObject: Record<string, string | number>
  ) => void;
  onInputChange?: (val: string) => void;
  value?: any
}
