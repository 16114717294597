export const specialRadioFieldLabel = {
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "150%",
    color: "#202020",
}

export const specialRadioFieldChildGrid = {
    fontStyle: "normal",
    fontSize: { md: "16px", xs: "14px" },
    lineHeight: "155%",
    color: "#1D2939",
    borderRadius: "8px",
    margin: "0px 8px",
    // border: "1px solid rgba(208, 213, 221, 0.25)",
    background: "#FFF",
    fontWeight: 400,
    boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
}

export const specialRadioFieldChildGridV2 = {
    fontStyle: "normal",
    fontSize: "14px",
    padding: "8px 16px",
    lineHeight: "155%",
    borderRadius: "8px",
    fontWeight: 400,
    boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
}

export const specialRadioFieldChildGridTitle = {
    fontStyle: "normal", fontWeight: 600, fontSize: "16px", lineHeight: "150%", color: "#666666"
}