import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from 'theme';
import depositIcon from "Latest/Images/depositIcon.png"
import withdrawIcon from "Latest/Images/withdrawIcon.png"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { quickLinkStyle } from '../style/quickLinkStyle';
import InvestModal from 'Latest/ModalV2/investModal';
import Withdraw from 'Latest/ModalV2/withDrawModal';
import { CardinfoStyle } from '../style/cardinfoStyle';
import { quickLinksArray } from 'Latest/DashboardScreen/FreshUserScreen/constant';


const Index = ({ Data }: any) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box sx={{ ...CardinfoStyle.itemGridRight ,borderRadius:"4px"}}>
            <Typography variant='h4' color={colors.purple[100]} mb={2} lineHeight={"36px"}>Quick links</Typography>

            {quickLinksArray.map((item: any, index: number) => (
                <>
                    <Box key={index} mb={index == 0 ? 1 : 0}>
                        <item.modalComp Data={Data?.goals[0]} item={item} />
                    </Box>
                    {/* {index == 0 && <Divider />} */}
                </>
            ))}

        </Box>
    )
}

export default Index