import { Container, Box, Grid, Typography, Tooltip, Skeleton, Stack, Button, CircularProgress, useTheme } from '@mui/material'
import { HeadingStyle, containerMain, containerMainBox, subHeadingStyle } from 'Latest/Style/Main'
import FileUploadField from 'jsx/components/Shared/FileUploadField'
import React, { useEffect, useState, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { onGetFrontCnicImg, onGetBackCnicImg } from 'redux/actions';
// import { cnicViewStyle } from '../Style/cnicView';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import LockOpenIcon from '@mui/icons-material/LockOpen';
// import LockIcon from '@mui/icons-material/Lock';
import IconButton from '@mui/material/IconButton';
// import LockInput from './lock';
import CNICUpdateModal from "./UpdateCnic/index"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import { getIconsScaleStyles } from 'Latest/Animation/iconsScale';
import { useAppLoading } from 'hooks/appLoading';
import { cnicViewStyle } from 'Latest/MyProfileV1/Style/style';
import LockInput from 'Latest/MyProfile/IDCardUpdate/lock';
import UploadIcon from "Latest/Images/svg/uploadIcon.svg"
import LockIcon from "Latest/Images/svg/lockIcon.svg"
import LockIconMui from '@mui/icons-material/Lock';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AxiosResponse } from 'axios';


const IDCardGrid = ({ setShowNicGrid, showNicGrid }: any) => {
  const theme = useTheme();
  const { userAccountDetails } = useAppSelector(state => state.auth);
  const [imageSrcFront, setImageSrcFront] = useState("");
  const [imageSrcBack, setImageSrcBack] = useState("");

  const [showUploadBtn, setShowUploadBtn] = useState(false);

  const authLoading = useAppLoading('auth');

  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [updateCnic, setUpdateCnic] = useState(false)

  const dispatch = useAppDispatch();
  const { CnicImgBack, CnicImgFront } = useAppSelector(state => state.auth);

  const documentsData = userAccountDetails?.documents;


  const getLastUserNicFront = (data: any) => {
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].type === "UserNicFront") {
        return data[i];
      }
    }
    return null;
  };

  const lastUserNicFront = getLastUserNicFront(documentsData);

  useEffect(() => {
    getAdminStatus();
  }, [])

  useEffect(() => {
    getAdminStatus();
  }, [userAccountDetails])

  const getAdminStatus = () => {
    if (documentsData?.length) {
      if (lastUserNicFront?.status != "Default") {
        setShowUploadBtn(true);
        setShowNicGrid(true);
      } else {
        setShowUploadBtn(false);
        setShowNicGrid(false)
      }
    } else {
      setShowUploadBtn(true);
      setShowNicGrid(true);
    }
  }

  // GET FUNCTION OF CNIC FRONT / BACK
  useEffect(() => {
    dispatch(onGetFrontCnicImg())
    dispatch(onGetBackCnicImg())
  }, [updateCnic])

  const handleFetchImageFront = async () => {
    const blob = new Blob([CnicImgFront]);
    const objectUrl = URL.createObjectURL(blob);
    setImageSrcFront(objectUrl);
  };

  const handleFetchImageBack = async () => {
    const blob = new Blob([CnicImgBack]);
    const objectUrl = URL.createObjectURL(blob);
    setImageSrcBack(objectUrl);
  };

  useEffect(() => {
    handleFetchImageFront();
    return () => {
      if (imageSrcFront) {
        URL.revokeObjectURL(imageSrcFront);
      }
    };
  }, [CnicImgFront]);

  useEffect(() => {
    handleFetchImageBack();
    return () => {
      if (imageSrcBack) {
        URL.revokeObjectURL(imageSrcBack);
      }
    };
  }, [CnicImgBack,]);

  const handleUnlock = () => {
    setDisabled(false);
    setChecked(false);
    setShowNicGrid(false)
  }

  const handleShowGrid = () => {
    setChecked(!checked);
    setDisabled(true);
    setShowNicGrid(true)
  }

  const handleClose = () => {
    setOpenModal(false);
  }

  return (
    <>
      <Grid item md={12} xs={12}>

        {showNicGrid ?
          <>
            {(showUploadBtn ) ?
              <Grid>
                <CNICUpdateModal setUpdateCnic={setUpdateCnic} updateCnic={updateCnic} />
              </Grid>
              :
              <Container disableGutters maxWidth={"lg"} sx={{ ml: 0, px: 0 }}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant='h4' sx={{ ...cnicViewStyle.title }}>Identity Card</Typography>
                  <Button sx={{ ...cnicViewStyle.approvalBtn }} onClick={handleUnlock}>
                    {smScreen ? <LockIconMui sx={{ color: "white" }} /> : "Lock CNIC"}
                  </Button>
                </Grid>

                <Grid item md={12} xs={12} sx={cnicViewStyle.cnicGrid} >
                  <Box sx={{ ...cnicViewStyle.imgBox, background: "#FFFFFF" }}>
                    <Zoom> <img src={imageSrcFront} alt="Front Image" style={{ maxWidth: "15rem" }} /></Zoom>
                  </Box>

                  <Box sx={{ ...cnicViewStyle.imgBox, background: "#FFFFFF" }}>
                    <Zoom>  <img src={imageSrcBack} alt="Back Image" style={{ maxWidth: "15rem" }} /></Zoom>
                  </Box>
                </Grid>
              </Container>
            }
          </>
          :
          <Container disableGutters maxWidth={"lg"} sx={{ ml: 0, px: 0 }}>
            <Grid item md={12} xs={12}>
                {authLoading ?
                <Box sx={{ ...cnicViewStyle.imgBox, ...cnicViewStyle.cnicLockBox, border: "none", p: 0 }}>
                  <LoadingSkeleton />
                  </Box>
                  :
                  <Box sx={{...cnicViewStyle.cnicLockImage }}>
                    <img src={LockIcon} alt={""} onClick={handleShowGrid} style={{ cursor: "pointer", marginTop: "24px"}}/>
                    <Typography variant='body2' sx={{ mt: "24px" }} onClick={handleShowGrid} >Click to unlock your CNIC image to view!</Typography>
                  </Box>
                }
            </Grid>
          </Container>
        }

      </Grid >


    </>
  )
}

export default IDCardGrid

const LoadingSkeleton = () => {
  return (
    <Stack spacing={4} sx={{ alignItems: "center", mt: 5 }}>
      <CircularProgress color="secondary" size={120} />
      <Skeleton variant="rounded" width={205} height={15} />
    </Stack>
  )
}