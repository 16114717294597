import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { createRef, useEffect, useRef } from 'react';
import "./style.css"
import FormikInputField from 'jsx/components/Account_Fields/FormikInputFieldLatest';
import { useFormik } from 'formik';
import moment from 'moment';
import dayjs from 'dayjs';
import SignatureCanvas from 'react-signature-canvas'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { zakaatSchema } from './zakaatSchema';
import { toast } from 'react-toastify';
import { onGetAccountSetup, onSubmitZakatDocuments } from 'redux/actions';

import SignaturePad from 'signature_pad';

const Index = ({ handleClose, handleUpload, loadingNext }: any) => {

    const { userAccountDetails } = useAppSelector(state => state.auth);
    const identityCard = useAppSelector(state => state.auth.userAccountDetails?.identityCard);
    const sigPadRef = useRef<any>(null);
    const todayDate = moment().format('DD/MM/YYYY');
    const dispatch = useAppDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { date: todayDate, place: '', signature: null, fiqah: '' },
        onSubmit: (values: any) => {
            const formData: any = new FormData();
            formData.append('Signature', formik?.values?.signature);
            formData.append('ReligiousAffiliation', "Muslim");
            formData.append('Date', formik?.values?.date);
            formData.append('Place', formik?.values?.place);
            formData.append('Fiqah', formik?.values?.fiqah);
            formData.append('DocumentType', 'UserSignature');

            handleUpload(formData, handleClose)
        },
        validationSchema: zakaatSchema
    });

    const clear = () => {
        sigPadRef.current.clear();
        formik?.setFieldValue("signature", null);
    };

    const theme = useTheme();

    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const buttonHtml = () => {
        return (
            <ButtonRowV1
                handleBack={handleClose} handleNext={() => formik.handleSubmit()} 
                loadingNextBtn={loadingNext}
                btnTitleNext={"Submit"} btnTitleBack={"Cancel"}
            />
        )
    }

    const formatIntoPng = () => {
        if (sigPadRef.current) {
            const dataURL = sigPadRef.current.toDataURL();
            return dataURL;
        }
    };

    const customInputFIeldHtml = () => {

        const error = formik.errors.fiqah && formik.touched.fiqah
        return (
            <span style={{ display: "inline-grid!important" }}>
                <input type="text" placeholder='Enter Fiqah' id="fiqah" name="fiqah" style={{ padding: "5px 14px", border: `1px solid ${error ? "#FC5555" : "#D0D5DD"}`, borderRadius: "8px" }} onChange={(e) => formik?.setFieldValue("fiqah", e.target.value)} />
            </span>
        )
    }

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const signaturePadRef = useRef<SignaturePad | null>(null);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            const signaturePad = new SignaturePad(canvas, {
                minWidth: 1,
                maxWidth: 1.2,
                throttle: 0,
            });
            signaturePadRef.current = signaturePad;
            // Add event listener for endStroke event
            signaturePad.addEventListener("endStroke", () => {
                saveSignature();
            });


            // const resizeCanvas = () => {
            //     const container = canvas.parentElement;

            //     if (!container) return;

            //     const ratio = Math.max(window.devicePixelRatio || 1, 1);
            //     const containerWidth = container.offsetWidth;
            //     const containerHeight = container.offsetHeight;

            //     // Set canvas width and height to match container
            //     canvas.width = containerWidth * ratio;
            //     canvas.height = containerHeight * ratio;

            //     const context = canvas.getContext("2d");
            //     if (context) {
            //         // Scale the context to adjust for device pixel ratio
            //         context.scale(ratio, ratio);
            //         // Clear signature pad
            //         signaturePad.clear();
            //     }
            // };

            // // Add event listener for window resize event
            // window.addEventListener("resize", resizeCanvas);

            // // Initialize canvas size
            // resizeCanvas();

        }
    }, []);

    // Function to handle saving the signature as data URL
    const saveSignature = () => {
        if (signaturePadRef.current) {
            const dataURL = signaturePadRef.current.toDataURL(); // Save image as PNG
            formik?.setFieldValue("signature", dataURL)
            console.log(dataURL); // Do something with the data URL, e.g., send it to backend
        }
    };

    // Function to clear the canvas
    const clearSignature = () => {
        if (signaturePadRef.current) {
            signaturePadRef.current.clear();
            formik?.setFieldValue("signature", null)
        }
    };


    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant='h4'>DECLARATION FORM</Typography>
                <IconButton component="label" onClick={handleClose} sx={{ width: "24px", height: "24px" }}>
                    <CloseIcon />
                </IconButton>
            </Box>


            <Box >
                <Typography variant='body2' py={2} >UNDER THE PROVISION TO SUB-SECTION(3) OF SECTION-1 OF THE ZAKAT AND USHER ORDINANCE, 1980 (XVIII OF 1980) AND RULE 20 OF THE ZAKAT (COLLECTION AND REFUND) RULES, 1981 SWORN ON OATH BEFORE.</Typography>

                <Typography variant='body2' >I <span className="underLineText">{identityCard.name}</span> Son/Daughter/Wife/Widow of <span className="underLineText">{identityCard.fatherName}</span>, Muslim adult resident of&nbsp;
                    <span className="underLineText">{userAccountDetails?.permanentAddress?.lines.map((i: any) => {
                        return (<>{i}&nbsp;</>)
                    })}
                        {userAccountDetails?.permanentAddress?.city}, {userAccountDetails?.permanentAddress?.province}</span> do hereby solemnly swear by Allah that:
                </Typography>

                <Box py={1}>
                    <ul className="roman-list">

                        <li className="roman-list">
                            I am a Muslim and follow {customInputFIeldHtml()} Fiqah (name of the recognized Fiqh that the deponent follows)
                            <ul className="roman-list">
                                <li>
                                    According to my faith and the above said fiqh I am not obliged to pay zakat on the following types of assets in the manner laid down in zakat & usher ordinance 1980 (XVIII of 1980) and in accordance with the decision dated 9th March, 1999 of Supreme Court of Pakistan.
                                </li>
                                <li>
                                    Bank Account all types of bank accounts including savings bank account. Notice deposit account. Fixed deposit account and other account by whatever name described, with the Bank, Post Office, National Saving Centres and Financial Institutions etc.
                                </li>
                                <li>
                                    Securities all types of bank accounts including shares, Debentures, N.I.T Units, I.C.P mutual fund certificate, Defence Savings Certificates, Special Savings Certificates, National Deposit Certificate/Account Khas Deposit Certificate/Account Premium Saving Certificates and Account thereof Government Securities etc.
                                </li>
                                <li>
                                    Funds Provident Funds gratuity, Annuity etc.
                                </li>
                                <li>
                                    Loans given to others
                                </li>
                                <li>
                                    And all other investments etc. including insurance policies, claims, premiums etc.
                                </li>
                                <li>
                                    That what is stated above is true to the best of my knowledge and belief.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Box>

                <Typography variant='body2' pb={3}>That what is stated above is true to the best of my knowledge and belief:</Typography>


                <Box sx={{ display: "flex", justifyContent: "space-between", gap: { xs: 1, md: 2 }, flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "flex-start", md: "flex-end" } }} pb={"8px"}>
                    <FormikInputField
                        formik={formik}
                        label="City / Place"
                        name='place'
                        placeholder="Enter here"
                        col={smScreen ? 12 : 3}
                    />

                    <Box>
                        <Typography variant='body2'>Date: {todayDate}</Typography>
                    </Box>
                </Box>


                <Box>
                    <Typography variant='body2' color={"#1D2939"}>Deponent Signature:</Typography>
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <Box sx={{ width: { xs: "100%", md: "60%", lg: "50%" }, height: "160px", border: "1px solid #ccc", borderRadius: "8px" }}>
                            {/* <SignatureCanvas
                                ref={sigPadRef}
                                onEnd={() => formik?.setFieldValue("signature", formatIntoPng())}
                                canvasProps={{ className: "sigPad" }} /> */}

                            <canvas ref={canvasRef} style={{width: '100%', height: "100%"}}></canvas>
                        </Box>
                        <IconButton aria-label="AutorenewIcon" onClick={clearSignature} >
                            {/* <IconButton aria-label="AutorenewIcon" onClick={clear} > */}
                            <AutorenewIcon />
                        </IconButton>
                    </Box>
                    {formik.errors.signature && formik.touched.signature && <span className='mt-1 text-danger'>
                        {formik.errors.signature}
                    </span>
                    }
                </Box>
            </Box>

            <Box sx={{ pt: { xs: 2, md: 1 } }}>
                {buttonHtml()}
            </Box>

        </>
    );
};

export default Index;