/* eslint-disable react-hooks/rules-of-hooks */
import briefcase from "../../../Images/briefcase.png";
import signal from "../../../Images/signal.png";
import wallet from "../../../Images/wallet.png";
import { links } from 'static/links';
import { LandingPageURL } from "constants/landingPageURL"
import { Env_Name, mixPannelTrackerEventName } from 'constants/index'
import { ENVIRONMENT_NAME } from "constants/settings";
import ProfileIcon from "Latest/Images/svg/ProfileIcon";
import HomeIcon from "Latest/Images/svg/HomeIcon";
import SettingsIcon from "Latest/Images/svg/SettingsIcon";
import HelpIcon from "Latest/Images/svg/HelpIcon";
import ExitIcon from "Latest/Images/svg/ExitIcon";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { helpCrunchTrigger } from "Latest/HelpCrunch";
import saveImage from "Latest/Images/svg/save+Image.svg";
import InvestImage from "Latest/Images/svg/InvestImage.svg";
import illustration1 from "Latest/Images/svg/illustration1.svg"
import illustration2 from "Latest/Images/svg/illustration2.svg"
import illustration3 from "Latest/Images/svg/illustration3.svg"

import illustration4 from "Latest/Images/svg/illustration4.svg"
import illustration5 from "Latest/Images/svg/illustration5.svg"
import illustration6 from "Latest/Images/svg/illustration6.svg"
import illustration7 from "Latest/Images/svg/illustration7.svg"

import HomeIconV2 from "Latest/Images/svg/HomeIconV2";
import PortfolioIconV2 from "Latest/Images/svg/PortfolioIconV2";
import TransactionsIconV2 from "Latest/Images/svg/TransactionsIconV2";
import ProfileIconV2 from "Latest/Images/svg/ProfileIconV2";
import NotificationsIconV2 from "Latest/Images/svg/NotificationsIconV2";
import HelpIconV2 from "Latest/Images/svg/HelpIconV2";
import LogoutIcon from "Latest/Images/svg/LogoutIcon";
import { onLogout } from "redux/actions";

import depositIcon from "Latest/Images/depositIcon.png"
import withdrawIcon from "Latest/Images/withdrawIcon.png"
import InvestModal from "Latest/ModalV2/investModal";
import Withdraw from "Latest/ModalV2/withDrawModal";
import FinanceIconV2 from "Latest/Images/svg/FinancialIcon";

export const expectContent = [
    "Seamless account opening",
    "Accessibility at your fingertips",
    "Diverse investment options in shariah compliant assets"
]


const rgbaToHsl = (rgbaColor: any) => {
    // Convert RGBA to RGB
    const rgbaArray = rgbaColor.match(/\d+/g).map(Number);
    const [r, g, b, a] = rgbaArray;
    const alpha = a / 255;

    // Normalize RGB values to the range [0, 1]
    const normalizedR = r / 255;
    const normalizedG = g / 255;
    const normalizedB = b / 255;

    // Find the minimum and maximum values among R, G, and B
    const maxColor = Math.max(normalizedR, normalizedG, normalizedB);
    const minColor = Math.min(normalizedR, normalizedG, normalizedB);

    // Calculate lightness (L)
    let lightness = (maxColor + minColor) / 2;
    lightness += 0.3;

    // Calculate saturation (S)
    let saturation;
    if (maxColor === minColor) {
        saturation = 0;
    } else {
        saturation =
            lightness <= 0.5
                ? (maxColor - minColor) / (maxColor + minColor)
                : (maxColor - minColor) / (2 - maxColor - minColor);
    }

    // Calculate hue (H)
    let hue;
    if (maxColor === minColor) {
        hue = 0;
    } else if (maxColor === normalizedR) {
        hue = ((normalizedG - normalizedB) / (maxColor - minColor) + 6) % 6;
    } else if (maxColor === normalizedG) {
        hue = ((normalizedB - normalizedR) / (maxColor - minColor) + 2) % 6;
    } else {
        hue = ((normalizedR - normalizedG) / (maxColor - minColor) + 4) % 6;
    }

    // Convert hue to degrees
    hue *= 60;

    return `hsl(${Math.round(hue)}, ${Math.round(saturation * 100)}%, ${Math.round(lightness * 100)}%)`;
};

export const porfolioColorsToHsl = () => {
    const { investPortfolioAlloc } = useAppSelector((state) => state.sharedData);
    const colorsArray: any[] = [];
    investPortfolioAlloc?.forEach((item: any) => {
        if (item?.colour) {
            const hslColor = rgbaToHsl(item.colour);
            colorsArray.push(hslColor);
        }
    });

    return colorsArray;
};


export const porfolioColors = () => {
    const { investPortfolioAlloc } = useAppSelector(state => state.sharedData);
    const colorsArray: any[] = [];
    investPortfolioAlloc?.map((item: any) => {
        colorsArray.push(item?.colour)
    })

    return colorsArray;
}

export const CardArray = () => {
    const { isGIAllowed } = useAppSelector(state => state.auth);

    const arr = [{
        img: signal, heading: "Mahaana Save+", subHeading: "STOCKS AND SHARES ISA", btnTitle: "Start Now", link: links.Cashfund,
        isDisable: false,
        list: [
            <>A tool to grow your <strong>idle cash</strong> efficiently</>,
            <>Invest in <strong>Islamic</strong> instruments managed by our trusted fund managers</>,
            <>Make up to <strong>2.5x return</strong> compared to banks</>,
            <>Start with as low as <strong>PKR 1,000</strong></>,
        ], category: "save+"
    },
    {
        img: wallet, heading: "Mahaana Invest", subHeading: "STARTER KIT ON INVESTMENT",
        btnTitle: isGIAllowed ? 'Start Now' : "Coming Soon",
        isDisable: !isGIAllowed,
        link: isGIAllowed ? links.Goal : "",
        list: [
            <>Personalized investment starter kit with portfolio allocation suggestions</>,
            <>Automatic rebalancing for portfolio protection against market volatility</>,
            <>Direct investment into domestic equities and money market funds</>,
            <>Start with as low as <strong>PKR 10,000</strong></>,
        ], category: "invest"
    },
    {
        img: briefcase, heading: "Mahaana Retirement", subHeading: "RISK FREE RETIREMENT", btnTitle: "Coming soon", link: '', isDisable: true,
        list: [
            <>A retirement fund aligned with your retirement goals and time horizon</>,
            <>Asset allocation best suited to your personal circumstances</>,
            <>Claim up to 20% tax credit</>,
            <>Start with as low as <strong>PKR 5,000</strong></>
        ], category: "retirement"
    },]

    return arr
}

export const CardArrayV2 = () => {
    const { isGIAllowed } = useAppSelector(state => state.auth);

    const arr = [{
        img: saveImage, heading: "Mahaana Save+", subHeading: "STOCKS AND SHARES ISA", btnTitle: "Start now", link: links.Cashfund, mixpanelText: mixPannelTrackerEventName?.save_plus_started,
        isDisable: false,
        list: [
            { content: "<span style='color: #432F87'>2X more</span> return than a bank saving account", contentIcon: illustration1 },
            { content: "Make <span style='color: #432F87'>halal</span> income (shariah-compliant investment)", contentIcon: illustration2 },
            { content: "<span>Invests in </span><a href='https://www.mahaana.com/micf' style='color: #432F87; text-decoration: underline;'>Mahaana Islamic Cash Fund</a>", contentIcon: illustration4 },
            { content: "Start with as low as <span style='color: #432F87'>PKR 1,000</span>", contentIcon: illustration3 },
            { content: "<span style='color: #432F87'>Low risk</span> investing", contentIcon: illustration7 },
        ], category: "save+"
    },
    {
        img: InvestImage, heading: "Mahaana Invest", subHeading: "STARTER KIT ON INVESTMENT",
        btnTitle: isGIAllowed ? 'Start now' : "Coming soon",
        isDisable: !isGIAllowed,
        link: isGIAllowed ? links.Goal : "",
        list: [
            { content: "Invest in a <span style='color: #432F87'>diverse</span> set of assets (stocks & sukuks)", contentIcon: illustration4 },
            { content: "Make <span style='color: #432F87'>halal</span> profits (shariah-compliant investments)", contentIcon: illustration5 },
            { content: "Start with as low as <span style='color: #432F87'>PKR 10,000</span>", contentIcon: illustration6 },
            { content: "A <span style='color: #432F87'>suitable portfolio</span> will be chosen based on your risk tolerance", contentIcon: illustration7 }
        ], category: "invest"
    },
    ]

    return arr
}


export const list = [
    "We automatically add 25% tax relief.",
    "Invest up to 40, 000 or 100% of your earnings(whichever is lower) across all your pensions.",
    "Minimum initial investment is 5, 000 and minimum monthly investment is 15, 000",
]

export const navBarOptions = [
    { title: 'Home', route: '/', icon: HomeIconV2, isNavlink: true, navDropDown: [], target: false },
    // { title: "About", route: LandingPageURL.ABOUT_US, icon: HomeIcon, isNavlink: false, navDropDown: [], target: true },
    // { title: 'Products', route: '/products', icon: HomeIcon, isNavlink: false, navDropDown: [{ title: "Mahaana Save+", route: "", icon: HomeIcon, href: LandingPageURL.SAVE, target: true }], target: false },
    // { title: 'Contact Us', route: LandingPageURL.CONTACT_US, icon: HomeIcon, isNavlink: false, navDropDown: [], target: true },
]

export const sideBarRoute = () => {
    const dispatch = useAppDispatch();

    const LogoutTrigger = () => {
        localStorage.clear();
        window.location.reload();
        dispatch(onLogout())
        window.location.replace("/auth/login")
    }

    const arr = [
        { title: "Portfolio", route: "/portfolio", icon: TransactionsIconV2, isNavlink: true, navDropDown: [], target: false },
        { title: "Transactions", route: "/transactions", icon: PortfolioIconV2, isNavlink: true, navDropDown: [], target: false },
        { title: "My profile", route: links.myprofile, icon: ProfileIconV2, isNavlink: true, navDropDown: [], target: false },
        // { title: "Settings", route: links.settings, icon: SettingsIcon, isNavlink: true, navDropDown: [], target: false },
        // { title: "Notifications", route: "/notifications", icon: NotificationsIconV2, isNavlink: true, navDropDown: [], target: false },
        { title: "Need help?", route: links.support, icon: HelpIconV2, isNavlink: true, navDropDown: [], target: false },
        { title: "Financial advice", route: links.finance, icon: FinanceIconV2, isNavlink: true, navDropDown: [], target: false },
        // { title: "Need help?", route: links.support, icon: HelpIconV2, isNavlink: false, navDropDown: [], target: false, onClick: () => helpCrunchTrigger(), functionTrigger: true },
        { title: "Log out", route: "", icon: LogoutIcon, isNavlink: false, navDropDown: [], target: false, onClick: () => LogoutTrigger(), functionTrigger: true },
    ]

    return arr
}

export const quickLinksArray = [
    { icon: depositIcon, title: "Deposit money", link: "", modalComp: InvestModal, type: "down" },
    { icon: withdrawIcon, title: "Withdraw money", link: "", modalComp: Withdraw, type: "up" }
]


// export const sideBarRoute = [
//     // { title: "Help", route: "/help", icon: HelpIcon, isNavlink: true, navDropDown: [], target: false },
//     // { title: "Settings", route: links.settings, icon: SettingsIcon, isNavlink: true, navDropDown: [], target: false },

//     { title: "Portfolio", route: "/portfolio", icon: PortfolioIconV2, isNavlink: true, navDropDown: [], target: false },
//     { title: "Transactions", route: "/transactions", icon: TransactionsIconV2, isNavlink: true, navDropDown: [], target: false },
//     { title: "My Profile", route: links.myprofile, icon: ProfileIconV2, isNavlink: true, navDropDown: [], target: false },
//     { title: "Notifications", route: "/notifications", icon: NotificationsIconV2, isNavlink: true, navDropDown: [], target: false },
//     { title: "Need help?", route: links.support, icon: HelpIconV2, isNavlink: false, navDropDown: [], target: false, onClick: () => helpCrunchTrigger(), functionTrigger: true },
//     { title: "Log out", route: links.support, icon: LogoutIcon, isNavlink: false, navDropDown: [], target: false, onClick: () => LogoutTrigger(), functionTrigger: true },
// ]




export const onBoardingRoutes = [
    { title: 'Exit', route: '/', icon: ExitIcon, isNavlink: true, navDropDown: [], target: false },
]

export const footerOptions = [
    { id: 1, title: 'Terms & Conditions', href: LandingPageURL.TC },
    { id: 2, title: 'Contact Us', href: LandingPageURL.CONTACT_US },
    { id: 3, title: "FAQ's", href: LandingPageURL.FAQS },
]