import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STOCK_DETAIL_BASE_URL } from '../../constants';

const initialState: InitialState = {
  allEtfBasketData: null
};

const name = 'eftBasket';
const etfBasketSlice = createSlice({
  initialState,
  name,
  reducers: {
    allEftBasketDataSuccess: (state, action: PayloadAction<any>) => {
      state.allEtfBasketData = action.payload;
    }
  }
});

export const { allEftBasketDataSuccess } = etfBasketSlice.actions;
export default etfBasketSlice.reducer;

//? APIS ACTIONS

export const onGetEtfBasketData = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: allEftBasketDataSuccess,
    url: `${STOCK_DETAIL_BASE_URL}/api/Basket/efts`
  },
  type: 'onGetEtfBasketData'
});

// ? Types

interface InitialState {
  allEtfBasketData: any | null;
}
