import axios from 'axios';
import { memo, useEffect, useState } from 'react';
import Loader from '../components/Shared/Loader';
import './../components/Thematic/thematic.css';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { LocalUrl1 } from 'redux/middlewares/api';
import { useAppLoading } from 'hooks/appLoading';
import TabContent from 'jsx/components/EftBasket/TabContent';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { onGetEtfBasketData } from 'redux/reducers/etfBasket';
import LoaderWithText from 'jsx/components/Shared/LoaderWithText';

const EtfBasket = () => {
  // const loading = useAppLoading('Thematic');
  const [loading, setLoading] = useState<boolean>(true);
  const [selected, setSelected] = useState<any>([]);
  const [basketId, setBasketId] = useState<any>('');
  const { allEtfBasketData } = useAppSelector(state => state.etfBasket);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allEtfBasketData) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setLoading(true);
    }
    setSelected(allEtfBasketData);
    setBasketId(allEtfBasketData?.id);
  }, [allEtfBasketData]);

  useEffect(() => {
    dispatch(onGetEtfBasketData());
  }, []);

  if (loading)
    return (
      <div className='text-center p-5'>
        <Loader />
      </div>
    );

  return (
    <div className='thematic'>
      <Box
        component='main'
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1
        }}
      >
        <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid
              item
              className='text-center ptr'
              onClick={() => setSelected(allEtfBasketData)}
            >
              <span
                className={`fa ${allEtfBasketData?.iconUrl} thematic-icons  ${
                  allEtfBasketData?.displayName === selected?.displayName
                    ? 'activeColor'
                    : 'notActive'
                }`}
              />
              <p
                className={
                  allEtfBasketData?.displayName === selected?.displayName
                    ? 'borderColordiv'
                    : 'greyScale'
                }
              >
                {allEtfBasketData?.displayName}{' '}
              </p>
            </Grid>

            <TabContent
              basketId={basketId}
              basketName={selected?.name}
              bg={selected?.basketColor}
              items={selected}
              title={selected?.name}
            />
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default memo(EtfBasket);
