export const pieChartLegentCustomMainDivStyle={
     display: 'flex', alignItems: "baseline" ,marginTop:6
}
export const pieChartLegentCustomColorBoxStyle ={  width: "12px", height: "12px" }

export const pieChartLegentCustomTitleStyle = {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginLeft: "0.5rem"
  }

  export const pieChartLegentCustomAmountStyle = {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000000",
  }
