/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Button, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { tokens } from 'theme'
import { customCard } from './style'
import ThreeDots from 'Latest/Images/svg/DotsThreeVertical.svg'
import editPencil from 'Latest/Images/svg/PencilSimple.svg';
import trash from 'Latest/Images/svg/Trash.svg';
import { useAppLoading } from 'hooks/appLoading'
import * as Yup from 'yup';
import { useFormik } from 'formik'
import { DltGoalModal, EditGoalModal } from 'Latest/ModalV2/goalModals'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { onDeleteGoal, onGetTransactionDetail, onPatchEditGoal } from 'redux/reducers/sharedStateData'
import { failTransactionsStatus } from '../../constants'
import { goalIdAction, onGetDashboard } from 'redux/actions'


const MenuUpdateGoals = ({ goalInfo, onDltSuccess = () => { } }: any) => {
    const dispatch = useAppDispatch();

    const { userAccountDetails } = useAppSelector((state: any) => state.auth);
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [goalEditModal, setGoalEditModal] = useState(false);
    const [goalDeleteModal, setGoalDeleteModal] = useState(false);
    const [unableDeleteModal, setUnableDeleteModal] = useState(false);

    const [currentGoalTransactions, setCurrentGoalTransactions] = useState<any>([]);

    const [selectedGoal, setSelectedGoal] = useState<any>(goalInfo);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const loading = useAppLoading('sharedStateData');

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const openEditGoalModal = () => { setGoalEditModal(true); handleClose(); }
    const openDltGoalModal = () => { setGoalDeleteModal(true); handleClose(); }

    useEffect(() => {
        getTransactionHistory()
    }, [])

    useEffect(() => {
        dashboardDetail?.goals?.filter((item: any) => {
            if (item?.idGoal == goalInfo?.idGoal) {
                setSelectedGoal(item)
            }
        })
    }, [goalInfo ,dashboardDetail])

    const getTransactionHistory = () => {

        const onSuccess = (message: any, response: any) => {

            const arr: any[] = [];
            message?.filter((item: any) => {
                if (item?.category == "GeneralInvestment" && item?.goalId == selectedGoal?.idGoal) {
                    arr.push(item)
                }
            })
            setCurrentGoalTransactions(arr)
        }

        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
        }
        const param = {
            id: ""
        }
        dispatch(onGetTransactionDetail(param, onSuccess, onFail))
    }

    const goalEditFormik = useFormik({
        enableReinitialize: true,
        initialValues: { goalName: selectedGoal?.name },
        onSubmit: (values: any) => {
            const data = {
                "userId": userAccountDetails?.userId,
                "idInvestGoal": selectedGoal?.idGoal,
                "goalName": values?.goalName,
                "goalStatus": 0
            }
            onUpdateGoal(data);
        },
        validationSchema: Yup.object({
            goalName: Yup.string().required("Goal name is required").nullable(),
        })
    })

    const onUpdateGoal = (data: any) => {

        const onSuccess = (message: any, response: any) => {
            setGoalEditModal(false);
            dispatch(onGetDashboard())
        }

        dispatch(onPatchEditGoal(data, selectedGoal?.idGoal, onSuccess))
    }

    const goalDeleteFormik = useFormik({
        enableReinitialize: true,
        initialValues: { goalName: selectedGoal?.name },
        onSubmit: (values: any) => {
            setGoalDeleteModal(false);

            const hasPendingTransactions = currentGoalTransactions?.filter((item: any) => failTransactionsStatus[item?.status])

            if (hasPendingTransactions?.length) {
                setUnableDeleteModal(true);
            } else {
                onDltGoal();
            }
        },
        validationSchema: Yup.object({
            goalName: Yup.string()
                .test('require', '', function (value, context) {
                    if (value && (value !== selectedGoal?.name)) {
                        return this.createError({
                            message: "Name is not valid"
                        });
                    }
                    return true;
                }).required("Name is required").nullable()
        })
    })

    const onDltGoal = () => {
        const onSuccess = (message: any, response: any) => {
            dispatch(onGetDashboard());
            onDltSuccess();
            dispatch(goalIdAction("")) ;
        }
        dispatch(onDeleteGoal({}, selectedGoal?.idGoal, onSuccess))
    }

    return (
        <Box>
            <Box display={"flex"} gap={"8px"} flexWrap={"wrap"}>

                {
                    selectedGoal?.category?.toLowerCase() != "cashfund" ? (
                        <>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{ p: 0, minWidth: "auto", "&:hover": {background: "soft"} }}
                            >
                                <img src={ThreeDots} style={{ cursor: "pointer" }} />
                            </Button>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                sx={{ ...customCard.menuWrapper }}
                            >
                                <MenuItem onClick={openEditGoalModal}>
                                    <img src={editPencil} alt='edit goal' />
                                    <Typography variant='subtitle2' fontWeight={400} color={colors.grey[5]} pl="12px" lineHeight={"21px"}>Edit goal name</Typography>
                                </MenuItem>
                                <MenuItem onClick={openDltGoalModal}>
                                    <img src={trash} alt='delete goal' />
                                    <Typography variant='subtitle2' fontWeight={400} color={colors.red[150]} pl="12px" lineHeight={"21px"}>Delete goal</Typography>
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <></>
                    )
                }
            </Box>

            <EditGoalModal
                open={goalEditModal}
                handleClose={() => setGoalEditModal(false)}
                handleConfirm={() => goalEditFormik.handleSubmit()}
                loadingConfirmBtn={loading} formik={goalEditFormik}
            />

            <DltGoalModal
                open={goalDeleteModal}
                handleClose={() => setGoalDeleteModal(false)}
                handleConfirm={() => goalDeleteFormik.handleSubmit()}
                loadingConfirmBtn={loading} formik={goalDeleteFormik}
                title="Delete this goal?"
                subTitle="All of your funds in this goal will be automatically withdrawn (if any)."
                nextTitle={"Delete goal"}
            />

            <DltGoalModal
                open={unableDeleteModal}
                handleClose={() => setUnableDeleteModal(false)}
                title="Unable to delete goal"
                subTitle="Pending transactions are available for this goal. A goal can be deleted once all pending transactions are resolved."
                showNextBtn={false} backTitle={"Ok"}
            />
        </Box>
    )

}

export default MenuUpdateGoals