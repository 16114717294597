import { CombinedState, combineReducers } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';

import auth from './auth';
import questionAnswer from './questionAnswer';
import dashboard from './dashboard';
import employer from './employer';
import funds from './funds';
import fundsSnapshot from './fundsSnapshot';
import globalError from './globalError';
import globalLoading from './globalLoading';
import payment from './payment';
import pension from './pension';
import portfolio from './portfolio';
import pieChartData from './ProgressBarChartReducer';
import sharedData from './sharedStateData';
import transactionHistory from './transactionHistory';
import etfBasket from './etfBasket';
import equityBasket from './equityBasket';
import basketHistory from './basketHistory';
import paymentGateway from "./paymentGateways";
import notificationStatus from './notificationStatus';
// import profileParams from "./profileParams"

const appReducer = combineReducers({
  auth,
  dashboard,
  equityBasket,
  basketHistory,
  etfBasket,
  employer,
  error: globalError,
  funds,
  fundsSnapshot,
  globalLoading,
  payment,
  pension,
  pieChartData,
  portfolio,
  questionAnswer,
  sharedData,
  transactionHistory,
  paymentGateway,
  notificationStatus
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: CombinedState<any>, action: PayloadAction) => {
  if (action.type === 'auth/logout') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
