/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';

const FormikSelectField = ({
  col = 6,
  disabled = false,
  fieldName,
  fieldSelect = false,
  fields,
  formik,
  isMulti = false,
  label,
  onChange,
  onInputChange,
  placeholder,
  required = false,
  value
}: FormikSelectFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;
  const changeHandle = (option: Field) => onChange?.(option.value, option);

  const handleDefaultValue = (
    fields: Array<Field>,
    value: any,
    isMulti: boolean
  ) => {
    if (value === '') {
      return [];
    }
    if (isMulti) {
      const filteredArray: Array<Field> = [];
      fields?.forEach((item: any, index: any) =>
        value?.forEach((el: string) => {
          if (el === item.value) {
            filteredArray.push(item);
          }
        })
      );
      return filteredArray;
    } else {
      const temp: any = [];
      fields?.forEach((item: any) => {
        if (value === item.value) {
          temp.push(item);
        }
      });
      return temp[0];
    }
  };

  return (
    <Col md={col} style={{ padding: 0 }}>
      <div
        className={'form-groups'}
        id={fieldSelect ? 'react-select-field' : 'react-select'}
      >
        {/* {label && (
          <label className='mb-2' style={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "150%",
            color: "#202020"
          }}>
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </label>
        )} */}


        {label && (
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "6px" }}>
            <Typography variant='body2' color="#1D2939">
              {label}
              {required && <span className='ml-1 text-danger'>*</span>}
            </Typography>
          </Box>
        )}

        <Select
          data-inputmask="'mask': '0399-99999999'"
          defaultValue={handleDefaultValue(
            fields,
            formik.values[fieldName],
            isMulti
          )}
          isDisabled={disabled}
          isMulti={isMulti}
          name={fieldName}
          // @ts-ignore
          onChange={option => option && changeHandle(option)}
          onInputChange={onInputChange}
          options={fields}
          placeholder={placeholder ?? label}
          style={{ borderRadius: '1rem!important', display: 'none!important' }}
          value={value}
        />
      </div>

      {/* {
        <span className='ml-2 text-danger'>
          {formikTouch[fieldName] && formikError[fieldName]}
        </span>
      } */}

    </Col>
  );
};

export default memo(FormikSelectField);

export type Field = { label: string; value: string };
interface FormikSelectFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  defaultValue?: Array<Field>;
  fields: Array<Field>;
  fieldSelect?: boolean;
  formik: {
    setFieldValue: (name: string, value: string | Array<string>) => void;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  isMulti?: boolean;
  label: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (
    option: string,
    optionObject: Record<string, string | number>
  ) => void;
  onInputChange?: (val: string) => void;
  value?: any
}
