/* eslint-disable sort-keys */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ACCOUNT_MANAGEMENT_BASE_URL,
  RISK_PROFILE_BASE_URL,
  QuestionAnswerCategoryEnum,
  PendingQuestionAnswerCategoryEnum
} from '../../constants';

const initialState: InitialState = {
  signUpQuestions: [],
  riskProfileAllQuestions: [],
  cashFundProfileUserQuestions: [],
  cashFundRiskProfileUserQuestions: [],
  accountSetupAllQuestions: [],
  paymentQuestions: [],
  signUpUserQuestions: [],
  riskProfileUserQuestions: [],
  accountSetupUserQuestions: [],
  paymentUserQuestions: [],
  questionAnswerViewModel: [],
  allUserSelectedAnswer: [],
  pendingRiskProfileQuestion: [],
  pendingCashFundQuestion: [],
  kycDecQuestion: [],
  pendingKYCQuestion: [],
  termConditionUserQuestions: [],
  pendingTermConditionQuestions: [],
};

const name = 'questionAnswer';
const questionAnswerSlice = createSlice({
  initialState,
  name,
  reducers: {
    onGetSignUpQuestionSuccess: (state, action: PayloadAction<any>) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.signUpQuestions = res.questionAnswers[0];
      }
    },
    onGetRiskProfileAllQuestionSuccess: (state, action: PayloadAction<any>) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.riskProfileAllQuestions = res.questionAnswers[0];
      }
    },
    onGetAccountSetupAllQuestionsuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.accountSetupAllQuestions = res.questionAnswers[0];
      }
    },
    onGetPaymentQuestionSuccess: (state, action: PayloadAction<any>) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.paymentQuestions = res.questionAnswers[0];
      }
    },
    onGetSignUpUserQuestionSuccess: (state, action: PayloadAction<any>) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.signUpUserQuestions = res.questionAnswers[0];
      }
    },
    onGetRiskProfileUserQuestionSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.riskProfileUserQuestions = res.questionAnswers[0];
      }
    },
    onGetCashfundProfileUserQuestionSuccess: (state, action: PayloadAction<any>) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.cashFundProfileUserQuestions = res.questionAnswers[0];
      }
    },
    onGetCashfundRiskProfileUserQuestionSuccess: (state, action: PayloadAction<any>) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.cashFundRiskProfileUserQuestions = res.questionAnswers;
      }
    },
    onGetKYCDecUserQuestionSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.kycDecQuestion = res.questionAnswers[0];
      }
    },
    onGetAccountSetupUserQuestionSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.accountSetupUserQuestions = res.questionAnswers[0];
      }
    },
    onGetPaymentUserQuestionSuccess: (state, action: PayloadAction<any>) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.paymentUserQuestions = res.questionAnswers[0];
      }
    },
    onGetQuestionAnswerViewModelSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.questionAnswerViewModel = action.payload;
    },

    onGetAllUserQuestionAnswersAction: (state, action: PayloadAction<any>) => {
      state.allUserSelectedAnswer = action.payload;
    },
    onGetUserPendingRiskProfileQuestionAction: (state, action: PayloadAction<any>) => {
      state.pendingRiskProfileQuestion = action.payload;
    },
    onGetUserPendingCashFundQuestionAction: (state, action: PayloadAction<any>) => {
      state.pendingCashFundQuestion = action.payload;
    },
    onGetUserPendingKYCQuestionAction: (state, action: PayloadAction<any>) => {
      state.pendingKYCQuestion = action.payload;
    },
    onGetTermConditionUserQuestionSuccess: (state, action: PayloadAction<any>) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.termConditionUserQuestions = res.questionAnswers[0];
      }
    },
    onGetPendingTermConditionQuestionSuccess: (state, action: PayloadAction<any>) => {
      const res = action.payload;
      if (res?.questionAnswers) {
        state.pendingTermConditionQuestions = res.questionAnswers[0];
      } else {
        state.pendingTermConditionQuestions = res;
      }
    },
  }
});
export const {
  onGetAccountSetupAllQuestionsuccess,
  onGetAccountSetupUserQuestionSuccess,
  onGetAllUserQuestionAnswersAction,
  onGetCashfundProfileUserQuestionSuccess,
  onGetCashfundRiskProfileUserQuestionSuccess,
  onGetKYCDecUserQuestionSuccess,
  onGetPaymentQuestionSuccess,
  onGetPaymentUserQuestionSuccess,
  onGetPendingTermConditionQuestionSuccess,
  onGetQuestionAnswerViewModelSuccess,
  onGetRiskProfileAllQuestionSuccess,
  onGetRiskProfileUserQuestionSuccess,
  onGetSignUpQuestionSuccess,
  onGetSignUpUserQuestionSuccess,
  onGetTermConditionUserQuestionSuccess,
  onGetUserPendingCashFundQuestionAction,
  onGetUserPendingKYCQuestionAction,
  onGetUserPendingRiskProfileQuestionAction
} = questionAnswerSlice.actions;
export default questionAnswerSlice.reducer;

const selectQUestionAnswers = (category: number) => {
  switch (category) {
    case QuestionAnswerCategoryEnum.AccountSetup:
      return onGetAccountSetupAllQuestionsuccess;
    case QuestionAnswerCategoryEnum.Payment:
      return onGetPaymentQuestionSuccess;
    case QuestionAnswerCategoryEnum.RiskProfile:
      return onGetRiskProfileAllQuestionSuccess;
    case QuestionAnswerCategoryEnum.SignUp:
      return onGetSignUpQuestionSuccess;
    default:
      break;
  }
};

const selectUserQUestionAnswers = (category: number) => {
  switch (category) {
    case QuestionAnswerCategoryEnum.AccountSetup:
      return onGetAccountSetupUserQuestionSuccess;
    case QuestionAnswerCategoryEnum.Payment:
      return onGetPaymentUserQuestionSuccess;
    case QuestionAnswerCategoryEnum.RiskProfile:
      return onGetRiskProfileUserQuestionSuccess;
    case QuestionAnswerCategoryEnum.CashFund:
      return onGetCashfundProfileUserQuestionSuccess;
    case QuestionAnswerCategoryEnum.cashFundRiskProfile:
      return onGetCashfundRiskProfileUserQuestionSuccess;
    case QuestionAnswerCategoryEnum.SignUp:
      return onGetSignUpUserQuestionSuccess;
    case QuestionAnswerCategoryEnum.Kyc:
      return onGetKYCDecUserQuestionSuccess;
    case QuestionAnswerCategoryEnum.TermCondition:
      return onGetTermConditionUserQuestionSuccess;
    default:
      break;
  }
};

const selectPedingQuestionAnswers = (category: string | number) => {
  switch (category) {
    case PendingQuestionAnswerCategoryEnum.RiskProfile:
      return onGetUserPendingRiskProfileQuestionAction;
    case PendingQuestionAnswerCategoryEnum.CashFund:
      return onGetUserPendingCashFundQuestionAction;
    // case PendingQuestionAnswerCategoryEnum.TermCondition:
    //   return onGetPendingTermConditionQuestionSuccess;
    default:
      break;
  }
};
const UserSelectedAnswers = (category: string | number) => {
  if (QuestionAnswerCategoryEnum.Kyc == category) {
    return onGetUserPendingKYCQuestionAction;
  } else if (QuestionAnswerCategoryEnum.TermCondition == category) {
    return onGetPendingTermConditionQuestionSuccess;
  } else {
    return onGetAllUserQuestionAnswersAction
  }
};


//? APIS ACTIONS
export const onGetQuestionAnswers = (category: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: selectQUestionAnswers(category),
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/QuestionAnswer?category=${category}`
  },
  type: 'onGetQuestionAnswers'
});

export const onGetUserQuestionAnswersAccountSetup = (userId: string | undefined, category: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: selectUserQUestionAnswers(category),
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount/questions?category=${category}`
  },
  type: 'onGetUserQuestionAnswers'
});

export const onGetUserQuestionAnswers = (userId: string | undefined, category: number, attempted = false) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: selectUserQUestionAnswers(category),
    url: `${RISK_PROFILE_BASE_URL}/api/QuestionAnswer/GetAll?categoryId=${category}&attempted=${attempted}`
  },
  type: 'onGetUserQuestionAnswers'
});

export const onGetAllUserQuestionAnswers = (category: any, attempted = false) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: UserSelectedAnswers(category),
    // url: `${RISK_PROFILE_BASE_URL}/api/InvestorRiskProfile/GetAllUserQuestion?attempted=${attempted}&$filter=category/id eq ${category}`
    url: `${RISK_PROFILE_BASE_URL}/api/InvestorRiskProfile/GetAllUserQuestion?attempted=${attempted}&category=${category}`

  },
  type: 'onGetAllUserQuestionAnswers'
});

export const onGetUserPendingRiskProfileQuestion = (category: string) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: selectPedingQuestionAnswers(category),
    url: `${RISK_PROFILE_BASE_URL}/api/InvestorRiskProfile/GetPendingQuestion?category=${category}`
  },
  type: 'onGetUserPendingRiskProfileQuestion'
});

export const onPostAddress = (data: any) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    errorToast: true,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAddress`
  },
  type: 'onPostAddress'
});


export const onPostAddressV1 = (data: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    onSuccess,
    onFail,
    errorToast: true,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAddress`
  },
  type: 'onPostAddress'
});

export const onGetQuestionAnswerViewModel = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onGetQuestionAnswerViewModelSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/QuestionAnswer/ViewModel`
  },
  type: 'onGetQuestionAnswerViewModel'
});

export const onPostQuestionAnswers = (data: any) => ({
  payload: {
    apiName: name,
    method: 'post',
    data,
    onSuccess: onGetQuestionAnswerViewModelSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/QuestionAnswer`
  },
  type: 'onPostQuestionAnswers'
});

export const onPostUserAnswers = (data: any, userId: string | undefined) => ({
  payload: {
    apiName: name,
    method: 'post',
    data,
    onSuccess: onGetQuestionAnswerViewModelSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/QuestionAnswer/${userId}/submitanswers`
  },
  type: 'onPostQuestionAnswers'
});

export const onPutUserAnswers = (data: any, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    method: 'put',
    data,
    errorToast: true,
    onSuccess: onSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount`
  },
  type: 'onPutUserAnswers'
});

export const onPostUserAnswersRiskProfile = (data: any) => ({
  payload: {
    apiName: name,
    method: 'post',
    data,
    onSuccess: onGetQuestionAnswerViewModelSuccess,
    url: `${RISK_PROFILE_BASE_URL}/api/InvestorRiskProfile`
  },
  type: 'onPostQuestionAnswers'
});

// ? Types

interface InitialState {
  signUpQuestions: any;
  riskProfileAllQuestions: any;
  accountSetupAllQuestions: any;
  paymentQuestions: any;
  questionAnswerViewModel: any;
  signUpUserQuestions: any;
  riskProfileUserQuestions: any;
  cashFundProfileUserQuestions: any;
  accountSetupUserQuestions: any;
  paymentUserQuestions: any;
  pendingRiskProfileQuestion: any;
  allUserSelectedAnswer: any;
  pendingCashFundQuestion: any;
  kycDecQuestion: any;
  pendingKYCQuestion: any;
  termConditionUserQuestions: any;
  pendingTermConditionQuestions: any;
  cashFundRiskProfileUserQuestions: any
}
