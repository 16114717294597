import { memo } from 'react';

import loadingImage from 'images/svg/loader.svg';

const LoaderWithText = ({ className = '', text, width = 150 }: LoaderProps) => (
  <div className='mt-4' style={{ textAlign: 'center' }}>
    <img alt='loading' className={className} src={loadingImage} width={width} />
    <h4>
      {text}
    </h4>
  </div>
);

export default memo(LoaderWithText);

interface LoaderProps {
  className?: string;
  text: string
  width?: number;
}
