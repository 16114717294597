export const customDrawerStylelistItemHover = {
    background: "#432F87", color: "#FFFFFF", borderRadius: "10px",
    '& .MuiListItemButton-root:hover': {
        color: "#FFFFFF"
    }
}

export const customDrawerStyle = {
    flex: { display: "flex" },
    listItemBtn: { height: "38px", p: "5px 10px", },
    listItemIcon: { minWidth: 0, justifyContent: 'center', },
    listItem: {
        display: 'block',
        color: "#344054",
        mb: "8px",

    },
    expandBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '60%',
        width: '24px',
        height: '24px',
        background: "#FFFFFF",
        cursor: "pointer",
    }
}