import React, { useEffect, useState } from 'react';
import PieChart from 'jsx/components/Shared/PieChart';
import { memo } from 'react';
import AccordionList from './AccordionList';
import { useAppSelector } from 'hooks/reduxHooks';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import Loader from '../../../Shared/Loader';
import { projectionsAmountStyle, projectionsAmountTitleStyle } from '../Style/projectionStyle';
import { Goals } from '../../../../../constants';

export interface KeyValuePair {
  name: string,
  value: number
}

const ProgressBarChart = ({ formik }: any) => {
  const intialAmountCount = formik.values.intialAmountCount

  const COLORS = ['#F4AC6A', '#F1A5D1', '#72D6B8'];
  const [pieColors] = useState(COLORS)
  const { personalizedPortfolio } = useAppSelector(state => state.sharedData);
  const [pieMockData, setPieMockData] = useState<KeyValuePair[]>([]);

  useEffect(() => {
    if (personalizedPortfolio?.assetAllocations?.length > 0) {
      const data = personalizedPortfolio?.assetAllocations?.map((val: { name: any; percentage: any; amount: number }) => ({ 'name': val.name, 'value': val.percentage, "amount": val.amount * intialAmountCount }));
      setPieMockData(data);
    }
    // }, [personalizedPortfolio]);
  }, [intialAmountCount])

  return (
    <>
      {personalizedPortfolio?.assetAllocations?.length > 0 ?
        <Grid container sx={{ p: 2 }} >
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Box sx={{ display: "flex",gap: { xs: 2, md: 5 }, flexWrap: "wrap" }}>
              <Box>
                <Typography sx={projectionsAmountTitleStyle}>Return & Risk</Typography>
                <Typography sx={projectionsAmountStyle}>{personalizedPortfolio?.yieldCategory}</Typography>
              </Box>

              <Box >
                <Typography sx={projectionsAmountTitleStyle}>Saving Horizon </Typography>
                <Typography sx={projectionsAmountStyle}>{formik?.initialValues?.goalId === Goals.TravelTime ? (
                  <span>{formik.values.desired_retirement_age} Months</span>
                ) : (
                  <span>{formik.values.desired_retirement_age} Years</span>
                )}
                </Typography>
              </Box>
            </Box >
          </Grid>

          <Grid item md={8} xs={12} sx={{ border: "1px solid #EDEDED", borderRadius: "12px" }}>
            {pieMockData && <PieChart colors={pieColors} data={pieMockData} route={'ProgressBarChart'} />}
          </Grid>

          <Grid item md={4} xs={12} sx={{ px: {xs:0,md:2} , mt:{xs:2,md:0 }}}>
            <AccordionList data={personalizedPortfolio.assetAllocations} />
          </Grid>

        </Grid>
        : <>
          <Skeleton variant="rectangular" height={400} />
        </>

      }
    </>
  );
};

export default memo(ProgressBarChart);
