import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import GreenTick from "../../../Latest/Images/greenTick.png"
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: "90%",
    bgcolor: '#FFFFFF',
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    p: 4,
    borderRadius: "12px"
};

export default function ThankYouModal({ Content, btnTitle = "Close", handleClose, open }: any) {

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box sx={{ position: "absolute", right: 15, top: 18 }}>
                        <IconButton aria-label="upload picture" component="label">
                            <CloseIcon onClick={handleClose} />
                        </IconButton>
                    </Box>
                    <Grid container spacing={2} >
                        <Grid item  >
                            <img src={GreenTick} />

                        </Grid>

                        {Content()}

                        <Grid item xs={12}>
                            <ButtonRowV1 btnTitleBack={btnTitle} handleBack={handleClose} isShowNext={false} />
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </div >
    );
}