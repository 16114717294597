import { Box, CircularProgress, Container, Divider, Grid, Typography } from '@mui/material'
import TopBarHeading from 'Latest/ComponentV1/topBarHeading'
import { CASH_FUND_ID, FlowPath, PaymentMethodType, goalCategory, mixPannelTrackerEventName } from '../../constants';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { NIFTBankFormValue, SellCf, payMobServiceFeeActions } from 'redux/actions';
import { links } from 'static/links';
import { AxiosResponse } from 'axios';
import ErrModal from 'Latest/MyProfile/Modal/errModal';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import Big from 'big.js';
import { onGetTransactionDetail } from 'redux/reducers/sharedStateData';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';

const PayMobReceipt = () => {
    const { push } = useHistory();
    const dispatch = useAppDispatch();
    const { goalFlowId, goalFlowPath, goalIdPost,payMobServiceFee } = useAppSelector(state => state?.portfolio);

    const { user: { email } } = useAppSelector((state: any) => state.auth);

    const [state, setState] = useState<any>(
        {
            amount_Cents: "",
            transaction_Id: "",
            status_Check: "",
            hasAmount_cents: "",
            amount: "",
            transitionsFee:"",
        }
    )
    const [openErrModal, setOpenErrModal] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search); //paymod
        const amount_Cents = Number(urlParams.get('amount_cents'));  //paymod
        const transaction_Id = urlParams.get('id'); //paymod
        const status_Check = urlParams.get('success'); //paymod
        const hasAmount_cents = !!amount_Cents; //paymod
        const amt = Number(Big(amount_Cents).div(100))
       
        setState({
            amount_Cents,
            transaction_Id,
            status_Check: status_Check == "false" ? false : true,
            hasAmount_cents,
            amount: amt
        })


    }, [])

    useEffect(() => {
        if (state.amount > 1) {
            goalSubmit()
        }
    }, [state])

    const getTransactionHistory = () => { //to check out transaction history for mixpanel 
        const onSuccess = (message: any, response: any) => {
            const filterTransactionDetail = message.filter((item: any, index: number) => item.goalId == goalIdPost)
            if (filterTransactionDetail[0].category.toLowerCase() == goalCategory.CashFund.toLowerCase()) {
                filterTransactionDetail.length == 1 && MixPannelTracker({ eventName: mixPannelTrackerEventName.initial_transaction_submitted, email })
                filterTransactionDetail.length == 1 && Number(state.amount) >= 10000 && MixPannelTracker({ eventName: mixPannelTrackerEventName.tenK_crossed, email })
            }

        }
        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
        }
        const param = {
            id: ""
        }
        dispatch(onGetTransactionDetail(param, onSuccess, onFail))
    }
    const handleRedirect = () => {
        push(`/Receipt?id=${state.transaction_Id}&amount_cents=${state.amount_Cents}&success=${state.status_Check}&paymentMethodType=${PaymentMethodType.PayMob}`, "data");
        getTransactionHistory()

    }

    const goalSubmit = () => {

        const AccountCategory = Number(goalFlowId) == CASH_FUND_ID ? goalCategory.CashFund : goalCategory.GeneralInvest
        console.log(state.amount)
        console.log(payMobServiceFee)
        console.log(state.amount-payMobServiceFee)
        const formData: any = new FormData();
        formData.append('originalAmount', state.amount-payMobServiceFee);
        formData.append('additionalCharges', payMobServiceFee);
        formData.append('paymentGateway', PaymentMethodType.PayMob);
        formData.append('goalId', goalIdPost);
        formData.append('AccountCategory', AccountCategory);
        formData.append('transactionId', state.transaction_Id);


        if (state.status_Check) {   // payment is approved by paymob
            dispatch(NIFTBankFormValue(formData, onSuccessResponse));
        } else { // payment declined by by paymob
            handleRedirect()
        }

    }

    const onSuccessResponse = (message: string, response: AxiosResponse) => {
        handleRedirect()
        // const SellCFBody = {
        //     "idCashFundInvestment": goalIdPost,
        //     "investAmount": state.amount
        // }
        // const onSuccessSellCF = (message: string, response: any) => {
        //     handleRedirect()
        // }

        // const onFailSellCF = (message: string, response: AxiosResponse) => {
        //     setOpenErrModal(true)
        // }

        // if (goalFlowPath == FlowPath.Invest) {
        //     dispatch(SellCf(SellCFBody, onSuccessSellCF, onFailSellCF))
        //     return;
        // }

        // const onSuccessGoal = () => {
        //     console.log()
        // }
        // const onFail = () => {
        //     console.log()
        // }

        // if (Number(goalFlowId) == CASH_FUND_ID) {
        //     handleRedirect()
        // } else {
        //     handleRedirect()
        //     //for GI flow
        //     // dispatch(goalStatus(addGoalBody, formik.values.goalIdPost, details?.pp_RetreivalReferenceNo,  details?.pp_ResponseMessage, onSuccessGoal, onFail)) 

        // }

        return;
    };

    const ContentFailed = () => {
        return (
            <Grid item xs={12} sx={{ pt: "8px" }}>
                <Typography sx={ModalStyle.headStyle}>Oops!</Typography>
                <Typography sx={ModalStyle.subheadStyle}>"Sorry for the inconvenience. Please try again later."</Typography>
            </Grid>
        )
    }

    return (
        <>
            <ErrModal link={true} handleOpen={() => setOpenErrModal(true)} open={openErrModal} handleClose={() => setOpenErrModal(false)} Content={ContentFailed} btnTitle={"Back to dashboard"} />

            <Container disableGutters maxWidth={"xl"}>
                <TopBarHeading heading={"PayMob Receipt"} />
                <Divider sx={{ borderColor: "#EAECF0" }} />
                <Box sx={{ p: { xs: "16px", sm: "30px" } }}>
                    <Container maxWidth="lg" sx={{ px: "0px!important" }}>
                        <Grid container sx={{ background: "#F9FAFB", textAlign: "center", p: { xs: "24px 16px", md: "30px" }, borderRadius: "12px", display: 'flex', alignItems: 'center', flexDirection: "colmun" }}>

                            <Grid item md={12} xs={12} mt={1}>
                                <CircularIndeterminate />
                            </Grid>

                        </Grid>
                    </Container >
                </Box>
            </Container>
        </>
    )

}

export default PayMobReceipt

const CircularIndeterminate = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center", gap: 3, mt: 4 }}>
            <CircularProgress color='secondary' size={90} />
            <Typography>Please wait for a while...</Typography>
        </Box>
    )
}