import React from 'react'
import CustomCard from 'Latest/ComponentV1/CustomCard'
import CustomCard1 from 'Latest/ComponentV1/Customcard1'
import { useHistory } from 'react-router-dom';
import RetirementLogo from "Latest/Images/retirementLogo.png"
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from 'theme';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';


const RetirementCard = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <>
            {/* <CustomCard heading='Mahaana Retirement'
                content={<CustomCard1 title={"Open your retirement account"} imgIcon={RetirementLogo} btnTitle={"Coming soon"} type={"Retirement"} />
                } /> */}

            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant='h4' color={colors.purple[100]} lineHeight={"30px"}>Mahaana Retirement</Typography>
                <LoadingBtnCustom disabled={true} btnTitle={"Coming soon"} style={{ py: 0, height: "37px", fontWeight: 400, minWidth: "fit-content" }} />
            </Box>

        </>
    )
}

export default RetirementCard