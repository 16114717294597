/* eslint-disable sort-keys */
import { useEffect, useRef, useState } from 'react';
import { Box, Grid, Backdrop, Modal, Fade, Typography, Button } from '@mui/material';
import { WaitListModalStyles, WaitListResponseModalStyles } from 'Latest/Style/Style';
import { FormikProps, useFormik } from 'formik';
import { waitListValidation } from 'validate/VPSGoal';
import { useAppDispatch } from 'hooks/reduxHooks';
import { submitWaitlistUser } from 'redux/actions';
import { toast } from 'react-toastify';
import close from 'Latest/Images/close.png'
import warning from 'Latest/Images/warning.png'
import ButtonsRowLatest from '../WizardFormModal/ButtonsRowLatest';
import mahaanaLogo from 'Latest/Images/mahaana.png';
import InputField from 'jsx/components/Shared/FormikInputFieldLatest';

export const WaitListResponseModal = ({ handleClose, isVisible, nextButtonOnClick }: any) => {
    // const description = 'We are thrilled to welcome you on board!<br/>However, we are currently in the beta testing phase and only available to limited users who have been invited to participate.'
    const description = "Access to Mahaana is only available to shortlisted users currently. This allows us to thoroughly test our application before releasing it to the public! Visit our website to subscribe to the waitlist."
    const visitSite = () => {
        const url = 'http://mahaana.com/';
        window.open(url, '_blank');
    }

    return (
        <Modal
            closeAfterTransition
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isVisible}
            onClose={handleClose}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                },
            }}
        >
            <Fade in={isVisible}>
                <Box sx={WaitListResponseModalStyles.container}>
                    <Grid container>
                        <Grid item md={12} xs={12} className="head">
                            <img src={warning} alt="warning" />
                            <img src={close} alt="close" className="closeImg" onClick={handleClose} />
                        </Grid>
                        <Grid item md={12} xs={12} className="body">
                            <Typography component={'h5'}>Your email hasn’t been shortlisted</Typography>
                            <Typography dangerouslySetInnerHTML={{ __html: description }} />
                        </Grid>
                        <Grid item md={12} xs={12} className="footer">
                            <ButtonsRowLatest
                                prevButtonLabel={'Cancel'}
                                backButtonOnClick={handleClose}
                                nextButtonLabel={'Visit website'}
                                nextButtonOnClick={visitSite}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export const WaitListModal = ({ callback, handleClose, isVisible, userData }: any) => {

    const dispatch = useAppDispatch();
    const description = 'We are thrilled to welcome you on board!<br/>However, we are currently in the beta testing phase and only available to limited users who have been invited to participate.'
    const formikRef = useRef<FormikProps<{ name: string; email: string; }>>(null);
    const [errorMsg, setErrorMsg] = useState('')

    const userInfo = {
        name: '',
        email: '',
    }

    useEffect(() => {
        if (userData) {
            formik.setFieldValue('name', userData?.name)
            formik.setFieldValue('email', userData?.email)
        }
    }, [isVisible])

    useEffect(() => {
        setErrorMsg('')
    }, [formikRef])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: userInfo,
        validationSchema: waitListValidation,
        onSubmit: (values: any) => {
            setErrorMsg('')
            submitUser(values)
        },
        innerRef: formikRef,
    });

    const submitUser = (data: any) => {
        const userData = {
            name: data?.name,
            email: data?.email
        }
        dispatch(submitWaitlistUser(userData, onSuccess, onFail))
    }

    const onFail = (message: string, response: any) => {
        setErrorMsg(response?.data)
    };

    const onSuccess = (message: string, response: any) => {
        callback()
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        formik.submitForm();
    };

    return (
        <Modal
            closeAfterTransition
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isVisible}
            onClose={() => {
                handleClose()
                setErrorMsg('')
            }}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {

                },
            }}
        >
            <Fade in={isVisible}>
                <Box sx={WaitListModalStyles.container}>
                    <Grid container>

                        <Grid item md={12} xs={12} sx={WaitListModalStyles.header}>
                            <img src={mahaanaLogo} alt="mahaana" />
                            <img src={close} alt="close" className='closeIcon' onClick={() => {
                                handleClose()
                                setErrorMsg('')
                            }} />
                        </Grid>

                        <Grid item md={12} xs={12} sx={WaitListModalStyles.body}>
                            <Grid item md={12} xs={12} sx={WaitListModalStyles.firstContentSec}>
                                <Typography component={'h2'} className="gradiantText">If you are already invited!</Typography>
                                <Button onClick={handleClose}>Start Now</Button>
                            </Grid>

                            <Grid item md={12} xs={12} sx={WaitListModalStyles.secContentSec}>
                                <Typography component={'h3'}>Join the Waitlist</Typography>
                                <Typography dangerouslySetInnerHTML={{ __html: description }} />
                            </Grid>

                            <Grid item md={12} xs={12} sx={WaitListModalStyles.formSec}>
                                <form onSubmit={handleSubmit}>
                                    <Grid item md={12} xs={12} sx={WaitListModalStyles.inputStylesContainer}>
                                        <InputField
                                            required
                                            label={'Name '}
                                            formik={formik}
                                            name={'name'}
                                            placeholder={'Enter your name'}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12} sx={WaitListModalStyles.inputStylesContainer}>
                                        <InputField
                                            required
                                            label={'Email '}
                                            formik={formik}
                                            name={'email'}
                                            placeholder={'Enter your email'}
                                        />
                                    </Grid>
                                </form>

                                {errorMsg && <span className='text-danger'>{errorMsg}</span>}
                            </Grid>

                            <Grid item md={12} xs={12} sx={WaitListModalStyles.buttonsContainer}>
                                <ButtonsRowLatest
                                    prevButtonLabel={'Cancel'}
                                    backButtonOnClick={handleClose}
                                    nextButtonLabel={'Submit'}
                                    nextButtonOnClick={handleSubmit}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export const ContentWithLogoModal = ({ description, handleClose, isVisible, title }: any) => {

    return (
        <Modal
            closeAfterTransition
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isVisible}
            onClose={handleClose}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {

                },
            }}
        >
            <Fade in={isVisible}>
                <Box sx={{ ...WaitListModalStyles.container }}>
                    <Grid container>
                        <Grid item md={12} xs={12} sx={WaitListModalStyles.header}>
                            <img src={mahaanaLogo} alt="mahaana" />
                            <img src={close} alt="close" className='closeIcon' onClick={handleClose} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Grid item md={12} xs={12} sx={{ ...WaitListModalStyles.firstContentSec, ...WaitListModalStyles.contentModal }}>
                                {title && <Typography component={'h2'} className="gradiantText">{title}</Typography>}
                                {description && <Typography dangerouslySetInnerHTML={{ __html: description }} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

            </Fade>
        </Modal>
    )
}