import { Col, Row } from 'react-bootstrap';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { JSXElementConstructor, ReactElement, memo, useEffect, useState } from 'react';
import { Box, InputLabel, TextField, TextFieldProps, Typography, useMediaQuery } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs'; // Make sure to import dayjs or your chosen date library
import moment from 'moment';

import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

const AuthInputField = ({
  FieldTouchInput = false,
  autoFocus = false,
  bottomInfo,
  col = 12,
  disabled = false,
  formik,
  isMutual = false,
  label,
  maxDate,
  maxLength,
  minLength,
  name,
  nestedFieldName,
  onChange,
  placeholder,
  removeWhitespace = false,
  required = false,
  signUpLabelStyle = false,
  style,
  topInfo,
  type = 'text',
  upperCaseEnable = false
}: AuthInputFieldProps) => {

  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formikTouch = formik.touched as any;
  const formikError = formik.errors as any;
  let fields: any = formik.getFieldProps(name);

  let modifiedValue: any = fields?.value || '';

  if (upperCaseEnable) {
    modifiedValue = modifiedValue.toUpperCase();
  }

  if (removeWhitespace) {
    modifiedValue = modifiedValue.replace(/\s/g, '');
  }

  fields = {
    ...fields,
    value: modifiedValue
  };

  let FieldError = "";
  let FieldTouch = false;
  let NestedFieldError = "";
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[name?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[name?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[name];
    FieldTouch = formikTouch[name];
  }

  const [isCalendarOpen, setCalendarOpen] = useState(false);

  const handleOpen = () => {
    setCalendarOpen(true);
    if (formik.values[name] == '' && mdScreen) {
      setTimeout(() => {
        const elements = document.querySelectorAll('.MuiPickersCalendarHeader-label');
        elements.forEach((element) => { element.innerHTML = '--'; });
      }, 300);
    }
  };

  const handleClose = () => {
    setCalendarOpen(false);
  };

  function CalendarIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M19.5 3H17.25V2.25C17.25 2.05109 17.171 1.86032 17.0303 1.71967C16.8897 1.57902 16.6989 1.5 16.5 1.5C16.3011 1.5 16.1103 1.57902 15.9697 1.71967C15.829 1.86032 15.75 2.05109 15.75 2.25V3H8.25V2.25C8.25 2.05109 8.17098 1.86032 8.03033 1.71967C7.88968 1.57902 7.69891 1.5 7.5 1.5C7.30109 1.5 7.11032 1.57902 6.96967 1.71967C6.82902 1.86032 6.75 2.05109 6.75 2.25V3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM19.5 7.5H4.5V4.5H6.75V5.25C6.75 5.44891 6.82902 5.63968 6.96967 5.78033C7.11032 5.92098 7.30109 6 7.5 6C7.69891 6 7.88968 5.92098 8.03033 5.78033C8.17098 5.63968 8.25 5.44891 8.25 5.25V4.5H15.75V5.25C15.75 5.44891 15.829 5.63968 15.9697 5.78033C16.1103 5.92098 16.3011 6 16.5 6C16.6989 6 16.8897 5.92098 17.0303 5.78033C17.171 5.63968 17.25 5.44891 17.25 5.25V4.5H19.5V7.5Z" fill="#667085" />
    </svg>;
  }

  return (
    <Col md={col}>
      <Row>
        {label && (
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "6px" }}>
            <Typography variant='body2' color="#1D2939">
              {label}
              {required && <span className='ml-1 text-danger'>*</span>}
            </Typography>
          </Box>
        )}
      </Row>

      {topInfo && (
        <Row>
          {typeof (topInfo) == 'string' ? <div className='small-info mb-2 mt-1 text-muted'>{topInfo}</div> :
            <ul>{topInfo?.map((text) => <li key={text} className='small-info mb-2 mt-1 text-muted'>{text}</li>)}</ul>
          }
        </Row>
      )}
      <Row>
        {type == 'Date' ?
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {signUpLabelStyle ? <DatePicker
              open={isCalendarOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              openTo={mdScreen ? "year" : "day"}
              views={['year', 'month', 'day']}
              onChange={(newDate: dayjs.Dayjs | null) => {
                formik.setFieldTouched(name, true);
                const formattedDate = newDate ? newDate.toDate() : null;
                formik?.setFieldValue(name, formattedDate);
              }}
              disableHighlightToday={true}
              format='DD/MM/YYYY'
              maxDate={dayjs()}
              sx={{ '& .MuiInputBase-root': { width: '100%' } }}
            /> :
              <DatePicker
                open={isCalendarOpen}
                onOpen={handleOpen}
                onClose={handleClose}
                openTo={smScreen ? "year" : "day"}
                views={['year', 'month', 'day']}
                onChange={(newDate: dayjs.Dayjs | null) => {
                  formik.setFieldTouched(name, true);
                  const formattedDate = newDate ? moment(newDate.toDate()).format('YYYY-MM-DD') : null;
                  formik?.setFieldValue(name, formattedDate);
                }}
                disableHighlightToday={true}
                format='DD/MM/YYYY'
                sx={{ '& .MuiInputBase-root': { width: '100%', height: "48px", '& fieldset': { borderColor: "#D0D5DD !important" } } }}
                value={dayjs(formik.values[name])}
                className='mui-date-picker'
                maxDate={maxDate}
                slots={{ openPickerIcon: CalendarIcon }}
              />}
          </LocalizationProvider>
          :
          <input
            autoComplete="off"
            autoFocus={autoFocus}
            className='text-box-custom'
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            name={name}
            placeholder={placeholder}
            style={{ width: "100%", ...style }}
            type={type}
            {...fields}
          />
        }
      </Row>
      {/* <div style={{ display: 'flex' }}>
          {type === 'number' && < p style={{ marginLeft: '-60px', marginTop: '5px', fontWeight: 'bold' }}>
            PKR
          </p>}
        </div> */}
      {bottomInfo && (
        <Row>
          <div className='small-info mb-2 mt-1 text-muted'>{bottomInfo}</div>
        </Row>
      )}

      {(FieldTouch || FieldTouchInput) && FieldError && (
        <Row>
          <span className='mt-1 text-danger'>
            {FieldError}
          </span>
        </Row>
      )}

      {NestedFieldTouch && NestedFieldError && (
        <Row>
          <span className='mt-1 text-danger'>
            {NestedFieldError}
          </span>
        </Row>
      )}
    </Col>
  );
};

export default memo(AuthInputField);

interface AuthInputFieldProps {
  autoFocus?: boolean;
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  formik: FormikFieldProps;
  label?: string | JSX.Element;
  maxLength?: number;
  minLength?: number;
  name: any;
  nestedFieldName?: any;
  placeholder: string;
  required?: boolean;
  topInfo?: string | string[];
  type?: string;
  bottomInfo?: string | JSX.Element;
  isMutual?: boolean;
  FieldTouchInput?: boolean,
  upperCaseEnable?: boolean,
  removeWhitespace?: boolean,
  signUpLabelStyle?: boolean,
  style?: any,
  onChange?: any,
  maxDate?: any
}

export interface FormikFieldProps {
  getFieldProps: (field: string) => void;
  touched: FormikTouched<unknown>;
  errors: FormikErrors<unknown>;
  values: FormikValues;
  setFieldValue: any;
  setValues?: any;
  setFieldTouched: any;
}
