import { MAHAANA_ABOUT_US, MAHAANA_CONTACT_US, MAHAANA_FAQS, MAHAANA_SAVE, MAHAANA_TC,MAHAANA_MAIN_LANDING_PAGE,MAHAANA_STG_LANDING_PAGE } from "./settings";

export const LandingPageURL = {
    CONTACT_US: MAHAANA_CONTACT_US,
    ABOUT_US: MAHAANA_ABOUT_US,
    FAQS: MAHAANA_FAQS,
    TC: MAHAANA_TC,
    SAVE: MAHAANA_SAVE,
    MAIN:MAHAANA_MAIN_LANDING_PAGE,
    STG:MAHAANA_STG_LANDING_PAGE
}



