import { Box, Container, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useFormik } from 'formik';
import FormikInputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import PhoneField from 'jsx/components/Shared/V1/PhoneFieldV1';
import { authStyles } from 'Latest/Style/Style';
import { phoneFieldStyle } from 'jsx/components/Shared/style/phoneFieldStyle';
import { nextKinValidation } from 'validate/Screen/NextKinValidation';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';


const ShowNextKin = ({ checkNextKin, loading, path, setCheckNextKin }: props) => {
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    return (
        <>
            <Grid container spacing={"20px"}>
                <Grid item md={6} xs={12} >
                    <Typography variant='body2'>Name</Typography>
                    <Typography variant='h6' sx={{ mt: "4px" }}>{userAccountDetails?.beneficiaries?.[0]?.name || "-"} </Typography>
                </Grid>
                <Grid item md={6} xs={12} >
                    <Typography variant='body2'>CNIC Number</Typography>
                    <Typography variant='h6' sx={{ mt: "4px" }}>{userAccountDetails?.beneficiaries?.[0]?.cnic || "-"} </Typography>
                </Grid>
                <Grid item md={6} xs={12} >
                    <Typography variant='body2'>Mobile Number</Typography>
                    <Typography variant='h6' sx={{ mt: "4px" }}>{`+${userAccountDetails?.beneficiaries?.[0]?.phone}` || "-"}</Typography>
                </Grid>
            </Grid>

            <Grid item md={12} xs={12} display={"flex"} justifyContent={"flex-end"}>
                <LoadingBtnCustom loading={loading} btnTitle={"Edit Details"} handleOnClick={() => setCheckNextKin(false)} />
            </Grid>
        </>
    )
}

export default ShowNextKin

interface props {
    setCheckNextKin?: any,
    checkNextKin?: boolean,
    loading?: boolean,
    path?: string
}