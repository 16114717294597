export const ModalStyle = {
    wrapStyles: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        px: "24px",
        maxWidth: "400px",
        margin: {md: 0, xs: "0 auto"}
    },
    headStyle: {
        color: "#101828",
        fontSize: { md: "1rem", xs: "1rem" },
        fontWeight: 500,
        lineHeight: "normal",
        textAlign: { md: "left", xs: "left" }
    },
    subheadStyle: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: { md: "0.875rem", xs: "0.875rem" },
        lineHeight: "normal",
        textAlign: { md: "left", xs: "left" },
        color: "#475467",
    },
    confirmBtn: {
        background: "#F1933C",
        border: "1px solid #F1933C",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        padding: "10px 18px",
        color: "#FFFFFF",
        "&:hover": {
            background: "#f1933ce6"
        }
    },
    cancelBtn: {
        fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        background: "#FFFFFF",
        border: "1px solid #D0D5DD",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        borderRadius: "8px",
        padding: "10px 18px",
        color: "#344054",
        "&:hover": {
            background: "#fcf5f5"
        }
    },
    nextBtn: {
        minWidth: '189px',
        minHeight: '44px',
        borderRadius: '8px',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        background: '#43BED8',
        border: '1px solid #43BED8',
        color: '#FFFFFF',
        "&:hover": {
            background: "#3CABC2",
            border: '1px solid #3CABC2',
        }
    }
}