import React from 'react'
import Illustration from "../../Latest/Images/Illustration1.png"
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import { illustrationStyle } from './style'
import { tokens } from 'theme'



const NoData = ({ loading = false, message = "Data will reflect once a transaction is submitted and verified" }: prop) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box sx={{ width: "100%", position: "relative", }}>
            {loading && <LoadingBarGraph />}
            <Box sx={{ ...illustrationStyle.noDataMainBox }}>
                <>
                    <img src={Illustration} />

                    <Typography variant='alertTitle' textAlign="center" color={colors.grey[900]} >Nothing to show here currently</Typography>

                    <Typography variant='body2' textAlign="center" color={colors.grey[600]}>{message}</Typography>
                </>
            </Box>
        </Box>
    )
}

export default NoData

interface prop {
    message?: string;
    loading?: boolean
}

const LoadingBarGraph = () => {
    return (
        <Box sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            height: '100%',
            background: 'white'
        }}>
            <CircularProgress color="secondary" />
        </Box>
    )
}