import { Box, Button, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import BottomBtn from 'Latest/2ndTierKyc/bottomBtn'
import { mainContainer } from './styles/index';
import { smallScreenBtnWrap } from 'Latest/Style/Style';
import FatcaInputFields from "./FatcaInputFields"
import * as Yup from 'yup';
import { useFormik } from "formik";
import Fields from "./Fields";
import React, { useEffect, useState } from "react";
import UploadField from 'jsx/components/Shared/V2/FileUploadFieldV2';
import { fatcaValidationSchema } from "./FatcaValidation";
import { CASH_FUND_ID } from '../../../../constants';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import { useAppSelector } from "hooks/reduxHooks";
import { useAppLoading } from "hooks/appLoading";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { downloadWNineForm } from "redux/actions";
import Loader from "jsx/components/Shared/Loader";


const Index = ({ formik, handleAPI, handleNext, handlePrevious }: any) => {

    const { push } = useHistory();
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const loading = useAppLoading('auth');
    
    const {countries } = useAppSelector(state => state.sharedData);
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const isFatcaAndCrs:any = userAccountDetails?.fatcaCrs;
    let  updatedCountries = countries.filter(con => con.label !== 'Pakistan')
    updatedCountries = updatedCountries.filter(con => con.label !== 'United States of America')

    const isWNineForm = formik.values["hasGreenCard"] === "Yes" || formik.values["hasTaxPayer"] === "Yes" || formik.values["usCitizen"] === "Yes" || formik.values["usResident"] === "Yes"
    const isTaxResident = formik.values["hasTaxResident"] === "Yes"
    const isTaxpayerNumberYes = formik.values["hasTaxpayerNum"] === "Yes"
    const isTaxpayerNumberNo = formik.values["hasTaxpayerNum"] === "No"
    const isWnineSubmitted = isFatcaAndCrs?.formSubmitted === "Yes";


    const handleSaveExit = async () => {
        const errors = await formik.validateForm();
        if (Object.keys(errors).length === 0) {
            // No validation errors, proceed with your save/exit logic
            handleAPI(true)
        } else {
            // There are validation errors, you can handle them or show a message
            push("myprofile?scroll=true")
        }

    }

    return (
        <>
            <Grid item xs={12} md={12} sx={{ pb: "32px" }}>
                <Box >
                    <Typography variant='h1' color={"#1D2939"} mb={"10px"}>FATCA & CRS Details</Typography>
                    <Typography variant="body1" color={"rgba(29, 41, 57, 0.70)"}>Ensuring global tax compliance</Typography>
                </Box>
                <Box sx={{ marginTop: "40px" }}>
                    <Typography variant="h6" color={"var(--Primary-500, #422E84)"}>FATCA</Typography>
                    <Typography variant="body2" sx={{ marginTop: "5px", width: "70%" }} color={"var(--Text-Para, rgba(29, 41, 57, 0.70))"}>The Foreign Account Tax Compliance Act (FATCA) requires foreign financial institutions to report U.S. taxpayers' financial accounts to the IRS.</Typography>
                </Box>
                <Box sx={{ marginTop: "30px" }}>
                    <Box sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" } }} gap={3}>
                        <FatcaInputFields types="radio" formik={formik} maxLen={0} fieldName={"usResident"} fieldData={Fields[0]} />
                        <FatcaInputFields types="radio" formik={formik} maxLen={0} fieldName={"usCitizen"} fieldData={Fields[1]} />
                    </Box>
                    <Box sx={{ display: "flex", marginTop: "20px", flexDirection: { md: "row", sm: "row", xs: "column" } }} gap={3}>
                        <FatcaInputFields types="radio" formik={formik} maxLen={0} fieldName={"hasGreenCard"} fieldData={Fields[2]} />
                        <FatcaInputFields types="radio" formik={formik} maxLen={0} fieldName={"hasTaxPayer"} fieldData={Fields[3]} />
                    </Box>

                    {/* <Button onClick={()=> fatcaFormik.submitForm()}>submit</Button> */}
                </Box>
                <Box>
                    {
                        isWNineForm &&
                        <>
                            <Box sx={{ marginBottom: "20px", marginTop: "20px" }}>
                                <Typography variant="h6" color={"var(--Primary-500, #422E84)"}>W-9 Form</Typography>
                                <Typography variant="body2" sx={{ marginTop: "5px", width: "70%" }} color={"var(--Text-Para, rgba(29, 41, 57, 0.70))"}>A W-9 form is an Internal Revenue Service (IRS) document used by U.S. taxpayers to provide their taxpayer identification number for tax reporting purposes.</Typography>
                                <ul>
                                    <li style={{ listStyle: "circle", color: "var(--Text-Para, rgba(29, 41, 57, 0.70))", fontSize: "14px", marginLeft: "30px" }}>Please <a href="/Images/wNineFormPdf/wNineForm.pdf" download={true} style={{ color: "var(--Primary-500, #422E84)", textDecoration: "underline", fontWeight: "bold" }}>click here</a> to download W9 form, its editable.</li>
                                    <li style={{ listStyle: "circle", color: "var(--Text-Para, rgba(29, 41, 57, 0.70))", fontSize: "14px", marginLeft: "30px" }}>Complete the W9 form, sign and upload it.</li>
                                </ul>
                            </Box>
                            <Box sx={{ width: { md: "500px", sm: "500px", xs: "100%" } }}>

                                <UploadField
                                    required
                                    acceptedType='application/pdf'
                                    buttonTitle="Upload W9 form"
                                    col={12}
                                    fieldName="wNineForm"
                                    formik={formik}
                                    inputFiles={isFatcaAndCrs && isWnineSubmitted?[{type:"application/pdf"}]:[]}
                                    maxFiles={1}
                                    title={""}
                                    style={{ height: "170px", '& svg ~ p': { fontSize: "14px", fontWeight: 500, textAlign: "center" } }}
                                    subTitle="Please sign & upload the attached document"
                                    toastErrMessage="'File must be a Pdf'"
                                />
                            </Box>
                        </>
                    }
                </Box>
                <Box sx={{ marginTop: "40px" }}>
                    <Typography variant="h6" color={"var(--Primary-500, #422E84)"}>CRS</Typography>
                    <Typography variant="body2" sx={{ marginTop: "5px", width: "70%" }} color={"var(--Text-Para, rgba(29, 41, 57, 0.70))"}>The Common Reporting Standard (CRS) is a global system for the automatic exchange of tax-related financial information.</Typography>
                </Box>
                <Box sx={{ display: "flex", marginTop: "20px", flexDirection: { md: "row", sm: "row", xs: "column" }, alignItems: "center" }} gap={3}>
                    <FatcaInputFields types="radio" formik={formik} fieldName={"hasTaxResident"} maxLen={0} fieldData={Fields[4]} />
                </Box>
                <Box sx={{ width: "100%", display: "flex", marginTop: "20px", justifyContent: "space-between", flexDirection: { md: "row", sm: "row", xs: "column" } }} gap={3}>
                    {isTaxResident &&  <FatcaInputFields types="selectWithLabel" formik={formik} maxLen={0} fieldName={"country"} fieldData={updatedCountries} /> }
                    {isTaxResident && <FatcaInputFields types="textField" formik={formik} maxLen={32} fieldName={"city"} fieldData={Fields[6]} />}
                </Box>

                {isTaxResident && <Box sx={{ display: "flex", marginTop: "20px", flexDirection: { md: "row", sm: "row", xs: "column" }, alignItems: "center" }} gap={3}>
                    <FatcaInputFields types="radio" formik={formik} maxLen={0} fieldName={"hasTaxpayerNum"} fieldData={Fields[7]} />
                    {
                        isTaxResident ? (
                            isTaxpayerNumberYes ? (
                                <FatcaInputFields types="textField" formik={formik} maxLen={64} fieldName={"taxPayerNumber"} fieldData={Fields[8]} />
                            ) : isTaxpayerNumberNo ? (
                                <FatcaInputFields types="select" formik={formik} maxLen={0} fieldName={"selectReason"} fieldData={Fields[9]} />
                            ) : (
                                ""
                            )
                        ) : (
                            ""
                        )
                    }
                </Box>
                }
                <Box sx={{ marginTop: "20px" }} >
                    <FatcaInputFields types="checkBox" formik={formik} maxLen={0} fieldName={"confirmAll"} fieldData={Fields[10]} />
                </Box>
            </Grid>
            <BottomBtn handleSaveExit={handleSaveExit} handlePrevious={handlePrevious} handleNext={() => formik.handleSubmit()} loading={loading } showBackBtn={true} />
        </>
    )
}

export default Index