import React, { useEffect } from "react";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { LocalUrl } from "redux/middlewares/api";

interface IProps {
    openModal: boolean;
    setOpenModal: any
    id: any
}

const DeleteModal = (props: IProps) => {
    const [getArray, setGetArray] = useState<any>([])
    const fetchData = async () => {
        const response = await axios.get(LocalUrl + `QuestionAnswer/${(props.id).toString()}`)
        setGetArray(response.data)
    }
    const submitHandler = async () => {
        setGetArray(getArray.isRetire = true)
        const idss = getArray.riskProfileCategory.id;
        setGetArray(getArray.riskProfileCategory = idss)
        const body = getArray;
        props.setOpenModal(false);
    }
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div>
            <Modal animation={false} onHide={() => props.openModal} show={props.openModal} >
                <Modal.Header >
                    <Modal.Title>Retire Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to retire this question?

                    {/* <p>
                        {props.id}
                    </p> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => props.setOpenModal(false)} variant="secondary">
                        No
                    </Button>
                    <Button onClick={() => submitHandler()} variant="primary" >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
export default DeleteModal;