import { Box, Grid, Typography } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import { formikCheckBoxFieldStyle, formikRadioFieldStyle } from '../style/formikRadioFieldStyle';

const FormikCheckboxField = ({
    col = 6,
    fieldName,
    fields,
    formik,
    label,
    onChange = () => null,
    required = false,
    singleLine = false,
    style

}: FormikCheckboxFieldProps) => {
    const formikTouch = formik.touched as never;
    const formikError = formik.errors as never;
    return (
        <>
            <Grid item xs={12}>
                {label && (
                    <Typography sx={{ mb: "6px" }} variant='body2'>
                        {label}
                        {required && <span className='ml-1 text-danger'>*</span>}
                    </Typography>
                )}

                <Box
                    // .className={singleLine ? 'singleLine ' : ''}
                    sx={{ ...style, display: "flex", flexDirection: singleLine ? "row" : "column", flexWrap: 'wrap', gap: singleLine ? "14px" : "12px", ".form-check-label": { ml: "12px", mt: 0 } }}

                >
                    {fields.map(val => (
                        <Form.Check
                            key={val.label}
                            checked={formik?.values[fieldName] && formik.values[fieldName].includes(val?.value)}
                            disabled={!!val.disabled}
                            id={val.value.toString()}
                            label={val.label || val.value}
                            name={fieldName}
                            onChange={formik.getFieldProps(fieldName).onChange}
                            type='checkbox'
                            style={formikCheckBoxFieldStyle.formCheckV1}
                            value={val.value}
                        />
                    ))}
                </Box>

                {formikTouch[fieldName] && formikError[fieldName] && (
                <span className='mt-2 text-danger'>
                    {formikError[fieldName]}
                </span>
                )}

            </Grid>

        </>
    );
};

export default memo(FormikCheckboxField);

interface FormikCheckboxFieldProps {
    col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    disabled?: boolean;
    fieldName: string;
    fields: Array<{ label: string; value: string; disabled?: boolean }>;
    formik: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getFieldProps: any;
        touched: FormikTouched<unknown>;
        errors: FormikErrors<unknown>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values: any;
    };
    label?: string;
    required?: boolean;
    singleLine?: boolean;
    onChange?: any;
    style?: any
}
