const auth = '/auth';
const employer = '/employer';
const subscribtion = "/promotional-email";

export const links = {
  auth,
  subscribtion,
  companyProfile: '/thematic/:id',
  confirmation: '/Activate',
  employer,
  employerDashboard: `${employer}/dashboard`,
  adminDashboard: `/AdminDashboard`,
  employerContributions: `${employer}/contributions`,
  employerEmployees: `${employer}/employees`,
  investments: `${employer}/investments`,
  employerScreen: '/employer',
  forgot: `${auth}/forgot`,
  resetPassword: `${auth}/resetPassword/:userId/:token`,
  fundDetails: '/fund/:id',
  fundsOverview: '/funds-overview',
  home: '/',
  learn: '/learn',
  linkAccounts: '/link-accounts',
  login: `${auth}/login`,
  accountSetupForm: '/account-setup-form',
  documentsUpload: '/documents-upload',
  myAccounts: '/my-accounts',
  otp: `${auth}/sendotp`,
  otpVerify: `${auth}/verify-otp`,
  overview: '/overview',
  paymentOptions: '/payment-options',
  pension: '/pension',
  pensionAccount: '/pension-account',
  portfolio: '/portfolio',
  riskProfile: `/risk-profile`,
  riskScore: '/risk-score',
  setting: `/settings`,
  signup: `${auth}/signup`,
  verifyEmail: `${auth}/verifyEmail/:userId/:token/:email`,
  verifyEmailToken: `${auth}/verifyEmail/:userId/:token`,
  resendEmailVerification: `${auth}/resendEmailVerification`,
  taxSavingAccount: '/tax-saving-account',
  thematic: '/thematic',
  etfBasket: '/etfbasket',
  basket: '/basket',
  transactionHistory: '/transaction-history',
  adminForm: '/Form',
  adminView: '/risk-view',
  equityAllocation: '/Equity-Allocation',
  EquityForm: '/Equity-Form',
  RiskProfileView: '/RiskProfile',
  TradeExecution: '/Trade-Execution',
  TradeFiles: '/Trade-Files',
  FileDetail: '/File-Detail',
  StockDetail: '/Stock-Detail',
  GeneralInvestment: '/GeneralInvestment',
  Goal: '/Goal',
  Invest: '/Invest',
  PayNow: '/payment',
  TermsCondition: '/terms&condition',
  PayConfirmation: '/payment/confirmation',
  PayReceipt: '/Receipt',
  Cashfund: '/Cashfund',
  newUser: "/newUser",
  myprofile: "/myprofile",
  settings: "/settings",
  help: "/help",
  support: "/support",
  tierKyc: "/2nd-tier-kyc",
  viewDetail:"/viewDetail",
  PayMobReceipt: '/PayMobReceipt',
  transactions: '/transactions',
  status:`${subscribtion}/subscribtion`,
  finance:"/finance"
};
