import { Card, Col, Row } from 'react-bootstrap';
import { memo, useEffect, useState } from 'react';
import InputField, { FormikFieldProps } from 'jsx/components/Shared/FormikInputFieldLatest';
import FormikRadioField from 'jsx/components/Shared/V2/FormikRadioFieldLatestV2';
import '../styles/ChooseInvestment.scss';
import { useAppSelector } from 'hooks/reduxHooks';
import { LABELS } from '../chooseInvestmentsLabel';
import { Goals, PLAN_TYPES } from '../../../../constants';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import ButtonsRow from '../ButtonsRowLatest';
import { containerMain, containerMainBox, HeadingStyle, mainColMargin } from 'Latest/Style/Main';
import { useAppLoading } from 'hooks/appLoading';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import targetIcon from "Latest/Images/svg/target.svg"
import calendarIcon from "Latest/Images/svg/calendar.svg"
import { mainContainerV2 } from 'Latest/Style/Style';
import { toast } from 'react-toastify';
import { useIsOnline } from 'react-use-is-online';

const ChooseInvestment = ({ chooseInvestmentActiveStep, formik, goalId, handleAPIsOnStart, handleGoalBack, nextStep, questionAnswers, setChooseInvestmentActiveStep }: any) => {
  const loadingStates = [
    useAppLoading('portfolio'),
    useAppLoading('questionAnswer'),
    useAppLoading('payment'),
    useAppLoading('sharedStateData'),
  ];
  const { isOnline } = useIsOnline();
  const [count, setCount] = useState(0)
  const loading = loadingStates.some((loadingState) => loadingState)

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { userAccountDetails } = useAppSelector((state: any) => state.auth);
  const [otherGoal, setOtherGoal] = useState(false);

  useEffect(() => {
    const frequencyAge: number = userAccountDetails?.identityCard?.age;
    formik.setFieldValue('userAge', frequencyAge);
  }, []);


  useEffect(() => {
    if (formik.values.plan_type == PLAN_TYPES.ACCUMULATE_AMOUNT) {
      formik.setFieldValue('initial_amount', 10000)
      formik.setFieldValue('retirement_amount', 50000)
      formik.setFieldValue('desired_retirement_age', 3)
      formik.setFieldValue('monthly_saving', 0)
    } else if (formik.values.plan_type == PLAN_TYPES.MONTHLY_CONTRIBUTIONS) {
      formik.setFieldValue('initial_amount', 10000)
      formik.setFieldValue('monthly_saving', 500)
      formik.setFieldValue('desired_retirement_age', 3)
      formik.setFieldValue('retirement_amount', 0)
    }
  }, [formik.values.plan_type])

  const expected = (
    <Row>
      <Col className='mb-4' md='12'>
        <InputField
          col={12}
          formik={formik}
          inputEnd={"PKR"}
          isStep={true}
          label={LABELS[goalId]?.AMOUNT_HEADING}
          max={20}
          maxLength={20}
          name='retirement_amount'
          placeholder='Enter amount'
          thousandSeparator={true}
          topInfo={LABELS[goalId]?.AMOUNT_DESCRIPTION}
          type='number'
        />
      </Col>

      {formik.getFieldProps('goalId')?.value === Goals.TravelTime ? (
        <Col className='mb-3' md='12'>
          <InputField
            col={12}
            formik={formik}
            inputEnd={"MONTH"}
            isStep={false}
            label={LABELS[goalId]?.MONTH_HEADING}
            max={4}
            maxLength={4}
            name='desired_retirement_age'
            placeholder='Enter months'
            topInfo={LABELS[goalId]?.Month_DESCRIPTION}
            type='number'
          />
        </Col>
      ) : (
        <Col className='mb-3' md='12'>
          <InputField
            col={12}
            formik={formik}
            inputEnd={"YEARS"}
            isStep={false}
            label={LABELS[goalId]?.YEAR_HEADING}
            max={3}
            maxLength={3}
            name='desired_retirement_age'
            placeholder='Enter years'
            topInfo={LABELS[goalId]?.YEAR_DESCRIPTION}
            type='number'
          />
        </Col>
      )}
    </Row>
  );
  const monthly = (
    <Row>
      <Col className='mb-3' md='12'>
        <InputField
          col={12}
          formik={formik}
          inputEnd={"PKR"}
          isStep={true}
          label={LABELS[goalId]?.SAVING_AMOUNT_HEADING}
          max={20}
          maxLength={20}
          name='monthly_saving'
          placeholder='Enter amount'
          thousandSeparator={true}
          topInfo={LABELS[goalId]?.SAVING_AMOUNT_DESCRIPTION}
          type='number'
        />
      </Col>

      {formik.getFieldProps('goalId')?.value === Goals.TravelTime ? (
        <Col className='mb-3' md='12'>
          <InputField
            col={12}
            formik={formik}
            inputEnd={"MONTH"}
            isStep={false}
            label={LABELS[goalId]?.MONTH_HEADING}
            max={4}
            maxLength={4}
            name='desired_retirement_age'
            placeholder='Enter months'
            topInfo={LABELS[goalId]?.Month_DESCRIPTION}
            type='number'
          />
        </Col>
      ) : (
        <Col className='mb-3' md='12'>
          <InputField
            col={12}
            formik={formik}
            inputEnd={"YEARS"}
            isStep={false}
            label={LABELS[goalId]?.YEAR_HEADING}
            max={3}
            maxLength={3}
            name='desired_retirement_age'
            placeholder='Enter years'
            topInfo={LABELS[goalId]?.YEAR_DESCRIPTION}
            type='number'
          />
        </Col>
      )}
    </Row>
  );

  const returnSelectForm = () => {
    // @ts-ignore
    const type = formik.getFieldProps('plan_type')?.value;
    if (type === 'AccumulateAmount') return expected;
    if (type === 'MonthlyContributions') return monthly;
    return null;
  };

  useEffect(() => {  // for internet check
    if (count !== 0 && questionAnswers?.length) {
      nextStep()
    }
  }, [questionAnswers])

  const handleNext = () => {
    // nextStep() old
    if (isOnline) { // for internet check

      if (questionAnswers?.length) {
        nextStep()
      } else {
        setCount(count + 1)
        handleAPIsOnStart()
      }

    } else {
      toast.error('Internet is required to proceed', { theme: "colored" });
    }

  }

  const buttonHtml = () => {
    return (
      <ButtonRowV1 handleNext={handleNext} loadingNextBtn={loading} handleBack={handleBack} btnTitleNext={"Continue"} />
    )

  }

  const handleBack = () => {
    if (chooseInvestmentActiveStep == 0) {
      handleGoalBack()
    } else {
      setChooseInvestmentActiveStep(0)
    }
  }
  return (
    <>
      <Box sx={{ background: "#F8FAFB", height: "100vh" }}>

        <Container maxWidth={'lg'} sx={{ pt: { xs: "24px", md: "40px" }, }} disableGutters={smScreen}>

          <Box
            sx={{
              ...mainContainerV2,
              // borderRadius: "3px 3px 8px 8px",
              // background: "#FFF",
              // boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
              // padding: { xs: "16px", md: "20px 40px" }
            }}
          >

            <Typography variant='h1' color={"#1D2939"} textAlign={"center"}>Plan your goal</Typography>

            <>
              <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <FormikRadioField
                  singleLine
                  col={12}
                  customStyle={{ height: "8rem", display: "flex", alignItems: "center" }}
                  fieldName='plan_type'
                  fields={[
                    {
                      // label: LABELS[goalId].PLAN_MONTHLY,
                      label: "I know my monthly contribution",
                      value: 'MonthlyContributions',
                      // title: "MONTHLY"
                      icon: calendarIcon

                    },
                    {
                      // label: LABELS[goalId].I_KNOW_THE_AMOUNT,
                      label: "I know my target amount",
                      value: 'AccumulateAmount',
                      // title: "TOTAL"
                      icon: targetIcon
                    },

                  ]}
                  formik={formik}
                  label='Select One Option'
                />
              </form>
            </>


            {!smScreen && <Box sx={{ mt: { xs: "24px", md: "40px" } }} width={"100%"}>
              {buttonHtml()}
            </Box>}

          </Box>


          {smScreen && <Box sx={{ p: "8px 16px", mt: "24px", background: "white" }}>
            {buttonHtml()}
          </Box>}

        </Container>
      </Box>

      {/* <Container maxWidth={'md'} disableGutters={smScreen}>

        <Typography variant={"h5"} sx={{ p: { xs: '16px', md: "40px 0px 16px 0px" }, background: { xs: "#f9fafb", md: "transparent" } }} >How much do you want to save for your goal?</Typography>

        <Grid container sx={{ borderRadius: "12px", border: { xs: "none", md: "1px solid #EAECF0" }, background: "#FFF", p: { xs: "16px", md: "24px" } }}>

          <Grid item xs={12} md={12}>
            {chooseInvestmentActiveStep == 0 ?
              <>
                <form onSubmit={formik.handleSubmit}>
                  <FormikRadioField
                    singleLine
                    col={12}
                    customStyle={{ height: "8rem", display: "flex", alignItems: "center" }}
                    fieldName='plan_type'
                    fields={[
                      {
                        // label: LABELS[goalId].I_KNOW_THE_AMOUNT,
                        label: "I know my target amount",
                        value: 'AccumulateAmount',
                        // title: "TOTAL"
                      },
                      {
                        // label: LABELS[goalId].PLAN_MONTHLY,
                        label: "I know my monthly contribution",
                        value: 'MonthlyContributions',
                        // title: "MONTHLY"
                      },
                    ]}
                    formik={formik}
                    label='Select One Option'
                  />
                </form>
              </>
              :
              <form onSubmit={formik.handleSubmit}>
                <Col className="mb-3" md='12' style={{ marginTop: "16px" }} >
                  <InputField
                    col={12}
                    inputEnd={"PKR"}
                    formik={formik}
                    isStep={true}
                    label={LABELS[goalId]?.INITIAL_AMOUNT}
                    max={20}
                    maxLength={20}
                    name='initial_amount'
                    placeholder='Enter amount'
                    thousandSeparator={true}
                    topInfo='Enter amount'
                    type='number'
                  />
                </Col>
                <Col md='12' >
                  {returnSelectForm()}
                </Col>
              </form>
            }
          </Grid>

          <Grid item md={12} xs={12} sx={{ pt: "24px" }}>
            <ButtonRowV1 handleNext={nextStep} loadingNextBtn={loading} handleBack={handleBack} />
          </Grid>

        </Grid>

      </Container> */}

    </>
  );
};

export default memo(ChooseInvestment);

export interface ChooseInvestmentProps {
  chooseInvestmentActiveStep: number;
  formik: any;
  goalId: any;
}
