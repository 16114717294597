import * as Yup from 'yup';
import { ACCOUNT_SETUP_MSGS } from './AccountSetupMessages';

const max_limit = 200;
const mobile_length = 12;
const cnic_length = 13;
const INVALID_DATE = 'Invalid Date';
const IBAN_length = 24


export const motherNameValidation = Yup.object({
  motherName: Yup.string().matches(
    /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
    'Mother Name must contain only alphabetic value'
  ).required().nullable().label("Mother maiden name"),
})

export const ibanValidation = Yup.object({
  IBANnumber: Yup.string()
    .matches(
      /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/,
      "Invalid IBAN format. Must be 2 letters followed by 2 digits, and then up to 30 alphanumeric characters."
    )
    .nullable()
    .required("IBAN is required."),
})

export const accountSetupFormValidation = Yup.object({
  name: Yup.string().matches(
    /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
    'Name must contain only alphabetic value'
  ).required().nullable().label('Name'),
  fatherName: Yup.string().matches(
    /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
    'Name must contain only alphabetic value'
  )
    .min(3, 'Father name is too short')
    .max(max_limit, 'Father name is too long')
    .required('Father Name is required')
    .nullable(),

  // motherName: Yup.string().matches(
  //   /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
  //   'Name must contain only alphabetic value'
  // )
  //   .min(3, 'Mother maiden name is too short')
  //   .max(max_limit, 'Father name is too long')
  //   .required('Mother maiden name is required')
  //   .nullable(),
  countryOfStay: Yup.string().required('Country of stay is required').nullable(),

  nationalIdentityNumber: Yup.string()
    .required('CNIC is required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'CNIC number is not valid'
    )
    .length(cnic_length, 'CNIC number is not valid'),
  // IBANnumber: Yup.string()
  //   .matches(
  //     /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/,
  //     "Invalid IBAN format. Must be 2 letters followed by 2 digits, and then up to 30 alphanumeric characters."
  //   )
  //   .nullable()
  //   .required("IBAN is required."),
  nationalIdentityIssuanceDate: Yup.date()
    .required('CNIC Issuance date is required')
    .typeError('Invalid date')
    .test('required', '', function (value, context) {
      const currentDate = new Date(); // Get the current date
      const cnicIssueDate = new Date(context.parent.nationalIdentityIssuanceDate);
      const cnicExpiryDate = new Date(context.parent.nationalIdentityExpiryDate);
      const dateOfBirth = new Date(context.parent.dateOfBirth);
      const lifeTimeBit = context.parent.lifeTimeBit

      if (context.parent.nationalIdentityIssuanceDate == undefined) {
        return this.createError({
          message: ACCOUNT_SETUP_MSGS.CNIC_ISSUE_REQUIRED_MESSAGE
        });
      }
      if (cnicIssueDate > currentDate) {
        return this.createError({
          message: ACCOUNT_SETUP_MSGS.INVALID_MESSAGE
        });
      }
      if (cnicIssueDate >= cnicExpiryDate && !lifeTimeBit) {
        return this.createError({
          message: ACCOUNT_SETUP_MSGS.INVALID_MESSAGE
        });
      }
      if (cnicIssueDate <= dateOfBirth) {
        return this.createError({
          message: ACCOUNT_SETUP_MSGS.CNIC_ISS_DATE_LT_DOB
        });
      }
      if (cnicIssueDate.toString() === INVALID_DATE) {
        return this.createError({
          message: ACCOUNT_SETUP_MSGS.INVALID_MESSAGE
        });
      }

      return true;
    }),
  nationalIdentityExpiryDate: Yup.string()
    .required('CNIC expiry date is required')
    .test('required', '', function (value, context) {
      const currentDate = new Date(); // Get the current date
      const cnicIssueDate = new Date(context.parent.nationalIdentityIssuanceDate);
      const cnicExpiryDate = new Date(context.parent.nationalIdentityExpiryDate);
      const dateOfBirth = new Date(context.parent.dateOfBirth);
      const lifeTimeBit = context.parent.lifeTimeBit

      if (!lifeTimeBit) {
        if (context.parent.nationalIdentityExpiryDate == undefined) {
          return this.createError({
            message: ACCOUNT_SETUP_MSGS.CNIC_EXPIRED_REQUIRED_MESSAGE
          });
        }
        if (cnicExpiryDate < currentDate) {
          return this.createError({
            message: ACCOUNT_SETUP_MSGS.INVALID_MESSAGE
          });
        }
        if (cnicExpiryDate <= cnicIssueDate) {
          return this.createError({
            message: ACCOUNT_SETUP_MSGS.INVALID_MESSAGE
          });
        }
        if (cnicExpiryDate <= dateOfBirth) {
          return this.createError({
            message: ACCOUNT_SETUP_MSGS.CNIC_EXP_DATE_LT_DOB
          });
        }

        if (cnicExpiryDate.toString() === INVALID_DATE && !context?.parent?.lifeTimeBit) {
          return this.createError({
            message: ACCOUNT_SETUP_MSGS.INVALID_MESSAGE
          });
        }
      }
      return true;
    }).nullable(),
  gender: Yup.string().required('Gender is required'),
  dateOfBirth: Yup.string().required('Date of birth is required').nullable(),
  email: Yup.string()
    .max(max_limit, 'Email address is too Long!')
    .email()
    .required()
    .label('Email'),
  // mobile: Yup.string()
  //   .required('Mobile number is required')
  //   .length(mobile_length, 'Mobile number is not valid')
});

export const additionalDetailsFormValidation = Yup.object({

  motherName: Yup.string().matches(
    /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
    'Mother Name must contain only alphabetic value'
  ).required().nullable().label('Mother name'),

  IBANnumber: Yup.string()
    .matches(
      /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/,
      "Invalid IBAN format. Must be 2 letters followed by 2 digits, and then up to 30 alphanumeric characters."
    )
    .nullable().required().label('IBAN number'),

  // ukn: Yup.string().required().nullable().label('UKN number'),

  isStockMarketAccountOpen: Yup.string().required().nullable().label('PSX stock market account'),

  ukn: Yup.string()
    .when('isStockMarketAccountOpen', {
      is: (value: any) => value === 'Yes',
      then: Yup.string().required('UKN number is required for stock market account'),
      otherwise: Yup.string().nullable().notRequired()
    })
    .label('UKN number'),


});
