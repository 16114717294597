import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import infoIcon from "Latest/Images/svg/InfoOutlined.svg"
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import QRCode from "react-qr-code";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: "24px",
    borderRadius: "12px",
    width: { xs: "95%", sm: "auto", md: "512px" },
};

export default function QRCodeModal({ handleClose, open }: any) {

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                        <Typography color={"secondary"} variant='h5'>Scan QR code</Typography>

                        {/* <IconButton aria-label="close" component="label"  */}
                        <CloseIcon sx={{ cursor: "pointer", }} onClick={handleClose} />
                        {/* </IconButton> */}
                    </Box>

                    <Typography sx={{ pt: "8px", pb: "20px" }} variant='body2'> Use your mobile phone to scan the QR code  </Typography>

                    <Box sx={{
                        height: "auto", margin: "0 auto", maxWidth: "128px", width: "100%", borderRadius: "8px",
                        background: "#F9FAFB", py: "16px"
                    }}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={""}
                            viewBox={`0 0 256 256`}
                        />
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}