import axios from "axios";
import React, { useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row, Table, } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { onFileUpload } from "redux/reducers/sharedStateData";
import { links } from "static/links";
import { promises } from "stream";

const FileTypes = [
    { label: "Employee", value: "1" },
    { label: "Organization", value: "2" },
    { label: "Bloomberg", value: "3" },
]

const TradeExecution = () => {
    // const toBase64 = (file: any) => new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = error => reject(error);
    // });
    const [fileType, setFileType] = useState<any>('');
    const [fileStream, setFileStream] = useState<any>();
    const dispatch = useDispatch();
    const history = useHistory();

    const submitHandler = async () => {
        const formData = new FormData();
        formData.append('FileContent', fileStream[0]);
        formData.append('FileType', fileType);
        formData.append('FileName', fileStream[0]?.name);
        dispatch(onFileUpload(formData))
    }

    return (
        <>
            <h3 className='mb-3'> Uppload Files</h3>
            <Card>
                <Card.Body>
                    <Row >
                        <Col md="3" style={{ marginBottom: '3%' }}>
                            <Form.Label >
                                File Type
                            </Form.Label>
                            <Select
                                onChange={(e) => setFileType(e?.label)}
                                options={FileTypes}
                            />
                        </Col>
                        <Col md="1" />
                        <Col md="2">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>
                                    Choose File:
                                </Form.Label>
                                <input
                                    accept=".xlsx"
                                    onChange={(e) => setFileStream(e.target?.files)}
                                    type="file"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    </Row>
                </Card.Body>
                <div style={{ textAlign: 'center' }}>
                    <button className="buttonDisable"
                        onClick={() => submitHandler()} style={{ borderRadius: '10rem', height: '2.5rem', width: '20rem', alignSelf: 'center', color: 'white', backgroundColor: '#603AB0', borderColor: 'white', marginBottom: '2%' }}>
                        Submit
                    </button>
                </div>
            </Card>
        </>
    )
}
export default TradeExecution;