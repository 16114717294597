import { Box, Typography } from '@mui/material'
import React from 'react'
import { submitInfoStyle } from '../style'
import Checkbox from '@mui/material/Checkbox';
import ModalNextOfKin from 'Latest/MyProfileV1/NextOfKin/modalNextOfKin';
import ModalZakaatDec from 'Latest/MyProfileV1/ZakatDeclaration/modalZakaat';
import { useAppSelector } from 'hooks/reduxHooks';
import IconInfo from "Latest/Images/svg/IconInfo.svg";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const SubmitInfoNextOfKin = ({ checkNextOfKinbeneficiaries, checkZakaatDocDefault }: any) => {
    return (

        <Box sx={submitInfoStyle.mainCard}>
            <Typography sx={submitInfoStyle.mainHeading}>
                <img src={IconInfo} />
                Submit remaining information
            </Typography>
            <Typography variant='body1' sx={{ ...submitInfoStyle.alignContent, ...submitInfoStyle.text, display: "block" }}>
                <ModalNextOfKin checkNextOfKinbeneficiaries={checkNextOfKinbeneficiaries} />&nbsp;next of kin details (required).
            </Typography>


            {/* <Typography variant='h6'>Submit remaining information </Typography>

            <Box sx={submitInfoStyle.secondaryCardMainBox}>
                <Box sx={submitInfoStyle.secondaryCard}  >
                    <Checkbox {...label} checked={checkNextOfKinbeneficiaries} sx={{ p: 0, cursor: "default" }} color="success" />
                    <Typography variant='body1' sx={submitInfoStyle.alignContent}>Add&nbsp;<ModalNextOfKin checkNextOfKinbeneficiaries={checkNextOfKinbeneficiaries} />&nbsp;details (required)</Typography>
                </Box>

            </Box> */}
        </Box>
    )
}

export default SubmitInfoNextOfKin