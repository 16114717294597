import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import useStylesAlertWarning from 'Latest/ComponentV1/CustomMakeStyle/customWarningAlert';
import { useAppSelector } from 'hooks/reduxHooks';
import React from 'react'
import { useHistory } from 'react-router-dom';
import IconWarning from "Latest/Images/svg/IconWarning.svg"


const FreezAccWarningAlert = () => {

    const classes = useStylesAlertWarning();
    const { userAccountDetails } = useAppSelector(state => state.auth);

    return (
        <>
            <Box>
                <Alert severity="warning" className={classes.customAlert}
                    icon={false}>
                    <AlertTitle className={classes.customAlertTitle} sx={{ fontWeight: 500 }}>
                        <img src={IconWarning} />
                        Your account has been blocked for an unspecified period of time
                    </AlertTitle>
                    <Typography className={classes.customText}>{userAccountDetails?.restriction?.comment}</Typography>
                </Alert>
            </Box>
        </>
    )
}

export default FreezAccWarningAlert;