import { Col } from 'react-bootstrap';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { memo } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { Box, Typography } from '@mui/material';

const AuthInputField = ({
  autoFocus = false,
  bottomInfo,
  className,
  disabled = false,
  formik,
  isMutual = false,
  label,
  maxLength,
  minLength,
  name,
  nestedFieldName,
  placeholder,
  required = false,
  topInfo,
  type = 'text'
}: AuthInputFieldProps) => {
  const formikTouch = formik.touched as any;
  const formikError = formik.errors as any;
  let fields: any = formik.getFieldProps(name);
  fields = {
    ...fields,
    value: fields.value || ''
  }

  let FieldError = "";
  let FieldTouch = false;
  let NestedFieldError = "";
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[name?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[name?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[name];
    FieldTouch = formikTouch[name];
  }

  return (
    <Box >
      {label && (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "6px" }}>
          <Typography variant='body2' color="#1D2939">
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </Typography>
        </Box>
      )}


      <TextField
        // autoComplete="email"
        autoFocus={autoFocus}
        inputProps={{
          autoComplete: 'off',
          maxlength:maxLength,
       }}
        // className={"email_Input" + " " + className}
        disabled={disabled}
        fullWidth={true}
        // id="email"
        // margin="normal"
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        placeholder={placeholder}
        required={true}
        type={type}
        {...fields}
      />
      {bottomInfo && (
        <Box className='small-info mb-2 mt-1 text-muted d-flex'>{bottomInfo}</Box>
      )}

      {FieldTouch && FieldError && (
        <span className='mt-1 text-danger'>
          {FieldError}
        </span>
      )}

      {NestedFieldTouch && NestedFieldError && (
        <span className='mt-1 text-danger'>
          {NestedFieldError}
        </span>
      )}

    </Box>
  );
};

export default memo(AuthInputField);

interface AuthInputFieldProps {
  autoFocus?: boolean;
  disabled?: boolean;
  formik: FormikFieldProps;
  label: string | JSX.Element;
  maxLength?: number;
  minLength?: number;
  name: any;
  nestedFieldName?: any;
  placeholder: string;
  required?: boolean;
  topInfo?: string;
  type?: string;
  bottomInfo?: string | JSX.Element;
  isMutual?: boolean;
  className?: any
}

export interface FormikFieldProps {
  getFieldProps: (field: string) => void;
  touched: FormikTouched<unknown>;
  errors: FormikErrors<unknown>;
  values: FormikValues;
}
