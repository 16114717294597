/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Field } from 'jsx/components/Shared/FormikSelectField';
import { WEALTH_API_BASE_URL } from '../../constants';

const initialState: InitialState = {
  banksList: [],
  paymentAcc: [],
  confirmPayment: false,
  serviceFee: []
};

const name = 'payment';
const paymentSlice = createSlice({
  initialState,
  name,
  reducers: {
    banksListSuccess: (state, action: PayloadAction<any>) => {
      const banks: Array<Field> = [];
      action.payload.forEach((item: bankProps) => {
        banks.push({ label: item.name, value: item.id.toString() });
      });
      state.banksList = banks;
    },
    getPaymentAccAction: (state, action) => {
      state.paymentAcc = action.payload;
    },
    getServiceFeeAction: (state, action) => {
      state.serviceFee = action.payload;
    },
    confirmPaymentModalAction: (state, action) => {
      state.confirmPayment = action.payload;
    }
  }
});

export const { confirmPaymentModalAction, getPaymentAccAction, getServiceFeeAction } = paymentSlice.actions;

export default paymentSlice.reducer;

//? APIS ACTIONS

export const onGetBanksList = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'PaymentOption/GetBanks'
  },
  type: 'banksList'
});

export const onBankTransfer = (data: bankTransferPost) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'PaymentOption/BankTransfer'
  },
  type: 'bankTransfer'
});

// ///////////////////////////////////

export const OnlineBankFormValue = (data: any, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    formData: true,
    method: 'post',
    onSuccess: onSuccess,
    errorToast: true,
    url: `${WEALTH_API_BASE_URL}/api/payment`
  },
  type: 'bankTransfer'
});

export const NIFTBankFormValue = (data: any, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    onSuccess: onSuccess,
    errorToast: true,
    url: `${WEALTH_API_BASE_URL}/api/payment`
  },
  type: 'bankTransfer'
});

export const getServiceFee = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `${WEALTH_API_BASE_URL}/api/Payment/serviceFee`,
    onSuccess: getServiceFeeAction,
  },
  type: 'getServiceFee'
});

export const getPaymentAcc = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `${WEALTH_API_BASE_URL}/api/BankAccount`,
    onSuccess: getPaymentAccAction,
  },
  type: 'getPaymentAccount'
});

export const SellCf = (data: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    onSuccess: onSuccess,
    onFail: onFail,
    errorToast: true,
    url: `${WEALTH_API_BASE_URL}/api/CashFundPendingProcure`
  },
  type: 'SellCf'
});

export const PostRedemption = (data: any, param: { id: any, category: any }, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    onSuccess: onSuccess,
    onFail: onFail,
    url: `${WEALTH_API_BASE_URL}/api/Redemption/${param.id}/${param.category}`
  },
  type: 'PostRedemption'
});

export const verifyWithdrawalOtp = (data:any ,param: { id: any, category: any }, onSuccess:(message: any, response: any) => void, onFail: (message: string, response: any) => void ) => ({
  payload: {
    apiName: name,
    method: 'post',
    data,
    errorToast: true,
    onSuccess,
    onFail,
    url: `${WEALTH_API_BASE_URL}/api/Redemption/confirm/${param.id}/${param.category}`
  },
  type: 'verifyWithdrawalOtp'
});
// ? Types

export interface bankProps {
  id: number;
  name: string;
}

interface InitialState {
  banksList: Array<Field>;
  paymentAcc: any;
  confirmPayment: boolean;
  serviceFee: any
}

export interface bankTransferPost {
  AccTitle: string;
  AccNumber: string;
  BankId: number;
}

export interface OnlineBankFormValues {
  accountNumber: string;
  accountTitle: string;
  bankName: any;
  proofOfPayment?: any
}