import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CASH_FUND_WEALTH_BASE_URL, STOCK_DETAIL_BASE_URL, WEALTH_API_BASE_URL } from '../../constants';
import mockDashboardData from '../../mockData/dashboardData.json';

const initialState: InitialState = {
  allDashboardData: null,
  dashboardDetail: "",
  dashboardPotAllocation: null,
};

const name = 'dashboard';
const dashboardSlice = createSlice({
  initialState,
  name,
  reducers: {
    allDashboardDataSuccess: (state, action: PayloadAction<any>) => {
      state.allDashboardData = action.payload;
      //state.allDashboardData = mockDashboardData.mockDashboardData;
    },
    onGetDashboardAction: (state, action: PayloadAction<any>) => {
      state.dashboardDetail = action.payload
    },
    onGetDashboardPotAllocationAction: (state, action: PayloadAction<any>) => {
      state.dashboardPotAllocation = action.payload
    }
  }
});

export const { allDashboardDataSuccess, onGetDashboardAction, onGetDashboardPotAllocationAction } = dashboardSlice.actions;
export default dashboardSlice.reducer;

//? APIS ACTIONS

export const onGetAllDashboardData = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: allDashboardDataSuccess,
    url: `${STOCK_DETAIL_BASE_URL}/api/portfolio`
  },
  type: 'onGetAllDashboardData'
});

export const onGetDashboard = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onGetDashboardAction,
    url: `${WEALTH_API_BASE_URL}/api/Portfolio/Summary`,
  },
  type: 'onGetDashboard'
});
export const onGetDashboardPotAllocation = (portfolioAmount:number) => ({
  payload: {
    apiName: "CFPotAllocation",
    method: 'get',
    onSuccess: onGetDashboardPotAllocationAction,
    url: `${CASH_FUND_WEALTH_BASE_URL}/api/CashFund/allocations/${portfolioAmount}`,
  },
  type: 'onGetDashboardPotAllocation'
});

// ? Types

interface InitialState {
  allDashboardData: any | null;
  dashboardDetail: any;
  dashboardPotAllocation: any
}
