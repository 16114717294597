import { useFormik } from 'formik';
import Big from 'big.js';
import { ReactElement, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {
  addGoal,
  getInvestorGoals,
  onGetAccountSetup,
  onPostUserAnswers,
  onSubmitDocuments,
  onPostUserAnswersRiskProfile,
  addGoalPut,
  goalIdAction,
  onPostAddress
} from 'redux/actions';
import Loader from '../Shared/Loader';
import { Compound } from './Compound';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { VPSGoal } from 'validate/VPSGoal';
import { OccupationalGoal } from 'validate/OccupationalGoal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import DynamicQuestionAnswers from './DynamicQuestionAnswers';
import NationalIdentityDetails from 'jsx/components/AccountSetupForm/NationalIdentityDetails';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ChooseInvestment from './ChooseInvestment';
import ButtonsRow from './ButtonsRow';
import PlannedSaving from './PlannedSaving';
import ProgressBarChart from './AssestAlocation/Component/ProgressBarChart';
import './wizard.css';
import {
  addGoalBodyAction,
  onGetPersonalisedScore
} from 'redux/reducers/sharedStateData';
import PermanentFields from './AccountSetup/permanentField';
import MailingFields from './AccountSetup/mailingFields';
import {
  mailingAddressFormSchema,
  mailingAddressInitialValue,
  permanentAddressFormSchema,
  permanentAddressInitialValue
} from './AccountSetup/validation';
import ConfirmationRiskProfileScreen from './ConfirmationRiskProfile/ConfirmationRiskProfileScreen';
import { CnicValidation } from 'validate/cnicValidation';
import { completeAccount } from './enumFIle';

enum WizardStepsEnum {
  Step_ChooseInvestment = 'Choose investment',
  Step_SavingPlan = 'Saving plan',
  Step_RiskProfile = 'Risk profile',
  Step_Portfolio = 'Portfolio',
  Step_CompleteYourAccount = "Let's complete your account",
  Step_Finish = 'Complete'
}
const riskProfileFormikInitialValues: any = {};
const accountSetupFormikInitialValues: any = {};

const WizardFormBody = ({
  goalId,
  goalValue,
  loading,
  proceedButtonHandler
}: WizardFormBodyProps) => {
  const dispatch = useAppDispatch();
  const { user, userAccountDetails } = useAppSelector(state => state.auth);
  const { goalIdPost } = useAppSelector(state => state?.portfolio);
  const {
    accountSetupUserQuestions,
    pendingRiskProfileQuestion,
    riskProfileUserQuestions
  } = useAppSelector(state => state.questionAnswer);
  const { companies } = useAppSelector(state => state.employer);
  const [InvestmentAmount, setInvestmentAmount] = useState(0);
  const age =
    new Date().getFullYear() - new Date(user?.dob ?? '').getFullYear();
  const [isLoading, setLoading] = useState(true);
  const [isSavingPlanButton, setIsSavingPlanButton] = useState(true);
  const [userNICFront, setUserNICFront] = useState([]);
  const [userNICBack, setUserNICBack] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [activeStep, setActiveStep] = useState<IStep>();
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [riskProfileActiveStep, setRiskProfileActiveStep] = useState<number>(0);
  const [accountSetupActiveStep, setAccountSetupActiveStep] =
    useState<number>(0);
  const [interestData, setInterestData] = useState<any>({});
  const [isLoadingCNIC, setIsLoadingCNIC] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disableRiskNext, setDisableRiskNext] = useState(false);
  const [previousRiskScore, setPreviousRiskScore] = useState(true);

  useEffect(() => {
    setActiveStep(wizardSteps.at(0));
    setActiveStepIndex(0);
  }, []);

  useEffect(() => {
    if (
      accountSetupUserQuestions == undefined ||
      !(
        accountSetupUserQuestions?.questionAnswer?.length >
        accountSetupActiveStep
      )
    ) {
      setShowDisclaimer(true);
    }
  }, [accountSetupUserQuestions]);

  const formikVP = useFormik({
    initialValues: { goalId, ...voluntaryPension },
    onSubmit: values => {
      // console.log('');
    },
    validationSchema: VPSGoal
  });

  const formikOPS = useFormik({
    enableReinitialize: true,
    initialValues: occupationalPension,
    onSubmit: values => {
      // console.log('');
    },
    validationSchema: OccupationalGoal
  });

  const submitGoal = async () => {
    const bodyAddGoal = {
      investorCode: user?.id?.toString(),
      goalName: goalId === 4 ? formikVP.values.goal_name : '',
      id: goalIdPost || '',
      goalType: {
        id: goalId,
        value: goalValue
      },
      // investmentType: {
      //   id: 0,
      //   value: formikVP.values.investment_type.toString()
      // },
      initialAmount: formikVP.values.initial_amount,
      savingScheme: {
        id: 0,
        value: formikVP?.values?.plan_type
      },
      monthlyContribution: {
        savingAmountPerMonth:
          formikVP?.values?.plan_type == 'MonthlyContributions'
            ? Number(formikVP.values.retirement_amount)
            : 0,
        totalAmount:
          formikVP?.values?.plan_type == 'MonthlyContributions'
            ? Number(formikVP.values.future_values)
            : 0,
        numberOfMonths:
          formikVP?.values?.plan_type == 'MonthlyContributions'
            ? formikVP.values.number_of_payments
            : 0
      },
      accumulatedContribution: {
        expectedAmount:
          formikVP?.values?.plan_type == 'AccumulateAmount'
            ? Number(formikVP.values.retirement_amount)
            : 0,
        totalAmount:
          formikVP?.values?.plan_type == 'AccumulateAmount'
            ? Number(formikVP.values.future_values)
            : 0,
        numberOfMonths:
          formikVP?.values?.plan_type == 'AccumulateAmount'
            ? formikVP.values.number_of_payments
            : 0
      }
    };
    dispatch(addGoalBodyAction(bodyAddGoal));

    let insurance = 0;
    insurance = Big(formikVP.values.initial_amount)
      .add(formikVP.values.monthly_saving)
      .toNumber();
    localStorage.setItem('InitialAmount', formikVP.values.initial_amount);
    localStorage.setItem('InvestmentAmount', String(insurance));
  };

  const riskProfileFormikValidationSchema: any = {};

  const riskProfileValidations_ = (questionAnswer: any) => {
    if (questionAnswer?.regex) {
      riskProfileFormikValidationSchema[questionAnswer?.id] = Yup.number()
        // .matches(new RegExp(questionAnswer.regex), 'Invalid Input')
        .required(`Amount is required`)
        .max(100000000, 'Amount should be less than 1 billion');
    } else {
      riskProfileFormikValidationSchema[questionAnswer?.id] =
        Yup.string().required(`Field is required.`);
    }
  };

  let nestedQuestion_1 = {};
  let nestedQuestion_2 = {};

  if (riskProfileUserQuestions?.questionAnswer?.length) {
    riskProfileUserQuestions?.questionAnswer?.map((item: any, index: any) => {
      riskProfileFormikInitialValues[item?.id] = '';
    });
    if (riskProfileUserQuestions?.questionAnswer?.length) {
      riskProfileValidations_(
        riskProfileUserQuestions?.questionAnswer[riskProfileActiveStep]
      );
    }
  }
  const nextStep = (currentStep?: IStep) => {
    const index = wizardSteps.findIndex((step, index, steps) => {
      return (currentStep?.label ?? activeStep?.label) === step.label;
    });

    if (index + 1 == wizardSteps.length) {
      return;
    }

    const step = wizardSteps.at(index + 1);

    activeStep?.onNext?.();

    if (!(step?.ifSkip?.() === true)) {
      setActiveStep(step);
      setActiveStepIndex(index + 1);
    } else {
      nextStep(step);
    }
  };

  const previousStep = (currentStep?: IStep) => {
    const index = wizardSteps.findIndex((step, index, steps) => {
      return (currentStep?.label ?? activeStep?.label) === step.label;
    });

    if (index <= 0) {
      return;
    }

    const step = wizardSteps.at(index - 1);
    if (!(step?.ifSkip?.() === true)) {
      setActiveStep(step);
      setActiveStepIndex(index - 1);
      setAccountSetupActiveStep(0);
      setRiskProfileActiveStep(0);
    } else {
      previousStep(step);
    }
  };

  const formikRiskProfile = useFormik({
    initialValues: {
      ...riskProfileFormikInitialValues
    },
    onSubmit: values => {
      const questionId =
        riskProfileUserQuestions?.questionAnswer[riskProfileActiveStep - 1]?.id;
      const questionIdNested = `${
        riskProfileUserQuestions?.questionAnswer[riskProfileActiveStep - 1]?.id
      }-nested`;
      let body: any = {
        investorCode: user.id,
        investorQueriesDto: [
          {
            id: riskProfileUserQuestions?.questionAnswer[
              riskProfileActiveStep - 1
            ].id,
            answer: {
              answerOptions: [
                {
                  id: 0,
                  value: values[questionId].toString()
                }
              ]
            },
            riskProfileCategoryDto: {
              id: riskProfileUserQuestions?.questionAnswer[
                riskProfileActiveStep
              ]?.category?.id
            }
          }
        ]
      };

      if (values[questionIdNested]) {
        let nestedValues: any = [];
        if (questionIdNested === '636c51ca1833d0b8ffb404e4-nested') {
          nestedValues = values[questionIdNested];
        } else {
          nestedValues = values[questionIdNested].map(
            (item: any, index: number) => {
              return {
                value: item
              };
            }
          );
        }
        body = {
          investorCode: user.id,
          investorQueriesDto: [
            {
              id: riskProfileUserQuestions?.questionAnswer[
                riskProfileActiveStep - 1
              ].id,
              answer: {
                answerOptions: [
                  {
                    id: 0,
                    value: values[questionId].toString(),
                    answer: { answerOptions: nestedValues }
                  }
                ]
              },
              riskProfileCategoryDto: {
                id: riskProfileUserQuestions?.questionAnswer[
                  riskProfileActiveStep
                ]?.category?.id
              }
            }
          ]
        };
      }
      dispatch(onPostUserAnswersRiskProfile(body));

      if (
        riskProfileActiveStep >
        riskProfileUserQuestions?.questionAnswer?.length - 1
      ) {
        const numbersOfPayment = formikVP.values.number_of_payments;
        dispatch(onGetPersonalisedScore(goalValue, Number(numbersOfPayment)));
        nextStep();
      }
    },
    validationSchema: Yup.object({
      ...riskProfileFormikValidationSchema
    })
  });

  if (riskProfileUserQuestions?.questionAnswer?.length) {
    riskProfileUserQuestions?.questionAnswer[
      riskProfileActiveStep
    ]?.answer?.answerOptions?.map((item: any, index: number) => {
      const questionId =
        riskProfileUserQuestions?.questionAnswer[riskProfileActiveStep]?.id;
      if (item.value === formikRiskProfile.values[questionId]) {
        if (item.answer) {
          nestedQuestion_1 = item.answer;
        } else {
          nestedQuestion_1 = {};
          if (formikRiskProfile.values[`${questionId}-nested`]) {
            formikRiskProfile.setFieldValue(`${questionId}-nested`, undefined);
          }
        }
      }
    });
  }

  const accountSetupFormikValidationSchema: any = {};

  const accountSetupValidations_ = (questionAnswer: any) => {
    if (questionAnswer?.regex) {
      accountSetupFormikValidationSchema[questionAnswer?.id] = Yup.string()
        .matches(new RegExp(questionAnswer.regex), 'Invalid Input')
        .required(`Field is required.`);
    } else {
      accountSetupFormikValidationSchema[questionAnswer?.id] =
        Yup.string().required(`Field is required.`);
    }
  };

  if (accountSetupUserQuestions?.questionAnswer?.length) {
    accountSetupUserQuestions?.questionAnswer?.map((item: any, index: any) => {
      accountSetupFormikInitialValues[item?.id] = '';
    });
    if (accountSetupUserQuestions?.questionAnswer?.length) {
      accountSetupValidations_(
        accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep]
      );
    }
  }

  const formikAccountSetup = useFormik({
    initialValues: {
      ...accountSetupFormikInitialValues
    },
    onSubmit: values => {
      const questionId =
        accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep - 1]
          ?.id;
      const questionIdNested = `${
        accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep - 1]
          ?.id
      }-nested`;
      let body: any = {
        category:
          accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep - 1]
            ?.category?.id,
        questionId:
          accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep - 1]
            .id,
        answers: [
          {
            value: values[questionId].toString()
          }
        ]
      };
      if (values[questionIdNested]) {
        const nestedValues = values[questionIdNested].map(
          (item: any, index: number) => {
            return {
              value: item
            };
          }
        );
        body = {
          category:
            accountSetupUserQuestions?.questionAnswer[
              accountSetupActiveStep - 1
            ]?.category?.id,
          questionId:
            accountSetupUserQuestions?.questionAnswer[
              accountSetupActiveStep - 1
            ].id,
          answers: [
            {
              value: values[questionId].toString(),
              answers: nestedValues
            }
          ]
        };
      }

      // dispatch(onPostUserAnswers(body, user?.id));

      if (
        accountSetupActiveStep ===
        accountSetupUserQuestions?.questionAnswer?.length - 1
      ) {
        nextStep();
      } else {
        setAccountSetupActiveStep(accountSetupActiveStep + 1);
      }
    },
    validationSchema: Yup.object({
      ...accountSetupFormikValidationSchema
    })
  });

  const formikPermanentAccountSetup = useFormik({
    initialValues: permanentAddressInitialValue,
    onSubmit: values => {
      // console.log(values, 'values');
    },
    validationSchema: permanentAddressFormSchema
  });

  const submitParmanentAddress = (check: boolean) => {
    const {
      cityPermanent,
      permanentAddress1,
      permanentAddress2,
      provincePermanent
    } = formikPermanentAccountSetup?.values;
    const { cityMailing, mailingAddress1, mailingAddress2, provinceMailing } =
      formikMailingAccountSetup?.values;

    const permanentAddress = [permanentAddress1, permanentAddress2].filter(
      (x: any) => x != ''
    );
    const mailingAddress = [mailingAddress1, mailingAddress2].filter(
      (x: any) => x != ''
    );

    const body = {
      lines: check ? permanentAddress : mailingAddress,
      city: check ? cityPermanent?.value : cityMailing?.value,
      province: check ? provincePermanent?.value : provinceMailing?.value,
      addressType: check ? 'Permanent' : 'Mailing'
    };
    dispatch(onPostAddress(body));
  };

  const formikMailingAccountSetup = useFormik({
    initialValues: mailingAddressInitialValue,
    onSubmit: values => {
      // console.log(values, 'values');
    },
    validationSchema: mailingAddressFormSchema
  });

  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked(!isChecked); // Toggle the checked status when the checkbox is clicked
  };

  if (accountSetupUserQuestions?.questionAnswer?.length) {
    accountSetupUserQuestions?.questionAnswer[
      accountSetupActiveStep
    ]?.answer?.answerOptions?.map((item: any, index: number) => {
      const questionId =
        accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep]?.id;
      if (item.answer) {
        nestedQuestion_2 = item.answer;
      } else {
        nestedQuestion_2 = {};
        if (formikAccountSetup.values[`${questionId}-nested`]) {
          formikAccountSetup.setFieldValue(`${questionId}-nested`, undefined);
        }
      }
    });
    if (
      formikAccountSetup?.values['63693be88664ebf895e12490-nested']?.length >
        0 &&
      !formikAccountSetup?.values['63693be88664ebf895e12490']
    ) {
      formikAccountSetup?.setFieldValue(
        '63693be88664ebf895e12490',
        formikAccountSetup?.values['63693bac8664ebf895e1248f']
      );
    }
  }
  const formikNIC = useFormik({
    enableReinitialize: true,
    initialValues: {
      UserNICFront: '',
      UserNICBack: ''
    },
    onSubmit: values => {
      const formData = new FormData();
      formData.append('UserNicFront', userNICFront[0]);
      formData.append('UserNicBack', userNICBack[0]);

      const onFail = (message: string, response: any) => {
        setIsLoadingCNIC(false);
        if (response.status == 409) {
          toast.error('CNIC is already exist.',{theme: "colored"});
          return;
        } else {
          toast.error('Something went wrong!',{theme: "colored"});
          return;
        }
      };
      const onSuccess = (message: string, response: any) => {
        setAccountSetupActiveStep(accountSetupActiveStep + 1);
        setIsLoadingCNIC(false);
        return;
      };
      setIsLoadingCNIC(true);
      dispatch(onSubmitDocuments(formData, onSuccess, onFail));
    },
    validationSchema: CnicValidation
  });

  const handleInterestCompute = (values: Record<string, string | number>) => {
    const computedIntrest = Compound(
      values.initial_amount,
      values.monthly_saving,
      65 - age,
      0.096,
      12
    );
    setInterestData(computedIntrest);
  };

  const { search } = useLocation();
  const param = search.slice(6);
  const isOPS = param === 'employer';

  const selectedCompany = companies.find(item => {
    return item.id === parseInt(formikOPS.values.employer);
  });

  const plannedSavingData = () =>
    isOPS
      ? {
          initialAmount: 0,
          monthlySaving:
            ((selectedCompany?.cont_prec ?? 1) * formikOPS.values.salary) / 100
        }
      : {
          initialAmount: formikVP.values.initial_amount,
          monthlySaving: formikVP.values.monthly_saving,
          retirementAmount: Number(formikVP.values.retirement_amount),
          planType: formikVP.values.plan_type
        };

  interface IStep {
    label: string;
    ifSkip?: () => boolean;
    onNext?: () => void;
  }

  const wizardSteps: IStep[] = [
    {
      label: WizardStepsEnum.Step_ChooseInvestment
    },
    {
      label: WizardStepsEnum.Step_SavingPlan
    },
    {
      label: WizardStepsEnum.Step_RiskProfile,
      ifSkip: () => {
        return riskProfileUserQuestions?.questionAnswer?.length > 0 === false;
      },
      onNext: () => {
        // submitGoal();
        // if (riskProfileActiveStep === riskProfileUserQuestions?.questionAnswer?.length - 1) {
        //   //setActiveStep(activeStep + 1);
        // } else {
        //   setRiskProfileActiveStep(riskProfileActiveStep + 1)
        // }
      }
    },
    {
      label: WizardStepsEnum.Step_Portfolio,
      onNext: () => {
        // submitGoal();
      }
    },
    {
      label: WizardStepsEnum.Step_CompleteYourAccount,
      onNext: () => {
        // console.log('Completed');
      }
    }
  ];

  const quesId =
    riskProfileUserQuestions?.questionAnswer[riskProfileActiveStep]?.id;
  const isDisabledRisprofileNextBtn =
    formikRiskProfile?.values[quesId] == undefined
      ? true
      : formikRiskProfile.values[quesId] == ''
      ? true
      : false;
  const isDisabledRisprofileNextBtnError =
    formikRiskProfile?.errors[
      riskProfileUserQuestions?.questionAnswer[riskProfileActiveStep]?.id
    ] == undefined
      ? false
      : true;

  const valueRisk = formikRiskProfile?.values[quesId];

  const nestedAnswer =
    riskProfileUserQuestions?.questionAnswer?.length &&
    riskProfileUserQuestions?.questionAnswer[
      riskProfileActiveStep
    ]?.answer?.answerOptions?.find(
      (item: any, index: number) => item.value === valueRisk
    );

  const isDisabledNestedRisprofileNextBtn =
    nestedAnswer?.answer == null
      ? false
      : formikRiskProfile?.values[`${quesId}-nested`] == undefined
      ? true
      : formikRiskProfile?.values[`${quesId}-nested`]?.length > 1
      ? false
      : true;

  return (
    <Box sx={{ width: '100%' }}>
      <Row className='pr-2'>
        <Col md='12' style={{ marginTop: '1rem' }}>
          <Stepper activeStep={activeStepIndex}>
            {wizardSteps.map((step, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={step.label} {...stepProps}>
                  <StepLabel {...labelProps}>{step.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Col>
      </Row>
      <hr />

      {WizardStepsEnum.Step_ChooseInvestment == activeStep?.label && (
        <Row className='pr-2'>
          <Col md='12' style={{ marginTop: '1rem' }}>
            <ChooseInvestment formik={formikVP} goalId={goalId} />
          </Col>
          <Col md='12' style={{ marginTop: '1rem' }}>
            <ButtonsRow
              isBackbutton={false}
              loading={loading}
              nextButtonDisabled={
                (formikVP?.errors.plan_type && true) ||
                (formikVP.errors.initial_amount && true) ||
                (formikVP.errors.desired_retirement_age && true) ||
                ((formikVP.values.plan_type === 'AccumulateAmount'
                  ? formikVP.errors.retirement_amount ||
                    formikVP.values.retirement_amount <
                      formikVP.values.initial_amount
                  : formikVP.errors.monthly_saving && true) &&
                  true) ||
                !!!formikVP.values?.initial_amount ||
                (formikVP.values.goalId === 4
                  ? formikVP.values.goal_name === ''
                  : false)
              }
              nextButtonOnClick={(e: any) => {
                e.preventDefault();
                nextStep();
                setTimeout(() => {
                  setIsSavingPlanButton(false);
                }, 1000);
              }}
            />
          </Col>
        </Row>
      )}

      {WizardStepsEnum.Step_SavingPlan == activeStep?.label && (
        <Row className='pr-4'>
          <Col md='12' style={{ height: '25rem', marginTop: '1rem' }}>
            {loading ? (
              <div className='m-5 text-center'>
                <Loader width={100} />
              </div>
            ) : (
              <PlannedSaving
                formik={isOPS ? formikOPS : formikVP}
                handleInterestCompute={handleInterestCompute}
                interestData={interestData}
                investmentAmount={(amount: any) => setInvestmentAmount(amount)}
                isEmployer={isOPS}
                {...plannedSavingData()}
              />
            )}
          </Col>
          <Col md='12' style={{ marginTop: '1rem' }}>
            <ButtonsRow
              backButtonOnClick={(e: any) => {
                e.preventDefault();
                previousStep();
              }}
              loading={loading}
              nextButtonDisabled={isSavingPlanButton}
              nextButtonOnClick={(e: any) => {
                e.preventDefault();
                nextStep();
                setTimeout(() => {
                  setLoading(false);
                }, 3000);
              }}
            />
          </Col>
        </Row>
      )}

      {WizardStepsEnum.Step_RiskProfile == activeStep?.label && (
        <Row className='pr-2'>
          {pendingRiskProfileQuestion.length > 0 ? (
            <>
              <Col md='12' style={{ height: '25rem', marginTop: '3rem' }}>
                {riskProfileUserQuestions?.questionAnswer?.length && (
                  <form>
                    <DynamicQuestionAnswers
                      activeStep={riskProfileActiveStep || 0}
                      formik={formikRiskProfile}
                      nestedQuestion={nestedQuestion_1}
                      questionAnswers={riskProfileUserQuestions}
                    />
                  </form>
                )}
              </Col>
              <Col md='12' style={{ marginTop: '1rem' }}>
                <ButtonsRow
                  backButtonOnClick={(e: any) => {
                    e.preventDefault();
                    if (riskProfileActiveStep === 0) {
                      previousStep();
                    } else {
                      setRiskProfileActiveStep(riskProfileActiveStep - 1);
                    }
                  }}
                  loading={loading}
                  nextButtonDisabled={
                    isDisabledRisprofileNextBtn ||
                    isDisabledRisprofileNextBtnError ||
                    isDisabledNestedRisprofileNextBtn
                  }
                  nextButtonOnClick={(e: any) => {
                    e.preventDefault();
                    formikRiskProfile.submitForm();
                    if (
                      riskProfileActiveStep >=
                      riskProfileUserQuestions?.questionAnswer?.length + 1
                    ) {
                      // nextStep();
                    } else {
                      setRiskProfileActiveStep(riskProfileActiveStep + 1);
                    }
                  }}
                />
              </Col>
            </>
          ) : (
            <>
              <Col md='12' style={{ height: '25rem', marginTop: '3rem' }}>
                <ConfirmationRiskProfileScreen
                  formik={isOPS ? formikOPS : formikVP}
                  goalValue={goalValue}
                  previousRiskScore={previousRiskScore}
                  setPreviousRiskScore={setPreviousRiskScore}
                />
              </Col>
              <Col md='12' style={{ marginTop: '1rem' }}>
                <ButtonsRow
                  backButtonOnClick={(e: any) => {
                    e.preventDefault();
                    previousStep();
                  }}
                  loading={loading}
                  nextButtonDisabled={!previousRiskScore}
                  nextButtonOnClick={(e: any) => {
                    e.preventDefault();
                    // formikNIC.submitForm();
                    // submitGoal();
                    nextStep();
                    // setAccountSetupActiveStep(accountSetupActiveStep + 1);
                  }}
                />
              </Col>
            </>
          )}
        </Row>
      )}

      {WizardStepsEnum.Step_Portfolio == activeStep?.label && (
        <Row className='pr-2'>
          <Col
            className='res-modal'
            md='12'
            style={{
              height: '25rem',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {isLoading ? (
              <div className='m-5 text-center'>
                <Loader width={100} />
              </div>
            ) : (
              <ProgressBarChart />
            )}
          </Col>
          <Col md='12' style={{ marginTop: '1rem' }}>
            <ButtonsRow
              backButtonOnClick={(e: any) => {
                e.preventDefault();
                previousStep();
              }}
              loading={loading}
              nextButtonOnClick={(e: any) => {
                e.preventDefault();
                submitGoal();
                if (userAccountDetails?.isAccountSetupComplete) {
                  proceedButtonHandler();
                } else {
                  nextStep();
                }
              }}
            />
          </Col>
        </Row>
      )}

      {WizardStepsEnum.Step_CompleteYourAccount == activeStep?.label && (
        <Row className='pr-2'>
          <>
            <Col
              md='12'
              style={{ height: '25rem', overflow: 'auto', marginTop: '1rem' }}
            >
              {(() => {
                if (accountSetupActiveStep == completeAccount.Cnic) {
                  return isLoadingCNIC ? (
                    <div className='m-5 text-center'>
                      <Loader width={100} />
                    </div>
                  ) : (
                    <NationalIdentityDetails
                      backNICFile={userNICBack}
                      formik={formikNIC}
                      frontNICFile={userNICFront}
                      imageError={imageError}
                      setImageError={setImageError}
                      setUserNICBack={setUserNICBack}
                      setUserNICFront={setUserNICFront}
                    />
                  );
                } else if (
                  accountSetupActiveStep == completeAccount.permanentAddress
                ) {
                  return (
                    <PermanentFields formik={formikPermanentAccountSetup} />
                  );
                } else if (
                  accountSetupActiveStep == completeAccount.MailingAddress
                ) {
                  return (
                    <MailingFields
                      formik={formikMailingAccountSetup}
                      formikPermanent={formikPermanentAccountSetup}
                      handleChange={handleChange}
                      isChecked={isChecked}
                    />
                  );
                }
              })()}
            </Col>
            <ButtonsRow
              backButtonOnClick={(e: any) => {
                e.preventDefault();
                if (accountSetupActiveStep === completeAccount.Cnic) {
                  previousStep();
                } else {
                  setAccountSetupActiveStep(accountSetupActiveStep - 1);
                }
              }}
              loading={loading}
              nextButtonDisabled={
                accountSetupActiveStep == completeAccount.Cnic
                  ? isLoadingCNIC
                    ? true
                    : (formikNIC.errors.UserNICFront && true) ||
                      (formikNIC.errors.UserNICBack && true) ||
                      formikNIC.values.UserNICFront == '' ||
                      formikNIC.values.UserNICBack == ''
                  : accountSetupActiveStep == completeAccount.permanentAddress
                  ? formikPermanentAccountSetup.values.permanentAddress1 ==
                      '' ||
                    formikPermanentAccountSetup.values.cityPermanent.value ==
                      '' ||
                    formikPermanentAccountSetup.values.provincePermanent
                      .value == ''
                  : accountSetupActiveStep == completeAccount.MailingAddress
                  ? formikMailingAccountSetup.values.mailingAddress1 == '' ||
                    formikMailingAccountSetup.values.cityMailing == null ||
                    formikMailingAccountSetup.values.provinceMailing == null
                  : false
              }
              nextButtonOnClick={(e: any) => {
                e.preventDefault();
                if (accountSetupActiveStep == completeAccount.Cnic) {
                  formikNIC.submitForm();
                } else if (
                  accountSetupActiveStep == completeAccount.permanentAddress
                ) {
                  submitParmanentAddress(true);
                  setAccountSetupActiveStep(accountSetupActiveStep + 1);
                } else if (
                  accountSetupActiveStep == completeAccount.MailingAddress
                ) {
                  submitParmanentAddress(false);
                  proceedButtonHandler();
                  // setAccountSetupActiveStep(accountSetupActiveStep + 1);
                }
              }}
            />
          </>
        </Row>
      )}
    </Box>
  );
};

export default WizardFormBody;
interface WizardFormBodyProps {
  goalId: number;
  goalValue: string;
  isThisGoalCreated?: boolean | undefined;
  loading: boolean;
  proceedButtonHandler: any;
}

const voluntaryPension = {
  initial_amount: '',
  goal_name: '',
  investment_type: 'islamic',
  monthly_exp: '',
  monthly_saving: '',
  plan_type: 'AccumulateAmount',
  future_values: '',
  retirement_amount: '',
  desired_retirement_age: '',
  number_of_payments: '',
  userAge: '',
  years_required: '',
  expected_monthly_saving: '',
  travelPlan: 'Travel Plan'
};

const occupationalPension = {
  CNIC: '',
  contribution: '',
  employer: '1',
  investment_type: 'islamic',
  salary: 4000
};
