import * as Yup from 'yup';

export const sellSchema = Yup.object({
    qty: Yup.number()
        .test('required', '', function (value, context) {
            if (value == undefined) {
                return this.createError({
                    message: "Quantity is required."
                });
            } else if (value > context?.parent?.selectedItem?.quantity) {
                return this.createError({
                    message: "Sorry, the quantity you have requested exceeds the available quantity. Please enter a lower quantity and try again."
                });
            } else if (value <= 0) {
                return this.createError({
                    message: "Quantity is required."
                });
            }
            return true;
        }).nullable(),
})