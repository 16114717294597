import React from 'react'
import Error404 from 'Latest/ErrorScreen/404Screen';
import { Route, Switch, useLocation } from 'react-router-dom';
import PrivateRoute from 'jsx/routes/PrivateRoute';
import { ScopedAppRoutes } from 'jsx/routes/AppRoutes';
import { useAppSelector } from 'hooks/reduxHooks';
import { Box } from '@mui/material';

const PvtRoutes = () => {

    const { scopes } = useAppSelector(state => state.auth);
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);

    const removeRoute = dashboardDetail?.status == "New" ? ["/myprofile"] : [];
    const filteredRoutes = ScopedAppRoutes?.filter((item) => (item?.scope === (scopes?.filter((el) => el === item?.scope)[0])));
    const removeRouteItems = (array: any, removeRoute: any) => {
        return array?.filter((item: any) => !removeRoute?.includes(item?.url));
    };


    const updatedFilteredRoutes = removeRouteItems(filteredRoutes, removeRoute);
    return (
        <Box sx={{ backgroundColor: "#F8FAFB", minHeight: '100vh' }}>
            {updatedFilteredRoutes?.length ?
                <Switch>
                    {
                        updatedFilteredRoutes?.map((data: any, i: number) => (
                            <PrivateRoute
                                key={i}
                                component={data.component}
                                exact={data.exact}
                                path={data.url}
                                sideBarActive={data.sideBarActive}
                            />
                        ))
                    }
                    <Route
                        path='*'
                        render={() => (
                            <Error404 pageName='Dashboard' url={"/"} />
                        )}
                    />
                </Switch>
                :
                ""
            }
        </Box>
    )
}

export default PvtRoutes