import { Box, Button, Grid, Typography, useTheme } from "@mui/material"
import Envelope from "../../../Latest/Images/Envelope.png"
import LinkedinLogo from "../../../Latest/Images/LinkedinLogo.png"
import Phone from "../../../Latest/Images/Phone.png"
import WhatsappLogo from "../../../Latest/Images/WhatsappLogo.png"
import { tokens } from "theme"
import LoadingBtnCustom from "Latest/ComponentV1/LoadingBtnCustom"
import { borderRadius } from "react-select/src/theme"
import { BorderColor } from "@mui/icons-material"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { onRequestASlot } from "redux/reducers/sharedStateData"
import { useAppLoading } from "hooks/appLoading"


const Index = ({ data }: { data: any }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const loading = useAppLoading("RequestSlot");

    const handleLink = () => {
        window.open(data?.bookingLink, "_blank")
    }

    const buttonStyles = {
        textTransform: "none",
        marginTop: {md:"30px",sm:"30px",xs:"10px"},
        marginLeft: { md: "20px", sm: "20px", xs: "0px" },
        borderRadius: "100px",
        color: "#422E84",
        borderColor: "#422E84",
        width: { md: "150px", sm: "150px", xs: "100%" },
        paddingTop: "7px",
        paddingBottom: "7px"
    }

    const onRequestHandler = () => {
        const body = { adviser: data?.advisor };

        const onSuccess = (message: any, response: any) => {
            toast.success('Slot Requested', { theme: "colored" });
        }
        dispatch(onRequestASlot(body, onSuccess))
    }

    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: { md: "space-between", sm: "space-between", xs: "center" }, flexDirection: { md: "row", sm: "row", xs: "column" } }}>
            <Box sx={{ width: { md: "80%", sm: "80%", xs: "100%" }, marginTop: "40px" }}>
                <Typography variant="h1" color={colors.grey[800]}>{data?.name}</Typography>
                <Typography variant="body2" color={"black"} sx={{ fontWeight: 400, marginTop: "10px" }}>{data?.title}</Typography>
                <Typography variant="body1" color={"black"} sx={{ fontWeight: 400, marginTop: "30px", width: "90%" }} >{data?.description}</Typography>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: "30px", flexWrap: "wrap" }} gap={3}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img src={Envelope} />
                        <Typography sx={{ marginLeft: "10px", wordBreak: "break-all" }}><a href={`mailto:${data?.email}`} target="_blank" rel="noreferrer" style={{ color: "black" }} >{data?.email}</a></Typography>
                    </Box>
                    {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img src={Phone} />
                        <Typography sx={{ marginLeft: "10px", wordBreak: "break-all" }}><a href={data?.phoneLink} target="_blank" rel="noreferrer" style={{ color: "black" }}>{data?.phoneNo}</a></Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img src={WhatsappLogo} />
                        <Typography sx={{ marginLeft: "10px", wordBreak: "break-all" }}><a href={data?.whatsappLink} target="_blank" rel="noreferrer" style={{ color: "black" }}>{data?.whatsappNo}</a></Typography>
                    </Box> */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img src={LinkedinLogo} />
                        <Typography sx={{ marginLeft: "10px", wordBreak: "break-all" }}><a href={data?.linkedinLink} target="_blank" rel="noreferrer" style={{ color: "black" }}>Visit LinkedIn</a></Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: { md: "row", sm: "row", xs: "column" } }}>
                    <LoadingBtnCustom btnTitle={"Book a call"} style={{ width: { md: "130px", sm: "130px", xs: "100%" }, marginTop: "30px" }} handleOnClick={handleLink} />
                    <LoadingBtnCustom loading={loading} btnTitle={"Request a slot"} variant="outlined" style={{ ...buttonStyles }} handleOnClick={onRequestHandler} />
                </Box>

            </Box>
            <Box sx={{ marginLeft: { md: "20px", sm: "20px", xs: "0px" }, marginTop: { md: "0px", sm: "0px", xs: "30px" } }}>
                <img src={data?.image} className="responsive" />
            </Box>
        </Box>
    )
}

export default Index