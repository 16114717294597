import { memo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select, { StylesConfig, components } from 'react-select';
import { CSSObject } from 'styled-components';

type Field = { label: string; value: string };

const styles: StylesConfig<Field, false> = {
  option: (provided: CSSObject, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderRadius: '0.75rem !important',
    color: state.isSelected ? '#4012A0' : '#444',
    height: 40
  })
};

const AppSelectWithLabel = ({
  col = 2,
  customStyle,
  disabled = false,
  fieldName,
  fieldSelect = false,
  fields,
  formik,
  isMulti = false,
  label,
  loading = false,
  name,
  nestedFieldName,
  onChange,
  onInputChange,
  placeholder,
  required,
  value
}: AppSelectWithLabelProps) => {
  const [isFieldTouched, setIsFieldTouched] = useState(false);
  const changeHandle = (option: Field) => onChange?.(option.label, option);
  const formikTouch = formik.touched as any;
  const formikError = formik.errors as any;
  if (!fields.length) return null;
  if (loading) return null;

  let FieldError = "";
  let FieldTouch = false;
  let NestedFieldError = "";
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }


  return (
    <Col md={col}>
      <div
        className={'form-group'}
        id={fieldSelect ? 'react-select-field' : 'react-select'}
      >
        {label && (
          <Row>
            <label className='mb-1 field-label'>
              <strong style={{ fontFamily: 'PlayFair', color: '#000000' }}>{label}</strong>
              {required && <span className='ml-1 text-danger'>*</span>}
            </label>
          </Row>
        )}
        <Select
          className={fieldSelect ? 'h-38' : 'app-select h-38 '}
          defaultValue={fields.filter(el => el.label == formik.values[fieldName])}
          isDisabled={disabled}
          isLoading={loading}
          name={name}
          onBlur={() => nestedFieldName ? formik.setFieldTouched(nestedFieldName, true) : formik.setFieldTouched(fieldName, true)}
          onChange={option => option && changeHandle(option)}
          onInputChange={onInputChange}
          options={fields}
          placeholder={placeholder ?? label}
          required={required || false}
          styles={{ ...styles, ...customStyle }}
          // styles={
          //   fieldSelect
          //     ? undefined
          //     : {
          //       option: (provided, state) => ({
          //         ...provided,
          //         backgroundColor: 'transparent',
          //         borderRadius: '0.75rem !important',
          //         color: state.isSelected ? '#4012A0' : '#444',
          //         height: 40
          //       })
          //     }
          // }
          touched={isFieldTouched}
        />

        {formik && FieldTouch && FieldError && (
          <Row>
            <span className='ml-2 text-danger'>
              {FieldError}
            </span>
          </Row>
        )}

        {formik && NestedFieldTouch && NestedFieldError && (
          <Row>
            <span className='ml-2 text-danger'>
              {NestedFieldError}
            </span>
          </Row>
        )}
      </div>
    </Col>
  );
};

export default memo(AppSelectWithLabel);

interface AppSelectWithLabelProps {
  fieldSelect?: boolean;
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  nestedFieldName?: any;
  fields: Array<Field>;
  formik: any;
  isMulti?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (
    option: string,
    optionObject: Record<string, string | number>
  ) => void;
  onInputChange?: (label: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  loading?: boolean;
  customStyle?: any
}
