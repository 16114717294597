
export const quickLinkStyle = {
    mainBox: {
        p: "12px",
        display: "flex",
        gap: "12px",
        cursor: "pointer",
        ".childBox": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
        },
        ":hover": {
            background: "#F2F4F7"
        }
    },
    mainBoxV2: {
        p: "12px 24px",
        display: "flex",
        gap: "12px",
        cursor: "pointer",
        borderRadius: "100px",
        
        ".childBox": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            gap: "8px"
        },
    }
}