import Big from "big.js";

export const monthlySavingReturnsCalculation = (value: any, monthlySaving?: any, rate?: any) => {
    let returnRate: any;
    const monthlyInvest = monthlySaving || 0;

    if (monthlyInvest) {
        returnRate = rate.div(12).add(1).mul(value.add(monthlyInvest));
    } else {
        returnRate = value.add((value.mul(rate).div(12).mul(10).div(10)))
    }
    return returnRate
}