import * as Yup from 'yup';
import { ACCOUNT_SETUP_MSGS } from './AccountSetupMessages';
import { isValidPhoneNumber } from 'react-phone-number-input'

const mobile_length = 12;
const cnic_length = 13;

export const nextKinValidation = Yup.object({
  name: Yup.string().matches(
    /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
    'Name must contain only alphabetic value'
  ).required().nullable().label('Name'),
  CNIC: Yup.string()
    .required('CNIC is required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'CNIC number is not valid'
    )
    .length(cnic_length, 'CNIC number is not valid'),
    phoneNumber: Yup.string().required('Mobile number is required').test('require', '', function (value, context) {
      const val = value;
      const isValid = isValidPhoneNumber(`+${String(val)}`)
      if (!isValid) {
          return this.createError({
              message: "Mobile number is Invalid"
          });
      }
      return true;
  }),
});
