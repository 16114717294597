import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { Box, Button, IconButton, Skeleton, Typography, useTheme } from '@mui/material';
import useStylesAlertWarning from 'Latest/ComponentV1/CustomMakeStyle/customWarningAlert';
import IconWarning from "Latest/Images/svg/IconWarning.svg"
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { zakaatDeclNotiAction } from 'redux/reducers/notificationStatus';
import { useAppLoading } from 'hooks/appLoading';
import { useHistory } from 'react-router-dom';
import InvestModal from 'Latest/ModalV2/investModal';
import depositIcon from "Latest/Images/depositIcon.png";
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack';
import { tokens } from 'theme';

export default function NoPaymentAlert() {
    const { push } = useHistory();
    const classes = useStylesAlertWarning();
    const dispatch = useAppDispatch();
    const loading = useAppLoading('sharedStateData');

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const { userAccountDetails } = useAppSelector(state => state.auth);
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
    const { transactionDetail } = useAppSelector(state => state.sharedData);

    if (transactionDetail === undefined || transactionDetail === null) return <SkeletonLoading />;

    const quickLinksArray = [
        { icon: depositIcon, title: "Deposit money", link: "", modalComp: InvestModal, type: "down" },
    ]

    const customComp = ({ handleOpen }: any) => {

        const buttonStyle = {
            borderColor: colors.newWarning[100], padding: "8px 16px", height: "37px", color: colors.newWarning[100], backgroundColor: colors.background[100],
            '&:hover': {
                borderColor: colors.newWarning[100], backgroundColor: colors.newWarning[25], color: colors.newWarning[100],
            }
        }

        return (
            <LoadingBtnCustomBack
                btnTitle={"Deposit money"}
                style={buttonStyle}
                handleOnClick={handleOpen}
            />
        )
    }

    return (
        <>
            <Box>

                {/* {loading ?
                    <SkeletonLoading />
                    : */}
                <Alert
                    severity="warning" className={classes.customAlert}
                    sx={{ position: "relative" }}
                    icon={false}
                    iconMapping={{ warning: <span className={classes.customIcon}><img src={IconWarning} /></span> }}
                >
                    <AlertTitle className={classes.customAlertTitle} sx={{ fontWeight: 500 }}>
                        <img src={IconWarning} />
                        Your deposit is still pending
                    </AlertTitle>

                    <Typography className={classes.customText}>Press the button below to complete your account opening process.</Typography>

                    {quickLinksArray.map((item: any, index: number) => (
                        <Box key={index}>
                            <item.modalComp
                                Data={dashboardDetail?.goals[0]}
                                item={item}
                                customComp={customComp}
                                showRenderItem={false}
                            />
                        </Box>
                    ))}
                </Alert>
                {/* } */}

            </Box>
        </>
    );
}


const SkeletonLoading = () => {
    return (
        <Box sx={{ px: { xs: "16px", sm: "32px" }, pb: { xs: "16px", sm: "24px" } }}>
            <Skeleton variant="rounded" width={"100%"} height={20} />
        </Box>
    )
}