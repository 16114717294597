import { ModalBody } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { number } from 'yup';
import { Typography } from '@mui/material';
import InputField from 'jsx/components/Shared/FormikInputField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
interface IProps {
  onClose: any;
  isModalOpen: boolean;
  onSubmit: any;
  investmentAmount: number;
  formik: any;
  monthlySaving: number;
}

const ConfirmationModal = (props: any, { formik }: IProps) => {
  
  const initialAmount = localStorage.getItem('InitialAmount');
  const [amount, setAmount] = useState<any>(initialAmount);
  const [isCheckEnableTerm, setIsCheckEnableTerm] = useState(false);
  const [isCheckEnableConfirm, setIsCheckEnableConfirm] = useState(false);

  const investment = localStorage.getItem('monthlySaving');
  const [monthlyAmount, setMonthlyAmount] = useState(Math.ceil(Number(investment)));

 

  const [investmentAmount] = useState(Math.ceil(Number(investment)));

  const handleClose = () => {
    setIsCheckEnableTerm(false)
    setIsCheckEnableConfirm(false)
    props.onClose()
  }

  return (
    <Modal
      animation={false}
      onHide={handleClose}
      show={props.isModalOpen}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title
          className='pt-5'
          style={{
            fontFamily: 'Playfair Display',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '20px'
          }}
        >
          Your investment summary
        </Modal.Title>
      </Modal.Header>
      <ModalBody>
        <>
          <Typography className='text-heading-2'>
            Invest the following amount
          </Typography>
          <br />
          <Typography className='text-heading-3'>Initial amount</Typography>
          <Typography className='email_Input'>{amount}</Typography> <br />
          <div
            className='Invest-text'
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <ErrorOutlineIcon sx={{ color: '#3D2B7D' }} />
            &nbsp;
            <p className='text-heading-4'>
              We noticed you don't currently have life insurance or it does not
              cover all risks. We therefore recommend: IGI Life Insurance. Our
              representative will contact you to help you make the right choice.
            </p>
          </div>

          <div
            className='Invest-text'
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <ErrorOutlineIcon sx={{ color: '#3D2B7D' }} />
            &nbsp;
            <p className='text-heading-4'>
              Disclaimer: All investments are subject to market risks. Past performance is not necessarily indicative of future results.
              Please read the Terms & Conditions to understand the investment policies and the risks involved.
            </p>
          </div>
          <div>
            <FormControlLabel
              className='text-heading-3 mt-2'
              control={
                <Checkbox onChange={() => setIsCheckEnableTerm(!isCheckEnableTerm)} />
              }
              label='I agree to the Terms & Conditions of use'
            />
          </div>
          <div>
            <FormControlLabel
              className='text-heading-3 mt-2'
              control={
                <Checkbox onChange={() => setIsCheckEnableConfirm(!isCheckEnableConfirm)} />
              }
              label='You are almost ready to build the future you dream.: 
              I confirm that the provided information is correct as per my knowledge '
            />
          </div>
        </>
      </ModalBody>
      <Modal.Footer>
        <Button className='button-sky' disabled={!(isCheckEnableTerm && isCheckEnableConfirm)} onClick={() => props.onSubmit()} >
          Invest Now
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmationModal;
