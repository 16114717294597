import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, Container, Grid, IconButton, Typography } from '@mui/material';
import { WrongCnicModalStyles } from 'Latest/Style/Main';
import CloseIcon from '@mui/icons-material/Close';
import logo from 'images/mahanaLogo.png';
import ButtonsRow from 'jsx/components/WizardFormModal/ButtonsRow';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';


export default function WrongCnicModal({ btnTitle="", handleClose, handleOpen, nextStep , type = "flow" }: any) {

    return (
        <div>
            <Modal
                open={handleOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ padding: '0 20px' }}
            >
                <Container maxWidth={"xs"} sx={WrongCnicModalStyles.container}>
                    <Box sx={{ position: "absolute", right: 15, top: 15 }}>
                        <IconButton aria-label="upload picture" component="label">
                            <CloseIcon onClick={handleClose} />
                        </IconButton>
                    </Box>
                    <Box sx={WrongCnicModalStyles.imageWrapper}>
                        <img src={logo} alt='mahaana' />
                    </Box>

                    <Box sx={WrongCnicModalStyles.contentWrapper}>
                        <Typography>We were unable to extract your CNIC information</Typography>
                        {type == "flow" ?
                            <ButtonsRow
                                backButtonOnClick={(e: any) => {
                                    e.preventDefault();
                                    handleClose()
                                }}
                                // loading={loading}
                                nextButtonOnClick={(e: any) => {
                                    e.preventDefault();
                                    nextStep()
                                    handleClose()
                                }}
                                backButtonLabel={'Try again'}
                                nextButtonLabel={'Add manually'}
                            />
                            :
                            <Grid item xs={12}>
                                <Button fullWidth sx={ModalStyle.cancelBtn} onClick={handleClose}>{btnTitle}</Button>
                            </Grid>
                        }


                    </Box>
                </Container>
            </Modal>
        </div>
    );
}