import { TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

const TableHeadCustom = ({ head }: any) => {
    return (
        <>
            <TableHead>
                <TableRow>
                    {head.map((item: any, index: number) => (
                        <TableCell key={item.name} align={item.align}>{item.name}</TableCell>
                    )
                    )}
                </TableRow>
            </TableHead>

        </>
    )
}

export default TableHeadCustom