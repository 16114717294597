import React from 'react'

const BankIcon = ({ fill = "#475467" }: any) => {
    return (
        <div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.6172 0.164062C20.2344 -0.0546875 19.7656 -0.0546875 19.3828 0.164062L1.23438 10.4453C0.46875 10.8828 0 11.6875 0 12.5625C0 13.9062 1.09375 15 2.4375 15H20H37.5625C38.9062 15 40 13.9062 40 12.5625C40 11.6875 39.5312 10.875 38.7656 10.4453L20.6172 0.164062ZM23.9531 12.5C24.2266 11.9297 24.375 11.2969 24.375 10.625C24.375 8.21094 22.4141 6.25 20 6.25C17.5859 6.25 15.625 8.21094 15.625 10.625C15.625 11.2969 15.7734 11.9297 16.0469 12.5H2.67969L20 2.6875L37.3203 12.5H23.9531ZM20 8.75C20.4973 8.75 20.9742 8.94754 21.3258 9.29917C21.6775 9.65081 21.875 10.1277 21.875 10.625C21.875 11.1223 21.6775 11.5992 21.3258 11.9508C20.9742 12.3025 20.4973 12.5 20 12.5C19.5027 12.5 19.0258 12.3025 18.6742 11.9508C18.3225 11.5992 18.125 11.1223 18.125 10.625C18.125 10.1277 18.3225 9.65081 18.6742 9.29917C19.0258 8.94754 19.5027 8.75 20 8.75ZM5 27.5C4.3125 27.5 3.75 28.0625 3.75 28.75C3.75 29.4375 4.3125 30 5 30H35C35.6875 30 36.25 29.4375 36.25 28.75C36.25 28.0625 35.6875 27.5 35 27.5V17.5H32.5V27.5H26.25V17.5H23.75V27.5H16.25V17.5H13.75V27.5H7.5V17.5H5V27.5ZM3.125 32.5C2.4375 32.5 1.875 33.0625 1.875 33.75C1.875 34.4375 2.4375 35 3.125 35H36.875C37.5625 35 38.125 34.4375 38.125 33.75C38.125 33.0625 37.5625 32.5 36.875 32.5H3.125ZM1.25 37.5C0.5625 37.5 0 38.0625 0 38.75C0 39.4375 0.5625 40 1.25 40H38.75C39.4375 40 40 39.4375 40 38.75C40 38.0625 39.4375 37.5 38.75 37.5H1.25Z" fill={fill} />
            </svg>
        </div>
    )
}

export default BankIcon