/* eslint-disable sort-keys */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { CASH_FUND_WEALTH_BASE_URL, RISK_PROFILE_BASE_URL, WEALTH_API_BASE_URL } from '../../constants';

const initialState: InitialState = {
  investorGoal: undefined,
  goalIdPost: '',     //save Goal id i.e cashfundId , GI id  
  goalFlowId: '',     //save Goal category i.e cashfund == 100 
  goalFlowPath: "",  //save Path i.e invest , wizard 
  payMobServiceFee:0
};

const name = 'portfolio';
const portfolioSlice = createSlice({
  initialState,
  name,
  reducers: {
    postGoalSuccess: (state, action: PayloadAction<any>) => {
      toast.success('Goal Added', {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'bottom-right',
        progress: undefined
      });
    },
    getInvestorGoalsSuccess: (state, action: PayloadAction<any>) => {
      state.investorGoal = action.payload;
    },
    goalIdAction: (state, action: PayloadAction<any>) => {
      state.goalIdPost = action.payload;
    },
    goalFlowIdAction: (state, action: PayloadAction<any>) => {
      state.goalFlowId = action.payload;
    },
    goalFlowPathAction: (state, action: PayloadAction<any>) => {
      state.goalFlowPath = action.payload;
    },
    payMobServiceFeeActions: (state, action: PayloadAction<any>) => {
      state.payMobServiceFee = action.payload;
    },
  }
});

export const { getInvestorGoalsSuccess, goalFlowIdAction, goalFlowPathAction, goalIdAction,payMobServiceFeeActions, postGoalSuccess } = portfolioSlice.actions;
export default portfolioSlice.reducer;

//? APIS ACTIONS

export const addGoal = (data: any, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    onSuccess: onSuccess,
    url: `${RISK_PROFILE_BASE_URL}/api/InvestorGoal`,
  },
  type: 'addGoal'
});

export const addCashFund = (data: any, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    errorToast: true,
    method: 'post',
    onSuccess: onSuccess,
    url: `${WEALTH_API_BASE_URL}/api/CashFundInvestment`,
  },
  type: 'addCashFund'
});

export const addCashFundPut = (data: any, id: string) => ({
  payload: {
    apiName: name,
    data,
    method: 'put',
    errorToast: true,
    url: `${WEALTH_API_BASE_URL}/api/CashFundInvestment`,
  },
  type: 'addCashFundPut'
});

export const goalStatus = (data: any, goalId: string, ref_no: any, goal_status: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    // data,
    method: 'PATCH',
    onSuccess: onSuccess,
    onFail: onFail,
    url: `${RISK_PROFILE_BASE_URL}/api/InvestorGoal/${goalId}/${ref_no}/Status/${goal_status}`,
  },
  type: 'goal_statuses'
});

export const addGoalPut = (data: any, id: string) => ({
  payload: {
    apiName: name,
    data,
    method: 'put',
    url: `${RISK_PROFILE_BASE_URL}/api/InvestorGoal?id=${id}`,
  },
  type: 'addGoal'
});

export const getInvestorGoals = (userId: string | undefined) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: getInvestorGoalsSuccess,
    url: `${RISK_PROFILE_BASE_URL}/api/investorGoal`
  },
  type: 'getInvestorGoals'
});

export const deleteInvestorGoal = (goalId: string | undefined) => ({
  payload: {
    apiName: name,
    method: 'delete',
    url: `${RISK_PROFILE_BASE_URL}/api/investorGoal/${goalId}`
  },
  type: 'deleteInvestorGoals'
});

//? Types
interface InitialState {
  investorGoal: Record<string, any> | undefined;
  goalIdPost: string;
  goalFlowId: string;
  goalFlowPath: any;
  payMobServiceFee:number
}

export interface InvestorGoalParams {
  currentAge: number;
  currentHoldingBalance: number;
  goalTypes: Array<GoalTypesParams>;
  investorCode: string;
  investorGoalList: Record<string, any>;
}

export interface GoalTypesParams {
  id: number;
  value: string;
}
