import { debounce, Paper, Typography } from '@mui/material';
import Big from 'big.js';
import * as Yup from 'yup';
import {
  DefaultAgeEstimate,
  DefaultRetirementAge
} from 'constants/globalVariables';
import { useAppSelector } from 'hooks/reduxHooks';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { onGetAccountSetup } from 'redux/actions';
import { Goals } from './../../../constants';
import { PLAN_TYPES } from './../../../constants';

import {
  CurrencyFormatter,
  FormulaForMonthlysavings,
  FormulaeForRetirementSavings
} from 'utils/calculation';
import AreaChartComponent from '../Shared/AreaChart';
import './styles/PlannedSaving.scss';
const PlannedSaving = ({
  formik,
  handleInterestCompute,
  initialAmount,
  interestData,
  investmentAmount,
  isEmployer,
  monthlySaving,
  planType,
  retirementAmount,
  travelPlan
}: Props) => {
  const dispatch = useDispatch();
  const [range, setRange] = useState<number>(0);
  const [retirementAge, setRetirementAge] = useState(
    formik.values.desired_retirement_age
  );
  const [futureValue, setFutureValue] = useState<number>(0);
  const [maxFutureValue, setMaxFutureValue] = useState<number>(100000);
  const [featureSavingValue, setFeatureSavingValue] = useState<number>(0);
  const { userAccountDetails } = useAppSelector((state: any) => state.auth);
  const [graphData, setGraphData] = useState<any>();
  const [rangeMax, setRangeMax] = useState<number>(100);
  const [rangeMin, setRangeMin] = useState<number>(10);
  const [selectedMonthlySavings, setSelectedMonthlySavings] =
    useState<number>(0);
  const [selectedFeatureMonthly, setSelectedFeatureMonthly] =
    useState<number>(0);
  const [maxExpectedAmount, setMaxSelectedExpectedAmount] = useState<number>(0);
  const [expectedAmount, setSelectedExpectedAmount] = useState<number>(0);
  const age = userAccountDetails?.identityCard?.age ?? DefaultAgeEstimate;
  const range100 = range;
  const travelTimeCounterMax = 1200;
  const travelTimeCounterMin = 1;
  const goalCounterMax = 100;
  const oneBillion = 1000000000;
  const fiftyThousand = 50000;

  const setRangeAmountData = (amount: number) => {
    let maxRange = new Big(Number(amount)).mul(5).round().toNumber();
    let minRange = new Big(Number(amount)).div(100).div(5).round().toNumber();

    if (planType === PLAN_TYPES.ACCUMULATE_AMOUNT) {
      if (minRange < fiftyThousand) minRange = fiftyThousand;
      if (maxRange > oneBillion) maxRange = oneBillion - 1;
    }

    setRangeMax(maxRange);
    if (minRange > 10) {
      setRangeMin(minRange);
    }

    const maxExpected = maxRange * 100;
    setMaxSelectedExpectedAmount(maxExpected);
    setSelectedExpectedAmount(amount);
    formik.setFieldValue('future_values', amount);
  };

  const planTypeSliderChange = () => {
    return planType === PLAN_TYPES.ACCUMULATE_AMOUNT ? '' : '/month';
  };

  const yearCounterValidationSchema = (param: number) => {
    if (formik?.initialValues?.goalId > 0) {
      if (formik?.initialValues?.goalId === Goals.TravelTime) {
        return param < travelTimeCounterMin
          ? travelTimeCounterMin
          : param > travelTimeCounterMax
          ? travelTimeCounterMax
          : param;
      } else {
        return param <= 0 ? 1 : param > goalCounterMax ? goalCounterMax : param;
      }
    } else {
      return param < formik?.values?.userAge + 1
        ? formik?.values?.userAge + 1
        : param > goalCounterMax
        ? goalCounterMax
        : param;
    }
  };

  const calculateNumberOfPayments = (retirementAge: number) => {
    let numberOfPayments;
    if (formik?.initialValues?.goalId === Goals.VoluntaryPension) {
      numberOfPayments = (retirementAge - age) * 12;
    } else {
      if (formik?.initialValues?.goalId === Goals.TravelTime) {
        numberOfPayments = retirementAge;
      } else {
        numberOfPayments = retirementAge * 12;
      }
    }
    if (numberOfPayments < 0) {
      numberOfPayments = 0;
    }

    formik.setFieldValue('desired_retirement_age', retirementAge);

    return numberOfPayments;
  };

  useEffect(() => {
    const numberOfPayments = calculateNumberOfPayments(retirementAge);
    const monthly: number = FormulaeForRetirementSavings({
      numberOfPayments: numberOfPayments,
      retirementAmount: Number(retirementAmount),
      initialAmount: Number(initialAmount)
    });
    if (planType === PLAN_TYPES.ACCUMULATE_AMOUNT) {
      setSelectedMonthlySavings(monthly);
      setRangeAmountData(Number(retirementAmount));
    } else {
      setSelectedMonthlySavings(Number(monthlySaving));
      setRangeAmountData(Number(monthlySaving));
    }
  }, []);

  useEffect(() => {
    if (expectedAmount > 0) {
      setRange(expectedAmount);
    }
  }, [expectedAmount]);

  const debouncedDrawGraph = useCallback(
    debounce(
      (expectedAmount, retirementAge, maxMonthlyAmount) =>
        HandleFormulae(
          expectedAmount,
          retirementAge,
          selectedMonthlySavings,
          selectedFeatureMonthly
        ),
      1000
    ),
    [selectedMonthlySavings, selectedFeatureMonthly]
  );

  useEffect(() => {
    if (range > 0) {
      const expectedChangeAmount = range;
      handleInterestCompute({
        initial_amount: initialAmount,
        monthly_saving: selectedMonthlySavings
      });

      if (planType !== PLAN_TYPES.ACCUMULATE_AMOUNT) {
        formik.setFieldValue('monthly_saving', expectedChangeAmount);
      } else {
        formik.setFieldValue('monthly_saving', selectedMonthlySavings);
      }

      debouncedDrawGraph(
        expectedChangeAmount,
        retirementAge,
        maxExpectedAmount
      );
      formik.setFieldValue(
        'retirement_amount',
        Math.round(expectedChangeAmount)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, retirementAge, selectedMonthlySavings]);

  const HandleFormulae = (
    expectedAmount: number,
    retirementAge: number,
    monthlySavingValue: number,
    selectedFeatureMonthly: number
  ) => {
    let monthlySelectedSavingValue;
    if (planType !== PLAN_TYPES.ACCUMULATE_AMOUNT) {
      monthlySelectedSavingValue = expectedAmount;
    } else {
      monthlySelectedSavingValue = monthlySavingValue;
    }
    const numberOfPayments = calculateNumberOfPayments(retirementAge);
    formik.setFieldValue('number_of_payments', numberOfPayments);
    const total = setGraphDataFunc(
      numberOfPayments,
      monthlySelectedSavingValue
    );
    const totalMax = total
      .plus(total.mul(new Big(Math.random() * 0.1).add(Math.random() * 0.15)))
      .div(1000)
      .round()
      .mul(1000)
      .toNumber();

    let monthlyValue;
    if (planType !== PLAN_TYPES.ACCUMULATE_AMOUNT) {
      monthlyValue = Number(monthlySaving);
      setFeatureSavingValue(Math.ceil(Number(total)));
    } else {
      monthlyValue = FormulaeForRetirementSavings({
        numberOfPayments: numberOfPayments,
        retirementAmount: expectedAmount,
        initialAmount: Number(initialAmount)
      });
      setFeatureSavingValue(monthlyValue);
    }

    setMaxFutureValue(Math.ceil(Number(totalMax)));
    setFutureValue(Math.ceil(Number(total)));
    setSelectedFeatureMonthly(monthlyValue);
    setSelectedMonthlySavings(monthlyValue);
    formik.setFieldValue('future_values', Math.ceil(Number(total)));
  };

  const setGraphDataFunc = (
    numberOfPayments: number,
    monthlySavingValue: number
  ) => {
    const data = [];
    let months = numberOfPayments;
    if (months < 0) {
      months = 0;
    }
    let startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    let futureAge = new Big(age);
    let amount = new Big(initialAmount).add(monthlySavingValue);
    for (let i = 0; i < months; i++) {
      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        1
      );
      futureAge = futureAge.add(0.08333);
      amount = amount.add(amount.mul(0.1).div(12).add(monthlySavingValue));
      if (months > 48 && (i % 12 == 0 || months - 1 === i)) {
        data.push({
          key: `${startDate.getFullYear()}`,
          value: amount.toFixed(0)
        });
      } else if (months <= 48) {
        data.push({
          key: `${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
          value: amount.toFixed(0)
        });
      }
    }

    setGraphData(data);
    return amount;
  };

  const setTotalMonthlyFunc = (
    numberOfPayments: number,
    monthlySavingValue: number
  ) => {
    let months = numberOfPayments;
    if (months < 0) {
      months = 0;
    }

    let amount = new Big(initialAmount).add(monthlySavingValue);
    for (let i = 0; i < months; i++) {
      amount = amount.add(amount.mul(0.1).div(12).add(monthlySavingValue));
    }
    return amount;
  };

  const handleRangeChange = (e: any) => {
    setRange(e.target.value);
  };

  return (
    <Row>
      <Col md={6}>
        {!isEmployer ? (
          <h3 className='range_amount mb-4'>
            {CurrencyFormatter(range100, true, 'auto', true)}
            {planTypeSliderChange()}
          </h3>
        ) : (
          <h3 className='range_amount'>
            {CurrencyFormatter(formik.values.salary, true, 'auto', true)}
          </h3>
        )}
        {!isEmployer && (
          <div>
            <Form.Control
              className='sss '
              max={rangeMax}
              min={rangeMin}
              onChange={handleRangeChange}
              type='range'
              value={range}
            />
          </div>
        )}
        {!isEmployer && (
          <p className='para_PS mt-5'>
            You can increase or decrease your planned saving to see how your
            money will grow.
          </p>
        )}
      </Col>
      <Col md={12}>
        <h3 className='para_amount mt-0'>
          {planType === PLAN_TYPES.ACCUMULATE_AMOUNT
            ? 'You should save '
            : 'You can have '}
          <span className='para_amount_PKR'>
            {CurrencyFormatter(featureSavingValue, true, 'auto', true)}
            {/* {futureValue.toLocaleString()} */}
          </span>
          {planType === PLAN_TYPES.ACCUMULATE_AMOUNT
            ? ' monthly to reach your goal in'
            : ' in '}
          {formik?.initialValues?.goalId === 0 ? (
            <input
              autoFocus
              className='input_amount'
              max={100}
              min={35}
              onChange={e =>
                setRetirementAge(
                  yearCounterValidationSchema(Number(e.target.value))
                )
              }
              type='number'
              value={retirementAge}
            />
          ) : (
            <>
              {formik?.initialValues?.goalId === Goals.TravelTime ? (
                <input
                  autoFocus
                  className='input_amount'
                  max={1200}
                  min={1}
                  onChange={e =>
                    setRetirementAge(
                      yearCounterValidationSchema(Number(e.target.value))
                    )
                  }
                  type='number'
                  value={retirementAge}
                />
              ) : (
                <input
                  autoFocus
                  className='input_amount'
                  max={100}
                  min={1}
                  onChange={e =>
                    setRetirementAge(
                      yearCounterValidationSchema(Number(e.target.value))
                    )
                  }
                  type='number'
                  value={retirementAge}
                />
              )}
            </>
          )}

          {formik?.initialValues?.goalId === Goals.TravelTime ? (
            <span>months</span>
          ) : (
            <span>years</span>
          )}

          {}
        </h3>
      </Col>
      {graphData && (
        <Col md='12'>
          <AreaChartComponent
            data={graphData}
            height={200}
            maxY={new Big(maxFutureValue).round().toNumber()}
            xAxis={'key'}
            yAxis={'value'}
          />
        </Col>
      )}
    </Row>
  );
};

export default memo(PlannedSaving);
interface interestDataProps {
  interest: number[];
  principal: number[];
  result: string;
  total: number[];
}
interface Props {
  interestData: interestDataProps | Record<string, never>;
  monthlySaving: number | string;
  initialAmount: number | string;
  handleInterestCompute: (values: Record<string, number | string>) => void;
  isEmployer: boolean;
  future_values?: any;
  investmentAmount?: any;
  formik: any;
  retirementAmount?: number | undefined;
  planType?: string | undefined;
  travelPlan?: string | undefined;
}
