import React from 'react'
import { Form } from 'react-bootstrap'

const labelStyle = {
    display: 'flex',
    alignItems: 'center',
    // fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    // lineHeight: "24px",
    color: "#1D2939",
}

const CustomSingleCheckBox = ({ fieldName, formik, label, styleErr, styleForm }: any) => {
    const formikTouch = formik.touched as never;
    const formikError = formik.errors as never;
    return (
        <>
            <Form.Check
                checked={formik.values[fieldName]}
                id={`${fieldName}`}
                label={label}
                className="sameAsAddressCheckBox"
                onChange={formik.getFieldProps(fieldName).onChange}
                type={"checkbox"}
                style={{ ...labelStyle, ...styleForm }}
            />

            <span className='text-danger' style={{ ...styleErr }}>
                {formikTouch[fieldName] && formikError[fieldName]}
            </span>
        </>
    )
}

export default CustomSingleCheckBox