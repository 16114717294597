import { ToastContainer, toast } from 'react-toastify';
import { memo, useCallback, useEffect } from 'react';

import { onClearError } from 'redux/reducers/globalError';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

const AppErrors = () => {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector(state => state.error);
  const clearHandle = useCallback(
    (id: number) => dispatch(onClearError(id)),
    [dispatch]
  );
  const notify = useCallback(
    () =>
      (error && error.message) &&
      toast.error(error.message, {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        onClose: () => clearHandle(error.id),
        pauseOnHover: true,
        position: 'bottom-right',
        progress: undefined,
        theme: "colored"
      }),
    [error, clearHandle]
  );

  useEffect(() => {
    notify();
  }, [notify]);

  if (!error || !error.message) return null;
  else {
    return (
      <ToastContainer
        closeOnClick
        draggable
        pauseOnFocusLoss
        pauseOnHover
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        position='top-right'
        rtl={false}
      />
    );
  }
};

export default memo(AppErrors);
