export const PayNowStylesV1 = {
    blueChip: {
        borderRadius: "8px",
        border: "1px solid #B2DDFF",
        background: "#F5FAFF",
        padding: "16px 20px",
        gap: "8px",
        display: "flex",
        flexDirection: "column"
    },
    cardBox: {
        borderRadius: "6px",
        border: "1px solid  #EAECF0",
        background: "#FFF",
        boxShadow: "0px 0px 37px 0px rgba(16, 24, 40, 0.07)",
        ".mainCardHead": {
            padding: "14px 24px 8px 16px",
            borderBottom: "1px solid #EAECF0",
            display:"flex",
            gap:"15px"
        },
        ".innerMainBox": {
            p:"20px"
        },
        ".GITotalAmtBox":{
            p:"16px" , display:"flex" ,justifyContent: "space-between", borderBottom: "1px solid #EAECF0",alignItems: "center"
        }
    }
}