import { useAppSelector } from "hooks/reduxHooks";
import { Mixpanel } from "./index";
import { cryptoGraphyString } from "utils/cryptoGraphyString";

const MixPannelTracker = async ({ ...props }) => {

  // const { user: { email } } = useAppSelector((state: any) => state.auth);

  const { email, eventName, message, status } = props;

  const hashingEmail = await cryptoGraphyString(email);

  Mixpanel.identify(hashingEmail);
  // Mixpanel.alias(hashingEmail);
  Mixpanel.track(eventName, {
    message: message,
    status: status,
    // set_once:"aqibrafiq111@gmail.com"
  });
  Mixpanel.people.set({ "name": hashingEmail , "Email": hashingEmail});
  return;

};

export { MixPannelTracker };