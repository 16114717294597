import { Box, Grid, Typography } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { memo, useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import { formikCheckBoxFieldStyle, formikRadioFieldStyle } from '../style/formikRadioFieldStyle';
import { specialRadioFieldChildGridV2 } from '../style/specialRadioField';

const FormikCustomSelectField = ({
    col = 6,
    fieldName,
    fields,
    formik,
    label,
    onChange = () => null,
    required = false,
    singleLine = false,
    style

}: FormikCheckboxFieldProps) => {
    const formikTouch = formik.touched as never;
    const formikError = formik.errors as never;


    const handleClick = (selectedOption: any) => {
        formik.setFieldValue(fieldName, selectedOption);
    }

    const textInput: any = useRef(null);

    return (
        <>
            <Grid item xs={12}>
                {label && (
                    <Typography sx={{ mb: "6px" }} variant='body2'>
                        {label}
                        {required && <span className='ml-1 text-danger'>*</span>}
                    </Typography>
                )}

                <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap", cursor: "pointer", gap: "8px" }}>
                    {fields.map(val => {
                        return (
                            // <Grid key={val.label} item onClick={() => handleClick(val.value)}>
                            //     <Box
                            //         onClick={() => handleClick(val.value)}
                            <Grid key={val.label} item onClick={() => formik.getFieldProps(fieldName).onChange}>
                                <Box onClick={() => formik.getFieldProps(fieldName).onChange}
                                    sx={{
                                        ...specialRadioFieldChildGridV2,
                                        border: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "1px solid #422E84" : "1px solid rgba(208, 213, 221, 0.25)",
                                        backgroundColor: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "#422E84" : "#FFF",
                                        '& .form-check-label': {
                                            color: formik?.values[fieldName] && formik.values[fieldName].includes(val?.value) ? "#fff" : "#1D2939",
                                            margin: 0
                                        }
                                    }}
                                >
                                    {/* <Form.Check
                                        ref={textInput}
                                        key={val.label}
                                        // checked={formik.values[fieldName] === val.value}
                                        checked={formik?.values[fieldName] && formik.values[fieldName].includes(val?.value)}
                                        onChange={formik.getFieldProps(fieldName).onChange}

                                        className="specialRadio"
                                        disabled={!!val.disabled}
                                        id={val?.label?.toString() + label?.toString()}
                                        label={val.label}
                                        name={fieldName}
                                        style={{ paddingLeft: 0 }}
                                        type='radio'
                                        value={val.value}
                                    /> */}

                                    <Form.Check
                                        key={val.label}
                                        className="specialCheckbox specialCheckboxV2"
                                        checked={formik?.values[fieldName] && formik.values[fieldName].includes(val?.value)}
                                        disabled={!!val.disabled}
                                        id={val.value.toString()}
                                        label={val.label || val.value}
                                        name={fieldName}
                                        onChange={formik.getFieldProps(fieldName).onChange}
                                        type='checkbox'
                                        value={val.value}
                                    />
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>



                {formikTouch[fieldName] && formikError[fieldName] && (
                    <span className='mt-2 text-danger'>
                        {formikError[fieldName]}
                    </span>
                )}

            </Grid>

        </>
    );
};

export default memo(FormikCustomSelectField);

interface FormikCheckboxFieldProps {
    col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    disabled?: boolean;
    fieldName: string;
    fields: Array<{ label: string; value: string; disabled?: boolean }>;
    formik: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getFieldProps: any;
        touched: FormikTouched<unknown>;
        errors: FormikErrors<unknown>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values: any;
        setFieldValue: any;
    };
    label?: string;
    required?: boolean;
    singleLine?: boolean;
    onChange?: any;
    style?: any
}
