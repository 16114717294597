import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { formikRadioFieldStyle } from './style/formikRadioFieldStyle';
// import { RadioGroup } from '@mui/material';
import './styling.css';
const FormikRadioField = ({
  col = 6,
  fieldName,
  fields,
  formik,
  label,
  nestedFieldName,
  required = false,
  singleLine = false
}: FormikRadioFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  let FieldError = '';
  let FieldTouch = false;
  let NestedFieldError = '';
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }

  return (
    <Col md={col}>
      <div className='form-group'>
        <Row>
          <label className='mb-2 field-label'>
            <strong
              style={formikRadioFieldStyle.label}
            >
              {label}
              {required && <span className='ml-1 text-danger'>*</span>}
            </strong>
          </label>
        </Row>
        <Row>
          <div className={singleLine ? 'mb-2 singleLine' : 'mb-2'} style={{ flexWrap: 'wrap' }}>
            {fields.map(val => (
              <div key={val.label} className='question_'>
                <Form.Check
                  key={val.label}
                  checked={formik.values[fieldName] === val.value}
                  className='radio-button-custom'
                  disabled={!!val.disabled}
                  id={val?.label?.toString() + label?.toString()}
                  label={val.label}
                  name={fieldName}
                  onChange={formik.getFieldProps(fieldName).onChange}
                  style={formikRadioFieldStyle.formCheck}
                  type='radio'
                  value={val.value}
                />
              </div>
            ))}
          </div>
        </Row>
        {FieldTouch && FieldError && (
          <Row>
            <span className='ml-2 mt-1 text-danger'>{FieldError}</span>
          </Row>
        )}
        {NestedFieldTouch && NestedFieldError && (
          <Row>
            <span className='ml-2 mt-1 text-danger'>{NestedFieldError}</span>
          </Row>
        )}
      </div>
    </Col>
  );
};

export default memo(FormikRadioField);

interface FormikRadioFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  nestedFieldName?: string;
  fields: Array<{ label: string; value: string; disabled?: boolean }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  label?: string;
  required?: boolean;
  singleLine?: boolean;
}
