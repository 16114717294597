import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
  data: [],
  graphFinal: null,
  graphInitial: null
};

const name = 'pension';
const pensionSlice = createSlice({
  initialState,
  name,
  reducers: {
    getSuccess: (state, action: PayloadAction<SuccessPayload>) => {
      state.data = action.payload.Data.filter(val => val.age % 5 === 0).map(
        value => ({
          ...value,
          amount: value.amount?.toFixed(0)
        })
      );
      state.graphFinal = action.payload.Final;
      state.graphInitial = action.payload.Initial;
    }
  }
});

export default pensionSlice.reducer;

//? APIS ACTIONS

// export const onGetPensionData = () => ({
//   // payload: {
//   //   apiName: name,
//   //   data: {},
//   //   method: 'get',
//   //   url: 'Dashboard/GetPensionGoalGraph'
//   // },
//   // type: 'get'
// });

// ? Types

export interface PensionGraphData<T> {
  age: number;
  amount: T;
  date: string;
}

interface HeadingValue {
  age: number;
  amount: number;
  date: string;
}

interface InitialState {
  data: Array<PensionGraphData<string>>;
  graphFinal: HeadingValue | null;
  graphInitial: HeadingValue | null;
}

interface SuccessPayload {
  Data: Array<PensionGraphData<number>>;
  Final: HeadingValue | null;
  Initial: HeadingValue | null;
}
