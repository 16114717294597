import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import ConfirmationImage from '../../../images/confirmation.png'
import FormikInputField from './FormikInputField';

const AddCard = ({
    handleCloseModal,
    handleConfirm,
    openModal
}: DeleteConfirmProps) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
        },
        onSubmit: async (values: any) => {
            // console.log('');
        },
    });
    return (
        <Modal
            centered
            aria-labelledby='contained-modal-title-vcenter'
            onHide={() => handleCloseModal()}
            show={openModal}
            size='sm'
        >
            <Modal.Body className='px-3'>
                <div className='ml-3'>
                    <p className='' style={{ fontSize: '20px', fontFamily: 'PlayFair', fontWeight: 'bold', color: 'black' }}>
                        Add New Card
                    </p>
                </div>
                <div className='mt-2 mb-4'>
                    <FormikInputField
                        required
                        col={12}
                        formik={formik}
                        label={'Account Title'}
                        maxLength={50}
                        name='mailingAddress.completeAddress'
                        placeholder="Enter here..."
                    />
                </div>
                <div className='mt-4 mb-4'>
                    <FormikInputField
                        required
                        col={12}
                        formik={formik}
                        label={'IBFT Account Number'}
                        maxLength={50}
                        name='mailingAddress.completeAddress'
                        placeholder="Enter here..."
                    />
                </div>
                <div className='mt-4 mb-4'>
                    <FormikInputField
                        required
                        col={12}
                        formik={formik}
                        label={'Bank Name'}
                        maxLength={50}
                        name='mailingAddress.completeAddress'
                        placeholder="Enter here..."
                    />
                </div>
                <div className='mt-4 mb-3'>
                    <FormikInputField
                        required
                        col={12}
                        formik={formik}
                        label={'Branch Name'}
                        maxLength={50}
                        name='mailingAddress.completeAddress'
                        placeholder="Enter here..."
                    />
                </div>
                <div className='d-flex justify-content-end' >
                    <button className='button-sky' onClick={() => handleCloseModal()}>
                        Add
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddCard;

interface DeleteConfirmProps {
    handleConfirm: () => void;
    handleCloseModal: () => void;
    openModal: boolean;
}
