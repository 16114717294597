import React, { useState, useEffect } from 'react'
import InputField from '../../Shared/V2/FormikInputFieldV2';
import SelectField from '../../Shared/FormikSelectFieldLatest';
import { Grid, Typography } from '@mui/material';
import { CityName } from './city';
import { ProvinceName } from './province';
import { subHeadingSecStyle } from 'Latest/Style/Main';
import CustomSingleCheckBox from 'jsx/components/Shared/CustomSingleCheckBox';


const MailingFields = ({ formik }: any) => {

    const [cityList, setcityList] = useState<any>([])
    const [provinceList, setProvinceList] = useState<any>([])
    const [maxTextLength, setMaxTextLength] = useState(120);

    useEffect(() => {
        const data = CityName?.map((val) => ({ 'value': val.name, 'label': val.name }));
        setcityList(data)

        const stateList = ProvinceName?.map((val) => ({ 'value': val.name, 'label': val.name }));
        setProvinceList(stateList)

        const largestCity = CityName?.reduce((max, city) => Math.max(max, city.name.length), 0);
        const largestProvince = ProvinceName?.reduce((max, province) => Math.max(max, province.name.length), 0);
        const newLength = 120 - (largestProvince + largestCity);
        const roundedNumber = Math.round(newLength / 10) * 10;
        setMaxTextLength(roundedNumber);

    }, [CityName, ProvinceName])

    useEffect(() => {
        if (formik.values.sameAsParmanent) {
            formik.setFieldValue('mailingAddress1', formik.values.permanentAddress1)
            formik.setFieldValue('cityMailing', formik.values.cityPermanent)
            formik.setFieldValue('provinceMailing', formik.values.provincePermanent)
            formik.setFieldValue('mailingAddress2', formik.values.permanentAddress2)
        }
    }, [formik.values.permanentAddress1, formik.values.cityPermanent, formik.values.provincePermanent, formik.values.permanentAddress2, formik.values.sameAsParmanent])

    return (
        <>
            <Grid container spacing={1} mt={3}>
                <Grid item md={12} xs={12}>
                    <Typography sx={subHeadingSecStyle}>Mailing address</Typography>
                </Grid>

                <Grid item md={12} xs={12} sx={{ pt: "16px !important" }}>
                    <CustomSingleCheckBox
                        formik={formik}
                        fieldName={`sameAsParmanent`}
                        label={`Same as permanent address`}
                        type={"checkbox"}
                        styleForm={{ paddingLeft: "0px" }}
                        styleErr={{ marginLeft: "0px" }}
                    />
                </Grid>

                {!formik.values.sameAsParmanent &&
                    <>
                        <Grid item md={12} xs={12}>
                            <InputField
                                maxLength={maxTextLength}
                                disabled={formik.values.sameAsParmanent}
                                formik={formik}
                                label='Address'
                                name='mailingAddress1'
                                placeholder='Enter mailing address here'
                                type="text"
                            />
                        </Grid>

                        {/* <Grid item md={12} xs={12}>
                            <InputField
                                disabled={formik.values.sameAsParmanent}
                                formik={formik}
                                label='Address line 2 (optional)'
                                name='mailingAddress2'
                                placeholder='Enter mailing address here'
                                type="text"
                            />
                        </Grid> */}

                        <Grid item lg={6} md={6} xs={12}>
                            <SelectField
                                col={12}
                                disabled={formik.values.sameAsParmanent}
                                fieldName='cityMailing'
                                fields={cityList}
                                formik={formik}
                                label='City'
                                onChange={(e) => {
                                    const selectedField = cityList.filter((i: any) => i.value == e)
                                    formik.setFieldValue('cityMailing', selectedField[0])
                                }}
                                placeholder={"Select City"}
                                value={formik.values.cityMailing.value == '' ? "" : formik.values.cityMailing}

                            />
                        </Grid>

                        <Grid item lg={6} md={6} xs={12}>
                            <SelectField
                                col={12}
                                disabled={formik.values.sameAsParmanent}
                                fieldName='provinceMailing'
                                fields={provinceList}
                                formik={formik}
                                label='Province'
                                onChange={(e) => {
                                    const selectedField = provinceList.filter((i: any) => i.value == e)
                                    formik.setFieldValue('provinceMailing', selectedField[0])
                                }}
                                placeholder={"Select Province"}
                                value={formik.values.provinceMailing.value == '' ? "" : formik.values.provinceMailing}
                            />
                        </Grid>
                    </>}
            </Grid>
        </>
    )
}

export default MailingFields