import { Grid, Skeleton, Typography, useTheme } from '@mui/material'
import React from 'react'
import EmptyBox from "../../../src/Latest/Images/emptyBox.png"
import NoAccountImg from "Latest/Images/svg/NoAccount.svg"
import { tokens } from 'theme'

const NoAccdataScreen = ({ headContent = "Oops!", loading = false, subHeadContent = "Sorry, no data was found." }: any) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <>
            <Grid container spacing={"16px"}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    {loading ?
                        <Skeleton variant="circular" width={120} height={120} />
                        :
                        <>
                            <img src={NoAccountImg} />
                        </>
                    }
                </Grid>

                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                    {loading ?
                        <Skeleton variant="rounded" width={50} height={18} />
                        :
                        <>
                            <Typography variant='h6' color={colors.grey[900]}>{headContent}</Typography>
                        </>
                    }

                    {loading ?
                        <Skeleton variant="rounded" width={280} height={18} />
                        :
                        <>
                            <Typography variant='body2' color={colors.grey[600]}>{subHeadContent}</Typography>
                        </>
                    }

                </Grid>
            </Grid>
        </>
    )
}

export default NoAccdataScreen