import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import React from 'react'

const CustomBreadcrumb = ({ breadcrumbsLinks }: any) => {
    return (
        <>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbsLinks}
            </Breadcrumbs>
        </>
    )
}

export default CustomBreadcrumb