import { Alert, Box, Button, Grid, Typography } from "@mui/material"
import LoadingBtnCustom from "Latest/ComponentV1/LoadingBtnCustom";
import { memo, useState } from "react"

const SubscribtionComp = ({isAlert ,isButton,isSubscribe , setIsAlert,setIsButton, subscribtionHandler  } :{isAlert:boolean ,isButton:boolean,isSubscribe:boolean , setIsAlert:any ,setIsButton:any,subscribtionHandler: () => void }) => {


    const subscibtHandler = (e:any) => {
        e.preventDefault();
        subscribtionHandler()
    }
    return (
        <>
            {
                isAlert ? (
                    <Box sx={{display:"flex" , justifyContent:"center" ,  background:"white", position:"absolute" , width:"100%" , top:"70px" }}>
                        <Alert sx={{ width: "345px",marginLeft:"20px",marginRight:"20px" }} severity="success" onClose={() => { setIsAlert(false) }}>
                            You’ve successfully {isSubscribe?"Subscribed":"Unsubscribed"} 
                        </Alert>
                    </Box>
                ) : (
                    <></>
                )
            }
            <Grid container flexDirection={"column"} justifyContent={"center"} alignItems={"center"} minHeight={"100vh"} sx={{background:"white" , paddingLeft:"20px", paddingRight:"20px"}}>
                <Grid item textAlign={"center"}>
                    <Typography variant="h3" fontWeight={"bold"} color={"black"}>{isSubscribe?"Subscribe to":"Unsubscribe from"} our mailing list?</Typography>
                    <Typography variant="body2" mt={"6px"}>{isSubscribe?"You'll start receiving marketing emails from us.":"You'll stop receiving marketing emails from us."}</Typography>
                </Grid>
                <Grid item mt={"20px"} textAlign={"center"}>
                    {
                        isButton?(
                            <LoadingBtnCustom style={{minWidth:{md:"300px",sm:"300px",xs:"200px"}}} handleOnClick={subscibtHandler} loading={false} btnTitle={isSubscribe?"Subscribe":"Unsubscribe"} />
                        ):(
                            <Typography variant="h5" color={"black"}>{isSubscribe?"You have successfully Subscribed to all email communications.":"You have successfully unsubscribed from all email communications."}</Typography>
                        )
                    }
                </Grid>
            </Grid>
        </>
    )
};

export default SubscribtionComp