import * as React from 'react';
import '../../scss/login/_Login.scss';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { memo } from 'react';
import { LoginParams } from 'redux/actions';
import { links } from 'static/links';
import { login } from 'validate/auth/login';
import { useAppDispatch } from 'hooks/reduxHooks';
import { setEmail, setClearEmailPassword } from 'redux/actions';
import { authStyles } from 'Latest/Style/Style';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import AuthInputField from '../components/MUI_components/FormikTextField';
import AuthPasswordField from '../components/MUI_components/FormikPasswordField';
import MUILink from '@mui/material/Link';
import Box from '@mui/material/Box';
import Logo from 'Latest/Images/mahanna2.png';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import { styleAuth } from './style';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import { FormControl, useMediaQuery } from '@mui/material';
import InputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface State {
  password: string;
  showPassword: boolean;
}

const Login = ({ clearEmailPassword, loading, loginHandle }: LoginProps) => {

  const dispatch = useAppDispatch();

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: (values: LoginParams) => {
      loginHandle(values);
      dispatch(setEmail(values.email));
      dispatch(setClearEmailPassword(true))
      if (clearEmailPassword) {
        formik.resetForm();
      }
    },
    validationSchema: login
  });
  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    formik.setFieldValue('password', formik.values.password.replace(/\s/g, '').trim())
  }, [formik.values.password])

  return (
    <>
      <Container disableGutters={smScreen} sx={{ mt: { xs: "24px", md: "40px" }, maxWidth: "500px!important" }}>

        <form onSubmit={formik.handleSubmit}>
          <Box sx={styleAuth.cardContainer}>
            <Typography variant='h1' color='#1D2939' pb='12px'>Sign in</Typography>

            <AuthInputField
              disabled={loading}
              formik={formik}
              label='Email'
              name='email'
              placeholder='hello@example.com'
            />

            <AuthPasswordField
              disabled={loading}
              removeWhitespace={true}
              endAdornment={<InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>}
              formik={formik}
              label='Password'
              name='password'
              onChange={handleChange('password')}
              placeholder='••••••••••'
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
            />

            <Typography variant='body2' color="#4B5563" sx={{ mb: "12px" }} ><a href={links.forgot} style={{ color: "#4B5563" }}>Forgot password?</a></Typography>
            <Box sx={{ pt: "12px" }}>
              <LoadingBtnCustom type="submit" btnTitle="Log in" size={"large"} fullWidth={true} handleOnClick={formik.handleSubmit} loading={loading} />
            </Box>

            <Typography variant='body1' color="#4B5563" textAlign={"center"}>Don’t have an account? <a href={links.signup} style={{ color: "#43BED8" }}>Sign up here.</a></Typography>
          </Box>
        </form>

      </Container>

      {/* <Container className="Login" component="main" maxWidth="sm" sx={authStyles.container}>
        <Card className='login_Card'>
          <Box className='Form_Main'
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <img alt="Mahaana" className='img logo' src={Logo} />
            <h1 className='Login_heading'> Sign in </h1>
            <Box className='Form_Secondary'>
              <AuthInputField
                required
                disabled={loading}
                formik={formik}
                label='Email Address'
                name='email'
                placeholder='hello@example.com'
              />
              <AuthPasswordField
                required
                disabled={loading}
                removeWhitespace={true}
                endAdornment={<InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>}
                formik={formik}
                label='Password'
                name='password'
                onChange={handleChange('password')}
                placeholder='••••••••••'
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
              />
              <MUILink className='label_login' href={links.forgot}>
                Forgot password?
              </MUILink>
              <LoadingButton
                // className='btn_Signin'
                loading={loading}
                fullWidth={true}
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  height: "56px",
                  borderRadius: '6px !important',
                  boxShadow: 'none !important',
                  fontSize: '18px !important',
                  fontWeight: '500 !important',
                  lineHeight: '22px !important',
                  textTransform: "capitalize !important",
                  mt: "10px"
                }}
              >
                Sign In
              </LoadingButton>
              <Typography className='acc_label' sx={{ pt: 2, flexWrap: 'wrap', textAlign: 'center' }}>
                Don’t have an account?
                <MUILink className='link' href={links.signup} variant="body2" >
                  {"Sign up here"}
                </MUILink>
              </Typography>
            </Box>
          </Box>
        </Card>
      </Container> */}

    </>
  );
};

export default memo(Login);

interface LoginProps {
  clearEmailPassword: boolean;
  loading: boolean;
  loginHandle: (data: LoginParams) => void;
}
