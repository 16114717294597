export const screen404Style = {
    head: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "60px",
        lineHeight: "72px",
        color: "#101828"
    },
    para: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "35px",
        color: "#475467"
    },
    para2: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: {xs:"10px",sm:"16px",md:"20px"},
        color: "#475467"
    },
    blueTitle: {
        fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#43BED8"
    },
    contentGrid: { background: "#FFFFFF", display: "flex", justifyContent: "center", flexDirection: "column" },
    addBackBtn: {
        color: "#344054",
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: "150%",
        border: "1px solid #D0D5DD",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        borderRadius: "8px",
        padding: "12px 20px",
        width:{xs:"100%",sm:"auto",md:"auto"},
        textTransform: 'none',
    },
    homeBtn: {
        background: "#403081",
        border: "1px solid #403081",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        borderRadius: "8px",
        color: "#FFFFF",
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: "150%",
        padding: "12px 20px",
        width:{xs:"100%",sm:"auto",md:"auto"},
        textTransform: 'none',
        "&:hover": {
            color: '#FFFFF',
            background: "#403081de"
        }
    }
}
