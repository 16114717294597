import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import SaveIcon from "../../../Latest/Images/saveIcon.png"
import { ModalStyle } from '../Style/modalStyle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import warningIcon from "Latest/Images/svg/warningIcon3.svg"


import LoadingButton from '@mui/lab/LoadingButton';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    boxShadow: "0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)",
    p: "24px",
    borderRadius: "8px",
    maxWidth: '100%',
    width: { xs: "95%", sm: "auto", md: "500px" },
    '& img': { height: 'auto', width: 'auto', maxWidth: '100%' }
};

const gridStyles = {
    display: 'flex'
}


export default function BasicModal({ Content, btnTitle = "Continue", confirmBtnVariant = "warning", handleClose, handleConfirm, handleOpen, image, loadingConfirmBtn = false, open, showIcon = true, styleBox }: any) {
    const classes = useStyles();

    const [modalContent, setModalContent] = React.useState<any>('');
    React.useEffect(() => {
        setModalContent(Content)
    }, [Content])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column", gap: "16px", ...styleBox }}>
                        {showIcon &&
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <img src={image ? image : warningIcon} />

                                <Box>
                                    <IconButton aria-label="close" component="label" onClick={handleClose} sx={{ p: "0px" }} >
                                        <CloseIcon sx={{ fontSize: 22, cursor: "pointer", color: "#667085" }} onClick={handleClose} />
                                    </IconButton>
                                </Box>
                            </Box>
                        }

                        <Box width={"100%"} sx={{ display: "flex", gap: "4px", flexDirection: "column" }}>
                            {modalContent}
                        </Box>
                    </Box>


                    <Grid item xs={12} sx={{ display: "flex", gap: 2, justifyContent: "end", pt: 3 }}>
                        <ButtonRowV1 handleBack={handleClose} handleNext={handleConfirm} loadingNextBtn={loadingConfirmBtn} btnTitleNext={btnTitle} btnTitleBack="Cancel" />
                    </Grid>

                </Box>
            </Modal>
        </div>
    );
}