import React from 'react'
import ChangePass from './changePass'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
const Index = () => {
    

    return (
        <Container maxWidth="lg" sx={{ px: "0px!important", ml: 0 }}>
            <Grid container sx={{ background: "#fff", p: { xs: "24px 16px", md: "30px" } }}>
                <ChangePass />
            </Grid>
        </Container >
    )
}

export default Index