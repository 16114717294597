import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Card, Col, Row, Modal } from 'react-bootstrap';
import EmployesTable from './EmployesTable';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useAppLoading } from 'hooks/appLoading';
import {
  onClearEmployerMsg,
  onGetEmployeeListForEmployer,
  onGetEmployerContribution,
  onImportExcelFile
} from 'redux/reducers/employer';
import Loader from 'jsx/components/Shared/Loader';
import { useDropzone } from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify';
import AppSelect from 'jsx/components/Shared/AppSelect';
import axios from 'axios';
import EmployeeDetailsModal from '../../components/Dashboard/EmployeeDetailsModal';
import { onFileUpload, onGetEmployee, onPostEmployee, onPutEmployee } from 'redux/reducers/sharedStateData';
const baseUrl = "https://dev-mahaana-dfa-accountmanagement.azurewebsites.net/api";

export default function DashboardEmployees() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);
  const [fileStream, setFileStream] = useState<any>();
  const [employeesList, setEmployeesList] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState();

  const dispatch = useAppDispatch();
  const {
    employerContribution,
    employerEmployees,
    employerEmployeesTotalRecords,
    msg
  } = useAppSelector(state => state.employer);

  const loading = useAppLoading('employer');

  const getInitialData = useCallback(() => {
    // dispatch(onGetEmployerContribution());
    // dispatch(onGetEmployeeListForEmployer(currentPage));
  }, [dispatch, currentPage]);
  const { employee } = useAppSelector((state: any) => state.sharedData)
  const onDrop = useCallback(
    (acceptedFiles: Files) => {
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formData: any = new FormData();
        formData.append('file', file as File);
        dispatch(onImportExcelFile(formData));
        return;
      }
      toast.error('Please select only one file',{theme: "colored"});
    },
    [dispatch]
  );

  const notify = useCallback(
    () =>
      msg?.length &&
      toast.success(msg, {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        onClose: () => dispatch(onClearEmployerMsg()),
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined
      }),
    [dispatch, msg]
  );

  useEffect(() => {
    dispatch(onGetEmployee());
    notify();
    fetchEmployees();
  }, [notify]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData, employeesList]);
  const submitFile = () => {
    if (fileStream) {
      const formData = new FormData();
      formData.append('FileContent', fileStream[0]);
      formData.append('FileType', 'Employee');
      formData.append('FileName', fileStream[0]?.name);
      dispatch(onFileUpload(formData))
    }


  }
  const fetchEmployees = async () => {
    employee?.employees?.length > 0 && setEmployeesList(employee.employees);
  }

  const fetchEmployeeById = async (employeeId: string) => {
    formik.resetForm();
    // const resp = employe;
    // const employeeData = reps;
    // formik.setFieldValue('id', employeeData.id);
    // formik.setFieldValue('name', employeeData.name);
    // formik.setFieldValue('gender', (employeeData.gender).toString());
    // formik.setFieldValue('maritalStatus', (employeeData.maritalStatus).toString());
    // formik.setFieldValue('birthDate', new Date(employeeData.dateOfBirth));
    // formik.setFieldValue('nationalIdentityNumber', employeeData.nationalIdentityNumber);
    // formik.setFieldValue('occupation', employeeData.occupation);
    // formik.setFieldValue('incomeSource', employeeData.incomeSource);
    // formik.setFieldValue('employmentStatus', (employeeData.employmentStatus).toString());
    // formik.setFieldValue('country', employeeData.country);
    // formik.setFieldValue('state', employeeData.state);
    // formik.setFieldValue('city', employeeData.city);
    // formik.setFieldValue('address', employeeData.address);
    // setEmployeeDetails(employeeData);
    // setAddEmployeeModalOpen(true);
  }

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept:
      'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDrop
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      gender: '',
      maritalStatus: '',
      nationalIdentityNumber: '',
      occupation: '',
      incomeSource: '',
      country: '',
      state: '',
      city: '',
      address: '',
      employmentStatus: ''
    },

    onSubmit: async (values: Record<string, any>) => {
      const values_ = {
        ...values,
        gender: Number(values.gender),
        maritalStatus: Number(values.maritalStatus),
        employmentStatus: Number(values.employmentStatus),
        nationalIdentityNumber: values.nationalIdentityNumber,
      }
      if (values.id) {
        // await axios.put(`${baseUrl}/Employee`, values_);
        dispatch(onPutEmployee(values_));
      } else {

        // await axios.post(`${baseUrl}/Employee`, values_);
        dispatch(onPostEmployee(values_));
      }
      await fetchEmployees();
      setAddEmployeeModalOpen(false);
    },
    // validationSchema: inviteSchema
  });

  return (
    <>
      {/* <ToastContainer /> */}
      <div>
        {loading ? (
          <div className='text-center'>
            <Loader width={200} />
          </div>
        ) : (
          <>
            <Card className='pb-4 ' style={{ height: 'auto' }}>
              <Card.Header className='d-block'>
                <Row>
                  <Col className='custom-padding' lg={6} sm={12}>
                    <p className='mb-0 text-black text-capitalize '>
                      {/* {`${employerContribution.length
                        ? employerContribution[0].employeer_name
                        : 'no company'
                        }/Employees`} */}
                    </p>
                    <h2 className='mb-0 text-primary '>Employees</h2>
                  </Col>
                  <Col className=' employeesHeadOptions' lg={6} sm={12}>
                    <AppSelect
                      col={4}
                      fieldName='employees'
                      fields={[{ label: 'john Doe', value: '1' }]}
                      placeholder='Search'
                    />
                    <AppSelect
                      col={4}
                      fieldName='employeeStatus'
                      fields={[
                        { label: 'Show All', value: '1' },
                        { label: 'Active', value: '2' },
                        { label: 'Terminated', value: '3' },
                        { label: 'Not Participating', value: '4' },
                        { label: 'Not Eligible', value: '5' }
                      ]}
                      placeholder='Select Status'
                      value={'1'}
                    />

                    <Col lg={4}>
                      <Button className='rit cutomMarginBottom w-100 h-38 p-0'
                        onClick={() => {
                          // formik.resetForm()
                          submitFile();
                          setAddEmployeeModalOpen(true)
                        }}>
                        Add
                      </Button>
                    </Col>
                    <Col lg={4}>
                      <div className='form-group'>
                        {/* <div {...getRootProps()} className=''>
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <Button className='rit cutomMarginBottom w-100 h-38 p-0'>
                              Import
                            </Button>
                          )}
                        </div> */}
                        <input
                          accept='.xlsx'
                          onChange={(e) => setFileStream(e.target?.files)}
                          type='file'
                        />
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row className='mt-2'>
                  <Col md={12} sm={12}>
                    <EmployesTable
                      // employerEmployees={employerEmployees}
                      employerEmployees={employeesList}
                      fetchEmployeeById={(employeeId: string) => fetchEmployeeById(employeeId)}
                    // employerEmployeesTotalRecords={
                    //   employerEmployeesTotalRecords
                    // }
                    // setCurrentPage={setCurrentPage}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* {isAddEmployeeModalOpen && */}
            <EmployeeDetailsModal
              formik={formik}
              isAddEmployeeModalOpen={isAddEmployeeModalOpen}
              // isUpdateForm={formik.values.id && true}
              setAddEmployeeModalOpen={(val: boolean) => setAddEmployeeModalOpen(val)}
            />
            {/* } */}
          </>
        )}
      </div>
    </>
  );
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Files = Array<Record<string, any>>;