import React from 'react'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import Statement from './StatementComp'
import { paramFinder } from 'utils/paramFInder'

const Index = () => {
    const startDate = paramFinder("startdate")
    const endDate = paramFinder("enddate")

    return (
        <Container maxWidth="lg" sx={{ px: "0px!important", ml: 0 }}>
            <Grid container sx={{ background: "#fff", p: { xs: "24px 16px", md: "30px" } }}>
                <Statement startdates ={startDate} enddates ={endDate}/>
            </Grid> 
        </Container >
    )
}

export default Index