export const LoaderStyles = {
  mainBox: {
    background: "rgba(255,255,255,0.3)",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    position: "fixed", width: "100%", height: "100%", top: 0, zIndex: "2147483647",
    display: "flex", justifyContent: "center", alignItems: "center", left: 0,
    px: 2
  },
  subBox: {
    minWidth: { sm: "334px", md: "334px", lg: "334px" },
    borderRadius: "8px",
    border: "1px solid  rgba(208, 213, 221, 0.25)",
    background: "#FFF",
    boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
    padding: "40px 30px",
    gap: "30px", display: "flex", flexDirection: "column"

    // background: "#FFFFFF",
    // boxShadow: "0px 16px 64px rgba(0, 0, 0, 0.08)",
    // borderRadius: "10px",
    // py: "1rem",
    // px: "2rem",
  },
  
  imgBox: { display: "flex", justifyContent: "center", },

  bouncingBox: {
    display: "flex", justifyContent: "center", alignItems: "baseline",
    fontFamily: 'poppins',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: { xs: "18px", md: "25px" },
    lineHeight: "20px",
    color: "#000000",
    mt: 2
  },
  titleBox: {
    display: "flex", justifyContent: "center",
    fontFamily: 'poppins',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "155%",
    color: "#344054",
    textAlign: 'center'
  }
}