import { Box, Typography } from '@mui/material'
import React from 'react'
import { cnicViewStyle } from "Latest/MyProfileV1/Style/style";

const texts = "Current investment limit for your account is PKR 800,000. To disable limits please provide additional information and documents."
const noteText = "Your account upgrade will be completed in 5 business days"


const StaticContent = ({ noteTextShow = false, text = texts, }: props) => {
    return (
        <>
            <Box>
                <Typography variant='h4' sx={{ ...cnicViewStyle.title }}>Upgrade account</Typography>
                <Typography sx={{ mt: "10px" }}>{text}</Typography>

                {noteTextShow && <Typography sx={{ mt: "10px", fontStyle: 'italic' }}>{noteText}</Typography>}

            </Box>
        </>
    )
}

export default StaticContent

interface props {
    text?: string;
    noteTextShow?: boolean;
}