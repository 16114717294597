export function pxToRem(value: number) {
    return `${value / 16}rem`;
}

export function responsiveFontSizes({ lg, md, sm, xl , xs }: { xs: number; sm: number; md: number; lg: number , xl: number }) {
    return {
        '@media (min-width:0px)': {
            fontSize: pxToRem(xs),
        },
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (min-width:900px)': {
            fontSize: pxToRem(md),
        },
        '@media (min-width:1200px)': {
            fontSize: pxToRem(lg),
        },
        '@media (min-width:1536px)': {
            fontSize: pxToRem(xl),
        },
    };
}
