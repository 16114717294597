import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { portfolioChartCard } from '../style'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import PorfolioChart from 'jsx/components/WizardFormModal/CashFundAssetAllocation/Component/PorfolioChart';
import { onGetAssetAllocation } from 'redux/reducers/sharedStateData';
import AccordionList from '../../AssestAlocation/Component/AccordionList';
import CustomPieChart from 'Latest/ChartV1/customPieChart';
import { colorPortfolioCard, pieColorHsl, pieColors } from '../../../../../constants';
import { CustomScrollerCardStyle } from 'Latest/ComponentV1/style/style';
import CustomScrollerCard from 'Latest/ComponentV1/customScrollerCard';
import Big from 'big.js';
import { customColorGenerator } from 'utils/colorGerator';
import { porfolioColors, porfolioColorsToHsl } from 'Latest/DashboardScreen/FreshUserScreen/constant';

export interface KeyValuePair {
    name: string,
    value: number
}

const PorojectionCard = ({ colorGraph, formik, selectedPorfolio }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const newColor = porfolioColors();
    const hslColors = porfolioColorsToHsl();
    // console.log("hslColor", hslColors)

    const { personalizedPortfolio } = useAppSelector(state => state.sharedData);
    const [pieMockData, setPieMockData] = useState<any>();
    const [accordionList, setAccordionList] = useState<any>([]);

    const { user } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    const [activeIndex, setActiveIndex] = useState(-1)
    const [genratedColor, setGenratedColor] = useState([])

    const handleColorGenerator = () => {
        const index = newColor.findIndex(obj => obj === colorGraph);
        // const index = colorPortfolioCard.findIndex(obj => obj === colorGraph);
        const hslColor = index >= 0 ? hslColors[index] : pieColorHsl[1];

        const generatedColors: any = customColorGenerator(hslColor)
        if (pieMockData !== undefined) {
            if (pieMockData.length == 1) {
                const darkColor: any = [generatedColors[2]]
                setGenratedColor(darkColor);
            } else {
                setGenratedColor(generatedColors);
            }
        }
    }

    useEffect(() => {
        handleColorGenerator()
    }, [colorGraph, pieMockData])

    useEffect(() => {
        if (selectedPorfolio?.assetsType?.length > 0) {
            const data = selectedPorfolio?.assetsType?.map((val: { assetName: any; assetPercentage: any; amount?: number }) => (
                { 'name': val?.assetName, 'value': val?.assetPercentage, "amount": null }
            )).filter((item: any) => item.value > 0);
            setPieMockData(data);

            if (selectedPorfolio.assetsType?.length > 0) {
                const resultArray = selectedPorfolio?.assetsType?.filter((asset: any) => asset.assetPercentage > 0)
                    .map((asset: any) => {
                        const amtTotal = new Big(formik?.values?.initial_amount || 0).mul(asset?.assetPercentage).div(100).toFixed(2, 0)
                        // const portfolioAllocations = asset?.investProducts ? Object.entries(asset?.investProducts)
                        //     .filter(([name, per]) => Number(per) > 0)
                        //     .map(([name, percentage]) => ({
                        //         name,
                        //         percentage,
                        //         initialAmount: new Big(formik?.values?.initial_amount || 0).mul(Number(percentage || 0)).div(100).toFixed(2, 0)
                        //     }))
                        //     :
                        //     [];
                        const portfolioAllocations = asset?.products?.length > 0 ? asset?.products
                            .filter((x: any) => Number(x.value1) > 0)
                            .map((item: any) => ({
                                name: item.key,
                                percentage: item.value1,
                                initialAmount: new Big(formik?.values?.initial_amount || 0).mul(Number(item.value1 || 0)).div(100).toFixed(2, 0),
                                link: item.value2
                            }))
                            :
                            [];
                        return {
                            name: asset.assetName,
                            value: asset.assetPercentage,
                            amount: amtTotal,
                            portfolioAllocations
                        };
                    });
                setAccordionList(resultArray)
            }
        }
    }, [selectedPorfolio, formik.values.initial_amount])


    return (
        <>
            <Grid item md={12} xs={12} sx={{ p: "12px 20px", borderBottom: "1px solid rgba(208, 213, 221, 0.25)" }}>
                <Typography variant='h1' color="#422E84" >Portfolio</Typography>
            </Grid>

            <Grid item md={12} xs={12} >
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center" }}>
                    <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
                        {pieMockData && <CustomPieChart hoverDotVal={(hoverDotVal: any) => { setActiveIndex(hoverDotVal) }} innerRadiusPie={"84%"} outerRadiusPie={"100%"} colors={genratedColor} data={pieMockData} showCustomActiveShape={false} customTooltipTrue={false} hoverToolTip={false} customHeight={smScreen ? 230 : xsScreen ? 210 : 310} />}
                    </Box>
                    <CustomScrollerCard data={accordionList} colors={genratedColor} activeIndex={activeIndex} />
                </Box>
            </Grid>

        </>
    )
}

export default PorojectionCard