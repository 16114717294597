import { useAppLoading } from "hooks/appLoading";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Box, Collapse, Skeleton } from '@mui/material'
import { useState } from "react";
import { onPatchNotifications } from "redux/reducers/sharedStateData";
import DevidendWarningAlert from "../Alerts/devidendWarningAlert";

const Dividend_Notification = () => {

    const loading = useAppLoading('auth');
    const dispatch = useAppDispatch();
    const [isCloseLocal, setIsCloseLocal] = useState(true);
    const { notficationHub } = useAppSelector(state => state.sharedData);

    const handleClose = () => {
        setIsCloseLocal(false)
        const formData: any = new FormData();
        formData.append('EnableNotifications', false);
        formData.append('NotificationType', "DividendPayout");
        dispatch(onPatchNotifications(formData))
    }

    return (
        <>
            {loading ?
                <SkeletonLoading />
                :
                <Collapse in={isCloseLocal} timeout={{ enter: 500, exit: 500 }} >
                    {notficationHub?.DividendPayout?.toLowerCase() == "true" && <DevidendWarningAlert handleClose={handleClose} />}
                </Collapse>

            }
        </>
    )
}

export default Dividend_Notification;


const SkeletonLoading = () => {
    return (
        <Box sx={{ px: { xs: "16px", sm: "32px" }, pb: { xs: "16px", sm: "24px" } }}>
            <Skeleton variant="rounded" width={"100%"} height={20} />
        </Box>
    )
}