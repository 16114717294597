import { memo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikFieldProps } from './FormikInputField';
import Select, { StylesConfig, components } from 'react-select';
import { CSSObject } from 'styled-components';
import { Box, Typography } from '@mui/material';

type Field = { label: string; value: string };

const styles: StylesConfig<Field, false> = {
  option: (provided: CSSObject, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    // borderRadius: '0.75rem !important',
    color: state.isSelected ? '#4012A0' : '#444',
    height: 40,
    borderColor: state.isFocused ? 'grey' : 'red',
  })
};

const AppSelectWithLabel = ({
  FieldTouchInput = false,
  customStyle,
  disabled = false,
  fieldName,
  fieldSelect = false,
  fields,
  formik,
  isMulti = false,
  label,
  loading = false,
  name,
  nestedFieldName,
  onChange,
  onInputChange,
  placeholder,
  required,
  value,
}: AppSelectWithLabelProps) => {
  const [isFieldTouched, setIsFieldTouched] = useState(false);
  const changeHandle = (option: Field) => onChange?.(option.label, option);
  const formikTouch = formik.touched as any;
  const formikError = formik.errors as any;
  if (!fields.length) return null;
  if (loading) return null;

  let FieldError = "";
  let FieldTouch = false;
  let NestedFieldError = "";
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }

  return (
    <Col md={12} className='ContaryCol'>
      <div
        className={'form-group mb-0'}
        id={fieldSelect ? 'react-select-field' : 'react-select'}
      >
        {/* <label className='mb-1 field-label ml-3'>
              <strong style={{ fontFamily: 'Inter,sans-serif', color: '#000000', fontSize: '14px' }}>{label}</strong>
              {required && <span className='ml-1 text-danger'>*</span>}
            </label> */}

        {label && (
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "6px" }}>
            <Typography variant='body2' color="#1D2939">
              {label}
              {required && <span className='ml-1 text-danger'>*</span>}
            </Typography>
          </Box>
        )}
        
        <Select
          className=''
          defaultValue={fields.filter(el => el.label == formik.values[fieldName])}
          isDisabled={disabled}
          isLoading={loading}
          name={name}
          onMenuOpen={() => nestedFieldName ? formik.setFieldTouched(nestedFieldName, true) : formik.setFieldTouched(fieldName, true)}
          // onBlur={() => nestedFieldName ? formik.setFieldTouched(nestedFieldName, true) : formik.setFieldTouched(fieldName, true)}
          onChange={(option: any) => option && changeHandle(option)}
          onInputChange={onInputChange}
          options={fields}
          placeholder={placeholder ?? label}
          required={required || false}
          styles={{ ...styles, ...customStyle }}
          touched={isFieldTouched}
          value={fields.find(el => el.label == formik.values[fieldName])}
        />

        {formik && (FieldTouch || FieldTouchInput) && FieldError && (
          <Row>
            <span className='ml-3 mt-1 text-danger'>
              {FieldError}
            </span>
          </Row>
        )}

        {/* {formik && NestedFieldTouch && NestedFieldError && (
          <Row>
            <span className='ml-4 mt-1 text-danger'>
              {NestedFieldError}
            </span>
          </Row>
        )} */}
      </div>
    </Col>
  );
};

export default memo(AppSelectWithLabel);
interface AppSelectWithLabelProps {
  fieldSelect?: boolean;
  FieldTouchInput?: boolean,
  disabled?: boolean;
  fieldName: string;
  nestedFieldName?: any;
  fields: Array<Field>;
  formik: any;
  isMulti?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (
    option: string,
    optionObject: Record<string, string | number>
  ) => void;
  onInputChange?: (label: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  loading?: boolean;
  customStyle?: any
}
