import { pxToRem } from "utils/V1/getFontValues";

export const withdrawStyle = {
    checkboxKey: {
        // fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: `13px !important`,
        lineHeight: "150%",
        color: "#666666",
        textTransform: "capitalize",
        pb: "6px"
    },
    checkboxValue: {
        // fontFamily: 'Inter,sans-serif',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px !important",
        lineHeight: "150%",
        color: "#202020",
        pb: "6px"
    },
    imgGrid: {
         py: "17px" , px:"18px",
        borderRight:{xs:"0px",sm:"1px solid #E4E4E4"},
        borderBottom : {xs:"1px solid #E4E4E4",sm:"0px"},
        borderRadius: "4px 0px 0px 4px",
        display: { xs: "flex",sm:"grid", md: "grid" },
        justifyContent: "center",
        alignItems: {sm:"center" , md:"center"}
    },
    keyGrid: {
        display: "flex", direction: "column", cursor: "pointer",
        border: "1px solid #E4E4E4", borderRadius: "4px", position: "relative", flexDirection: { xs: "column",sm:"row" ,  md: "row" }
    },
    detailBox: { width: "100%", display: "flex", flexWrap: "wrap", flexDirection: { xs: "column",sm:"row", md: "row" }, wordWrap: "break-word" },
    detailSubBoxValue: { width: { xs: "100%", md: "60%" } },
    detailSubBoxKey: { width: { xs: "100%", md: "40%" } }
}