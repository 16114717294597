export const CityName = [
    {
      "id": "63aa0845e0201df34a78b9b1",
      "name": "Abbottabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9b2",
      "name": "Adezai",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9b3",
      "name": "Ahmed Nager Chatha",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9b4",
      "name": "Ahmedpur East",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9b5",
      "name": "Ali Bandar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9b6",
      "name": "Ali Pur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9b7",
      "name": "Amir Chah",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9b8",
      "name": "Arifwala",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9b9",
      "name": "Astor",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9ba",
      "name": "Attock",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9bb",
      "name": "Ayubia",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9bc",
      "name": "Baden",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9bd",
      "name": "Bagh",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9be",
      "name": "Bahawalnagar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9bf",
      "name": "Bahawalpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9c0",
      "name": "Bajaur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9c1",
      "name": "Banda Daud Shah",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9c2",
      "name": "Bannu",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9c3",
      "name": "Baramula",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9c4",
      "name": "Basti Malook",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9c5",
      "name": "Batagram",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9c6",
      "name": "Bazdar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9c7",
      "name": "Bela",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9c8",
      "name": "Bellpat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9c9",
      "name": "Bhag",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9ca",
      "name": "Bhagalchur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9cb",
      "name": "Bhaipheru",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9cc",
      "name": "Bhakkar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9cd",
      "name": "Bhalwal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9ce",
      "name": "Bhimber",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9cf",
      "name": "Birote",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9d0",
      "name": "Buner",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9d1",
      "name": "Burewala",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9d2",
      "name": "Burj",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9d3",
      "name": "Chachro",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9d4",
      "name": "Chagai",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9d5",
      "name": "Chah Sandan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9d6",
      "name": "Chailianwala",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9d7",
      "name": "Chakdara",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9d8",
      "name": "Chakku",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9d9",
      "name": "Chakwal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9da",
      "name": "Chaman",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9db",
      "name": "Charsadda",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9dc",
      "name": "Chhatr",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9dd",
      "name": "Chichawatni",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9de",
      "name": "Chiniot",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9df",
      "name": "Chitral",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9e0",
      "name": "Chowk Azam",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9e1",
      "name": "Chowk Sarwar Shaheed",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9e2",
      "name": "Chunia",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9e3",
      "name": "Dadu",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9e4",
      "name": "Dadyal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9e5",
      "name": "Daharki",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9e6",
      "name": "Dalbandin",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9e7",
      "name": "Dargai",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9e8",
      "name": "Darya Khan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9e9",
      "name": "Darya Khan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9ea",
      "name": "Daska",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9eb",
      "name": "Dera Bugti",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9ec",
      "name": "Dera Ghazi Khan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9ed",
      "name": "Dera Ismail Khan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9ee",
      "name": "Derawar Fort",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9ef",
      "name": "Dhana Sar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9f0",
      "name": "Dhaular",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9f1",
      "name": "Dhungi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9f2",
      "name": "Digri",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9f3",
      "name": "Dina City",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9f4",
      "name": "Dinga",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9f5",
      "name": "Dipalpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9f6",
      "name": "Diplo",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9f7",
      "name": "Dir",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9f8",
      "name": "Diwana",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9f9",
      "name": "Dokri",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9fa",
      "name": "Drasan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9fb",
      "name": "Drosh",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9fc",
      "name": "Duki",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9fd",
      "name": "Dushi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9fe",
      "name": "Duzab",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78b9ff",
      "name": "Faisalabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba00",
      "name": "Fateh Jang",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba01",
      "name": "Gadar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba02",
      "name": "Gadra",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba03",
      "name": "Gajar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba04",
      "name": "Gandava",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba05",
      "name": "Garhi Khairo",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba06",
      "name": "Garruck",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba07",
      "name": "Ghakhar Mandi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba08",
      "name": "Ghanian",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba09",
      "name": "Ghauspur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba0a",
      "name": "Ghazluna",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba0b",
      "name": "Ghotki",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba0c",
      "name": "Gilgit",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba0d",
      "name": "Girdan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba0e",
      "name": "Gojra",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba0f",
      "name": "Goth Machi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba10",
      "name": "Gujar Khan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba11",
      "name": "Gujranwala",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba12",
      "name": "Gujrat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba13",
      "name": "Gulistan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba14",
      "name": "Gwadar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba15",
      "name": "Gwash",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba16",
      "name": "Hab Chauki",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba17",
      "name": "Hafizabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba18",
      "name": "Hala (Sindh) Hala",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba19",
      "name": "Hameedabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba1a",
      "name": "Hangu",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba1b",
      "name": "Hangu",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba1c",
      "name": "Haripur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba1d",
      "name": "Harnai",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba1e",
      "name": "Haroonabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba1f",
      "name": "Hasan Abdal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba20",
      "name": "Hasilpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba21",
      "name": "Hattian Bala",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba22",
      "name": "Haveli Lakha",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba23",
      "name": "Hinglaj",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba24",
      "name": "Hoshab",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba25",
      "name": "Hub",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba26",
      "name": "Hunza",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba27",
      "name": "Hyderabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba28",
      "name": "Islamabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba29",
      "name": "Islamkot",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba2a",
      "name": "Ispikan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba2b",
      "name": "Jacobabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba2c",
      "name": "Jafferabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba2d",
      "name": "Jahania",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba2e",
      "name": "Jalla Araain",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba2f",
      "name": "Jamesabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba30",
      "name": "Jampur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba31",
      "name": "Jamshoro",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba32",
      "name": "Janghar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba33",
      "name": "Jaranwala",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba34",
      "name": "Jati",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba35",
      "name": "Jauharabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba36",
      "name": "Jhal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba37",
      "name": "Jhal Jhao",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba38",
      "name": "Jhal Magsi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba39",
      "name": "Jhang",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba3a",
      "name": "Jhatpat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba3b",
      "name": "Jhelum",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba3c",
      "name": "Jhudo",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba3d",
      "name": "Jiwani",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba3e",
      "name": "Jungshahi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba3f",
      "name": "Kalabagh",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba40",
      "name": "Kalam",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba41",
      "name": "Kalandi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba42",
      "name": "Kalat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba43",
      "name": "Kamalia",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba44",
      "name": "Kamararod",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba45",
      "name": "Kamber",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba46",
      "name": "Kamokey",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba47",
      "name": "Kanak",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba48",
      "name": "Kandhkot",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba49",
      "name": "Kandi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba4a",
      "name": "Kandiaro",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba4b",
      "name": "Kanpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba4c",
      "name": "Kapip",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba4d",
      "name": "Kappar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba4e",
      "name": "Karachi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba4f",
      "name": "Karak",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba50",
      "name": "Karodi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba51",
      "name": "Karor Lal Esan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba52",
      "name": "Kashmor",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba53",
      "name": "Kasur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba54",
      "name": "Katuri",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba55",
      "name": "Keti Bandar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba56",
      "name": "Khairpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba57",
      "name": "Khairpur Miris",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba58",
      "name": "Khanaspur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba59",
      "name": "Khanewal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba5a",
      "name": "Khanpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba5b",
      "name": "Kharan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba5c",
      "name": "Kharian",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba5d",
      "name": "Khokhropur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba5e",
      "name": "Khora",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba5f",
      "name": "Khuiratta",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba60",
      "name": "Khushab",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba61",
      "name": "Khuzdar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba62",
      "name": "Khyber",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba63",
      "name": "Kikki",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba64",
      "name": "Klupro",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba65",
      "name": "Kohan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba66",
      "name": "Kohat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba67",
      "name": "Kohistan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba68",
      "name": "Kohlu",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba69",
      "name": "Korak",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba6a",
      "name": "Kot Addu",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba6b",
      "name": "Kot Sarae",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba6c",
      "name": "Kotli",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba6d",
      "name": "Kotri",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba6e",
      "name": "Kurram",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba6f",
      "name": "Laar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba70",
      "name": "Lahore",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba71",
      "name": "Lahri",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba72",
      "name": "Lakki Marwat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba73",
      "name": "Lalamusa",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba74",
      "name": "Larkana",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba75",
      "name": "Lasbela",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba76",
      "name": "Latamber",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba77",
      "name": "Layyah",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba78",
      "name": "Liari",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba79",
      "name": "Lodhran",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba7a",
      "name": "Loralai",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba7b",
      "name": "Lower Dir",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba7c",
      "name": "Lund",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba7d",
      "name": "Mach",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba7e",
      "name": "Madyan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba7f",
      "name": "Mailsi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba80",
      "name": "Makhdoom Aali",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba81",
      "name": "Malakand",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba82",
      "name": "Malakand",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba83",
      "name": "Mamoori",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba84",
      "name": "Mand",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba85",
      "name": "Mandi Bahauddin",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba86",
      "name": "Mandi Warburton",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba87",
      "name": "Mangla",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba88",
      "name": "Manguchar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba89",
      "name": "Mansehra",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba8a",
      "name": "Mardan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba8b",
      "name": "Mashki Chah",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba8c",
      "name": "Maslti",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba8d",
      "name": "Mastuj",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba8e",
      "name": "Mastung",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba8f",
      "name": "Mathi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba90",
      "name": "Matiari",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba91",
      "name": "Matli",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba92",
      "name": "Mehar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba93",
      "name": "Mekhtar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba94",
      "name": "Merui",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba95",
      "name": "Mian Channu",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba96",
      "name": "Mianez",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba97",
      "name": "Mianwali",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba98",
      "name": "Minawala",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba99",
      "name": "Miram Shah",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba9a",
      "name": "Mirpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba9b",
      "name": "Mirpur Batoro",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba9c",
      "name": "Mirpur Khas",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba9d",
      "name": "Mirpur Mathelo",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba9e",
      "name": "Mirpur Sakro",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78ba9f",
      "name": "Mithani",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baa0",
      "name": "Mithi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baa1",
      "name": "Mohmand",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baa2",
      "name": "Moin Jo Daro",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baa3",
      "name": "Mongora",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baa4",
      "name": "Moro",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baa5",
      "name": "Multan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baa6",
      "name": "Murgha Kibzai",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baa7",
      "name": "Muridke",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baa8",
      "name": "Murree",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baa9",
      "name": "Musa Khel Bazar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baaa",
      "name": "Muzaffarabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baab",
      "name": "Muzaffargarh",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baac",
      "name": "Nagar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baad",
      "name": "Nagar Parkar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baae",
      "name": "Nagha Kalat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baaf",
      "name": "Nal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bab0",
      "name": "Nankana Sahib",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bab1",
      "name": "Naokot",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bab2",
      "name": "Narowal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bab3",
      "name": "Naseerabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bab4",
      "name": "Naudero",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bab5",
      "name": "Nauroz Kalat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bab6",
      "name": "Naushara",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bab7",
      "name": "Naushero Feroze",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bab8",
      "name": "Nawabshah",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bab9",
      "name": "Nazimabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baba",
      "name": "Neelum",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78babb",
      "name": "North Waziristan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78babc",
      "name": "Nowshera",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78babd",
      "name": "Nur Gamma",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78babe",
      "name": "Nushki",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78babf",
      "name": "Nuttal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bac0",
      "name": "Okara",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bac1",
      "name": "Ormara",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bac2",
      "name": "Paharpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bac3",
      "name": "Pak Pattan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bac4",
      "name": "Palantuk",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bac5",
      "name": "Panjgur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bac6",
      "name": "Panjgur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bac7",
      "name": "Panoakil",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bac8",
      "name": "Parachinar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bac9",
      "name": "Pasni",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baca",
      "name": "Pattoki",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bacb",
      "name": "Peacock",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bacc",
      "name": "Pendoo",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bacd",
      "name": "Peshawar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bace",
      "name": "Piharak",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bacf",
      "name": "Pirmahal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bad0",
      "name": "Pishin",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bad1",
      "name": "Plandri",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bad2",
      "name": "Pokran",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bad3",
      "name": "Punch",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bad4",
      "name": "Qambar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bad5",
      "name": "Qamruddin Karez",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bad6",
      "name": "Qazi Ahmad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bad7",
      "name": "Qila Abdullah",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bad8",
      "name": "Qila Didar Singh",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bad9",
      "name": "Qila Ladgasht",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bada",
      "name": "Qila Safed",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78badb",
      "name": "Qila Saifullah",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78badc",
      "name": "Quetta",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78badd",
      "name": "Rabwah",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bade",
      "name": "Rahim Yar Khan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78badf",
      "name": "Raiwind",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bae0",
      "name": "Rajan Pur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bae1",
      "name": "Rakhni",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bae2",
      "name": "Ranipur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bae3",
      "name": "Ratodero",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bae4",
      "name": "Rawalakot",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bae5",
      "name": "Rawalpindi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bae6",
      "name": "Renala Khurd",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bae7",
      "name": "Robat Thana",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bae8",
      "name": "Rodkhan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bae9",
      "name": "Rohri",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baea",
      "name": "Rohri",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baeb",
      "name": "Sadiqabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baec",
      "name": "Safdarabad-dabansing",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baed",
      "name": "Sahiwal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baee",
      "name": "Saidu Sharif",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baef",
      "name": "Saidu Sharif",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baf0",
      "name": "Saindak",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baf1",
      "name": "Sakesar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baf2",
      "name": "Sakrand",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baf3",
      "name": "Samberial",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baf4",
      "name": "Sanghar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baf5",
      "name": "Sangla Hill",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baf6",
      "name": "Sanjawi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baf7",
      "name": "Sarai Alamgir",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baf8",
      "name": "Sargodha",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baf9",
      "name": "Saruna",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bafa",
      "name": "Shabaz Kalat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bafb",
      "name": "Shadadkhot",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bafc",
      "name": "Shafqat Shahed Chowk",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bafd",
      "name": "Shahbandar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bafe",
      "name": "Shahdadpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78baff",
      "name": "Shahpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb00",
      "name": "Shahpur Chakar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb01",
      "name": "Shakargarh",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb02",
      "name": "Shandur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb03",
      "name": "Shangla",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb04",
      "name": "Shangrila",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb05",
      "name": "Sharam Jogizai",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb06",
      "name": "Sharaqpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb07",
      "name": "Sheikhupura",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb08",
      "name": "Shikarpur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb09",
      "name": "Shingar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb0a",
      "name": "Shorap",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb0b",
      "name": "Sialkot",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb0c",
      "name": "Sibi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb0d",
      "name": "Skardu",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb0e",
      "name": "Sohawa",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb0f",
      "name": "Sonmiani",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb10",
      "name": "Sooianwala",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb11",
      "name": "South Waziristan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb12",
      "name": "Spezand",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb13",
      "name": "Spintangi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb14",
      "name": "Sudhnoti",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb15",
      "name": "Sui",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb16",
      "name": "Sujawal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb17",
      "name": "Sukkur",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb18",
      "name": "Sundar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb19",
      "name": "Suntsar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb1a",
      "name": "Surab",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb1b",
      "name": "Swabi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb1c",
      "name": "Swat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb1d",
      "name": "Takhtbai",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb1e",
      "name": "Talagang",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb1f",
      "name": "Tandlianwala",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb20",
      "name": "Tando Adam",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb21",
      "name": "Tando Alah Yar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb22",
      "name": "Tando Bago",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb23",
      "name": "Tando Muhammad Khan",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb24",
      "name": "Tangi",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb25",
      "name": "Tank",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb26",
      "name": "Tar Ahamd Rind",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb27",
      "name": "Tarbela",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb28",
      "name": "Taxila",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb29",
      "name": "Thall",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb2a",
      "name": "Thalo",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb2b",
      "name": "Tharparkar",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb2c",
      "name": "Thatta",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb2d",
      "name": "Timargara",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb2e",
      "name": "Toba Tek Singh",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb2f",
      "name": "Tordher",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb30",
      "name": "Tujal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb31",
      "name": "Tump",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb32",
      "name": "Turbat",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb33",
      "name": "Ubaro",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb34",
      "name": "Umarao",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb35",
      "name": "Umarkot",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb36",
      "name": "Upper Dir",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb37",
      "name": "Uthal",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb38",
      "name": "Vehari",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb39",
      "name": "Veirwaro",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb3a",
      "name": "Vitakri",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb3b",
      "name": "Wadh",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb3c",
      "name": "Wah Cantt",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb3d",
      "name": "Wana",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb3e",
      "name": "Warah",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb3f",
      "name": "Washap",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb40",
      "name": "Wasjuk",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb41",
      "name": "Wazirabad",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb42",
      "name": "Yakmach",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb43",
      "name": "Zhob",
      "state": null,
      "country": "Pakistan"
    },
    {
      "id": "63aa0845e0201df34a78bb44",
      "name": "Ziarat",
      "state": null,
      "country": "Pakistan"
    }
  ]