export const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 530,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    py: 3,
    px: 4,
    minWidth: 320
};

export const CloseIconStyle = {
    position: "absolute",
    top: 6,
    right: 6
};

export const ConfirmationMessageStyle = {
    color: "black !important",
    fontWeight: "bold"
}

export const totalAmountStyle = {
    mt: 2,
    mb: 2,
    color: "black"
}

export const CheckmarkBoxStyle = {
    display: "flex",
    justifyContent: "center",
    my: 4
}

export const OkBtnGridStyle = {
    justifyContent: "center",
    mt: 4,
    mb: 2
}