import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import { containerMain, containerMainBox } from 'Latest/Style/Main'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AvatarMale from "Latest/Images/male2.jpg"
import AvatarFemale from "Latest/Images/female1.jpg"
import { useAppSelector } from 'hooks/reduxHooks';
import AvatarIcon from "Latest/Images/svg/brokenImg.svg"

const AvatarCard = () => {
    const { userAccountDetails } = useAppSelector((state: any) => state.auth);

    const gender = userAccountDetails?.identityCard?.gender == "Male"

    return (

        <>

            <Grid item xs={12} sx={{ display: "flex", alignItems: 'center', gap: "16px" }}>

                <Box sx={{
                    borderRadius: "142px",
                    border: "2.84px solid #FFF",
                    boxShadow: "0px 2.8399999141693115px 4.259999752044678px -1.4199999570846558px rgba(16, 24, 40, 0.03), 0px 8.519999504089355px 11.359999656677246px -2.8399999141693115px rgba(16, 24, 40, 0.08)"
                }}>
                    <Avatar alt="Remy Sharp" src={AvatarIcon}
                        sx={{ cursor: "pointer", "&:hover": { transform: "scale(0.98)" }, width: {xs:60,md:71}, height: {xs:60,md:71}, }} />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: "column", gap: "4px" }} >
                    <Typography variant='h5'>
                        {userAccountDetails?.identityCard?.name || userAccountDetails?.userName}
                    </Typography>
                    <Typography variant='body1'>
                        {userAccountDetails?.email || "-"}
                    </Typography>
                </Box>

            </Grid>
        </>
    )
}

export default AvatarCard