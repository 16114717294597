import { Link } from 'react-router-dom';
import { memo, useEffect } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import Img from "Latest/Images/errImg.png"
import { screen404Style } from '../style/style';
import { linkSpan } from 'Latest/Style/Main';
import { bankDetailStyle } from 'Latest/MyProfile/Style/bankDetailStyle';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { links } from 'static/links';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'hooks/reduxHooks';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import { mixPannelTrackerEventName } from '../../../constants/index';


const ErrorUndefined = ({ pageName, url }: Error404Props) => {
    const { push } = useHistory();
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const reloadPage = () => {
        window.location.href = '/';
    };
    const { user: { email } } = useAppSelector((state: any) => state.auth);
    useEffect(() => {
        // Ensure email is available before calling MixPannelTracker
        if (email) {
          MixPannelTracker({ eventName: mixPannelTrackerEventName.something_went_wrong, email });
        }
      }, [email]);
    return (
        <>
            <Container maxWidth={'xl'} sx={{ my: 5, }} >
                <Box sx={{ background: "#FFFFFF", borderRadius: "12px", border: "1px solid #EDEDED", p: { xs: 4, sm: 10, md: 5, lg: 5 } }}>

                    <Grid container  >

                        <Grid item md={12} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <img src={Img} style={{ width: smScreen ? "40%" : "100%" }} />
                            <Typography sx={screen404Style.blueTitle}>Oops, something went wrong</Typography>
                            <Typography sx={screen404Style.para2}>You may refresh the page or try again later</Typography>
                            <Button sx={{ ...screen404Style.homeBtn, background: "rgba(64, 48, 129, 1)", color: "white", mt: 2 }} onClick={reloadPage} >
                                Go to home
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
            </Container>

        </>
    );
};

export default memo(ErrorUndefined);

interface Error404Props {
    pageName?: string;
    url?: string;
}