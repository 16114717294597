import { Box, Container, Grid } from '@mui/material'
import WizardFormBody from 'jsx/components/WizardFormModal/WizardFormBodyLatest'
import React from 'react'
import { links } from 'static/links'
import { useHistory } from 'react-router-dom';

const Index = ({ handleGoalBack, selectedGoal }: any) => {

    const { push } = useHistory();
    
    return (
        <>
            <WizardFormBody
                goalId={selectedGoal.id}
                goalValue={selectedGoal.val}
                goalName={selectedGoal.name}
                isThisGoalCreated={true}
                loading={false}
                proceedButtonHandler={() => push(links.accountSetupForm)}
                handleGoalBack={handleGoalBack}
            />
        </>

    )

}

export default Index