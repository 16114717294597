/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import React, { memo, useState, useCallback, CSSProperties, FormEvent, useRef, useEffect } from "react";
import SingleInput from "./SingleInput";

export interface OTPInputProps {
  length: number;
  onChangeOTP: (otp: string) => any;

  autoFocus?: boolean;
  isNumberInput?: boolean;
  disabled?: boolean;

  style?: CSSProperties;
  className?: string;

  inputStyle?: CSSProperties;
  inputClassName?: string;
}

export function OTPInputComponent(props: OTPInputProps) {
  const {
    autoFocus,
    disabled,
    inputClassName,
    inputStyle,
    isNumberInput,
    length,
    onChangeOTP,
    ...rest
  } = props;

  const otpFieldsRef = useRef<Array<HTMLInputElement | null>>([]);
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOtpValues] = useState(Array<string>(length).fill(""));

  useEffect(() => {
    handleOtpChange(otpValues)
  }, [otpValues])
  useEffect(() => {
    // Focus on the next empty field when the active input changes
    if (activeInput < length) {
      otpFieldsRef.current[activeInput]?.focus();
    }
  }, [activeInput]);

  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join("");
      onChangeOTP(otpValue);
    },
    [onChangeOTP]
  );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str: string) => {
      const changedValue = str;

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : "";
    },
    [isNumberInput]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string, updatedIndex?: number) => {
      const index = updatedIndex || 0;
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[index] || "";
      setOtpValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number, e?: React.FocusEvent<HTMLInputElement>) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback((currentActive?: number) => {
    const shouldActive = currentActive || activeInput + 1;
    focusInput(shouldActive);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>, index: number) => {
      focusInput(index, e);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }

      const updatedIndex = e?.target.selectionStart == 2 ? 1 : 0
      changeCodeAtFocus(val, updatedIndex);
      focusNextInput(activeInput + (updatedIndex + 1));
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case "Backspace":
        case "Delete": {
          changeCodeAtFocus("");
          focusPrevInput();
          break;
        }
        case "ArrowLeft": {
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/) || pressedKey == "ArrowUp" || pressedKey == "ArrowDown") {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput)
        .split("");
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOtpValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues]
  );

  return (
    <div {...rest}>

      {Array(length)
        .fill("")
        .map((_, index) => {
          return (
            <SingleInput
              key={`SingleInput-${index}`}
              focus={activeInput === index}
              value={otpValues && otpValues[index]}
              autoFocus={autoFocus}
              onFocus={(e) => handleOnFocus(e, index)}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onBlur={onBlur}
              onPaste={handleOnPaste}
              style={inputStyle}
              className={`${inputClassName} ${activeInput === index ? 'focused' : ''}`}
              disabled={disabled}
              placeholder="-"
            />
          )
        })}
    </div>
  );
}

const OTPInput = memo(OTPInputComponent);
export default OTPInput;


