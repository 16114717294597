

import { Box } from '@mui/material'
import NoData from 'Latest/Illustration/noData'
import React, { useEffect, useState } from 'react'
import { PerformanceAreaChart } from 'Latest/ChartV1/performanceAreaChart'
import Big from 'big.js'
import { monthlySavingReturnsCalculation } from 'utils/monthlySavingReturnsCalculation'
import { goalCategory, mahaanaSaveRate } from '../../../constants'
import moment from 'moment'
import { onGetCFPerformanceGraph, onGetGIPerformanceGraph } from 'redux/reducers/sharedStateData'
import { useDispatch } from 'react-redux'
import { useAppLoading } from 'hooks/appLoading'


const testData = [
    {
        "xAxisVal": moment("20231017", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231017", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "206467.69612959"
    },
    {
        "xAxisVal": moment("20231018", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231018", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "207416.19657111"
    },
    {
        "xAxisVal": moment("20231019", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231019", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "207544.23639417"
    },
    {
        "xAxisVal": moment("20231020", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231020", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "207671.69597634"
    },
    {
        "xAxisVal": moment("20231022", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231022", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "207913.8498411"
    },
    {
        "xAxisVal": moment("20231023", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231023", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "208041.5028369"
    },
    {
        "xAxisVal": moment("20231024", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231024", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "208162.57976928"
    },
    {
        "xAxisVal": moment("20231025", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231025", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "214773.12149566"
    },
    {
        "xAxisVal": moment("20231025", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231025", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "214905.09340674"
    },
    {
        "xAxisVal": moment("20231027", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231027", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "215032.08148432"
    },
    {
        "xAxisVal": moment("20231029", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231029", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "215285.6589328"
    },
    {
        "xAxisVal": moment("20231030", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231030", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "215422.2159707"
    },
    {
        "xAxisVal": moment("20231031", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231031", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "215789.82352966"
    },
    {
        "xAxisVal": moment("20231101", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231101", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "215927.77604094"
    },
    {
        "xAxisVal": moment("20231102", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231102", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "216523.84252754"
    },
    {
        "xAxisVal": moment("20231103", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231103", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "216653.2228452"
    },
    {
        "xAxisVal": moment("20231105", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231105", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "371418.59885325"
    },
    {
        "xAxisVal": moment("20231106", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231106", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "370691.00551974"
    },
    {
        "xAxisVal": moment("20231107", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231107", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "370961.9956483"
    },
    {
        "xAxisVal": moment("20231108", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231108", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "371163.19560556"
    },
    {
        "xAxisVal": moment("20231109", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231109", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "371402.52482714"
    },
    {
        "xAxisVal": moment("20231110", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231110", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "371675.89803472"
    },
    {
        "xAxisVal": moment("20231112", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231112", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "372105.53313804"
    },
    {
        "xAxisVal": moment("20231113", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231113", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "372343.50060018"
    },
    {
        "xAxisVal": moment("20231114", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231114", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "372560.70123086"
    },
    {
        "xAxisVal": moment("20231115", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231115", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "372795.2642944"
    },
    {
        "xAxisVal": moment("20231116", "YYYYMMDD").format('DD-MMM-YYYY'),
        "date": moment("20231116", "YYYYMMDD").format('MMM-YYYY'),
        "data1": "358013.1441664"
    }
]

const testData1 = [
    {
        "xAxisVal": "21-Aug-2023",
        "date": "Aug-2023",
        "data1": "82891.66529664"
    },
    {
        "xAxisVal": "22-Aug-2023",
        "date": "Aug-2023",
        "data1": "199938.43880805"
    },
    {
        "xAxisVal": "23-Aug-2023",
        "date": "Aug-2023",
        "data1": "200051.19895434"
    },
    {
        "xAxisVal": "24-Aug-2023",
        "date": "Aug-2023",
        "data1": "200163.95910063"
    },
    {
        "xAxisVal": "25-Aug-2023",
        "date": "Aug-2023",
        "data1": "200276.71924692"
    },
    {
        "xAxisVal": "27-Aug-2023",
        "date": "Aug-2023",
        "data1": "200502.43295313"
    },
    {
        "xAxisVal": "28-Aug-2023",
        "date": "Aug-2023",
        "data1": "200615.19309942"
    },
    {
        "xAxisVal": "29-Aug-2023",
        "date": "Aug-2023",
        "data1": "200727.95324571"
    },
    {
        "xAxisVal": "30-Aug-2023",
        "date": "Aug-2023",
        "data1": "200840.90680563"
    },
    {
        "xAxisVal": "31-Aug-2023",
        "date": "Aug-2023",
        "data1": "200955.40767459"
    },
    {
        "xAxisVal": "01-Sep-2023",
        "date": "Sep-2023",
        "data1": "201068.16782088"
    },
    {
        "xAxisVal": "03-Sep-2023",
        "date": "Sep-2023",
        "data1": "201294.07494072"
    },
    {
        "xAxisVal": "04-Sep-2023",
        "date": "Sep-2023",
        "data1": "201407.02850064"
    },
    {
        "xAxisVal": "05-Sep-2023",
        "date": "Sep-2023",
        "data1": "201522.88326501"
    },
    {
        "xAxisVal": "06-Sep-2023",
        "date": "Sep-2023",
        "data1": "201650.92308807"
    },
    {
        "xAxisVal": "07-Sep-2023",
        "date": "Sep-2023",
        "data1": "201765.81078429"
    },
    {
        "xAxisVal": "08-Sep-2023",
        "date": "Sep-2023",
        "data1": "201879.73141236"
    },
    {
        "xAxisVal": "10-Sep-2023",
        "date": "Sep-2023",
        "data1": "202107.76608213"
    },
    {
        "xAxisVal": "11-Sep-2023",
        "date": "Sep-2023",
        "data1": "202219.55916027"
    },
    {
        "xAxisVal": "12-Sep-2023",
        "date": "Sep-2023",
        "data1": "202331.35223841"
    },
    {
        "xAxisVal": "13-Sep-2023",
        "date": "Sep-2023",
        "data1": "202442.95190292"
    },
    {
        "xAxisVal": "14-Sep-2023",
        "date": "Sep-2023",
        "data1": "202442.95190292"
    },
    {
        "xAxisVal": "15-Sep-2023",
        "date": "Sep-2023",
        "data1": "202553.77791291"
    },
    {
        "xAxisVal": "16-Sep-2023",
        "date": "Sep-2023",
        "data1": "202664.6039229"
    },
    {
        "xAxisVal": "17-Sep-2023",
        "date": "Sep-2023",
        "data1": "202891.86493815"
    },
    {
        "xAxisVal": "18-Sep-2023",
        "date": "Sep-2023",
        "data1": "202891.86493815"
    },
    {
        "xAxisVal": "19-Sep-2023",
        "date": "Sep-2023",
        "data1": "203005.20532533"
    },
    {
        "xAxisVal": "20-Sep-2023",
        "date": "Sep-2023",
        "data1": "203118.93253977"
    },
    {
        "xAxisVal": "21-Sep-2023",
        "date": "Sep-2023",
        "data1": "203232.46634058"
    },
    {
        "xAxisVal": "22-Sep-2023",
        "date": "Sep-2023",
        "data1": "203346.19355502"
    },
    {
        "xAxisVal": "23-Sep-2023",
        "date": "Sep-2023",
        "data1": "203459.5339422"
    },
    {
        "xAxisVal": "24-Sep-2023",
        "date": "Sep-2023",
        "data1": "203685.8278893"
    },
    {
        "xAxisVal": "25-Sep-2023",
        "date": "Sep-2023",
        "data1": "203798.20120833"
    },
    {
        "xAxisVal": "26-Sep-2023",
        "date": "Sep-2023",
        "data1": "203910.1877001"
    },
    {
        "xAxisVal": "27-Sep-2023",
        "date": "Sep-2023",
        "data1": "204023.72150091"
    },
    {
        "xAxisVal": "28-Sep-2023",
        "date": "Sep-2023",
        "data1": "204173.81047779"
    },
    {
        "xAxisVal": "29-Sep-2023",
        "date": "Sep-2023",
        "data1": "204173.81047779"
    },
    {
        "xAxisVal": "01-Oct-2023",
        "date": "Oct-2023",
        "data1": "204511.31726214"
    },
    {
        "xAxisVal": "02-Oct-2023",
        "date": "Oct-2023",
        "data1": "204623.8839948"
    },
    {
        "xAxisVal": "03-Oct-2023",
        "date": "Oct-2023",
        "data1": "204736.64414109"
    },
    {
        "xAxisVal": "04-Oct-2023",
        "date": "Oct-2023",
        "data1": "204854.62645539"
    },
    {
        "xAxisVal": "05-Oct-2023",
        "date": "Oct-2023",
        "data1": "205050.55446258"
    },
    {
        "xAxisVal": "06-Oct-2023",
        "date": "Oct-2023",
        "data1": "205167.3762951"
    },
    {
        "xAxisVal": "08-Oct-2023",
        "date": "Oct-2023",
        "data1": "205400.82654651"
    },
    {
        "xAxisVal": "09-Oct-2023",
        "date": "Oct-2023",
        "data1": "205541.43825552"
    },
    {
        "xAxisVal": "10-Oct-2023",
        "date": "Oct-2023",
        "data1": "205655.93912448"
    },
    {
        "xAxisVal": "11-Oct-2023",
        "date": "Oct-2023",
        "data1": "205772.18071611"
    },
    {
        "xAxisVal": "12-Oct-2023",
        "date": "Oct-2023",
        "data1": "205888.03548048"
    },
    {
        "xAxisVal": "13-Oct-2023",
        "date": "Oct-2023",
        "data1": "206004.08365848"
    },
    {
        "xAxisVal": "15-Oct-2023",
        "date": "Oct-2023",
        "data1": "206235.98660085"
    },
    {
        "xAxisVal": "16-Oct-2023",
        "date": "Oct-2023",
        "data1": "206352.03477885"
    },
    {
        "xAxisVal": "17-Oct-2023",
        "date": "Oct-2023",
        "data1": "206467.69612959"
    },
    {
        "xAxisVal": "18-Oct-2023",
        "date": "Oct-2023",
        "data1": "207416.19657111"
    },
    {
        "xAxisVal": "19-Oct-2023",
        "date": "Oct-2023",
        "data1": "207544.23639417"
    },
    {
        "xAxisVal": "20-Oct-2023",
        "date": "Oct-2023",
        "data1": "207671.69597634"
    },
    {
        "xAxisVal": "22-Oct-2023",
        "date": "Oct-2023",
        "data1": "207913.8498411"
    },
    {
        "xAxisVal": "23-Oct-2023",
        "date": "Oct-2023",
        "data1": "208041.5028369"
    },
    {
        "xAxisVal": "24-Oct-2023",
        "date": "Oct-2023",
        "data1": "208162.57976928"
    },
    {
        "xAxisVal": "25-Oct-2023",
        "date": "Oct-2023",
        "data1": "214773.12149566"
    },
    {
        "xAxisVal": "26-Oct-2023",
        "date": "Oct-2023",
        "data1": "214905.09340674"
    },
    {
        "xAxisVal": "27-Oct-2023",
        "date": "Oct-2023",
        "data1": "215032.08148432"
    },
    {
        "xAxisVal": "29-Oct-2023",
        "date": "Oct-2023",
        "data1": "215285.6589328"
    },
    {
        "xAxisVal": "30-Oct-2023",
        "date": "Oct-2023",
        "data1": "215422.2159707"
    },
    {
        "xAxisVal": "31-Oct-2023",
        "date": "Oct-2023",
        "data1": "215789.82352966"
    },
    {
        "xAxisVal": "01-Nov-2023",
        "date": "Nov-2023",
        "data1": "215927.77604094"
    },
    {
        "xAxisVal": "02-Nov-2023",
        "date": "Nov-2023",
        "data1": "216523.84252754"
    },
    {
        "xAxisVal": "03-Nov-2023",
        "date": "Nov-2023",
        "data1": "216653.2228452"
    },
    {
        "xAxisVal": "05-Nov-2023",
        "date": "Nov-2023",
        "data1": "371418.59885325"
    },
    {
        "xAxisVal": "06-Nov-2023",
        "date": "Nov-2023",
        "data1": "370691.00551974"
    },
    {
        "xAxisVal": "07-Nov-2023",
        "date": "Nov-2023",
        "data1": "370961.9956483"
    },
    {
        "xAxisVal": "08-Nov-2023",
        "date": "Nov-2023",
        "data1": "371163.19560556"
    },
    {
        "xAxisVal": "09-Nov-2023",
        "date": "Nov-2023",
        "data1": "371402.52482714"
    },
    {
        "xAxisVal": "10-Nov-2023",
        "date": "Nov-2023",
        "data1": "371675.89803472"
    },
    {
        "xAxisVal": "12-Nov-2023",
        "date": "Nov-2023",
        "data1": "372105.53313804"
    },
    {
        "xAxisVal": "13-Nov-2023",
        "date": "Nov-2023",
        "data1": "372343.50060018"
    },
    {
        "xAxisVal": "14-Nov-2023",
        "date": "Nov-2023",
        "data1": "372560.70123086"
    },
    {
        "xAxisVal": "15-Nov-2023",
        "date": "Nov-2023",
        "data1": "372795.2642944"
    },
    {
        "xAxisVal": "16-Nov-2023",
        "date": "Nov-2023",
        "data1": "358013.1441664"
    },
    {
        "xAxisVal": "17-Nov-2023",
        "date": "Nov-2023",
        "data1": "358239.25497956"
    },
    {
        "xAxisVal": "19-Nov-2023",
        "date": "Nov-2023",
        "data1": "358657.49463396"
    },
    {
        "xAxisVal": "20-Nov-2023",
        "date": "Nov-2023",
        "data1": "358866.94121089",
        "data2": "358866.94121089"
    },
    {
        "xAxisVal": "Dec 01, 2024",
        "date": "Dec 01, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 02, 2024",
        "date": "Dec 02, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 03, 2024",
        "date": "Dec 03, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 04, 2024",
        "date": "Dec 04, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 05, 2024",
        "date": "Dec 05, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 06, 2024",
        "date": "Dec 06, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 07, 2024",
        "date": "Dec 07, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 08, 2024",
        "date": "Dec 08, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 09, 2024",
        "date": "Dec 09, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 10, 2024",
        "date": "Dec 10, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 11, 2024",
        "date": "Dec 11, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 12, 2024",
        "date": "Dec 12, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 13, 2024",
        "date": "Dec 13, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 14, 2024",
        "date": "Dec 14, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 15, 2024",
        "date": "Dec 15, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 16, 2024",
        "date": "Dec 16, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 17, 2024",
        "date": "Dec 17, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 18, 2024",
        "date": "Dec 18, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 19, 2024",
        "date": "Dec 19, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 20, 2024",
        "date": "Dec 20, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 21, 2024",
        "date": "Dec 21, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 22, 2024",
        "date": "Dec 22, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 23, 2024",
        "date": "Dec 23, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 24, 2024",
        "date": "Dec 24, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 25, 2024",
        "date": "Dec 25, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 26, 2024",
        "date": "Dec 26, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 27, 2024",
        "date": "Dec 27, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 28, 2024",
        "date": "Dec 28, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 29, 2024",
        "date": "Dec 29, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 30, 2024",
        "date": "Dec 30, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Dec 31, 2024",
        "date": "Dec 31, 2024",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 01, 2025",
        "date": "Jan 01, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 02, 2025",
        "date": "Jan 02, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 03, 2025",
        "date": "Jan 03, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 04, 2025",
        "date": "Jan 04, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 05, 2025",
        "date": "Jan 05, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 06, 2025",
        "date": "Jan 06, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 07, 2025",
        "date": "Jan 07, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 08, 2025",
        "date": "Jan 08, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 09, 2025",
        "date": "Jan 09, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 10, 2025",
        "date": "Jan 10, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 11, 2025",
        "date": "Jan 11, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 12, 2025",
        "date": "Jan 12, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 13, 2025",
        "date": "Jan 13, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 14, 2025",
        "date": "Jan 14, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 15, 2025",
        "date": "Jan 15, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 16, 2025",
        "date": "Jan 16, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 17, 2025",
        "date": "Jan 17, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 18, 2025",
        "date": "Jan 18, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 19, 2025",
        "date": "Jan 19, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 20, 2025",
        "date": "Jan 20, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 21, 2025",
        "date": "Jan 21, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 22, 2025",
        "date": "Jan 22, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 23, 2025",
        "date": "Jan 23, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 24, 2025",
        "date": "Jan 24, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 25, 2025",
        "date": "Jan 25, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 26, 2025",
        "date": "Jan 26, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 27, 2025",
        "date": "Jan 27, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 28, 2025",
        "date": "Jan 28, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 29, 2025",
        "date": "Jan 29, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 30, 2025",
        "date": "Jan 30, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Jan 31, 2025",
        "date": "Jan 31, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 01, 2025",
        "date": "Feb 01, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 02, 2025",
        "date": "Feb 02, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 03, 2025",
        "date": "Feb 03, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 04, 2025",
        "date": "Feb 04, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 05, 2025",
        "date": "Feb 05, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 06, 2025",
        "date": "Feb 06, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 07, 2025",
        "date": "Feb 07, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 08, 2025",
        "date": "Feb 08, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 09, 2025",
        "date": "Feb 09, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 10, 2025",
        "date": "Feb 10, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 11, 2025",
        "date": "Feb 11, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 12, 2025",
        "date": "Feb 12, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 13, 2025",
        "date": "Feb 13, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 14, 2025",
        "date": "Feb 14, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 15, 2025",
        "date": "Feb 15, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 16, 2025",
        "date": "Feb 16, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 17, 2025",
        "date": "Feb 17, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 18, 2025",
        "date": "Feb 18, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 19, 2025",
        "date": "Feb 19, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 20, 2025",
        "date": "Feb 20, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 21, 2025",
        "date": "Feb 21, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 22, 2025",
        "date": "Feb 22, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 23, 2025",
        "date": "Feb 23, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 24, 2025",
        "date": "Feb 24, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 25, 2025",
        "date": "Feb 25, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 26, 2025",
        "date": "Feb 26, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 27, 2025",
        "date": "Feb 27, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Feb 28, 2025",
        "date": "Feb 28, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 01, 2025",
        "date": "Mar 01, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 02, 2025",
        "date": "Mar 02, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 03, 2025",
        "date": "Mar 03, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 04, 2025",
        "date": "Mar 04, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 05, 2025",
        "date": "Mar 05, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 06, 2025",
        "date": "Mar 06, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 07, 2025",
        "date": "Mar 07, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 08, 2025",
        "date": "Mar 08, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 09, 2025",
        "date": "Mar 09, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 10, 2025",
        "date": "Mar 10, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 11, 2025",
        "date": "Mar 11, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 12, 2025",
        "date": "Mar 12, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 13, 2025",
        "date": "Mar 13, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 14, 2025",
        "date": "Mar 14, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 15, 2025",
        "date": "Mar 15, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 16, 2025",
        "date": "Mar 16, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 17, 2025",
        "date": "Mar 17, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 18, 2025",
        "date": "Mar 18, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 19, 2025",
        "date": "Mar 19, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 20, 2025",
        "date": "Mar 20, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 21, 2025",
        "date": "Mar 21, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 22, 2025",
        "date": "Mar 22, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 23, 2025",
        "date": "Mar 23, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 24, 2025",
        "date": "Mar 24, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 25, 2025",
        "date": "Mar 25, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 26, 2025",
        "date": "Mar 26, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 27, 2025",
        "date": "Mar 27, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 28, 2025",
        "date": "Mar 28, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 29, 2025",
        "date": "Mar 29, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 30, 2025",
        "date": "Mar 30, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Mar 31, 2025",
        "date": "Mar 31, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 01, 2025",
        "date": "Apr 01, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 02, 2025",
        "date": "Apr 02, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 03, 2025",
        "date": "Apr 03, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 04, 2025",
        "date": "Apr 04, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 05, 2025",
        "date": "Apr 05, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 06, 2025",
        "date": "Apr 06, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 07, 2025",
        "date": "Apr 07, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 08, 2025",
        "date": "Apr 08, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 09, 2025",
        "date": "Apr 09, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 10, 2025",
        "date": "Apr 10, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 11, 2025",
        "date": "Apr 11, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 12, 2025",
        "date": "Apr 12, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 13, 2025",
        "date": "Apr 13, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 14, 2025",
        "date": "Apr 14, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 15, 2025",
        "date": "Apr 15, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 16, 2025",
        "date": "Apr 16, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 17, 2025",
        "date": "Apr 17, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 18, 2025",
        "date": "Apr 18, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 19, 2025",
        "date": "Apr 19, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 20, 2025",
        "date": "Apr 20, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 21, 2025",
        "date": "Apr 21, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 22, 2025",
        "date": "Apr 22, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 23, 2025",
        "date": "Apr 23, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 24, 2025",
        "date": "Apr 24, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 25, 2025",
        "date": "Apr 25, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 26, 2025",
        "date": "Apr 26, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 27, 2025",
        "date": "Apr 27, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 28, 2025",
        "date": "Apr 28, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 29, 2025",
        "date": "Apr 29, 2025",
        "data2": "365233.83"
    },
    {
        "xAxisVal": "Apr 30, 2025",
        "date": "Apr 30, 2025",
        "data2": "365233.83"
    }
]

const testData2 = [
    {
        "xAxisVal": 1692558000000,
        "date": "Aug-2023",
        "data1": "82891.66529664"
    },
    {
        "xAxisVal": 1692644400000,
        "date": "Aug-2023",
        "data1": "199938.43880805"
    },
    {
        "xAxisVal": 1692730800000,
        "date": "Aug-2023",
        "data1": "200051.19895434"
    },
    {
        "xAxisVal": 1692817200000,
        "date": "Aug-2023",
        "data1": "200163.95910063"
    },
    {
        "xAxisVal": 1692903600000,
        "date": "Aug-2023",
        "data1": "200276.71924692"
    },
    {
        "xAxisVal": 1693076400000,
        "date": "Aug-2023",
        "data1": "200502.43295313"
    },
    {
        "xAxisVal": 1693162800000,
        "date": "Aug-2023",
        "data1": "200615.19309942"
    },
    {
        "xAxisVal": 1693249200000,
        "date": "Aug-2023",
        "data1": "200727.95324571"
    },
    {
        "xAxisVal": 1693335600000,
        "date": "Aug-2023",
        "data1": "200840.90680563"
    },
    {
        "xAxisVal": 1693422000000,
        "date": "Aug-2023",
        "data1": "200955.40767459"
    },
    {
        "xAxisVal": 1693508400000,
        "date": "Sep-2023",
        "data1": "201068.16782088"
    },
    {
        "xAxisVal": 1693681200000,
        "date": "Sep-2023",
        "data1": "201294.07494072"
    },
    {
        "xAxisVal": 1693767600000,
        "date": "Sep-2023",
        "data1": "201407.02850064"
    },
    {
        "xAxisVal": 1693854000000,
        "date": "Sep-2023",
        "data1": "201522.88326501"
    },
    {
        "xAxisVal": 1693940400000,
        "date": "Sep-2023",
        "data1": "201650.92308807"
    },
    {
        "xAxisVal": 1694026800000,
        "date": "Sep-2023",
        "data1": "201765.81078429"
    },
    {
        "xAxisVal": 1694113200000,
        "date": "Sep-2023",
        "data1": "201879.73141236"
    },
    {
        "xAxisVal": 1694286000000,
        "date": "Sep-2023",
        "data1": "202107.76608213"
    },
    {
        "xAxisVal": 1694372400000,
        "date": "Sep-2023",
        "data1": "202219.55916027"
    },
    {
        "xAxisVal": 1694458800000,
        "date": "Sep-2023",
        "data1": "202331.35223841"
    },
    {
        "xAxisVal": 1694545200000,
        "date": "Sep-2023",
        "data1": "202442.95190292"
    },
    {
        "xAxisVal": 1694631600000,
        "date": "Sep-2023",
        "data1": "202442.95190292"
    },
    {
        "xAxisVal": 1694718000000,
        "date": "Sep-2023",
        "data1": "202553.77791291"
    },
    {
        "xAxisVal": 1694804400000,
        "date": "Sep-2023",
        "data1": "202664.6039229"
    },
    {
        "xAxisVal": 1694890800000,
        "date": "Sep-2023",
        "data1": "202891.86493815"
    },
    {
        "xAxisVal": 1694977200000,
        "date": "Sep-2023",
        "data1": "202891.86493815"
    },
    {
        "xAxisVal": 1695063600000,
        "date": "Sep-2023",
        "data1": "203005.20532533"
    },
    {
        "xAxisVal": 1695150000000,
        "date": "Sep-2023",
        "data1": "203118.93253977"
    },
    {
        "xAxisVal": 1695236400000,
        "date": "Sep-2023",
        "data1": "203232.46634058"
    },
    {
        "xAxisVal": 1695322800000,
        "date": "Sep-2023",
        "data1": "203346.19355502"
    },
    {
        "xAxisVal": 1695409200000,
        "date": "Sep-2023",
        "data1": "203459.5339422"
    },
    {
        "xAxisVal": 1695495600000,
        "date": "Sep-2023",
        "data1": "203685.8278893"
    },
    {
        "xAxisVal": 1695582000000,
        "date": "Sep-2023",
        "data1": "203798.20120833"
    },
    {
        "xAxisVal": 1695668400000,
        "date": "Sep-2023",
        "data1": "203910.1877001"
    },
    {
        "xAxisVal": 1695754800000,
        "date": "Sep-2023",
        "data1": "204023.72150091"
    },
    {
        "xAxisVal": 1695841200000,
        "date": "Sep-2023",
        "data1": "204173.81047779"
    },
    {
        "xAxisVal": 1695927600000,
        "date": "Sep-2023",
        "data1": "204173.81047779"
    },
    {
        "xAxisVal": 1696100400000,
        "date": "Oct-2023",
        "data1": "204511.31726214"
    },
    {
        "xAxisVal": 1696186800000,
        "date": "Oct-2023",
        "data1": "204623.8839948"
    },
    {
        "xAxisVal": 1696273200000,
        "date": "Oct-2023",
        "data1": "204736.64414109"
    },
    {
        "xAxisVal": 1696359600000,
        "date": "Oct-2023",
        "data1": "204854.62645539"
    },
    {
        "xAxisVal": 1696446000000,
        "date": "Oct-2023",
        "data1": "205050.55446258"
    },
    {
        "xAxisVal": 1696532400000,
        "date": "Oct-2023",
        "data1": "205167.3762951"
    },
    {
        "xAxisVal": 1696705200000,
        "date": "Oct-2023",
        "data1": "205400.82654651"
    },
    {
        "xAxisVal": 1696791600000,
        "date": "Oct-2023",
        "data1": "205541.43825552"
    },
    {
        "xAxisVal": 1696878000000,
        "date": "Oct-2023",
        "data1": "205655.93912448"
    },
    {
        "xAxisVal": 1696964400000,
        "date": "Oct-2023",
        "data1": "205772.18071611"
    },
    {
        "xAxisVal": 1697050800000,
        "date": "Oct-2023",
        "data1": "205888.03548048"
    },
    {
        "xAxisVal": 1697137200000,
        "date": "Oct-2023",
        "data1": "206004.08365848"
    },
    {
        "xAxisVal": 1697310000000,
        "date": "Oct-2023",
        "data1": "206235.98660085"
    },
    {
        "xAxisVal": 1697396400000,
        "date": "Oct-2023",
        "data1": "206352.03477885"
    },
    {
        "xAxisVal": 1697482800000,
        "date": "Oct-2023",
        "data1": "206467.69612959"
    },
    {
        "xAxisVal": 1697569200000,
        "date": "Oct-2023",
        "data1": "207416.19657111"
    },
    {
        "xAxisVal": 1697655600000,
        "date": "Oct-2023",
        "data1": "207544.23639417"
    },
    {
        "xAxisVal": 1697742000000,
        "date": "Oct-2023",
        "data1": "207671.69597634"
    },
    {
        "xAxisVal": 1697914800000,
        "date": "Oct-2023",
        "data1": "207913.8498411"
    },
    {
        "xAxisVal": 1698001200000,
        "date": "Oct-2023",
        "data1": "208041.5028369"
    },
    {
        "xAxisVal": 1698087600000,
        "date": "Oct-2023",
        "data1": "208162.57976928"
    },
    {
        "xAxisVal": 1698174000000,
        "date": "Oct-2023",
        "data1": "214773.12149566"
    },
    {
        "xAxisVal": 1698260400000,
        "date": "Oct-2023",
        "data1": "214905.09340674"
    },
    {
        "xAxisVal": 1698346800000,
        "date": "Oct-2023",
        "data1": "215032.08148432"
    },
    {
        "xAxisVal": 1698519600000,
        "date": "Oct-2023",
        "data1": "215285.6589328"
    },
    {
        "xAxisVal": 1698606000000,
        "date": "Oct-2023",
        "data1": "215422.2159707"
    },
    {
        "xAxisVal": 1698692400000,
        "date": "Oct-2023",
        "data1": "215789.82352966"
    },
    {
        "xAxisVal": 1698778800000,
        "date": "Nov-2023",
        "data1": "215927.77604094"
    },
    {
        "xAxisVal": 1698865200000,
        "date": "Nov-2023",
        "data1": "216523.84252754"
    },
    {
        "xAxisVal": 1698951600000,
        "date": "Nov-2023",
        "data1": "216653.2228452"
    },
    {
        "xAxisVal": 1699124400000,
        "date": "Nov-2023",
        "data1": "371418.59885325"
    },
    {
        "xAxisVal": 1699210800000,
        "date": "Nov-2023",
        "data1": "370691.00551974"
    },
    {
        "xAxisVal": 1699297200000,
        "date": "Nov-2023",
        "data1": "370961.9956483"
    },
    {
        "xAxisVal": 1699383600000,
        "date": "Nov-2023",
        "data1": "371163.19560556"
    },
    {
        "xAxisVal": 1699470000000,
        "date": "Nov-2023",
        "data1": "371402.52482714"
    },
    {
        "xAxisVal": 1699556400000,
        "date": "Nov-2023",
        "data1": "371675.89803472"
    },
    {
        "xAxisVal": 1699729200000,
        "date": "Nov-2023",
        "data1": "372105.53313804"
    },
    {
        "xAxisVal": 1699815600000,
        "date": "Nov-2023",
        "data1": "372343.50060018"
    },
    {
        "xAxisVal": 1699902000000,
        "date": "Nov-2023",
        "data1": "372560.70123086"
    },
    {
        "xAxisVal": 1699988400000,
        "date": "Nov-2023",
        "data1": "372795.2642944"
    },
    {
        "xAxisVal": 1700074800000,
        "date": "Nov-2023",
        "data1": "358013.1441664"
    },
    {
        "xAxisVal": 1700161200000,
        "date": "Nov-2023",
        "data1": "358239.25497956"
    },
    {
        "xAxisVal": 1700334000000,
        "date": "Nov-2023",
        "data1": "358657.49463396"
    },
    {
        "xAxisVal": 1700420400000,
        "date": "Nov-2023",
        "data1": "358866.94121089",
        "data2": "358866.94121089"
    },
    {
        "xAxisVal": 1701370800000,
        "date": "Dec-2023",
        "data2": "365233.83"
    },
    {
        "xAxisVal": 1704049200000,
        "date": "Jan-2024",
        "data2": "371713.69"
    },
    {
        "xAxisVal": 1706727600000,
        "date": "Feb-2024",
        "data2": "378308.51"
    },
    {
        "xAxisVal": 1709233200000,
        "date": "Mar-2024",
        "data2": "385020.33"
    },
    {
        "xAxisVal": 1711911600000,
        "date": "Apr-2024",
        "data2": "391851.24"
    },
    {
        "xAxisVal": 1714503600000,
        "date": "May-2024",
        "data2": "398803.33"
    },
    {
        "xAxisVal": 1717182000000,
        "date": "Jun-2024",
        "data2": "405878.77"
    },
    {
        "xAxisVal": 1719774000000,
        "date": "Jul-2024",
        "data2": "413079.73"
    }
]

const testData3 = {
    "id": "643cf7ad353d639f1c1eed9d",
    "durationMonths": 12,
    "periodicContribution": 500,
    "consumedMonths": 10,
    "returnPercentage": 0,
    "contributionsGraph": [
        {
            "date": "19/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1000.9614936
        },
        {
            "date": "20/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1001.4367896
        },
        {
            "date": "21/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1001.9110954
        },
        {
            "date": "22/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1002.3863914
        },
        {
            "date": "23/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1002.8606972
        },
        {
            "date": "24/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1003.3359932
        },
        {
            "date": "25/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1003.8192108
        },
        {
            "date": "26/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "27/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "28/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "29/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "30/04/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1006.2491616
        },
        {
            "date": "01/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1006.7175262
        },
        {
            "date": "02/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1007.2195576
        },
        {
            "date": "03/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1007.2195576
        },
        {
            "date": "04/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1007.7087164
        },
        {
            "date": "05/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1007.7087164
        },
        {
            "date": "06/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1007.7087164
        },
        {
            "date": "07/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1009.177183
        },
        {
            "date": "08/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1009.6297044
        },
        {
            "date": "09/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1010.1168828
        },
        {
            "date": "10/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1010.6040612
        },
        {
            "date": "11/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1011.127877
        },
        {
            "date": "12/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1011.7546736
        },
        {
            "date": "13/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1011.7546736
        },
        {
            "date": "14/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1012.8240896
        },
        {
            "date": "15/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1013.3587976
        },
        {
            "date": "16/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1013.9063782
        },
        {
            "date": "17/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1014.4559392
        },
        {
            "date": "18/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1015.0064904
        },
        {
            "date": "19/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1015.5580318
        },
        {
            "date": "20/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1015.5580318
        },
        {
            "date": "21/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1016.6601244
        },
        {
            "date": "22/05/2023",
            "contributionAmount": 1000,
            "portfolioValue": 1017.2106756
        },
        {
            "date": "23/05/2023",
            "contributionAmount": 2000,
            "portfolioValue": 2017.7634452
        },
        {
            "date": "24/05/2023",
            "contributionAmount": 2000,
            "portfolioValue": 2018.85690304
        },
        {
            "date": "25/05/2023",
            "contributionAmount": 2000,
            "portfolioValue": 2019.95036088
        },
        {
            "date": "26/05/2023",
            "contributionAmount": 2000,
            "portfolioValue": 2021.0418556
        },
        {
            "date": "27/05/2023",
            "contributionAmount": 2000,
            "portfolioValue": 2021.0418556
        },
        {
            "date": "28/05/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1623.22335
        },
        {
            "date": "29/05/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1624.09905
        },
        {
            "date": "30/05/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1624.976325
        },
        {
            "date": "31/05/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1625.7717
        },
        {
            "date": "01/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1626.645825
        },
        {
            "date": "02/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1627.54515
        },
        {
            "date": "03/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1627.54515
        },
        {
            "date": "04/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1629.345375
        },
        {
            "date": "05/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1630.32345
        },
        {
            "date": "06/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1631.2275
        },
        {
            "date": "07/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1632.15045
        },
        {
            "date": "08/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1633.0734
        },
        {
            "date": "09/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1633.9995
        },
        {
            "date": "10/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1633.9995
        },
        {
            "date": "11/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1635.846975
        },
        {
            "date": "12/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1636.769925
        },
        {
            "date": "13/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1632.69126404
        },
        {
            "date": "14/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1633.61041656
        },
        {
            "date": "15/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1634.53117599
        },
        {
            "date": "16/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1635.45193542
        },
        {
            "date": "17/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1635.45193542
        },
        {
            "date": "18/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1637.29184737
        },
        {
            "date": "19/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1638.2126068
        },
        {
            "date": "20/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1639.13175932
        },
        {
            "date": "21/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1640.06537403
        },
        {
            "date": "22/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1640.99095419
        },
        {
            "date": "23/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1641.91653435
        },
        {
            "date": "25/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1643.76930158
        },
        {
            "date": "26/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1637.66911206
        },
        {
            "date": "27/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1638.56439243
        },
        {
            "date": "28/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1638.56439243
        },
        {
            "date": "29/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1638.56439243
        },
        {
            "date": "30/06/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1638.56439243
        },
        {
            "date": "02/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1643.03915757
        },
        {
            "date": "03/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1643.93280123
        },
        {
            "date": "04/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1644.82480818
        },
        {
            "date": "05/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1645.86575574
        },
        {
            "date": "06/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1646.79868044
        },
        {
            "date": "07/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1646.79868044
        },
        {
            "date": "09/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1649.90842944
        },
        {
            "date": "10/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1650.83808072
        },
        {
            "date": "11/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1651.767732
        },
        {
            "date": "12/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1652.69738328
        },
        {
            "date": "13/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1653.62703456
        },
        {
            "date": "14/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1654.55668584
        },
        {
            "date": "16/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1656.41762511
        },
        {
            "date": "17/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1657.50767397
        },
        {
            "date": "18/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1658.44714551
        },
        {
            "date": "19/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1659.38661705
        },
        {
            "date": "20/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1660.32608859
        },
        {
            "date": "21/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1661.26556013
        },
        {
            "date": "23/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1663.1428665
        },
        {
            "date": "24/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1664.08233804
        },
        {
            "date": "25/07/2023",
            "contributionAmount": 1600,
            "portfolioValue": 1665.02017287
        },
        {
            "date": "26/07/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81665.9637825
        },
        {
            "date": "27/07/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81723.00860652
        },
        {
            "date": "28/07/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81723.00860652
        },
        {
            "date": "30/07/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81862.61197332
        },
        {
            "date": "31/07/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81909.22666074
        },
        {
            "date": "01/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 81955.76111634
        },
        {
            "date": "02/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82002.29557194
        },
        {
            "date": "03/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82049.070723
        },
        {
            "date": "04/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82095.68541042
        },
        {
            "date": "06/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82188.99501708
        },
        {
            "date": "07/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82239.05967276
        },
        {
            "date": "08/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82282.86624648
        },
        {
            "date": "09/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82329.64139754
        },
        {
            "date": "10/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82376.4165486
        },
        {
            "date": "11/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82423.19169966
        },
        {
            "date": "13/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82423.19169966
        },
        {
            "date": "14/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82423.19169966
        },
        {
            "date": "15/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82612.05740394
        },
        {
            "date": "16/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82658.75232318
        },
        {
            "date": "17/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82705.28677878
        },
        {
            "date": "18/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82751.98169802
        },
        {
            "date": "20/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82844.89014558
        },
        {
            "date": "21/08/2023",
            "contributionAmount": 81600,
            "portfolioValue": 82891.66529664
        },
        {
            "date": "22/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 199938.43880805
        },
        {
            "date": "23/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200051.19895434
        },
        {
            "date": "24/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200163.95910063
        },
        {
            "date": "25/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200276.71924692
        },
        {
            "date": "27/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200502.43295313
        },
        {
            "date": "28/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200615.19309942
        },
        {
            "date": "29/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200727.95324571
        },
        {
            "date": "30/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200840.90680563
        },
        {
            "date": "31/08/2023",
            "contributionAmount": 198600,
            "portfolioValue": 200955.40767459
        },
        {
            "date": "01/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201068.16782088
        },
        {
            "date": "03/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201294.07494072
        },
        {
            "date": "04/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201407.02850064
        },
        {
            "date": "05/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201522.88326501
        },
        {
            "date": "06/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201650.92308807
        },
        {
            "date": "07/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201765.81078429
        },
        {
            "date": "08/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 201879.73141236
        },
        {
            "date": "10/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202107.76608213
        },
        {
            "date": "11/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202219.55916027
        },
        {
            "date": "12/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202331.35223841
        },
        {
            "date": "13/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202442.95190292
        },
        {
            "date": "14/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202442.95190292
        },
        {
            "date": "15/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202553.77791291
        },
        {
            "date": "16/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202664.6039229
        },
        {
            "date": "17/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202891.86493815
        },
        {
            "date": "18/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 202891.86493815
        },
        {
            "date": "19/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203005.20532533
        },
        {
            "date": "20/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203118.93253977
        },
        {
            "date": "21/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203232.46634058
        },
        {
            "date": "22/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203346.19355502
        },
        {
            "date": "23/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203459.5339422
        },
        {
            "date": "24/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203685.8278893
        },
        {
            "date": "25/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203798.20120833
        },
        {
            "date": "26/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 203910.1877001
        },
        {
            "date": "27/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204023.72150091
        },
        {
            "date": "28/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204173.81047779
        },
        {
            "date": "29/09/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204173.81047779
        },
        {
            "date": "01/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204511.31726214
        },
        {
            "date": "02/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204623.8839948
        },
        {
            "date": "03/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204736.64414109
        },
        {
            "date": "04/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 204854.62645539
        },
        {
            "date": "05/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205050.55446258
        },
        {
            "date": "06/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205167.3762951
        },
        {
            "date": "08/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205400.82654651
        },
        {
            "date": "09/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205541.43825552
        },
        {
            "date": "10/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205655.93912448
        },
        {
            "date": "11/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205772.18071611
        },
        {
            "date": "12/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 205888.03548048
        },
        {
            "date": "13/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 206004.08365848
        },
        {
            "date": "15/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 206235.98660085
        },
        {
            "date": "16/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 206352.03477885
        },
        {
            "date": "17/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 206467.69612959
        },
        {
            "date": "18/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 207416.19657111
        },
        {
            "date": "19/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 207544.23639417
        },
        {
            "date": "20/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 207671.69597634
        },
        {
            "date": "22/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 207913.8498411
        },
        {
            "date": "23/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 208041.5028369
        },
        {
            "date": "24/10/2023",
            "contributionAmount": 198600,
            "portfolioValue": 208162.57976928
        },
        {
            "date": "25/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 214773.12149566
        },
        {
            "date": "26/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 214905.09340674
        },
        {
            "date": "27/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 215032.08148432
        },
        {
            "date": "29/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 215285.6589328
        },
        {
            "date": "30/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 215422.2159707
        },
        {
            "date": "31/10/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 215789.82352966
        },
        {
            "date": "01/11/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 215927.77604094
        },
        {
            "date": "02/11/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 216523.84252754
        },
        {
            "date": "03/11/2023",
            "contributionAmount": 204999.1406234,
            "portfolioValue": 216653.2228452
        },
        {
            "date": "05/11/2023",
            "contributionAmount": 359509.1406234,
            "portfolioValue": 371418.59885325
        },
        {
            "date": "06/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 370691.00551974
        },
        {
            "date": "07/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 370961.9956483
        },
        {
            "date": "08/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 371163.19560556
        },
        {
            "date": "09/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 371402.52482714
        },
        {
            "date": "10/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 371675.89803472
        },
        {
            "date": "12/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 372105.53313804
        },
        {
            "date": "13/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 372343.50060018
        },
        {
            "date": "14/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 372560.70123086
        },
        {
            "date": "15/11/2023",
            "contributionAmount": 358509.1406234,
            "portfolioValue": 372795.2642944
        },
        {
            "date": "16/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 358013.1441664
        },
        {
            "date": "17/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 358239.25497956
        },
        {
            "date": "19/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 358657.49463396
        },
        {
            "date": "20/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 358866.94121089
        },
        {
            "date": "21/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 359092.07177486
        },
        {
            "date": "22/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 359303.47885017
        },
        {
            "date": "23/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 359480.90395356
        },
        {
            "date": "24/11/2023",
            "contributionAmount": 343509.1406234,
            "portfolioValue": 359707.66826618
        },
        {
            "date": "26/11/2023",
            "contributionAmount": 194009.1406234,
            "portfolioValue": 210499.129085
        },
        {
            "date": "27/11/2023",
            "contributionAmount": 194009.1406234,
            "portfolioValue": 210622.55223
        },
        {
            "date": "28/11/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365176.69394203
        },
        {
            "date": "29/11/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365288.59160349
        },
        {
            "date": "30/11/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365252.17520479
        },
        {
            "date": "01/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365301.17181395
        },
        {
            "date": "03/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365733.53378397
        },
        {
            "date": "04/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 365933.49291865
        },
        {
            "date": "05/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 366150.99813634
        },
        {
            "date": "06/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 366350.29515468
        },
        {
            "date": "07/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 366550.25428936
        },
        {
            "date": "08/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 366749.88236587
        },
        {
            "date": "12/12/2023",
            "contributionAmount": 348438.1406234,
            "portfolioValue": 367557.3332425
        },
        {
            "date": "13/12/2023",
            "contributionAmount": 347438.1406234,
            "portfolioValue": 359114.86391616
        },
        {
            "date": "14/12/2023",
            "contributionAmount": 347438.1406234,
            "portfolioValue": 359311.26693132
        },
        {
            "date": "15/12/2023",
            "contributionAmount": 346938.1406234,
            "portfolioValue": 359031.33536078
        },
        {
            "date": "17/12/2023",
            "contributionAmount": 346938.1406234,
            "portfolioValue": 359457.89069066
        },
        {
            "date": "18/12/2023",
            "contributionAmount": 346938.1406234,
            "portfolioValue": 359661.52263206
        },
        {
            "date": "19/12/2023",
            "contributionAmount": 346938.1406234,
            "portfolioValue": 359812.63896752
        },
        {
            "date": "20/12/2023",
            "contributionAmount": 346938.1406234,
            "portfolioValue": 359987.3337383
        },
        {
            "date": "21/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210111.11317518
        },
        {
            "date": "22/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210222.60183588
        },
        {
            "date": "24/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210462.66713892
        },
        {
            "date": "25/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210582.69979044
        },
        {
            "date": "26/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210693.98006112
        },
        {
            "date": "27/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210790.25625036
        },
        {
            "date": "28/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210707.73380244
        },
        {
            "date": "29/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210762.33198768
        },
        {
            "date": "31/12/2023",
            "contributionAmount": 196938.1406234,
            "portfolioValue": 210844.8544356
        },
        {
            "date": "01/01/2024",
            "contributionAmount": 46938.1406234,
            "portfolioValue": 60880.5784323
        },
        {
            "date": "02/01/2024",
            "contributionAmount": 46938.1406234,
            "portfolioValue": 60912.6912966
        },
        {
            "date": "03/01/2024",
            "contributionAmount": 201621.1406234,
            "portfolioValue": 215595.695211
        },
        {
            "date": "04/01/2024",
            "contributionAmount": 201621.1406234,
            "portfolioValue": 215736.38790425
        },
        {
            "date": "05/01/2024",
            "contributionAmount": 101621.1406234,
            "portfolioValue": 115803.52507586
        },
        {
            "date": "07/01/2024",
            "contributionAmount": 101621.1406234,
            "portfolioValue": 115937.35221642
        },
        {
            "date": "08/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 15946.30246926
        },
        {
            "date": "09/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 15946.30246926
        },
        {
            "date": "10/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 15963.86712132
        },
        {
            "date": "11/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 15973.20668162
        },
        {
            "date": "12/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 15982.56193866
        },
        {
            "date": "14/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16001.27245274
        },
        {
            "date": "15/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16011.64799788
        },
        {
            "date": "16/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16020.92477122
        },
        {
            "date": "17/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16029.85621628
        },
        {
            "date": "18/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16039.19577658
        },
        {
            "date": "19/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16048.28418904
        },
        {
            "date": "21/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16066.72785854
        },
        {
            "date": "22/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16075.64360686
        },
        {
            "date": "23/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16085.97206178
        },
        {
            "date": "24/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16094.5738753
        },
        {
            "date": "25/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16103.48962362
        },
        {
            "date": "26/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16112.84488066
        },
        {
            "date": "28/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16131.02170558
        },
        {
            "date": "29/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16140.06302782
        },
        {
            "date": "30/01/2024",
            "contributionAmount": 1621.1406234,
            "portfolioValue": 16129.4206381
        },
        {
            "date": "31/01/2024",
            "contributionAmount": 2121.1406234,
            "portfolioValue": 16648.44099675
        },
        {
            "date": "01/02/2024",
            "contributionAmount": 2121.1406234,
            "portfolioValue": 16667.844114
        },
        {
            "date": "02/02/2024",
            "contributionAmount": 157627.1406234,
            "portfolioValue": 172173.84718872
        },
        {
            "date": "04/02/2024",
            "contributionAmount": 157627.1406234,
            "portfolioValue": 172470.05997156
        },
        {
            "date": "05/02/2024",
            "contributionAmount": 157627.1406234,
            "portfolioValue": 172569.85626465
        },
        {
            "date": "06/02/2024",
            "contributionAmount": 2627.1406234,
            "portfolioValue": 17569.8511992
        },
        {
            "date": "07/02/2024",
            "contributionAmount": 2627.1406234,
            "portfolioValue": 17590.12125696
        },
        {
            "date": "08/02/2024",
            "contributionAmount": 2627.1406234,
            "portfolioValue": 17600.09460192
        },
        {
            "date": "09/02/2024",
            "contributionAmount": 2627.1406234,
            "portfolioValue": 17610.1019856
        },
        {
            "date": "11/02/2024",
            "contributionAmount": 2627.1406234,
            "portfolioValue": 17630.15079168
        },
        {
            "date": "12/02/2024",
            "contributionAmount": 0,
            "portfolioValue": 7630.15418928
        },
        {
            "date": "13/02/2024",
            "contributionAmount": 0,
            "portfolioValue": 7638.33760419
        },
        {
            "date": "14/02/2024",
            "contributionAmount": 0,
            "portfolioValue": 7642.52875008
        },
        {
            "date": "15/02/2024",
            "contributionAmount": 0,
            "portfolioValue": 7632.77641764
        },
        {
            "date": "16/02/2024",
            "contributionAmount": 0,
            "portfolioValue": 7650.62377527
        }
    ]
}

const testData4 = {
    "id": "643cf7ad353d639f1c1eed9d",
    "durationMonths": 12,
    "periodicContribution": 500,
    "consumedMonths": 2,
    "returnPercentage": 0,
    "contributionsGraph": [
        {
            "date": "19/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1000.9614936
        },
        {
            "date": "20/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1001.4367896
        },
        {
            "date": "21/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1001.9110954
        },
        {
            "date": "22/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1002.3863914
        },
        {
            "date": "23/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1002.8606972
        },
        {
            "date": "24/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1003.3359932
        },
        {
            "date": "25/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1003.8192108
        },
        {
            "date": "26/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "27/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "28/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "29/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1004.3024284
        },
        {
            "date": "30/01/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1006.2491616
        },
        {
            "date": "01/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1006.7175262
        },
        {
            "date": "02/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1007.2195576
        },
        {
            "date": "03/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1007.2195576
        },
        {
            "date": "04/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1007.7087164
        },
        {
            "date": "05/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1007.7087164
        },
        {
            "date": "06/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1007.7087164
        },
        {
            "date": "07/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1009.177183
        },
        {
            "date": "08/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1009.6297044
        },
        {
            "date": "09/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1010.1168828
        },
        {
            "date": "10/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1010.6040612
        },
        {
            "date": "11/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1011.127877
        },
        {
            "date": "12/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1011.7546736
        },
        {
            "date": "13/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1011.7546736
        },
        {
            "date": "14/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1012.8240896
        },
        {
            "date": "15/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1013.3587976
        },
        {
            "date": "16/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1013.9063782
        },
        {
            "date": "17/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1014.4559392
        },
        {
            "date": "18/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1015.0064904
        },
        {
            "date": "19/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1015.5580318
        },
        {
            "date": "20/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1015.5580318
        },
        {
            "date": "21/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1016.6601244
        },
        {
            "date": "22/02/2024",
            "contributionAmount": 1000,
            "portfolioValue": 1017.2106756
        },
        {
            "date": "23/02/2024",
            "contributionAmount": 2000,
            "portfolioValue": 2017.7634452
        },
        {
            "date": "24/02/2024",
            "contributionAmount": 2000,
            "portfolioValue": 2018.85690304
        },
        {
            "date": "25/02/2024",
            "contributionAmount": 2000,
            "portfolioValue": 2019.95036088
        },
        {
            "date": "26/02/2024",
            "contributionAmount": 2000,
            "portfolioValue": 2021.0418556
        },
        {
            "date": "27/02/2024",
            "contributionAmount": 2000,
            "portfolioValue": 2021.0418556
        },
        {
            "date": "28/02/2024",
            "contributionAmount": 1600,
            "portfolioValue": 1623.22335
        },
        {
            "date": "29/02/2024",
            "contributionAmount": 1600,
            "portfolioValue": 1624.09905
        },
        // // {
        // //     "date": "30/02/2024",
        // //     "contributionAmount": 1600,
        // //     "portfolioValue": 1624.976325
        // // },
        // // {
        // //     "date": "31/02/2024",
        // //     "contributionAmount": 1600,
        // //     "portfolioValue": 1625.7717
        // // },
        // {
        //     "date": "01/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1626.645825
        // },
        // {
        //     "date": "02/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1627.54515
        // },
        // {
        //     "date": "03/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1627.54515
        // },
        // {
        //     "date": "04/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1629.345375
        // },
        // {
        //     "date": "05/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1630.32345
        // },
        // {
        //     "date": "06/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1631.2275
        // },
        // {
        //     "date": "07/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1632.15045
        // },
        // {
        //     "date": "08/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1633.0734
        // },
        // {
        //     "date": "09/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1633.9995
        // },
        // {
        //     "date": "10/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1633.9995
        // },
        // {
        //     "date": "11/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1635.846975
        // },
        // {
        //     "date": "12/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1636.769925
        // },
        // {
        //     "date": "13/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1632.69126404
        // },
        // {
        //     "date": "14/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1633.61041656
        // },
        // {
        //     "date": "15/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1634.53117599
        // },
        // {
        //     "date": "16/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1635.45193542
        // },
        // {
        //     "date": "17/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1635.45193542
        // },
        // {
        //     "date": "18/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1637.29184737
        // },
        // {
        //     "date": "19/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1638.2126068
        // },
        // {
        //     "date": "20/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1639.13175932
        // },
        // {
        //     "date": "21/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1640.06537403
        // },
        // {
        //     "date": "22/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1640.99095419
        // },
        // {
        //     "date": "23/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1641.91653435
        // },
        // {
        //     "date": "25/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1643.76930158
        // },
        // {
        //     "date": "26/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1637.66911206
        // },
        // {
        //     "date": "27/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1638.56439243
        // },
        // {
        //     "date": "28/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1638.56439243
        // },
        // {
        //     "date": "29/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1638.56439243
        // },
        // {
        //     "date": "30/03/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1638.56439243
        // },
        // {
        //     "date": "02/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1643.03915757
        // },
        // {
        //     "date": "03/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1643.93280123
        // },
        // {
        //     "date": "04/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1644.82480818
        // },
        // {
        //     "date": "05/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1645.86575574
        // },
        // {
        //     "date": "06/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1646.79868044
        // },
        // {
        //     "date": "07/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1646.79868044
        // },
        // {
        //     "date": "09/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1649.90842944
        // },
        // {
        //     "date": "10/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1650.83808072
        // },
        // {
        //     "date": "11/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1651.767732
        // },
        // {
        //     "date": "12/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1652.69738328
        // },
        // {
        //     "date": "13/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1653.62703456
        // },
        // {
        //     "date": "14/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1654.55668584
        // },
        // {
        //     "date": "16/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1656.41762511
        // },
        // {
        //     "date": "17/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1657.50767397
        // },
        // {
        //     "date": "18/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1658.44714551
        // },
        // {
        //     "date": "19/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1659.38661705
        // },
        // {
        //     "date": "20/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1660.32608859
        // },
        // {
        //     "date": "21/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1661.26556013
        // },
        // {
        //     "date": "23/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1663.1428665
        // },
        // {
        //     "date": "24/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1664.08233804
        // },
        // {
        //     "date": "25/04/2024",
        //     "contributionAmount": 1600,
        //     "portfolioValue": 1665.02017287
        // },
        // {
        //     "date": "26/04/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 81665.9637825
        // },
        // {
        //     "date": "27/04/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 81723.00860652
        // },
        // {
        //     "date": "28/04/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 81723.00860652
        // },
        // {
        //     "date": "30/04/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 81862.61197332
        // },
        // {
        //     "date": "31/04/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 81909.22666074
        // },
        // {
        //     "date": "01/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 81955.76111634
        // },
        // {
        //     "date": "02/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82002.29557194
        // },
        // {
        //     "date": "03/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82049.070723
        // },
        // {
        //     "date": "04/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82095.68541042
        // },
        // {
        //     "date": "06/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82188.99501708
        // },
        // {
        //     "date": "07/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82239.05967276
        // },
        // {
        //     "date": "08/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82282.86624648
        // },
        // {
        //     "date": "09/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82329.64139754
        // },
        // {
        //     "date": "10/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82376.4165486
        // },
        // {
        //     "date": "11/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82423.19169966
        // },
        // {
        //     "date": "13/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82423.19169966
        // },
        // {
        //     "date": "14/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82423.19169966
        // },
        // {
        //     "date": "15/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82612.05740394
        // },
        // {
        //     "date": "16/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82658.75232318
        // },
        // {
        //     "date": "17/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82705.28677878
        // },
        // {
        //     "date": "18/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82751.98169802
        // },
        // {
        //     "date": "20/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82844.89014558
        // },
        // {
        //     "date": "21/05/2024",
        //     "contributionAmount": 81600,
        //     "portfolioValue": 82891.66529664
        // },
        // {
        //     "date": "22/05/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 199938.43880805
        // },
        // {
        //     "date": "23/05/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 200051.19895434
        // },
        // {
        //     "date": "24/05/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 200163.95910063
        // },
        // {
        //     "date": "25/05/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 200276.71924692
        // },
        // {
        //     "date": "27/05/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 200502.43295313
        // },
        // {
        //     "date": "28/05/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 200615.19309942
        // },
        // {
        //     "date": "29/05/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 200727.95324571
        // },
        // {
        //     "date": "30/05/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 200840.90680563
        // },
        // {
        //     "date": "31/05/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 200955.40767459
        // },
        // {
        //     "date": "01/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 201068.16782088
        // },
        // {
        //     "date": "03/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 201294.07494072
        // },
        // {
        //     "date": "04/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 201407.02850064
        // },
        // {
        //     "date": "05/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 201522.88326501
        // },
        // {
        //     "date": "06/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 201650.92308807
        // },
        // {
        //     "date": "07/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 201765.81078429
        // },
        // {
        //     "date": "08/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 201879.73141236
        // },
        // {
        //     "date": "10/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 202107.76608213
        // },
        // {
        //     "date": "11/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 202219.55916027
        // },
        // {
        //     "date": "12/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 202431.35223841
        // },
        // {
        //     "date": "13/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 202442.95190292
        // },
        // {
        //     "date": "14/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 202442.95190292
        // },
        // {
        //     "date": "15/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 202553.77791291
        // },
        // {
        //     "date": "16/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 202664.6039229
        // },
        // {
        //     "date": "17/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 202891.86493815
        // },
        // {
        //     "date": "18/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 202891.86493815
        // },
        // {
        //     "date": "19/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 203005.20532533
        // },
        // {
        //     "date": "20/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 203118.93253977
        // },
        // {
        //     "date": "21/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 203232.46634058
        // },
        // {
        //     "date": "22/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 203346.19355502
        // },
        // {
        //     "date": "23/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 203459.5339422
        // },
        // {
        //     "date": "24/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 203685.8278893
        // },
        // {
        //     "date": "25/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 203798.20120833
        // },
        // {
        //     "date": "26/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 203910.1877001
        // },
        // {
        //     "date": "27/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 204023.72150091
        // },
        // {
        //     "date": "28/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 204173.81047779
        // },
        // {
        //     "date": "29/06/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 204173.81047779
        // },
        // {
        //     "date": "01/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 204511.31726214
        // },
        // {
        //     "date": "02/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 204623.8839948
        // },
        // {
        //     "date": "03/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 204736.64414109
        // },
        // {
        //     "date": "04/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 204854.62645539
        // },
        // {
        //     "date": "05/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 205050.55446258
        // },
        // {
        //     "date": "06/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 205167.3762951
        // },
        // {
        //     "date": "08/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 205400.82654651
        // },
        // {
        //     "date": "09/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 205541.43825552
        // },
        // {
        //     "date": "10/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 205655.93912448
        // },
        // {
        //     "date": "11/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 205772.18071611
        // },
        // {
        //     "date": "12/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 205888.03548048
        // },
        // {
        //     "date": "13/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 206004.08365848
        // },
        // {
        //     "date": "15/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 206235.98660085
        // },
        // {
        //     "date": "16/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 206352.03477885
        // },
        // {
        //     "date": "17/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 206467.69612959
        // },
        // {
        //     "date": "18/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 207416.19657111
        // },
        // {
        //     "date": "19/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 207544.23639417
        // },
        // {
        //     "date": "20/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 207671.69597634
        // },
        // {
        //     "date": "22/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 207913.8498411
        // },
        // {
        //     "date": "23/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 208041.5028369
        // },
        // {
        //     "date": "24/07/2024",
        //     "contributionAmount": 198600,
        //     "portfolioValue": 208162.57976928
        // },
        // {
        //     "date": "25/07/2024",
        //     "contributionAmount": 204999.1406234,
        //     "portfolioValue": 214773.12149566
        // },
        // {
        //     "date": "26/07/2024",
        //     "contributionAmount": 204999.1406234,
        //     "portfolioValue": 214905.09340674
        // },
        // {
        //     "date": "27/07/2024",
        //     "contributionAmount": 204999.1406234,
        //     "portfolioValue": 215032.08148432
        // },
        // {
        //     "date": "29/07/2024",
        //     "contributionAmount": 204999.1406234,
        //     "portfolioValue": 215285.6589328
        // },
        // {
        //     "date": "30/07/2024",
        //     "contributionAmount": 204999.1406234,
        //     "portfolioValue": 215422.2159707
        // },
        // {
        //     "date": "31/07/2024",
        //     "contributionAmount": 204999.1406234,
        //     "portfolioValue": 215789.82352966
        // },
        // {
        //     "date": "01/08/2024",
        //     "contributionAmount": 204999.1406234,
        //     "portfolioValue": 215927.77604094
        // },
        // {
        //     "date": "02/08/2024",
        //     "contributionAmount": 204999.1406234,
        //     "portfolioValue": 216523.84252754
        // },
        // {
        //     "date": "03/08/2024",
        //     "contributionAmount": 204999.1406234,
        //     "portfolioValue": 216653.2228452
        // },
        // {
        //     "date": "05/08/2024",
        //     "contributionAmount": 359509.1406234,
        //     "portfolioValue": 371418.59885325
        // },
        // {
        //     "date": "06/08/2024",
        //     "contributionAmount": 358509.1406234,
        //     "portfolioValue": 370691.00551974
        // },
        // {
        //     "date": "07/08/2024",
        //     "contributionAmount": 358509.1406234,
        //     "portfolioValue": 370961.9956483
        // },
        // {
        //     "date": "08/08/2024",
        //     "contributionAmount": 358509.1406234,
        //     "portfolioValue": 371163.19560556
        // },
        // {
        //     "date": "09/08/2024",
        //     "contributionAmount": 358509.1406234,
        //     "portfolioValue": 371402.52482714
        // },
        // {
        //     "date": "10/08/2024",
        //     "contributionAmount": 358509.1406234,
        //     "portfolioValue": 371675.89803472
        // },
        // {
        //     "date": "12/08/2024",
        //     "contributionAmount": 358509.1406234,
        //     "portfolioValue": 372105.53313804
        // },
        // {
        //     "date": "13/08/2024",
        //     "contributionAmount": 358509.1406234,
        //     "portfolioValue": 372343.50060018
        // },
        // {
        //     "date": "14/08/2024",
        //     "contributionAmount": 358509.1406234,
        //     "portfolioValue": 372560.70123086
        // },
        // {
        //     "date": "15/08/2024",
        //     "contributionAmount": 358509.1406234,
        //     "portfolioValue": 372795.2642944
        // },
        // {
        //     "date": "16/08/2024",
        //     "contributionAmount": 343509.1406234,
        //     "portfolioValue": 358013.1441664
        // },
        // {
        //     "date": "17/08/2024",
        //     "contributionAmount": 343509.1406234,
        //     "portfolioValue": 358239.25497956
        // },
        // {
        //     "date": "19/08/2024",
        //     "contributionAmount": 343509.1406234,
        //     "portfolioValue": 358657.49463396
        // },
        // {
        //     "date": "20/08/2024",
        //     "contributionAmount": 343509.1406234,
        //     "portfolioValue": 358866.94121089
        // },
        // {
        //     "date": "21/08/2024",
        //     "contributionAmount": 343509.1406234,
        //     "portfolioValue": 359092.07177486
        // },
        // {
        //     "date": "22/08/2024",
        //     "contributionAmount": 343509.1406234,
        //     "portfolioValue": 359303.47885017
        // },
        // {
        //     "date": "23/08/2024",
        //     "contributionAmount": 343509.1406234,
        //     "portfolioValue": 359480.90395356
        // },
        // {
        //     "date": "24/08/2024",
        //     "contributionAmount": 343509.1406234,
        //     "portfolioValue": 359707.66826618
        // },
        // {
        //     "date": "26/08/2024",
        //     "contributionAmount": 194009.1406234,
        //     "portfolioValue": 210499.129085
        // },
        // {
        //     "date": "27/08/2024",
        //     "contributionAmount": 194009.1406234,
        //     "portfolioValue": 210622.55223
        // },
        // {
        //     "date": "28/08/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 365176.69394203
        // },
        // {
        //     "date": "29/08/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 365288.59160349
        // },
        // {
        //     "date": "30/08/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 365252.17520479
        // },
        // {
        //     "date": "01/09/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 365301.17181395
        // },
        // {
        //     "date": "03/09/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 365733.53378397
        // },
        // {
        //     "date": "04/09/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 365933.49291865
        // },
        // {
        //     "date": "05/09/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 366150.99813634
        // },
        // {
        //     "date": "06/09/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 366350.29515468
        // },
        // {
        //     "date": "07/09/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 366550.25428936
        // },
        // {
        //     "date": "08/09/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 366749.88236587
        // },
        // {
        //     "date": "12/09/2024",
        //     "contributionAmount": 348438.1406234,
        //     "portfolioValue": 367557.3332425
        // },
        // {
        //     "date": "13/09/2024",
        //     "contributionAmount": 347438.1406234,
        //     "portfolioValue": 359114.86391616
        // },
        // {
        //     "date": "14/09/2024",
        //     "contributionAmount": 347438.1406234,
        //     "portfolioValue": 359311.26693132
        // },
        // {
        //     "date": "15/09/2024",
        //     "contributionAmount": 346938.1406234,
        //     "portfolioValue": 359031.33536078
        // },
        // {
        //     "date": "17/09/2024",
        //     "contributionAmount": 346938.1406234,
        //     "portfolioValue": 359457.89069066
        // },
        // {
        //     "date": "18/09/2024",
        //     "contributionAmount": 346938.1406234,
        //     "portfolioValue": 359661.52263206
        // },
        // {
        //     "date": "19/09/2024",
        //     "contributionAmount": 346938.1406234,
        //     "portfolioValue": 359812.63896752
        // },
        // {
        //     "date": "20/09/2024",
        //     "contributionAmount": 346938.1406234,
        //     "portfolioValue": 359987.3337383
        // },
        // {
        //     "date": "21/09/2024",
        //     "contributionAmount": 196938.1406234,
        //     "portfolioValue": 210111.11317518
        // },
        // {
        //     "date": "22/09/2024",
        //     "contributionAmount": 196938.1406234,
        //     "portfolioValue": 210222.60183588
        // },
        // {
        //     "date": "24/09/2024",
        //     "contributionAmount": 196938.1406234,
        //     "portfolioValue": 210462.66713892
        // },
        // {
        //     "date": "25/09/2024",
        //     "contributionAmount": 196938.1406234,
        //     "portfolioValue": 210582.69979044
        // },
        // {
        //     "date": "26/09/2024",
        //     "contributionAmount": 196938.1406234,
        //     "portfolioValue": 210693.98006112
        // },
        // {
        //     "date": "27/09/2024",
        //     "contributionAmount": 196938.1406234,
        //     "portfolioValue": 210790.25625036
        // },
        // {
        //     "date": "28/09/2024",
        //     "contributionAmount": 196938.1406234,
        //     "portfolioValue": 210707.73380244
        // },
        // {
        //     "date": "29/09/2024",
        //     "contributionAmount": 196938.1406234,
        //     "portfolioValue": 210762.33198768
        // },
        // {
        //     "date": "31/09/2024",
        //     "contributionAmount": 196938.1406234,
        //     "portfolioValue": 210844.8544356
        // },
        // {
        //     "date": "01/10/2024",
        //     "contributionAmount": 46938.1406234,
        //     "portfolioValue": 60880.5784323
        // },
        // {
        //     "date": "02/10/2024",
        //     "contributionAmount": 46938.1406234,
        //     "portfolioValue": 60912.6912966
        // },
        // {
        //     "date": "03/10/2024",
        //     "contributionAmount": 201621.1406234,
        //     "portfolioValue": 215595.695211
        // },
        // {
        //     "date": "04/10/2024",
        //     "contributionAmount": 201621.1406234,
        //     "portfolioValue": 215736.38790425
        // },
        // {
        //     "date": "05/10/2024",
        //     "contributionAmount": 101621.1406234,
        //     "portfolioValue": 115803.52507586
        // },
        // {
        //     "date": "07/10/2024",
        //     "contributionAmount": 101621.1406234,
        //     "portfolioValue": 115937.35221642
        // },
        // {
        //     "date": "08/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 15946.30246926
        // },
        // {
        //     "date": "09/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 15946.30246926
        // },
        // {
        //     "date": "10/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 15963.86712132
        // },
        // {
        //     "date": "11/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 15973.20668162
        // },
        // {
        //     "date": "12/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 15982.56193866
        // },
        // {
        //     "date": "14/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16001.27245274
        // },
        // {
        //     "date": "15/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16011.64799788
        // },
        // {
        //     "date": "16/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16020.92477122
        // },
        // {
        //     "date": "17/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16029.85621628
        // },
        // {
        //     "date": "18/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16039.19577658
        // },
        // {
        //     "date": "19/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16048.28418904
        // },
        // {
        //     "date": "21/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16066.72785854
        // },
        // {
        //     "date": "22/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16075.64360686
        // },
        // {
        //     "date": "23/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16085.97206178
        // },
        // {
        //     "date": "24/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16094.5738753
        // },
        // {
        //     "date": "25/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16103.48962362
        // },
        // {
        //     "date": "26/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16112.84488066
        // },
        // {
        //     "date": "28/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16131.02170558
        // },
        // {
        //     "date": "29/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16140.06302782
        // },
        // {
        //     "date": "30/10/2024",
        //     "contributionAmount": 1621.1406234,
        //     "portfolioValue": 16129.4206381
        // },
        // {
        //     "date": "31/10/2024",
        //     "contributionAmount": 2121.1406234,
        //     "portfolioValue": 16648.44099675
        // },
        // {
        //     "date": "01/11/2024",
        //     "contributionAmount": 2121.1406234,
        //     "portfolioValue": 16667.844114
        // },
        // {
        //     "date": "02/11/2024",
        //     "contributionAmount": 157627.1406234,
        //     "portfolioValue": 172173.84718872
        // },
        // {
        //     "date": "04/11/2024",
        //     "contributionAmount": 157627.1406234,
        //     "portfolioValue": 172470.05997156
        // },
        // {
        //     "date": "05/11/2024",
        //     "contributionAmount": 157627.1406234,
        //     "portfolioValue": 172569.85626465
        // },
        // {
        //     "date": "06/11/2024",
        //     "contributionAmount": 2627.1406234,
        //     "portfolioValue": 17569.8511992
        // },
        // {
        //     "date": "07/11/2024",
        //     "contributionAmount": 2627.1406234,
        //     "portfolioValue": 17590.12125696
        // },
        // {
        //     "date": "08/11/2024",
        //     "contributionAmount": 2627.1406234,
        //     "portfolioValue": 17600.09460192
        // },
        // {
        //     "date": "09/11/2024",
        //     "contributionAmount": 2627.1406234,
        //     "portfolioValue": 17610.1019856
        // },
        // {
        //     "date": "11/11/2024",
        //     "contributionAmount": 2627.1406234,
        //     "portfolioValue": 17630.15079168
        // },
        // {
        //     "date": "12/11/2024",
        //     "contributionAmount": 0,
        //     "portfolioValue": 7630.15418928
        // },
        // {
        //     "date": "13/11/2024",
        //     "contributionAmount": 0,
        //     "portfolioValue": 7638.33760419
        // },
        // {
        //     "date": "14/11/2024",
        //     "contributionAmount": 0,
        //     "portfolioValue": 7642.52875008
        // },
        // {
        //     "date": "15/11/2024",
        //     "contributionAmount": 0,
        //     "portfolioValue": 7632.77641764
        // },
        // {
        //     "date": "16/11/2024",
        //     "contributionAmount": 0,
        //     "portfolioValue": 7650.62377527
        // }
    ]
}

const PerformanceCard = ({ goalInfo }: any) => {

    const dispatch = useDispatch();
    const loading = useAppLoading('sharedStateData');

    const [apiData, setApiData] = useState<any>()

    const [graphData, setGraphData] = useState<any>([])
    const [paidProjectionPoints, setPaidProjectionPoints] = useState<any>([])

    const retirementAge = 1

    useEffect(() => {
        getDataByContribution();

        // const updatedData = testData4?.contributionsGraph?.map((item: any) => ({
        //     xAxisVal: moment(item.date, "DD/MM/YYYY").valueOf(),
        //     date: moment(item.date, "DD/MM/YYYY").format('DD-MMM-YYYY'),
        //     data1: item.portfolioValue,
        //     tooltipTitle: "Portfolio Amount",
        // }));
        // handleProjectionPointFunction(updatedData, testData4)
    }, [])

    // useEffect(() => {
    //     if (apiData?.length > 0) {
    //         handleProjectionPointFunction(apiData)
    //     }
    // }, [apiData])

    const getDataByContribution = () => {
        const onSuccess = (message: string | any, response: any) => {
            const updatedData = message?.contributionsGraph?.map((item: any) => ({
                xAxisVal: moment(item.date, "DD/MM/YYYY").valueOf(),
                date: moment(item.date, "DD/MM/YYYY").format('DD MMM YYYY'),
                data1: item.portfolioValue,
                tooltipTitle: "Portfolio Amount",
            }));
            // setApiData(updatedData)

            handleProjectionPointFunction(updatedData, message)
        }
        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
            setApiData([])
        }

        const payload = {
            goalId: goalInfo.idGoal,
            duration: 10000
        }

        if (goalCategory.CashFund == goalInfo.category) {
            dispatch(onGetCFPerformanceGraph(payload, onSuccess, onFail))
        } else {
            dispatch(onGetGIPerformanceGraph(payload, onSuccess, onFail))
        }

    }

    const handleProjectionPointFunction = (data: any, rawData: any) => {
        const paidArr: any = data

        if (paidArr.length > 0) {
            // Get the last object in the array
            const lastObject = paidArr[paidArr?.length - 1];
            // Add a new property to the last object
            lastObject.data2 = lastObject?.data1;
        } else {
            console.log('The array is empty.');
        }

        const retirementAgeMonth = rawData.durationMonths + 11
        // const retirementAgeMonth = rawData.durationMonths < 24 ? 23 : rawData.durationMonths

        const monthlyContribution = 0
        // const monthlyContribution = rawData.periodicContribution
        const InvestedAmt = Number(paidArr[paidArr?.length - 1]?.data1)

        handleGraphDataFunc(retirementAgeMonth, monthlyContribution, InvestedAmt, paidArr, rawData)

    }

    const handleGraphDataFunc = (numberOfPayments: number, monthlySavingValue: number, InvestedAmt: number, paidArr: any, rawData: any) => {

        if (InvestedAmt > 0) {
            const data: any = paidArr;
            let months = numberOfPayments;
            // const countOfPaidMonth = (paidArr?.length || 0)
            const countOfPaidMonth = rawData?.consumedMonths || 0;

            const saveRate = goalCategory?.CashFund == goalInfo?.category ? mahaanaSaveRate : new Big(rawData?.returnPercentage || 1).div(100)


            if (months < 0) { months = 0 }
            let startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
            const initValue = new Big(InvestedAmt || 0)
            let amount = initValue
            // const totalMonths = months - countOfPaidMonth
            const totalMonths = 24

            for (let i = 0; i < totalMonths; i++) {

                startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
                amount = monthlySavingReturnsCalculation(amount, monthlySavingValue, saveRate)

                data.push({
                    xAxisVal: moment(startDate).valueOf(),
                    date: moment(startDate).format('MMM YYYY'),
                    data2: Number(amount.toFixed(2, 0)),
                    tooltipTitle: "Projected Value*",
                });
            }

            setGraphData(data);
            return amount;
        }
    };

    return (
        <Box sx={{ pt: "20px", display: "flex", alignItems: "center" }}>
            {graphData?.length > 3 ? graphData && <PerformanceAreaChart data={graphData} retirementAge={retirementAge} gradient={"#422E84"} />
                :
                <NoData loading={loading} message={"Your graph will be shown 3 days after a transaction is verified"} />
            }
        </Box>
    )
}

export default PerformanceCard