import { Mixpanel } from "./index";

const OtpTracker = ({ ...props }) => {
    const { email, eventName, message, status } = props;

    Mixpanel.identify(email);
    Mixpanel.track(eventName, {
        message: message,
        status: status,
    });
    return;

};

export { OtpTracker };