import {
  EmployerInvestmentDetail,
  EmployeeInvestmentDetail
} from 'redux/reducers/employer';

export default function InvestmentTableTopBar({
  // employee_investment_detail,
  employer_investment_detail
}: InvestmentTableTopBarProps) {
  return (
    <div>
      <div className='table-responsive'>
        <table className='table table-borderless investmentsTable'>
          <thead>
            <tr>
              <th>Employer Contribution to Date</th>
              <th>Matched Contributions to date</th>
              <th>Total Contributions to date</th>
              <th>Accumulated Value</th>
              <th>Return (absolute)</th>
              <th>Return (annualize)</th>
            </tr>
            <tr >
              <td>
                {employer_investment_detail.employerContributionToDate?.toLocaleString() || 0} PKR
              </td>
              <td>
                {employer_investment_detail.matchContributionToDate?.toLocaleString() || 0} PKR
              </td>
              <td>
                {employer_investment_detail.totalContributionToDate?.toLocaleString() || 0} PKR
              </td>
              <td>{employer_investment_detail.accumulatedValue?.toLocaleString() || 0}</td>
              <td>{employer_investment_detail.absoluteReturn} %</td>
              <td>{employer_investment_detail.annualizedReturn} %</td>
            </tr>
            <tr>
              <th>Holdings</th>
              <th>Value</th>
              <th>Allocation %</th>
            </tr>
          </thead>
          <tbody>
            {employer_investment_detail.holdings.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{item.name?.toLocaleString() || 0}</td>
                    <td>{item.value?.toLocaleString() || 0}</td>
                    <td>{item.allocation?.toLocaleString() || 0} %</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

interface InvestmentTableTopBarProps {
  employer_investment_detail: EmployerInvestmentDetail;
  // employee_investment_detail: Array<EmployeeInvestmentDetail>;
}
