import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { tokens } from 'theme';

export const StyledTableCell = styled(TableCell)(({ theme }) => {

  const customTheme = useTheme();
  const colors = tokens(customTheme.palette.mode);

  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colors.grey[50],
      color: colors.grey[10],
      fontWeight: 500,
      padding: "8px 0 8px 16px",
      borderColor: "#E5E7EB",
      ...(customTheme.typography as any)?.["tableHeader"]
    },
    [`&.${tableCellClasses.body}`]: {
      color: colors.grey[10],
      backgroundColor: theme.palette.common.white,
      ...(customTheme.typography as any)?.["body2"]
    },
  };
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({

  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& td, & th': {
    borderColor: "#E5E7EB",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function CustomizedTables({ TabelBody, customStyleTableCell, tableHead, tableMinWidth = 1000 }: any) {
  return (
    <TableContainer sx={{
      borderRadius: "8px",
      border: "1px solid #D0D5DD40"
      // boxShadow:" 0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.1), 0px 1px 3px 0px rgba(0,0,0,0.1)" 
    }} >
      <Table
        sx={{ minWidth: tableMinWidth }}
        aria-label="customized table">
        <TableHead>
          <TableRow>
            {tableHead.map((item: any, index: number) => (
              <StyledTableCell key={index} style={{ ...customStyleTableCell }} >{item?.text} {item?.component}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        {TabelBody}
      </Table>
    </TableContainer>
  );
}