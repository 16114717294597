import { Col } from 'react-bootstrap';
import { memo } from 'react';
import { Box } from '@mui/material';


const ButtonsRow = ({
    backButtonDisabled = false, backButtonOnClick, colStyle, isBackbutton = true,
    isNextButton = true, loading, nextButtonDisabled = false, nextButtonLabel,
    nextButtonOnClick, prevButtonLabel = 'Back', styleBackButton, styleNextButton, styles
}: ButtonsRowProps) => {

    const buttonWrapper = {
        background: '', display: 'flex', justifyContent: isBackbutton ? 'space-between' : "end",
        marginLeft: { md: '18px', xs: '0' },
        flexWrap: 'wrap',
        '& button': {
            margin: { xs: '5px 10px' }
        }, ...styles,
    }

    return (
        <Col md="12" className='pr-0' style={{ ...colStyle }} >
            <Box sx={buttonWrapper} className='buttonRowWrapper'>
                {isBackbutton && <button
                    className='BackButton'
                    disabled={backButtonDisabled || loading}
                    onClick={backButtonOnClick}
                    style={{ ...styleBackButton }}
                >
                    {prevButtonLabel}
                </button>}

                {isNextButton && <button
                    className='NextButton'
                    disabled={nextButtonDisabled || loading}
                    onClick={nextButtonOnClick}
                    style={{ ...styleNextButton }}
                >
                    {nextButtonLabel != undefined ? nextButtonLabel : 'Next'}
                </button>}
            </Box>
        </Col>
    )
};

export default memo(ButtonsRow);



export interface ButtonsRowProps {
    backButtonDisabled?: boolean;
    backButtonOnClick?: any;
    nextButtonDisabled?: boolean;
    nextButtonOnClick?: any;
    nextButtonLabel?: string;
    loading?: any;
    isBackbutton?: boolean,
    styles?: any,
    styleBackButton?: any,
    styleNextButton?: any,
    isNextButton?: boolean,
    prevButtonLabel?: any,
    colStyle?:any
}