import { Box, Divider, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CashFundAssetsAllocation } from 'jsx/components/WizardFormModal/CashFundAssetAllocation/Style/style';
import React, { useEffect, useRef, useState } from 'react'
import { TabPanel } from 'Latest/SharedFunction'
import a11yProps from 'Latest/SharedFunction'
import { componentV2Style } from '../style/style';

const CustomBoxTabV2 = ({ contentTabs, customTab, customTabs, scrollBtn = true }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const scrollMobileScreen = smScreen ? false : true
    const [value, setValue] = useState(0);

    const handleChangeContentTabs = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div >

            <Tabs
                allowScrollButtonsMobile
                scrollButtons={scrollMobileScreen && scrollBtn}
                className='cfAssetsTabs'
                variant="scrollable"
                value={value}
                onChange={handleChangeContentTabs}
                aria-label="basic tabs example"
                sx={{ ...componentV2Style.boxTab.tabs, ...customTabs }}
            >

                {contentTabs.map((item: any, index: number) => {
                    return (
                        <Tab
                            key={String(index)}
                            label={<>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: "8px", md: "16px" } }}>
                                    <item.icon fill={value == index ? "#422E84" : "#1D2939"} height={smScreen ? "24px" : "16px"} width={smScreen ? "24px" : "16px"} />
                                    <Typography variant='body1' color="#1D2939">{item.label}</Typography>
                                </Box>
                            </>}

                            // label = {item.label}
                            {...a11yProps(index)}
                            sx={{
                                ...componentV2Style.boxTab.tab, ...customTab,
                                // gap: 2,
                                minWidth: `calc((100% - 16px) / ${contentTabs?.length})`,
                            }}
                        />
                    )
                })}
            </Tabs>

            {contentTabs.map((item: any, index: number) => {
                return (
                    <TabPanel key={index} value={value} index={index} style={{p:0}}>
                        {item.comp}
                    </TabPanel>
                )
            })}



        </div>
    )
}

export default CustomBoxTabV2

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    styling?: any
}