/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Container, Grid, Typography } from '@mui/material';
import { HeadingStyle, subHeadingStyle } from 'Latest/Style/Main';
import { memo, useState } from 'react';
import UploadField from '../../Shared/V2/FileUploadFieldV2';
import CustomSingleCheckBox from 'jsx/components/Shared/V2/CustomSingleCheckBoxV2';

export { };
declare let window: any

const NationalIdentityDetails = ({ backNICFile, formik, frontNICFile, imageError, setImageError, setUserNICBack, setUserNICFront }: CnicProps) => {

  const cnicFront = formik?.values?.UserNICFront == '' ? [] : [formik?.values?.UserNICFront]
  const cnicBack = formik?.values?.UserNICBack == '' ? [] : [formik?.values?.UserNICBack]

  const [permissionGranted, setPermissionGranted] = useState<any>('');

  window.checkCameraPermission = (status?: string) => updateByWindow(status)
  const updateByWindow = (status?: string) => {
    if (status == "granted") { setPermissionGranted(status); }
    else { setPermissionGranted(status); }
  }

  return (
    <>
      {/* <Container sx={{ ml: "1rem", minHeight: "32rem", mt: "2rem" }} >
        <Box>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} >
              <Typography sx={HeadingStyle}>Please upload your CNIC</Typography>
            </Grid>

            <UploadField
              required
              acceptedType='image/png, image/jpg, image/jpeg'
              buttonTitle="Upload front side picture"
              col={6}
              fieldName="UserNICFront"
              formik={formik}
              imageError={imageError}
              inputFiles={cnicFront}
              maxFiles={1}
              setImageError={setImageError}
              setImages={setUserNICFront}
              title={"Front"}
              permissionGranted={permissionGranted}
            />

            <UploadField
              required
              acceptedType='image/png, image/jpg, image/jpeg'
              buttonTitle="Upload back side picture"
              col={6}
              fieldName="UserNICBack"
              formik={formik}
              imageError={imageError}
              inputFiles={cnicBack}
              maxFiles={1}
              setImageError={setImageError}
              setImages={setUserNICBack}
              title={"Back"}
              permissionGranted={permissionGranted}
            />

          </Grid>

          <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
            <Typography sx={subHeadingStyle}> Make sure both sides are clear and readable.</Typography>
          </Grid>

          <Grid item md={12} xs={12} sx={{ mt: 3, mb: 3 }}>
            <CustomSingleCheckBox
              formik={formik}
              fieldName={`consentCheck`}
              label={`I give consent to Mahaana to process and verify this CNIC for account opening through NADRA`}
              type={"checkbox"}
              styleForm={{ paddingLeft: "0px" }}
              styleErr={{ marginLeft: "0px" }}
            />
          </Grid>

        </Box>
      </Container> */}


      <Box sx={{ width: "100%" }}>

        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: "column" }}>
          <Typography variant='h4' textAlign={"center"} color={"#1D2939"} lineHeight={"normal"} letterSpacing={"unset"} pb={3}>Please upload your CNIC</Typography>

          <Grid item md={12} xs={12} display={"flex"} gap={"16px"} flexWrap={{ md: "nowrap", xs: "wrap" }}>
            <UploadField
              required
              acceptedType='image/png, image/jpg, image/jpeg'
              buttonTitle="Upload front view of CNIC"
              col={6}
              fieldName="UserNICFront"
              formik={formik}
              imageError={imageError}
              inputFiles={cnicFront}
              maxFiles={1}
              setImageError={setImageError}
              setImages={setUserNICFront}
              title={"Front view"}
              permissionGranted={permissionGranted}
              subTitle={"The image must be clear and readable."}
            />

            <UploadField
              required
              acceptedType='image/png, image/jpg, image/jpeg'
              buttonTitle="Upload back view of CNIC"
              col={6}
              fieldName="UserNICBack"
              formik={formik}
              imageError={imageError}
              inputFiles={cnicBack}
              maxFiles={1}
              setImageError={setImageError}
              setImages={setUserNICBack}
              title={"Back view"}
              permissionGranted={permissionGranted}
              subTitle={"The image must be clear and readable."}
            />
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} sx={{ mt: 3 }}>
          <CustomSingleCheckBox
            formik={formik}
            fieldName={`consentCheck`}
            label={`I consent to Mahaana processing and verifying this CNIC for account opening purposes through NADRA.`}
            type={"checkbox"}
            styleForm={{ paddingLeft: "0px" }}
            styleErr={{ marginLeft: "0px" }}
          />
        </Grid>
      </Box>

    </>
  );
};

export default memo(NationalIdentityDetails);

interface CnicProps {
  backNICFile: any;
  formik: any;
  frontNICFile: any;
  setUserNICFront: any;
  setUserNICBack: any;
  imageError: boolean;
  setImageError: (isImage: boolean) => void;
}
