export const featureMainBox = {
    p: "1rem",
    borderRadius: "6px",
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',
    width: "100%",
    border: "1px solid #EDEDED",
}

export const featureBox = {
    display: "flex",
    alignItems: "center"
}

export const featureBoxTypo = {
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "150%",
    textTransform: "capitalize",
    color: "#666666",

}
export const featureBoxBtn = {
    background: "#EDEDED",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#202020",
    px: '1rem',
    '&:hover': {
        background: "#edededd4",
        transition: 'all 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s',
        transform: 'translateY(-0.5px)',
        boxShadow: 'rgba(166, 166, 166, 0.5) 0px 5px 10px 0px',
    }
}
export const checkboxLabel = {
    fontFamily: 'Inter,sans-serif',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "155%",
}