/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Box, Container, Divider, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { receiptBox, receiptCardBoldTypoStyle, receiptCardHeadTypoStyle, receiptCardSubBoldTypoStyle, receiptCardTypoStyle, receiptContainer, receiptContentWrapperStyle } from './style';
import checkImage from '../Images/check.png';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { links } from 'static/links';
import { commaFormatted } from 'utils/commaSeparateMasking';
import { goalIdAction, goalFlowIdAction, goalStatus, goalFlowPathAction, payMobServiceFeeActions } from 'redux/reducers/portfolio';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { addGoalBodyAction } from 'redux/reducers/sharedStateData';
import { goalCategory } from '../../constants';
import Big from 'big.js';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import TopBarHeading from 'Latest/ComponentV1/topBarHeading';
import { CurrencyFormatterWithDecimal } from 'utils/calculation';
import SuccessReceipt from './successReceipts';
import FaildScreen from './faildReceipt';
import TopBarLogo from 'Latest/ComponentV1/topBarLogo';
import ExitIcon from "Latest/Images/svg/ExitIcon";
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom'
import { smallScreenBtnWrap } from 'Latest/Style/Style';

export { };

declare let window: any

const PayReceipt = () => {
    const { location, push } = useHistory();
    const dispatch = useAppDispatch();
    const loc: any = location?.state;

    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);


    const initial = localStorage.getItem('InitialAmount');
    const [initialAmount, setInitialAmount] = useState(Math.ceil(Number(initial)) || 0);
    const initAmount = loc?.key == "Sell" ? loc?.amount?.investAmount : initialAmount;
    const [statusCheck, setStatusCheck] = useState<any>(false);
    const [paymentType , setPaymentType] = useState<string>("")

    const [ref, setRef] = useState('')
    const [transacId, setTransacId] = useState<any>('')

    const { goalIdPost } = useAppSelector(state => state.portfolio);

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search); //paymob
        const amount_Cents = urlParams.get('amount_cents');  //paymob
        const transaction_Id = urlParams.get('id'); //paymob
        const status_Check = urlParams.get('success'); //paymob
        const hasAmount_cents = !!amount_Cents; //paymob
        const paymobType = urlParams.get("paymentMethodType");

        if (hasAmount_cents) { //Paymob callback check
            setStatusCheck(status_Check == "false" ? false : true)
            setTransacId(transaction_Id)
            setInitialAmount(Number(Big(amount_Cents).div(100)))
            setPaymentType(paymobType?paymobType:"");

        } else {
            setStatusCheck(true)
            let details = window.localStorage.getItem('transcriptDetails')
            details = JSON.parse(details)
            const referenceNumber = loc?.transactionReferenceNumber ? loc?.transactionReferenceNumber : details?.pp_TxnRefNo
            setRef(referenceNumber)
        }

        dispatch(goalIdAction(""))              //save Goal id i.e cashfundId , GI id  
        dispatch(goalFlowIdAction(""))        //save Goal category i.e cashfund == 100 
        dispatch(goalFlowPathAction(''))    //save Path i.e invest , wizard 
        dispatch(addGoalBodyAction({}));


    }, [])

    useEffect(() => {
        const handleBackButton = () => {
            // Handle the back button press here
            push(links.home)
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const backtoHomeHandler = () =>{
        dispatch(payMobServiceFeeActions(0))
        push(links.home)
    }


    const addExtraRoutes =
        [{ title: smScreen ? "Exit" : "Exit", route: null, icon: ExitIcon, isNavlink: false, navDropDown: [], target: false, onClick: () => push("/"), functionTrigger: true },]


    const checkNewUserScreen = dashboardDetail && dashboardDetail?.status == "New"
    console.log(ref,transacId,initAmount,"sasas")

    return (

        <>
            {/* <Container disableGutters={smScreen} maxWidth={"xl"} sx={{ mt: "40px" }} > */}
            {/* {(checkNewUserScreen && mdScreen) && <TopBarLogo addExtraRoutes={addExtraRoutes} />} */}
            <TopBarLogo addExtraRoutes={addExtraRoutes} />
            <Box sx={{ height: "100vh", background: "#F8FAFB" }}>
                <Container maxWidth={'lg'} sx={{ py: { xs: "24px", md: "40px" }, px: { xs: 0, md: "24px" } }} disableGutters={smScreen}>
                    <Box sx={{
                        boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
                        background: "white", p: { xs: "16px 0", md: "20px 40px" }, borderRadius: "8px", display: 'flex', alignItems: 'center', flexDirection: "column"
                    }}>
                        {statusCheck ?
                            <SuccessReceipt
                                refId={ref}
                                transacId={transacId}
                                initAmount={initAmount}
                                paymentType={paymentType}
                            />
                            :
                            <FaildScreen
                                refId={ref}
                                transacId={transacId}
                                initAmount={initAmount}
                            />
                        }

                        {!mdScreen && <Box display={"flex"} width={"100%"} sx={{ mt: "40px", justifyContent: "flex-end" }}>
                            <LoadingBtnCustom handleOnClick={() => backtoHomeHandler()} btnTitle={"Go to home"} style={{ padding: "12px 24px" }} />
                        </Box>}

                    </Box>

                    {mdScreen && <Box display={"flex"} sx={{ ...smallScreenBtnWrap, mt: "24px", justifyContent: "center" }}>
                        <LoadingBtnCustom handleOnClick={() => backtoHomeHandler()} btnTitle={"Go to home"} style={{ padding: "12px 24px" }} />
                    </Box>}
                </Container >
            </Box>

            {/* </Container> */}


            {/* <Container disableGutters maxWidth={"xl"}>
                <TopBarHeading heading={"Receipt"} />
                <Divider sx={{ borderColor: "#EAECF0" }} />
                <Box sx={{ p: { xs: "16px", sm: "30px" } }}>
                    <Container maxWidth="lg" sx={{ px: "0px!important" }}>
                        <Grid container sx={{ background: "#F9FAFB", textAlign: "center", p: { xs: "24px 16px", md: "30px" }, borderRadius: "12px", display: 'flex', alignItems: 'center', flexDirection: "colmun" }}>
                            {statusCheck ?
                                <SuccessReceipt
                                    refId={ref}
                                    transacId={transacId}
                                    initAmount={initAmount}
                                />
                                :
                                <FaildScreen
                                    refId={ref}
                                    transacId={transacId}
                                    initAmount={initAmount}
                                />
                            }

                        </Grid>
                    </Container >
                </Box>

            </Container> */}


        </>
    )

}

export default PayReceipt