import { GoalValueEnum, GoalIdEnum, GoalNameEnum } from "../../../constants";
import { Modal } from "react-bootstrap";
import { useAppSelector } from "hooks/reduxHooks";
import './style/AddGoal.scss'
import { useDispatch } from 'react-redux';
import { goalIdAction } from "redux/reducers/portfolio";

const AddGoalModal = (props: any) => {
    const dispatch = useDispatch();
    const { investorGoal } = useAppSelector(state => state.portfolio);
    const onSelect = (id: number, val: string, name: string) => {
        props.onSelectGoal(id, val, name);
        props.setShow(false);
    }

    const CloseModal = () => {
        props.setShow(false);
        dispatch(goalIdAction(""))
    }

    return (
        <>
            <Modal
                centered
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
            >
                <Modal.Header closeButton onHide={CloseModal} >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Goal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div>
                        {investorGoal?.some((e: any) => e.goalType.value === GoalValueEnum.HouseBuy) ? "" :
                            <p className="Oye" onClick={() => onSelect(GoalIdEnum.HouseBuy, GoalValueEnum.HouseBuy, GoalNameEnum.HouseBuy)} >
                                <i aria-hidden='true' className='fa fa-home mr-4' />
                                {GoalNameEnum.HouseBuy}
                            </p>
                        }
                        {investorGoal?.some((e: any) => e.goalType.value === GoalValueEnum.CollegeFee) ? "" :
                            <p className="Oye" onClick={() => onSelect(GoalIdEnum.CollegeFee, GoalValueEnum.CollegeFee, GoalNameEnum.CollegeFee)}>
                                <i aria-hidden='true' className='fa fa-graduation-cap mr-3' />
                                {GoalNameEnum.CollegeFee}
                            </p>
                        }

                        {investorGoal?.some((e: any) => e.goalType.value === GoalValueEnum.TravelTime) ? "" :
                            <p className="Oye"
                                onClick={() => onSelect(GoalIdEnum.TravelTime, GoalValueEnum.TravelTime, GoalNameEnum.TravelTime)}
                            >
                                <i aria-hidden='true' className='fa fa-plane mr-4' />
                                {GoalNameEnum.TravelTime}
                            </p>
                        }
                        {investorGoal?.some((e: any) => e.goalType.value === GoalValueEnum.LargeExpense) ? "" :
                            <p className="Oye" onClick={() => onSelect(GoalIdEnum.LargeExpense, GoalValueEnum.LargeExpense, GoalNameEnum.LargeExpense)}>
                                <i aria-hidden='true' className='fa fa-money mr-3' />
                                {GoalNameEnum.LargeExpense}
                            </p>
                        }
                        <p className="Oye" onClick={() => onSelect(GoalIdEnum.WindFallReceive, GoalValueEnum.WindFallReceive, GoalNameEnum.WindFallReceive)}>
                            <i aria-hidden='true' className='fa fa-calculator mr-3' />
                            {GoalNameEnum.WindFallReceive}
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default AddGoalModal;

