import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { Box, Button, IconButton, Skeleton, Typography } from '@mui/material';
import useStylesAlertWarning from 'Latest/ComponentV1/CustomMakeStyle/customWarningAlert';
import IconWarning from "Latest/Images/svg/IconWarning.svg"
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { zakaatDeclNotiAction } from 'redux/reducers/notificationStatus';
import { useAppLoading } from 'hooks/appLoading';
import { useHistory } from 'react-router-dom';
import useStylesAlertInfo from 'Latest/ComponentV1/CustomMakeStyle/customInfoAlert';
import IconInfo from "Latest/Images/svg/IconInfo.svg";
import { submitInfoStyle } from '../style';

export default function AccDetailSuccessAlert({ handleClose }: any) {
    const { push } = useHistory();
    const classes = useStylesAlertInfo();
    const dispatch = useAppDispatch();
    const loading = useAppLoading('auth');
    const { userAccountDetails } = useAppSelector(state => state.auth);

    if (userAccountDetails?.zakatAffidavit === undefined || userAccountDetails?.zakatAffidavit === null) return <SkeletonLoading />;

    return (
        <>
            <Box>

                {loading ?
                    <Skeleton variant="rounded" width={"100%"} height={20} />
                    :
                    <Alert severity="info" className={classes.customAlert}
                        // onClose={handleClose} 
                        sx={{ position: "relative" }} icon={false}>
                        <AlertTitle sx={{ ...submitInfoStyle.mainHeading, marginBottom: 0 }}>
                            <img src={IconInfo} />
                            Your account details and payment are being verified
                        </AlertTitle>
                    </Alert>
                }

            </Box>
        </>
    );
}


const SkeletonLoading = () => {
    return (
        <Box sx={{ px: { xs: "16px", sm: "32px" }, pb: { xs: "16px", sm: "24px" } }}>
            <Skeleton variant="rounded" width={"100%"} height={20} />
        </Box>
    )
}