/* eslint-disable sort-keys */
import * as React from 'react';
import '../../scss/login/_Login.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, IconButton, InputAdornment, useMediaQuery, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { memo, useState } from 'react';
import { RegisterParams } from 'redux/actions';
import { links } from 'static/links';
import { signup } from 'validate/auth/signup';
import Card from '@mui/material/Card';
import MUILink from '@mui/material/Link';
import Box from '@mui/material/Box';
import Logo from 'Latest/Images/mahanna2.png';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AuthInputField from '../components/MUI_components/FormikTextField';
import AuthPasswordField from '../components/MUI_components/FormikPasswordField';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import AuthInputNameField from '../components/MUI_components/FormikNameField';
import PhoneField from 'jsx/components/Shared/PhoneField';
import { authStyles } from 'Latest/Style/Style';
import { Link } from 'react-router-dom';
import FormikInputField from 'jsx/components/Account_Fields/FormikInputFieldLatest';
import { useAppLoading } from 'hooks/appLoading';
import moment from 'moment';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import TopBarHeading from 'Latest/ComponentV1/topBarHeading';
import TopBarLogo from 'Latest/ComponentV1/topBarLogo';
import { styleAuth } from './style';
import { passwordMaxLength } from '../../constants/index';

interface State {
  password: string;
  showPassword: boolean;
  ConfirmshowPassword: boolean;
}

const Signup = ({
  loading,
  phoneNo,
  registerHandle
}: SignupProps) => {
  const [countryCode, setCountryCode] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [birthDateError, setBirthDateError] = useState('');
  const [birthDate, setBirthDate] = useState<Date>(new Date());

  const theme = useTheme();

  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // dateOfBirth: '',
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',

    },
    onSubmit: (values: RegisterParams) => {
      phoneNo( `+${formik.values.phoneNumber}`);

      if (!values.phoneNumber.slice(countryCode.length).length) {
        setPhoneError('Mobile number is required');
        return;
      }

      const data = {
        // dateOfBirth: values.dateOfBirth,
        name: values.name,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        phoneNumber: `+${values.phoneNumber}`,
      };
      // data.dateOfBirth = moment(data.dateOfBirth).format('YYYY-MM-DD')
      registerHandle(data);
    },
    validationSchema: signup
  });

  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
    ConfirmshowPassword: false
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangeConfirmPassword = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      ConfirmshowPassword: !values.ConfirmshowPassword,
    });
  };

  const handleMouseConfirmDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const authRegisterLoading = useAppLoading('AuthRegister');
  const isLoading = loading || authRegisterLoading
  React.useEffect(() => {
    formik.setFieldValue('password', formik.values.password.replace(/\s/g, '').trim())
    formik.setFieldValue('confirmPassword', formik.values.confirmPassword.replace(/\s/g, '').trim())
  }, [formik.values.confirmPassword, formik.values.password])

  const dropdownStyles = {
    width:"245px",
  }

  return (
    <>

      {/* <TopBarLogo /> */}
      <Container sx={{ py: { xs: "24px", md: "40px" }, maxWidth: "500px!important" }} disableGutters={smScreen}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={styleAuth.cardContainer}>
            <Typography variant='h1' color='#1D2939' pb='12px'>Sign up</Typography>
            <AuthInputNameField
              // required
              disabled={isLoading}
              formik={formik}
              label='Name'
              name='name'
              placeholder='Name as per CNIC'
            />

            <AuthInputField
              // required
              disabled={isLoading}
              formik={formik}
              label='Email'
              name='email'
              placeholder='hello@example.com'
            />

            <AuthPasswordField
              // required
              removeWhitespace={true}
              maxLength={Number(passwordMaxLength.maxLength)}
              disabled={isLoading}
              endAdornment={<InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff sx={{ color: '#9CA3AF' }} /> : <Visibility sx={{ color: '#9CA3AF' }} />}
                </IconButton>
              </InputAdornment>}
              formik={formik}
              label='Password'
              name='password'
              onChange={handleChange('password')}
              placeholder='••••••••••'
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
            />

            <AuthPasswordField
              // required
              disabled={isLoading}
              maxLength={Number(passwordMaxLength.maxLength)}
              removeWhitespace={true}
              endAdornment={<InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseConfirmDownPassword}
                >
                  {values.ConfirmshowPassword ? <VisibilityOff sx={{ color: '#9CA3AF' }} /> : <Visibility sx={{ color: '#9CA3AF' }} />}
                </IconButton>
              </InputAdornment>}
              formik={formik}
              label='Confirm password'
              name='confirmPassword'
              onChange={handleChangeConfirmPassword('password')}
              placeholder='••••••••••'
              type={values.ConfirmshowPassword ? 'text' : 'password'}
              value={values.ConfirmshowPassword}
            />

            {/* <FormikInputField
              // required
              formik={formik}
              // FieldTouchInput={true}
              label='Date of birth'
              name='dateOfBirth'
              placeholder='dd/mm/yyyy'
              type='Date'
              // style={{ height: "48px" }}
              signUpLabelStyle={true}
            /> */}

            <PhoneField
              // required
              country={'pk'}
              countryCodeEditable={false}
              disableCountryCode={false}
              disableDropdown={false}
              dropdownStyle = {dropdownStyles}
              enableSearch = {true}
              fieldName='phoneNumber'
              formik={formik}
              label='Mobile number'
              
              value={formik.values.phoneNumber}
            />

            <Box sx={{ pt: "12px" }}>
              <LoadingBtnCustom type="submit" btnTitle="Sign up" size={"large"} fullWidth={true} handleOnClick={formik.handleSubmit} loading={isLoading} />
            </Box>

            <Typography variant='body1' color="#4B5563" textAlign={"center"}>Already have an account? <a href={links.login} style={{ color: "#43BED8" }}> Sign in here.</a></Typography>

          </Box>
        </form>
      </Container>






      {/* 
      <Container className="Login" component="main" maxWidth="sm" sx={authStyles.container}>
        <Card className='login_Card'>
          <Box className='Form_Main'
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Link to="/">
              <img alt="Mahaana" className='img logo' src={Logo} />
            </Link>
            <h1 className='Login_heading'>Sign Up </h1>
            <Box className='Form_Secondary'>
              <AuthInputNameField
                required
                disabled={isLoading}
                formik={formik}
                label='Name'
                name='name'
                placeholder='Name as per ID card'
              />
              <AuthInputField
                required
                disabled={isLoading}
                formik={formik}
                label='Email Address'
                name='email'
                placeholder='hello@example.com'
              />

              <AuthPasswordField
                required
                removeWhitespace={true}
                disabled={isLoading}
                endAdornment={<InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff sx={{ color: '#9CA3AF' }} /> : <Visibility sx={{ color: '#9CA3AF' }} />}
                  </IconButton>
                </InputAdornment>}
                formik={formik}
                label='Password'
                name='password'
                onChange={handleChange('password')}
                placeholder='••••••••••'
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
              />

              <AuthPasswordField
                required
                disabled={isLoading}
                removeWhitespace={true}
                endAdornment={<InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseConfirmDownPassword}
                  >
                    {values.ConfirmshowPassword ? <VisibilityOff sx={{ color: '#9CA3AF' }} /> : <Visibility sx={{ color: '#9CA3AF' }} />}
                  </IconButton>
                </InputAdornment>}
                formik={formik}
                label='Confirm Password'
                name='confirmPassword'
                onChange={handleChangeConfirmPassword('password')}
                placeholder='••••••••••'
                type={values.ConfirmshowPassword ? 'text' : 'password'}
                value={values.ConfirmshowPassword}
              />

              <Box className="dateOfCNICIssuanceInput" sx={{ height: "100px" }}>
                <FormikInputField
                  required
                  formik={formik}
                  // FieldTouchInput={true}
                  label='Date of Birth'
                  name='dateOfBirth'
                  placeholder='dd/mm/yyyy'
                  type='Date'
                  style={{ height: "48px" }}
                  signUpLabelStyle={true}
                />
              </Box>

              <PhoneField
                required
                country={'pk'}
                countryCodeEditable={false}
                disableCountryCode={false}
                disableDropdown={true}
                fieldName='phoneNumber'
                formik={formik}
                label='Mobile number'
                value={formik.values.phoneNumber}
              />
              <AuthSubmitButton
                isAuth={true}
                title='Sign Up'
                loading={isLoading}
              />
              <Typography className='acc_label' sx={{ pt: 2, flexWrap: 'wrap', textAlign: 'center' }}>
                Already have an account?
                <MUILink className='link' href={links.login} variant="body2" >
                  {"Sign in here"}
                </MUILink>
              </Typography>
            </Box>
          </Box>
        </Card>
      </Container> */}



    </>
  );
};

export default memo(Signup);

interface SignupProps {
  // createUserId: number | null;
  loading: boolean;
  registerHandle: (data: RegisterParams) => void;
  // otpHandle: (data: RegisterParams) => void;
  phoneNo?: any;

}
export interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}
