import SubscribtionComp from "Latest/SubscriptionComp/SubscriptionComp"
import { useAppDispatch } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { onSubscription } from "redux/actions";

const SubscribtionPage = () => {
    const [isAlert, setIsAlert] = useState(false);
    const [isButton, setIsButton] = useState(true);
    const [isSubscribe, setIsSubscribe] = useState(false)
    const dispatch = useAppDispatch();

    useEffect(() => {
        getStatusValue()
    },[isAlert,isButton,isSubscribe]);

    const getStatusValue = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const statusVal = urlParams.get("status");
        if (statusVal == "subscribe"){
            setIsSubscribe(true)
        }else if (statusVal == "unsubscribe"){
            setIsSubscribe(false)
        }
    }
    
    const onSubscribtion = () => {

        const getEmailFromRoute = () => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('email');
        };

        const email = getEmailFromRoute();
        const body = { 'email': email }

        const onSuccess = (message: any, response: any) => {

            console.log(message, response)
            setIsAlert(true)
            setIsButton(false)
        }

        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
            switch (response?.status) {
                case 500:
                    toast.error("Internal Server Error", {theme: "colored"});
                    break;
                default:
                    toast.error("Something Went Wrong", {theme: "colored"});

            }
        }


        dispatch(onSubscription(body,isSubscribe?"Subscribe":"Unsubscribe", onSuccess, onFail));

    }

    return (
        <>
            <SubscribtionComp isSubscribe={isSubscribe} subscribtionHandler={onSubscribtion} isAlert={isAlert} setIsAlert={setIsAlert} isButton={isButton} setIsButton={setIsButton} />
        </>
    )
}

export default SubscribtionPage