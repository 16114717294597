import { Box, Typography, useTheme } from "@mui/material"
import { tokens } from "theme";

export const Disclaimer = () => {
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);

    return (
        <Box>
            <Typography variant='h1' color={"secondary"}>Disclaimer</Typography>
            <Box sx={{display:"flex", marginTop:"15px"}}>
                <Typography sx={{color: colors.grey[5]}}>
                    *
                </Typography>
            <Typography sx={{color: colors.grey[5],marginLeft:"5px" }}>All investments are subject to market risks. Investors are advised to carefully read the fund manager
                report and offering document and carefully to make a decision. Projections are
                based on past performance and are not indicative for future returns.
            </Typography>
            </Box>
        </Box>
    )
}