import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STOCK_DETAIL_BASE_URL } from '../../constants';

const initialState: InitialState = {
  allBasketHistoryData: null
};

const name = 'basketHistory';
const basketHistorySlice = createSlice({
  initialState,
  name,
  reducers: {
    allBasketHistoryDataSuccess: (state, action: PayloadAction<any>) => {
      state.allBasketHistoryData = action.payload;
    }
  }
});

export const { allBasketHistoryDataSuccess } = basketHistorySlice.actions;
export default basketHistorySlice.reducer;

//? APIS ACTIONS

export const onGetBasketHistoryData = (basketName: any) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: allBasketHistoryDataSuccess,
    url: `${STOCK_DETAIL_BASE_URL}/api/Basket/history?basketName=${basketName}`
  },
  type: 'onGetBasketHistoryData'
});

// ? Types

interface InitialState {
  allBasketHistoryData: any | null;
}
