import { Container, Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import React, { useEffect, useState } from 'react'
import { onGetAllUserQuestionAnswers, onGetUserQuestionAnswers } from 'redux/actions';
import { QuestionAnswerCategoryEnum } from '../../../../constants';
import UpdateCashFundProfile from 'jsx/components/RiskProfile/UpdateRiskProfile';
import { useAppLoading } from 'hooks/appLoading';

const Index = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: any) => state.auth);
  const [question, setQuestion] = useState<any>([])
  const loading = useAppLoading('questionAnswer');

  useEffect(() => {
    handleAPIOnUpdate()
  }, [])

  const { allUserSelectedAnswer, cashFundRiskProfileUserQuestions } = useAppSelector(state => state.questionAnswer);

  useEffect(() => {
    handleMergeQuestion()
  }, [cashFundRiskProfileUserQuestions, allUserSelectedAnswer])

  const handleAPIOnUpdate = () => {
    dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.cashFundRiskProfile, true));
    dispatch(onGetAllUserQuestionAnswers(QuestionAnswerCategoryEnum.cashFundRiskProfile, true));

  }

  const handleMergeQuestion = () => {

    const mergedQuestionAnswers = cashFundRiskProfileUserQuestions.reduce((merged: any, obj: any) => {
      return merged.concat(obj.questionAnswer);
    }, []);

    const filteredQuestion = mergedQuestionAnswers.filter((itemQuest: any) => allUserSelectedAnswer.some((itemAns: any) => itemAns.id === itemQuest.id));

    if (filteredQuestion.length > 0) {
      const arr = {
        "category": "RiskProfile",
        "questionAnswer": filteredQuestion
      }
      setQuestion(arr)
    }
  }

  return (
    <Container maxWidth="lg" sx={{ px: "0px!important", ml: 0 }}>
      <Grid container sx={{ p: { xs: "24px 16px", md: "30px 32px" } }}>
        <UpdateCashFundProfile
          showNextBtn={false}
          url="myprofile"
          selectedAnswer={allUserSelectedAnswer}
          profileQuestions={question}
          handleAPIOnUpdate={handleAPIOnUpdate}
          loading={loading}
        />
      </Grid>
    </Container>
  )
}

export default Index