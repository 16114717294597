import { ReactElement } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Button, Typography, Box, Stack } from '@mui/material';
import { cardMainHeadStyle, cardMainLogoBoxStyle, cardSubHeadStyle, cardUlTypoStyle, customBtnBoxStyle } from '../style/card';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import briefcase from "../../Images/briefcase.png"
import { cardBtnTitleStyle } from '../style/card';
import { useHistory } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppLoading } from 'hooks/appLoading';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import { useAppSelector } from 'hooks/reduxHooks';


export default function FreshUserCardV2({ item }: any) {

    const { btnTitle, category, heading, img, isDisable, link, subHeading } = item
    const { push } = useHistory();
    const { user: { email } } = useAppSelector((state: any) => state.auth);

    const portfolioLoading = useAppLoading('portfolio');
    const questionAnswerLoading = useAppLoading('questionAnswer');

    const handleLink = () => {
        const params = { NewUser: true };
        if (category == "invest") {
            push(link, params)
        } else {
            push(link)
        }
        
        if (item.mixpanelText) {
            MixPannelTracker({ eventName: item.mixpanelText, email })
        }
    }


    const cardStyles = {
        borderRadius: "8px", p: "16px",
        background: "#FFF",
        maxWidth: "400px",
        border: "1px solid rgba(208, 213, 221, 0.25)",
        boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
        display: "flex", flexDirection: "column",
        // justifyContent: "space-between"
    }
    return (
        <>
            <Box sx={cardStyles}>

                <img src={img} style={{ width: "100%" }} />

                <Typography variant='h1' color={"#422E84"} px="8px" pt="8px" pb="16px" lineHeight={"normal"}>{heading}</Typography>

                <Box sx={{ flex: 1, pb: "16px" }}>
                    {item.list?.map((itemlist: any, indexList: number) => {
                        return (
                            <Box key={indexList} sx={{ display: "flex", alignItems: "center", gap: "8px", p: "8px" }}>
                                <img src={itemlist?.contentIcon} />
                                <Typography variant='body1' color={"#1D2939"} dangerouslySetInnerHTML={{ __html: itemlist?.content }} />
                            </Box>
                        )
                    })}
                </Box>


                <LoadingBtnCustom fullWidth={true} btnTitle={btnTitle}
                    loading={portfolioLoading || questionAnswerLoading}
                    disabled={isDisable} variant='contained'
                    handleOnClick={handleLink}
                />
            </Box>

        </>
    );
}