const colorLight = {
    primary: {
        1: "#43BED8",
        25: "#FCFAFF",
        50: "#D9D5E7",
        100: "#C0BAD7",
        200: "#A197C3",
        300: "#8274AF",
        400: "#62529B",
        500: "#432F87",
        600: "#382770",
        700: "#2D1F5A",
        750: "#6941C6",
        800: "#211743",
        900: "#16102D",
    },
    grey: {
        5: '#1D2939B2',
        10: 'rgba(29, 41, 57, 1)',
        15: '#DEE3E7',
        20: '#272E35',
        25: "#FCFCFD",
        50: "#F9FAFB",
        100: "#F2F4F7",
        200: "#EAECF0",
        300: "#D0D5DD",
        400: "#98A2B3",
        500: "#667085",
        550: "#6B6F80",
        600: "#475467",
        700: "#344054",
        800: "#1D2939",
        900: "#101828"
    },
    greyMuted: {
        1: "#DEE3E7"
    },
    greyPrimary: {
        1: "#272E35"
    },
    greyIron: {
        500: "#667085",
        600: "#51525C"
    },
    orangeMuted: {
        1: "#FCDEC0"
    },
    orangePrimary: {
        1: "#7A4510"
    },
    greenAccent: {
        1: "#12B76A",
        100: "#DEF7EC",
        200: "#C6F1DA",
        300: '#0E4E30',
        500: "#A0E2C380",
        800: "#03543F"
    },
    redAccent: {
        600: "#D92D20"
    },
    blue: {
        25: "#F5FAFF",
        50: "#EFF8FF",
        100: "#D1E9FF",
        200: "#B2DDFF",
        300: "#84CAFF",
        400: "#53B1FD",
        500: "#2E90FA",
        600: "#1570EF",
        700: "#175CD3",
        800: "#1849A9",
        900: "#194185",
    },
    purpleAccent: {
        100: "#422E84"
    },
    purple: {
        100: "#432F87",
    },
    background: {
        100: "#fff"
    },
    red: {
        1: "#E62E05",
        2: "#FFF4ED",
        100: "#FDE8E8",
        150: "#821B13",
        500: "#F0ABA680",
        800: "#9B1C1C",
    },
    error: {
        25: "#FFFBFA",
        50: "#FEF3F2",
        100: "#FEE4E2",
        200: "#FECDCA",
        300: "#FDA29B",
        400: "#F97066",
        500: "#F04438",
        600: "#D92D20",
        700: "#B42318",
        800: "#912018",
        900: "#7A271A"
    },
    warning: {
        25: "#FFFCF5",
        50: "#FFFAEB",
        100: "#FEF0C7",
        200: "#FEDF89",
        300: "#FEC84B",
        400: "#FDB022",
        500: "#F79009",
        600: "#DC6803",
        700: "#B54708",
        800: "#93370D",
        900: "#7A2E0E",
    },
    newWarning: {
        25: "#F4EFE3",
        100: "#5E4200"
    },
    success: {
        25: "#F6FEF9",
        50: "#ECFDF3",
        100: "#D1FADF",
        150: "#0D804A",
        200: "#A6F4C5",
        300: "#6CE9A6",
        400: "#32D583",
        500: "#12B76A",
        600: "#039855",
        700: "#027A48",
        800: "#05603A",
        900: "#054F31",
    }
} as const;

export default colorLight;