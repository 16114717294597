import AddCard from 'jsx/components/Shared/AddPaymentModal';
import { memo, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import './index.css'
const LinkAccounts = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <p className='linkHeading'>
        Link Accounts
      </p>
      <Card>
        {
          openModal && <AddCard handleCloseModal={() => setOpenModal(false)} handleConfirm={() => null} openModal={openModal} />
        }
        <Card.Body>
          {/* <Row> */}
          <div className='d-flex justify-content-space-between align-items-center'>
            <p className='linkHeading linkColor'>
              Your Cards
            </p>
            <button className='button-sky' onClick={() => setOpenModal(true)}>
              Add Card
            </button>
          </div>
          {/* </Row> */}
          <Row>
            <Col md="4">
              <div className='d-flex CardDiv'>
                <div>
                  Image
                </div>
                <div className='ml-4'>
                  <p>
                    MasterCard
                  </p>
                  <p>
                    078XXXXXXXX
                  </p>
                </div>
              </div>
            </Col>
          </Row>

        </Card.Body>
      </Card>
    </>
  )
}

export default memo(LinkAccounts);
