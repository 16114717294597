import { ClickAwayListener, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import infoIcon from "Latest/Images/svg/_infoIcon.svg"


const CustomTooltip = ({ tootltipContent }: props) => {

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);


  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  return (
    <>

      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={isTooltipOpen}
          title={tootltipContent}
        >

          <img src={infoIcon} onClick={handleTooltipOpen} />


        </Tooltip>
      </ClickAwayListener>

    </>
  )
}

export default CustomTooltip

interface props {
  tootltipContent: string
}