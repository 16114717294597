import React from 'react'
import { Button, Divider, Fab, MenuItem, Menu } from '@mui/material'

const MenuBar = ({ anchorEl, handleBuy, handleClick, handleClose, handleSell, handleSwitch, open, row, selectedItem }: any) => {
    return (
        <div>
            <Button
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                id='demo-positioned-button'
                onClick={(e) => handleClick(e, row)}
            >
                <i
                    aria-hidden='true'
                    className='fa fa-ellipsis-v text-dark'
                ></i>
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                aria-labelledby='demo-positioned-button'
                id='demo-positioned-menu'
                onClose={handleClose}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuItem>
                    <h4>{selectedItem.name}</h4>
                </MenuItem>

                <Divider />

                <MenuItem onClick={handleClose}>
                    <div className=''>
                        <Fab
                            aria-label='add'
                            className='mr-2'
                            color='primary'
                            onClick={handleBuy}
                            size='small'
                            sx={{ width: 80 }}
                            variant='extended'
                        >
                            Buy
                        </Fab>
                        <Fab
                            aria-label='add'
                            className='mr-2'
                            color='error'
                            onClick={handleSell}
                            size='small'
                            sx={{ width: 80 }}
                            variant='extended'
                        >
                            Sell
                        </Fab>
                        <Fab
                            aria-label='add'
                            className='mr-2'
                            color='warning'
                            onClick={handleSwitch}
                            size='small'
                            sx={{ width: 80 }}
                            variant='extended'
                        >
                            Switch
                        </Fab>
                    </div>
                </MenuItem>
            </Menu>
        </div>
    )
}

export default MenuBar