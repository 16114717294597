import { styled } from '@mui/material/styles';
import { Card, Button, Typography, Box } from '@mui/material';
import img from "../Images/briefcase2.png"
import { goalCardTitleStyle, cardMainLogoBoxStyle, checkMarkStyle, createdLabel } from './style';
import Checkmark from 'react-typescript-checkmark';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    padding: "16px 32px!important", borderRadius: "8px",
    background: "white",
    // boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
    display: "flex",
    alignItems: "center", flexDirection: "column", gap: "8px",
    cursor: "pointer",
    justifyContent: "flex-start", height: "100%",
    // [theme.breakpoints.up(414)]: {
    //     padding: theme.spacing(5),
    // },
    position: 'relative',
    minHeight:"128px",
    '&:hover': {
        // color: "white",
        // border: "1px solid #43BED8",
        boxShadow: "0px 0px 0px 2px #43BED8",
    }
}));



// ----------------------------------------------------------------------


export default function GoalCard({ handleGoal, item, selectedGoal }: any) {

    const handleClick = () => {
        if (!item.isDisable) {
            handleGoal(item)
        }
    }

    return (

        <>
            <RootStyle onClick={handleClick} sx={{ boxShadow: selectedGoal.id == item.id ? "0px 0px 0px 2px #43BED8" : "0px 1px 4px 0px rgba(208, 213, 221, 0.25)" }}>
                {selectedGoal.id == item.id ? <Box sx={checkMarkStyle}><Checkmark size='md' backgroundColor='#43BED8' checkColor={"white"} /></Box> : ""}
                {/* {item.isDisable && <Box sx={createdLabel}>Created</Box>} */}
                <Box >
                    <img src={item.icon} />
                </Box>
                <Typography variant='body1' color="#1D2939" textAlign={"center"}>{item.name}</Typography>
            </RootStyle>

            {/* <RootStyle onClick={handleClick} sx={{ boxShadow: selectedGoal.id == item.id ? "0px 0px 0px 2px #43BED8" : "0px 0px 0px 1px #EDEDED" }}>

                {selectedGoal.id == item.id ? <Box sx={checkMarkStyle}><Checkmark size='md' backgroundColor='#43BED8' checkColor={"white"} /></Box> : ""}
                {item.isDisable && <Box sx={createdLabel}>Created</Box>}

                <Box >
                    <img src={item.icon} />
                </Box>

                <Box sx={{ ...goalCardTitleStyle, pt: "24px" }}>
                    {item.name}
                </Box>

            </RootStyle > */}
        </>
    );
}