import React, { useEffect, useState } from 'react'
import SaveNextKin from './saveNextKin'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { Grid, Typography } from '@mui/material';
import ShowNextKin from './showInfoNextKin';
import { useAppLoading } from 'hooks/appLoading';
import CustomChip from 'Latest/ComponentV1/customChip';
import { ToastContainer } from 'react-toastify';
import { cnicViewStyle } from "Latest/MyProfileV1/Style/style";

const Index = ({ handleCloseModal, path = "myprofile" }: any) => {

    const loadingAccountSetup = useAppLoading('accountSetup');
    const loadingPostNextOfKin = useAppLoading('postNextOfKin');
    const loading = loadingAccountSetup || loadingPostNextOfKin

    const { userAccountDetails } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    const [checkNextKin, setCheckNextKin] = useState(false)

    useEffect(() => {
        setCheckNextKin(userAccountDetails?.beneficiaries?.length > 0)
    }, [dispatch, userAccountDetails?.beneficiaries])

    return (
        <>
            <ToastContainer />
            <Grid item md={12} xs={12} >
            <Typography variant='h4' sx={{ ...cnicViewStyle.title }}>Next of Kin <Typography variant='h5'>{!checkNextKin && <CustomChip label="Required" status="" />}</Typography></Typography>
            </Grid>

            <Grid item md={12} xs={12} sx={{ mb: "30px" ,mt: "4px" }} >
                <Typography variant='body2'>Details of the person who will inherit the assets of an account holder if the account holder passes away</Typography>
            </Grid>

            {checkNextKin ?
                <ShowNextKin setCheckNextKin={setCheckNextKin} checkNextKin={checkNextKin} loading={loading} path={path} />
                :
                <SaveNextKin setCheckNextKin={setCheckNextKin} checkNextKin={checkNextKin} loading={loading} path={path} handleCloseModal={handleCloseModal} />
            }


        </>
    )
}

export default Index