import { Box, Button, Container, Grid, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { FinancialAdvisors } from "../../constants/index";
import { customCard } from 'Latest/GoalDetail/style'
import Advisors from "./Advisors/index"
import { tokens } from "theme";
import TabsHeading from "Latest/ComponentV1/TabsHeading";
import SpeakPhone from "Latest/Images/svg/speakPhone";
import useMediaQuery from '@mui/material/useMediaQuery';
import AllAdvisors from "./Advisors/AllAdvisors";
import { useAppSelector } from "hooks/reduxHooks";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { onGetAdvisors } from "redux/reducers/sharedStateData";
import { useAppLoading } from "hooks/appLoading";
import Loader from "jsx/components/Shared/Loader";

const Index = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery('(min-width:600px)');
    const { user } = useAppSelector(state => state.auth);
    const [advisorsData, setAdvisorsData] = useState("")
    const loading = useAppLoading("sharedStateData")
    const dispatch = useDispatch();

    const staticVal = advisorsData?.toUpperCase();
    const noAdvisor: any = FinancialAdvisors?.NULL;
    const advisorOne: any = FinancialAdvisors?.CEO;
    const advisorTwo: any = FinancialAdvisors?.CIO;
    const advisorThree: any = FinancialAdvisors?.SO;

    useEffect(() => {
        const onSuccess = (message: any, response: any) => {
            setAdvisorsData(message?.adviser)
        }
        dispatch(onGetAdvisors(onSuccess))
    }, [user?.id])

    const handleLink = () => {
        window.open("https://outlook.office365.com/owa/calendar/Advisorybooking@Mahaana.com/bookings/", "_blank")
    }

    if(loading){
        return <Loader width={200} />
    }

    return (
        <Grid container>
            <Grid item xs={12} sx={{ ...customCard.mainBoxV2, border: 0 }} mb={3}>

                {staticVal === advisorOne?.advisor?.toUpperCase() && (
                    <>
                        <TabsHeading heading='Schedule a financial advice session' />
                        <Typography variant='body2' color={colors.grey[600]} sx={{ width: { md: "60%", sm: "60%", xs: "90%" }, marginLeft: { md: "32px", xs: "16px" }, marginBottom: "32px", fontsize: "16px", fontWeight: "400" }}>Speak to your dedicated financial advisor</Typography>
                    </>)}
                {staticVal === advisorTwo?.advisor?.toUpperCase() && (
                    <>
                        <TabsHeading heading='Schedule a financial advice session' />
                        <Typography variant='body2' color={colors.grey[600]} sx={{ width: { md: "60%", sm: "60%", xs: "90%" }, marginLeft: { md: "32px", xs: "16px" }, marginBottom: "32px", fontsize: "16px", fontWeight: "400" }}>Speak to your dedicated financial advisor</Typography>
                    </>)}
                {staticVal === advisorThree?.advisor?.toUpperCase() && (
                    <>
                        <TabsHeading heading='Schedule a financial advice session' />
                        <Typography variant='body2' color={colors.grey[600]} sx={{ width: { md: "60%", sm: "60%", xs: "90%" }, marginLeft: { md: "32px", xs: "16px" }, marginBottom: "32px", fontsize: "16px", fontWeight: "400" }}>Speak to your dedicated financial advisor</Typography>
                    </>)}
                {staticVal === noAdvisor?.advisor?.toUpperCase() && (
                    <>
                        <TabsHeading heading='Schedule a financial advice session' />

                        <Box pl={{ md: 4, sm: 2, xs: 2 }} pr={{ md: 4, sm: 2, xs: 2 }}>
                            <Box width={"100%"} sx={{ marginBottom: "30px", marginTop: "10px", background: "#443087", color: "#fff", borderRadius: "8px" }}>

                                <Box sx={{ p: "12px 24px", display: "flex", alignItems: "center", width: "100%" }}>
                                    <Box>
                                        {
                                            smScreen && <SpeakPhone />
                                        }
                                    </Box>
                                    <Box sx={{ width: "100%", marginLeft: { md: "20px", sm: "20px", xs: "0px" }, display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                        <Box>
                                            <Typography variant="body1" sx={{ fontWeight: "600",marginBottom:{xs:"8px",md:"0px"} }}>Speak to a financial advisor</Typography>
                                            <Typography variant="body2" sx={{ fontWeight: "400", width: { md: "90%", sm: "90%", xs: "100%" } }}>Need help with your investment strategy? Speak to our experienced financial advisors for a personalized investment plan considering your investment goals and risk tolerance.</Typography>
                                        </Box>
                                        <Box sx={{ width: { md: "130px", sm: "130px", xs: "100%" } }}>
                                            <Button variant="contained" sx={{ width: { md: "130px", sm: "130px", xs: "100%" }, padding: "12px 24px", background: "#fff", color: "black", textTransform: "none", borderRadius: "100px", fontSize: "14px", fontWeight: "500", marginTop: { md: '0px', sm: "0px", xs: "16px" },':hover': {background: '#fff'} }} onClick={handleLink}>Book a call</Button>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                        </Box>  </>)}

            </Grid>

            {staticVal === noAdvisor?.advisor?.toUpperCase() && (
                <Box width={"100%"} sx={{ p: { xs: "24px 16px", md: "30px" }, background: "#fff" }}>
                    <Container maxWidth="lg" sx={{ px: "0px!important", ml: 0 }}>
                        <Grid container>
                            <AllAdvisors data={FinancialAdvisors}/>
                        </Grid>
                    </Container>
                </Box>)}
            {staticVal === advisorOne?.advisor?.toUpperCase() && (
                <Box width={"100%"} sx={{ p: { xs: "24px 16px", md: "30px" }, background: "#fff" }}>
                    <Advisors data={advisorOne} />
                </Box>)}
            {staticVal === advisorTwo?.advisor?.toUpperCase() && (
                <Box width={"100%"} sx={{ p: { xs: "24px 16px", md: "30px" }, background: "#fff" }}>
                    <Advisors data={advisorTwo} />
                </Box>)}
            {staticVal === advisorThree?.advisor?.toUpperCase() && (
                <Box width={"100%"} sx={{ p: { xs: "24px 16px", md: "30px" }, background: "#fff" }}>
                    <Advisors data={advisorThree} />
                </Box>)}


        </Grid >
    )
}

export default Index