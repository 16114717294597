import axios, { AxiosError } from 'axios';
import { Dispatch } from 'react';
import { AnyAction, Middleware } from 'redux';
import { onLogout, setRefreshToken } from 'redux/actions';
import { RootState } from 'redux/store';
import { mapAPIError } from './mapAPIError';
import { RISK_PROFILE_BASE_URL, STOCK_DETAIL_BASE_URL, USER_MANAGER_BASE_URL } from 'constants/settings';
import { ErrorsToast } from './error';
import jwt_decode from "jwt-decode";
import { toast } from 'react-toastify';

export const LocalUrl = RISK_PROFILE_BASE_URL + '/api/'
export const LocalUrl1 = STOCK_DETAIL_BASE_URL + "/api/";

let isRefreshingToken = false;
let refreshAccessTokenPromise: Promise<string | null> | null = null;

export const api: Middleware =
  ({
    dispatch,
    getState
  }: {
    dispatch: Dispatch<AnyAction>;
    getState: () => RootState;
  }) =>
    next =>
      async action => {
        if (!action.payload?.apiName) return next(action);
        next(action);
        const {
          abortApi,
          apiName,
          authorize = true,
          content_type = 'application/json',
          data,
          errorToast = false,
          formData = false,
          headers: extraHeaders,
          method,
          onFail,
          onSuccess,
          onSuccessCallback,
          responseType = 'json',
          url,
        } = action.payload;

        dispatch({ payload: apiName, type: 'globalLoading/start' });
        dispatch({ type: `${apiName}/${action.type}` });
        const headers = { ...extraHeaders };
        headers['Content-Type'] = content_type;
        headers['Accept'] = 'application/json';

        if (authorize) {
          const accessToken = await ValidateAccessToken(getState().auth.access_token, getState().auth.refresh_token);
          headers["Authorization"] = `Bearer ${accessToken || getState().auth.access_token}`;

          if (isRefreshingToken && !accessToken) {
            // Delay the API call until the access token is refreshed
            return;
          }
        }

        if (formData) {
          headers['Content-Type'] = 'multipart/form-data';
        }

        let timeoutPromise;
        if (abortApi) {
          timeoutPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
              reject(new Error('Request timed out'));
            }, 30000); // 10 seconds timeout
          });
        }

        try {
          let response :any;
          if (abortApi) {
            response = await Promise.race([
              axios.request({
                // baseURL,
                data,
                headers,
                method,
                url,
                responseType,
              })
              ,
              timeoutPromise
            ]);
          }else{
            response = await axios.request({
                // baseURL,
                data,
                headers,
                method,
                url,
                responseType,
              })
          }

          dispatch({
            payload: response.data,
            type: `${apiName}/${action.type}Success`
          });
          dispatch({ payload: apiName, type: 'globalLoading/stop' });

          onSuccess && dispatch(onSuccess(response.data, data));
          onSuccessCallback && dispatch(onSuccessCallback(response, data));
        } catch (err) {
          const error = err as AxiosError;

          const { message, response } = error;

          const payload = {
            message: mapAPIError(error),
            statusCode: response?.status,
            statusText: response?.statusText
          };
          errorToast && ErrorsToast(error,responseType)
          // if (authorize && payload.statusCode === 403) {
          //   dispatch(onLogout());
          // } else
           if (authorize && payload.statusCode === 401) {
            dispatch(onLogout());
          } else {
            dispatch({ payload, type: `${apiName}/${action.type}Fail` });
            dispatch({ payload, type: `globalErrors/get` });
          }
          dispatch({ payload: apiName, type: 'globalLoading/stop' });
          onFail && onFail(message, response);
        }

        async function ValidateAccessToken(access_token: string | null, refresh_token: string | null) {

          const access_token_ = access_token || '';
          const refresh_token_ = refresh_token || '';
          const decoded: any | null = jwt_decode(access_token_);
          if (new Date() > new Date(decoded?.exp * 1000)) {
            if (!isRefreshingToken) {
              isRefreshingToken = true;

              const dataBody = new URLSearchParams();
              dataBody.append('client_id', 'default-client');
              dataBody.append('response_type', 'token');
              dataBody.append('grant_type', 'refresh_token');
              dataBody.append('refresh_token', refresh_token_);

              refreshAccessTokenPromise = refreshAccessToken(dataBody)
                .finally(() => {
                  isRefreshingToken = false;
                });
            }

            try {
              const newAccessToken = await refreshAccessTokenPromise;

              if (newAccessToken) {
                return newAccessToken;
              }
            } catch (err) {
              dispatch(onLogout());
            }
          } else {
            return access_token_;
          }
        }

        async function refreshAccessToken(dataBody: URLSearchParams): Promise<string | null> {
          try {
            const response = await axios.request({
              method: 'post',
              url: `${USER_MANAGER_BASE_URL}/api/account/token`,
              data: dataBody,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            });

            dispatch({ payload: apiName, type: 'globalLoading/stop' });
            dispatch(setRefreshToken(response?.data));

            return response?.data?.access_token;
          } catch (err) {
            dispatch(onLogout());
            return null;
          }
        }
      };

