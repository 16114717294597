import { memo } from 'react';
import { Box } from '@mui/system';
import loadingImage from 'images/svg/loader.svg';
import Logo from "images/mahanaLogo.png"
import { LoaderStyles } from './style/loader';
import loaderGif from "Latest/Images/gif/loader.gif";
import loaderGifMob from "Latest/Images/gif/loaderMob.gif";
import { useMediaQuery, useTheme } from '@mui/material';

const Loader = ({ multilineTitle, title = "", width }: any) => {

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={LoaderStyles.mainBox}>

      <Box sx={LoaderStyles.subBox}>

        {/* <Box sx={LoaderStyles.imgBox}>
          <img src={Logo} style={{ width: "99.287px", height: "45.17px" }} />
        </Box> */}

        {/* <Box sx={LoaderStyles.bouncingBox}>
        Loading
        <div className="bouncing-loader">
          <div style={{ background: "#43bed8" }}></div>
          <div style={{ background: "#432f87" }}></div>
          <div style={{ background: "#43bed8" }}></div>
        </div>
      </Box> */}

        <Box display={"flex"} justifyContent={"center"}>
          <img src={xsScreen ? loaderGifMob : loaderGif} />
        </Box>

        {title || multilineTitle && <Box>
          {title && <Box sx={LoaderStyles.titleBox}>
            {title}
          </Box>}

          {multilineTitle && multilineTitle.map((i: any, index: number) => {
            return (
              <Box key={i} sx={{ ...LoaderStyles.titleBox, mt: index == 0 ? 1 : 0 }} >{i} </Box>
            )
          })}
        </Box>}

      </Box>

    </Box>
  )
};

export default memo(Loader);

