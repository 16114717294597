import React, { useEffect, useState } from 'react';
import { memo } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAppSelector } from 'hooks/reduxHooks';
import CustomPieChart from 'Latest/ChartV2/customPieChart';
import { savePieColorsHsl } from '../../../../../constants';
import CustomScrollerCard from 'Latest/ComponentV1/customScrollerCard';
import Big from 'big.js';
import { customColorGenerator } from 'utils/colorGerator';

export interface KeyValuePair {
    name: string,
    value: number
}

const ProgressBarChart = ({ formik }: any) => {
    const intialAmountCount = formik.values.intialAmountCount
    const intialAmt = formik.values.initial_amount

    const [pieMockData, setPieMockData] = useState<KeyValuePair[]>([]);
    const [dataCustomScrollerCard, setDataCustomScrollerCard] = useState<any>([]);
    const { cashFundSummary } = useAppSelector(state => state.sharedData);
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [genratedColor, setGenratedColor] = useState([])
    const [activeIndex, setActiveIndex] = useState(-1)

    const handleColorGenerator = () => {

        const hslColor = 'hsl(250, 61%, 97%)';
        const generatedColors: any = customColorGenerator(hslColor)
        setGenratedColor(generatedColors);

    }

    useEffect(() => {
        handleColorGenerator()
    }, [])

    useEffect(() => {
        const data = cashFundSummary?.currentAssetAllocation && Object.entries(cashFundSummary?.currentAssetAllocation).map(([key, value]) => {
            const percentage = Number(value?.toString()?.replace("%", "")) || 0;
            const amt = new Big(intialAmt || 0).mul(Number(percentage || 0)).div(100).toFixed(2, 0)
            return { name: key, value: percentage, amount: null };
        }).filter(item => item?.value > 0);

        let Pie_sorted_Arr;
        if (data) {
            Pie_sorted_Arr = data.slice().sort((a:any, b:any) => b.value - a.value);
        }
        setPieMockData(Pie_sorted_Arr);

        const data2 = cashFundSummary?.currentAssetAllocation && Object.entries(cashFundSummary?.currentAssetAllocation).map(([key, value]) => {
            const percentage = Number(value?.toString()?.replace("%", "")) || 0;
            const amt = new Big(intialAmt || 0).mul(Number(percentage || 0)).div(100).toFixed(2, 0)
            return { name: key, value: percentage, amount: amt };
        }).filter(item => item?.value > 0);

        let sorted_Arr;
        if (data2) {
            sorted_Arr = data2.slice().sort((a:any, b:any) => b.value - a.value);
        }
        setDataCustomScrollerCard(sorted_Arr)

    }, [intialAmountCount, intialAmt])

    return (

        <>

            <Grid item md={12} xs={12} sx={{ p: "12px 20px", borderBottom: "1px solid rgba(208, 213, 221, 0.25)" }}>
                <Typography variant='h1' color="#422E84" sx={{ fontSize: { md: "24px", xs: "16px" } }}>Portfolio</Typography>
            </Grid>

            <Grid item md={12} xs={12} >

                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center" }}>
                    <Box sx={{ width: { xs: "100%", sm: "60%" }, py: { xs: 2, md: 0 } }}>
                        {pieMockData && <CustomPieChart
                            hoverDotVal={(hoverDotVal: any) => { setActiveIndex(hoverDotVal) }} innerRadiusPie={"84%"}
                            outerRadiusPie={"100%"} colors={savePieColorsHsl} data={pieMockData} showCustomActiveShape={false}
                            customTooltipTrue={false} hoverToolTip={false} customHeight={smScreen ? 230 : xsScreen ? 210 : 310}
                        />}
                    </Box>
                    <CustomScrollerCard data={dataCustomScrollerCard} colors={savePieColorsHsl} activeIndex={activeIndex} />
                </Box>
            </Grid>
        </>

    );
};

export default memo(ProgressBarChart);
