import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import HeaderText from 'jsx/components/PaymentOptions/HeaderText';
import PaymentOptionsConent from 'jsx/components/PaymentOptions/PaymentOptionsConent';
import Loader from 'jsx/components/Shared/Loader';
import { memo, useCallback, useEffect } from 'react';
import {
  bankTransferPost,
  onBankTransfer,
  onGetBanksList
} from 'redux/actions';
import { onGetBanks } from 'redux/reducers/sharedStateData';
import { getPaymentAcc } from 'redux/reducers/payment';

const banksList = [
  {
    label: 'Meezan Bank',
    value: '1'
  },
  {
    label: 'UBL Bank',
    value: '2'
  },
  {
    label: 'MCB Bank',
    value: '3'
  }
]
const PaymentOptions = () => {
  const dispatch = useAppDispatch();
  const loading = useAppLoading('payment');
  const { banksList } = useAppSelector(state => state.sharedData);
  const { paymentAcc } = useAppSelector(state => state.payment);

  const handleSubmitBankTransfer = (data: bankTransferPost) => {
    dispatch(onBankTransfer(data));
  };

  useEffect(() => {
    dispatch(onGetBanks());
    dispatch(getPaymentAcc())
  }, [dispatch]);

  if (loading) return <Loader />;

  return (
    <>
      <HeaderText />
      <PaymentOptionsConent
        banksList={banksList}
        paymentAccList={paymentAcc}
        submitBankTransfer={handleSubmitBankTransfer}
      />
    </>
  );
};

export default memo(PaymentOptions);
