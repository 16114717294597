import { FormikErrors, FormikTouched } from 'formik';
import { memo, useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { formikRadioFieldStyle } from '../style/formikRadioFieldStyle';
// import { RadioGroup } from '@mui/material';
// import './styling.css';
import { Box, Grid, Typography } from '@mui/material';
import { specialRadioFieldChildGridV2 } from '../style/specialRadioField';

const sigleLineStyle = {
  display: "flex",
  flexWrap: "nowrap",
}
const FormikRadioField = ({
  col = 6,
  fieldName,
  fields,
  formik,
  label,
  nestedFieldName,
  required = false,
  singleLine = false,
  style
}: FormikRadioFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  let FieldError = '';
  let FieldTouch = false;
  let NestedFieldError = '';
  let NestedFieldTouch = false;

  if (nestedFieldName) {
    const nestedFieldErrorSplitting = formikError[fieldName?.split('.')[0]];
    const nestedFieldTouchSplitting = formikTouch[fieldName?.split('.')[0]];
    if (nestedFieldErrorSplitting && nestedFieldTouchSplitting) {
      NestedFieldError = nestedFieldErrorSplitting[nestedFieldName];
      NestedFieldTouch = nestedFieldTouchSplitting[nestedFieldName];
    }
  } else {
    FieldError = formikError[fieldName];
    FieldTouch = formikTouch[fieldName];
  }


  const textInput: any = useRef(null);

  const handleClick = (selectedOption: any) => {
    formik.setFieldValue(fieldName, selectedOption);
  }


  return (
    <>
      <Grid container spacing={1}>

        {label && <Grid item xs={12} sx={{ pb: "3px" }}>
          <Typography variant='body2'>
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </Typography>
        </Grid>}

        <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap", cursor: "pointer", gap: "8px" }}>
          {fields.map(val => {
            return (
              <Grid key={val.label} item onClick={() => handleClick(val.value)}>
                <Box sx={{
                  ...specialRadioFieldChildGridV2,
                  border: formik.values[fieldName] === val.value ? "1px solid #422E84" : "1px solid rgba(208, 213, 221, 0.25)",
                  backgroundColor: formik.values[fieldName] === val.value ? "#422E84" : "#FFF",
                  '& .form-check-label': {
                    color: formik.values[fieldName] === val.value ? "#fff" : "#1D2939",
                    margin: 0
                  }
                }}
                >
                  <Form.Check
                    ref={textInput}
                    key={val.label}
                    checked={formik.values[fieldName] === val.value}
                    className="specialRadio"
                    disabled={!!val.disabled}
                    id={val?.label?.toString() + label?.toString()}
                    label={val.label}
                    name={fieldName}
                    // onChange={formik.getFieldProps(fieldName).onChange}
                    style={{ paddingLeft: 0 }}
                    type='radio'
                    value={val.value}
                  />
                </Box>
              </Grid>
            )
          })}
        </Grid>
        {FieldTouch && FieldError && (
          <Grid item xs={12} sx={{ pt: "0.25rem !important" }}>
            <span className='mt-1 text-danger'>{FieldError}</span>
          </Grid>
        )}
        {NestedFieldTouch && NestedFieldError && (
          <Grid item xs={12} sx={{ pt: "0.25rem !important" }}>
            <span className='mt-1 text-danger'>{NestedFieldError}</span>
          </Grid>
        )}
      </Grid>
    </>

  );
};

export default memo(FormikRadioField);

interface FormikRadioFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  nestedFieldName?: string;
  fields: Array<{ label: string; value: string; disabled?: boolean }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
    setFieldValue: any
  };
  label?: string;
  required?: boolean;
  singleLine?: boolean;
  style?: any
}
