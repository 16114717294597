import { Chip, useTheme } from '@mui/material'
import React from 'react'
import { ThemeSettings, tokens } from 'theme';

const style = {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "18px",
    border: "none",
    height:"auto",
    Letter:"0.16 px",
    ".css-6od3lo-MuiChip-label":{
        px:"10px" ,
        py:"2px"
    }
}
const TableStatusLabel = ({ label = "label", status = "success", variant = "outlined" }: any) => {

    const customTheme = useTheme();
    const colors = tokens(customTheme.palette.mode);

    const labelStatus: any = {
        success: <Chip label={label} variant={variant} sx={{ background: colors.greenAccent[200], color: colors.greenAccent[300], ...style }} />,
        progress: <Chip label={label} color="success" variant={variant} sx={{ background: colors.grey[15], color: colors.grey[20], ...style }} />,
        failed: <Chip label={label} color="primary" variant={variant} sx={{ background: colors.red[100], color: colors.red[800], ...style }} />,
        pending: <Chip label={label} color="primary" variant={variant} sx={{ background: colors.warning[100], color: colors.warning[600], ...style }} />
    }

    return (
        <>
            {labelStatus[status] || "-"} 
        </>
    )

}

export default TableStatusLabel