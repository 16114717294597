import { Box, IconButton, Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CurrencyFormatterWithDecimal } from 'utils/calculation'
import { CustomScrollerCardStyle } from './style/style'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { graphTabStyle } from 'Latest/ChartV1/style';
import Big from 'big.js';

const CustomScrollerCard = ({ activeIndex, colors, data, styles }: any) => {
    const [showPorfolioDetailInd, setShowPorfolioDetailInd] = useState(-1)
    const [isExpandMore, setIsExpandMore] = useState(true);

    const handleExpand = (index: number) => {
        if (showPorfolioDetailInd == index) {
            setShowPorfolioDetailInd(-1)
            setIsExpandMore(false)
        } else {
            setShowPorfolioDetailInd(index)
            setIsExpandMore(true)
        }
    }


    return (
        <>
            <Box sx={{ ...CustomScrollerCardStyle.contentScroller, ...styles }}>
                {data?.map((item: any, index: number) => {
                    return (
                        <Box key={index}>
                            <Box
                                sx={{
                                    padding: "16px 24px 16px 16px",
                                    display: 'flex', gap: "16px", alignItems: "center",
                                    backgroundColor: index === activeIndex ? "#EBEBEB" : (index % 2 === 0 ? "#F9FAFB" : "#FFFFFF")
                                }}>

                                <Box sx={{ width: "16px", height: "16px", borderRadius: "100px", background: colors[index] }} />

                                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <Box>
                                        <Typography variant='subtitle2' fontWeight={400} color={"#1D2939B2"}>
                                            {item.name}
                                        </Typography>
                                        <Typography variant="subtitle2" color={"#1D2939"} fontWeight={500} pt="2px">
                                            {/* {CurrencyFormatterWithDecimal(item?.amount, true, "never", false)}  */}
                                            {CurrencyFormatterWithDecimal(item?.value, false, "never", false)}%
                                        </Typography>
                                    </Box>

                                    {item?.portfolioAllocations?.length >= 1 && <IconButton aria-label="ExpandMoreIcon" onClick={() => handleExpand(index)}>
                                        {isExpandMore && showPorfolioDetailInd == index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>}
                                </Box>

                            </Box>

                            {item?.portfolioAllocations?.length >= 1 && showPorfolioDetailInd == index &&
                                <Box sx={{ padding: "12px 24px 12px 42px", display: 'flex', gap: "10px", backgroundColor: "#F9FAFB", flexDirection: "column" }}>
                                    {item.portfolioAllocations.map((itemNested: any, indexNested: number) => {
                                        return (
                                            <Box key={indexNested} sx={{ display: "flex", justifyContent: "space-between" }}>

                                                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                    <Box sx={{ ...graphTabStyle.tooltipCircle, background: colors[index] }} />
                                                    {/* <Typography variant='body2'> {itemNested.name}</Typography> */}
                                                    <Link href={itemNested.link} sx={{ color: "black", textDecorationColor: "black" }} variant="body2" target="_blank" rel="noopener noreferrer" underline="always">
                                                        {itemNested.name}
                                                    </Link>
                                                </Box>

                                                <Typography variant="subtitle2" color={"#51525C"}>
                                                    {/* {CurrencyFormatterWithDecimal(itemNested?.initialAmount || 0, true, "never", false)}  */}
                                                    {CurrencyFormatterWithDecimal(itemNested?.percentage || 0, false, "never", false)}%
                                                </Typography>

                                            </Box>
                                        )
                                    })}

                                </Box>}

                        </Box>
                    )
                })}

            </Box >
        </>
    )
}

export default CustomScrollerCard