export const investStyle = {
    heading: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "25px",
        lineHeight: "28px",
        color: "#403081"
    },
    subHeading: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#475467"
    },
    buyBtn: {
        color: "white", background: "#43BED8", boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        border: "1px solid #43BED8", padding: "10px 18px", borderRadius: "4px", width: "100%",
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        '&:hover': {
            backgroundColor: '#43bed8e6'
        },
    },

    cancelBtn: {
        color: "#344054", background: "#FFFFFF", boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        border: "1px solid #D0D5DD", padding: "10px 18px", borderRadius: "4px", width: "100%", fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px"
    },
    inputLabel: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#344054"
    },
    NumericInput: { border: "1px solid #D0D5DD", borderRadius: "8px" },
    optionAmount: {
        borderRadius: "30px", cursor: "pointer",
        '&:hover': {
            backgroundColor: '#DCF9FF'
        },
    },
    optionAmountTypo: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 400, fontSize: "14px", lineHeight: "20px", color: "#475467", padding: "7px 16px",
    },


    customProgressBar: {
        height: "30px", width: "100%", borderRadius: "100px", backgroundColor: "#49BDD8",
        display: "flex", alignItems: "center"
    },
    grayCard: {
        padding: "8px 12px", backgroundColor: "#D0D5DD40", border: "1px solid #D0D5DD40", borderRadius: "8px",
        minHeight: 40
    }
}