import { Box, Button, CircularProgress, Grid, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import settingCircleIcon from "Latest/Images/svg/settingCircleIcon.svg"
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom'
import { useAppLoading } from 'hooks/appLoading'
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack'
import { useHistory } from 'react-router-dom'

const GenerateIdRaast = ({ categoryAcc, formik, goalId, handleGenerateRaastId, originalAmt, previousStep }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { push } = useHistory();

    const loading = useAppLoading('onGenerateRaastId');

    const handleNext = () => {
        handleGenerateRaastId()
    }

    const buttonHtml = () => {
        return (
            <>
                <LoadingBtnCustomBack handleOnClick={previousStep ? previousStep : () => push("/")} btnTitle={"Back"} />
                <LoadingBtnCustom handleOnClick={handleNext} btnTitle={"Generate IBAN"} loading={loading} fullWidth={smScreen} />
            </>
        )
    }

    return (
        <>

            <Box sx={{ pt: { xs: "8px", md: "8px" } }}>

                <Box sx={{ display: "flex", justifyContent: "center" }} >
                    <img src={settingCircleIcon} />
                </Box>

                <Typography variant='h5' textAlign={"center"} sx={{ pt: "8px" }}>Generate your Raast Investment IBAN</Typography>

                <Typography textAlign={"center"} sx={{ pt: "4px", fontSize: "14px!important", color: "rgba(29, 41, 57, 0.70)", fontWeight: 400, maxWidth: "519px", margin: "auto" }}>
                    Your Raast Investment IBAN is automatically generated by the <a href="https://www.cdcpakistan.com/"
                        style={{ textDecorationLine: "underline", color: "#422E84" }} target="_blank" rel="noreferrer"
                    >Central Depository Company (CDC)</a>. All funds sent to the IBAN are maintained with CDC for safe-keeping.
                </Typography>

                {<Box sx={{ mt: { xs: "24px", md: "40px" }, display: "flex", justifyContent: "center", gap: "16px" }}>
                    {buttonHtml()}
                </Box>}

            </Box>

                   


        </>
    )
}

export default GenerateIdRaast