import { Box, Grid, Typography, useTheme } from '@mui/material';
import CustomSliderTwoColor from 'Latest/ComponentV1/customSliderTwoColor'
import { investStyle } from 'Latest/DashboardScreen/GoalDashboard/Style/investStyle';
import Big from 'big.js';
import React from 'react'
import { tokens } from 'theme';
import { CurrencyFormatterWithDecimal, roundToNearestThousand } from 'utils/calculation';

const GoalSlider = ({ goalInfo, userOnTrack = false }: any) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const maxLimit = goalInfo.maxLimit
    const portfolioAmount = goalInfo.potDetails.portfolioAmount
    const monthlyAmount = goalInfo.potDetails.periodicContribution
    const gainLossAmount = goalInfo.potDetails.gainLossAmount

    const completedPortfolioPercentage = Big(portfolioAmount || 0).div(maxLimit).mul(100).round().toNumber();
    const profitPercentage = Big(portfolioAmount || 0).minus(gainLossAmount).div(portfolioAmount || 1).mul(100).round().toNumber();

    function valuetext(value: number) {
        return `${value}%`;
    }

    return (
        <>
            <Box sx={{ py: 2 }}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant='alertTitle' color={colors.grey[800]}>Goal progress</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='alertTitle' color={colors.grey[800]}>{`${CurrencyFormatterWithDecimal(roundToNearestThousand(Number(maxLimit)), true, "never", true, true)}`}</Typography>
                    </Grid>
                </Grid>

                <Grid item sx={{ ...investStyle.customProgressBar, overflow: "hidden" }} mt={1}>
                    <Box sx={{ ...investStyle.customProgressBar, width: valuetext(completedPortfolioPercentage), backgroundColor: "#422E84" }}>
                        {completedPortfolioPercentage >= 3 && <Typography variant='alertTitle' color={"#fff"} textAlign={"right"} width={"100%"} pr={1}>{valuetext(completedPortfolioPercentage)}</Typography>}
                    </Box>
                    {completedPortfolioPercentage < 3 && <Typography variant='alertTitle' color={"#fff"} textAlign={"left"} width={"100%"} pl={1}>{valuetext(completedPortfolioPercentage)}</Typography>}
                </Grid>

                <Grid item md={12} sx={investStyle.grayCard} mt={1}>
                    <Typography variant='subtitle1' color={colors.grey[800]}>
                        {userOnTrack ? "You’re on track to achieving your goal of" : "You’re not on track of achieving your goal of"}
                        {' '}{`${CurrencyFormatterWithDecimal(roundToNearestThousand(Number(maxLimit)), true, "never", true, true)}`}
                        {monthlyAmount > 0 && (
                            <>
                                {' '}with a monthly contribution of{' '}
                                <Typography variant='alertTitle' color={colors.grey[800]}>
                                    {`${CurrencyFormatterWithDecimal(monthlyAmount, true, "never", true, true)}`}
                                </Typography>
                            </>
                        )}
                    </Typography>
                </Grid>

            </Box>
        </>
    )
}

export default GoalSlider