/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
  fundsOverview: {}
};

const name = 'fundsSnapshot';
const fundsSnapshotSlice = createSlice({
  initialState,
  name,
  reducers: {
    overviewSuccess: (state, action: PayloadAction<any>) => {
      state.fundsOverview = action.payload.table;
    }
  }
});

export default fundsSnapshotSlice.reducer;

//? APIS ACTIONS

export const onGetFundOverview = (id: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `FundSnapshot/GetOverview?id=${id}`
  },
  type: 'overview'
});

// ? Types

interface InitialState {
  fundsOverview: Record<string, any>;
}
