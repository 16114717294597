import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { links } from 'static/links';
import ConfirmationImage from '../../../images/confirmation.png'
const ConfirmPayment = ({
    deleteConfirm,
    handleDeleteCancel,
    handleDeleteConfirm
}: DeleteConfirmProps) => {
    const history = useHistory();
    return (
        <Modal
            centered
            aria-labelledby='contained-modal-title-vcenter'
            show={deleteConfirm}
            size='sm'
        >
            <Modal.Body className='px-3'>
                <div className='text-center'>
                    <img
                        src={ConfirmationImage}
                    />
                    <div>
                        <p style={{ fontFamily: ' PlayFair', fontSize: '20px', fontWeight: '700', color: 'black' }}>
                            Thank you
                        </p>
                        <p style={{ fontFamily: ' Lato', fontSize: '14px', fontWeight: '700', color: 'black' }}>
                            Your payment verification is in progress,
                        </p>
                        <button className='button-sky' onClick={() => { handleDeleteCancel(); history.push(links.overview) }}>
                            OK
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmPayment;

interface DeleteConfirmProps {
    deleteConfirm: boolean;
    handleDeleteConfirm: () => void;
    handleDeleteCancel: () => void;
}
